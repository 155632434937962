import AsyncStorage from '@react-native-async-storage/async-storage'
import { useEffect, useState } from 'react'

import logError from './logError'

type GlobalPersistedVariable<T> = () => [T, (value: T) => void]

export function createGlobalPersistedVariable<T> (initialValue: T, keyName: string): GlobalPersistedVariable<T> {
  let currentValue: T = initialValue
  const listeners = new Set<(value: T) => void>()

  AsyncStorage.getItem(keyName).then((raw) => {
    if (raw != null) {
      const value = JSON.parse(raw)
      currentValue = value
      listeners.forEach(fn => fn(value))
    }
  }).catch(logError)

  function setValue (value: T): void {
    currentValue = value
    listeners.forEach(fn => fn(value))
    AsyncStorage.setItem(keyName, JSON.stringify(value)).catch(logError)
  }

  return function hook () {
    const [current, setCurrent] = useState(currentValue)

    listeners.add(setCurrent)

    useEffect(() => {
      return () => {
        listeners.delete(setCurrent)
      }
    }, [setCurrent])

    return [current, setValue]
  }
}

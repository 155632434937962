import { WeekDay } from '../../types/graphql'

const days = [
  WeekDay.Monday,
  WeekDay.Tuesday,
  WeekDay.Wednesday,
  WeekDay.Thursday,
  WeekDay.Friday,
  WeekDay.Saturday,
  WeekDay.Sunday
]

export function periodEndingToWeekTime (periodEnding: { day: WeekDay | null, time: string | null }): number | null {
  if (periodEnding.day == null) return null
  if (periodEnding.time == null) return null

  const day = days.indexOf(periodEnding.day)
  const [hours, minutes, seconds] = periodEnding.time.split(':').map((part) => Number.parseInt(part, 10))
  return ((((((day * 24) + hours) * 60) + minutes) * 60) + seconds) * 1000
}

export function periodEndingToDayTime (periodEnding: { day: WeekDay | null, time: string | null }): number | null {
  if (periodEnding.time == null) return null

  const [hours, minutes, seconds] = periodEnding.time.split(':').map((part) => Number.parseInt(part, 10))
  return ((((hours * 60) + minutes) * 60) + seconds) * 1000
}

import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'

const Warning: React.FC<{ message?: string, paddingBottom?: number }> = ({ message, paddingBottom }) => {
  if (message == null) return null

  return (
    <HStack alignItems='center' paddingBottom={paddingBottom}>
      <MaterialIcons color='red' name='warning' size={24} />

      <Spacer width={16} />

      <Text color='red' size={16}>{message}</Text>
    </HStack>
  )
}

export default Warning

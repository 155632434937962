import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import { useForm } from 'react-hook-form'

import { useExportYouniumUsageMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import Select from '../components/Select'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import Heading from '../components/atoms/Heading'
import Paragraph from '../components/atoms/Paragraph'
import yup, { yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'

gql`
  mutation ExportYouniumUsage($input: ExportYouniumUsageInput!) {
    exportYouniumUsage(input: $input)
  }
`

const iconUrl = 'https://locousercontent.com/5M8wHuf20SdYDT8TtYArdvo1XKr65xwCifRT8js8PMHu9i2U-Bn5SEVuPcleZrns/original.svg'

function monthOptions (): Array<{ title: string, value: string }> {
  const result = []

  let date = Temporal.ZonedDateTime.from('2024-01-01T00:00:00.000+01:00[Europe/Stockholm]')
  const end = Temporal.Now.zonedDateTimeISO('Europe/Stockholm').with({ day: 1 }).startOfDay()

  while (date.epochMilliseconds < end.epochMilliseconds) {
    const value = date.toPlainYearMonth().toString()
    result.push({ title: value, value })
    date = date.add({ months: 1 }).startOfDay()
  }

  return result.reverse()
}

interface Form {
  email: string
  month: string
}

const schema = yup.object({
  email: yup.string().trim().required('Please enter your email address').email('Please enter a valid email address'),
  month: yup.string().required('Please choose which month to export usage data for')
})

const YouniumUsageExport: React.FC = () => {
  const [exportYouniumUsage, { called, error, loading }] = useExportYouniumUsageMutation()

  const form = useForm<Form>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const handleSubmit = (input: Form): void => {
    exportYouniumUsage({ variables: { input } }).catch(logError)
  }

  return (
    <Layout hideTitle screenType={ScreenType.Form} title='Younium Usage Export'>
      <FormContainer gap={20}>
        <Heading size='l'>
          {/* FIXME: react-native-web Image currently doesn't inline inside Text as it does on native */}
          <img src={iconUrl} style={{ height: 19, width: 19 }} /> Younium Usage Export
        </Heading>

        <Paragraph>
          Use this form to export usage data for all restaurants in CSV format, to be imported into Younium. The export will include all usage data for the selected month.
        </Paragraph>

        <Paragraph>
          The export will run in the background, and will send an email to the provided address when it is ready for download. It can take up to 15 minutes for the export to be ready.
        </Paragraph>

        <TextField form={form} inputMode='email' name='email' title='Email' />
        <Select form={form} name='month' options={monthOptions()} title='Month' />

        <SubmitFormButtons
          called={called}
          error={error}
          onSave={ignoreAsync(form.handleSubmit(handleSubmit))}
          saving={loading}
          successMessage='Export started. You will receive an email when the export is ready for download.'
          titleForSubmit='Export Usage Data'
        />
      </FormContainer>
    </Layout>
  )
}

export default YouniumUsageExport

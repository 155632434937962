import React from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { VStack } from 'react-stacked'

import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import { PrimaryButton } from './Buttons'
import { IntegerField } from './fields'

export interface ExtraDeliveryTime {
  extraDeliveryTime: number
}

const schema: ObjectSchema<ExtraDeliveryTime> = yup.object({
  extraDeliveryTime: yup.number().required('Vänligen ange extra leveranstid')
})

interface ExtraDeliveryTimeFieldProps {
  defaultValues: ExtraDeliveryTime
  loading: boolean
  onSave: (patch: ExtraDeliveryTime) => void
}

const ExtraDeliveryTimeField: React.FC<ExtraDeliveryTimeFieldProps> = ({ defaultValues, loading, onSave }) => {
  const form = useForm<ExtraDeliveryTime>({
    criteriaMode: 'all',
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(schema)
  })

  return (
    <VStack>
      <IntegerField
        form={form}
        name='extraDeliveryTime'
        title='Extra leveranstid (minuter)'
      />

      <Spacer height={12} />

      <PrimaryButton key='update' disabled={!form.formState.isDirty || loading} loading={loading} onPress={ignoreAsync(form.handleSubmit(onSave))} title='Uppdatera extra leveranstid' />
    </VStack>
  )
}

export default ExtraDeliveryTimeField

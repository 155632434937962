import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import { useGetRestaurantStripeEditDataQuery } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import Link from '../components/Link'
import SetupContainer from '../components/SetupContainer'
import { ACCENT_COLOR } from '../lib/color'
import openLink from '../util/openLink'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantStripeEditData($restaurantId: ID!, $redirectUrl: URL) {
    restaurant(id: $restaurantId) {
      id

      organization {
        id

        setupProgress {
          hasCompletedStripeSetup
          hasStripeAccount
        }
      }

      setupProgress {
        hasCashregister
        hasEnteredActivationCode
        taxAuthoritiesCashregisterRegistrationUrl
        taxAuthoritiesControlUnitRegistrationUrl
        stripeConnectionUrl(redirectUrl: $redirectUrl)
      }
    }
  }
`

const RestaurantStripeEdit: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantStripeEdit'>()

  const redirectUrl = new URL(window.location.origin)
  redirectUrl.pathname = '/hooks/v1/stripe-setup-complete'

  const { data, loading } = useGetRestaurantStripeEditDataQuery({ variables: { restaurantId, redirectUrl: redirectUrl.toString() } })

  const stripeConnectionUrl = data?.restaurant?.setupProgress?.stripeConnectionUrl ?? null
  const hasStripeAccount = data?.restaurant?.organization?.setupProgress?.hasStripeAccount ?? false
  const hasCompletedStripeSetup = data?.restaurant?.organization?.setupProgress?.hasCompletedStripeSetup ?? false

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantPaymentMethodList', { restaurantId }], title: 'Betalsätt' }
  ], [restaurantId])

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title='Stripe'>
      <VStack gap={12} grow={1} maxWidth={700}>
        <HStack>
          <SecondaryButton
            icon='chevron-left'
            onPress={() => navigation.navigate('RestaurantPaymentMethodList', { restaurantId })}
            title='Tillbaka'
          />
        </HStack>

        {loading ? (
          <VStack alignItems='center' grow={1} justifyContent='center'>
            <ActivityIndicator />
          </VStack>
        )
          : (
            <>
              <SetupContainer
                disabled={hasStripeAccount}
                disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
              >
                <HStack alignItems='center' gap={8}>
                  <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                    <Text color='white' size={16}>1</Text>
                  </VStack>

                  <Text color={ACCENT_COLOR} size={24}>Aktivera Stripe</Text>
                </HStack>

                <Text size={12}>För att kunna ta emot beställningar genom Loco behöver du ha ett Stripe-konto, det är så du får dina pengar</Text>

                <Spacer height={12} />

                {stripeConnectionUrl == null ? null : (
                  <PrimaryButton
                    onPress={() => openLink(stripeConnectionUrl)}
                    title='Anslut till Stripe nu'
                  />
                )}
              </SetupContainer>

              <SetupContainer
                disabled={!hasStripeAccount || hasCompletedStripeSetup}
                disabledMessage={!hasStripeAccount ? <Text backgroundColor='#333' borderRadius={24} color='#DDD' padding={8} paddingHorizontal={24} size={36}>Gör först klart steg 1</Text> : <MaterialIcons color='white' name='check-circle' size={48} />}
              >
                <HStack alignItems='center' gap={8}>
                  <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                    <Text color='white' size={16}>2</Text>
                  </VStack>

                  <Text color={ACCENT_COLOR} size={24}>Kompletterande Uppgifter</Text>
                </HStack>

                <Text size={12}>Stripe behöver kompletterande uppgifter för att kunna sköta utbetalningarna till ditt bankkonto.</Text>

                <Spacer height={12} />

                <PrimaryButton
                  onPress={() => openLink('https://dashboard.stripe.com')}
                  title='Komplettera uppgifter'
                />
              </SetupContainer>

              {!hasCompletedStripeSetup ? null : (
                <VStack backgroundColor='#FFF' borderRadius={4} gap={16} padding={8}>
                  <HStack alignItems='center' gap={8}>
                    <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                      <Text color='white' size={16}>3</Text>
                    </VStack>

                    <Text color={ACCENT_COLOR} size={24}>Inställning av Stripe slutförd!</Text>
                  </HStack>

                  <VStack alignSelf='center' backgroundColor='#F7F8FA' borderRadius={10} gap={10} padding={20}>
                    <Text size={16}>
                      Du har nu tillgång till Apple Pay på iOS, Google Pay på Android, Samsung Pay på Samsung-mobiler, och övriga kortbetalningar i Beställningsappen.
                    </Text>

                    <Text size={16}>
                      Här är några användbara tips:
                    </Text>

                    <Text size={14}>
                      <TextStyle weight='bold'>1. Stripe Dashboard:</TextStyle> Du kan nu logga in på din Stripe Dashboard för att hantera betalningar, se transaktioner och göra inställningar. Gå till <Link title="Stripe's inloggningssida" url='https://dashboard.stripe.com/login' /> för att komma igång.
                    </Text>

                    <Text size={14}>
                      <TextStyle weight='bold'>2. Hjälp och Support:</TextStyle> Har du frågor eller stöter på problem med Stripe? Deras <Link title='hjälpcenter' url='https://support.stripe.com' /> har svar på de flesta vanliga frågor.
                    </Text>

                    <Text size={14}>
                      <TextStyle weight='bold'>3. Kontakt:</TextStyle> Om du behöver ytterligare hjälp eller har specifika frågor gällande ditt konto, kan du kontakta Stripe's kundtjänst direkt genom deras <Link title='kontaktsida' url='https://stripe.com/contact' />.
                    </Text>

                    <Text size={16}>
                      Tack för att du valde oss! Om du har några frågor eller funderingar gällande vår tjänst, tveka inte att höra av dig.
                    </Text>

                    <Text size={16} weight='bold'>
                      Lycka till med dina betalningar!
                    </Text>
                  </VStack>

                  <PrimaryButton
                    onPress={() => openLink('https://dashboard.stripe.com')}
                    title='Gå till Stripe Dashboard'
                  />
                </VStack>
              )}
            </>
          )}
      </VStack>
    </Layout>
  )
}

export default RestaurantStripeEdit

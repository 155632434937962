import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import unwrap from 'ts-unwrap'

import { GetPageMenuDocument, type MenuProductInput, type MenuProductPropertiesFieldsFragment, type MenuProductPropertiesInput, MenuSystem, MenuType, useDeleteMenuProductMutation, useGetEditProductDataQuery, useUpdateAndRelocateMenuProductMutation, useUpdateMenuProductStockMutation } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import ProductForm, { type MenuProductInputSchema } from '../components/ProductForm'
import VerificationDialog from '../components/VerificationDialog'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetEditProductData($restaurantId: ID!, $menuProductId: ID!) {
    restaurant(id: $restaurantId) {
      id

      hasMenuWriteAccess: hasAccess(access:MenuWrite)
      menuSystem

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        pages {
          id
          name
          categories {
            id
            name
          }
        }

        alternativeGroups {
          id
          name
        }

        courses {
          id

          name
        }

        productOpeningHours {
          id
          name
        }

        product(id: $menuProductId) {
          ...MenuProductFields

          category {
            id
            name

            page {
              id
              name
            }
          }
        }

        reportGroups {
          ...FullMenuReportGroup
        }
      }

      printerQueues {
        id
        name
      }
    }
  }

  mutation UpdateAndRelocateMenuProduct($restaurantId: ID!, $menuProductId: ID!, $patch: MenuProductPatch!, $menuCategoryId: ID!, $fromMenuCategoryId: ID!) {
    updateMenuProduct(
      restaurantId: $restaurantId
      menuProductId: $menuProductId
      patch: $patch
    ) { id }
    relocateMenuProduct(
      restaurantId: $restaurantId
      menuProductId: $menuProductId
      fromMenuCategoryId: $fromMenuCategoryId
      toMenuCategoryId: $menuCategoryId
    )
  }

  mutation UpdateMenuProductStock ($restaurantId: ID!, $menuProductId: ID!, $patch: MenuProductPatch!) {
    updateMenuProduct(
      restaurantId: $restaurantId
      menuProductId: $menuProductId
      patch: $patch
    ) { id }
  }

  mutation DeleteMenuProduct($restaurantId: ID!, $menuProductId: ID!) {
    deleteMenuProduct(
      restaurantId: $restaurantId
      menuProductId: $menuProductId
    )
  }
`

function productHasMenuType (input: MenuProductPropertiesFieldsFragment | null | undefined): boolean {
  const priceIsOpen = input?.priceIsOpen ?? false

  const isProductWithOpenPrice = input?.price == null && priceIsOpen && input?.reportGroup != null
  const isNotNullLegacyProduct = input?.price != null && !priceIsOpen && input?.reportGroup != null

  return isProductWithOpenPrice || isNotNullLegacyProduct
}

const MenuProductEdit: React.FC = () => {
  const [navigation, { restaurantId, menuProductId }] = useNavigation<'MenuProductEdit'>()
  const { data, loading } = useGetEditProductDataQuery({ fetchPolicy: 'network-only', variables: { restaurantId, menuProductId } })
  const [updateMenuProduct, { error: updateMenuProductError, loading: savingMenuProduct }] = useUpdateAndRelocateMenuProductMutation({ awaitRefetchQueries: true })
  const [updateMenuProductStock, { loading: savingStock }] = useUpdateMenuProductStockMutation({ awaitRefetchQueries: true })
  const [deleteMenuProduct, { loading: deleting, error: deleteError }] = useDeleteMenuProductMutation({ awaitRefetchQueries: true })
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const saving = savingMenuProduct || savingStock

  const product = data?.restaurant?.menu?.product

  // Hide form for unfetched menutypes if legacy system is used and they are null.
  const menuSystem = data?.restaurant?.menuSystem ?? MenuSystem.Legacy

  const types = useMemo(() => {
    if (menuSystem === MenuSystem.Legacy) {
      const _types = []
      if (productHasMenuType(product?.delivery)) _types.push(MenuType.Delivery)
      if (productHasMenuType(product?.eatIn)) _types.push(MenuType.EatIn)
      if (productHasMenuType(product?.takeAway)) _types.push(MenuType.TakeAway)

      return _types
    }

    return [MenuType.EatIn, MenuType.TakeAway, MenuType.Delivery]
  }, [menuSystem, product])

  const initialValues: MenuProductInput | undefined = useMemo(() => {
    if (product == null || product?.name == null) return undefined

    let delivery: MenuProductPropertiesInput | undefined
    if (product.delivery != null && productHasMenuType(product.delivery) && types.includes(MenuType.Delivery)) {
      delivery = {
        alternativeGroupIds: product.delivery.alternativeGroups?.map(alternative => alternative.id) ?? undefined,
        description: product.delivery.description,
        imageUrl: product.delivery.imageUrl,
        isActive: product.delivery.isActive,
        openingHoursId: product.delivery.openingHours?.id ?? null,
        price: product.delivery.price,
        priceIsOpen: product.delivery.priceIsOpen,
        printAt: product.delivery.printAt?.map(printer => printer.id) ?? undefined,
        reportGroupId: product.delivery.reportGroup?.id,
        secondaryPrinterIds: product.delivery.secondaryPrinters?.map(printer => printer.id) ?? undefined
      }
    }

    let eatIn: MenuProductPropertiesInput | undefined
    if (product.eatIn != null && productHasMenuType(product.eatIn) && types.includes(MenuType.EatIn)) {
      eatIn = {
        alternativeGroupIds: product.eatIn.alternativeGroups?.map(alternative => alternative.id) ?? undefined,
        description: product.eatIn.description,
        imageUrl: product.eatIn.imageUrl,
        isActive: product.eatIn.isActive,
        openingHoursId: product.eatIn.openingHours?.id ?? null,
        price: product.eatIn.price,
        priceIsOpen: product.eatIn.priceIsOpen,
        printAt: product.eatIn.printAt?.map(printer => printer.id) ?? undefined,
        reportGroupId: product.eatIn.reportGroup?.id,
        secondaryPrinterIds: product.eatIn.secondaryPrinters?.map(printer => printer.id) ?? undefined
      }
    }

    let takeAway: MenuProductPropertiesInput | undefined
    if (product.takeAway != null && productHasMenuType(product.takeAway) && types.includes(MenuType.TakeAway)) {
      takeAway = {
        alternativeGroupIds: product.takeAway.alternativeGroups?.map(alternative => alternative.id) ?? undefined,
        description: product.takeAway.description,
        imageUrl: product.takeAway.imageUrl,
        isActive: product.takeAway.isActive,
        openingHoursId: product.takeAway.openingHours?.id ?? null,
        price: product.takeAway.price,
        priceIsOpen: product.takeAway.priceIsOpen,
        printAt: product.takeAway.printAt?.map(printer => printer.id) ?? undefined,
        reportGroupId: product.takeAway.reportGroup?.id,
        secondaryPrinterIds: product.takeAway.secondaryPrinters?.map(printer => printer.id) ?? undefined
      }
    }

    return { defaultCourseId: product.defaultCourse?.id, name: product.name, quantityAvailable: product.quantityAvailable ?? undefined, delivery, eatIn, takeAway }
  }, [product, types])

  const breadcrumbs = useMemo<Breadcrumb[]>(() => {
    return [
      { link: ['MenuView', { restaurantId }], title: 'Meny' },
      { link: ['MenuPageView', { restaurantId, menuPageId: data?.restaurant?.menu?.product?.category?.page?.id }], title: data?.restaurant?.menu?.product?.category?.page?.name ?? '' }
    ]
  }, [data?.restaurant?.menu?.product?.category?.page, restaurantId, types])

  const handleRedirect = (menuPageId: string): void => {
    navigation.navigate('MenuPageView', { restaurantId, menuPageId })
  }

  const handleUpdateProduct = (patch: MenuProductInputSchema, menuCategoryId: string, menuPageId: string): void => {
    let { delivery, eatIn, name, takeAway } = patch

    if (delivery?.priceIsOpen ?? false) delivery = { ...delivery, price: null }
    if (eatIn?.priceIsOpen ?? false) eatIn = { ...eatIn, price: null }
    if (takeAway?.priceIsOpen ?? false) takeAway = { ...takeAway, price: null }

    let quantityAvailable
    if (!patch.hasQuantityAvailable && product?.quantityAvailable != null) {
      quantityAvailable = null
    } else if (patch.hasQuantityAvailable && patch.quantityAvailable !== product?.quantityAvailable) {
      quantityAvailable = patch.quantityAvailable ?? 0
    }

    if (data?.restaurant?.hasMenuWriteAccess ?? false) {
      updateMenuProduct({
        onCompleted: () => handleRedirect(menuPageId),
        refetchQueries: [
          { query: GetPageMenuDocument, variables: { restaurantId, menuPageId } },
          { query: GetPageMenuDocument, variables: { restaurantId, menuPageId: data?.restaurant?.menu?.product?.category?.page?.id } }
        ],
        variables: {
          fromMenuCategoryId: data?.restaurant?.menu?.product?.category?.id ?? '',
          menuCategoryId,
          menuProductId,
          patch: {
            defaultCourseId: patch.defaultCourseId,
            delivery,
            eatIn,
            name,
            quantityAvailable,
            takeAway
          },
          restaurantId
        }
      }).catch(logError)
    } else {
      updateMenuProductStock({
        onCompleted: () => handleRedirect(menuPageId),
        refetchQueries: [
          { query: GetPageMenuDocument, variables: { restaurantId, menuPageId } },
          { query: GetPageMenuDocument, variables: { restaurantId, menuPageId: data?.restaurant?.menu?.product?.category?.page?.id } }
        ],
        variables: {
          menuProductId,
          patch: {
            quantityAvailable
          },
          restaurantId
        }
      }).catch(logError)
    }
  }

  const doDeleteMenuProduct = ignoreAsync(async () => {
    setShowDeleteDialog(false)
    // Delete page
    await deleteMenuProduct({
      refetchQueries: [
        { query: GetPageMenuDocument, variables: { restaurantId, menuPageId: data?.restaurant?.menu?.product?.category?.page?.id } }
      ],
      variables: { restaurantId, menuProductId }
    })
    // Redirect back to page list
    handleRedirect(unwrap(data?.restaurant?.menu?.product?.category?.page?.id))
  })

  if (loading) {
    return <Layout breadcrumbs={breadcrumbs} loading title='Redigera ...' />
  }

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title={`Redigera ${data?.restaurant?.menu?.product?.name ?? '...'}`}>
      {data?.restaurant?.menu?.product?.name == null
        ? null
        : (
          <ProductForm
            error={updateMenuProductError}
            hasMenuWriteAccess={data?.restaurant?.hasMenuWriteAccess ?? false}
            initialValues={initialValues}
            menuCategoryId={unwrap(data?.restaurant?.menu?.product?.category?.id)}
            onDelete={!(data.restaurant?.hasMenuWriteAccess ?? false) ? undefined : () => setShowDeleteDialog(true)}
            onDismiss={() => handleRedirect(unwrap(data?.restaurant?.menu?.product?.category?.page?.id))}
            onSave={handleUpdateProduct}
            restaurant={data?.restaurant}
            saving={saving}
            types={types}
          />
        )}

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={doDeleteMenuProduct}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt='Vill du radera produkten?'
        title='Radera produkt'
      />
    </Layout>
  )
}

export default MenuProductEdit

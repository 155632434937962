import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { type DeliveryLocationPatch, DeliveryLocationType, ListDeliveryLocationsDocument, useDeleteDeliveryLocationMutation, useGetDeliveryLocationQuery, useUpdateDeliveryLocationMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import VerificationDialog from '../components/VerificationDialog'
import { CheckBox } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

gql`
  query GetDeliveryLocation($restaurantId: ID!, $deliveryLocationId: ID!) {
    restaurant(id: $restaurantId) {
      id

      deliveryLocation(id: $deliveryLocationId) {
        ...DeliveryLocationFields
      }
    }
  }

  mutation DeleteDeliveryLocation($restaurantId: ID!, $deliveryLocationId: ID!) {
    deleteDeliveryLocation(restaurantId: $restaurantId, deliveryLocationId: $deliveryLocationId)
  }

  mutation UpdateDeliveryLocation($restaurantId: ID!, $deliveryLocationId: ID!, $patch: DeliveryLocationPatch!) {
    updateDeliveryLocation(restaurantId: $restaurantId, deliveryLocationId: $deliveryLocationId, patch: $patch) {
      ...DeliveryLocationFields
    }
  }
`

const schema: ObjectSchema<DeliveryLocationPatch> = yup.object({
  disabled: yup.boolean().required(),
  name: yup.string().trim().min(1, 'Namn med minst ett tecken krävs').required(),
  onlyTakeAway: yup.boolean()
})

const DeliveryLocationEdit: React.FC = () => {
  const [navigation, { restaurantId, deliveryLocationId }] = useNavigation<'DeliveryLocationEdit'>()

  const { data, loading } = useGetDeliveryLocationQuery({ variables: { restaurantId, deliveryLocationId } })

  const [deleteDeliveryLocation, { error: deleteError, loading: deleting }] = useDeleteDeliveryLocationMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('DeliveryLocationList', { restaurantId }),
    refetchQueries: [{ query: ListDeliveryLocationsDocument, variables: { restaurantId } }]
  })

  const [updateDeliveryLocation, { error: updateError, loading: updating }] = useUpdateDeliveryLocationMutation({
    onCompleted: () => navigation.navigate('DeliveryLocationList', { restaurantId })
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const values = useMemo(() => {
    if (data?.restaurant?.deliveryLocation == null) return undefined

    const onlyTakeAway = data.restaurant.deliveryLocation.type === DeliveryLocationType.Table
      ? undefined
      : data.restaurant.deliveryLocation.eatInEnabled == null
      ? undefined
      : !data.restaurant.deliveryLocation.eatInEnabled

    return schema.cast({ ...data.restaurant.deliveryLocation, onlyTakeAway }, { stripUnknown: true })
  }, [data?.restaurant?.deliveryLocation])

  const form = useForm<DeliveryLocationPatch>({
    criteriaMode: 'all',
    resetOptions: { keepDirtyValues: true, keepErrors: true },
    resolver: yupResolver(schema),
    values
  })

  const handleCancel = (): void => {
    navigation.navigate('DeliveryLocationList', { restaurantId })
  }

  const handleDelete = ignoreAsync(async () => {
    await deleteDeliveryLocation({ variables: { deliveryLocationId, restaurantId } })
  })

  const handleSave = ignoreAsync(async (patch: DeliveryLocationPatch) => {
    await updateDeliveryLocation({ variables: { restaurantId, deliveryLocationId, patch } })
  })

  return (
    <Layout
      breadcrumbs={[{ link: ['DeliveryLocationList', { restaurantId }], title: 'Bord / Kassa' }]}
      hideTitle
      loading={loading}
      screenType={ScreenType.Form}
      title={`Redigera ${data?.restaurant?.deliveryLocation?.type == null ? '...' : data?.restaurant?.deliveryLocation?.type === DeliveryLocationType.Table ? 'bord' : 'kassa'}`}
    >
      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={`Radera ${data?.restaurant?.deliveryLocation?.type === DeliveryLocationType.Table ? 'bord' : 'kassa'}`}
        title='Radera?'
      />

      <FormContainer gap={24} title={`Redigera ${data?.restaurant?.deliveryLocation?.type == null ? '...' : data?.restaurant?.deliveryLocation?.type === DeliveryLocationType.Table ? 'bord' : 'kassa'}`}>
        <CheckBox
          form={form}
          name='disabled'
          title='Inaktiverad'
        />

        <TextField
          autoFocus
          form={form}
          name='name'
          title={`Namn på ${data?.restaurant?.deliveryLocation?.type === DeliveryLocationType.Table ? 'bordet' : 'kassan'}`}
        />

        {data?.restaurant?.deliveryLocation?.type === DeliveryLocationType.Table ? null : (
          <CheckBox
            form={form}
            name='onlyTakeAway'
            title='Endast ta med'
          />
        )}

        <SubmitFormButtons
          deleting={deleting}
          disableDeleteButton={form.formState.isSubmitting}
          disableSaveButton={form.formState.isSubmitting}
          error={updateError}
          onCancel={handleCancel}
          onDelete={() => setShowDeleteDialog(true)}
          onSave={ignoreAsync(form.handleSubmit(handleSave))}
          saving={updating}
        />
      </FormContainer>
    </Layout>
  )
}

export default DeliveryLocationEdit

import { MaterialIcons } from '@expo/vector-icons'
import { getDocumentAsync } from 'expo-document-picker'
import React, { useState } from 'react'
import { Controller, type UseFormReturn } from 'react-hook-form'
import { Image, View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import logError from '../../util/logError'
import openLink from '../../util/openLink'
import { DeleteButton, PrimaryButton, SecondaryButton } from '../Buttons'
import Card from '../Card'

import { type WhiteLabelFormPatch } from './WhiteLabelForm'

interface AppleGoogleServicesGuideProps {
  appName: string
  iosBundleIdentifier: string
  page: number
}

const descriptions = [
  'Gå in på Firebase 🔥🚶‍♂️',
  'Välj "Loco-3"',
  'Klicka "Add app" och sedan "Apple"',
  'Mata in "App bundle ID" och "App nickname"',
  'Klicka "Register app"',
  'Klicka "Download GoogleServices-Info.plist"',
  'Ladda upp GoogleServices-Info.plist här nedan 📂',
  'Apple Google Services är uppladdat! 🎉 '
]

const imageUris = [
  'https://locousercontent.com/XR4uKYavxlKLlTNMMAQUXf-cZEFcTFJzQFQD9qaE7x5t8IShW3wkWPoJ0ZIIvvBU/original.png',
  'https://locousercontent.com/RyMHtUwHuGaqxKhKwdla4cnLRJvOlXgOlmiCTZt3QMeHBve9aV9gdhdfP2z5X-KZ/original.png',
  'https://locousercontent.com/3F63bCBgsdY5kIptzEuNTvjEczk_WaaRbPOiUsot0yWTKKnKBmwKOCD2OfL-6eNk/original.png',
  'https://locousercontent.com/wxZ9rooYUMS5_82HAiFGreG_cwHp7rI5wA7CpdLHTQBo0zhZ_zECDY56DkW3HEvF/original.png',
  'https://locousercontent.com/nSuVM2i2YjVaMEY8Xyo3zkRoW5Ny0lhacKXjUKJsul67XjyH_9AWRj7EY6jZPpJx/original.png',
  'https://locousercontent.com/MhhIGJBVmhGAE5Qyh0_gqPjxkqHNBEkeD65RKOaPo8hdb-heLtoMtUuBkxIaDn9g/original.png'
]

const AppleGoogleServicesGuide: React.FC<AppleGoogleServicesGuideProps> = ({ appName, iosBundleIdentifier, page }) => {
  return (
    <VStack alignItems='center' grow={1} justifyContent='center'>
      <Text size={32}>Steg {page + 1}</Text>

      <Spacer height={12} />

      <Text size={24}>{descriptions[page]}</Text>

      <Spacer height={24} />

      {page !== 0 ? null : (
        <SecondaryButton
          icon='open-in-new'
          onPress={() => openLink('https://console.firebase.google.com/')}
          title='Öppna Firebase i nytt fönster'
        />
      )}

      {page !== 3
        ? null
        : (
          <>
            <Spacer height={12} />
            <Text size={16}>
              App bundle ID: <TextStyle weight='bold'>{iosBundleIdentifier}</TextStyle>
            </Text>
            <Text size={16}>
              App nickname: <TextStyle weight='bold'>{appName}</TextStyle>
            </Text>
          </>
        )}

      {page >= imageUris.length
        ? null
        : <Image source={{ uri: imageUris[page], height: 866, width: 947 }} />}

      {page !== 7
        ? null
        : (
          <>
            <Spacer height={12} />
            <Text size={16}>Klicka spara längst ner på sidan för att spara ändringarna på whitelabeln❗❗❗</Text>
          </>
        )}
    </VStack>
  )
}

const AppleGoogleServices: React.FC<{ form: UseFormReturn<WhiteLabelFormPatch> }> = ({ form }) => {
  const appName = form.watch('appName') ?? ''
  const iosBundleIdentifier = form.watch('iosBundleIdentifier') ?? ''

  const [guidePage, setGuidePage] = useState(0)

  return (
    <Card title='Apple Google Services'>
      <Controller
        control={form.control}
        name='iosGoogleServiceInfoPlist'
        render={({ field: { onChange, value } }) => {
          const maxIndex = descriptions.length - (value == null ? 2 : 1)

          const handleUpload = (): void => {
            getDocumentAsync().then(async (result) => {
              if (result.canceled) return

              const response = await fetch(result.assets[0].uri)
              const data = await response.text()

              onChange(data)
              setGuidePage(descriptions.length - 1)
            }).catch(logError)
          }

          const handleAppleGoogleServicesDelete = (): void => {
            onChange(null)
          }

          return (
            <VStack>
              <HStack justifyContent='center'>
                <HStack basis={0} grow={1} justifyContent='end' paddingRight={8}>
                  {guidePage === 0
                    ? null
                    : <PrimaryButton onPress={() => setGuidePage(page => page - 1)} title='< Tillbaka' />}
                </HStack>

                <HStack basis={0} grow={1} justifyContent='start' paddingLeft={8}>
                  {guidePage >= maxIndex
                    ? null
                    : <PrimaryButton onPress={() => setGuidePage(page => page + 1)} title='Nästa >' />}
                </HStack>
              </HStack>

              <Spacer height={24} />

              <VStack grow={1} justifyContent='center'>
                <AppleGoogleServicesGuide
                  appName={appName}
                  iosBundleIdentifier={iosBundleIdentifier}
                  page={guidePage}
                />
              </VStack>

              <Spacer height={32} />

              {guidePage !== 6
                ? null
                : (
                  <VStack alignItems='center' justifyContent='center'>
                    {value == null
                      ? (
                        <PrimaryButton
                          onPress={handleUpload}
                          title='Ladda upp Apple Google Services'
                        />
                      )
                      : (
                        <HStack alignItems='center' borderRadius={3} justifyContent='center'>
                          <View
                            style={{
                              alignItems: 'center',
                              borderWidth: 0.2,
                              borderRadius: 3,
                              flexDirection: 'row',
                              justifyContent: 'center',
                              paddingHorizontal: 24,
                              paddingVertical: 8
                            }}
                          >
                            <Text size={20}>Apple Google Services</Text>

                            <Spacer width={16} />

                            <MaterialIcons color='green' name='check' size={32} />

                            <Spacer width={16} />

                            <DeleteButton
                              icon='delete'
                              onPress={handleAppleGoogleServicesDelete}
                              title='Ta bort'
                            />
                          </View>
                        </HStack>
                      )}

                    <Spacer height={16} />
                  </VStack>
                )}
            </VStack>
          )
        }}
      />
    </Card>
  )
}

export default AppleGoogleServices

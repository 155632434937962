import unreachable from 'ts-unreachable'

import { TransactionChannel } from '../../types/graphql'

export function transactionChannelName (channel: TransactionChannel): string {
  switch (channel) {
    case TransactionChannel.DeliveryHero:
      return 'Foodora'
    case TransactionChannel.Loco:
      return 'Loco'
    case TransactionChannel.Order:
      return 'Kassasystem'
    case TransactionChannel.SelfCheckout:
      return 'Expresskassa'
    case TransactionChannel.Web:
      return 'QR/Takeaway'
    case TransactionChannel.WhiteLabel:
      return 'Beställningsapp'
    default:
      unreachable(channel)
  }
}

import { MaterialIcons } from '@expo/vector-icons'
import * as Clipboard from 'expo-clipboard'
import parseIsoDuration from 'parse-iso-duration'
import React, { useState } from 'react'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type FullDeliveryCostAreaFragment, type FullDeliveryTimeAreaFragment } from '../../types/graphql'
import formatCurrency from '../util/formatCurrency'
import logError from '../util/logError'

import Bin from './Bin'
import ListItemWrapper from './ListItemWrapper'

const MINUTE = 60 * 1000

interface DeliveryAreaListItemProps {
  data: FullDeliveryCostAreaFragment | FullDeliveryTimeAreaFragment
  onBinPress: (id: string, name: string | null) => void
}

const DeliveryAreaListItem: React.FC<DeliveryAreaListItemProps> = ({ data, onBinPress }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  let specificField: string = ''
  if ('product' in data) {
    specificField = `${data.product?.name ?? ''}, ${formatCurrency(data.product?.properties?.price ?? 0)}`
  } else if ('time' in data) {
    specificField = `${parseIsoDuration(data.time ?? 'PT0M') / MINUTE} minuter`
  } else {
    return null
  }

  const handleFileCopy = (): void => {
    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              data.geometry?.map(coordinatePair => [coordinatePair.longitude, coordinatePair.latitude])
            ]
          }
        }
      ]
    }

    Clipboard.setStringAsync(JSON.stringify(geojson, null, 2)).catch(logError)

    setCopiedToClipboard(true)
    setTimeout(() => setCopiedToClipboard(false), 3000)
  }

  return (
    <HStack>
      <ListItemWrapper>
        <HStack alignItems='center' borderRadius={4} grow={1} justifyContent='space-between' paddingHorizontal={8} paddingVertical={12}>
          <HStack>
            <Text size={16}>{data.name}</Text>
          </HStack>

          <HStack alignItems='center' gap={8} justifyContent='center'>
            <Text>{specificField}</Text>

            {copiedToClipboard
              ? (
                <HStack alignItems='center' justifyContent='center'>
                  <MaterialIcons color='green' name='file-download-done' size={24} style={{ padding: 10 }} />
                  <Text>GeoJson kopierad!</Text>
                </HStack>
              )
              : <MaterialIcons name='file-copy' onPress={handleFileCopy} size={24} />}
          </HStack>
        </HStack>
      </ListItemWrapper>

      <Spacer width={16} />

      <VStack alignItems='center' justifyContent='center'>
        <Bin
          onSelected={() => {
            onBinPress(data.id, data.name ?? null)
          }}
        />
      </VStack>
    </HStack>
  )
}

export default DeliveryAreaListItem

import gql from 'graphql-tag'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, Dimensions } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type PaletteColorInput, useGetRestaurantDesignQuery, useUpdateRestaurantDesignMutation } from '../../types/graphql'
import Layout from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import { ColorField, ImageField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullPaletteColor on PaletteColor {
    dark
    light
    main

    text {
      disabled
      primary
      secondary
    }
  }

  fragment RestaurantDesignFields on Restaurant {
    id

    name
    receiptLogoUrl
    receiptTextBottom
    receiptTextTop

    design {
      adminImage
      backgroundColor
      backgroundImage
      categoryBackgroundColor
      categoryTextColor
      headerTextColor
      menuBackgroundColor
      menuTextColor
      productOverlayColor
      restaurantHeaderImage
      restaurantPreviewImage
      textColor

      background {
        ...FullPaletteColor
      }

      error {
        ...FullPaletteColor
      }

      primary {
        ...FullPaletteColor
      }

      secondary {
        ...FullPaletteColor
      }
    }
  }

  query GetRestaurantDesign($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      ...RestaurantDesignFields
    }
  }

  mutation UpdateRestaurantDesign($restaurantId: ID!, $patch: RestaurantPatch!) {
    updateRestaurant(restaurantId: $restaurantId, patch: $patch) {
      ...RestaurantDesignFields
    }
  }
`

const ADMIN_IMAGE_RATIO = 222 / 101

const paletteColorSchema: ObjectSchema<PaletteColorInput> = yup.object({
  main: yup.string().required()
})

interface FormInput {
  design?:
    | {
      adminImage?: string | null
      backgroundColor?: string | null
      backgroundImage?: string | null
      categoryBackgroundColor?: string | null
      categoryTextColor?: string | null
      headerTextColor?: string | null
      menuBackgroundColor?: string | null
      menuTextColor?: string | null
      productOverlayColor?: string | null
      restaurantHeaderImage?: string | null
      restaurantPreviewImage?: string | null
      textColor?: string | null
      primary?: PaletteColorInput | null
      secondary?: PaletteColorInput | null
      background?: PaletteColorInput | null
      error?: PaletteColorInput | null
    }
    | null
  receiptLogoUrl?: string | null
  receiptTextBottom?: string | null
  receiptTextTop?: string | null
}

const schema: ObjectSchema<FormInput> = yup.object({
  design: yup.object({
    adminImage: yup.string().nullable(),
    backgroundColor: yup.string().nullable(),
    backgroundImage: yup.string().nullable(),
    categoryBackgroundColor: yup.string().nullable(),
    categoryTextColor: yup.string().nullable(),
    headerTextColor: yup.string().nullable(),
    menuBackgroundColor: yup.string().nullable(),
    menuTextColor: yup.string().nullable(),
    productOverlayColor: yup.string().nullable(),
    restaurantHeaderImage: yup.string().nullable(),
    restaurantPreviewImage: yup.string().nullable(),
    textColor: yup.string().nullable(),
    primary: paletteColorSchema.nullable(),
    secondary: paletteColorSchema.nullable(),
    background: paletteColorSchema.nullable(),
    error: paletteColorSchema.nullable()
  }).nullable(),
  receiptLogoUrl: yup.string().nullable(),
  receiptTextBottom: yup.string().nullable(),
  receiptTextTop: yup.string().nullable()
})

const RestaurantDesignView: React.FC = () => {
  const [, { restaurantId }] = useNavigation<'RestaurantDesignView'>()

  const { data } = useGetRestaurantDesignQuery({ variables: { restaurantId } })

  const [update, { loading }] = useUpdateRestaurantDesignMutation()

  const form = useForm<FormInput>({
    criteriaMode: 'all',
    resetOptions: { keepDirtyValues: true, keepErrors: true },
    resolver: yupResolver(schema),
    values: data?.restaurant?.design == null ? undefined : schema.cast(data?.restaurant, { stripUnknown: true })
  })

  const handleReset = useCallback(() => {
    if (data?.restaurant != null) form.reset(data?.restaurant)
  }, [data?.restaurant, form])

  const handleSubmit = useCallback(async (patch: FormInput) => {
    await update({
      onCompleted: () => form.reset(undefined, { keepDirtyValues: false }),
      variables: { restaurantId, patch }
    })
  }, [restaurantId, update])

  if (data?.restaurant == null) return <ActivityIndicator size='large' style={{ margin: 64 }} />

  const backgroundColor = form.watch('design.backgroundColor') ?? undefined
  const backgroundImageWidth = Math.min(Dimensions.get('window').width - 80, 1242 / 4)
  const dirtyCount = Object.keys(form.formState.dirtyFields).length

  return (
    <Layout title='Design'>
      <VStack maxWidth={1024}>
        <HStack gap={16} padding={16} wrap>
          <ImageField
            cropToolProps={{ maxWidth: 2000, ratio: 222 / 101 }}
            form={form}
            name='design.adminImage'
            title='Loco POS Kitchen logo'
            viewOptions={{ height: 250 / ADMIN_IMAGE_RATIO, width: 250 }}
          />

          <ImageField
            cropToolProps={{ maxWidth: 2000, ratio: 1002 / 668 }}
            form={form}
            name='design.restaurantPreviewImage'
            title='Restauranglistan'
            viewOptions={{ height: 668 / 4, width: 1002 / 4 }}
          />

          <ImageField
            backgroundColor={backgroundColor}
            cropToolProps={{ maxWidth: 2000, ratio: 1000 / 250 }}
            form={form}
            name='design.restaurantHeaderImage'
            title='Rubrik'
            viewOptions={{ height: 250 / 4, width: 1000 / 4 }}
          />

          <ImageField
            cropToolProps={{ maxWidth: 3000, ratio: 1242 / 2208 }}
            form={form}
            name='design.backgroundImage'
            title='Bakgrundsbild'
            viewOptions={{ height: backgroundImageWidth * 2208 / 1242, width: backgroundImageWidth }}
          />
        </HStack>

        <VStack gap={24} padding={16}>
          <Text size={24} weight='500'>Kvittoinställningar</Text>

          <ImageField
            cropToolProps={{ maxWidth: 1152, ratio: 1152 / 576 }}
            form={form}
            name='receiptLogoUrl'
            title='Kvittobild'
            viewOptions={{ height: 576 / 3, width: 1152 / 3 }}
          />

          <TextField
            estimatedNumberOfCharacters={30}
            form={form}
            name='receiptTextTop'
            title='Kvitto - text (överst)'
          />

          <TextField
            estimatedNumberOfCharacters={30}
            form={form}
            name='receiptTextBottom'
            title='Kvitto - text (nederst)'
          />
        </VStack>

        <Spacer height={24} />

        <Text size={24} weight='500'>Färginställningar</Text>

        <HStack gap={24} padding={16} wrap>
          <ColorField form={form} name='design.backgroundColor' opaque title='Produkt - bakgrund' />
          <ColorField form={form} name='design.textColor' title='Produkt - text' />
          <ColorField form={form} name='design.productOverlayColor' title='Produkt - överlägg' />

          <ColorField form={form} name='design.categoryBackgroundColor' opaque title='Kategori - bakgrund' />
          <ColorField form={form} name='design.categoryTextColor' title='Kategori - text' />

          <ColorField form={form} name='design.menuBackgroundColor' title='Meny - bakgrund' />
          <ColorField form={form} name='design.menuTextColor' title='Meny - text' />

          <ColorField form={form} name='design.headerTextColor' title='Rubrik - text' />
        </HStack>

        <VStack paddingHorizontal={16}>
          <Text size={24}>Primär-, sekundär-, bakgrunds- och felfärger för restaurang</Text>
          <Text size={16}>Ljusa och mörka varianter för alla dessa färger genereras automatiskt. Dessutom genereras primära, sekundära och "avaktiverade" textfärger för dessa också.</Text>

          <Spacer height={24} />

          <HStack gap={24} wrap>
            <ColorField form={form} name='design.primary.main' opaque title='Primärfärg' />
            <ColorField form={form} name='design.secondary.main' opaque title='Sekundärfärg' />
            <ColorField form={form} name='design.background.main' opaque title='Bakgrund' />
            <ColorField form={form} name='design.error.main' opaque title='Felhantering' />
          </HStack>
        </VStack>

        <Spacer height={24} />

        <SubmitFormButtons
          disableCancelButton={!form.formState.isDirty || loading}
          disableSaveButton={!form.formState.isDirty || loading}
          onCancel={handleReset}
          onSave={ignoreAsync(form.handleSubmit(handleSubmit))}
          titleForSubmit={dirtyCount === 0 ? 'Spara' : `Spara (${dirtyCount})`}
        />
      </VStack>
    </Layout>
  )
}

export default RestaurantDesignView

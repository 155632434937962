import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { HStack, Text, VStack } from 'react-stacked'

interface DisclaimerProps {
  description?: string
  title?: string
}

const Disclaimer: React.FC<DisclaimerProps> = ({ description, title }) => {
  return (
    <HStack
      alignItems='center'
      backgroundColor='#fdffe1'
      borderColor='#fff97f'
      borderRadius={24}
      borderStyle='solid'
      borderWidth={1}
      gap={12}
      paddingHorizontal={24}
      paddingVertical={16}
    >
      <MaterialIcons color='#ffe300' name='warning' size={48} />

      <VStack gap={4}>
        {title == null ? null : <Text size={18} weight='bold'>{title}</Text>}

        {description == null ? null : <Text size={16}>{description}</Text>}
      </VStack>
    </HStack>
  )
}

export default Disclaimer

import { type Temporal } from '@js-temporal/polyfill'

import { type DateTimeRangeInput } from '../../types/graphql'

import serializeZonedDateTime from './serializeZonedDateTime'

/**
 * A range of time spanning from 'start' (including) to 'end' (excluding)
 */
export interface ZonedDateTimeRange {
  end: Temporal.ZonedDateTime
  start: Temporal.ZonedDateTime
}

export default function serializeZonedDateTimeRange (range: ZonedDateTimeRange): DateTimeRangeInput {
  return { end: serializeZonedDateTime(range.end), start: serializeZonedDateTime(range.start) }
}

import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useCallback, useState } from 'react'
import { ActivityIndicator, StyleSheet, View, useWindowDimensions } from 'react-native'
import { HStack, Text, VStack } from 'react-stacked'
import WithSeparator from 'react-with-separator'
import sortOn from 'sort-on'

import { useGetRestaurantSalesReportViewDataQuery, useGetRestaurantTimezoneQuery } from '../../types/graphql'
import { PrimaryButton } from '../components/Buttons'
import DateTimeRangeSelect from '../components/DateTimeRangeSelect'
import Divider from '../components/Divider'
import Layout, { ScreenType } from '../components/Layout'
import { Cell, Row, Table } from '../components/Table'
import Warning from '../components/Warning'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'
import serializeZonedDateTimeRange from '../util/serializeZonedDateTimeRange'
import useNavigation from '../util/useNavigation'

const SMALL_SCREEN_WIDTH_THRESHOLD = 500

gql`
  query GetRestaurantSalesReportViewData ($restaurantId: ID!, $dateTimeRange: DateTimeRangeInput!) {
    restaurant(id: $restaurantId) {
      id

      name
      streetAddress
      timeZone

      organization {
        id

        name
        organizationNumber
      }

      statistics(dateTimeRange: $dateTimeRange, resolution: Day) {
        id

        totalDiscountAmount
        totalTipAmount

        refundsByPaymentName {
          id

          count
          grossAmount
          paymentName
          tipAmount
        }

        refundsByProduct {
          id

          count
          discountAmount
          grossAmount
          name
          netAmount
          price
          productId
          vatAmount

          addons {
            id

            count
            discountAmount
            grossAmount
            name
            netAmount
            price
            productId
            vatAmount
          }

          alternativeItems {
            id

            count
            name
          }
        }

        salesByMenuType {
          id

          count
          grossAmount
          menuType
          vatAmount
        }

        salesByMenuTypeAndTimePeriod {
          id

          count
          grossAmount
          menuType
          periodStart
        }

        salesByPaymentName {
          id

          count
          grossAmount
          paymentName
          tipAmount
        }

        salesByProduct {
          id

          count
          discountAmount
          grossAmount
          name
          netAmount
          price
          productId
          vatAmount

          addons {
            id

            count
            discountAmount
            grossAmount
            name
            netAmount
            price
            productId
            vatAmount
          }

          alternativeItems {
            id

            count
            name
          }
        }

        salesByReportGroup {
          id

          count
          discountAmount
          grossAmount
          netAmount
          reportGroupName
          vatAmount
        }

        salesByVatRate {
          id

          grossAmount
          netAmount
          vatAmount
          vatRate
        }
      }
    }
  }
`

const RestaurantSalesReportView: React.FC = () => {
  const [navigation, { restaurantId, dateTimeRangeEnd, dateTimeRangeStart }] = useNavigation<'RestaurantSalesReportView'>()

  const queryDateTimeRange = {
    start: Temporal.Instant.from(dateTimeRangeStart).toZonedDateTimeISO('Europe/Stockholm'),
    end: Temporal.Instant.from(dateTimeRangeEnd).toZonedDateTimeISO('Europe/Stockholm')
  }

  const { data: timeZoneData, loading: loadingTimeZone } = useGetRestaurantTimezoneQuery({ variables: { restaurantId } })
  const timeZone = timeZoneData?.restaurant?.timeZone ?? 'Europe/Stockholm'

  const [formDateTimeRange, setFormDateTimeRange] = useState(queryDateTimeRange)

  const handleSubmit = useCallback(() => {
    navigation.setParams({
      dateTimeRangeEnd: formDateTimeRange.end.toInstant().toString(),
      dateTimeRangeStart: formDateTimeRange.start.toInstant().toString()
    })
  }, [formDateTimeRange])

  const { data, error, loading } = useGetRestaurantSalesReportViewDataQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { dateTimeRange: serializeZonedDateTimeRange(queryDateTimeRange), restaurantId }
  })

  const isSmallScreen = useWindowDimensions().width < SMALL_SCREEN_WIDTH_THRESHOLD
  const textSize = isSmallScreen ? 12 : undefined

  const numberOfSales = data?.restaurant?.statistics?.salesByMenuType?.reduce((mem, curr) => mem + (curr.count ?? 0), 0) ?? 0
  const numberOfProductsSold = data?.restaurant?.statistics?.salesByProduct?.reduce((mem, curr) => mem + (curr.count ?? 0) + (curr.addons?.reduce((mem, curr) => mem + (curr.count ?? 0), 0) ?? 0), 0) ?? 0
  const totalVatAmount = data?.restaurant?.statistics?.salesByProduct?.reduce((mem, curr) => mem + (curr.vatAmount ?? 0) + (curr.addons?.reduce((mem, curr) => mem + (curr.vatAmount ?? 0), 0) ?? 0), 0) ?? 0
  const totalNetAmount = data?.restaurant?.statistics?.salesByProduct?.reduce((mem, curr) => mem + (curr.netAmount ?? 0) + (curr.addons?.reduce((mem, curr) => mem + (curr.netAmount ?? 0), 0) ?? 0), 0) ?? 0
  const totalGrossAmount = data?.restaurant?.statistics?.salesByProduct?.reduce((mem, curr) => mem + (curr.grossAmount ?? 0) + (curr.addons?.reduce((mem, curr) => mem + (curr.grossAmount ?? 0), 0) ?? 0), 0) ?? 0

  const refunds = data?.restaurant?.statistics?.refundsByProduct
  const numberOfRefunds = data?.restaurant?.statistics?.refundsByPaymentName?.reduce((mem, curr) => mem + (curr.count ?? 0), 0) ?? 0
  const numberOfProductsRefunded = refunds?.reduce((mem, curr) => mem + (curr.count ?? 0) + (curr.addons?.reduce((mem, curr) => mem + (curr.count ?? 0), 0) ?? 0), 0) ?? 0
  const totalRefundVatAmount = refunds?.reduce((mem, curr) => mem + (curr.vatAmount ?? 0) + (curr.addons?.reduce((mem, curr) => mem + (curr.vatAmount ?? 0), 0) ?? 0), 0) ?? 0
  const totalRefundNetAmount = refunds?.reduce((mem, curr) => mem + (curr.netAmount ?? 0) + (curr.addons?.reduce((mem, curr) => mem + (curr.netAmount ?? 0), 0) ?? 0), 0) ?? 0
  const totalRefundGrossAmount = refunds?.reduce((mem, curr) => mem + (curr.grossAmount ?? 0) + (curr.addons?.reduce((mem, curr) => mem + (curr.grossAmount ?? 0), 0) ?? 0), 0) ?? 0

  const reportGroups = sortOn(data?.restaurant?.statistics?.salesByReportGroup ?? [], '-grossAmount')

  return (
    <Layout
      breadcrumbs={[{ link: ['RestaurantSalesReportList', { restaurantId }], title: 'Försäljningsrapporter' }]}
      screenType={ScreenType.Statistics}
      title={queryDateTimeRange == null ? undefined : `${formatDateTime(queryDateTimeRange.start, { dateStyle: 'medium' })} - ${formatDateTime(queryDateTimeRange.end, { dateStyle: 'medium' })}`}
    >
      <VStack gap={12} maxWidth={740}>
        <DateTimeRangeSelect initialValues={queryDateTimeRange} onDateTimeRangeChange={setFormDateTimeRange} timeZone={timeZone} />

        <PrimaryButton onPress={handleSubmit} title='Visa rapport' />

        {error == null ? null : (
          <HStack alignItems='center' justifyContent='center'>
            <Warning message={error.message} paddingBottom={8} />
          </HStack>
        )}

        {loading || loadingTimeZone ? (
          <HStack alignItems='center' justifyContent='center'>
            <ActivityIndicator size='large' />
          </HStack>
        ) : (
          <View style={{ backgroundColor: '#F5F5F5', borderColor: '#DDDDDD', borderWidth: StyleSheet.hairlineWidth, padding: 8 }}>
            <WithSeparator separator={<Divider />}>
              <Text size={textSize} weight='bold'>Försäljningsrapport</Text>

              <HStack gap={8}>
                <Text size={textSize} weight='bold'>Tidsperiod:</Text>
                <Text size={textSize} weight='bold'>{queryDateTimeRange == null ? null : formatDateTime(queryDateTimeRange.start, { dateStyle: 'full', timeStyle: 'short' })}</Text>
                <Text size={textSize} weight='bold'>-</Text>
                <Text size={textSize} weight='bold'>{queryDateTimeRange == null ? null : formatDateTime(queryDateTimeRange.end, { dateStyle: 'full', timeStyle: 'short' })}</Text>
              </HStack>

              <Text size={textSize}>{data?.restaurant?.organization?.name} (Organisationsnummer {data?.restaurant?.organization?.organizationNumber})</Text>

              <Text size={textSize}>{data?.restaurant?.name} - {data?.restaurant?.streetAddress}</Text>

              <Table>
                <Row>
                  <Cell>
                    <Text size={textSize} weight='bold'>Transaktionstyp</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Antal</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Produkter</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Moms</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Netto</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Brutto</Text>
                  </Cell>
                </Row>

                <Row>
                  <Cell>
                    <Text size={textSize}>Försäljning</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{numberOfSales}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{numberOfProductsSold}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalVatAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalNetAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalGrossAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                </Row>

                <Row>
                  <Cell>
                    <Text size={textSize}>Retur</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{numberOfRefunds}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{numberOfProductsRefunded}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalRefundVatAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalRefundNetAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalRefundGrossAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                </Row>

                <Row>
                  <Cell>
                    <Text size={textSize}>Total</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{numberOfSales - numberOfRefunds}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{numberOfProductsSold - numberOfProductsRefunded}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalVatAmount + totalRefundVatAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalNetAmount + totalRefundNetAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(totalGrossAmount + totalRefundGrossAmount, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                </Row>
              </Table>

              <Table>
                <Row>
                  <Cell>
                    <Text size={textSize} weight='bold'>Totalt per rapportgrupp</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Moms</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Netto</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Brutto</Text>
                  </Cell>
                </Row>

                {reportGroups?.map((reportGroup) => (
                  <Row key={reportGroup.id}>
                    <Cell>
                      <Text size={textSize}>{reportGroup.reportGroupName}</Text>
                    </Cell>
                    <Cell>
                      <Text alignSelf='end' size={textSize}>{formatCurrency(reportGroup.vatAmount ?? 0, { minimumFractionDigits: 2 })}</Text>
                    </Cell>
                    <Cell>
                      <Text alignSelf='end' size={textSize}>{formatCurrency(reportGroup.netAmount ?? 0, { minimumFractionDigits: 2 })}</Text>
                    </Cell>
                    <Cell>
                      <Text alignSelf='end' size={textSize}>{formatCurrency(reportGroup.grossAmount ?? 0, { minimumFractionDigits: 2 })}</Text>
                    </Cell>
                  </Row>
                ))}
              </Table>

              <Table>
                <Row>
                  <Cell>
                    <Text size={textSize} weight='bold'>Totalt per momssats</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Moms</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Netto</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Brutto</Text>
                  </Cell>
                </Row>

                {sortOn(data?.restaurant?.statistics?.salesByVatRate ?? [], 'vatRate')?.map(({ id, grossAmount, netAmount, vatAmount, vatRate }) => (
                  <Row key={id}>
                    <Cell>
                      <Text size={textSize}>Mervärdesskatt {vatRate}%</Text>
                    </Cell>
                    <Cell>
                      <Text alignSelf='end' size={textSize}>{formatCurrency(vatAmount ?? 0, { minimumFractionDigits: 2 })}</Text>
                    </Cell>
                    <Cell>
                      <Text alignSelf='end' size={textSize}>{formatCurrency(netAmount ?? 0, { minimumFractionDigits: 2 })}</Text>
                    </Cell>
                    <Cell>
                      <Text alignSelf='end' size={textSize}>{formatCurrency(grossAmount ?? 0, { minimumFractionDigits: 2 })}</Text>
                    </Cell>
                  </Row>
                ))}
              </Table>

              <Table>
                <Row>
                  <Cell>
                    <Text size={textSize} weight='bold'>Händelse</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Belopp</Text>
                  </Cell>
                </Row>

                <Row>
                  <Cell>
                    <Text size={textSize}>Dricks</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(data?.restaurant?.statistics?.totalTipAmount ?? 0, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                </Row>

                <Row>
                  <Cell>
                    <Text size={textSize}>Rabatt</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize}>{formatCurrency(data?.restaurant?.statistics?.totalDiscountAmount ?? 0, { minimumFractionDigits: 2 })}</Text>
                  </Cell>
                </Row>
              </Table>

              <Table>
                <Row>
                  <Cell>
                    <Text size={textSize} weight='bold'>Betalningsmetoder</Text>
                  </Cell>
                  <Cell>
                    <Text alignSelf='end' size={textSize} weight='bold'>Belopp</Text>
                  </Cell>
                </Row>

                {sortOn(data?.restaurant?.statistics?.salesByPaymentName ?? [], 'paymentName')?.map(({ id, grossAmount, paymentName, tipAmount }) => (
                  <Row key={id}>
                    <Cell>
                      <Text size={textSize}>{paymentName}</Text>
                    </Cell>
                    <Cell>
                      <Text alignSelf='end' size={textSize}>{formatCurrency((grossAmount ?? 0) + (tipAmount ?? 0), { minimumFractionDigits: 2 })}</Text>
                    </Cell>
                  </Row>
                ))}
              </Table>
            </WithSeparator>
          </View>
        )}
      </VStack>
    </Layout>
  )
}

export default RestaurantSalesReportView

import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'

import ErrorField from './ErrorField'

function parse (value: string, previousValue?: number): number | undefined {
  if (value.trim() === '') return undefined
  const floatValue = Number.parseFloat(value)
  return Number.isNaN(floatValue) ? previousValue : floatValue
}

interface FloatFieldProps<TFieldValues extends FieldValues> {
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
  title: string
}

export default function FloatField<T extends FieldValues> (props: FloatFieldProps<T>): JSX.Element {
  const error = get(props.form.formState.errors, props.name)
  return (
    <>
      <Controller
        control={props.form.control}
        name={props.name}
        render={({ field: { onBlur, onChange, value } }) => (
          <TextField
            error={error != null}
            label={props.title}
            onBlur={onBlur}
            onChange={(ev) => onChange(parse(ev.target.value, value))}
            type='number'
            value={value}
          />
        )}
      />

      <ErrorField message={error?.message} />
    </>
  )
}

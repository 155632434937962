import type { ImageResult } from 'expo-image-manipulator'
import * as ImageManipulator from 'expo-image-manipulator'
import React, { useState } from 'react'
import Cropper, { type Area } from 'react-easy-crop'
import { Text, View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, VStack } from 'react-stacked'

import { FILE_UPLOAD_ENDPOINT } from '../lib/config'
import ignoreAsync from '../util/ignoreAsync'
import optimizeImage from '../util/optimizeImage'

import { CancelButton, PrimaryButton } from './Buttons'

type SavingImageLoadingStatus = 'processing' | 'optimizing' | 'saving'

function getButtonLabel (status: SavingImageLoadingStatus): string {
  switch (status) {
    case 'processing':
      return 'Processar'
    case 'optimizing':
      return 'Optimerar bild...'
    case 'saving':
      return 'Sparar bild...'
  }
}

export interface Props {
  backgroundColor?: string
  format?: 'png'
  image: string
  maxWidth: number
  onAction: (imgUrl: string) => void
  onCancel: () => void
  ratio: number
}

const CropTool: React.FC<Props> = ({ backgroundColor, format, image, maxWidth, ratio, onCancel, onAction }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({ x: 0, y: 0, height: 0, width: 0 })
  const [loadingStatus, setLoadingStatus] = useState<SavingImageLoadingStatus | null>(null)
  const [zoom, setZoom] = useState(1)

  const handleCropComplete = (_: unknown, croppedAreaPixels: Area): void => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const handleSaveImage = ignoreAsync(async () => {
    setLoadingStatus('processing')
    const result = await manipulateImage()
    const imageData = await fetch(result.uri).then(async res => await res.blob())
    setLoadingStatus('optimizing')
    const optimized = await optimizeImage(imageData)
    setLoadingStatus('saving')
    const imageUrl = await fetch(`${FILE_UPLOAD_ENDPOINT}${format == null ? '' : `?format=${format}`}`, { method: 'POST', body: optimized }).then(async res => await res.text())
    onAction(imageUrl)
  })

  const manipulateImage = async (): Promise<ImageResult> => {
    const actions: ImageManipulator.Action[] = []
    const isJpeg = image.startsWith('data:image/jpeg')

    actions.push({
      extent: {
        backgroundColor: isJpeg ? (backgroundColor ?? '#fff') : null,
        originX: croppedAreaPixels.x,
        originY: croppedAreaPixels.y,
        width: croppedAreaPixels.width,
        height: croppedAreaPixels.height
      }
    })

    if (croppedAreaPixels.width > maxWidth) {
      actions.push({ resize: { width: maxWidth, height: maxWidth / ratio } })
    }

    const options = isJpeg
      ? { format: ImageManipulator.SaveFormat.JPEG, compress: 0.8 }
      : { format: ImageManipulator.SaveFormat.PNG }

    return await ImageManipulator.manipulateAsync(image, actions, options)
  }

  return (
    <VStack grow={1}>
      <View style={{ backgroundColor, flexGrow: 1, minHeight: 250 }}>
        <Cropper
          aspect={ratio}
          crop={crop}
          image={image}
          minZoom={0.2}
          onCropChange={setCrop}
          onCropComplete={handleCropComplete}
          onZoomChange={setZoom}
          restrictPosition={false}
          zoom={zoom}
        />
      </View>

      <HStack justifyContent='center' paddingVertical={12}>
        <CancelButton onPress={onCancel} title='Avbryt' />

        <Spacer width={12} />

        <PrimaryButton
          loading={loadingStatus != null}
          onPress={handleSaveImage}
          title='Välj bild'
        />
      </HStack>

      {loadingStatus == null ? null : (
        <HStack alignItems='center' justifyContent='center'>
          <Text style={{ fontStyle: 'italic', fontWeight: '400' }}>{getButtonLabel(loadingStatus)}</Text>
        </HStack>
      )}
    </VStack>
  )
}

export default CropTool

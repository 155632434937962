import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Pressable } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'
import unreachable from 'ts-unreachable'

import { PhysicalPrinterIdentifyStatus, useGetRestaurantPrintersViewDataQuery, useIdentifyPhysicalPrinterMutation, useIdentifyPhysicalPrintersMutation } from '../../types/graphql'
import { AddButton, SecondaryButton } from '../components/Buttons'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import { ACCENT_COLOR } from '../lib/color'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantPrintersViewData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      physicalPrinters {
        id

        name
        identifyStatus

        printerQueues {
          id

          name
        }
      }

      printerQueues {
        id

        isLegacy
        name
      }
    }
  }

  mutation IdentifyPhysicalPrinter($restaurantId: ID!, $physicalPrinterId: ID!) {
    identifyPhysicalPrinter(restaurantId: $restaurantId, physicalPrinterId: $physicalPrinterId) {
      id

      identifyStatus
    }
  }
  mutation IdentifyPhysicalPrinters($restaurantId: ID!) {
    identifyPhysicalPrinters(restaurantId: $restaurantId) {
      id

      identifyStatus
    }
  }
`

const RestaurantPrintersView: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantPrintersView'>()

  const [pollInterval, setPollInterval] = useState(0)

  const { data, loading } = useGetRestaurantPrintersViewDataQuery({ variables: { restaurantId }, pollInterval })

  const [identifyPhysicalPrinter] = useIdentifyPhysicalPrinterMutation()

  const [identifyPhysicalPrinters] = useIdentifyPhysicalPrintersMutation()

  useEffect(() => {
    if (data?.restaurant?.physicalPrinters?.some(physicalPrinter => physicalPrinter.identifyStatus === 'Pending') ?? false) {
      setPollInterval(5000)
    } else {
      setPollInterval(0)
    }
  }, [data?.restaurant?.physicalPrinters])

  if (loading) {
    return <Layout loading />
  }

  const getPrinterStatusIcon = (physicalPrinterId: string, identifyStatus: PhysicalPrinterIdentifyStatus | null): React.ReactNode => {
    switch (identifyStatus) {
      case null:
        return (
          <MaterialIcons
            name='print'
            onPress={ignoreAsync(async () => {
              await identifyPhysicalPrinter({ variables: { restaurantId, physicalPrinterId } })
            })}
            size={24}
          />
        )
      case PhysicalPrinterIdentifyStatus.Pending:
        return <ActivityIndicator />
      case PhysicalPrinterIdentifyStatus.Success:
        return <MaterialIcons name='check' size={24} />
      case PhysicalPrinterIdentifyStatus.Failure:
        return <MaterialIcons color='red' name='error' size={24} />
      default:
        return unreachable(identifyStatus)
    }
  }

  return (
    <Layout screenType={ScreenType.Form} title='Skrivare'>
      <FormContainer gap={16} title='Skrivarköer'>
        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('RestaurantPrinterQueueCreate', { restaurantId })}
          title='Skapa ny skrivarkö'
        />

        <VStack gap={4}>
          {data?.restaurant?.printerQueues?.map((printerQueue) => (
            <HStack key={printerQueue.id} alignItems='center' backgroundColor='#f2f2f2' borderRadius={8} grow={1} padding={12}>
              <Text>{printerQueue.name}</Text>
              <Spacer grow={1} />

              {(printerQueue.isLegacy ?? false)
                ? null
                : <MaterialIcons name='edit' onPress={() => navigation.navigate('RestaurantPrinterQueueEdit', { restaurantId, printerQueueId: printerQueue.id })} size={24} />}
            </HStack>
          ))}
        </VStack>
      </FormContainer>

      <FormContainer gap={16} title='Fysiska skrivare'>
        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('RestaurantPhysicalPrinterCreate', { restaurantId })}
          title='Skapa ny fysisk skrivare'
        />

        {((data?.restaurant?.physicalPrinters?.some(physicalPrinter => physicalPrinter.identifyStatus === 'Pending')) ?? false) && (
          <HStack alignItems='center' backgroundColor={ACCENT_COLOR} borderRadius={8} gap={12} grow={1} padding={12}>
            <MaterialIcons color='white' name='info-outline' size={24} />
            <Text alignSelf='start' color='white'>Vi försöker nu att skriva ut på dessa skrivare i upp till 15 minuter. Säkerställ att skrivaren är inkopplad och papper finns i skrivaren.</Text>
          </HStack>
        )}

        <VStack gap={4}>
          {data?.restaurant?.physicalPrinters?.map((physicalPrinter) => (
            <Pressable key={physicalPrinter.id} onPress={() => navigation.navigate('RestaurantPhysicalPrinterEdit', { restaurantId, physicalPrinterId: physicalPrinter.id })}>
              <HStack alignItems='center' backgroundColor='#f2f2f2' borderRadius={8} gap={12} grow={1} padding={12}>
                <Text alignSelf='start'>{physicalPrinter.name}</Text>
                <Spacer grow={1} />
                <Text align='right'>
                  <TextStyle weight='bold'>Skrivarköer</TextStyle>
                  {'\n'}
                  {physicalPrinter.printerQueues?.map(queue => queue.name)?.join('\n')}
                </Text>
                {getPrinterStatusIcon(physicalPrinter.id, physicalPrinter.identifyStatus ?? null)}
                <MaterialIcons name='edit' size={24} />
              </HStack>
            </Pressable>
          ))}

          <SecondaryButton
            icon='print'
            onPress={ignoreAsync(async () => {
              await identifyPhysicalPrinters({ variables: { restaurantId } })
            })}
            title='Skriv ut testutskrift'
          />
        </VStack>
      </FormContainer>
    </Layout>
  )
}

export default RestaurantPrintersView

import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'

import { type EmployeeGroupInput, GetEmployeesDocument, useDeleteGroupMutation, useEditGroupMutation, useGetGroupQuery } from '../../types/graphql'
import EmployeeGroupForm from '../components/EmployeeGroupForm'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetGroup($restaurantId: ID!, $employeeGroupId: ID!) {
    restaurant(id: $restaurantId) {
      id

      employeeGroup(id: $employeeGroupId) {
        ...EmployeeGroupFields
      }
    }
  }

  mutation EditGroup($restaurantId: ID!, $employeeGroupId: ID!, $patch: EmployeeGroupPatch!) {
    updateEmployeeGroup(
      restaurantId: $restaurantId,
      employeeGroupId: $employeeGroupId,
      patch: $patch
    ) {
      ...EmployeeGroupFields
    }
  }

  mutation DeleteGroup($restaurantId: ID!, $employeeGroupId: ID!) {
    deleteEmployeeGroup(
      restaurantId: $restaurantId,
      employeeGroupId: $employeeGroupId,
    )
  }
`

const RestaurantEmployeeGroupEdit: React.FC = () => {
  const [navigation, { restaurantId, employeeGroupId }] = useNavigation<'RestaurantEmployeeGroupEdit'>()

  const [editGroup, { loading: saving }] = useEditGroupMutation({ onCompleted: () => navigation.navigate('RestaurantEmployeeList', { restaurantId }) })
  const { data, loading } = useGetGroupQuery({ variables: { restaurantId, employeeGroupId } })
  const [deleteGroup, { loading: deleting, error: deleteError }] = useDeleteGroupMutation({ onCompleted: () => navigation.navigate('RestaurantEmployeeList', { restaurantId }), awaitRefetchQueries: true, refetchQueries: [{ query: GetEmployeesDocument, variables: { restaurantId } }] })
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantEmployeeList', { restaurantId }], title: 'Anställda' }
  ], [restaurantId])

  const handleDelete = (): void => {
    setShowDeleteDialog(false)
    deleteGroup({ variables: { restaurantId, employeeGroupId } }).catch(logError)
  }

  const handleDismiss = (): void => {
    navigation.navigate('RestaurantEmployeeList', { restaurantId })
  }

  const handleSave = (patch: EmployeeGroupInput): void => {
    editGroup({ variables: { restaurantId, employeeGroupId, patch } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title='Redigera anställningsgrupp'>
      {data?.restaurant?.employeeGroup == null ? null : (
        <EmployeeGroupForm
          initialValues={data.restaurant.employeeGroup}
          onDelete={() => setShowDeleteDialog(true)}
          onDismiss={handleDismiss}
          onSave={handleSave}
          saving={saving}
        />
      )}

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt='Vill du radera anställningsgruppen?'
        title='Radera anställningsgrupp'
      />
    </Layout>
  )
}

export default RestaurantEmployeeGroupEdit

import gql from 'graphql-tag'
import React from 'react'
import { Pressable } from 'react-native'
import { HStack, Text, TextStyle } from 'react-stacked'
import sortOn from 'sort-on'

import { useGetWhiteLabelUserListDataQuery } from '../../types/graphql'
import { AddButton } from '../components/Buttons'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import useNavigation from '../util/useNavigation'

gql`
  query GetWhiteLabelUserListData($whiteLabelId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      users {
        id

        email
        isOwner
        name
      }
    }
  }
`

const WhiteLabelUserList: React.FC = () => {
  const [navigation, { whiteLabelId }] = useNavigation<'WhiteLabelUserList'>()

  const { data, loading } = useGetWhiteLabelUserListDataQuery({ variables: { whiteLabelId } })

  return (
    <Layout hideTitle loading={loading} screenType={ScreenType.List} title='Administratör'>
      <ListContainer title='Administratör'>
        <AddButton
          onPress={() => navigation.navigate('WhiteLabelUserCreate', { whiteLabelId })}
          title='Lägg till ny administratör'
        />

        {sortOn(data?.whiteLabel?.users ?? [], ['isOwner', 'name']).map(({ id, email, isOwner, name }) => (
          <Pressable key={id} onPress={() => navigation.navigate('WhiteLabelUserEdit', { whiteLabelId, whiteLabelUserId: id })}>
            <HStack backgroundColor='#fefefe' borderRadius={5} gap={12} justifyContent='space-between' padding={16}>
              <Text size={20}>
                {name}

                {!(isOwner ?? false)
                  ? null
                  : <TextStyle color='#999' size={20}>{' '}(ägare)</TextStyle>}
              </Text>

              <Text size={20}>{email}</Text>
            </HStack>
          </Pressable>
        ))}
      </ListContainer>
    </Layout>
  )
}

export default WhiteLabelUserList

import gql from 'graphql-tag'
import React, { useState } from 'react'

import { type DiscountPatch, GetWhiteLabelDiscountListDataDocument, useDeleteWhiteLabelDiscountMutation, useEditWhiteLabelDiscountMutation, useGetWhiteLabelDiscountEditDataQuery } from '../../types/graphql'
import DiscountEditForm from '../components/DiscountEditForm'
import Layout, { ScreenType } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import logAndShowError from '../util/logAndShowError'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation DeleteWhiteLabelDiscount($whiteLabelId: ID!, $discountId: ID!) {
    deleteWhiteLabelDiscount(whiteLabelId: $whiteLabelId, discountId: $discountId)
  }

  mutation EditWhiteLabelDiscount($whiteLabelId: ID!, $discountId: ID!, $patch: DiscountPatch!) {
    updateWhiteLabelDiscount(whiteLabelId: $whiteLabelId, discountId: $discountId, patch: $patch) {
      id
    }
  }

  query GetWhiteLabelDiscountEditData ($whiteLabelId: ID!, $discountId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      createdAt
      updatedAt

      discount (id: $discountId) {
        ...FullDiscount
      }

      guestGroups(first: 1000) {
        edges {
          node {
            id

            name
          }
        }
      }

      restaurants(first: 1000) {
        edges {
          node {
            id

            name
          }
        }
      }
    }
  }
`

const WhiteLabelDiscountEdit: React.FC = () => {
  const [navigation, { whiteLabelId, discountId }] = useNavigation<'WhiteLabelDiscountEdit'>()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleCancel = (): void => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('WhiteLabelDiscountList', { whiteLabelId })
  }

  const { data, loading } = useGetWhiteLabelDiscountEditDataQuery({ variables: { whiteLabelId, discountId } })

  const guestGroups = data?.whiteLabel?.guestGroups?.edges ?? []
  const restaurants = data?.whiteLabel?.restaurants?.edges ?? []

  const [deleteDiscount, { error: deleteError, loading: deleting }] = useDeleteWhiteLabelDiscountMutation({ onCompleted: handleCancel })
  const [editWhiteLabelDiscount, { error: saveError, loading: saving }] = useEditWhiteLabelDiscountMutation({ onCompleted: handleCancel, onError: logAndShowError })

  const handleDelete = (): void => {
    deleteDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetWhiteLabelDiscountListDataDocument, variables: { id: whiteLabelId } }],
      variables: { whiteLabelId, discountId }
    }).catch(logAndShowError)
  }

  const handleCreate = (patch: DiscountPatch): void => {
    editWhiteLabelDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetWhiteLabelDiscountListDataDocument, variables: { id: whiteLabelId } }],
      variables: { whiteLabelId, discountId, patch }
    }).catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' },
        { link: ['WhiteLabelDiscountList', { whiteLabelId }], title: 'Rabatter' }
      ]}
      loading={loading}
      screenType={ScreenType.Form}
      title='Redigera rabatt'
    >
      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={`Är du säker på att du vill ta bort ${data?.whiteLabel?.discount?.title ?? `rabatten med kod ${data?.whiteLabel?.discount?.code ?? ''}`}?`}
        title='Ta bort rabatt'
      />

      {data?.whiteLabel?.discount == null ? null : (
        <DiscountEditForm
          discount={data.whiteLabel.discount}
          error={saveError}
          guestGroupOptions={guestGroups.map(guestGroup => ({ title: guestGroup.node.name ?? 'n/a', value: guestGroup.node.id })) ?? []}
          onCancel={handleCancel}
          onDelete={() => setShowDeleteDialog(true)}
          onSave={handleCreate}
          restaurantOptions={restaurants.map(restaurant => ({ title: restaurant.node.name ?? 'n/a', value: restaurant.node.id })) ?? []}
          saving={saving}
        />
      )}
    </Layout>
  )
}

export default WhiteLabelDiscountEdit

import React, { useCallback } from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'

import { ERROR_COLOR, INPUT_BORDER_COLOR, TEXT_COLOR } from '../lib/color'

import FormControl from './FormControl'
import { characterWidth } from './TextField'

const NULL_TOKEN = '4c3e936d-cdb7-4bcc-a75d-9bb3d62abcc2'

const selectStyle: React.CSSProperties = {
  // From gov.uk
  backgroundColor: '#fff',
  boxSizing: 'border-box',
  color: TEXT_COLOR,
  lineHeight: 1.25,
  maxWidth: '100%',
  MozOsxFontSmoothing: 'grayscale',
  padding: 5,
  WebkitFontSmoothing: 'antialiased',

  // Loco
  borderColor: INPUT_BORDER_COLOR,
  borderRadius: 4,
  borderWidth: 1,
  fontSize: 18,
  minHeight: 40
}

const errorSelectStyle: React.CSSProperties = {
  ...selectStyle,
  borderColor: ERROR_COLOR
}

interface UncontrolledSelectProps<TFieldValues extends FieldValues> {
  /**
   * The Boolean disabled attribute, when present, makes the element not mutable or focusable. The user can neither edit nor focus on the control.
   */
  disabled?: boolean
  /**
   *  The estimated number of characters is used to estimate the width of the text field. This is useful when the text field is used in a form with multiple inputs. As per the GOV.UK Design System, the width of the text field will be adjusted based on the estimated number of characters that you think will be used in the field.
   */
  estimatedNumberOfCharacters?: number
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  /**
   *  Hints are used to help users understand what is expected of them when filling in a form field. The hint will be displayed below the title and above the input, in gray.
   */
  hint?: string
  name: Path<TFieldValues>
  /**
   * The options prop is used to specify the options of the select.
   */
  options: Array<{ value: string | null, title: string }>
  /**
   *  The title is used to describe the input.
   */
  title: string
}

interface ControlledSelectProps<T extends null | string> {
  /**
   * The Boolean disabled attribute, when present, makes the element not mutable or focusable. The user can neither edit nor focus on the control.
   */
  disabled?: boolean
  /**
   *  The estimated number of characters is used to estimate the width of the text field. This is useful when the text field is used in a form with multiple inputs. As per the GOV.UK Design System, the width of the text field will be adjusted based on the estimated number of characters that you think will be used in the field.
   */
  estimatedNumberOfCharacters?: number
  errorMessage?: string
  /**
   *  Hints are used to help users understand what is expected of them when filling in a form field. The hint will be displayed below the title and above the input, in gray.
   */
  hint?: string
  /**
   *  The onBlur prop is used to handle when the user has finished editing the input.
   */
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
  /**
   *  The onChange prop is used to handle when the user has changed the input.
   */
  onChange: (value: T) => void
  /**
   * The options prop is used to specify the options of the select.
   */
  options: Array<{ value: T, title: string }>
  /**
   *  The title is used to describe the input.
   */
  title: string
  /**
   *  The value prop is used to set the value of the input.
   */
  value?: T | null
}

export default function Select<TFieldValues extends FieldValues, TValue extends null | string> (props: UncontrolledSelectProps<TFieldValues> | ControlledSelectProps<TValue>): JSX.Element {
  if ('form' in props) {
    const errorMessage = get(props.form.formState.errors, props.name)?.message

    return (
      <Controller
        control={props.form.control}
        name={props.name}
        render={({ field: { onBlur, onChange, value } }) => (
          <Select
            disabled={props.disabled ?? false}
            errorMessage={errorMessage}
            estimatedNumberOfCharacters={props.estimatedNumberOfCharacters}
            hint={props.hint}
            onBlur={onBlur}
            onChange={onChange}
            options={props.options}
            title={props.title}
            value={value}
          />
        )}
      />
    )
  }

  const hasNullOption = props.options.some(option => option.value === null)
  const hasEmptyStringOption = props.options.some(option => option.value === '')

  const handleChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange((ev.target.value === NULL_TOKEN ? null : ev.target.value) as TValue)
  }, [props.onChange])

  return (
    <FormControl
      errorMessage={props.errorMessage}
      hint={props.hint}
      title={props.title}
    >
      <select
        disabled={props.disabled ?? false}
        onBlur={props.onBlur}
        onChange={handleChange}
        style={{
          ...(props.errorMessage == null ? selectStyle : errorSelectStyle),
          minWidth: props.estimatedNumberOfCharacters == null ? '11.5em' : undefined,
          width: props.estimatedNumberOfCharacters == null ? 'auto' : characterWidth(props.estimatedNumberOfCharacters)
        }}
        value={props.value ?? NULL_TOKEN}
      >
        {props.value == null && !hasNullOption ? <option value={NULL_TOKEN} /> : null}
        {props.value === '' && !hasEmptyStringOption ? <option value='' /> : null}
        {props.options.map(option => <option key={option.value ?? NULL_TOKEN} value={option.value ?? NULL_TOKEN}>{option.title}</option>)}
      </select>
    </FormControl>
  )
}

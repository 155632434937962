import sortOn from 'sort-on'

import { type FullPaymentMethodFragment, PaymentProvider } from '../../types/graphql'

export default function extractSupportedTillPaymentMethods (paymentMethods: readonly FullPaymentMethodFragment[] | null | undefined): FullPaymentMethodFragment[] {
  const paymentProviderFilter = ({ paymentProvider }: { paymentProvider?: PaymentProvider | null }): boolean => (
    paymentProvider === PaymentProvider.Cash ||
    paymentProvider === PaymentProvider.External ||
    paymentProvider === PaymentProvider.NetsConnectCloud ||
    paymentProvider === PaymentProvider.PosTerminal ||
    paymentProvider === PaymentProvider.Swish
  )

  return sortOn(paymentMethods ?? [], 'name').filter(paymentProviderFilter)
}

import { type Temporal } from '@js-temporal/polyfill'

const cache = new Map<string, Intl.DateTimeFormat>()

type Format = Pick<Intl.DateTimeFormatOptions, 'dateStyle' | 'timeStyle'>

function getFormatter (format: Format, timeZone: string): Intl.DateTimeFormat {
  const key = `${format.dateStyle ?? '-'} ${format.timeStyle ?? '-'} ${timeZone}`

  let result = cache.get(key)

  if (result == null) {
    result = new Intl.DateTimeFormat('sv-SE', { ...format, timeZone })
    cache.set(key, result)
  }

  return result
}

/**
 * Format a date and time using the specified format and time zone.
 *
 * Currently hard-coded to the sv-SE locale.
 *
 * `dateStyle`:
 * - `full`: "tisdag 15 november 2022"
 * - `long`: "15 november 2022"
 * - `medium`: "15 nov. 2022"
 * - `short`: "2022-11-15"
 *
 * `timeStyle`:
 * - `full`: "kl. 13:37:00 centraleuropeisk normaltid"
 * - `long`: "13:37:00 CET"
 * - `medium`: "13:37:00"
 * - `short`: "13:37"
 */
export default function formatDateTime (date: Temporal.ZonedDateTime, format: Format): string {
  return getFormatter(format, date.timeZoneId.toString()).format(new Date(date.epochMilliseconds))
}

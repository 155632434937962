import unreachable from 'ts-unreachable'

import { WeekDay } from '../../types/graphql'

export default function nextDay (weekDay: WeekDay): WeekDay {
  switch (weekDay) {
    case WeekDay.Monday:
      return WeekDay.Tuesday
    case WeekDay.Tuesday:
      return WeekDay.Wednesday
    case WeekDay.Wednesday:
      return WeekDay.Thursday
    case WeekDay.Thursday:
      return WeekDay.Friday
    case WeekDay.Friday:
      return WeekDay.Saturday
    case WeekDay.Saturday:
      return WeekDay.Sunday
    case WeekDay.Sunday:
      return WeekDay.Monday
    default:
      unreachable(weekDay)
  }
}

import gql from 'graphql-tag'
import React, { useCallback } from 'react'

import { type DiscountInput, GetMetaOrganizationDocument, useCreateDiscountMutation, useGetMetaOrganizationQuery } from '../../types/graphql'
import DiscountCreateForm from '../components/DiscountCreateForm'
import Layout, { ScreenType } from '../components/Layout'
import logAndShowError from '../util/logAndShowError'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateDiscount($metaOrganizationId: ID!, $input: DiscountInput!) {
    createDiscount(metaOrganizationId: $metaOrganizationId, input: $input) {
      id
    }
  }
`

const MetaOrganizationDiscountCreate: React.FC = () => {
  const [navigation, { metaOrganizationId }] = useNavigation<'MetaOrganizationDiscountCreate'>()

  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('MetaOrganizationView', { metaOrganizationId })
  }, [navigation])

  const { data } = useGetMetaOrganizationQuery({ variables: { id: metaOrganizationId } })
  const [createDiscount, { error: createError, loading: creating }] = useCreateDiscountMutation({ onCompleted: handleCancel, onError: logAndShowError })

  const handleCreate = useCallback((input: DiscountInput) => {
    createDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetMetaOrganizationDocument, variables: { id: metaOrganizationId } }],
      variables: { metaOrganizationId, input }
    }).catch(logError)
  }, [createDiscount, metaOrganizationId, navigation])

  return (
    <Layout
      breadcrumbs={[
        { link: ['MetaOrganizationList', {}], title: 'Metaorganisationer' },
        { link: ['MetaOrganizationView', { metaOrganizationId }], title: data?.metaOrganization?.name ?? '...' }
      ]}
      screenType={ScreenType.Form}
      title='Skapa rabatt'
    >
      <DiscountCreateForm
        error={createError}
        onCancel={handleCancel}
        onSave={handleCreate}
        saving={creating}
      />
    </Layout>
  )
}

export default MetaOrganizationDiscountCreate

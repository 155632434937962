import gql from 'graphql-tag'
import React, { useMemo } from 'react'

import { type EmployeeGroupInput, GetEmployeesDocument, useCreateGroupMutation } from '../../types/graphql'
import EmployeeGroupForm from '../components/EmployeeGroupForm'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateGroup($restaurantId: ID!, $input: EmployeeGroupInput!) {
    createEmployeeGroup(
      restaurantId: $restaurantId,
      input: $input
    ) {
      ...EmployeeGroupFields
    }
  }
`

const RestaurantEmployeeGroupCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantEmployeeGroupCreate'>()

  const [createGroup, { loading: saving }] = useCreateGroupMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('RestaurantEmployeeList', { restaurantId }),
    refetchQueries: [{ query: GetEmployeesDocument, variables: { restaurantId } }]
  })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantEmployeeList', { restaurantId }], title: 'Anställda' }
  ], [restaurantId])

  const handleDismiss = (): void => {
    navigation.navigate('RestaurantEmployeeList', { restaurantId })
  }

  const handleSave = (input: EmployeeGroupInput): void => {
    createGroup({ variables: { restaurantId, input } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title='Skapa anställningsgrupp'>
      <EmployeeGroupForm
        onDismiss={handleDismiss}
        onSave={handleSave}
        saving={saving}
      />
    </Layout>
  )
}

export default RestaurantEmployeeGroupCreate

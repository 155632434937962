import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle } from 'react-stacked'

import { type PosTerminalPatch, useGetPosTerminalQuery, useUpdatePosTerminalMutation } from '../../types/graphql'
import Badge from '../components/Badge'
import FormContainer from '../components/FormContainer'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import { CheckBox } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import formatDateTime from '../util/formatDateTime'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetPosTerminal($restaurantId: ID!, $posTerminalId: ID!) {
    restaurant (id: $restaurantId) {
      id

      posTerminal (id: $posTerminalId) {
        ...FullPosTerminal
      }
    }
  }

  mutation UpdatePosTerminal($restaurantId: ID!, $posTerminalId: ID!, $patch: PosTerminalPatch!) {
    updatePosTerminal(
      restaurantId: $restaurantId,
      posTerminalId: $posTerminalId,
      patch: $patch
    ) {
      ...FullPosTerminal
    }
  }
`

const schema: ObjectSchema<PosTerminalPatch> = yup.object({
  description: yup.string().nullable(),
  isActive: yup.boolean(),
  name: yup.string()
})

const RestaurantPosTerminalEdit: React.FC = () => {
  const [navigation, { restaurantId, posTerminalId }] = useNavigation<'RestaurantPosTerminalEdit'>()

  const { data, loading } = useGetPosTerminalQuery({ variables: { restaurantId, posTerminalId } })
  const [updatePosTerminal, { loading: saving }] = useUpdatePosTerminalMutation({
    onCompleted: () => navigation.navigate('RestaurantTillList', { restaurantId })
  })

  const form = useForm<PosTerminalPatch>({
    criteriaMode: 'all',
    resetOptions: { keepDirtyValues: true, keepErrors: true },
    resolver: yupResolver(schema),
    values: schema.cast(data?.restaurant?.posTerminal, { stripUnknown: true })
  })

  const posTerminal = data?.restaurant?.posTerminal
  const name = posTerminal?.name ?? posTerminal?.externalId
  const isOnline = posTerminal?.isOnline ?? false

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantTillList', { restaurantId }], title: 'Kassor för POS' }
  ], [restaurantId])

  const handleCancel = (): void => {
    navigation.navigate('RestaurantTillList', { restaurantId })
  }

  const handleUpdate = (patch: PosTerminalPatch): void => {
    updatePosTerminal({
      variables: {
        restaurantId,
        posTerminalId,
        patch
      }
    }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} hideTitle loading={loading} screenType={ScreenType.Form} title={`Redigera kortterminal ${name ?? posTerminal?.externalId ?? '...'}`}>
      <FormContainer gap={16} title={`Redigera kortterminal ${name ?? posTerminal?.externalId ?? '...'}`}>
        <TextField
          form={form}
          name='name'
          title='Namn'
        />

        <Text paddingHorizontal={10} weight='500'>
          Terminalid: <TextStyle weight='normal'>{posTerminal?.externalId ?? 'Ej tillgängligt'}</TextStyle>
        </Text>

        <Text paddingHorizontal={10} weight='500'>
          Terminaltyp: <TextStyle weight='normal'>{posTerminal?.paymentProvider ?? 'Ej tillgänglig'}</TextStyle>
        </Text>

        <TextField
          form={form}
          name='description'
          title='Beskrivning'
        />

        <CheckBox
          form={form}
          name='isActive'
          title='Aktiv (visas i kassan)'
        />

        <HStack alignItems='center' paddingHorizontal={10}>
          <Text>Onlinestatus:</Text>

          <Spacer width={12} />

          <Badge status={isOnline ? 'success' : 'error'} />

          <Spacer width={4} />

          <Text size={12}>{isOnline ? 'Ansluten' : 'Ej ansluten'}</Text>
        </HStack>

        {isOnline ? null : (
          <HStack alignItems='center' gap={12}>
            <Text>Senast sedd:</Text>
            <Text>{posTerminal?.lastOnlineAt == null ? 'Aldrig' : formatDateTime(Temporal.Instant.from(posTerminal.lastOnlineAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}</Text>
          </HStack>
        )}

        <SubmitFormButtons
          disableSaveButton={!form.formState.isDirty || saving}
          onCancel={handleCancel}
          onSave={ignoreAsync(form.handleSubmit(handleUpdate))}
          saving={saving}
        />
      </FormContainer>
    </Layout>
  )
}

export default RestaurantPosTerminalEdit

import React, { type ReactElement, useCallback } from 'react'
import { DragDropContext, Draggable, type DraggableProvided, type DraggableStateSnapshot, type DropResult, Droppable, type DroppableProvided, type DroppableStateSnapshot } from 'react-beautiful-dnd'
import Spacer from 'react-spacer'
import WithSeparator from 'react-with-separator'

import DragHandle from './DragHandle'

export interface DraggableListProps<T> {
  data: readonly T[]
  disabled?: boolean
  hideItem?: (item: T) => boolean
  keyExtractor: (item: T) => string
  onDragEnd: ((rearrangedData: T[]) => void) | undefined
  renderItem: (dragHandle: JSX.Element, item: T) => JSX.Element | null
  separator?: ReactElement<any> | string
}

function DraggableList<T> ({ data, disabled, hideItem, keyExtractor, onDragEnd, renderItem, separator }: DraggableListProps<T>): JSX.Element {
  const handleDragEnd = useCallback((result: DropResult) => {
    if (result.destination == null) return

    // Move item to its new destination
    const rearrangedData = data.slice()
    const [item] = rearrangedData.splice(result.source.index, 1)
    rearrangedData.splice(result.destination.index, 0, item)

    onDragEnd?.(rearrangedData)
  }, [data, onDragEnd])

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='droppableId'>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ paddingBottom: snapshot.isDraggingOver ? 4 : 0, width: '100%' }}
          >
            <WithSeparator leading separator={separator ?? <Spacer height={8} />}>
              {data.map((item, index) => {
                if (hideItem?.(item) ?? false) return null

                const key = keyExtractor(item)
                return (
                  <Draggable key={key} draggableId={key} index={index}>
                    {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        style={{ ...providedDraggable.draggableProps.style, backgroundColor: snapshotDraggable.isDragging ? '#D3D3D3' : '#FFFFFF' }}
                      >
                        {renderItem(
                          <div {...providedDraggable.dragHandleProps}>
                            {(disabled ?? false) ? null : <DragHandle />}
                          </div>,
                          item
                        )}
                      </div>
                    )}
                  </Draggable>
                )
              })}
            </WithSeparator>

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableList

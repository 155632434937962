import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'
import { Pressable } from 'react-native'
import { HStack, Text, VStack } from 'react-stacked'

import { ACCENT_COLOR } from '../../lib/color'

import ErrorField from './ErrorField'

interface UncontrolledCheckBoxProps<TFieldValues extends FieldValues> {
  backgroundColor?: string
  checkedColor?: string
  disabled?: boolean | null
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
  textColor?: string
  title?: string
  uncheckedColor?: string
}

interface ControlledCheckBoxProps {
  backgroundColor?: string
  checked: boolean
  checkedColor?: string
  disabled?: boolean
  onPress?: () => void
  textColor?: string
  title?: string
  uncheckedColor?: string
}

export default function CheckBox<T extends FieldValues> (props: UncontrolledCheckBoxProps<T> | ControlledCheckBoxProps): JSX.Element {
  if ('form' in props) {
    return (
      <VStack>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onChange, value } }) => (
            <CheckBox
              backgroundColor={props.backgroundColor}
              /* @ts-expect-error TypeScript doesn't understand that the below props are ControlledCheckBoxProps */
              checked={value as boolean}
              checkedColor={props.checkedColor}
              disabled={props.disabled}
              onPress={() => onChange(!(value as boolean))}
              textColor={props.textColor}
              title={props.title}
              uncheckedColor={props.uncheckedColor}
            />
          )}
        />

        <ErrorField message={get(props.form.formState.errors, props.name)?.message} />
      </VStack>
    )
  }

  return (
    <Pressable accessibilityRole='checkbox' accessibilityState={{ checked: props.checked, disabled: (props.disabled ?? false) }} onPress={(props.disabled ?? false) ? undefined : props.onPress}>
      <HStack alignItems='center' backgroundColor={props.backgroundColor ?? 'white'} borderRadius={4} gap={12} padding={12}>
        <MaterialIcons
          color={(props.disabled ?? false) ? '#99A1A8' : props.checked ? (props.checkedColor ?? ACCENT_COLOR) : (props.uncheckedColor ?? '#bfbfbf')}
          name={props.checked ? 'check-box' : 'check-box-outline-blank'}
          size={24}
        />

        <Text color={props.textColor} weight='bold'>{props.title}</Text>
      </HStack>
    </Pressable>
  )
}

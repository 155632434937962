import { MaterialIcons } from '@expo/vector-icons'
import React, { type ReactElement } from 'react'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'
import sortOn from 'sort-on'
import unreachable from 'ts-unreachable'

import downloadCsvAsFile from '../util/downloadCsvAsFile'
import formatCurrency from '../util/formatCurrency'

import { SaveAsButton } from './Buttons'
import Divider from './Divider'
import { Cell, Column, Row, Table } from './Table'

function formatForCsv (column: DataColumnProps<any>, data: any): string | number {
  switch (column.type) {
    case 'currency':
      return data / 100
    case 'number':
      return data
    case 'string':
      return data
    default:
      unreachable(column.type)
  }
}

function formatForTable (column: DataColumnProps<any>, data: any): string {
  switch (column.type) {
    case 'currency':
      return formatCurrency(data, { maximumFractionDigits: column.maximumFractionDigits, minimumFractionDigits: column.minimumFractionDigits })
    case 'number':
      return data.toString()
    case 'string':
      return data
    default:
      unreachable(column.type)
  }
}

function fontVariantForType (type: DataColumnProps<any>['type']): 'tabular-nums' | undefined {
  switch (type) {
    case 'currency':
      return 'tabular-nums'
    case 'number':
      return 'tabular-nums'
    case 'string':
      return undefined
    default:
      unreachable(type)
  }
}

const SortIcon: React.FC<{ field: string, sort: string }> = ({ field, sort }) => {
  if (sort === field) return <MaterialIcons name='keyboard-arrow-up' size={24} />
  if (sort === `-${field}`) return <MaterialIcons name='keyboard-arrow-down' size={24} />

  return <MaterialIcons color='#aaa' name='keyboard-arrow-right' size={24} />
}

interface DataColumnProps<T extends { id: string }> {
  field: (keyof T) & string
  grow?: number
  hidden?: boolean
  maximumFractionDigits?: 0
  minimumFractionDigits?: 2
  title: string
  type: 'currency' | 'number' | 'string'
}

export function DataColumn<T extends { id: string }> (_: DataColumnProps<T>): null {
  return null
}

type Sort<T> = ((keyof T) & string) | `-${(keyof T) & string}`

interface DataTableProps<T extends { id: string }> {
  children: ReactElement<DataColumnProps<T>> | Array<ReactElement<DataColumnProps<T>>>
  csvFileName: string
  data: readonly T[]
  figure?: ReactElement | null
  hideSummaryRow?: boolean
  initialSort: Sort<T>
  onDataRowPress?: (data: T) => void
  title: string
}

export function DataTable<T extends { id: string }> (props: DataTableProps<T>): ReactElement {
  const columns = React.Children.toArray(props.children) as Array<ReactElement<DataColumnProps<T>>>
  const [sort, setSort] = React.useState<Sort<T>>(props.initialSort)

  const sortedData = React.useMemo(() => {
    return sortOn(props.data, [sort, 'id'], { locales: 'sv-SE', localeOptions: { numeric: true, sensitivity: 'accent' } })
  }, [props.data, sort])

  const summary = React.useMemo<T>(() => {
    const partialSummary: Partial<T> = {}

    for (const column of columns) {
      switch (column.props.type) {
        case 'currency':
          // @ts-expect-error TypeScript cannot understand that we now the field type here
          partialSummary[column.props.field] = 0
          break
        case 'number':
          // @ts-expect-error TypeScript cannot understand that we now the field type here
          partialSummary[column.props.field] = 0
          break
        case 'string':
          // @ts-expect-error TypeScript cannot understand that we now the field type here
          partialSummary[column.props.field] = 'Summa'
          break
      }
    }

    const summary = partialSummary as T

    for (const data of props.data) {
      for (const column of columns) {
        switch (column.props.type) {
          case 'currency':
            // @ts-expect-error TypeScript cannot understand that we now the field type here
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            summary[column.props.field] += data[column.props.field]
            break
          case 'number':
            // @ts-expect-error TypeScript cannot understand that we now the field type here
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            summary[column.props.field] += data[column.props.field]
            break
          case 'string':
            break
        }
      }
    }

    return summary
  }, [props.data])

  const createHandleSortPress = (column: DataColumnProps<T>): () => void => {
    const asc = column.field
    const desc = `-${column.field}` as const

    switch (column.type) {
      case 'currency':
        return () => setSort(sort === desc ? asc : desc)
      case 'number':
        return () => setSort(sort === desc ? asc : desc)
      case 'string':
        return () => setSort(sort === asc ? desc : asc)
      default:
        return unreachable(column.type)
    }
  }

  const handleCsvPress = (): void => {
    downloadCsvAsFile(
      props.csvFileName,
      sortedData.map(row => {
        const out: Record<string, string | number> = {}

        for (const column of columns) {
          out[column.props.title] = formatForCsv(column.props, row[column.props.field])
        }

        return out
      })
    )
  }

  return (
    <>
      <HStack alignItems='baseline' justifyContent='space-between'>
        <Text size={18} weight='bold'>{props.title}</Text>

        {props.data.length === 0
          ? null
          : <SaveAsButton onPress={handleCsvPress} title='Spara som CSV' />}
      </HStack>

      <Spacer height={8} />

      {props.figure}

      <Table>
        {columns.map(column => (
          <Column
            key={column.key}
            align={column.props.type === 'string' ? 'start' : 'end'}
            flexGrow={column.props.grow}
            hidden={column.props.hidden}
            paddingHorizontal={4}
          />
        ))}

        <Row paddingVertical={2}>
          {columns.map((column) => (
            <Cell key={column.key} flexGrow={column.props.grow} onPress={createHandleSortPress(column.props)}>
              <HStack alignItems='center'>
                <SortIcon field={column.props.field} sort={sort} />

                <Text>{column.props.title}</Text>
              </HStack>
            </Cell>
          ))}
        </Row>

        {sortedData.map((item) => (
          <Row key={item.id} onPress={props.onDataRowPress == null ? undefined : () => props.onDataRowPress?.(item)} paddingVertical={2}>
            {columns.map((column) => (
              <Cell key={column.key} flexGrow={column.props.grow}>
                <Text variant={fontVariantForType(column.props.type)}>{formatForTable(column.props, item[column.props.field])}</Text>
              </Cell>
            ))}
          </Row>
        ))}

        <Divider />

        {(props.hideSummaryRow ?? false) ? null : (
          <Row backgroundColor='transparent'>
            {columns.map((column) => (
              <Cell key={column.key} flexGrow={column.props.grow}>
                <Text>{formatForTable(column.props, summary[column.props.field])}</Text>
              </Cell>
            ))}
          </Row>
        )}
      </Table>
    </>
  )
}

import { type ApolloError } from '@apollo/client'

import logError from './logError'
import showAlert from './showAlert'

function extractErrorMessage (error: ApolloError): string {
  const state = error.graphQLErrors[0]?.extensions?.state
  // FIXME: This is not a nice error message to get!
  if (state != null) return JSON.stringify(state, null, 2)

  return error.message
}

export default function logAndShowError (error: ApolloError): void {
  logError(error)
  showAlert('Något gick tyvärr fel!', extractErrorMessage(error))
}

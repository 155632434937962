import gql from 'graphql-tag'
import React, { useState } from 'react'

import { useSetPageTemporarilyDisabledMutation } from '../../../types/graphql'
import logError from '../../util/logError'
import { PanicButton } from '../PanicButton'
import VerificationDialog from '../VerificationDialog'

gql`
  mutation SetPageTemporarilyDisabled($menuPageId: ID!, $restaurantId: ID!, $temporarilyDisabled: Boolean!) {
    updateMenuPage(
      restaurantId: $restaurantId
      menuPageId: $menuPageId
      patch: {
        temporarilyDisabled: $temporarilyDisabled
      }
    ) {
      id
      temporarilyDisabled
    }
  }
`

interface PageToggleButtonProps {
  disabled: boolean
  menuPageId: string
  restaurantId: string
}

const PageToggleButton: React.FC<PageToggleButtonProps> = ({ disabled, menuPageId, restaurantId }) => {
  const [toggleTemporarilyDisabled, { loading }] = useSetPageTemporarilyDisabledMutation({
    variables: {
      menuPageId,
      restaurantId,
      temporarilyDisabled: !disabled
    },
    optimisticResponse: { updateMenuPage: { __typename: 'MenuPage', id: menuPageId, temporarilyDisabled: !disabled } }
  })

  const [showTemporarilyDisabledConfirmation, setShowTemporarilyDisabledConfirmation] = useState(false)

  return (
    <>
      <PanicButton
        isDisabled={disabled}
        isLoading={loading}
        onActivation={() => {
          if (disabled) {
            toggleTemporarilyDisabled().catch(logError)
          } else {
            setShowTemporarilyDisabledConfirmation(true)
          }
        }}
      />
      {!showTemporarilyDisabledConfirmation ? null : (
        <VerificationDialog
          callToActionLabel='Bekräfta'
          errorMessage={undefined}
          loading={loading}
          onDismiss={() => {
            setShowTemporarilyDisabledConfirmation(false)
          }}
          onSubmit={() => {
            setShowTemporarilyDisabledConfirmation(false)
            toggleTemporarilyDisabled().catch(logError)
          }}
          open
          prompt='Är du säker på att du vill inaktivera huvudkategorin? Om du gör det kommer varken huvudkategorin eller dess innehåll vara synliga i vår beställningsapp.'
          title='Inaktivera huvudkategorin'
        />
      )}
    </>
  )
}

export default PageToggleButton

import gql from 'graphql-tag'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HStack, Text, VStack } from 'react-stacked'

import { useExportRestaurantCashregisterJournalMutation } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import { type PlainDateRange } from '../util/dateRangeFromTimePeriod'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import serializeZonedDateTimeRange from '../util/serializeZonedDateTimeRange'
import showAlert from '../util/showAlert'

import { PrimaryButton } from './Buttons'
import DateRangeSelect from './DateRangeSelect'
import Disclaimer from './Disclaimer'
import { TextField } from './TextField'
import Warning from './Warning'

gql`
  mutation ExportRestaurantCashregisterJournal($restaurantId: ID!, $input: ExportRestaurantCashregisterJournalInput!) {
    exportRestaurantCashregisterJournal(
      restaurantId: $restaurantId,
      input: $input
    )
  }
`

interface ExportRestaurantCashregisterJournalFormInput {
  email: string
}

const schema: ObjectSchema<ExportRestaurantCashregisterJournalFormInput> = yup.object({
  email: yup.string().email('E-postadressen du angivit är inte giltig').required('Var vänlig och fyll i till vilken e-postadress du vill skicka statistiken')
})

interface DownloadCashregisterJournalProps {
  restaurantId: string
}

const DownloadCashregisterJournal: React.FC<DownloadCashregisterJournalProps> = ({ restaurantId }) => {
  const [sendToEmail, setSendToEmail] = useState<string | null>(null)
  const [selectedDateRange, setSelectedDateRange] = useState<PlainDateRange | null>(null)

  const form = useForm<ExportRestaurantCashregisterJournalFormInput>({
    criteriaMode: 'all',
    resolver: yupResolver(schema)
  })

  const [exportRestaurantCashregisterJournal, { error, loading: exporting }] = useExportRestaurantCashregisterJournalMutation()

  const handleDateRangeChange = (value: PlainDateRange): void => {
    setSelectedDateRange(value)
  }

  const handleOnPress = useCallback((input: ExportRestaurantCashregisterJournalFormInput) => {
    if (selectedDateRange == null) {
      showAlert('Datumintervall saknas', 'Du måste välja ett datumintervall för att kunna generera statistik')

      return
    }

    const dateTimeRange = {
      start: selectedDateRange.start.toZonedDateTime('Europe/Stockholm'),
      end: selectedDateRange.end.add({ days: 1 }).toZonedDateTime('Europe/Stockholm')
    }

    exportRestaurantCashregisterJournal({
      onCompleted: () => setSendToEmail(input.email),
      variables: {
        restaurantId,
        input: {
          email: input.email,
          dateTimeRange: serializeZonedDateTimeRange(dateTimeRange)
        }
      }
    }).catch(logError)
  }, [selectedDateRange, restaurantId, exportRestaurantCashregisterJournal])

  return (
    <VStack gap={16}>
      <Disclaimer description='Denna funktion hanterar inte stora mängder data bra just nu. Vi vet om problemet och jobbar aktivt för att det ska gå att exportera större mängder data framåt.' />

      <Text paddingHorizontal={10} size={16} weight='bold'>Standardexport av data i journalminne från kassaregister (SKVFS 2021:16)</Text>

      <HStack alignItems='baseline' wrap>
        <DateRangeSelect onDateRangeChange={handleDateRangeChange} timeZone='Europe/Stockholm' />
      </HStack>

      {error == null
        ? null
        : <Warning message={error.message} paddingBottom={16} />}

      {sendToEmail != null
        ? (
          <VStack alignItems='center' gap={16} justifyContent='center' paddingHorizontal={10}>
            <Text size={16}>Vi skickar exporten till {sendToEmail} så snart den är klar.</Text>

            <Text size={16}>♻️ Tänk på att kolla skräpposten!</Text>
          </VStack>
        )
        : (
          <>
            <Text paddingHorizontal={10} size={14}>Ange till vilken e-postadress du vill skicka exporten när den genererats klart, så skickar vi en länk dit när den är klar. Tänk på att kolla i din e-posts skräppost om den inte trillat in i din inbox inom några minuter.</Text>

            <HStack alignItems='end' gap={8} wrap>
              <TextField
                form={form}
                name='email'
                title='E-postadress'
              />

              <PrimaryButton
                disabled={exporting}
                icon='file-csv'
                iconType='font-awesome-5'
                loading={exporting}
                onPress={ignoreAsync(form.handleSubmit(handleOnPress))}
                title='Generera xml'
              />
            </HStack>
          </>
        )}
    </VStack>
  )
}

export default DownloadCashregisterJournal

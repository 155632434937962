import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import { type FullRestaurantControlUnitOutageFragment, useCreateRestaurantControlUnitOutageReportMutation, useGetRestaurantControlUnitOutageReportQuery } from '../../types/graphql'
import formatDateTime from '../util/formatDateTime'
import ignoreAsync from '../util/ignoreAsync'

import { PrimaryButton } from './Buttons'
import Link from './Link'
import ListItemWrapper from './ListItemWrapper'

gql`
  fragment FullRestaurantControlUnitOutageReport on RestaurantControlUnitOutageReport {
    id

    csvLink
    sieLink
    status
  }

  query GetRestaurantControlUnitOutageReport($restaurantId: ID!, $restaurantControlUnitOutageReportId: ID!) {
    restaurant(id: $restaurantId) {
      id

      controlUnitOutageReport(id: $restaurantControlUnitOutageReportId) {
        ...FullRestaurantControlUnitOutageReport
      }
    }
  }

  mutation CreateRestaurantControlUnitOutageReport($restaurantId: ID!, $restaurantControlUnitOutageId: ID!) {
    createRestaurantControlUnitOutageReport(restaurantId: $restaurantId, restaurantControlUnitOutageId: $restaurantControlUnitOutageId) {
      ...FullRestaurantControlUnitOutageReport
    }
  }
`

interface RestaurantControlUnitOutageItemProps {
  item: FullRestaurantControlUnitOutageFragment
  restaurantId: string
}

const RestaurantControlUnitOutageItem: React.FC<RestaurantControlUnitOutageItemProps> = ({ item, restaurantId }) => {
  const isOngoing = item.restoredAt == null

  const [createReport, createReportResponse] = useCreateRestaurantControlUnitOutageReportMutation({
    variables: {
      restaurantControlUnitOutageId: item.id,
      restaurantId
    }
  })

  const getReportResponse = useGetRestaurantControlUnitOutageReportQuery({
    pollInterval: 5000,
    skip: createReportResponse.data?.createRestaurantControlUnitOutageReport?.id == null,
    variables: createReportResponse.data?.createRestaurantControlUnitOutageReport?.id == null ? undefined : {
      restaurantId,
      restaurantControlUnitOutageReportId: createReportResponse.data.createRestaurantControlUnitOutageReport.id
    }
  })

  useEffect(() => {
    if (getReportResponse.data?.restaurant?.controlUnitOutageReport?.csvLink == null) return

    getReportResponse.stopPolling()
  }, [getReportResponse.data?.restaurant?.controlUnitOutageReport?.csvLink])

  return (
    <ListItemWrapper backgroundColor={!isOngoing ? undefined : '#ffd0cd'}>
      <VStack
        alignItems='start'
        borderRadius={3}
        grow={1}
        padding={!isOngoing ? undefined : 8}
        paddingHorizontal={12}
      >
        <HStack alignItems='center' justifyContent='start'>
          <Text size={16}>ID: {item.id} {!isOngoing ? '' : '(pågående driftstopp)'}</Text>
        </HStack>

        <Spacer height={16} />

        <VStack alignItems='start'>
          {item.createdAt == null ? null : (
            <Text size={14}>
              <TextStyle weight='bold'>Inträffade:</TextStyle> {formatDateTime(Temporal.Instant.from(item.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}
            </Text>
          )}
          {item.restoredAt == null ? null : (
            <Text size={14}>
              <TextStyle weight='bold'>Återställdes:</TextStyle> {formatDateTime(Temporal.Instant.from(item.restoredAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}
            </Text>
          )}
        </VStack>
      </VStack>

      <Spacer width={16} />

      {isOngoing ? null : (
        getReportResponse.data?.restaurant?.controlUnitOutageReport?.csvLink != null ? (
          <VStack alignItems='end'>
            <Text padding={10}>
              <Link title='driftstopp.csv' url={getReportResponse.data.restaurant.controlUnitOutageReport.csvLink} />
            </Text>

            {getReportResponse.data.restaurant.controlUnitOutageReport.sieLink == null ? null : (
              <Text padding={10}>
                <Link title='driftstopp.si' url={getReportResponse.data.restaurant.controlUnitOutageReport.sieLink} />
              </Text>
            )}
          </VStack>
        ) : (
          <PrimaryButton
            loading={createReportResponse.loading || createReportResponse.called}
            onPress={ignoreAsync(async () => {
              await createReport()
            })}
            title='Skapa rapport'
          />
        )
      )}
    </ListItemWrapper>
  )
}

export default RestaurantControlUnitOutageItem

import { Temporal } from '@js-temporal/polyfill'

import { TimeResolution } from '../../types/graphql'

import type TimePeriod from './TimePeriod'
import { type ZonedDateTimeRange } from './serializeZonedDateTimeRange'

// +1 hour to cover Daylight Savings
const LONG_DAY = Temporal.Duration.from({ days: 1, hours: 1 })
const LONG_MONTH = Temporal.Duration.from({ days: 31 })

export function resolutionForDailySales (dateTimeRange: ZonedDateTimeRange | null, pick?: TimePeriod | null): TimeResolution {
  if (dateTimeRange == null) return TimeResolution.Day

  const delta = dateTimeRange.start.until(dateTimeRange.end)

  if (Temporal.Duration.compare(delta, LONG_MONTH) > 0) {
    if (pick?.kind === 'ThisYear' || pick?.kind === 'LastYear') {
      return TimeResolution.Week
    }
  }

  if (Temporal.Duration.compare(delta, LONG_DAY) > 0) {
    return TimeResolution.Day
  }

  return TimeResolution.Hour
}

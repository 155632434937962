import { useWindowDimensions } from 'react-native'
import { Text } from 'react-stacked'

import { TEXT_COLOR } from '../../lib/color'

interface ParagraphProps {
  children: React.ReactNode
}

const Paragraph: React.FC<ParagraphProps> = ({ children }) => {
  const { width } = useWindowDimensions()

  return <Text color={TEXT_COLOR} size={width >= 641 ? 19 : 16}>{children}</Text>
}

export default Paragraph

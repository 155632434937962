import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, useWindowDimensions } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import { GetRestaurantSetupProgressDocument, type GetRestaurantSetupProgressQuery, useActivateRestaurantMutation, useGetRestaurantSetupProgressQuery, useRegisterCashregisterMutation, useRegisterControlUnitMutation } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import Divider from '../components/Divider'
import Layout from '../components/Layout'
import StepIndicator from '../components/StepIndicator'
import VerificationDialog from '../components/VerificationDialog'
import { TextField } from '../components/fields'
import { ACCENT_COLOR } from '../lib/color'
import logError from '../util/logError'
import openLink from '../util/openLink'
import useNavigation from '../util/useNavigation'

const EMAIL_ADRESS = 'service@gastrogate.io'
const SMALL_SCREEN_WIDTH_THRESHOLD = 500

gql`
  fragment SetupProgressFragment on Restaurant {
    id

    cashregister {
      id

      address
      isRegistered
      manufacturer
      model
      name
      softwareName
      softwareVersion

      controlUnit {
        address
        identificationNumber
        isRegistered
        manufacturer
        model
        productionNumber
        serialNumber
      }

      location {
        id

        city
        name
        streetAddress
        zipCode
      }
    }

    organization {
      id

      setupProgress {
        hasCompletedStripeSetup
        hasStripeAccount
      }
    }

    setupProgress {
      hasCashregister
      hasEnteredActivationCode
      taxAuthoritiesCashregisterRegistrationUrl
      taxAuthoritiesControlUnitRegistrationUrl
    }
  }

  query GetRestaurantSetupProgress($redirectUrl: URL, $restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      ...SetupProgressFragment

      setupProgress {
        stripeConnectionUrl(redirectUrl: $redirectUrl)
      }
    }
  }

  mutation RegisterControlUnit($restaurantId: ID!, $taxIdNumber: String!) {
    registerControlUnit(
      restaurantId: $restaurantId
      taxIdNumber: $taxIdNumber
    ) {
      ...SetupProgressFragment
    }
  }

  mutation RegisterCashregister($restaurantId: ID!) {
    registerCashregister(
      restaurantId: $restaurantId
    ) {
      ...SetupProgressFragment
    }
  }

  mutation ActivateRestaurant($code: String!, $restaurantId: ID!) {
    activateRestaurant(
      code: $code,
      restaurantId: $restaurantId
    ) {
      ...SetupProgressFragment
    }
  }
`

const labels = ['Välkommen', 'Anmäl kontrollenhet', 'Anmäl kassaregister', 'Aktivera Loco', 'Klar!']

interface StepsProps {
  errorActivateLoco?: string
  errorRegisterCashregister?: string
  errorRegisterControlUnit?: string
  loadingActivateRestaurant: boolean
  loadingRegisterCashregister: boolean
  loadingRegisterControlUnit: boolean
  onActivateRestaurant: (code: string) => void
  onRegisterCashregister: () => void
  onRegisterControlUnit: (taxIdNumber: string) => void
  onStart: () => void
  restaurant: GetRestaurantSetupProgressQuery['restaurant']
  step: number
  taxAuthoritiesCashregisterRegistrationUrl?: string | null
  taxAuthoritiesControlUnitRegistrationUrl?: string | null
}

const Steps: React.FC<StepsProps> = ({
  errorActivateLoco,
  errorRegisterCashregister,
  errorRegisterControlUnit,
  loadingActivateRestaurant,
  loadingRegisterCashregister,
  loadingRegisterControlUnit,
  onActivateRestaurant,
  onRegisterCashregister,
  onRegisterControlUnit,
  onStart,
  restaurant,
  step,
  taxAuthoritiesCashregisterRegistrationUrl,
  taxAuthoritiesControlUnitRegistrationUrl
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [textActivationCode, setTextActivationCode] = useState<string>('')
  const [taxIdNumber, setTaxIdNumber] = useState<string>('')

  const cashregister = restaurant?.cashregister
  const controlUnit = restaurant?.cashregister?.controlUnit
  const [navigation, { restaurantId }] = useNavigation<'RestaurantGetStarted'>()

  if (step === 1) {
    return (
      <VStack>
        <HStack justifyContent='space-between'>
          <Text size={24}>{labels[step]}</Text>
          <SecondaryButton icon='open-in-new' onPress={() => openLink('https://help.gastrogate.io/kom-ig%C3%A5ng-loco-backoffice#anmal-kontrollenhet')} title='Öppna guide' />
        </HStack>

        <Spacer height={12} />

        <Text size={12}>Som restaurangägare måste du anmäla din elektroniska kontrollenhet till Skatteverket för att få den godkänd för användning. Det är lagstadgat och viktigt för att säkerställa korrekt registrering av försäljning och skattebetalningar.</Text>

        <Spacer height={12} />

        {taxAuthoritiesControlUnitRegistrationUrl == null
          ? null
          : (
            <HStack maxWidth={200}>
              <PrimaryButton
                onPress={() => openLink(taxAuthoritiesControlUnitRegistrationUrl)}
                title='Anmäl hos Skatteverket'
              />
            </HStack>
          )}

        <Spacer height={16} />

        <VStack gap={16}>
          <Text>
            <TextStyle weight='bold'>Tillverkare:</TextStyle>&nbsp;{controlUnit?.manufacturer ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Modell:</TextStyle>&nbsp;{controlUnit?.model ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Tillverkningsnummer:</TextStyle>&nbsp;{controlUnit?.serialNumber ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Adress:</TextStyle>&nbsp;{controlUnit?.address ?? ''}
          </Text>
        </VStack>

        <Divider spacing={24} />

        <HStack alignItems='center'>
          <MaterialIcons color='red' name='warning' size={24} />

          <Spacer width={12} />

          <Text size={12}>Fyll i Identifikationsnumret du får genom registreringen hos Skatteverket för att fortsätta.</Text>
        </HStack>

        <Spacer height={24} />

        <TextField
          id='id'
          onChange={setTaxIdNumber}
          title='Identifikationsnummer på formen "0907AB12345"'
          value={taxIdNumber}
        />

        <Spacer height={16} />

        {errorRegisterControlUnit == null
          ? null
          : (
            <>
              <HStack alignItems='center'>
                <MaterialIcons color='red' name='warning' size={24} />

                <Spacer width={16} />

                <Text color='red' size={12}>{errorRegisterControlUnit}</Text>
              </HStack>

              <Spacer height={16} />
            </>
          )}

        <PrimaryButton
          disabled={taxIdNumber.length < 6}
          loading={loadingRegisterControlUnit}
          onPress={() => {
            setShowDialog(false)
            onRegisterControlUnit(taxIdNumber)
          }}
          title='Spara och fortsätt'
        />
      </VStack>
    )
  }

  if (step === 2) {
    return (
      <VStack>
        <HStack justifyContent='space-between'>
          <Text size={24}>{labels[step]}</Text>
          <SecondaryButton icon='open-in-new' onPress={() => openLink('https://help.gastrogate.io/kom-ig%C3%A5ng-loco-backoffice#anmal-kassaregister')} title='Öppna guide' />
        </HStack>

        <Spacer height={12} />

        <Text size={12}>Nästa steg blir att anmäla kassaregister till Skatteverket. Detta görs genom att fylla i en ansökningsblankett på Skatteverkets hemsida.</Text>

        <Spacer height={12} />

        {taxAuthoritiesCashregisterRegistrationUrl == null
          ? null
          : (
            <HStack maxWidth={200}>
              <PrimaryButton
                onPress={() => openLink(taxAuthoritiesCashregisterRegistrationUrl)}
                title='Anmäl hos Skatteverket'
              />
            </HStack>
          )}

        <Spacer height={16} />

        <VStack gap={16}>
          <Text>
            <TextStyle weight='bold'>Identifikationsnummer:</TextStyle>&nbsp;{controlUnit?.identificationNumber ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Beteckning:</TextStyle>&nbsp;{cashregister?.softwareName ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Modell/Program:</TextStyle>&nbsp;{cashregister?.model ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Version:</TextStyle>&nbsp;{cashregister?.softwareVersion ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Tillverkningsnummer/namn:</TextStyle>&nbsp;{cashregister?.name ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Kassaregistrets adress:</TextStyle>&nbsp;{cashregister?.location?.name ?? ''}, {cashregister?.location?.streetAddress ?? ''}, {cashregister?.location?.zipCode ?? ''} {cashregister?.location?.city ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Programvarans adress:</TextStyle>&nbsp;{cashregister?.manufacturer ?? ''}, {cashregister?.address ?? ''}
          </Text>
          <Text>
            <TextStyle weight='bold'>Journalminnets adress:</TextStyle>&nbsp;{cashregister?.manufacturer ?? ''}, {cashregister?.address ?? ''}
          </Text>
        </VStack>

        <Divider spacing={24} />

        {errorRegisterCashregister == null
          ? null
          : (
            <>
              <HStack alignItems='center'>
                <MaterialIcons color='red' name='warning' size={24} />

                <Spacer width={16} />

                <Text color='red' size={12}>{errorRegisterCashregister}</Text>
              </HStack>

              <Spacer height={12} />
            </>
          )}

        <HStack alignItems='center'>
          <MaterialIcons color={ACCENT_COLOR} name='info' />

          <Spacer width={12} />

          <Text size={12}>När du har anmält, se till att bekräfta mottagandet från Skatteverket och håll dig uppdaterad om eventuella ytterligare steg som behöver tas.</Text>
        </HStack>

        <Spacer height={24} />

        <PrimaryButton
          loading={loadingRegisterCashregister}
          onPress={() => setShowDialog(true)}
          title='Jag har nu anmält kassaregistret till Skatteverket'
        />

        <VerificationDialog
          errorMessage={errorRegisterCashregister}
          loading={loadingRegisterCashregister}
          onDismiss={() => setShowDialog(false)}
          onSubmit={() => {
            setShowDialog(false)
            onRegisterCashregister()
          }}
          open={showDialog}
          prompt='Se till att både KONTROLLENHET och KASSAREGISTER är anmält till Skatteverket. Välj OK för att bekräfta.'
          title='Innan du fortsätter'
        />
      </VStack>
    )
  }

  if (step === 3) {
    return (
      <VStack>
        <HStack justifyContent='space-between'>
          <Text size={24}>{labels[step]}</Text>
          <SecondaryButton icon='open-in-new' onPress={() => openLink('https://help.gastrogate.io/kom-ig%C3%A5ng-loco-backoffice#aktivera-loco')} title='Öppna guide' />
        </HStack>

        <Spacer height={12} />

        <Text size={12}>Nu är det inte mycket kvar! Men innan vi kan aktivera Loco för att starta upp din försäljning är det några viktiga detaljer kvar att fixa. Skapa din meny, lägga till öppettider och se till att betalningsuppgifter är på plats. Klicka på knapparna nedan för att komma till respektive sida.</Text>

        <Spacer height={16} />

        <VStack gap={8} maxWidth={220}>
          <PrimaryButton icon='local-dining' onPress={() => navigation.navigate('MenuView', { restaurantId })} title='Meny' />
          <PrimaryButton icon='schedule' onPress={() => navigation.navigate('OpeningHoursList', { restaurantId })} title='Öppettider' />
          <PrimaryButton icon='payment' onPress={() => navigation.navigate('RestaurantPaymentMethodList', { restaurantId })} title='Betalsätt' />
        </VStack>

        <Spacer height={24} />

        <Divider />

        <Spacer height={24} />

        <HStack alignItems='center'>
          <MaterialIcons color={ACCENT_COLOR} name='info' />

          <Spacer width={12} />

          <Text size={12}>Har du inte fått en aktiveringskod, var vänlig att kontakta oss via chat, telefon: 021 - 360 95 50 eller mejl: {EMAIL_ADRESS} så hjälper vi dig.</Text>
        </HStack>

        <Spacer height={24} />

        <TextField
          id='id'
          onChange={setTextActivationCode}
          title='Aktiveringskod'
          value={textActivationCode}
        />

        <Spacer height={16} />

        {errorActivateLoco == null
          ? null
          : (
            <>
              <HStack alignItems='center'>
                <MaterialIcons color='red' name='warning' size={24} />

                <Spacer width={16} />

                <Text color='red' size={12}>{errorActivateLoco}</Text>
              </HStack>

              <Spacer height={12} />
            </>
          )}

        <PrimaryButton
          disabled={textActivationCode.length < 5}
          loading={loadingActivateRestaurant}
          onPress={() => setShowDialog(true)}
          title={`Aktivera Loco för ${cashregister?.name ?? ''}`}
        />

        <VerificationDialog
          errorMessage={errorActivateLoco}
          loading={loadingActivateRestaurant}
          onDismiss={() => setShowDialog(false)}
          onSubmit={() => onActivateRestaurant(textActivationCode)}
          open={showDialog}
          prompt='Välj OK för att aktivera Loco.'
          title='Har du lagt till meny, öppettider och betalsätt?'
        />
      </VStack>
    )
  }

  if (step === 4) {
    return (
      <VStack alignItems='center' justifyContent='center' padding={12}>
        <ActivityIndicator color={ACCENT_COLOR} size='large' />
      </VStack>
    )
  }

  return (
    <VStack>
      <HStack justifyContent='space-between'>
        <Text size={24}>{labels[0]}</Text>
        <SecondaryButton icon='open-in-new' onPress={() => openLink('https://help.gastrogate.io/kom-ig%C3%A5ng-loco-backoffice')} title='Öppna guide' />
      </HStack>

      <Spacer height={12} />

      <Text size={12}>Snyggt jobbat med registreringen, nu är det bara några steg kvar för att aktivera Loco</Text>

      <Spacer height={24} />

      <PrimaryButton
        onPress={onStart}
        title='Kom igång'
      />
    </VStack>
  )
}

const RestaurantGetStarted: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantGetStarted'>()

  const { data, loading } = useGetRestaurantSetupProgressQuery({ variables: { restaurantId } })

  const [registerControlUnit, { loading: loadingRegisterControlUnit, error: errorRegisterControlUnit }] = useRegisterControlUnitMutation({ awaitRefetchQueries: true, refetchQueries: [{ query: GetRestaurantSetupProgressDocument, variables: { restaurantId } }] })
  const [registerCashregister, { loading: loadingRegisterCashregister, error: errorRegisterCashregister }] = useRegisterCashregisterMutation({ variables: { restaurantId }, awaitRefetchQueries: true, refetchQueries: [{ query: GetRestaurantSetupProgressDocument, variables: { restaurantId } }] })
  const [activateRestaurant, { loading: loadingActivateRestaurant, error: errorActivateLoco }] = useActivateRestaurantMutation({ awaitRefetchQueries: true, refetchQueries: [{ query: GetRestaurantSetupProgressDocument, variables: { restaurantId } }] })

  const { width: windowWidth } = useWindowDimensions()

  const lastCompletedStep = useMemo(() => {
    let lastCompletedStep = 0

    if (data?.restaurant?.cashregister?.controlUnit?.isRegistered ?? false) lastCompletedStep = 2
    if (data?.restaurant?.cashregister?.isRegistered ?? false) lastCompletedStep = 3
    if (data?.restaurant?.setupProgress?.hasEnteredActivationCode ?? false) lastCompletedStep = 4

    return lastCompletedStep
  }, [data?.restaurant?.setupProgress])

  const [currentStep, setCurrentStep] = useState(lastCompletedStep)

  useEffect(() => {
    setCurrentStep(lastCompletedStep > 0 ? lastCompletedStep : 0)
  }, [lastCompletedStep])

  const handleRegisterControlUnit = (taxIdNumber: string): void => {
    registerControlUnit({ variables: { restaurantId, taxIdNumber } }).catch(logError)
  }

  const handleRegisterCashregister = (): void => {
    registerCashregister().catch(logError)
  }

  const handleActivateRestaurant = (code: string): void => {
    activateRestaurant({ variables: { code, restaurantId } }).catch(logError)
  }

  useEffect(() => {
    if (lastCompletedStep === 4) navigation.navigate('RestaurantDashboard', { restaurantId })
  }, [lastCompletedStep])

  return (
    <Layout loading={loading} title='Kom igång'>
      {windowWidth >= SMALL_SCREEN_WIDTH_THRESHOLD ? null : (
        <VStack minWidth={240} padding={20}>
          <StepIndicator
            currentPosition={currentStep}
            direction='horizontal'
            stepCount={labels.length}
          />
        </VStack>
      )}

      <HStack gap={32}>
        {windowWidth < SMALL_SCREEN_WIDTH_THRESHOLD ? null : (
          <VStack height={420} paddingHorizontal={20}>
            <StepIndicator
              currentPosition={currentStep}
              direction={windowWidth < SMALL_SCREEN_WIDTH_THRESHOLD ? 'horizontal' : 'vertical'}
              labels={labels}
              stepCount={labels.length}
            />
          </VStack>
        )}

        <VStack maxWidth={700} paddingHorizontal={20} paddingTop={30}>
          <Steps
            errorActivateLoco={errorActivateLoco?.message}
            errorRegisterCashregister={errorRegisterCashregister?.message}
            errorRegisterControlUnit={errorRegisterControlUnit?.message}
            loadingActivateRestaurant={loadingActivateRestaurant}
            loadingRegisterCashregister={loadingRegisterCashregister}
            loadingRegisterControlUnit={loadingRegisterControlUnit}
            onActivateRestaurant={handleActivateRestaurant}
            onRegisterCashregister={handleRegisterCashregister}
            onRegisterControlUnit={handleRegisterControlUnit}
            onStart={() => setCurrentStep(1)}
            restaurant={data?.restaurant}
            step={currentStep}
            taxAuthoritiesCashregisterRegistrationUrl={data?.restaurant?.setupProgress?.taxAuthoritiesCashregisterRegistrationUrl}
            taxAuthoritiesControlUnitRegistrationUrl={data?.restaurant?.setupProgress?.taxAuthoritiesControlUnitRegistrationUrl}
          />
        </VStack>
      </HStack>
    </Layout>
  )
}

export default RestaurantGetStarted

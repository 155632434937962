import gql from 'graphql-tag'
import React from 'react'

import { type OrganizationUserInput, useCreateOrganizationUserMutation, useGetOrganizationUserCreateDataQuery } from '../../types/graphql'
import Layout, { ScreenType } from '../components/Layout'
import OrganizationUserForm from '../components/OrganizationUserForm'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  query GetOrganizationUserCreateData($organizationId: ID!) {
    organization(id: $organizationId) {
      id

      isOwner: hasAccess(access:Write)

      restaurants {
        id

        name
      }
    }
  }

  mutation CreateOrganizationUser($organizationId: ID!, $input: OrganizationUserInput!) {
    createOrganizationUser(organizationId: $organizationId, input: $input) {
      id
    }
  }
`

const OrganizationUserCreate: React.FC = () => {
  const [navigation, { organizationId }] = useNavigation<'OrganizationUserCreate'>()

  const { data, loading } = useGetOrganizationUserCreateDataQuery({ variables: { organizationId } })

  const [createOrganizationUser, { loading: saving }] = useCreateOrganizationUserMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('OrganizationUserList', { organizationId }),
    onError: logAndShowError,
    refetchQueries: ['GetOrganizationUserList']
  })

  const handleCancel = (): void => {
    navigation.navigate('OrganizationUserList', { organizationId })
  }

  const handleSubmit = ignoreAsync(async (input: OrganizationUserInput) => {
    await createOrganizationUser({ variables: { input, organizationId } })
  })

  return (
    <Layout
      breadcrumbs={[{ link: ['OrganizationUserList', { organizationId }], title: 'Användare' }]}
      loading={loading}
      screenType={ScreenType.Form}
      title='Skapa ny användare'
    >
      <OrganizationUserForm
        canUpdateOwners={data?.organization?.isOwner ?? false}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        restaurants={data?.organization?.restaurants}
        saving={saving}
      />
    </Layout>
  )
}

export default OrganizationUserCreate

import { useEffect, useMemo, useRef } from 'react'
import { Controller, type FieldError, type FieldValues, type Path, type UseFormReturn, useFieldArray, useForm } from 'react-hook-form'
import { HStack, Text, VStack } from 'react-stacked'

import { type OpeningHoursPeriodInput, WeekDay, type WeekDayTimeInput } from '../../../types/graphql'
import yup, { type ArraySchema, type ObjectSchema } from '../../lib/validation'
import createOpeningPeriods from '../../util/createOpeningPeriods'
import extractPeriods from '../../util/extractPeriods'
import getBunchedOpeningPeriods, { type BunchedPeriods } from '../../util/getBunchedOpeningPeriods'
import { WEEK_DAYS } from '../../util/getBunchedPeriodsShortform'
import getSelectableClosingHours from '../../util/getSelectableClosingHours'
import getSelectableOpeningHours from '../../util/getSelectableOpeningHours'
import getWeekDayShortform from '../../util/getWeekDayShortform'
import validateOpeningPeriods from '../../util/validateOpeningPeriods'
import Bin from '../Bin'
import ButtonGroup from '../ButtonGroup'
import { AddButton } from '../Buttons'

import ErrorField from './ErrorField'
import Select from './Select'

const weekDayTimeSchema: ObjectSchema<WeekDayTimeInput> = yup.object({
  day: yup.mixed<WeekDay>().oneOf([WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday, WeekDay.Saturday, WeekDay.Sunday]).required(),
  time: yup.string().required()
})

const openingHoursPeriodSchema: ObjectSchema<OpeningHoursPeriodInput> = yup.object({
  close: weekDayTimeSchema.nullable(),
  open: weekDayTimeSchema.required()
})

export const openingHoursPeriodsSchema: ArraySchema<OpeningHoursPeriodInput[] | undefined, any, any, any> = yup.array().of(openingHoursPeriodSchema)
  .test('Overlapping', '', function (value) {
    if (value == null) return true

    const periods: OpeningHoursPeriodInput[] = value

    const validateResult = validateOpeningPeriods(periods)

    if (validateResult == null) return true

    throw this.createError({ message: validateResult.message })
  })

function bunchedPeriodIsFrom (period: BunchedPeriods, test: OpeningHoursPeriodInput): boolean {
  return (
    period.closeTime === test.close?.time &&
    period.openTime === test.open.time &&
    period.days.some(day => (test.open.day <= day && day <= (test.close?.day ?? WeekDay.Sunday)))
  )
}

function transformToBunchedPeriods (openingPeriods: readonly OpeningHoursPeriodInput[] | null | undefined): BunchedPeriods[] {
  const result = getBunchedOpeningPeriods(extractPeriods(openingPeriods))

  if (result.length === 0) {
    result.push({ closeTime: '', openTime: '', days: [] })
  }

  return result
}

function bunchedPeriodsHash (periods: BunchedPeriods[]): string {
  return periods.map(period => `${period.openTime}-${period.closeTime ?? ''}-${period.days.join(',')}`).join(';')
}

function periodsInputHash (periods: OpeningHoursPeriodInput[]): string {
  return periods.map(period => `${period.open.time}-${period.close?.time ?? ''}-${period.open.day}-${period.close?.day ?? ''}`).join(';')
}

interface InternalForm {
  bunchedPeriods: BunchedPeriods[]
}

interface EntryFieldProps {
  form: UseFormReturn<InternalForm>
  name: `bunchedPeriods.${number}`
  onRemove: () => void
}

function EntryField ({ form, name, onRemove }: EntryFieldProps): JSX.Element {
  const openTime: string | undefined = form.watch(`${name}.openTime`)
  const closeTime: string | undefined = form.watch(`${name}.closeTime`) ?? undefined

  const selectableOpeningTimes = useMemo(() => {
    const times = getSelectableOpeningHours().map(hhmm => `${hhmm}:00`)

    if (openTime != null && !times.includes(openTime)) {
      times.unshift(openTime)
    }

    return times.map(value => ({ value, title: value.slice(0, 5) }))
  }, [openTime])

  const selectableClosingTimeOptions = useMemo(() => {
    const times = getSelectableClosingHours().map(hhmm => `${hhmm}:00`)

    if (closeTime != null && !times.includes(closeTime)) {
      times.unshift(closeTime)
    }

    return times.map(value => ({ value, title: value.slice(0, 5) }))
  }, [closeTime])

  return (
    <HStack alignItems='center' gap={8} grow={1} wrap>
      <VStack grow={2} minWidth={300}>
        <ButtonGroup
          buttons={WEEK_DAYS.map(value => ({ label: getWeekDayShortform(value), value }))}
          form={form}
          name={`${name}.days`}
          selectMultiple
        />
      </VStack>

      <HStack alignItems='center' gap={8} grow={1}>
        <VStack grow={1}>
          <Select
            form={form}
            name={`${name}.openTime`}
            options={selectableOpeningTimes}
            title='Öppnar'
          />
        </VStack>

        <VStack grow={1}>
          <Select
            form={form}
            name={`${name}.closeTime`}
            options={selectableClosingTimeOptions}
            title='Stänger'
          />
        </VStack>

        <Bin onSelected={onRemove} />
      </HStack>
    </HStack>
  )
}

interface ControlledOpeningHoursPeriodsFieldProps {
  error: Array<{ close?: { time?: FieldError }, open?: { time?: FieldError } } | undefined> | FieldError | undefined
  onChange: (value: OpeningHoursPeriodInput[] | null) => void
  title?: string | null
  value: OpeningHoursPeriodInput[]
}

function ControlledOpeningHoursPeriodsField ({ error, onChange, title, value }: ControlledOpeningHoursPeriodsFieldProps): JSX.Element {
  const skipOnChange = useRef(true)
  const skipReset = useRef(true)

  const form = useForm<InternalForm>({ defaultValues: { bunchedPeriods: transformToBunchedPeriods(value) } })
  const { fields, append, remove } = useFieldArray({ control: form.control, name: 'bunchedPeriods' })
  const { bunchedPeriods } = form.watch()

  const topLevelError = error != null && !Array.isArray(error) ? error : undefined

  // Inject errors into our internal form
  useEffect(() => {
    form.clearErrors()

    if (error == null) return
    if (!Array.isArray(error)) return

    for (const [bunchedIndex, bunchedPeriod] of bunchedPeriods.entries()) {
      for (const [valueIndex, valueError] of error.entries()) {
        if (valueError == null) continue
        if (!bunchedPeriodIsFrom(bunchedPeriod, value[valueIndex])) continue

        if (valueError.close?.time != null) {
          form.setError(`bunchedPeriods.${bunchedIndex}.closeTime`, valueError.close.time)
        }

        if (valueError.open?.time != null) {
          form.setError(`bunchedPeriods.${bunchedIndex}.openTime`, valueError.open.time)
        }
      }
    }
  }, [error])

  // Update the form when the value changes
  useEffect(() => {
    if (skipReset.current) {
      skipReset.current = false
      return
    }

    skipOnChange.current = true
    form.reset({ bunchedPeriods: transformToBunchedPeriods(value) })
  }, [periodsInputHash(value)])

  // Update the value when the form changes
  useEffect(() => {
    if (skipOnChange.current) {
      skipOnChange.current = false
      return
    }

    skipReset.current = true
    onChange(createOpeningPeriods(bunchedPeriods))
  }, [bunchedPeriodsHash(bunchedPeriods)])

  return (
    <VStack gap={24}>
      {title == null ? null : <Text size={16}>{title}</Text>}

      {fields.map(({ id, ...value }, index) => (
        <EntryField
          key={id}
          form={form}
          name={`bunchedPeriods.${index}`}
          onRemove={() => remove(index)}
        />
      ))}

      {topLevelError == null ? null : <ErrorField message={topLevelError.message} />}

      <AddButton
        icon='clock'
        iconType='material-community'
        onPress={() => append({ closeTime: '', openTime: '', days: [] })}
        title='Lägg till tider'
      />
    </VStack>
  )
}

interface OpeningHoursPeriodsFieldProps<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>
  name: Path<TFieldValues>
  title?: string | null
}

export function OpeningHoursPeriodsField<TFieldValues extends FieldValues> ({ form, name, title }: OpeningHoursPeriodsFieldProps<TFieldValues>): JSX.Element {
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ControlledOpeningHoursPeriodsField
          error={error}
          onChange={onChange}
          title={title}
          value={value ?? []}
        />
      )}
    />
  )
}

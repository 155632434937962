import { type NavigationHelpers as UpstreamNavigationHelpers, useNavigation as upstream, useRoute } from '@react-navigation/native'
import { type StackNavigationProp } from '@react-navigation/stack'

import { type ParamList } from '../lib/navigation'

export type NavigateFunction = StackNavigationProp<ParamList, keyof ParamList>['navigate']
export type NavigationHelpers = Pick<UpstreamNavigationHelpers<ParamList>, 'navigate'>

export default function useNavigation<RouteName extends keyof ParamList> (): [StackNavigationProp<ParamList, RouteName>, ParamList[RouteName]] {
  return [upstream<StackNavigationProp<ParamList, RouteName>>(), (useRoute().params ?? {}) as any as ParamList[RouteName]]
}

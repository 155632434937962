import { MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import React from 'react'
import { Controller, type UseFormReturn } from 'react-hook-form'
import { Pressable } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type OpenDateTimeRangeInput, type OrderFilter, TransactionChannel } from '../../types/graphql'
import yup, { type ObjectSchema } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import { isValidPrice, parsePrice } from '../util/price'
import serializeZonedDateTime from '../util/serializeZonedDateTime'

import { SecondaryButton } from './Buttons'
import Dialog, { Action } from './Dialog'
import { TextField } from './TextField'
import { CheckBox } from './fields'
import DateField from './fields/DateField'

interface ReceiptSearchDialogProps {
  form: UseFormReturn<FormInput>
  hasDeliveryHeroAccess: boolean
  onDismiss: () => void
  onSubmit: (filter: OrderFilter) => void
  timeZone: string
}

interface FormDateTimeInput {
  endDate?: string | null
  endTime?: string | null
  startDate?: string | null
  startTime?: string | null
}

export interface FormInput {
  createdAtEndDate?: string | null
  createdAtEndTime?: string | null
  createdAtStartDate?: string | null
  createdAtStartTime?: string | null
  humanOrderNumber?: string | null
  showingDateInterval?: boolean | null
  totalAmount?: string | null
  transactionChannels?: TransactionChannel[] | null
}

const VALID_DATE = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/
const VALID_TIME = /^(0?[1-9]|[0-1][0-9]|2[0-3]):([0-5][0-9])(:([0-5][0-9]))?$|(24:00)$/

function isValidDate (date: string | null | undefined): date is string {
  if (date == null || date === '') return false

  return VALID_DATE.test(date)
}

function isValidTime (time: string | null | undefined): time is string {
  if (time == null || time === '') return false

  return VALID_TIME.test(time)
}

function parseUserDateTime (inputDate: string, inputTime: string | null | undefined, defaultTime: string): Temporal.PlainDateTime | undefined {
  inputTime = isValidTime(inputTime) ? inputTime : defaultTime

  const dateGroups = VALID_DATE.exec(inputDate)
  const timeGroups = VALID_TIME.exec(inputTime ?? defaultTime)

  if (dateGroups == null || timeGroups == null) return undefined

  const year = Number(dateGroups[1])
  const month = Number(dateGroups[2])
  const day = Number(dateGroups[3])

  if (timeGroups[0] === '24:00') {
    return Temporal.PlainDateTime.from({ year, month, day }).add({ days: 1 })
  }

  const hour = Number(timeGroups[1])
  const minute = Number(timeGroups[2])
  const second = Number(timeGroups[4] ?? '00')

  return Temporal.PlainDateTime.from({ year, month, day, hour, minute, second })
}

const convertFormInputToDateTimeRange = ({ endDate, endTime, startDate, startTime }: FormDateTimeInput, isDateInterval: boolean, timeZone: string): OpenDateTimeRangeInput | null => {
  endDate = endDate === '' ? null : endDate
  endTime = endTime === '' ? null : endTime
  startDate = startDate === '' ? null : startDate
  startTime = startTime === '' ? null : startTime

  if (endDate == null && startDate == null) return null

  let end: Temporal.PlainDateTime | undefined
  let start: Temporal.PlainDateTime | undefined
  if (startDate != null) start = parseUserDateTime(startDate, startTime, '00:00')
  if (endDate != null && isDateInterval) end = parseUserDateTime(endDate, endTime, '24:00')

  if (start != null && !isDateInterval) {
    if (startTime == null) {
      end = start.add({ days: 1 })
    } else {
      end = start.add({ minutes: 1 })
    }
  }

  return {
    end: end == null ? null : serializeZonedDateTime(end.toZonedDateTime(timeZone)),
    start: start == null ? null : serializeZonedDateTime(start.toZonedDateTime(timeZone))
  }
}

export const schema: ObjectSchema<FormInput> = yup.object({
  createdAtEndDate: yup.string().nullable()
    .test('end-date-is-valid-date', 'Ange datum på formen åååå-mm-dd', (createdAtEndDate) => (createdAtEndDate == null || createdAtEndDate === '' || isValidDate(createdAtEndDate)))
    .test({
      message: 'Slutdatum måste vara efter eller lika med startdatum',
      name: 'end-date-is-after-or-equal-to-start-date',
      test: function (createdAtEndDate) {
        if (this.parent.createdAtStartDate == null || createdAtEndDate == null) return true
        if (!isValidDate(this.parent.createdAtStartDate) || !isValidDate(createdAtEndDate)) return true

        return new Date(this.parent.createdAtStartDate) <= new Date(createdAtEndDate)
      }
    }),
  createdAtEndTime: yup.string().nullable()
    .test('end-time-is-valid-time', 'Ange tid i 24:00-format, exempelvis 01:24 eller 23:43', (createdAtEndTime) => (createdAtEndTime == null || createdAtEndTime === '' || isValidTime(createdAtEndTime)))
    .test('end-time-requires-end-date', 'Fyll i datum för att ange tid', function (createdAtEndTime) {
      return createdAtEndTime == null || createdAtEndTime === '' || !(isValidTime(createdAtEndTime) && !isValidDate(this.parent.createdAtEndDate))
    }),
  createdAtStartDate: yup.string().nullable()
    .test('start-date-is-valid-date', 'Ange datum på formen åååå-mm-dd', (createdAtStartDate) => (createdAtStartDate == null || createdAtStartDate === '' || isValidDate(createdAtStartDate)))
    .test({
      message: 'Startdatum måste vara före eller lika med slutdatum',
      name: 'start-date-is-after-or-equal-to-start-date',
      test: function (createdAtStartDate) {
        if (createdAtStartDate == null || this.parent.createdAtEndDate == null) return true
        if (!isValidDate(createdAtStartDate) || !isValidDate(this.parent.createdAtEndDate)) return true

        return new Date(createdAtStartDate) <= new Date(this.parent.createdAtEndDate)
      }
    }),
  createdAtStartTime: yup.string().nullable()
    .test('start-time-is-valid-time', 'Ange tid i 24:00-format, exempelvis 01:24 eller 23:43', (createdAtStartTime) => (createdAtStartTime == null || createdAtStartTime === '' || isValidTime(createdAtStartTime)))
    .test('end-time-requires-end-date', 'Fyll i datum för att ange tid', function (createdAtStartTime) {
      return createdAtStartTime == null || createdAtStartTime === '' || !(isValidTime(createdAtStartTime) && !isValidDate(this.parent.createdAtStartDate))
    }),
  humanOrderNumber: yup.string().nullable().test('humanOrderNumberIsValid', 'Måste vara ett nummer', val => val == null || val === '' ? true : /^\d+$/.test(val)),
  showingDateInterval: yup.boolean(),
  totalAmount: yup.string().nullable().test('totalAmount', 'Måste vara ett nummer', val => val == null || val === '' ? true : isValidPrice(val)),
  transactionChannels: yup.array().of(yup.string().oneOf(Object.values(TransactionChannel)).required()).nullable()
})

const ReceiptSearchDialog: React.FC<ReceiptSearchDialogProps> = ({ form, hasDeliveryHeroAccess, onDismiss, onSubmit, timeZone }) => {
  const showingDateInterval = form.watch('showingDateInterval') ?? false
  const transactionChannels = form.watch('transactionChannels') ?? []

  const handleSubmit = (input: FormInput): void => {
    const createdAt = convertFormInputToDateTimeRange(
      {
        endDate: input.createdAtEndDate,
        endTime: input.createdAtEndTime,
        startDate: input.createdAtStartDate,
        startTime: input.createdAtStartTime
      },
      input.showingDateInterval ?? false,
      timeZone
    )

    const totalAmount = input.totalAmount == null || input.totalAmount === '' ? undefined : { start: parsePrice(input.totalAmount), end: parsePrice(input.totalAmount) }

    onSubmit({
      createdAt,
      humanOrderNumber: input.humanOrderNumber == null || input.humanOrderNumber === '' ? undefined : parseInt(input.humanOrderNumber),
      totalAmount,
      transactionChannels: input.transactionChannels
    })
  }

  return (
    <Dialog
      actions={[
        <Action key='cancel' onPress={onDismiss} text='Avbryt' />,
        <Action key='ok' onPress={ignoreAsync(form.handleSubmit(handleSubmit))} text='Sök' />
      ]}
      maxWidth={600}
      title='Sök kvitton'
      visible
    >
      <ScrollView>
        <VStack gap={8} padding={16}>
          <HStack grow={1} padding={8} wrap>
            <VStack gap={12} grow={1} paddingRight={5}>
              <Text paddingVertical={5}>{showingDateInterval ? 'Start, datum och tid' : 'Datum och tid'}</Text>

              <DateField
                fontSize={18}
                form={form}
                name='createdAtStartDate'
                placeholder='åååå-mm-dd'
              />

              <TextField
                estimatedNumberOfCharacters={5}
                form={form}
                name='createdAtStartTime'
                title='Starttid, ex. 00:00'
              />
            </VStack>

            <Controller
              control={form.control}
              name='showingDateInterval'
              render={({ field: { onChange, value } }) =>
                !((value as boolean) ?? false)
                  ? (
                    <VStack alignItems='center' grow={1} justifyContent='center'>
                      <SecondaryButton icon='add' onPress={() => onChange(true)} title='Datumintervall' />
                    </VStack>
                  )
                  : (
                    <VStack gap={12} grow={1}>
                      <Pressable onPress={() => onChange(false)}>
                        <HStack gap={4}>
                          <MaterialIcons color='#1e88e5' name='remove' size={24} />

                          <Text color='#1e88e5' paddingVertical={5}>Slut, datum och tid</Text>
                        </HStack>
                      </Pressable>

                      <DateField
                        fontSize={18}
                        form={form}
                        name='createdAtEndDate'
                        placeholder='åååå-mm-dd'
                      />

                      <TextField
                        estimatedNumberOfCharacters={5}
                        form={form}
                        name='createdAtEndTime'
                        title='Sluttid, ex. 24:00'
                      />
                    </VStack>
                  )}
            />
          </HStack>

          <TextField
            form={form}
            name='humanOrderNumber'
            title='Ordernummer'
          />

          <TextField
            form={form}
            inputMode='decimal'
            name='totalAmount'
            title='Total kostnad'
          />

          <Spacer height={12} />

          <VStack>
            <Text size={18}>Transaktionskanal</Text>

            <Spacer height={12} />

            <Text size={16}>LocoPOS Order</Text>

            <CheckBox
              checked={transactionChannels.includes(TransactionChannel.Order)}
              onPress={() => {
                if (transactionChannels.includes(TransactionChannel.Order)) {
                  form.setValue('transactionChannels', transactionChannels.filter(channel => channel !== TransactionChannel.Order) ?? [], { shouldDirty: true })
                } else {
                  form.setValue('transactionChannels', [...transactionChannels, TransactionChannel.Order], { shouldDirty: true })
                }
              }}
              title='Kassasystem'
            />

            <CheckBox
              checked={transactionChannels.includes(TransactionChannel.SelfCheckout)}
              onPress={() => {
                if (transactionChannels.includes(TransactionChannel.SelfCheckout)) {
                  form.setValue('transactionChannels', transactionChannels.filter(channel => channel !== TransactionChannel.SelfCheckout), { shouldDirty: true })
                } else {
                  form.setValue('transactionChannels', [...transactionChannels, TransactionChannel.SelfCheckout], { shouldDirty: true })
                }
              }}
              title='Expresskassa'
            />

            {!hasDeliveryHeroAccess ? null : (
              <>
                <Text size={16}>Leverans</Text>

                <CheckBox
                  checked={transactionChannels.includes(TransactionChannel.DeliveryHero)}
                  onPress={() => {
                    if (transactionChannels.includes(TransactionChannel.DeliveryHero)) {
                      form.setValue('transactionChannels', transactionChannels.filter(channel => channel !== TransactionChannel.DeliveryHero) ?? [], { shouldDirty: true })
                    } else {
                      form.setValue('transactionChannels', [...transactionChannels, TransactionChannel.DeliveryHero], { shouldDirty: true })
                    }
                  }}
                  title='Foodora'
                />
              </>
            )}

            <Text size={16}>Onlinebeställning</Text>

            <CheckBox
              checked={transactionChannels.includes(TransactionChannel.WhiteLabel)}
              onPress={() => {
                if (transactionChannels.includes(TransactionChannel.WhiteLabel)) {
                  form.setValue('transactionChannels', transactionChannels.filter(channel => channel !== TransactionChannel.WhiteLabel) ?? [], { shouldDirty: true })
                } else {
                  form.setValue('transactionChannels', [...transactionChannels, TransactionChannel.WhiteLabel], { shouldDirty: true })
                }
              }}
              title='Beställningsapp'
            />

            <CheckBox
              checked={transactionChannels.includes(TransactionChannel.Web)}
              onPress={() => {
                if (transactionChannels.includes(TransactionChannel.Web)) {
                  form.setValue('transactionChannels', transactionChannels.filter(channel => channel !== TransactionChannel.Web) ?? [], { shouldDirty: true })
                } else {
                  form.setValue('transactionChannels', [...transactionChannels, TransactionChannel.Web], { shouldDirty: true })
                }
              }}
              title='Loco Web (QR/Takeaway)'
            />

            <CheckBox
              checked={transactionChannels.includes(TransactionChannel.Loco)}
              onPress={() => {
                if (transactionChannels.includes(TransactionChannel.Loco)) {
                  form.setValue('transactionChannels', transactionChannels.filter(channel => channel !== TransactionChannel.Loco) ?? [], { shouldDirty: true })
                } else {
                  form.setValue('transactionChannels', [...transactionChannels, TransactionChannel.Loco], { shouldDirty: true })
                }
              }}
              title='Loco App (QR/Takeaway)'
            />
          </VStack>
        </VStack>
      </ScrollView>
    </Dialog>
  )
}

export default ReceiptSearchDialog

import gql from 'graphql-tag'
import React, { useCallback, useMemo, useState } from 'react'
import { ActivityIndicator, FlatList, Switch } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { GetMetaOrganizationDocument, useAddRestaurantToMetaOrganizationMutation, useGetMetaOrganizationQuery, useRemoveRestaurantFromMetaOrganizationMutation } from '../../types/graphql'
import ButtonGroup from '../components/ButtonGroup'
import { AddButton } from '../components/Buttons'
import DiscountList from '../components/DiscountList'
import Layout from '../components/Layout'
import { CheckBox } from '../components/fields'
import { ACCENT_COLOR } from '../lib/color'
import logAndShowError from '../util/logAndShowError'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

gql`
  query GetMetaOrganization($id: ID!) {
    metaOrganization(id: $id) {
      id
      name
      createdAt
      updatedAt

      discounts(first: 1000) {
        edges {
          node {
            ...FullDiscount
          }
        }

        pageInfo {
          hasNextPage
        }
      }

      restaurants(first: 1000) {
        edges {
          node {
            id
            name
          }
        }

        pageInfo {
          hasNextPage
        }
      }
    }

    restaurants(first: 1000) {
      edges {
        node {
          id
          name
        }
      }
    }
  }

  mutation AddRestaurantToMetaOrganization($metaOrganizationId: ID!, $restaurantId: ID!) {
    addRestaurantToMetaOrganization(metaOrganizationId: $metaOrganizationId, restaurantId: $restaurantId)
  }

  mutation RemoveRestaurantFromMetaOrganization($metaOrganizationId: ID!, $restaurantId: ID!) {
    removeRestaurantFromMetaOrganization(metaOrganizationId: $metaOrganizationId, restaurantId: $restaurantId)
  }
`

function sizeInParentheses (data?: readonly unknown[]): string {
  return data == null ? '' : ` (${data.length})`
}

const MetaOrganizationView: React.FC = () => {
  const [navigation, { metaOrganizationId }] = useNavigation<'MetaOrganizationView'>()
  const { data } = useGetMetaOrganizationQuery({ variables: { id: metaOrganizationId } })

  const [addRestaurant, { loading: adding }] = useAddRestaurantToMetaOrganizationMutation()
  const [removeRestaurant, { loading: removing }] = useRemoveRestaurantFromMetaOrganizationMutation()

  const [tab, setTab] = useState<'discounts' | 'restaurants'>('discounts')
  const [showAllRestaurants, setShowAllRestaurants] = useState(false)
  const [updatingRestaurantId, setUpdatingRestaurantId] = useState<string>()

  const handleCreateDiscount = useCallback(() => {
    navigation.navigate('MetaOrganizationDiscountCreate', { metaOrganizationId })
  }, [metaOrganizationId])

  const handleOnDiscountPress = useCallback((discountId: string) => navigation.navigate('MetaOrganizationDiscountEdit', { metaOrganizationId, discountId }), [metaOrganizationId, navigation])

  const handleAddRemoveRestaurant = useCallback((restaurantId: string, selected: boolean) => () => {
    setUpdatingRestaurantId(restaurantId)

    const options = {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetMetaOrganizationDocument, variables: { id: metaOrganizationId } }],
      variables: { metaOrganizationId, restaurantId }
    }

    if (selected) {
      removeRestaurant(options).catch(logAndShowError).finally(() => setUpdatingRestaurantId(undefined))
    } else {
      addRestaurant(options).catch(logAndShowError).finally(() => setUpdatingRestaurantId(undefined))
    }
  }, [addRestaurant, metaOrganizationId, setUpdatingRestaurantId, removeRestaurant])

  const discounts = useMemo(() => data?.metaOrganization?.discounts?.edges.map(edge => edge.node), [data?.metaOrganization?.discounts])

  const selectedRestaurants = useMemo(() => data?.metaOrganization?.restaurants?.edges.map(edge => edge.node), [data?.metaOrganization?.restaurants])

  const restaurants = useMemo(() => {
    const result = (showAllRestaurants ? data?.restaurants?.edges.map(edge => edge.node) : selectedRestaurants) ?? []

    return sortOn(
      result.map(({ id, name }) => ({
        id,
        name,
        selected: selectedRestaurants?.some(sr => sr.id === id) ?? false,
        updating: updatingRestaurantId === id
      })),
      'name'
    )
  }, [data?.restaurants?.edges, showAllRestaurants, selectedRestaurants, updatingRestaurantId])

  return (
    <Layout breadcrumbs={[{ link: ['MetaOrganizationList', {}], title: 'Metaorganisationer' }]} title={data?.metaOrganization?.name}>
      <VStack maxWidth={1024} padding={normalize(8, 16)}>
        {/* FIXME: replace button group with standard tabs */}
        <ButtonGroup
          buttons={[
            { label: `Rabatter${sizeInParentheses(discounts)}`, value: 'discounts' },
            { label: `Restauranger${sizeInParentheses(selectedRestaurants)}`, value: 'restaurants' }
          ]}
          onPress={setTab}
          selected={tab}
        />

        <Spacer height={16} />

        {tab === 'discounts'
          ? (
            <>
              <DiscountList
                discounts={discounts}
                onPress={handleOnDiscountPress}
                parentType='MetaOrganization'
              />

              <Spacer height={24} />

              <AddButton
                icon='add-circle'
                onPress={handleCreateDiscount}
                title='Skapa ny rabatt'
              />
            </>
          )
          : (
            <>
              <HStack justifyContent='center' padding={8}>
                <Text size={16}>Visa alla restauranger</Text>

                <Spacer width={16} />

                <Switch
                  // @ts-expect-error web only: https://necolas.github.io/react-native-web/docs/switch/#props
                  activeThumbColor={ACCENT_COLOR}
                  onValueChange={() => setShowAllRestaurants(!showAllRestaurants)}
                  thumbColor={!showAllRestaurants ? '#d4d4d4' : undefined}
                  trackColor={{ false: '#adadad', true: '#e58900' }}
                  value={showAllRestaurants}
                />
              </HStack>

              <Spacer height={16} />

              <FlatList
                data={restaurants}
                keyExtractor={item => item.id}
                ListEmptyComponent={discounts == null ? <ActivityIndicator size='large' /> : undefined}
                renderItem={({ item: { id, name, selected, updating }, index }) => (
                  <HStack alignItems='center' backgroundColor={['#e1e1e1', undefined][index % 2]} justifyContent='space-between' minHeight={56} paddingHorizontal={8}>
                    <Text weight={selected ? 'bold' : undefined}>{name}</Text>

                    {updating
                      ? <ActivityIndicator style={{ marginRight: 14 }} />
                      : (
                        <CheckBox
                          backgroundColor='none'
                          checked={selected}
                          disabled={adding || removing}
                          onPress={handleAddRemoveRestaurant(id, selected)}
                        />
                      )}
                  </HStack>
                )}
              />
            </>
          )}
      </VStack>
    </Layout>
  )
}

export default MetaOrganizationView

import React from 'react'
import { VStack } from 'react-stacked'

import { type ProductOpeningHoursFragment } from '../../../types/graphql'
import extractPeriods from '../../util/extractPeriods'
import getBunchedOpeningPeriods, { type BunchedPeriods } from '../../util/getBunchedOpeningPeriods'
import getBunchedPeriodsShortform from '../../util/getBunchedPeriodsShortform'
import OpeningHoursShortform from '../OpeningHoursShortform'

type ServingTimeProps = { bunchedPeriods: BunchedPeriods[] } | { openingHours: ProductOpeningHoursFragment }

export const ServingTime: React.FC<ServingTimeProps> = (props) => {
  if ('openingHours' in props) {
    const openingPeriods = extractPeriods(props.openingHours?.periods)
    const bunchedPeriodsFromOpeningHours = getBunchedOpeningPeriods(openingPeriods)

    return (
      <VStack paddingLeft={20}>
        {getBunchedPeriodsShortform(bunchedPeriodsFromOpeningHours).map((period, index) => (
          <OpeningHoursShortform
            key={index}
            closeTime={period.closeTime}
            firstDay={period.firstDay}
            lastDay={period.lastDay}
            openTime={period.openTime}
          />
        ))}
      </VStack>
    )
  } else {
    return (
      <VStack>
        {getBunchedPeriodsShortform(props.bunchedPeriods).map((period, index) => (
          <OpeningHoursShortform
            key={index}
            closeTime={period.closeTime}
            firstDay={period.firstDay}
            lastDay={period.lastDay}
            openTime={period.openTime}
          />
        ))}
      </VStack>
    )
  }
}

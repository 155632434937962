import { TextField as MuTextField, type TextFieldProps as MuTextFieldProps } from '@mui/material'
import React, { type ChangeEvent, useCallback } from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'

import ErrorField from './ErrorField'

interface UncontrolledTextFieldProps<TFieldValues extends FieldValues> {
  autoFocus?: boolean
  disabled?: boolean
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  helperText?: string
  icon?: JSX.Element
  iconLeft?: boolean
  multiline?: boolean
  name: Path<TFieldValues>
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  title: string
  type?: string
}

interface ControlledTextFieldProps {
  autoFocus?: boolean
  disabled?: boolean
  error?: boolean
  helperText?: string
  icon?: JSX.Element
  iconLeft?: boolean
  id: string
  multiline?: boolean
  onBlur?: MuTextFieldProps['onBlur']
  onChange: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  title: string
  type?: string
  value: string
}

/**
 * @deprecated The method should not be used. Use ../components/TextField.tsx instead
 */
export default function TextField<T extends FieldValues> (props: UncontrolledTextFieldProps<T> | ControlledTextFieldProps): JSX.Element {
  if ('form' in props) {
    const error = get(props.form.formState.errors, props.name)
    return (
      <>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onBlur, onChange, value } }) => (
            <TextField
              autoFocus={props.autoFocus}
              disabled={props.disabled}
              error={error != null}
              helperText={props.helperText}
              icon={props.icon}
              iconLeft={props.iconLeft}
              id={props.name}
              multiline={props.multiline}
              onBlur={onBlur}
              onChange={onChange}
              onKeyDown={props.onKeyDown}
              placeholder={props.placeholder}
              readOnly={props.readOnly}
              required={props.required}
              title={props.title}
              type={props.type}
              value={value}
            />
          )}
        />

        <ErrorField message={error?.message} />
      </>
    )
  }

  const handleChange = useCallback((ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.onChange(ev.target.value)
  }, [props.onChange])

  return (
    <MuTextField
      autoFocus={props.autoFocus ?? false}
      disabled={props.disabled}
      error={props.error ?? false}
      helperText={props.helperText}
      id={props.id}
      InputProps={{
        endAdornment: props.iconLeft !== true && props.icon != null ? props.icon : undefined,
        readOnly: props.readOnly ?? false,
        startAdornment: props.iconLeft === true && props.icon != null ? props.icon : undefined
      }}
      label={props.title}
      multiline={props.multiline}
      onBlur={props.onBlur}
      onChange={handleChange}
      onKeyDown={props.onKeyDown}
      placeholder={props.placeholder}
      required={props.required ?? false}
      type={props.type}
      value={props.value ?? ''}
    />
  )
}

import { gql } from '@apollo/client'
import { Temporal } from '@js-temporal/polyfill'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { type UseFormReturn, useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { Text, TextStyle } from 'react-stacked'

import { type ChangeUserEmailConfirmMutationVariables, type ChangeUserEmailRequestMutationVariables, useChangeUserEmailConfirmMutation, useChangeUserEmailRequestMutation, useGetUserSettingsInfoQuery } from '../../types/graphql'
import { PrimaryButton } from '../components/Buttons'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import Warning from '../components/Warning'
import TextField from '../components/fields/TextField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useDate from '../util/useDate'
import useNavigation from '../util/useNavigation'

gql`
  query GetUserSettingsInfo {
    me {
      id

      username
    }

    sudoMode {
      id

      expiresAt
    }
  }

  mutation ChangeUserEmailRequest($userId: ID!, $email: EmailAddress!) {
    requestChangeUserEmail(userId: $userId, email: $email) {
      id
    }
  }

  mutation ChangeUserEmailConfirm($requestId: ID!, $code: String!) {
    confirmChangeUserEmail(requestId: $requestId, code: $code) {
      id
    }
  }
`

interface StepsProps {
  confirmError: string | null
  confirmForm: UseFormReturn<Omit<ChangeUserEmailConfirmMutationVariables, 'requestId'>>
  confirmLoading: boolean
  newEmail: string
  oldEmail: string
  onConfirmSubmit: () => void
  onNavigateToAccount: () => void
  onRequestSubmit: () => void
  requestError: string | null
  requestForm: UseFormReturn<Omit<ChangeUserEmailRequestMutationVariables, 'userId'>>
  requestLoading: boolean
  step: number
}

const Steps: React.FC<StepsProps> = ({
  confirmError,
  confirmForm,
  confirmLoading,
  newEmail,
  oldEmail,
  onConfirmSubmit,
  onNavigateToAccount,
  onRequestSubmit,
  requestError,
  requestForm,
  requestLoading,
  step
}) => {
  if (step === 0) {
    return (
      <>
        <Text size={19}>Ändra e-post</Text>

        <Spacer height={12} />

        <Text size={14}>Nuvarande e-postadress: {oldEmail}</Text>

        <Spacer height={8} />

        <Text size={14}>Skriv in din nya e-postadress som du vill använda i Loco nedan. Vi skickar en engångskod till den adressen.</Text>

        <Spacer height={24} />

        {requestError == null
          ? null
          : <Warning message={requestError} paddingBottom={8} />}

        <TextField
          key='email'
          form={requestForm}
          name='email'
          title='Ny E-post'
        />

        <Spacer height={24} />

        <SubmitFormButtons
          onCancel={onNavigateToAccount}
          onSave={onRequestSubmit}
          saving={requestLoading}
          titleForSubmit='Fortsätt'
        />
      </>
    )
  }

  if (step === 1) {
    return (
      <>
        <Text size={19}>Kod</Text>

        <Spacer height={12} />

        <Text size={14}>Skriv in koden som skickats till {newEmail !== '' ? newEmail : 'din nya e-postadress'}</Text>

        <Spacer height={24} />

        {confirmError == null
          ? null
          : <Warning message={confirmError} paddingBottom={8} />}

        <TextField
          key='code'
          form={confirmForm}
          name='code'
          title='Kod'
        />

        <Spacer height={24} />

        <SubmitFormButtons
          onCancel={onNavigateToAccount}
          onSave={onConfirmSubmit}
          saving={confirmLoading}
          titleForSubmit='Fortsätt'
        />
      </>
    )
  }

  return (
    <>
      <Text size={19}>Dina ändringar har sparats!</Text>

      <Spacer height={18} />

      <Text size={14}>Din e-post har ändrats</Text>

      <Spacer height={12} />

      <Text size={14}>
        Från: <TextStyle weight='bold'>{oldEmail}</TextStyle>
      </Text>

      <Spacer height={6} />

      <Text size={14}>
        Till: <TextStyle weight='bold'>{newEmail}</TextStyle>
      </Text>

      <Spacer height={36} />

      <PrimaryButton onPress={onNavigateToAccount} title='Till konto' />
    </>
  )
}

const requestSchema: ObjectSchema<Omit<ChangeUserEmailRequestMutationVariables, 'userId'>> = yup.object({
  email: yup.string().trim().required('Vänligen ange din e-postadress')
})

const confirmSchema: ObjectSchema<Omit<ChangeUserEmailConfirmMutationVariables, 'requestId'>> = yup.object({
  code: yup.string().trim().required('Vänligen ange koden som skickats till din nya e-postadress')
})

const EditUsername: React.FC = () => {
  const [navigation] = useNavigation()

  const requestForm = useForm<Omit<ChangeUserEmailRequestMutationVariables, 'userId'>>({
    resolver: yupResolver(requestSchema)
  })

  const confirmForm = useForm<Omit<ChangeUserEmailConfirmMutationVariables, 'requestId'>>({
    resolver: yupResolver(confirmSchema)
  })

  const { data } = useGetUserSettingsInfoQuery()

  const [changeUserEmailRequest, { error: requestError, loading: requestLoading, data: requestData }] = useChangeUserEmailRequestMutation()
  const [changeUserEmailConfirm, { error: confirmError, loading: confirmLoading }] = useChangeUserEmailConfirmMutation()

  const [step, setStep] = useState(0)
  const [newEmail, setNewEmail] = useState('')
  const [oldEmail, setOldEmail] = useState('')

  const now = useDate({ interval: 'minute' })

  const isInSudoMode = useMemo(() => {
    if (data?.sudoMode?.expiresAt == null) return false
    return Temporal.Instant.compare(Temporal.Now.instant(), Temporal.Instant.from(new Date(data.sudoMode.expiresAt).toISOString())) < 0
  }, [data?.sudoMode?.expiresAt, now])

  const handleNavigateToAccount = (): void => {
    setStep(0)
    navigation.navigate('AccountEdit', {})
  }

  const handleRequestSubmit = useCallback(
    ignoreAsync(requestForm.handleSubmit(({ email }): void => {
      changeUserEmailRequest({
        onCompleted: () => {
          setStep(1)
          setNewEmail(email)
        },
        variables: { email, userId: data?.me?.id ?? '' }
      }).catch(logError)
    })),
    [data?.me?.id]
  )

  const handleConfirmSubmit = useCallback(
    ignoreAsync(confirmForm.handleSubmit(({ code }): void => {
      changeUserEmailConfirm({
        awaitRefetchQueries: true,
        onCompleted: () => {
          setStep(2)
        },
        refetchQueries: ['GetUserSettingsInfo'],
        variables: { code, requestId: requestData?.requestChangeUserEmail?.id ?? '' }
      }).catch(logError)
    })),
    [requestData?.requestChangeUserEmail?.id]
  )

  useEffect(() => {
    if (data?.me?.username != null && data.me.username !== '' && oldEmail === '') setOldEmail(data.me.username)
  }, [data?.me?.username])

  useEffect(() => {
    if (isInSudoMode || step > 0) return

    navigation.navigate('AccountEdit', {})
  }, [isInSudoMode])

  return (
    <Layout hideTitle screenType={ScreenType.Form} title='Ändra användarnamn'>
      <FormContainer gap={20} title='Ändra användarnamn'>
        <Steps
          confirmError={confirmError?.message == null ? null : confirmError.message}
          confirmForm={confirmForm}
          confirmLoading={confirmLoading}
          newEmail={newEmail}
          oldEmail={oldEmail}
          onConfirmSubmit={handleConfirmSubmit}
          onNavigateToAccount={handleNavigateToAccount}
          onRequestSubmit={handleRequestSubmit}
          requestError={requestError?.message == null ? null : requestError.message}
          requestForm={requestForm}
          requestLoading={requestLoading}
          step={step}
        />
      </FormContainer>
    </Layout>
  )
}

export default EditUsername

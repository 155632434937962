import React from 'react'
import { Image } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unreachable from 'ts-unreachable'

import { type MenuProductPropertiesFieldsFragment, MenuType } from '../../../types/graphql'
import formatCurrency from '../../util/formatCurrency'
import { title as headline } from '../../util/menuTypes'

interface ProductVariantViewProps {
  menuType: MenuType
  titleIcon?: React.ReactNode
  variant: MenuProductPropertiesFieldsFragment
}

function backgroundColor (menuType: MenuType): string {
  switch (menuType) {
    case MenuType.Delivery:
      return '#AAAAAA'
    case MenuType.EatIn:
      return '#CFCFCF'
    case MenuType.GiftCard:
      return '#DEDEDE'
    case MenuType.TakeAway:
      return '#EEEEEE'
    default:
      unreachable(menuType)
  }
}

const ProductVariantView: React.FC<ProductVariantViewProps> = ({ menuType, titleIcon, variant }) => {
  return (
    <HStack backgroundColor={backgroundColor(menuType)} borderRadius={4} padding={10} wrap>
      <Image
        source={{ uri: variant.imageUrl == null ? require('../../../assets/placeholder.png') : variant.imageUrl }}
        style={{ borderRadius: 5, height: 64, width: 64 }}
      />

      <Spacer width={16} />

      <VStack grow={1} minWidth={160}>
        <HStack>
          {titleIcon}
          <Text weight='bold'>{headline(menuType)}</Text>
        </HStack>

        <Text size={12}>Alternativ: {variant.alternativeGroups?.map(alternativeGroup => alternativeGroup.name).join(', ')}</Text>
        <Text size={12}>Skrivare: {variant.printAt?.map(printer => printer.name).join(', ')}</Text>
        <Text size={12}>Öppettider: {variant.openingHours?.name}</Text>
      </VStack>

      <VStack alignItems='end' grow={1} minWidth={100}>
        <Text size={19}>
          {(variant.priceIsOpen ?? false) ? 'Öppet pris' : null}
          {(variant.price == null) ? null : formatCurrency(variant.price)}
        </Text>
        <Text size={12}>{variant.reportGroup?.name} ({variant.reportGroup?.vatRate}%)</Text>
        <Text size={12}>{variant.description}</Text>
      </VStack>
    </HStack>
  )
}

export default ProductVariantView

import React from 'react'
import { FlatList, type ListRenderItemInfo } from 'react-native'
import Spacer from 'react-spacer'
import { VStack } from 'react-stacked'

import { type FullPaymentMethodFragment, useGetRestaurantPaymentMethodListDataQuery } from '../../types/graphql'
import { AddButton } from '../components/Buttons'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import RestaurantPaymentMethodListItem from '../components/RestaurantPaymentMethodListItem'
import useNavigation from '../util/useNavigation'

const RestaurantPaymentMethodList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantPaymentMethodList'>()

  const { data, loading } = useGetRestaurantPaymentMethodListDataQuery({ variables: { restaurantId } })

  const paymentMethods = data?.restaurant?.paymentMethods ?? []

  const hasSwish = paymentMethods.some(item => item.id === 'builtin-swish')
  const hasStripe = paymentMethods.some(item => item.id === 'builtin-stripe')

  const handlePress = (item: FullPaymentMethodFragment): void => {
    if (item.id === 'builtin-stripe') {
      navigation.navigate('RestaurantStripeEdit', { restaurantId })
    } else if (item.id === 'builtin-swish') {
      navigation.navigate('RestaurantSwishEdit', { restaurantId })
    } else {
      navigation.navigate('RestaurantPaymentMethodEdit', { restaurantId, paymentMethodId: item.id })
    }
  }

  const renderPaymentMethodListItem = ({ item }: ListRenderItemInfo<FullPaymentMethodFragment>): JSX.Element => <RestaurantPaymentMethodListItem item={item} onPress={handlePress} />

  return (
    <Layout hideTitle loading={loading} screenType={ScreenType.List} title='Betalsätt'>
      <ListContainer title='Betalsätt'>
        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('RestaurantPaymentMethodCreate', { restaurantId })}
          title='Skapa nytt betalsätt'
        />

        <VStack gap={8}>
          {hasSwish ? null : (
            <RestaurantPaymentMethodListItem
              item={{
                id: 'builtin-swish',
                iconUrl: 'https://locousercontent.com/JewWibMKyYdwOYumfQKuu45mSoRdR3e299iXED9RwETItD6FCEQqaLvI_lP2s7iq/original.png',
                name: 'Swish'
              }}
              onPress={handlePress}
            />
          )}

          {hasStripe ? null : (
            <RestaurantPaymentMethodListItem
              item={{
                id: 'builtin-stripe',
                iconUrl: 'https://locousercontent.com/VTO3rbMhJqadi9iOfbIWPx2h6LRTQhUBErPr19v5IFlJ7QCftVHngTtAIFsinG6q/original.png',
                name: 'Stripe'
              }}
              onPress={handlePress}
            />
          )}

          <FlatList
            data={paymentMethods}
            ItemSeparatorComponent={() => <Spacer height={8} />}
            keyExtractor={item => item.id}
            renderItem={renderPaymentMethodListItem}
          />
        </VStack>
      </ListContainer>
    </Layout>
  )
}

export default RestaurantPaymentMethodList

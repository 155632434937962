import React from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'
import { Pressable } from 'react-native'
import { HStack, Text } from 'react-stacked'
import WithSeparator from 'react-with-separator'

import { ErrorField } from './fields'

interface UncontrolledMultiSelectButtonGroupFieldProps<TFieldValues extends FieldValues, V> {
  buttons: ReadonlyArray<{ label: string, value: V }>
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
  selectMultiple: true
}

interface ControlledMultiSelectButtonGroupFieldProps<V> {
  buttons: ReadonlyArray<{ label: string, value: V }>
  onChange: (items: V[]) => void
  selectMultiple: true
  value?: V[]
}

interface UncontrolledButtonGroupFieldProps<TFieldValues extends FieldValues, V> {
  buttons: ReadonlyArray<{ label: string, value: V }>
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
}

interface ControlledButtonGroupFieldProps<V> {
  buttons: ReadonlyArray<{ label: string, value: V }>
  onPress: (item: V) => void
  selected?: V
}

type Props<T extends FieldValues, V> = UncontrolledMultiSelectButtonGroupFieldProps<T, V> | ControlledMultiSelectButtonGroupFieldProps<V> | UncontrolledButtonGroupFieldProps<T, V> | ControlledButtonGroupFieldProps<V>

export default function ButtonGroup<T extends FieldValues, V extends string | number | null> ({ buttons, ...props }: Props<T, V>): JSX.Element {
  if ('form' in props) {
    return (
      <>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onChange, value } }) => {
            if ('selectMultiple' in props) {
              return (
                <ButtonGroup
                  buttons={buttons}
                  onChange={onChange}
                  selectMultiple
                  value={value}
                />
              )
            }
            return (
              <ButtonGroup
                buttons={buttons}
                onPress={onChange}
                selected={value}
              />
            )
          }}
        />

        <ErrorField message={get(props.form.formState.errors, props.name)?.message} />
      </>
    )
  }

  return (
    <HStack borderColor='#eee' borderStyle='solid' borderWidth={1}>
      <WithSeparator separator={<HStack backgroundColor='#eee' width={1} />}>
        {buttons.map((button) => {
          const selected = ('selectMultiple' in props) ? (props.value?.includes(button.value) ?? false) : (button.value === props.selected)
          const onPress = ('selectMultiple' in props) ? () => props.onChange(selected ? props.value?.filter(v => v !== button.value) ?? [] : [...(props.value ?? []), button.value]) : () => props.onPress(button.value)

          return (
            <Pressable key={String(button.value)} onPress={onPress} style={{ flex: 1 }}>
              <Text
                align='center'
                backgroundColor={selected ? '#2089dc' : 'white'}
                color={selected ? 'white' : 'black'}
                height='100%'
                paddingHorizontal={8}
                paddingVertical={10}
                size={16}
                weight='bold'
              >
                {button.label}
              </Text>
            </Pressable>
          )
        })}
      </WithSeparator>
    </HStack>
  )
}

import { Text } from 'react-stacked'

import { ERROR_COLOR } from '../lib/color'

import { containerErrorStyle } from './FormControl'

export const FormError: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Text {...containerErrorStyle} color={ERROR_COLOR} paddingVertical={5} size={20}>
    {children}
  </Text>
)

import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, Image } from 'react-native'
import { HStack, Text, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type CreateAccessTokenMutationVariables, useCreateAccessTokenMutation, useGetCreateUserDataQuery } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import { FormError } from '../components/FormError'
import { TextField } from '../components/TextField'
import { clearAccessToken, hasAccessToken, setAccessToken } from '../lib/token-storage'
import yup, { yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import { normalize } from '../util/normalize'
import redirectToLanding from '../util/redirectToLanding'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateAccessToken($username: String!, $password: String!) {
    createAccessToken(username: $username, password: $password) {
      parseToken
    }
  }
`

const schema = yup.object({
  username: yup.string().trim().required('Vänligen ange din e-postadress'),
  password: yup.string().trim().required('Vänligen ange ditt lösenord')
})

const Login: React.FC = () => {
  const [navigation] = useNavigation<'Login'>()

  const { data, loading } = useGetCreateUserDataQuery({ fetchPolicy: 'network-only' })

  const [createAccessToken, { loading: submitting }] = useCreateAccessTokenMutation()

  const form = useForm<CreateAccessTokenMutationVariables>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const [error, setError] = useState<'credentials' | 'unknown' | null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null)

  const isFocused = navigation.isFocused()

  const handleLogin = async ({ username, password }: CreateAccessTokenMutationVariables): Promise<void> => {
    setError(null)

    try {
      await clearAccessToken()
      const { data } = await createAccessToken({ variables: { username: username.toLowerCase(), password } })
      const token = unwrap(data?.createAccessToken?.parseToken)
      await setAccessToken(token)
      await redirectToLanding(navigation)
    } catch (error) {
      if (
        error.graphQLErrors?.[0]?.extensions?.state?.username != null ||
        error.graphQLErrors?.[0]?.extensions?.state?.password != null
      ) {
        setError('credentials')
      } else {
        setError('unknown')
        logError(error)
      }
    }
  }

  useEffect(() => {
    if (!isFocused) return
    if (loading) return

    if (isLoggedIn ?? false) {
      if (data?.me?.username == null) {
        clearAccessToken().catch(logError)
        return
      }

      redirectToLanding(navigation).catch(logError)
    }
  }, [data?.me, isFocused, isLoggedIn, loading])

  useEffect(() => {
    async function setLoginStatus (): Promise<void> {
      setIsLoggedIn(await hasAccessToken())
    }

    setLoginStatus().catch(logError)
  }, [])

  return (
    <VStack backgroundColor='black' grow={1}>
      <Image source={require('../../assets/icon.png')} style={{ height: normalize(75, 90), width: normalize(75, 90) }} />

      <HStack alignItems='center' justifyContent='center'>
        <VStack gap={36} grow={1} maxWidth={480}>
          {loading
            ? <ActivityIndicator color='white' size='large' />
            : (
              <>
                <VStack backgroundColor='#fff' gap={24} padding={24}>
                  {error == null ? null : (
                    <FormError>
                      {error === 'credentials' ? 'Felaktig e-postadress eller lösenord' : 'Ett okänt fel inträffade, vänligen se över din internetanslutning och försök igen'}
                    </FormError>
                  )}

                  <TextField
                    autoFocus
                    form={form}
                    inputMode='email'
                    name='username'
                    title='E-postadress'
                  />

                  <TextField
                    form={form}
                    name='password'
                    onSubmitEditing={ignoreAsync(form.handleSubmit(handleLogin))}
                    secureTextEntry
                    title='Lösenord'
                  />

                  <PrimaryButton
                    loading={submitting}
                    onPress={ignoreAsync(form.handleSubmit(handleLogin))}
                    title='Logga in'
                  />

                  <SecondaryButton
                    onPress={() => navigation.navigate('PasswordReset', {})}
                    title='Glömt lösenordet?'
                  />
                </VStack>

                <VStack backgroundColor='#fff' gap={24} padding={24}>
                  <Text size={20}>Inte med i Loco ännu?</Text>

                  <PrimaryButton onPress={() => navigation.navigate('UserCreate', {})} title='Registrera en ny användare' />
                </VStack>
              </>
            )}
        </VStack>
      </HStack>
    </VStack>
  )
}

export default Login

import React from 'react'
import { Text } from 'react-stacked'

import { Content, type DoneState } from './MultiEditForm'

interface DoneStepProps extends DoneState {
  onNext: () => void
}

const DoneStep: React.FC<DoneStepProps> = ({ errorMessage, onNext }) => {
  return (
    <Content
      nextButtonTitle='OK'
      onNext={onNext}
      title='Uppdateringen klar!'
    >
      <Text color={errorMessage == null ? undefined : 'red'}>{errorMessage ?? 'Tryck på OK för att stänga denna dialogruta'}</Text>
    </Content>
  )
}

export default DoneStep

import { useIsFocused } from '@react-navigation/native'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HStack } from 'react-stacked'

import { GetRestaurantReceiptListDataDocument, type GetRestaurantReceiptListDataQuery, type GetRestaurantReceiptListDataQueryVariables, type OrderFilter, useGetRestaurantReceiptSummaryDataQuery } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import ReceiptSearchDialog, { type FormInput, schema } from '../components/ReceiptSearchDialog'
import { yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'
import useQuery from '../util/useQuery'

import ReceiptList from './ReceiptList'

gql`
  query GetRestaurantReceiptListData($restaurantId: ID!, $cursor: String, $filter: OrderFilter) {
    restaurant(id: $restaurantId) {
      id

      hasDeliveryHeroAccess
      timeZone

      orders(first: 25, after: $cursor, filter: $filter) {
        edges {
          node {
            ...ReceiptListOrder
          }
        }

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }

  query GetRestaurantReceiptSummaryData ($orderId: ID!, $restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      name
      timeZone

      order (id: $orderId) {
        ...FullOrder
      }
    }
  }
`

const RestaurantReceiptList: React.FC = () => {
  const [, { restaurantId }] = useNavigation<'RestaurantReceiptList'>()

  const form = useForm<FormInput>({
    criteriaMode: 'all',
    defaultValues: {
      showingDateInterval: false,
      transactionChannels: null
    },
    mode: 'onTouched',
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  const isFocused = useIsFocused()
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const [filter, setFilter] = useState<OrderFilter | null>(null)
  const [showingSearchDialog, setShowingSearchDialog] = useState(false)
  const [showingReceiptSummary, setShowingReceiptSummary] = useState<string | null>(null)
  const { data, error, fetchingMore, fetchMore, loading, refetch, refetching } = useQuery<GetRestaurantReceiptListDataQuery, GetRestaurantReceiptListDataQueryVariables>(GetRestaurantReceiptListDataDocument, { handlesStaleData: 'briefly', pollInterval: 60000, variables: { restaurantId, filter } })
  const { data: restaurantReceiptSummaryData } = useGetRestaurantReceiptSummaryDataQuery({
    skip: showingReceiptSummary == null,
    variables: showingReceiptSummary == null ? undefined : { orderId: showingReceiptSummary, restaurantId }
  })

  const receiptDialogData = restaurantReceiptSummaryData?.restaurant?.order ?? null

  const orders = data?.restaurant?.orders?.edges?.map(edge => edge.node) ?? []

  const loadMore = ignoreAsync(async () => {
    const pageInfo = data?.restaurant?.orders?.pageInfo
    if (pageInfo?.hasNextPage ?? false) {
      await fetchMore({
        updateQuery (previousResult, { fetchMoreResult }) {
          if (previousResult.restaurant?.orders == null) {
            return previousResult
          }

          const newEdges = fetchMoreResult?.restaurant?.orders?.edges

          if (newEdges == null || newEdges.length === 0) {
            return previousResult
          }

          return dotProp.set(previousResult, 'restaurant.orders', {
            __typename: previousResult.restaurant.orders.__typename,
            edges: [...previousResult.restaurant.orders.edges, ...newEdges],
            pageInfo: fetchMoreResult?.restaurant?.orders?.pageInfo
          })
        },
        variables: { restaurantId, cursor: pageInfo?.endCursor ?? null, filter }
      }).catch(logError)
    }
  })

  const handleRefresh = ignoreAsync(async () => {
    setErrorDialogVisible(false)

    try {
      await refetch()
    } catch (error) {
      logError(error)
      setErrorDialogVisible(true)
    }
  })

  const handleClearHistorySearch = (): void => {
    setFilter(null)
    form.reset()
  }

  const handleSearch = (filter: OrderFilter): void => {
    setFilter(filter)
    setShowingSearchDialog(false)
  }

  const handleSearchButtonPress = (): void => {
    setShowingSearchDialog(true)
  }

  useEffect(() => {
    if (error == null) return
    setErrorDialogVisible(true)
  }, [error])

  useEffect(() => {
    if (!isFocused) {
      setErrorDialogVisible(false)
      setShowingSearchDialog(false)
    }
    // These deps are necessary because if logged out the states of the dialogs change after isFocused is false, and so will open on the login screen
  }, [errorDialogVisible, isFocused, showingSearchDialog])

  useEffect(() => {
    // Close dialog on dismount
    return () => {
      setErrorDialogVisible(false)
      setShowingSearchDialog(false)
    }
  }, [])

  return (
    <Layout hideTitle screenType={ScreenType.List} title='Kvitton'>
      <ListContainer title='Kvitton'>
        {!showingSearchDialog
          ? null
          : (
            <ReceiptSearchDialog
              form={form}
              hasDeliveryHeroAccess={data?.restaurant?.hasDeliveryHeroAccess ?? false}
              onDismiss={() => setShowingSearchDialog(false)}
              onSubmit={handleSearch}
              timeZone={data?.restaurant?.timeZone ?? 'Europe/Stockholm'}
            />
          )}

        <HStack gap={8} justifyContent='end'>
          {filter == null ? null : <SecondaryButton icon='clear' onPress={handleClearHistorySearch} title='Ta bort sökning' />}

          <PrimaryButton icon='search' onPress={handleSearchButtonPress} title='Sök' />
        </HStack>

        <ReceiptList
          canFetchMore={data?.restaurant?.orders?.pageInfo.hasNextPage ?? false}
          fetchingMore={fetchingMore}
          loading={loading}
          loadingSelectedOrder={restaurantReceiptSummaryData == null}
          onCloseDialog={() => setShowingReceiptSummary(null)}
          onFetchMore={loadMore}
          onRefresh={handleRefresh}
          onSelectOrder={setShowingReceiptSummary}
          orders={orders}
          refetching={refetching}
          selectedOrder={receiptDialogData}
          showReceiptSummaryDialog={showingReceiptSummary != null}
        />
      </ListContainer>
    </Layout>
  )
}

export default RestaurantReceiptList

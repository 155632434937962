import { type ApolloError } from '@apollo/client'
import React from 'react'
import { useWindowDimensions } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { CancelButton, DeleteButton, PrimaryButton, RedoButton } from './Buttons'
import { ErrorField } from './fields'

const noop = (): void => {}

interface SubmitFormButtonsProps {
  called?: boolean
  deleting?: boolean
  disableCancelButton?: boolean
  disableDeleteButton?: boolean
  disableSaveButton?: boolean
  error?: ApolloError
  onCancel?: () => void
  onDelete?: () => void
  onReset?: () => void
  onSave: () => void
  isDirty?: boolean
  saving?: boolean
  successMessage?: string
  titleForCancel?: string
  titleForDelete?: string
  titleForSubmit?: string
}

interface ResponsiveStackProps {
  children: React.ReactNode
}

const ResponsiveStack: React.FC<ResponsiveStackProps> = ({ children }) => {
  const windowWidth = useWindowDimensions().width
  return windowWidth > 500 ? <HStack gap={12}>{children}</HStack> : <VStack gap={6}>{children}</VStack>
}

const SubmitFormButtons: React.FC<SubmitFormButtonsProps> = ({ called = false, deleting = false, disableDeleteButton = false, disableSaveButton, disableCancelButton = false, error, onCancel, onDelete, onReset, onSave, isDirty = false, saving = false, successMessage, titleForCancel = 'Avbryt', titleForSubmit = 'Spara', titleForDelete = 'Radera' }) => {
  const windowWidth = useWindowDimensions().width
  return (
    <ResponsiveStack>
      {onDelete == null ? null : (
        <DeleteButton
          disabled={disableDeleteButton}
          loading={deleting}
          onPress={onDelete ?? noop}
          title={titleForDelete}
        />
      )}

      {windowWidth > 500 && <Spacer grow={1} />}

      {onCancel == null ? null : (
        <CancelButton
          disabled={disableCancelButton}
          onPress={onCancel ?? noop}
          title={titleForCancel}
        />
      )}

      {!isDirty ? null : (
        <RedoButton
          onPress={onReset ?? noop}
          title='Återställ'
        />
      )}

      {(!called || isDirty || saving) ? null : (
        <Text padding={8} weight='500'>
          {(error != null) ? <ErrorField message={error.message} /> : (successMessage ?? 'Ändringen har sparats')}
        </Text>
      )}

      <PrimaryButton
        disabled={disableSaveButton}
        loading={saving}
        onPress={onSave}
        title={titleForSubmit}
      />
    </ResponsiveStack>
  )
}

export default SubmitFormButtons

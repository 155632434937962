import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type FullOrganizationUserDetailsFragment, type OrganizationUserInput } from '../../types/graphql'
import { TextField } from '../components/TextField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import { RedoButton } from './Buttons'
import InfoIcon from './InfoIcon'
import SubmitFormButtons from './SubmitFormButtons'
import { Cell, Column, Row, Table } from './Table'
import { CheckBox } from './fields'

const globalPermissions = [
  { key: 'hasEditOrganizationUserAccess', label: 'Administrera användare', tooltip: 'Kan redigera ta bort och lägga till användare på organisationen.' },
  { key: 'hasOrganizationAccountingAccess', label: 'Bokföring', tooltip: 'Kommer åt fliken för Bokföring samt full rapportbehörighet på alla restauranger under organisationen.' }
] as const

const permissions = [
  { key: 'hasEditMenuAccess', label: 'Meny - redigera', tooltip: 'Kan lägga till, redigera och ta bort produkter och menyer.' },
  { key: 'hasEditMenuStockAccess', label: 'Meny - lagersaldo', tooltip: 'Kan ändra lagersaldo och aktivera/inaktivera huvud- och underkategorier, samt produkter.' },
  { key: 'hasEditOpeningHoursAccess', label: 'Öppettider - redigera', tooltip: 'Kan redigera öppettider för restaurangen och serveringstider för maträtter genom Öppettider i sidomenyn.' },
  { key: 'hasEditReportsAccess', label: 'Rapporter - redigera', tooltip: 'Kan redigera automatiskt dagsavslut, e-postadress till ekonomiansvarig mm genom Rapporter i sidomenyn, samt Rapportgrupper.' },
  { key: 'hasReadReportsAccess', label: 'Rapporter - läsa', tooltip: 'Kan läsa och exportera rapporter genom Rapporter i sidomenyn.' },
  { key: 'hasPosAccess', label: 'Kassasystemsadministratör', tooltip: 'Kan redigera Kassor för POS och Anställda för att arbeta med kassasystem och expresskassa. Kan logga in i expresskasseläge och logga in med pinkod i kassasystemet  (konfigureras i sidomenyn > Anställda). Med denna rättighet styrs vad man kan göra i kassasystemet helt av rättigheterna på Anställda, och inte med de val man kan göra på denna sida.' },
  { key: 'hasDiscountAccess', label: 'Rabattkoder - redigera', tooltip: 'Kan lägga till och redigera rabatter i sidomenyn.' },
  { key: 'hasDiscountedCheckoutAccess', label: 'Rabatter i kassan', tooltip: 'Kan ge rabatter i kassasystemet utan att ange rabattkod.' },
  { key: 'hasSettingsAccess', label: 'Inställningar & Design', tooltip: 'Kan redigera samtliga inställningar under sidomenyn>Inställningar och sidomenyn>Design.' },
  { key: 'hasExternalPaymentAccess', label: 'Manuella betalsätt', tooltip: 'Kan göra köp med manuella betalsätt så som kontanter, ej kassakopplade eller externa kortterminaler, eller andra betalsätt som kräver manuell verifikation av att betalning gått igenom i kassasystemet.' },
  { key: 'hasCreateCashAdjustmentAccess', label: 'Kan justera växelkassa', tooltip: 'Kan justera växelkassa genom att trycka på knappen Justera växelakassa i kassasystemet.' },
  { key: 'hasOpenPriceAccess', label: 'Öppet pris', tooltip: 'Kan lista och checka ut produkter med öppet pris, alltså priser med "Öppet" satt på produkten i menyn, i kassasystemet.' },
  { key: 'hasOrderRefundAccess', label: 'Återbetala och makulera', tooltip: 'Kan återbetala kvitton och makulera varor i öppna notor i kassasystemet.' },
  { key: 'hasOrderHistoryAccess', label: 'Orderhistorik', tooltip: 'Kan se orderhistorik i kassasystemet.' },
  { key: 'hasOpenOrderAccess', label: 'Öppna notor', tooltip: 'Kan använda öppna notor i kassasystemet.' }
] as const

type GlobalPermissionKey = typeof globalPermissions[number]['key']
type PermissionKey = typeof permissions[number]['key']
type PermissionsRecord = Partial<Record<PermissionKey, boolean>>

function getPermissionDefaults (input: FullOrganizationUserDetailsFragment | NonNullable<FullOrganizationUserDetailsFragment['restaurantPermissions']>[number] | null | undefined): PermissionsRecord {
  return permissions.reduce<PermissionsRecord>((acc, permission) => {
    acc[permission.key] = input?.[permission.key] ?? false
    return acc
  }, {})
}

interface OrganizationUserFormProps {
  canUpdateOwners: boolean
  deleting?: boolean
  initialValues?: FullOrganizationUserDetailsFragment | null
  onCancel: () => void
  onDelete?: () => void
  onResendInvite?: () => void
  onSubmit: (input: OrganizationUserInput) => void
  restaurants?: Array<{ id: string, name?: string | null }> | null
  saving: boolean
}

const schema: ObjectSchema<OrganizationUserInput> = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  hasCreateCashAdjustmentAccess: yup.boolean(),
  hasDiscountAccess: yup.boolean(),
  hasDiscountedCheckoutAccess: yup.boolean(),
  hasEditMenuAccess: yup.boolean(),
  hasEditMenuStockAccess: yup.boolean(),
  hasEditOpeningHoursAccess: yup.boolean(),
  hasEditOrganizationUserAccess: yup.boolean(),
  hasEditReportsAccess: yup.boolean(),
  hasExternalPaymentAccess: yup.boolean(),
  hasOpenOrderAccess: yup.boolean(),
  hasOpenPriceAccess: yup.boolean(),
  hasOrderHistoryAccess: yup.boolean(),
  hasOrderRefundAccess: yup.boolean(),
  hasOrganizationAccountingAccess: yup.boolean(),
  hasPosAccess: yup.boolean(),
  hasReadReportsAccess: yup.boolean(),
  hasSettingsAccess: yup.boolean(),
  isOwner: yup.boolean(),
  restaurantPermissions: yup.array().of(
    yup.object().shape({
      restaurantId: yup.string().required(),
      hasCreateCashAdjustmentAccess: yup.boolean(),
      hasDiscountAccess: yup.boolean(),
      hasDiscountedCheckoutAccess: yup.boolean(),
      hasEditMenuAccess: yup.boolean(),
      hasEditMenuStockAccess: yup.boolean(),
      hasEditOpeningHoursAccess: yup.boolean(),
      hasEditReportsAccess: yup.boolean(),
      hasExternalPaymentAccess: yup.boolean(),
      hasOpenPriceAccess: yup.boolean(),
      hasOrderRefundAccess: yup.boolean(),
      hasPosAccess: yup.boolean(),
      hasReadReportsAccess: yup.boolean(),
      hasSettingsAccess: yup.boolean()
    })
  )
})

const OrganizationUserForm: React.FC<OrganizationUserFormProps> = ({
  canUpdateOwners,
  deleting,
  initialValues,
  onCancel,
  onDelete,
  onResendInvite,
  onSubmit,
  restaurants,
  saving
}) => {
  const defaultValues = useMemo(() => {
    const restaurantPermissions = restaurants?.map((restaurant) => {
      const restaurantPermission = initialValues?.restaurantPermissions?.find((restaurantPermission) => restaurantPermission.restaurant?.id === restaurant.id)

      return { restaurantId: restaurant.id, ...getPermissionDefaults(restaurantPermission) }
    })

    return {
      email: initialValues?.email ?? '',
      hasEditOrganizationUserAccess: initialValues?.hasEditOrganizationUserAccess ?? false,
      hasOrganizationAccountingAccess: initialValues?.hasOrganizationAccountingAccess ?? false,
      isOwner: initialValues?.isOwner,
      name: initialValues?.name ?? '',
      restaurantPermissions,
      ...getPermissionDefaults(initialValues)
    }
  }, [initialValues, restaurants])

  const form = useForm<OrganizationUserInput>({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    values: defaultValues
  })

  const toggleOrganizationPermission = (permissionKey: GlobalPermissionKey | PermissionKey): void => {
    const current = form.getValues(permissionKey) ?? false

    form.setValue(permissionKey, !current)

    if (permissionKey === 'hasOrganizationAccountingAccess' && !current) {
      form.setValue('hasEditReportsAccess', true)
      form.setValue('hasReadReportsAccess', true)
    }

    if (permissionKey === 'hasEditMenuAccess' && !current) {
      form.setValue('hasEditMenuStockAccess', true)
    }

    if (permissionKey === 'hasEditReportsAccess' && !current) {
      form.setValue('hasReadReportsAccess', true)
    }
  }

  const toggleRestaurantPermission = (restaurantIndex: number, permissionKey: PermissionKey): void => {
    const current = form.getValues(`restaurantPermissions.${restaurantIndex}.${permissionKey}`) ?? false

    form.setValue(`restaurantPermissions.${restaurantIndex}.${permissionKey}`, !current)

    if (permissionKey === 'hasEditMenuAccess' && !current) {
      form.setValue(`restaurantPermissions.${restaurantIndex}.hasEditMenuStockAccess`, true)
    }

    if (permissionKey === 'hasEditReportsAccess' && !current) {
      form.setValue(`restaurantPermissions.${restaurantIndex}.hasReadReportsAccess`, true)
    }
  }

  const { restaurantPermissions, ...organizationPermissions } = form.watch()

  return (
    <VStack backgroundColor='#fefefe' borderRadius={5} gap={16} maxWidth='100%' padding={16}>
      <TextField autoFocus form={form} name='name' title='Namn' />
      <TextField form={form} hint='E-postadress kan endast ändras av användaren själv' name='email' readOnly={Boolean(initialValues)} title='Email' />

      {!canUpdateOwners && !(initialValues?.isOwner ?? false) ? null : (
        <HStack alignItems='center'>
          <CheckBox
            disabled={!canUpdateOwners}
            form={form}
            name='isOwner'
            title='Ägare'
          />

          <InfoIcon content='Fullständiga rättigheter. Varning: Kan ta bort andra ägare.' />
        </HStack>
      )}

      {(organizationPermissions.isOwner ?? false) ? null : (
        <>
          {globalPermissions.map((permission) => (
            <HStack key={permission.key} alignItems='center'>
              <CheckBox
                checked={organizationPermissions[permission.key] ?? false}
                onPress={() => toggleOrganizationPermission(permission.key)}
                title={permission.label}
              />

              <InfoIcon content={permission.tooltip} />
            </HStack>
          ))}

          {(initialValues?.userSignupCompleted === false && onResendInvite != null) && (
            <HStack justifyContent='end'>
              <RedoButton
                onPress={onResendInvite}
                title='Skicka e-post igen'
              />
            </HStack>
          )}

          <Text paddingTop={20} size={20} weight='500'>Rättigheter</Text>

          <ScrollView horizontal>
            <Table borderRadius={4}>
              <Column paddingHorizontal={8} width={250} />

              <Column width={60} />
              {restaurants?.map((restaurant) => <Column key={restaurant.id} width={60} />)}

              <Row backgroundColor='transparent' paddingBottom={4} paddingTop={140}>
                <Cell />

                <Cell paddingLeft={24}>
                  <Text rotate={-45} width={200}>Alla restauranger</Text>
                </Cell>

                {restaurants?.map((restaurant) => (
                  <Cell key={restaurant.id} paddingLeft={24}>
                    <Text rotate={-45} width={200}>{restaurant.name}</Text>
                  </Cell>
                ))}
              </Row>

              {permissions.map((permission) => {
                let isPrerequisitePermission = false

                if (permission.key === 'hasEditMenuStockAccess') {
                  isPrerequisitePermission ||= organizationPermissions.hasEditMenuAccess ?? false
                }

                if (permission.key === 'hasEditReportsAccess') {
                  isPrerequisitePermission ||= organizationPermissions.hasOrganizationAccountingAccess ?? false
                }

                if (permission.key === 'hasReadReportsAccess') {
                  isPrerequisitePermission ||= organizationPermissions.hasEditReportsAccess ?? false
                  isPrerequisitePermission ||= organizationPermissions.hasOrganizationAccountingAccess ?? false
                }

                return (
                  <Row key={permission.key} align='center' backgroundColor='white' borderColor='#999' borderStyle='solid' borderWidth={StyleSheet.hairlineWidth}>
                    <Cell>
                      <HStack alignItems='center' justifyContent='space-between'>
                        <Text>{permission.label}</Text>
                        <InfoIcon content={permission.tooltip} />
                      </HStack>
                    </Cell>

                    <Cell>
                      <CheckBox
                        checked={organizationPermissions[permission.key] ?? false}
                        disabled={isPrerequisitePermission}
                        name={permission.key}
                        onPress={() => toggleOrganizationPermission(permission.key)}
                      />
                    </Cell>

                    {restaurants?.map((restaurant, index) => {
                      const hasOrganizationPermission = organizationPermissions[permission.key] === true

                      let isPrerequisitePermission = false

                      if (permission.key === 'hasEditMenuStockAccess') {
                        isPrerequisitePermission = (organizationPermissions.hasEditMenuAccess ?? false) || (restaurantPermissions?.[index]?.hasEditMenuAccess ?? false)
                      }

                      if (permission.key === 'hasReadReportsAccess') {
                        isPrerequisitePermission = (organizationPermissions.hasEditReportsAccess ?? false) || (restaurantPermissions?.[index]?.hasEditReportsAccess ?? false)
                      }

                      return (
                        <Cell key={restaurant.id}>
                          <CheckBox
                            checked={hasOrganizationPermission || (restaurantPermissions?.[index]?.[permission.key] ?? false)}
                            disabled={hasOrganizationPermission || isPrerequisitePermission}
                            onPress={() => toggleRestaurantPermission(index, permission.key)}
                          />
                        </Cell>
                      )
                    })}
                  </Row>
                )
              })}
            </Table>

            <Spacer width={140} />
          </ScrollView>
        </>
      )}

      <SubmitFormButtons
        deleting={deleting}
        disableSaveButton={saving}
        onCancel={onCancel}
        onDelete={!canUpdateOwners && (initialValues?.isOwner ?? false) ? undefined : onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSubmit))}
        saving={saving}
      />
    </VStack>
  )
}

export default OrganizationUserForm

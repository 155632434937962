import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { Pressable } from 'react-native'
import unreachable from 'ts-unreachable'

import { ACCENT_COLOR } from '../../lib/color'

export type TriState = 'checked' | 'unchecked' | 'indeterminate'

export function nextState (state: TriState): TriState {
  switch (state) {
    case 'checked':
      return 'unchecked'
    case 'unchecked':
      return 'checked'
    case 'indeterminate':
      return 'checked'
    default:
      unreachable(state)
  }
}

interface TriStateCheckBoxProps {
  state: TriState
  onPress: () => void
}

const TriStateCheckBox: React.FC<TriStateCheckBoxProps> = ({ state, onPress }) => {
  const checked = state !== 'unchecked'

  return (
    <Pressable accessibilityRole='checkbox' accessibilityState={{ checked }} onPress={onPress}>
      <MaterialIcons
        color={checked ? ACCENT_COLOR : '#bfbfbf'}
        name={checked ? (state === 'indeterminate' ? 'indeterminate-check-box' : 'check-box') : 'check-box-outline-blank'}
        size={24}
      />
    </Pressable>
  )
}

export default TriStateCheckBox

import { type Temporal } from '@js-temporal/polyfill'
import unreachable from 'ts-unreachable'

import type TimePeriod from './TimePeriod'

function startOfDay (date: Temporal.ZonedDateTime): Temporal.ZonedDateTime {
  return date.withPlainTime('00:00:00')
}

function startOfWeek (date: Temporal.ZonedDateTime): Temporal.ZonedDateTime {
  return date.subtract({ days: date.dayOfWeek - 1 })
}

export default function dateTimeRangeFromTimePeriod (timePeriod: TimePeriod, now: Temporal.Instant, timeZone: string = 'Europe/Stockholm'): { end: Temporal.ZonedDateTime, start: Temporal.ZonedDateTime } {
  const today = startOfDay(now.toZonedDateTimeISO(timeZone))

  switch (timePeriod.kind) {
    case 'Custom':
      return { start: timePeriod.start.toZonedDateTime({ timeZone, plainTime: '00:00:00' }), end: timePeriod.end.toZonedDateTime({ timeZone, plainTime: '00:00:00' }).add({ days: 1 }) }
    case 'Today':
      return { start: today, end: today.add({ days: 1 }) }
    case 'Yesterday':
      return { start: today.subtract({ days: 1 }), end: today }
    case 'ThisWeek': {
      const start = startOfWeek(today)
      return { start, end: start.add({ weeks: 1 }) }
    }
    case 'LastWeek': {
      const start = startOfWeek(today).subtract({ weeks: 1 })
      return { start, end: start.add({ weeks: 1 }) }
    }
    case 'ThisMonth': {
      const start = today.with({ day: 1 })
      return { start, end: start.add({ months: 1 }) }
    }
    case 'LastMonth': {
      const start = today.with({ day: 1 }).subtract({ months: 1 })
      return { start, end: start.add({ months: 1 }) }
    }
    case 'ThisYear': {
      const start = today.with({ month: 1, day: 1 })
      return { start, end: start.add({ years: 1 }) }
    }
    case 'LastYear': {
      const start = today.with({ year: today.year - 1, month: 1, day: 1 })
      return { start, end: start.add({ years: 1 }) }
    }
    default:
      return unreachable(timePeriod)
  }
}

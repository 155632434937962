import React from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { DeleteButton, PrimaryButton, SecondaryButton } from './Buttons'
import Dialog from './Dialog'

interface VerificationDialogProps {
  callToActionLabel?: string
  errorMessage: string | undefined
  loading: boolean
  onDismiss: () => void
  onDelete?: () => void
  onSubmit?: () => void
  open: boolean
  prompt: string
  title: string
}

const VerificationDialog: React.FC<VerificationDialogProps> = ({ callToActionLabel, errorMessage, loading, onDismiss, onDelete, onSubmit, open, prompt, title }) => (
  <Dialog maxWidth={400} visible={open}>
    <VStack gap={24} padding={24}>
      {loading
        ? <ActivityIndicator />
        : (
          <>
            <Text size={19}>{title}</Text>

            <Text>{prompt}</Text>

            <HStack justifyContent='end'>
              <SecondaryButton disabled={loading} onPress={onDismiss} title='Avbryt' />

              <Spacer width={12} />

              {onDelete == null ? null : <DeleteButton disabled={loading} onPress={onDelete} title={callToActionLabel ?? 'Ta bort'} />}
              {onSubmit == null ? null : <PrimaryButton disabled={loading} onPress={onSubmit} title={callToActionLabel ?? 'Skicka'} />}
            </HStack>

            {errorMessage == null
              ? null
              : <Text color='red' weight='bold'>{errorMessage}</Text>}
          </>
        )}
    </VStack>
  </Dialog>
)

export default VerificationDialog

import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useCallback, useMemo } from 'react'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { useListRestaurantsQuery } from '../../types/graphql'
import { SaveAsButton } from '../components/Buttons'
import Layout from '../components/Layout'
import { Cell, Column, Row, Table } from '../components/Table'
import { TextField } from '../components/fields'
import downloadCsvAsFile from '../util/downloadCsvAsFile'
import useNavigation from '../util/useNavigation'

gql`
  query ListRestaurants {
    restaurants(first: 999) {
      edges {
        node {
          id

          hasEnteredActivationCode
          name
          phoneNumber

          organization {
            id

            email
            name
          }
        }
      }
    }
  }
`

const RestaurantList: React.FC = () => {
  const [navigation, params] = useNavigation<'RestaurantList'>()

  const { data } = useListRestaurantsQuery()

  const handleFilterChange = useCallback((q: string) => navigation.setParams({ q }), [navigation])

  const handleFilterClear = useCallback(() => navigation.setParams({ q: undefined }), [navigation])

  const restaurants = useMemo(() => {
    let result = data?.restaurants?.edges ?? []
    if (params.q != null) {
      const regEx = new RegExp(params.q, 'i')
      result = result.filter(edge => edge?.node?.name?.match(regEx) != null || edge.node.id === params.q || edge.node.organization?.name?.match(regEx) != null)
    }
    return sortOn(result.map(edge => edge.node), 'name')
  }, [data?.restaurants?.edges, params.q])

  const handleCsvPress = useCallback(() => {
    const csvFileName = 'gastrogate_clients.csv'

    downloadCsvAsFile(
      csvFileName,
      restaurants.map(restaurant => ({
        Namn: restaurant.name ?? '',
        Telefonnummer: restaurant.phoneNumber ?? '',
        'E-post': restaurant.organization?.email ?? ''
      }))
    )
  }, [restaurants])

  return (
    <Layout title='Restauranger'>
      <VStack maxWidth={1080} paddingHorizontal={16}>
        <HStack alignItems='baseline' justifyContent='space-between' padding={8}>
          <Text size={22}>{restaurants?.length ?? '...'} restauranger</Text>

          <Spacer width={4} />

          <HStack alignItems='baseline'>
            <HStack alignItems='start' width={140}>
              <TextField id='filter' onChange={handleFilterChange} title='Filter' value={params.q ?? ''} />
              <MaterialIcons name='clear' onPress={handleFilterClear} style={{ opacity: params.q == null || params.q === '' ? 0 : 1 }} />
            </HStack>

            <SaveAsButton onPress={handleCsvPress} title='Spara som CSV' />
          </HStack>
        </HStack>

        <Table>
          <Column paddingHorizontal={8} />
          <Column paddingHorizontal={8} />

          <Row paddingVertical={8}>
            <Cell>
              <Text weight='bold'>Namn</Text>
            </Cell>

            <Cell>
              <Text weight='bold'>Länkar</Text>
            </Cell>
          </Row>

          {restaurants?.map(({ hasEnteredActivationCode, id, name }) => (
            <Row key={id} paddingVertical={8}>
              <Cell>
                <Text weight={hasEnteredActivationCode === true ? 'bold' : undefined}>{name}</Text>
              </Cell>

              <Cell>
                <HStack gap={8} wrap>
                  <MaterialIcons name='insert-chart' onPress={() => navigation.navigate('RestaurantStatisticsView', { restaurantId: id })} size={24} />
                  <MaterialIcons name='schedule' onPress={() => navigation.navigate('OpeningHoursList', { restaurantId: id })} size={24} />
                  <MaterialIcons name='event-available' onPress={() => navigation.navigate('TimeSlotsView', { restaurantId: id })} size={24} />
                  <MaterialIcons name='account-balance' onPress={() => navigation.navigate('CashregisterView', { restaurantId: id })} size={24} />
                  <MaterialIcons name='airline-seat-recline-normal' onPress={() => navigation.navigate('DeliveryLocationList', { restaurantId: id })} size={24} />

                  <MaterialIcons name='local-dining' onPress={() => navigation.navigate('MenuView', { restaurantId: id })} size={24} />
                  <MaterialIcons name='plus-one' onPress={() => navigation.navigate('MenuAddonProductList', { restaurantId: id })} size={24} />
                  <MaterialIcons name='storage' onPress={() => navigation.navigate('MenuAlternativeGroupList', { restaurantId: id })} size={24} />
                  <MaterialCommunityIcons name='truck-fast' onPress={() => navigation.navigate('DeliveryLocoEdit', { restaurantId: id })} size={24} />
                  <MaterialIcons name='description' onPress={() => navigation.navigate('ReportGroupList', { restaurantId: id })} size={24} />
                  <MaterialIcons name='palette' onPress={() => navigation.navigate('RestaurantDesignView', { restaurantId: id })} size={24} />
                  <MaterialIcons name='edit' onPress={() => navigation.navigate('RestaurantEdit', { restaurantId: id })} size={24} />
                  <MaterialCommunityIcons name='tag' onPress={() => navigation.navigate('RestaurantDiscountsList', { restaurantId: id })} size={24} />
                </HStack>
              </Cell>
            </Row>
          ))}
        </Table>
      </VStack>
    </Layout>
  )
}

export default RestaurantList

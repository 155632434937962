import React from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { Text } from 'react-stacked'

import { type EmployeeGroupFieldsFragment, type EmployeeGroupInput, type EmployeeInput } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'
import { CheckBox } from './fields'
import TextField from './fields/TextField'

interface EmployeeGroupFormProps {
  deleting?: boolean
  initialValues?: EmployeeGroupFieldsFragment
  onDelete?: () => void
  onDismiss: () => void
  onSave: (input: EmployeeInput) => void
  saving: boolean
}

const schema: ObjectSchema<EmployeeGroupInput> = yup.object({
  name: yup.string().trim().min(1, 'Namn med minst 1 tecken måste anges').required(),
  canLogoutTill: yup.boolean().required(),
  canListDiscounts: yup.boolean().required(),
  canListProductsWithOpenPrice: yup.boolean().required(),
  canMakeCashAdjustments: yup.boolean().required(),
  canManageOpenOrders: yup.boolean().required(),
  canRefundOrders: yup.boolean().required(),
  canUpdateMenu: yup.boolean().required(),
  canViewHistory: yup.boolean().required(),
  canViewReceipts: yup.boolean().required()
})

const EmployeeGroupForm: React.FC<EmployeeGroupFormProps> = ({ deleting, initialValues, onDelete, onDismiss, onSave, saving }) => {
  const form = useForm<EmployeeGroupInput>({
    criteriaMode: 'all',
    defaultValues: {
      name: initialValues?.name ?? '',
      canListDiscounts: initialValues?.canListDiscounts ?? false,
      canListProductsWithOpenPrice: initialValues?.canListProductsWithOpenPrice ?? false,
      canLogoutTill: initialValues?.canLogoutTill ?? false,
      canMakeCashAdjustments: initialValues?.canMakeCashAdjustments ?? false,
      canManageOpenOrders: initialValues?.canManageOpenOrders ?? false,
      canRefundOrders: initialValues?.canRefundOrders ?? false,
      canUpdateMenu: initialValues?.canUpdateMenu ?? false,
      canViewHistory: initialValues?.canViewHistory ?? false,
      canViewReceipts: initialValues?.canViewReceipts ?? false
    },
    resolver: yupResolver(schema)
  })

  return (
    <FormContainer gap={12}>
      <TextField
        form={form}
        name='name'
        title='Namn'
      />

      <Spacer height={4} />

      <Text size={20}>Rättigheter</Text>

      <CheckBox
        form={form}
        name='canListDiscounts'
        title='Kan lista rabatter'
      />

      <CheckBox
        form={form}
        name='canListProductsWithOpenPrice'
        title='Kan lista produkter med öppet pris'
      />

      <CheckBox
        form={form}
        name='canMakeCashAdjustments'
        title='Kan justera växelkassa'
      />

      <CheckBox
        form={form}
        name='canManageOpenOrders'
        title='Kan skapa och betala ut öppna notor'
      />

      <CheckBox
        form={form}
        name='canRefundOrders'
        title='Kan returnera ordrar och ta bort eller ändra i öppna notor'
      />

      <CheckBox
        form={form}
        name='canUpdateMenu'
        title='Kan uppdatera menyn från kassan genom långtryck på produkt'
      />

      <CheckBox
        form={form}
        name='canViewHistory'
        title='Kan se orderhistorik'
      />

      <CheckBox
        form={form}
        name='canViewReceipts'
        title='Kan se kvitton'
      />

      <CheckBox
        form={form}
        name='canLogoutTill'
        title='Kan återställa kassasystemet'
      />

      <Spacer height={4} />

      <SubmitFormButtons
        deleting={deleting ?? false}
        disableDeleteButton={saving || (deleting ?? false)}
        disableSaveButton={saving || (deleting ?? false)}
        onCancel={onDismiss}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSave))}
        saving={saving ?? false}
      />
    </FormContainer>
  )
}

export default EmployeeGroupForm

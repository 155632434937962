import { NetworkStatus } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import React from 'react'
import { ActivityIndicator, useWindowDimensions } from 'react-native'
import Spacer from 'react-spacer'
import { Text, TextStyle, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type FullStripeReportFragment, useListStripeReportsQuery } from '../../types/graphql'
import ButtonGroup from '../components/ButtonGroup'
import { PrimaryButton } from '../components/Buttons'
import Layout from '../components/Layout'
import { Cell, Column, Row, Table } from '../components/Table'
import formatCurrency from '../util/formatCurrency'
import logError from '../util/logError'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

const SMALL_SCREEN_WIDTH_THRESHOLD = 500

gql`
  fragment FullStripeReport on StripeReport {id
    datePeriod {
      start
      end
    }

    total(currency: SEK) {
      grossAmount
      transactionCount

      fee {
        vatAmount
      }
    }
  }


  query ListStripeReports($organizationId: ID!, $cursor: String) {
    organization (id: $organizationId) {
      id

      stripeReports(first: 25, after: $cursor) {
        edges {
          cursor

          node {
            ...FullStripeReport
          }
        }

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

function formatDate (datePeriod: FullStripeReportFragment['datePeriod'], timeZone: string): string {
  const start = Temporal.Instant.from(unwrap(datePeriod?.start)).toZonedDateTimeISO(timeZone).toPlainDate()
  const end = Temporal.Instant.from(unwrap(datePeriod?.end)).toZonedDateTimeISO(timeZone).toPlainDate()

  return start.equals(end) ? start.toString() : `${start.toString()} till ${end.toString()}`
}

function money (value: number | null | undefined): string {
  return value == null ? '' : formatCurrency(value)
}

const StripeReportList: React.FC = () => {
  const [navigation, { organizationId }] = useNavigation<'StripeReportList'>()

  const { data, fetchMore, networkStatus } = useListStripeReportsQuery({ notifyOnNetworkStatusChange: true, variables: { organizationId } })

  const timeZone = 'Europe/Stockholm'
  const fetchingMore = networkStatus === NetworkStatus.fetchMore
  const loading = networkStatus === NetworkStatus.loading

  const handleFetchMore = (): void => {
    fetchMore({
      variables: { cursor: data?.organization?.stripeReports?.pageInfo?.endCursor, organizationId },

      updateQuery (previousResult, { fetchMoreResult }) {
        if (previousResult.organization?.stripeReports == null) {
          return previousResult
        }

        const newEdges = fetchMoreResult?.organization?.stripeReports?.edges

        if (newEdges == null || newEdges.length === 0) {
          return previousResult
        }

        return dotProp.set(previousResult, 'organization.stripeReports', {
          __typename: previousResult.organization.stripeReports.__typename,
          edges: [...previousResult.organization.stripeReports.edges, ...newEdges],
          pageInfo: fetchMoreResult?.organization?.stripeReports?.pageInfo
        })
      }
    }).catch(logError)
  }

  const handleOnPress = (reportId: string) => (): void => {
    navigation.navigate('StripeReportView', { organizationId, reportId })
  }

  // Roughly adapt the table to fit on small screens
  const isSmallScreen = useWindowDimensions().width < SMALL_SCREEN_WIDTH_THRESHOLD
  const textSize = isSmallScreen ? 12 : undefined

  return (
    <Layout title='Stripe'>
      <VStack gap={12} maxWidth={1024} padding={normalize(8, 20)}>
        <ButtonGroup
          buttons={[{ label: 'Bokföringsexport', value: 'sie' }, { label: 'Stripe', value: 'stripe' }]}
          onPress={(id) => {
            switch (id) {
              case 'sie':
                navigation.navigate('SieReportView', { organizationId })
                break
            }
          }}
          selected='stripe'
        />

        {loading
          ? <ActivityIndicator />
          : (
            <>
              <Text size={24} weight='bold'>Rapporter från Stripe</Text>

              <VStack maxWidth={1024}>
                <Text>
                  <TextStyle weight='bold'>Varje dag genererar vi en rapport</TextStyle> som sammanfattar alla händelser på ditt Stripe-konto. Detta inkluderar till exempel dagens försäljning samt utbetalningar till ditt bankkonto.
                </Text>
                <Spacer height={8} />
                <Text>
                  OBS! Kom ihåg <TextStyle weight='bold'>momsen du ska få tillbaka</TextStyle> som syns i rapporterna här.
                </Text>
              </VStack>

              <Table borderRadius={4}>
                <Column paddingHorizontal={4} width={isSmallScreen ? 80 : 120} />
                <Column align='center' paddingHorizontal={4} />
                <Column align='end' paddingHorizontal={4} />
                <Column align='end' paddingHorizontal={4} />
                <Column align='center' width={isSmallScreen ? 32 : 48} />

                <Row align='center' paddingVertical={8}>
                  <Cell>
                    <Text size={textSize} weight='bold'>Datum</Text>
                  </Cell>
                  <Cell>
                    <Text size={textSize} weight='bold'>Antal händelser</Text>
                  </Cell>
                  <Cell>
                    <Text size={textSize} weight='bold'>Omsättning (SEK)</Text>
                  </Cell>
                  <Cell>
                    <Text size={textSize} weight='bold'>Moms att få tillbaka (SEK)</Text>
                  </Cell>
                  <Cell />
                </Row>

                {data?.organization?.stripeReports?.edges?.map((edge) => (
                  <Row key={edge.cursor} align='center' onPress={handleOnPress(edge.node.id)} paddingVertical={8}>
                    <Cell>
                      <Text size={textSize}>{formatDate(edge.node.datePeriod, timeZone)}</Text>
                    </Cell>
                    <Cell>
                      <Text size={textSize}>{edge.node.total?.transactionCount ?? 0}</Text>
                    </Cell>
                    <Cell>
                      <Text size={textSize}>{money(edge.node.total?.grossAmount ?? 0)}</Text>
                    </Cell>
                    <Cell>
                      <Text size={textSize}>{money(edge.node.total?.fee?.vatAmount ?? 0)}</Text>
                    </Cell>
                    <Cell>
                      <MaterialIcons name='open-in-new' size={isSmallScreen ? 20 : 24} />
                    </Cell>
                  </Row>
                ))}
              </Table>

              <Spacer height={8} />

              {!(data?.organization?.stripeReports?.pageInfo.hasNextPage ?? false)
                ? null
                : <PrimaryButton loading={fetchingMore} onPress={handleFetchMore} title='Hämta fler' />}
            </>
          )}
      </VStack>
    </Layout>
  )
}

export default StripeReportList

import { Parser } from '@json2csv/plainjs'

import downloadTextAsFile from './downloadTextAsFile'

type CsvData = ReadonlyArray<Record<string, string | number>>

const parser = new Parser()

export default function downloadCsvAsFile (fileName: string, data: CsvData): void {
  downloadTextAsFile(parser.parse(data), fileName, 'text/csv')
}

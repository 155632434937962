import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { ActivityIndicator, FlatList, Pressable } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useGetEmployeesQuery } from '../../types/graphql'
import { AddButton } from '../components/Buttons'
import Layout from '../components/Layout'
import ListContainer from '../components/ListContainer'
import { ACCENT_COLOR } from '../lib/color'
import useNavigation from '../util/useNavigation'

const PermissionsSummary: React.FC<{ hasPermission: boolean | null | undefined, title: string }> = ({ hasPermission, title }) => {
  return (
    <HStack alignItems='center'>
      <Text align='right'>{title}</Text>

      <Spacer width={8} />

      <MaterialIcons color={(hasPermission ?? false) ? 'green' : 'red'} name={(hasPermission ?? false) ? 'done' : 'clear'} size={14} />
    </HStack>
  )
}

const RestaurantEmployeeList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantEmployeeList'>()

  const { data, loading } = useGetEmployeesQuery({ variables: { restaurantId } })

  return (
    <Layout title='Anställda'>
      <HStack alignItems='center' padding={16}>
        <MaterialIcons color={ACCENT_COLOR} name='announcement' size={24} />

        <Spacer width={8} />

        <Text>Denna funktion gäller endast för LocoPOS Order och tillåter dig skapa och underhålla anställningsgrupper och anställda för inloggning i LocoPOS Order-systemet.</Text>
      </HStack>
      <HStack gap={16} padding={16} wrap>
        <ListContainer>
          <AddButton
            icon='add-circle'
            onPress={() => navigation.navigate('RestaurantEmployeeGroupCreate', { restaurantId })}
            title='Skapa ny anställningsgrupp'
          />

          {loading
            ? <ActivityIndicator />
            : (
              <FlatList
                data={data?.restaurant?.employeeGroups ?? []}
                ItemSeparatorComponent={() => <Spacer height={8} />}
                keyExtractor={group => group.id}
                renderItem={({ item: group }) => (
                  <Pressable onPress={() => navigation.navigate('RestaurantEmployeeGroupEdit', { restaurantId, employeeGroupId: group.id })}>
                    <HStack backgroundColor='#fefefe' borderRadius={5} grow={1} justifyContent='space-between' paddingHorizontal={8} paddingVertical={12}>
                      <VStack grow={1}>
                        <Text size={16}>{group.name}</Text>
                      </VStack>

                      <Spacer width={8} />

                      <VStack alignItems='end' grow={1}>
                        <Text size={14} weight='bold'>Rättigheter:</Text>

                        <Spacer height={8} />

                        <PermissionsSummary
                          hasPermission={group.canListDiscounts}
                          title='Ge & lista rabatter'
                        />

                        <PermissionsSummary
                          hasPermission={group.canListProductsWithOpenPrice}
                          title='Lista produkter med öppet pris'
                        />

                        <PermissionsSummary
                          hasPermission={group.canMakeCashAdjustments}
                          title='Kan justera växelkassa'
                        />

                        <PermissionsSummary
                          hasPermission={group.canManageOpenOrders}
                          title='Skapa och betala ut öppna notor'
                        />

                        <PermissionsSummary
                          hasPermission={group.canRefundOrders}
                          title='Göra orderretur'
                        />

                        <PermissionsSummary
                          hasPermission={group.canUpdateMenu}
                          title='Kan uppdatera menyn från kassan'
                        />

                        <PermissionsSummary
                          hasPermission={group.canViewHistory}
                          title='Se köphistorik'
                        />

                        <PermissionsSummary
                          hasPermission={group.canViewReceipts}
                          title='Se kvitton'
                        />

                        <PermissionsSummary
                          hasPermission={group.canLogoutTill}
                          title='Systemutloggning'
                        />
                      </VStack>

                      <Spacer width={16} />

                      <MaterialIcons name='edit' size={24} />
                    </HStack>
                  </Pressable>
                )}
              />
            )}
        </ListContainer>

        <ListContainer>
          <AddButton
            icon='add-circle'
            onPress={() => navigation.navigate('RestaurantEmployeeCreate', { restaurantId })}
            title='Lägg till anställd'
          />

          {loading
            ? <ActivityIndicator />
            : (
              <FlatList
                data={data?.restaurant?.employees ?? []}
                ItemSeparatorComponent={() => <Spacer height={8} />}
                keyExtractor={employee => employee.id}
                renderItem={({ item: employee }) => (
                  <Pressable onPress={() => navigation.navigate('RestaurantEmployeeEdit', { restaurantId, employeeId: employee.id })}>
                    <HStack alignItems='center' backgroundColor='#fefefe' borderRadius={5} grow={1} justifyContent='space-between' paddingHorizontal={8} paddingVertical={12}>
                      <HStack alignItems='center'>
                        <Text>{employee.name}</Text>

                        <Spacer width={8} />

                        <Text color='gray'>PIN: {employee.pin}</Text>
                      </HStack>

                      <HStack alignItems='center'>
                        <Text>{employee.groups?.map(group => group.name).join(', ')}</Text>

                        <Spacer width={8} />

                        <MaterialIcons name='edit' size={24} style={{ padding: 10 }} />
                      </HStack>
                    </HStack>
                  </Pressable>
                )}
              />
            )}
        </ListContainer>
      </HStack>
    </Layout>
  )
}

export default RestaurantEmployeeList

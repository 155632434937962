import React, { useEffect } from 'react'
import { ActivityIndicator } from 'react-native'

import useMultiEditProducts from '../../util/useMultiEditProducts'

import { Content, type UpdatingState } from './MultiEditForm'

interface UpdatingStepProps extends UpdatingState {
  onDismiss: () => void
  onNext: (errorMessage?: string) => void
}

const UpdatingStep: React.FC<UpdatingStepProps> = ({ changeFn, onDismiss, onNext, products, restaurantId }) => {
  const [update, { errorMessage, updating }] = useMultiEditProducts(restaurantId)

  useEffect(() => {
    update(products, changeFn).finally(() => onNext())
  }, [])

  return (
    <Content
      disableNext={updating}
      nextButtonTitle='Spara'
      onDismiss={onDismiss}
      onNext={() => onNext(errorMessage)}
    >
      <ActivityIndicator />
    </Content>
  )
}

export default UpdatingStep

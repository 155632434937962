import { MaterialIcons } from '@expo/vector-icons'
import React, { useMemo } from 'react'
import { Image, Pressable, View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { type WhiteLabelFieldsFragment } from '../../../types/graphql'
import useNavigation from '../../util/useNavigation'
import Card from '../Card'

const Restaurants: React.FC<{ whiteLabel: WhiteLabelFieldsFragment }> = ({ whiteLabel }) => {
  const [navigation] = useNavigation()

  const restaurants = useMemo(() => {
    return sortOn(whiteLabel?.restaurants?.edges?.map(edge => edge.node) ?? [], 'name')
  }, [whiteLabel?.restaurants?.edges])

  return (
    <HStack gap={16} padding={16} wrap>
      {restaurants?.map(restaurant => (
        <Card key={restaurant.id} height={216} width={316}>
          <HStack>
            <VStack paddingRight={16}>
              <Pressable onPress={() => navigation.navigate('RestaurantStatisticsView', { restaurantId: restaurant.id })}>
                <MaterialIcons name='insert-chart' size={24} />
              </Pressable>
            </VStack>

            <VStack grow={1}>
              <Text>{restaurant.name ?? undefined}</Text>
              <Text color='#999'>{restaurant.city ?? undefined}</Text>
            </VStack>

            <View style={{ flexDirection: 'row' }}>
              <MaterialIcons name='local-shipping' size={24} style={{ paddingHorizontal: 2, opacity: restaurant.hasDeliveryMenu === true ? 1 : 0 }} />
              <MaterialIcons name='local-mall' size={24} style={{ paddingHorizontal: 2, opacity: restaurant.hasTakeAwayMenu === true ? 1 : 0 }} />
              <MaterialIcons name='local-dining' size={24} style={{ paddingHorizontal: 2, opacity: restaurant.hasEatInMenu === true ? 1 : 0 }} />
            </View>
          </HStack>

          <Spacer grow={1} />

          <Image source={{ uri: restaurant.design?.restaurantPreviewImage ?? undefined }} style={{ width: 300, height: 140, backgroundColor: '#bbb' }} />
        </Card>
      ))}
    </HStack>
  )
}

export default React.memo(Restaurants)

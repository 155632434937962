import { MaterialIcons } from '@expo/vector-icons'
import React, { useCallback } from 'react'
import { Pressable, useWindowDimensions } from 'react-native'
import { HStack, Text, VStack } from 'react-stacked'

import { type GetStatisticsOverviewDataQuery } from '../../types/graphql'
import { Columns } from '../screen/StatisticsOverview'
import formatCurrency from '../util/formatCurrency'

import StatisticsDropdown, { type DropdownFields } from './StatisticsDropdown'
import { Cell, Column, ExpandableRow, Row, Table } from './Table'

type SalesByRestaurant = NonNullable<NonNullable<GetStatisticsOverviewDataQuery['statistics']>['salesByRestaurant']>[number]

interface StatisticsTableProps {
  data: readonly SalesByRestaurant[]
  onPress: (column: Columns) => void
  sortAsc: boolean
  sortColumn: Columns
}

const StatisticsTable: React.FC<StatisticsTableProps> = ({ onPress, data, sortAsc, sortColumn }) => {
  const { width } = useWindowDimensions()
  const medium = 800

  const hideOrderCount = width < medium

  const renderIcon = (column: Columns): JSX.Element => {
    if (sortColumn !== column) return <MaterialIcons name='keyboard-arrow-right' size={24} />
    if (sortAsc) return <MaterialIcons name='keyboard-arrow-down' size={24} />
    return <MaterialIcons name='keyboard-arrow-up' size={24} />
  }

  const handleRestaurantNamePress = useCallback(() => onPress(Columns.RestaurantName), [onPress])
  const handleOrderCountPress = useCallback(() => onPress(Columns.OrderCount), [onPress])
  const handleGrossAmountPress = useCallback(() => onPress(Columns.GrossAmount), [onPress])

  return (
    <Table>
      <Column paddingHorizontal={8} />
      <Column paddingHorizontal={8} />
      <Column paddingHorizontal={8} />

      <Row align='center' paddingVertical={8}>
        <Cell>
          <Pressable onPress={handleRestaurantNamePress}>
            <HStack alignItems='center'>
              {renderIcon(Columns.RestaurantName)}
              <Text weight='bold'>Restaurang</Text>
            </HStack>
          </Pressable>
        </Cell>

        {hideOrderCount ? null : (
          <Cell>
            <Pressable onPress={handleOrderCountPress}>
              <HStack alignItems='center'>
                {renderIcon(Columns.OrderCount)}
                <Text weight='bold'>Beställningar</Text>
              </HStack>
            </Pressable>
          </Cell>
        )}

        <Cell>
          <Pressable onPress={handleGrossAmountPress}>
            <HStack alignItems='center'>
              {renderIcon(Columns.GrossAmount)}
              <Text weight='bold'>Försäljning</Text>
            </HStack>
          </Pressable>
        </Cell>
      </Row>

      {data.map((sales, index) => {
        const columns: DropdownFields = {
          grossAmount: formatCurrency(sales.grossAmount ?? 0),
          orderCount: (sales.count ?? 0).toString()
        }

        return (
          <ExpandableRow
            key={sales.restaurant?.id ?? ''}
            dropdown={() => <StatisticsDropdown fields={columns} index={index} />}
            paddingVertical={8}
          >
            <Cell>
              <VStack>
                <Text weight='500'>{sales.restaurant?.name ?? ''}</Text>
              </VStack>
            </Cell>

            {hideOrderCount ? null : (
              <Cell>
                <Text weight='500'>{columns.orderCount}</Text>
              </Cell>
            )}

            <Cell>
              <Text weight='500'>{columns.grossAmount}</Text>
            </Cell>
          </ExpandableRow>
        )
      })}
    </Table>
  )
}

export default StatisticsTable

import React from 'react'

import { VictoryContainer, VictoryPie } from './Victory'

interface PieChartProps {
  data: Array<{ x: string, y: number }>
}

const PieChart: React.FC<PieChartProps> = ({ data }) => {
  return (
    <VictoryPie
      containerComponent={<VictoryContainer style={{ touchAction: 'auto' }} />}
      data={data}
      height={360}
      padding={{ left: 100, right: 100, top: 66, bottom: 66 }}
      style={{ parent: { userSelect: 'none' } }}
      width={480}
    />
  )
}

export default React.memo(PieChart)

import { VStack, ZStack } from 'react-stacked'

import DisabledOverlay from './DisabledOverlay'

interface SetupContainerProps {
  children: React.ReactNode
  disabled?: boolean
  disabledMessage?: JSX.Element
}

const SetupContainer: React.FC<SetupContainerProps> = ({ children, disabled = false, disabledMessage }) => {
  return (
    <ZStack>
      <DisabledOverlay backgroundColor='#00000044' disabled={disabled} message={disabledMessage}>
        <VStack backgroundColor='#FFF' borderRadius={4} gap={12} padding={8}>
          {children}
        </VStack>
      </DisabledOverlay>
    </ZStack>
  )
}

export default SetupContainer

import { type OpeningHoursPeriodInput } from '../../types/graphql'

import { type BunchedPeriods } from './getBunchedOpeningPeriods'
import nextDay from './getNextDay'
import { getPeriodInDayTime } from './getPeriodTime'

export default function createOpeningPeriods (bunchedPeriods: BunchedPeriods[]): OpeningHoursPeriodInput[] | null {
  const openingPeriods: OpeningHoursPeriodInput[] = []

  for (const periods of bunchedPeriods) {
    for (const day of periods.days) {
      const period = { open: { day, time: periods.openTime }, close: periods.closeTime == null ? null : { day, time: periods.closeTime } }

      const [periodOpenInDayTime, periodCloseInDayTime] = getPeriodInDayTime(period)
      if (period.close == null) continue

      // If open.time > close.time set close.day to nextDay(open.day)
      if ((periodOpenInDayTime ?? 0) > (periodCloseInDayTime ?? 0)) {
        period.close.day = nextDay(period.open.day)

        // If close.time > open.time set close.day to open.day
      } else if ((periodCloseInDayTime ?? 0) > (periodOpenInDayTime ?? 0)) {
        period.close.day = period.open.day
      }

      openingPeriods.push(period)
    }
  }

  return openingPeriods.length > 0 ? openingPeriods : null
}

import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useEnterSudoModeMutation } from '../../types/graphql'
import Dialog from '../components/Dialog'
import Warning from '../components/Warning'
import { PasswordField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'

import { PrimaryButton, SecondaryButton } from './Buttons'

gql`
  mutation EnterSudoMode($password: String!) {
    enterSudoMode(password: $password) {
      id

      expiresAt
    }
  }
`

interface PasswordPromptProps {
  onCancel: () => void
  onSuccess: () => void
}

interface FieldValues {
  password: string
}

const schema: ObjectSchema<FieldValues> = yup.object({
  password: yup.string().trim().required('Vänligen ange ditt lösenord')
})

const PasswordPrompt: React.FC<PasswordPromptProps> = ({ onCancel, onSuccess }) => {
  const [errors, setErrors] = useState<{ password?: string[] }>({})

  const [enterSudoMode, { error, loading }] = useEnterSudoModeMutation()

  const form = useForm<FieldValues>({
    resolver: yupResolver(schema)
  })

  const handleLogin = async (variables: FieldValues): Promise<void> => {
    setErrors({})

    try {
      await enterSudoMode({ awaitRefetchQueries: true, refetchQueries: ['GetAccountData'], variables })

      onSuccess()
    } catch (error) {
      if (error.graphQLErrors?.[0]?.state != null) {
        setErrors(error.graphQLErrors[0].state)
      } else {
        logError(error)
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key !== 'Enter') return

    event.preventDefault()
    form.handleSubmit(handleLogin)().catch(logError)
  }

  return (
    <Dialog
      maxWidth={448}
      title='Lösenord krävs'
      visible
    >
      <VStack backgroundColor='#fff' borderRadius={6} padding={20} width='100%'>
        <HStack padding={20}>
          <Text size={16}>För att komma åt vissa funktioner i backoffice, vänligen bekräfta ditt lösenord.</Text>
        </HStack>

        <VStack padding={20}>
          {error?.graphQLErrors[0].extensions?.code == null ? null : <Warning message='Felaktigt användarnamn och/eller lösenord' paddingBottom={12} />}

          <PasswordField
            error={errors.password != null}
            form={form}
            helperText={errors.password == null ? undefined : errors.password?.join()}
            name='password'
            onKeyDown={handleKeyDown}
            title='Lösenord'
          />
        </VStack>

        <VStack padding={20}>
          <PrimaryButton loading={loading} onPress={ignoreAsync(form.handleSubmit(handleLogin))} title='Fortsätt' />

          <Spacer height={8} />

          <SecondaryButton onPress={onCancel} title='Avbryt' />
        </VStack>
      </VStack>
    </Dialog>
  )
}

export default PasswordPrompt

import React, { type ReactNode } from 'react'
import { View, type ViewStyle } from 'react-native'
import { VStack } from 'react-stacked'

const StatisticsContainer: React.FC<{ children: ReactNode, maxWidth?: number }> = ({ children, maxWidth }) => {
  const style: ViewStyle = {
    alignContent: 'center',
    backgroundColor: '#fefefe',
    borderRadius: 5,
    flexDirection: 'column',
    padding: 16,
    maxWidth: maxWidth ?? 1024,
    width: '100%'
  }

  return (
    <VStack paddingTop={16}>
      <View style={style}>
        {children}
      </View>
    </VStack>
  )
}

export default StatisticsContainer

import { NetworkStatus } from '@apollo/client'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import React, { useCallback, useMemo } from 'react'
import { ActivityIndicator, FlatList, type ListRenderItemInfo } from 'react-native'
import Spacer from 'react-spacer'
import { Text, VStack } from 'react-stacked'

import { type FullRestaurantControlUnitOutageFragment, useGetRestaurantControlUnitOutageDataQuery } from '../../types/graphql'
import { PrimaryButton } from '../components/Buttons'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import RestaurantControlUnitOutageItem from '../components/RestaurantControlUnitOutageItem'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

const keyExtractor = (item: { id: string }): string => item.id

gql`
  fragment FullRestaurantControlUnitOutage on RestaurantControlUnitOutage {
    id

    createdAt
    restoredAt
  }

  query GetRestaurantControlUnitOutageData($restaurantId: ID!, $cursor: String) {
    restaurant(id: $restaurantId) {
      id

      controlUnitOutages(first: 5, after: $cursor) {
        edges {
          cursor

          node {
            ...FullRestaurantControlUnitOutage
          }
        }

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

const RestaurantControlUnitOutageList: React.FC = () => {
  const [, { restaurantId }] = useNavigation<'RestaurantControlUnitOutageList'>()

  const { data, fetchMore, networkStatus } = useGetRestaurantControlUnitOutageDataQuery({ variables: { restaurantId } })

  const fetchingMore = networkStatus === NetworkStatus.fetchMore
  const loading = networkStatus === NetworkStatus.loading

  const hasNextPage = data?.restaurant?.controlUnitOutages?.pageInfo?.hasNextPage ?? false

  const handleFetchMore = (): void => {
    fetchMore({
      updateQuery (previousResult, { fetchMoreResult }) {
        if (previousResult.restaurant?.controlUnitOutages == null) {
          return previousResult
        }

        const newEdges = fetchMoreResult?.restaurant?.controlUnitOutages?.edges

        if (newEdges == null || newEdges.length === 0) {
          return previousResult
        }

        return dotProp.set(previousResult, 'restaurant.controlUnitOutages', {
          __typename: previousResult.restaurant.controlUnitOutages.__typename,
          edges: [...previousResult.restaurant.controlUnitOutages.edges, ...newEdges],
          pageInfo: fetchMoreResult?.restaurant?.controlUnitOutages?.pageInfo
        })
      },
      variables: {
        cursor: data?.restaurant?.controlUnitOutages?.pageInfo.endCursor,
        restaurantId
      }
    }).catch(logError)
  }

  const renderItem = useCallback(({ item }: ListRenderItemInfo<FullRestaurantControlUnitOutageFragment>): JSX.Element => {
    return (
      <RestaurantControlUnitOutageItem
        item={item}
        restaurantId={restaurantId}
      />
    )
  }, [restaurantId])

  const ListEmptyComponent = useMemo(() => {
    if (loading) {
      return (
        <VStack alignItems='center' gap={8} justifyContent='center'>
          <ActivityIndicator size='large' />

          <Text size={14}>Laddar driftstopp av kontrollenheten...</Text>
        </VStack>
      )
    }

    return <Text size={18}>Inga driftstopp har skett på denna kontrollenhet</Text>
  }, [loading])

  return (
    <Layout hideTitle screenType={ScreenType.List} title='Kontrollenheten'>
      <ListContainer title='Kontrollenheten'>
        <Text size={24}>Driftstopp av kontrollenhet</Text>

        <Text size={16}>
          Gastrogate använder sig av en kontrollenhet som hanteras av företaget Server For Pos för att verifiera köp i enlighet med Skatteverkets regler. Samtliga köp som bokförs i kassaregistret måste gå genom kontrollenheten. För att möjliggöra köp när kontrollenheten är ur drift skapar vi så kallade "Kontantfakturor" i stället för kvitton, och noterar att kontrollenheten och kassaregistret inte är i drift genom att markera dessa fällt som "SAKNAS" på kontantfakturan.
        </Text>

        <Text size={16}>
          Således dyker det som sålts under ett driftstopp inte upp i X/Z-rapport men exporteras som vanligt i den månatliga SIE-filen i separata verifikationer. Om du vill kan du också ladda ner de enskilda verifikationerna nedan av det som sålts under de driftstopp du varit med om, samt en SIE-fil som täcker det driftstoppet.
        </Text>

        <FlatList
          data={data?.restaurant?.controlUnitOutages?.edges.map(edge => edge.node) ?? []}
          ItemSeparatorComponent={() => <Spacer height={8} />}
          keyExtractor={keyExtractor}
          ListEmptyComponent={ListEmptyComponent}
          renderItem={renderItem}
        />

        {!hasNextPage
          ? null
          : <PrimaryButton disabled={fetchingMore} loading={fetchingMore} onPress={handleFetchMore} title='Hämta fler' />}
      </ListContainer>
    </Layout>
  )
}

export default RestaurantControlUnitOutageList

import gql from 'graphql-tag'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HStack, VStack } from 'react-stacked'

import { type ThemePatch, type WhiteLabelFieldsFragment, type WhiteLabelPatch, useDeleteWhiteLabelMutation, usePublishWhiteLabelMutation, useUpdateWhiteLabelMutation } from '../../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../../lib/validation'
import ignoreAsync from '../../util/ignoreAsync'
import logError from '../../util/logError'
import useNavigation from '../../util/useNavigation'
import { PrimaryButton } from '../Buttons'
import SubmitFormButtons from '../SubmitFormButtons'
import VerificationDialog from '../VerificationDialog'
import Warning from '../Warning'

import AndroidGoogleServices from './AndroidGoogleServices'
import AppleGoogleServices from './AppleGoogleServices'
import Colors from './Colors'
import Images from './Images'
import ScreenShots from './ScreenShots'
import Settings from './Settings'

gql`
  mutation DeleteWhiteLabel($whiteLabelId: ID!) {
    deleteWhiteLabel(whiteLabelId: $whiteLabelId)
  }

  mutation UpdateWhiteLabel($whiteLabelId: ID!, $patch: WhiteLabelPatch!) {
    updateWhiteLabel(whiteLabelId: $whiteLabelId, patch: $patch) {
      ...WhiteLabelFields
    }
  }

  mutation PublishWhiteLabel($whiteLabelId: ID!) {
    publishWhiteLabel(
      whiteLabelId: $whiteLabelId,
    ) {
      id

      isPublishing
    }
  }
`

type ThemeFormPatch = Pick<ThemePatch, 'backgroundColor' | 'borderColor' | 'cardColor' | 'drawerBackgroundColor' | 'drawerListItemIconColor' | 'drawerListItemSubtitleColor' | 'drawerListItemTitleColor' | 'drawerLogoImage' | 'drawerVersionTextColor' | 'listBackgroundColor' | 'listBackgroundImage' | 'listClearIconColor' | 'listEmptyListButtonBackgroundColor' | 'listItemBackgroundColor' | 'listItemSeparatorColor' | 'listItemTextColor' | 'listMenuIconColor' | 'listSearchIconColor' | 'listTabBarActiveTextColor' | 'listTabBarInactiveTextColor' | 'listTabBarUnderlineColor' | 'listTextInputColor' | 'listTextInputPlaceholderTextColor' | 'menuTypeSelectionBackgroundColor' | 'menuTypeSelectionBackgroundImage' | 'menuTypeSelectionButtonBackgroundColor' | 'menuTypeSelectionButtonBorderColor' | 'menuTypeSelectionButtonTextColor' | 'menuTypeSelectionTitleColor' | 'notificationColor' | 'primaryColor' | 'textColor'>
export type WhiteLabelFormPatch = { theme: ThemeFormPatch } & Pick<WhiteLabelPatch, 'androidFeatureGraphicUrl' | 'androidGoogleServices' | 'androidPackageName' | 'appIconUrl' | 'appName' | 'appSplashUrl' | 'deliveryEnabled' | 'description' | 'easProjectId' | 'googleMapsApiKey' | 'iPadPro129Screenshots' | 'iPhone55Screenshots' | 'iPhone65Screenshots' | 'iosAppstoreId' | 'iosBundleIdentifier' | 'iosGoogleServiceInfoPlist' | 'keywords' | 'scheme' | 'shortDescription' | 'shortUrlPrefix'>

const themeScheme: ObjectSchema<ThemeFormPatch> = yup.object({
  backgroundColor: yup.string().nullable(),
  borderColor: yup.string().nullable(),
  cardColor: yup.string().nullable(),
  drawerBackgroundColor: yup.string().nullable(),
  drawerListItemIconColor: yup.string().nullable(),
  drawerListItemSubtitleColor: yup.string().nullable(),
  drawerListItemTitleColor: yup.string().nullable(),
  drawerLogoImage: yup.string().nullable(),
  drawerVersionTextColor: yup.string().nullable(),
  listBackgroundColor: yup.string().nullable(),
  listBackgroundImage: yup.string().nullable(),
  listClearIconColor: yup.string().nullable(),
  listEmptyListButtonBackgroundColor: yup.string().nullable(),
  listItemBackgroundColor: yup.string().nullable(),
  listItemSeparatorColor: yup.string().nullable(),
  listItemTextColor: yup.string().nullable(),
  listMenuIconColor: yup.string().nullable(),
  listSearchIconColor: yup.string().nullable(),
  listTabBarActiveTextColor: yup.string().nullable(),
  listTabBarInactiveTextColor: yup.string().nullable(),
  listTabBarUnderlineColor: yup.string().nullable(),
  listTextInputColor: yup.string().nullable(),
  listTextInputPlaceholderTextColor: yup.string().nullable(),
  menuTypeSelectionBackgroundColor: yup.string().nullable(),
  menuTypeSelectionBackgroundImage: yup.string().nullable(),
  menuTypeSelectionButtonBackgroundColor: yup.string().nullable(),
  menuTypeSelectionButtonBorderColor: yup.string().nullable(),
  menuTypeSelectionButtonTextColor: yup.string().nullable(),
  menuTypeSelectionTitleColor: yup.string().nullable(),
  notificationColor: yup.string().nullable(),
  primaryColor: yup.string().nullable(),
  textColor: yup.string().nullable()
})

const schema: ObjectSchema<WhiteLabelFormPatch> = yup.object({
  androidFeatureGraphicUrl: yup.string().url().nullable(),
  androidGoogleServices: yup.mixed().nullable(),
  androidPackageName: yup.string().nullable(),
  appIconUrl: yup.string().url().nullable(),
  appName: yup.string().nullable(),
  appSplashUrl: yup.string().url().nullable(),
  deliveryEnabled: yup.boolean().nullable(),
  description: yup.string().nullable(),
  easProjectId: yup.string().nullable(),
  googleMapsApiKey: yup.string().nullable(),
  iPadPro129Screenshots: yup.array(yup.string().url().defined()).nullable(),
  iPhone55Screenshots: yup.array(yup.string().url().defined()).nullable(),
  iPhone65Screenshots: yup.array(yup.string().url().defined()).nullable(),
  iosAppstoreId: yup.string().nullable(),
  iosBundleIdentifier: yup.string().nullable(),
  iosGoogleServiceInfoPlist: yup.string().nullable(),
  keywords: yup.string().nullable(),
  scheme: yup.string().nullable(),
  shortDescription: yup.string().nullable(),
  shortUrlPrefix: yup.string().nullable(),
  theme: themeScheme
})

const WhiteLabelForm: React.FC<{ whiteLabel: WhiteLabelFieldsFragment }> = ({ whiteLabel }) => {
  const [navigation] = useNavigation()

  const form = useForm<WhiteLabelFormPatch>({ criteriaMode: 'all', resolver: yupResolver(schema) })
  const [showingPublishVerificationDialog, setShowingPublishVerificationDialog] = useState(false)
  const [showingArchiveVerificationDialog, setShowingArchiveVerificationDialog] = useState(false)

  const [update, { error, loading }] = useUpdateWhiteLabelMutation()
  const [publishWhiteLabel, { error: publishError, loading: publishing }] = usePublishWhiteLabelMutation()
  const [deleteWhiteLabel, { error: archiveError, loading: archivingWhiteLabel }] = useDeleteWhiteLabelMutation()

  const handleArchive = useCallback(() => {
    deleteWhiteLabel({
      onCompleted: () => {
        setShowingArchiveVerificationDialog(false)

        navigation.navigate('WhiteLabelList', {})
      },
      refetchQueries: ['ListWhiteLabels'],
      variables: {
        whiteLabelId: whiteLabel.id
      }
    }).catch(logError)
  }, [navigation, whiteLabel.id])

  const handleArchivePress = (): void => {
    setShowingArchiveVerificationDialog(true)
  }

  const handlePublishPress = (): void => {
    setShowingPublishVerificationDialog(true)
  }

  const handlePublishConfirm = (): void => {
    publishWhiteLabel({
      onCompleted: () => setShowingPublishVerificationDialog(false),
      variables: { whiteLabelId: whiteLabel.id }
    }).catch(logError)
  }

  const handleReset = useCallback(() => {
    form.reset(schema.cast(whiteLabel, { stripUnknown: true }))
  }, [form, whiteLabel])

  const handleSubmit = useCallback((patch: WhiteLabelPatch) => {
    update({ variables: { whiteLabelId: whiteLabel.id, patch } }).catch(logError)
  }, [update, whiteLabel])

  useEffect(handleReset, [whiteLabel])

  return (
    <>
      {!showingPublishVerificationDialog ? null : (
        <VerificationDialog
          callToActionLabel='Publicera Whitelabel'
          errorMessage={publishError?.message}
          loading={publishing}
          onDismiss={() => setShowingPublishVerificationDialog(false)}
          onSubmit={handlePublishConfirm}
          open
          prompt='Genom att bekräfta denna aktion kommer appar att skickas till Apple AppStore och Google Play för review. Är du säker på att du vill publicera den här whitelabeln?'
          title='Publicera Whitelabel'
        />
      )}

      {!showingArchiveVerificationDialog ? null : (
        <VerificationDialog
          callToActionLabel='Arkivera Whitelabel'
          errorMessage={archiveError?.message}
          loading={archivingWhiteLabel}
          onDismiss={() => setShowingArchiveVerificationDialog(false)}
          onSubmit={handleArchive}
          open
          prompt='Du håller på att arkivera denna whitelabel. Detta kommer att ta bort den från listan över whitelabels, och göra att eventuella appar ute i AppStore och Google Play slutar fungera. Den kommer fortsätta finnas kvar i vår databas för återställning om det skulle bli aktuellt. Är du säker på att du vill arkivera den här whitelabeln?'
          title='Bekräfta arkivering av Whitelabel'
        />
      )}

      <VStack gap={16} padding={16}>
        <Settings form={form} />

        {whiteLabel.androidGoogleServices != null ? null : <AndroidGoogleServices form={form} />}
        {whiteLabel.iosGoogleServiceInfoPlist != null ? null : <AppleGoogleServices form={form} />}

        <Colors form={form} />

        <Images form={form} whiteLabel={whiteLabel} />

        <ScreenShots form={form} whiteLabel={whiteLabel} />
      </VStack>

      {error == null
        ? null
        : (
          <VStack alignItems='end' grow={1} paddingRight={24}>
            <Warning message={error.message} paddingBottom={16} />
          </VStack>
        )}

      <HStack gap={16}>
        <SubmitFormButtons
          disableSaveButton={!form.formState.isDirty || loading}
          isDirty={form.formState.isDirty}
          onDelete={handleArchivePress}
          onReset={handleReset}
          onSave={ignoreAsync(form.handleSubmit(handleSubmit))}
          titleForDelete='Arkivera'
        />
        {!(whiteLabel.isReadyForPublishing ?? false) ? null : (
          <PrimaryButton
            disabled={whiteLabel.isPublishing ?? false}
            onPress={handlePublishPress}
            title={(whiteLabel.isPublishing ?? false) ? 'Whitelabel ligger ute för publicering' : 'Publicera Whitelabel'}
          />
        )}
      </HStack>
    </>
  )
}

export default WhiteLabelForm

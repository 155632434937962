import React from 'react'

import type { AppBarProps } from './AppBar'

const style = {
  backgroundColor: 'black',
  color: 'white',
  left: 0,
  position: 'sticky',
  right: 0,
  top: 0,
  zIndex: 1100
} as const

export const AppBar: React.FC<AppBarProps> = ({ children }) => {
  return <div style={style}>{children}</div>
}

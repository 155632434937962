interface CommonProps {
  // FIXME: Figure out how to pass StyleSheet.create styles to <iframe />
  style?: object
}

interface HtmlProps extends CommonProps {
  html: string
}

interface UrlProps extends CommonProps {
  url: string
}

const WebView: React.FC<HtmlProps | UrlProps> = (options) => <iframe style={{ alignSelf: 'stretch', borderWidth: 0, flexGrow: 1, ...options.style }} {...('html' in options) ? { srcDoc: options.html } : { src: options.url }} />

export default WebView

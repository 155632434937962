import unwrap from 'ts-unwrap'

import { type OpeningHoursPeriodInput } from '../../types/graphql'

import { periodEndingToDayTime } from './getTime'

export function getPeriodInDayTime (period: OpeningHoursPeriodInput): [number, number | null] {
  const periodOpenInDayTime = unwrap(periodEndingToDayTime({ day: period.open.day, time: period.open.time }))
  const periodCloseInDayTime = periodEndingToDayTime({ day: period?.close?.day ?? null, time: period?.close?.time ?? null })

  return [periodOpenInDayTime, periodCloseInDayTime]
}

import gql from 'graphql-tag'
import React, { type ReactNode, useCallback, useMemo, useState } from 'react'
import { ScrollView, useWindowDimensions } from 'react-native'
import StepIndicator from 'react-native-step-indicator'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unreachable from 'ts-unreachable'
import unwrap from 'ts-unwrap'

import { type MenuProductFieldsFragment, type MenuProductPropertiesFieldsFragment, type MenuType, useGetCoursesQuery, useGetMenuAlternativeGroupListDataQuery, useGetMenuOpeningHoursQuery, useGetPrinterQueuesQuery, useGetReportGroupsQuery } from '../../../types/graphql'
import { ACCENT_COLOR } from '../../lib/color'
import * as MenuTypes from '../../util/menuTypes'
import { type ChangeFn } from '../../util/useMultiEditProducts'
import { CancelButton, PrimaryButton } from '../Buttons'

import ConfirmStep from './ConfirmStep'
import DoneStep from './DoneStep'
import EditAlternativeGroups from './EditAlternativeGroups'
import EditCourse from './EditCourse'
import EditOpeningHours from './EditOpeningHours'
import EditPrice from './EditPrice'
import EditPrinterQueues from './EditPrinterQueues'
import EditReportGroup from './EditReportGroup'
import FilterStep from './FilterStep'
import UpdatingStep from './UpdatingStep'

gql`
  query GetPrinterQueues($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      printerQueues {
        ...FullPrinterQueue
      }
    }
  }

  query GetCourses($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      menu {
        id

        courses {
          id

          name
        }
      }
    }
  }
`

function allProperties (products: readonly MenuProductFieldsFragment[]): MenuProductPropertiesFieldsFragment[] {
  const result: MenuProductPropertiesFieldsFragment[] = []

  for (const product of products) {
    for (const fieldName of MenuTypes.ALL.map(MenuTypes.productFieldName)) {
      const props = product[fieldName]
      if (props != null) result.push(props)
    }
  }

  return result
}

// Styles copied from RestaurantGetStarted
const customStyles = {
  currentStepIndicatorLabelFontSize: 13,
  currentStepIndicatorSize: 30,
  currentStepLabelColor: ACCENT_COLOR,
  currentStepStrokeWidth: 3,
  labelColor: '#999999',
  labelSize: 11,
  stepStrokeCurrentColor: ACCENT_COLOR,
  stepStrokeWidth: 3,
  separatorFinishedColor: ACCENT_COLOR,
  separatorStrokeWidth: 2,
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorFinishedColor: ACCENT_COLOR,
  stepIndicatorLabelCurrentColor: ACCENT_COLOR,
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelFontSize: 11,
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  stepIndicatorSize: 25,
  stepIndicatorUnFinishedColor: '#ffffff',
  stepStrokeFinishedColor: ACCENT_COLOR,
  stepStrokeUnFinishedColor: '#aaaaaa'
}

interface ContentProps {
  children?: ReactNode
  disableCancel?: boolean
  disableNext?: boolean
  nextButtonTitle?: string
  onDismiss?: () => void
  onNext?: () => void
  title?: string
}

export const Content: React.FC<ContentProps> = ({ children, disableCancel, disableNext, nextButtonTitle, onDismiss, onNext, title }) => {
  const { height } = useWindowDimensions()

  return (
    <VStack>
      {title == null ? null : (
        <HStack paddingTop={8}>
          <Text size={18}>{title}</Text>
        </HStack>
      )}

      <Spacer height={16} />

      <ScrollView contentContainerStyle={{ maxHeight: height - 256 }}>
        {children}
      </ScrollView>

      <Spacer grow={1} height={24} />

      <HStack>
        {onDismiss == null ? null : <CancelButton disabled={disableCancel} onPress={onDismiss} title='Avbryt' />}
        <Spacer grow={1} />
        {onNext == null ? null : <PrimaryButton disabled={disableNext} onPress={onNext} title={nextButtonTitle ?? 'Nästa'} />}
      </HStack>
    </VStack>
  )
}

export interface FilterState {
  kind: 'filter'
  menuTypes: MenuType[]
  products: readonly MenuProductFieldsFragment[]
}
export interface EditState {
  kind: 'edit'
  products: readonly MenuProductFieldsFragment[]
}
export interface ConfirmState {
  kind: 'last'
  step: 'confirm'
  products: readonly MenuProductFieldsFragment[]
  changeFn: ChangeFn
}
export interface UpdatingState {
  kind: 'last'
  step: 'updating'
  products: readonly MenuProductFieldsFragment[]
  changeFn: ChangeFn
  restaurantId: string
}
export interface DoneState {
  kind: 'last'
  step: 'done'
  errorMessage?: string
}

type LastState = ConfirmState | UpdatingState | DoneState

interface State {
  current: 'filter' | 'edit' | 'last'
  filter?: FilterState
  edit?: EditState
  last?: LastState
}

function getInitialState (products: readonly MenuProductFieldsFragment[]): State {
  function createInitialState (value: DoneState | EditState | FilterState): State {
    return {
      current: value.kind,
      [value.kind]: value
    }
  }

  const menuTypes = MenuTypes.ALL.filter(menuType => products.some(product => product[MenuTypes.productFieldName(menuType)] != null))

  switch (menuTypes.length) {
    case 0:
      return createInitialState({ kind: 'last', step: 'done', errorMessage: 'Det saknas produkter' })
    case 1:
      return createInitialState({ kind: 'edit', products })
    default:
      return createInitialState({ kind: 'filter', menuTypes, products })
  }
}

const labelForStep = (changeType: MultiEditFormProps['changeType']) => (current: State['current']): string => {
  switch (current) {
    case 'last':
      return 'Bekräfta förändringar'
    case 'edit':
      switch (changeType) {
        case 'alternativeGroups':
          return 'Välj alternativ'
        case 'course':
          return 'Välj rätt'
        case 'openingHours':
          return 'Välj öppettider'
        case 'price':
          return 'Justera priser'
        case 'printerQueues':
          return 'Välj skrivare'
        case 'secondaryPrinters':
          return 'Välj sekundärskrivare'
        case 'reportGroup':
          return 'Välj rapportgrupp'
        default:
          unreachable(changeType)
      }
      break
    case 'filter':
      return 'Välj menyer'
    default:
      unreachable(current)
  }
}

export type MultiEditChangeType = 'alternativeGroups' | 'course' | 'openingHours' | 'price' | 'printerQueues' | 'secondaryPrinters' | 'reportGroup'

export interface MultiEditFormProps {
  changeType: MultiEditChangeType
  onDismiss: () => void
  products: readonly MenuProductFieldsFragment[]
  restaurantId: string
}

const MultiEditForm: React.FC<MultiEditFormProps> = ({ changeType, onDismiss, products: initialProducts, restaurantId }) => {
  const initialState = useMemo(() => getInitialState(initialProducts), [])
  const [state, setState] = useState(initialState)

  const steps = useMemo(() => {
    if (initialState.current === 'last') return ['last'] as Array<State['current']>

    const labels = ['edit', 'last'] as Array<State['current']>
    return initialState.current === 'filter' ? ['filter', ...labels] as Array<State['current']> : labels
  }, [initialState, state])

  const alternativeGroupsQuery = useGetMenuAlternativeGroupListDataQuery({ skip: changeType !== 'alternativeGroups', variables: { restaurantId } })
  const courseQuery = useGetCoursesQuery({ skip: changeType !== 'course', variables: { restaurantId } })
  const openingHoursQuery = useGetMenuOpeningHoursQuery({ skip: changeType !== 'openingHours', variables: { restaurantId } })
  const printerQueuesQuery = useGetPrinterQueuesQuery({ skip: changeType !== 'printerQueues', variables: { restaurantId } })
  const secondaryPrintersQuery = useGetPrinterQueuesQuery({ skip: changeType !== 'secondaryPrinters', variables: { restaurantId } })
  const reportGroupsQuery = useGetReportGroupsQuery({ skip: changeType !== 'reportGroup', variables: { restaurantId } })

  const courseOptions = courseQuery?.data?.restaurant?.menu?.courses == null ? [] : [{ value: null, title: 'Ta bort rätt' }, ...courseQuery.data.restaurant.menu.courses.map(({ id, name }) => ({ value: id, title: name ?? '' }))]

  const handleEditOnNext = useCallback((products: readonly MenuProductFieldsFragment[]) => (changeFn: ChangeFn) => {
    setState(value => ({ ...value, current: 'last', last: { kind: 'last', step: 'confirm', products, changeFn } }))
  }, [])

  const handleStateChange = useCallback((partialState: EditState | UpdatingState | DoneState) => {
    setState(value => ({ ...value, current: partialState.kind, [partialState.kind]: partialState }))
  }, [])

  const handleStepIndicatorOnPress = useCallback((step: number) => {
    if (state.current === 'last' && state.last?.step === 'updating') return

    if (step === 0) return setState(initialState)

    const current = steps[step]
    if (['edit', 'last'].includes(current) && state[current] != null) setState(value => ({ ...value, current }))
  }, [initialState, state])

  const renderEditStep = (editState: EditState): JSX.Element => {
    const { products } = editState

    switch (changeType) {
      case 'alternativeGroups':
        return (
          <EditAlternativeGroups
            alternativeGroups={alternativeGroupsQuery.data?.restaurant?.menu?.alternativeGroups}
            onDismiss={onDismiss}
            onNext={handleEditOnNext(products)}
            productProperties={allProperties(products)}
          />
        )

      case 'course':
        return (
          <EditCourse
            courseOptions={courseOptions}
            onDismiss={onDismiss}
            onNext={handleEditOnNext(products)}
          />
        )

      case 'openingHours':
        return (
          <EditOpeningHours
            onDismiss={onDismiss}
            onNext={handleEditOnNext(products)}
            openingHours={openingHoursQuery.data?.restaurant?.menu?.productOpeningHours}
          />
        )

      case 'price':
        return (
          <EditPrice
            onDismiss={onDismiss}
            onNext={handleEditOnNext(products)}
          />
        )

      case 'printerQueues':
        return (
          <EditPrinterQueues
            onDismiss={onDismiss}
            onNext={handleEditOnNext(products)}
            printerQueues={printerQueuesQuery.data?.restaurant?.printerQueues}
            productProperties={allProperties(products)}
          />
        )

      case 'secondaryPrinters':
        return (
          <EditPrinterQueues
            onDismiss={onDismiss}
            onNext={handleEditOnNext(products)}
            productProperties={allProperties(products)}
            secondaryPrinters={secondaryPrintersQuery.data?.restaurant?.printerQueues}
          />
        )

      case 'reportGroup':
        return (
          <EditReportGroup
            onDismiss={onDismiss}
            onNext={handleEditOnNext(products)}
            reportGroups={reportGroupsQuery.data?.restaurant?.menu?.reportGroups}
          />
        )

      default:
        unreachable(changeType)
    }
  }

  const renderLastStep = (lastState: LastState): JSX.Element => {
    switch (lastState.step) {
      case 'confirm':
        return (
          <ConfirmStep
            onDismiss={onDismiss}
            onNext={() => handleStateChange({ kind: 'last', step: 'updating', changeFn: lastState.changeFn, products: lastState.products, restaurantId })}
            {...lastState}
          />
        )

      case 'updating':
        return (
          <UpdatingStep
            onDismiss={onDismiss}
            onNext={errorMessage => handleStateChange({ kind: 'last', step: 'done', errorMessage })}
            {...lastState}
          />
        )

      case 'done':
        return (
          <DoneStep
            onNext={onDismiss}
            {...lastState}
          />
        )

      default:
        unreachable(lastState)
    }
  }

  const renderContent = (): JSX.Element => {
    switch (state.current) {
      case 'filter':
        return (
          <FilterStep
            changeType={changeType}
            onDismiss={onDismiss}
            onNext={products => handleStateChange({ kind: 'edit', products })}
            {...unwrap(state[state.current])}
          />
        )

      case 'edit':
        return renderEditStep(unwrap(state[state.current]))

      case 'last':
        return renderLastStep(unwrap(state[state.current]))

      default:
        unreachable(state.current)
    }
  }

  return (
    <>
      <StepIndicator
        currentPosition={steps.indexOf(state.current)}
        customStyles={customStyles}
        labels={steps.map(labelForStep(changeType))}
        onPress={handleStepIndicatorOnPress}
        stepCount={steps.length}
      />
      {renderContent()}
    </>
  )
}

export default MultiEditForm

import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type PersonalkollenCredentials, useAcceptPersonalkollenIntegrationTermsOfServiceMutation, useGetRestaurantPersonalkollenDataQuery, useLinkPersonalkollenAccountMutation } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import Divider from '../components/Divider'
import InfoIcon from '../components/InfoIcon'
import Layout, { ScreenType } from '../components/Layout'
import Link from '../components/Link'
import ListContainer from '../components/ListContainer'
import SetupContainer from '../components/SetupContainer'
import { TextField } from '../components/TextField'
import Warning from '../components/Warning'
import PersonalkollenServices from '../components/WhiteLabel/PersonalkollenServices'
import { ACCENT_COLOR } from '../lib/color'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import openLink from '../util/openLink'
import useNavigation from '../util/useNavigation'

import DashboardContainer from './DashboardContainer'

gql`
  query GetRestaurantPersonalkollenData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      hasPersonalkollenAccess
      hasPersonalkollenCredentials
    }
  }

  mutation AcceptPersonalkollenIntegrationTermsOfService($restaurantId: ID!) {
    acceptPersonalkollenIntegrationTermsOfService(restaurantId: $restaurantId) {
      id

      hasPersonalkollenAccess
    }
  }

  mutation LinkPersonalkollenAccount($restaurantId: ID!, $credentials: PersonalkollenCredentials!) {
    linkPersonalkollenAccount(restaurantId: $restaurantId, credentials: $credentials) {
      id

      hasPersonalkollenCredentials
    }
  }
`

interface FormInput {
  credentials: PersonalkollenCredentials
}

const schema: ObjectSchema<FormInput> = yup.object({
  credentials: yup.object().shape({
    token: yup.string().required()
  })
})

const RestaurantPersonalkollenView: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantPersonalkollenView'>()

  const form = useForm<FormInput>({
    context: yupResolver(schema),
    criteriaMode: 'all',
    mode: 'onChange'
  })

  const { data, loading } = useGetRestaurantPersonalkollenDataQuery({ variables: { restaurantId } })
  const [acceptTermsOfService, { error: acceptTermsOfServiceError, loading: acceptingTermsOfService }] = useAcceptPersonalkollenIntegrationTermsOfServiceMutation()
  const [linkAccount, { error: linkAccountError, loading: linkingAccount }] = useLinkPersonalkollenAccountMutation()

  const handleAcceptPress = (): void => {
    acceptTermsOfService({ variables: { restaurantId } }).catch(logError)
  }

  const handleLinkPress = (input: FormInput): void => {
    linkAccount({ variables: { restaurantId, credentials: input.credentials } }).catch(logError)
  }

  if (loading) {
    return <Layout loading title='Personalkollen' />
  }

  return (
    <Layout hideTitle screenType={ScreenType.Dashboard} title='Personalkollen'>
      <VStack>
        <HStack>
          <SecondaryButton
            icon='chevron-left'
            onPress={() => navigation.navigate('RestaurantDashboard', { restaurantId })}
            title='Tillbaka'
          />
        </HStack>

        <ListContainer title='Personalkollen'>
          <SetupContainer
            disabled={data?.restaurant?.hasPersonalkollenAccess ?? false}
            disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
          >
            <HStack alignItems='center' gap={8}>
              <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                <Text color='white' size={16}>1</Text>
              </VStack>

              <Text color={ACCENT_COLOR} size={24}>Acceptera Terms of Service</Text>
            </HStack>

            <Text size={12}>För att kunna använda integrationen med personalkollen måste du acceptera våra Terms of Service.</Text>
            <Text size={12}>Denna tjänst möjliggör</Text>
            <Text paddingLeft={8} size={12}>☑️ Synkronisering av försäljningsstatistik till Personalkollen</Text>

            <Text size={12}>
              Genom att acceptera villkoren för användandet av denna funktion bekräftar du att du läst våra <Link title='Terms of Service' url='https://gastrogate.notion.site/Terms-of-Service-Loco-d501714a78054cb5af6315c7ca0df83d' /> och att priset sätts i enlighet med rådande <Link title='prislista på gastrogate.io' url='https://gastrogate.io/personalkollen/' />.
            </Text>

            <Spacer height={12} />

            {acceptTermsOfServiceError?.message == null ? null : <Warning message={acceptTermsOfServiceError.message} paddingBottom={12} />}

            <PrimaryButton
              loading={acceptingTermsOfService}
              onPress={handleAcceptPress}
              title='Acceptera användarvillkor'
            />
          </SetupContainer>

          {!(data?.restaurant?.hasPersonalkollenAccess ?? false) ? null : (
            <>
              {(data?.restaurant?.hasPersonalkollenCredentials ?? false) ? null : (
                <SetupContainer>
                  <PersonalkollenServices />
                </SetupContainer>
              )}

              <SetupContainer
                disabled={data?.restaurant?.hasPersonalkollenCredentials ?? false}
                disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
              >
                <HStack alignItems='center' gap={8}>
                  <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                    <Text color='white' size={16}>2</Text>
                  </VStack>

                  <Text color={ACCENT_COLOR} size={24}>Länka Personalkollen med Loco</Text>
                </HStack>

                <Text size={12}>Följ instruktionerna i bilddiagrammet ovan för att hämta nyckel från Personalkollen och klista in det i fältet härnedan.</Text>

                <Spacer height={12} />

                <TextField
                  form={form}
                  hint='Skriv in din nyckel från Personalkollen här'
                  name='credentials.token'
                  title='Nyckel från Personalkollen'
                />

                <Spacer height={12} />

                {linkAccountError?.message == null ? null : <Warning message={linkAccountError.message} paddingBottom={12} />}

                <PrimaryButton
                  loading={linkingAccount}
                  onPress={ignoreAsync(form.handleSubmit(handleLinkPress))}
                  title='Länka Personalkollen och Loco'
                />
              </SetupContainer>
            </>
          )}
          {!(data?.restaurant?.hasPersonalkollenCredentials ?? false) ? null : (
            <SetupContainer>
              <VStack backgroundColor='#FFF' borderRadius={4} gap={16} padding={8}>
                <HStack alignItems='center' gap={8}>
                  <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                    <Text color='white' size={16}>3</Text>
                  </VStack>

                  <Text color={ACCENT_COLOR} size={24}>Synkronisering av Personalkollen klar!</Text>
                </HStack>

                <VStack alignSelf='center' backgroundColor='#F7F8FA' borderRadius={10} gap={10} padding={20}>
                  <Text size={16}>
                    Du har nu tillgång till försäljningsstatistik från Loco i Personalkollen.
                  </Text>
                </VStack>

                <PrimaryButton
                  onPress={() => openLink('https://personalkollen.se')}
                  title='Gå till Personalkollen'
                />
              </VStack>
            </SetupContainer>
          )}
        </ListContainer>
      </VStack>

      <DashboardContainer gap={12}>
        <VStack>
          <Text size={24}>
            <InfoIcon content='' /> Personalkollen
          </Text>

          <Divider />
        </VStack>

        <Text size={16}>
          Personalkollen är en tjänst som hjälper dig att hålla koll på dina anställdas arbetstider och löner.
        </Text>

        <Text size={16}>
          Du kan läsa mer om tjänsten på <Link title='personalkollen.se' url='https://personalkollen.se' />.
        </Text>

        <Text size={16}>
          Om du har några frågor eller funderingar är du välkommen att kontakta oss på <Link title='service@gastrogate.io' url='mailto:service@gastrogate.io' />
        </Text>

        <Divider />

        <Text size={16}>
          Genom att klicka på acceptera användarvillkor godkänner du att vi debiterar dig i enlighet med rådande prislista för denna integration. Vid prisändring kommer du att meddelas i förväg.
        </Text>

        <Text size={16}>
          Därefter kommer du fullborda integrationen mot personalkollen genom att utföra guidade steg som vi hjälper dig igenom här på sidan.
        </Text>

        <Divider />

        <Text size={16}>
          Du kan när som helst återkalla ditt samtycke genom att kontakta oss på <Link title='service@gastrogate.io' url='mailto:service@gastrogate.io' />
        </Text>

        <Divider />

        <Text size={16}>
          Du kan även kontakta Personalkollen direkt på <Link title='personalkollen.se' url='https://personalkollen.se' /> för mer information om hur de hanterar dina personuppgifter.
        </Text>
      </DashboardContainer>
    </Layout>
  )
}

export default RestaurantPersonalkollenView

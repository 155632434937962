import gql from 'graphql-tag'
import React, { useCallback, useMemo } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { VStack } from 'react-stacked'

import { useGetRestaurantDiscountsQuery, useUpdateRestaurantMutation } from '../../types/graphql'
import { AddButton } from '../components/Buttons'
import DiscountList from '../components/DiscountList'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import { ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR } from '../components/PanicButton'
import Warning from '../components/Warning'
import { CheckBox } from '../components/fields'
import { ACCENT_COLOR } from '../lib/color'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullDiscount on Discount {
    id

    code
    createdAt
    description
    discountAmount
    discountLimit
    discountPercentage
    isDisabled
    maxAmount
    menuType
    minAmount
    numberOfTimesPerUser
    title
    totalDiscountAmount
    updatedAt
    usability
    validFrom
    validUntil

    metaOrganization {
      id
    }

    openingHours {
      open { day, time }
      close { day, time }
    }

    requiresGuestGroups {
      id
    }

    requiresRestaurants {
      id
    }

    restaurant {
      id
    }
  }

  query GetRestaurantDiscounts($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      discountsEnabled
      timeZone

      discounts(first: 1000) {
        edges {
          node {
            ...FullDiscount
          }
        }
      }
    }
  }
`

const RestaurantDiscountsList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantDiscountsList'>()

  const { data, loading } = useGetRestaurantDiscountsQuery({
    variables: { restaurantId }
  })
  const [updateRestaurant, { error: updateRestaurantError, loading: updateRestaurantLoading }] = useUpdateRestaurantMutation()

  const discountsEnabled = data?.restaurant?.discountsEnabled

  const handleCreate = useCallback(() => {
    navigation.navigate('RestaurantDiscountCreate', { restaurantId })
  }, [navigation, restaurantId])

  const handleDiscountsEnabledPress = (discountsEnabled: boolean): void => {
    updateRestaurant({
      variables: { restaurantId, patch: { discountsEnabled } }
    }).catch(logError)
  }

  const handleOnDiscountPress = useCallback((discountId: string) => {
    navigation.navigate('RestaurantDiscountEdit', { restaurantId, discountId })
  }, [navigation, restaurantId])

  const discounts = useMemo(() => data?.restaurant?.discounts?.edges.map(edge => edge.node), [data])

  return (
    <Layout hideTitle screenType={ScreenType.List} title='Rabatter'>
      <ListContainer title='Rabatter'>
        {loading
          ? null
          : (
            <View style={{ backgroundColor: (discountsEnabled ?? false) ? undefined : '#efefef', borderRadius: 8, padding: 16, paddingBottom: 0 }}>
              {(discountsEnabled ?? false)
                ? null
                : <Warning message='Rabattkoder är inte markerade som aktiva och syns således inte i Loco & egen app, eller i LocoPOS Order' paddingBottom={8} />}

              {updateRestaurantError == null
                ? null
                : <Warning message={updateRestaurantError.message} paddingBottom={8} />}

              {updateRestaurantLoading
                ? (
                  <VStack alignItems='center' backgroundColor='#ffffff' borderRadius={5} justifyContent='center' padding={24}>
                    <ActivityIndicator color={ACCENT_COLOR} size='large' />
                  </VStack>
                ) : (
                  <CheckBox
                    checked={discountsEnabled ?? false}
                    checkedColor={ACTIVE_BUTTON_COLOR}
                    onPress={() => handleDiscountsEnabledPress(!(discountsEnabled ?? false))}
                    title='Aktivera rabattkoder - Fält visas i varukorgen i Loco & egen app, och beroende på inställningar i Kassor för POS'
                    uncheckedColor={INACTIVE_BUTTON_COLOR}
                  />
                )}
            </View>
          )}

        <AddButton
          icon='add-circle'
          onPress={handleCreate}
          title='Skapa ny rabatt'
        />

        <DiscountList
          discounts={discounts}
          onPress={handleOnDiscountPress}
          parentType='Restaurant'
          timeZone={data?.restaurant?.timeZone ?? undefined}
        />
      </ListContainer>
    </Layout>
  )
}

export default RestaurantDiscountsList

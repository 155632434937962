import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { useGetAppVersionsDataQuery, useGetRestaurantsStatusQuery, useGetTransactionChannelIncomeDataQuery } from '../../types/graphql'
import Layout, { ScreenType } from '../components/Layout'
import { Cell, Column, Row, Table } from '../components/Table'
import WidgetContainer from '../components/WidgetContainer'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'
import formatDuration from '../util/formatDuration'
import getDeviceTimeZone from '../util/getDeviceTimeZone'
import serializeZonedDateTimeRange from '../util/serializeZonedDateTimeRange'

const COLOR_INACTIVE = '#ed6e69'
const COLOR_WARNING = 'red'

gql`
  query GetAppVersionsData {
    appVersions (filter: { app: Order }) {
      id

      gitRef
      userDevicesCount
      version
    }
  }

  query GetTransactionChannelIncomeData ($dateTimeRange: DateTimeRangeInput!) {
    transactionChannelIncome (dateTimeRange: $dateTimeRange) {
      id

      totalAmount
      totalSales
      transactionChannel
    }
  }

  query GetRestaurantsStatus {
    restaurants(first: 100000) {
      edges {
        node {
          id

          kitchenOrderQueueGrossAmount
          kitchenOrderQueueLength
          lastActiveTime
          lastOrderPlacedAt
          name
        }
      }
    }
  }
`

const SuperUserDashboard: React.FC = () => {
  const deviceTimeZone = getDeviceTimeZone()

  const now = Temporal.Now.zonedDateTimeISO(deviceTimeZone)
  const startOfMonth = now.with({ day: 1 }).startOfDay()

  const dateTimeRange = serializeZonedDateTimeRange({ start: startOfMonth, end: startOfMonth.add({ months: 1 }) })
  const { data: appVersionsData, loading: loadingAppVersionsData } = useGetAppVersionsDataQuery()
  const { data: incomeData, loading: loadingIncomeData } = useGetTransactionChannelIncomeDataQuery({ variables: { dateTimeRange } })
  const { data: restaurantsData, loading: loadingRestaurantsData } = useGetRestaurantsStatusQuery()

  const appVersions = useMemo(() => {
    return sortOn(appVersionsData?.appVersions ?? [], [
      // Sort null versions first
      ({ version }) => version == null ? 0 : 1,
      // Then descending version
      '-version',
      // Use id as tie breaker
      'id'
    ], { localeOptions: { numeric: true } })
  }, [appVersionsData?.appVersions])

  const transactionChannelIncome = incomeData?.transactionChannelIncome ?? []

  const restaurantEdges = useMemo(() => {
    if (restaurantsData?.restaurants?.edges == null) return null

    return sortOn(restaurantsData.restaurants.edges ?? [], 'node.name', { locales: 'sv-SE' })
  }, [restaurantsData])

  return (
    <Layout screenType={ScreenType.Dashboard} title='Dashboard'>
      <WidgetContainer>
        <Text size={24}>Versionsanvändning för LocoPOS Order</Text>

        <Spacer height={16} />

        {loadingAppVersionsData
          ? (
            <VStack alignItems='center' grow={1} justifyContent='center'>
              <ActivityIndicator />
            </VStack>
          )
          : (
            <>
              <HStack>
                <VStack basis={1} grow={1}>
                  <Text>
                    <TextStyle size={18} weight='bold'>Version</TextStyle>
                    <TextStyle color='#aaa' size={18} weight='bold'>#gitRef</TextStyle>
                  </Text>
                </VStack>

                <Spacer width={12} />

                <VStack alignItems='end' basis={1} grow={1}>
                  <Text size={18} weight='bold'>Antal enheter</Text>
                </VStack>
              </HStack>

              {appVersions.map(appVersion => (
                <HStack key={appVersion.id}>
                  <VStack basis={1} grow={1}>
                    <Text>
                      <TextStyle size={18} weight='400'>{appVersion.version == null ? '< 1.8.0' : appVersion.version}</TextStyle>
                      <TextStyle color='#888' size={18} weight='400'>{appVersion.gitRef == null ? '' : `#${appVersion.gitRef}`}</TextStyle>
                    </Text>
                  </VStack>

                  <Spacer width={12} />

                  <VStack alignItems='end' basis={1} grow={1}>
                    <Text size={18} weight='400'>{appVersion.userDevicesCount}</Text>
                  </VStack>
                </HStack>
              ))}
            </>
          )}
      </WidgetContainer>

      <WidgetContainer>
        <Text size={24}>Inkomst per transaktionkanal under {now.toPlainYearMonth().toString()}</Text>

        <Spacer height={16} />

        {loadingIncomeData
          ? (
            <VStack alignItems='center' grow={1} justifyContent='center'>
              <ActivityIndicator />
            </VStack>
          )
          : (
            <VStack>
              <HStack>
                <VStack basis={1} grow={1}>
                  <Text size={18} weight='bold'>Transaktionskanal</Text>
                </VStack>

                <VStack alignItems='end' basis={1} grow={1}>
                  <Text size={18} weight='bold'>Inkomst</Text>
                </VStack>

                <Spacer width={12} />

                <VStack alignItems='end' basis={1} grow={1}>
                  <Text size={18} weight='bold'>Total försäljning</Text>
                </VStack>
              </HStack>

              {transactionChannelIncome.map(channel => (
                <HStack key={channel.id}>
                  <VStack basis={1} grow={1}>
                    <Text size={18} weight='400'>{channel.transactionChannel}</Text>
                  </VStack>

                  <Spacer width={16} />

                  <VStack alignItems='end' basis={1} grow={1}>
                    <Text size={16} weight='400'>{formatCurrency(channel.totalAmount ?? 0)}</Text>
                  </VStack>

                  <Spacer width={16} />

                  <VStack alignItems='end' basis={1} grow={1}>
                    <Text size={16} weight='400'>{formatCurrency(channel.totalSales ?? 0)}</Text>
                  </VStack>
                </HStack>
              ))}

              <Spacer height={16} />

              <HStack>
                <VStack basis={1} grow={1}>
                  <Text size={18} weight='400'>Total</Text>
                </VStack>

                <VStack alignItems='end' basis={1} grow={1}>
                  <Text size={16} weight='400'>{formatCurrency(transactionChannelIncome.reduce((mem, val) => mem + (val.totalAmount ?? 0), 0))}</Text>
                </VStack>

                <Spacer width={16} />

                <VStack alignItems='end' basis={1} grow={1}>
                  <Text size={16} weight='400'>{formatCurrency(transactionChannelIncome.reduce((mem, val) => mem + (val.totalSales ?? 0), 0))}</Text>
                </VStack>
              </HStack>
            </VStack>
          )}
      </WidgetContainer>

      <WidgetContainer>
        <Text size={24}>Restauranger</Text>

        <Spacer height={16} />

        {loadingRestaurantsData
          ? (
            <VStack alignItems='center' grow={1} justifyContent='center'>
              <ActivityIndicator />
            </VStack>
          )
          : (
            <Table>
              <Column paddingHorizontal={8} />
              <Column align='end' paddingHorizontal={8} />
              <Column align='end' paddingHorizontal={8} />
              <Column align='end' paddingHorizontal={8} />

              <Row>
                <Cell>
                  <Text weight='bold'>Restaurang</Text>
                </Cell>

                <Cell>
                  <Text weight='bold'>Beställningar i kö</Text>
                </Cell>

                <Cell>
                  <Text weight='bold'>Försäljning i kö</Text>
                </Cell>

                <Cell>
                  <Text weight='bold'>Senaste order</Text>
                </Cell>
              </Row>

              {restaurantEdges?.map(({ node: restaurant }) => {
                const lastActiveTime = restaurant.lastActiveTime == null ? null : Temporal.Instant.from(restaurant.lastActiveTime)
                const lastActiveSince = lastActiveTime == null ? null : now.toInstant().since(lastActiveTime)
                const isInactive = lastActiveSince == null ? false : Temporal.Duration.compare(lastActiveSince, { minutes: 10 }) > 0
                const hasOrderInQueue = (restaurant.kitchenOrderQueueLength ?? 0) > 0
                const textColor = hasOrderInQueue && (isInactive ?? false) ? 'white' : 'black'
                const backgroundColor = !(isInactive ?? false) ? undefined : (hasOrderInQueue ? COLOR_WARNING : COLOR_INACTIVE)

                return (
                  <Row key={restaurant.id} backgroundColor={backgroundColor}>
                    <Cell>
                      {!isInactive
                        ? null
                        : (
                          <>
                            {hasOrderInQueue
                              ? <Text color={textColor} size={16} weight='bold'>OFFLINE - Order i kö</Text>
                              : <Text color={textColor} size={16} weight='bold'>OFFLINE</Text>}

                            {lastActiveSince == null ? null : <Text color={textColor} size={12}>{`Senast aktiv ${formatDuration(lastActiveSince, { compact: true })} sedan`}</Text>}
                          </>
                        )}

                      <Text color={textColor}>{restaurant.name}</Text>
                    </Cell>

                    <Cell>
                      <Text color={textColor}>{restaurant.kitchenOrderQueueLength}</Text>
                    </Cell>

                    <Cell>
                      <Text color={textColor}>{restaurant.kitchenOrderQueueGrossAmount == null ? null : formatCurrency(restaurant.kitchenOrderQueueGrossAmount)}</Text>
                    </Cell>

                    <Cell>
                      <Text color={textColor}>{restaurant.lastOrderPlacedAt == null ? null : formatDateTime(Temporal.Instant.from(restaurant.lastOrderPlacedAt).toZonedDateTimeISO(deviceTimeZone), { dateStyle: 'short', timeStyle: 'short' })}</Text>
                    </Cell>
                  </Row>
                )
              })}
            </Table>
          )}
      </WidgetContainer>
    </Layout>
  )
}

export default SuperUserDashboard

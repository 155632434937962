import { MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, type LayoutChangeEvent, View, useWindowDimensions } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import WithSeparator from 'react-with-separator'
import sortOn from 'sort-on'
import unreachable from 'ts-unreachable'

import { type GetRestaurantSalesQuery, type GetRestaurantSalesQueryVariables, MenuType, type RestaurantStatisticsFilter, useGetRestaurantSalesQuery, useGetRestaurantStatisticsViewDataQuery } from '../../types/graphql'
import { PrimaryButton, SaveAsButton, UpdateButton } from '../components/Buttons'
import DailySalesPlot from '../components/DailySalesPlot'
import { DataColumn, DataTable } from '../components/DataTable'
import DateRangeSelect from '../components/DateRangeSelect'
import Divider from '../components/Divider'
import DownloadSalesForm from '../components/DownloadSalesForm'
import Layout, { ScreenType } from '../components/Layout'
import PieChart from '../components/PieChart'
import SalesFilter from '../components/SalesFilter'
import StatisticsContainer from '../components/StatisticsContainer'
import { Cell, Column, Row, RowGroup, Table } from '../components/Table'
import Warning from '../components/Warning'
import { CheckBox, TextField } from '../components/fields'
import dateRangeFromTimePeriod, { type PlainDateRange } from '../util/dateRangeFromTimePeriod'
import downloadCsvAsFile from '../util/downloadCsvAsFile'
import formatCurrency from '../util/formatCurrency'
import getDeviceTimeZone from '../util/getDeviceTimeZone'
import logError from '../util/logError'
import { resolutionForDailySales } from '../util/resolutionForDailySales'
import serializeZonedDateTimeRange from '../util/serializeZonedDateTimeRange'
import useNavigation from '../util/useNavigation'

import { getLastUpdatedLabel } from './StatisticsOverview'

gql`
  query GetRestaurantStatisticsViewData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      dailyReconciliationTime
      hasDeliveryHeroAccess
      timeZone
    }
  }
`

const MOBILE_VIEW_TRESHOLD_WIDTH = 700

enum SortOrder {
  ASC = '',
  DESC = '-'
}

type RefundsByPaymentName = NonNullable<NonNullable<NonNullable<GetRestaurantSalesQuery['restaurant']>['statistics']>['refundsByPaymentName']>[number]
type RefundsByProduct = NonNullable<NonNullable<NonNullable<GetRestaurantSalesQuery['restaurant']>['statistics']>['refundsByProduct']>[number]
type SalesByMenuType = NonNullable<NonNullable<NonNullable<GetRestaurantSalesQuery['restaurant']>['statistics']>['salesByMenuType']>[number]
type SalesByPaymentName = NonNullable<NonNullable<NonNullable<GetRestaurantSalesQuery['restaurant']>['statistics']>['salesByPaymentName']>[number]
type SalesByProduct = NonNullable<NonNullable<NonNullable<GetRestaurantSalesQuery['restaurant']>['statistics']>['salesByProduct']>[number]
type SalesByReportGroup = NonNullable<NonNullable<NonNullable<GetRestaurantSalesQuery['restaurant']>['statistics']>['salesByReportGroup']>[number]

type ProductTableColumns = keyof Pick<SalesByProduct, 'name' | 'count' | 'price' | 'discountAmount' | 'grossAmount' | 'vatAmount' | 'netAmount'>

type AddonRow = Omit<NonNullable<SalesByProduct['addons']>[number] | NonNullable<RefundsByProduct['addons']>[number], '__typename'> & { readonly __typename?: string }
type AlternativeItemRow = Omit<NonNullable<SalesByProduct['alternativeItems']>[number] | NonNullable<RefundsByProduct['alternativeItems']>[number], '__typename'> & { readonly __typename?: string }
type ProductRow = Omit<SalesByProduct | RefundsByProduct, '__typename' | 'addons' | 'alternativeItems'> & { readonly __typename?: string, readonly addons?: readonly AddonRow[] | null, readonly alternativeItems?: readonly AlternativeItemRow[] | null }

const HorizontalSeparator: React.FC = () => (
  <VStack paddingVertical={16}>
    <VStack backgroundColor='#AAA' height={1} />
  </VStack>
)

const VerticalSeparator: React.FC = () => (
  <HStack paddingHorizontal={16}>
    <HStack backgroundColor='#AAA' width={1} />
  </HStack>
)

interface SortButtonProps {
  field: string
  sortCriteria: { field: string, order: SortOrder }
  title: string
}

const SortButton: React.FC<SortButtonProps> = ({ field, sortCriteria, title }) => (
  <HStack alignItems='center'>
    <SortIcon sortOrder={field === sortCriteria.field ? sortCriteria.order : undefined} />
    <Text weight={field === sortCriteria.field ? 'bold' : '500'}>{title}</Text>
  </HStack>
)

function mergeRows<T extends ProductRow | AddonRow> (a: T, b: T): T {
  const count = (a.count ?? 0) + (b.count ?? 0)

  return {
    ...a,
    count,
    discountAmount: (a.discountAmount ?? 0) + (b.discountAmount ?? 0),
    grossAmount: (a.grossAmount ?? 0) + (b.grossAmount ?? 0),
    netAmount: (a.netAmount ?? 0) + (b.netAmount ?? 0),
    price: ((a.price ?? 0) * (a.count ?? 0) + (b.price ?? 0) * (b.count ?? 0)) / count,
    vatAmount: (a.vatAmount ?? 0) + (b.vatAmount ?? 0)
  }
}

function groupSales<T extends ProductRow | AddonRow> (rows: readonly T[], by: 'name' | 'nameAndPrice', merger: (a: T, b: T) => T): T[] {
  const keyProducer: (row: T) => string = by === 'name'
    ? row => `${row.__typename ?? ''}__${row.name ?? ''}`
    : row => `${row.__typename ?? ''}__${row.name ?? ''}__${row.price ?? 0}`

  const groupedProducts: Record<string, T> = {}
  for (const row of rows) {
    const key = keyProducer(row)
    const previous = groupedProducts[key]

    if (previous != null) {
      groupedProducts[key] = merger(previous, row)
    } else {
      groupedProducts[key] = row
    }
  }

  return Object.values(groupedProducts)
}

function groupAddonSales (addons: readonly AddonRow[], by: 'name' | 'nameAndPrice' = 'name'): AddonRow[] {
  return groupSales(addons, by, mergeRows)
}

function groupAlternativeItemRows (items: readonly AlternativeItemRow[]): AlternativeItemRow[] {
  const groupedAlternativeItems: Record<string, AlternativeItemRow> = {}
  for (const row of items) {
    const key = row.name ?? ''
    const previous = groupedAlternativeItems[key]

    groupedAlternativeItems[key] = previous != null ? { ...previous, count: (previous.count ?? 0) + (row.count ?? 0) } : row
  }

  return Object.values(groupedAlternativeItems)
}

function groupProductSales (products: readonly ProductRow[]): ProductRow[] {
  const merger = (a: ProductRow, b: ProductRow): ProductRow => ({
    ...mergeRows(a, b),
    addons: [...(a.addons ?? []), ...(b.addons ?? [])]
  })

  return groupSales(products, 'name', merger)
}

interface SalesByMenuTypeProps {
  menuTypeData?: readonly SalesByMenuType[] | null
}

const SalesByMenuTypeView: React.FC<SalesByMenuTypeProps> = ({ menuTypeData }) => {
  const [width, setWidth] = useState(0)
  const windowDimensions = useWindowDimensions()

  const handleLayoutChange = useCallback((event: LayoutChangeEvent) => setWidth(event.nativeEvent.layout.width), [setWidth])

  const sales = useMemo(() => {
    return sortOn(menuTypeData ?? [], [
      (item) => (item.menuType === MenuType.TakeAway) ? 0 : 1,
      (item) => (item.menuType === MenuType.EatIn) ? 0 : 1,
      (item) => (item.menuType === MenuType.Delivery) ? 0 : 1
    ])
  }, [menuTypeData])

  useEffect(() => setWidth(0), [sales.length])

  if (width > windowDimensions.width) {
    return (
      <VStack padding={10}>
        <WithSeparator separator={<HorizontalSeparator />}>
          {sales.map(sale => <SalesByMenuTypeItem key={sale.menuType} sales={sale} />)}
        </WithSeparator>
      </VStack>
    )
  }

  return (
    <View onLayout={handleLayoutChange}>
      <HStack padding={8}>
        <WithSeparator separator={<VerticalSeparator />}>
          {sales.map(sale => <SalesByMenuTypeItem key={sale.menuType} sales={sale} />)}
        </WithSeparator>
      </HStack>
    </View>
  )
}

const menuTypeLabel = (menuType: MenuType): string => {
  switch (menuType) {
    case MenuType.Delivery:
      return 'LEVERANS'
    case MenuType.EatIn:
      return 'ÄTA HÄR'
    case MenuType.TakeAway:
      return 'TA MED'
    case MenuType.GiftCard:
      return 'PRESENTKORT'
    default:
      unreachable(menuType)
  }
}
interface SalesByMenuTypeItemProps {
  sales: SalesByMenuType
}

const SalesByMenuTypeItem: React.FC<SalesByMenuTypeItemProps> = ({ sales: data }) => (
  <VStack alignItems='center' justifyContent='center' padding={12}>
    <HStack alignItems='baseline'>
      <Text size={56}>{data.count}</Text>
      <Text size={12}>ST</Text>
    </HStack>

    <Text color='#333' size={12}>beställningar</Text>

    <Spacer height={8} />

    <Text numberOfLines={1} size={20}>{data.grossAmount == null ? '' : formatCurrency(data.grossAmount)}</Text>

    <Spacer height={4} />

    <Text color='#333' numberOfLines={1} size={12}>(varav moms: {data.vatAmount == null ? '' : formatCurrency(data.vatAmount)})</Text>

    <Spacer height={8} />

    <Text size={12} weight='bold'>{data.menuType == null ? '' : menuTypeLabel(data.menuType)}</Text>
  </VStack>
)

const SortIcon: React.FC<{ sortOrder?: SortOrder }> = ({ sortOrder }) => {
  if (sortOrder == null) return <MaterialIcons color='#aaa' name='keyboard-arrow-right' size={24} />

  return sortOrder === SortOrder.ASC ? <MaterialIcons name='keyboard-arrow-up' size={24} /> : <MaterialIcons name='keyboard-arrow-down' size={24} />
}

interface ReportGroupListingProps {
  mobileView: boolean
  reportGroupRows: readonly SalesByReportGroup[]
}

const ReportGroupListing: React.FC<ReportGroupListingProps> = ({ mobileView, reportGroupRows }) => {
  const { reportGroupAmounts, reportGroupCounts } = useMemo(() => {
    const totalAmount = reportGroupRows.reduce((mem, item) => mem + (item.grossAmount ?? 0), 0)
    const totalCount = reportGroupRows.reduce((mem, item) => mem + (item.count ?? 0), 0)

    const reportGroupAmounts = []
    const reportGroupCounts = []
    let smallAmounts
    let smallCounts

    for (const row of reportGroupRows) {
      if ((row.grossAmount ?? 0) / totalAmount < 0.1) {
        if (smallAmounts == null) {
          smallAmounts = { x: `${row.reportGroupName ?? ''}\n${formatCurrency(row.grossAmount ?? 0)}`, y: row.grossAmount ?? 0 }
        } else {
          smallAmounts.y += row.grossAmount ?? 0
          smallAmounts.x = `Övrigt\n${formatCurrency(smallAmounts.y)}`
        }
      } else {
        reportGroupAmounts.push({ x: `${row.reportGroupName ?? ''}\n${formatCurrency(row.grossAmount ?? 0)}`, y: row.grossAmount ?? 0 })
      }

      if ((row.count ?? 0) / totalCount < 0.1) {
        if (smallCounts == null) {
          smallCounts = { x: `${row.reportGroupName ?? ''}\n${row.count ?? 0} st`, y: row.count ?? 0 }
        } else {
          smallCounts.y += row.count ?? 0
          smallCounts.x = `Övrigt\n${smallCounts.y ?? 0} st`
        }
      } else {
        reportGroupCounts.push({ x: `${row.reportGroupName ?? ''}\n${row.count ?? 0} st`, y: row.count ?? 0 })
      }
    }

    if (smallAmounts != null) reportGroupAmounts.push(smallAmounts)
    if (smallCounts != null) reportGroupCounts.push(smallCounts)

    return { reportGroupAmounts, reportGroupCounts }
  }, [reportGroupRows])

  const figure = reportGroupCounts.length === 0
    ? null
    : (
      <View style={{ alignItems: 'center', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center' }}>
        <VStack alignItems='center'>
          <Text>Antal produkter per rapportgrupp</Text>
          <PieChart data={reportGroupCounts} />
        </VStack>
        <VStack alignItems='center'>
          <Text>Total försäljning per rapportgrupp</Text>
          <PieChart data={reportGroupAmounts} />
        </VStack>
      </View>
    )

  return (
    <StatisticsContainer>
      <DataTable csvFileName='report-group-sales.csv' data={reportGroupRows} figure={figure} initialSort='-grossAmount' title='Rapportgrupper'>
        <DataColumn<SalesByReportGroup> field='reportGroupName' title='Namn' type='string' />
        <DataColumn<SalesByReportGroup> field='count' title='Antal' type='number' />
        <DataColumn<SalesByReportGroup> field='grossAmount' minimumFractionDigits={2} title='Brutto' type='currency' />
      </DataTable>
    </StatisticsContainer>
  )
}

type SumAdaptionsRow = Omit<ProductRow, 'addons' | 'alternativeItems'> | AddonRow | AlternativeItemRow

type ProductTableRow = ProductRow | SumAdaptionsRow

interface ProductsTableProps {
  csvFileName: string
  mobileView: boolean
  onAddonProductPress: (menuAddonProductId: string) => void
  onProductPress: (menuProductId: string) => void
  predicate?: (item: ProductTableRow) => boolean
  productRows: readonly ProductRow[]
  splitProductsByPrice: boolean
  sumAdaptions: boolean
  title: string
}

const ProductsTable: React.FC<ProductsTableProps> = ({ csvFileName, mobileView, onAddonProductPress, onProductPress, predicate, productRows, splitProductsByPrice, sumAdaptions, title }) => {
  const [sortCriteria, setSortCriteria] = useState<{ field: ProductTableColumns, order: SortOrder }>({ field: 'count', order: SortOrder.DESC })

  const rows = useMemo<ProductTableRow[]>(() => {
    // Sort by more than one criteria to make the order more stable
    const sortOnProperty = sortCriteria.field === 'name' ? [`${sortCriteria.order}name`, 'count'] : [`${sortCriteria.order}${sortCriteria.field}`, 'name']

    function assembleSumAdaptionRows (): SumAdaptionsRow[] {
      const addons: AddonRow[] = []
      const alternativeItems: AlternativeItemRow[] = []
      const products: ProductRow[] = []

      // Remove all addons and alternative items from products and collect them separately
      for (const product of productRows) {
        if (predicate == null) {
          addons.push(...(product.addons ?? []))
          alternativeItems.push(...(product.alternativeItems ?? []))
          products.push({ ...product, addons: [], alternativeItems: [] })
        } else {
          addons.push(...(product.addons?.filter(predicate) ?? []))
          alternativeItems.push(...(product.alternativeItems?.filter(predicate) ?? []))
          if (predicate(product)) products.push({ ...product, addons: [], alternativeItems: [] })
        }
      }

      if (splitProductsByPrice) {
        return [...products, ...groupAddonSales(addons, 'nameAndPrice'), ...groupAlternativeItemRows(alternativeItems)]
      } else {
        return [...groupProductSales(products), ...groupAddonSales(addons), ...groupAlternativeItemRows(alternativeItems)]
      }
    }

    function assembleProductRows (): ProductRow[] {
      const result: ProductRow[] = []
      if (splitProductsByPrice) {
        for (let { addons, alternativeItems, ...product } of productRows) {
          if (predicate != null) {
            addons = addons?.filter(predicate)
            alternativeItems = alternativeItems?.filter(predicate)
          }

          // Include the product if any addon or alternative item got a match
          if (predicate == null || (addons?.length ?? 0) > 0 || (alternativeItems?.length ?? 0) > 0 || predicate(product)) {
            result.push({
              ...product,
              addons: sortOn(addons ?? [], sortOnProperty),
              alternativeItems: sortOn(alternativeItems ?? [], sortOnProperty)
            })
          }
        }
      } else {
        for (let { addons, alternativeItems, ...product } of groupProductSales(productRows)) {
          if (predicate != null) {
            addons = (addons ?? []).filter(predicate)
            alternativeItems = (alternativeItems ?? []).filter(predicate)
          }

          // Include the product if any addon or alternative item got a match
          if (predicate == null || (addons?.length ?? 0) > 0 || (alternativeItems?.length ?? 0) > 0 || predicate(product)) {
            result.push({
              ...product,
              addons: sortOn(groupAddonSales(addons ?? []), sortOnProperty),
              alternativeItems: sortOn(groupAlternativeItemRows(alternativeItems ?? []), sortOnProperty)
            })
          }
        }
      }

      return result
    }

    return sortOn(sumAdaptions ? assembleSumAdaptionRows() : assembleProductRows(), sortOnProperty)
  }, [predicate, productRows, splitProductsByPrice, sortCriteria, sumAdaptions])

  const summary = useMemo(() => {
    const result = {
      count: 0,
      discountAmount: 0,
      grossAmount: 0,
      netAmount: 0,
      vatAmount: 0
    }

    function merge (row: ProductRow | SumAdaptionsRow): void {
      result.count += row.count ?? 0

      if ('discountAmount' in row) result.discountAmount += row.discountAmount ?? 0
      if ('grossAmount' in row) result.grossAmount += row.grossAmount ?? 0
      if ('netAmount' in row) result.netAmount += row.netAmount ?? 0
      if ('vatAmount' in row) result.vatAmount += row.vatAmount ?? 0
    }

    for (const row of rows) {
      merge(row)

      if ('addons' in row) row.addons?.forEach(merge)
      if ('alternativeItems' in row) row.alternativeItems?.forEach(merge)
    }

    return result
  }, [rows])

  const handleCsvPress = (): void => {
    downloadCsvAsFile(
      csvFileName,
      rows.flatMap(row => [
        {
          Namn: ((row.__typename === 'RestaurantStatisticsRefundsByProduct' || row.__typename === 'RestaurantStatisticsSalesByProduct') ? '' : ' \u2022 ') + (row.name ?? ''),
          Antal: row.count ?? 0,
          [splitProductsByPrice ? 'Pris' : 'Snittpris']: ('price' in row && row.price != null) ? row.price / 100 : '',
          Rabatt: ('discountAmount' in row && row.discountAmount != null) ? row.discountAmount / 100 : '',
          Brutto: ('grossAmount' in row && row.grossAmount != null) ? row.grossAmount / 100 : '',
          Moms: ('vatAmount' in row && row.vatAmount != null) ? row.vatAmount / 100 : '',
          Netto: ('netAmount' in row && row.netAmount != null) ? row.netAmount / 100 : ''
        },
        ...(!('addons' in row) || row.addons == null
          ? []
          : row.addons.map(addon => ({
            Namn: ' \u2022 ' + (addon.name ?? ''),
            Antal: addon.count ?? 0,
            [splitProductsByPrice ? 'Pris' : 'Snittpris']: ('price' in addon && addon.price != null) ? addon.price / 100 : '',
            Rabatt: ('discountAmount' in addon && addon.discountAmount != null) ? addon.discountAmount / 100 : '',
            Brutto: ('grossAmount' in addon && addon.grossAmount != null) ? addon.grossAmount / 100 : '',
            Moms: ('vatAmount' in addon && addon.vatAmount != null) ? addon.vatAmount / 100 : '',
            Netto: ('netAmount' in addon && addon.netAmount != null) ? addon.netAmount / 100 : ''
          }))),
        ...(!('alternativeItems' in row) || row.alternativeItems == null
          ? []
          : row.alternativeItems.map(alternativeItem => ({
            Namn: ' \u2022 ' + (alternativeItem.name ?? ''),
            Antal: alternativeItem.count ?? 0,
            [splitProductsByPrice ? 'Pris' : 'Snittpris']: '',
            Rabatt: '',
            Brutto: '',
            Moms: '',
            Netto: ''
          })))
      ])
    )
  }

  const createRowPressHandler = (row: ProductTableRow): (() => void) | undefined => {
    const productId = ('productId' in row) ? row.productId : null
    if (productId == null) return

    switch (row.__typename) {
      case 'RestaurantStatisticsRefundsByProduct':
      case 'RestaurantStatisticsSalesByProduct':
        return () => onProductPress(productId)
      case 'RestaurantStatisticsRefundsByProductAddon':
      case 'RestaurantStatisticsSalesByProductAddon':
        return () => onAddonProductPress(productId)
    }
  }

  const createSortPressHandler = (field: ProductTableColumns, defaultSortOrder: SortOrder) => () => {
    if (field === sortCriteria.field) {
      // Toggle the sort order if the user clicked the same column
      setSortCriteria({ field, order: sortCriteria.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })
    } else {
      setSortCriteria({ field, order: defaultSortOrder })
    }
  }

  return (
    <>
      <HStack alignItems='baseline' justifyContent='space-between'>
        <Text size={18} weight='bold'>{title}</Text>

        {rows.length === 0
          ? null
          : <SaveAsButton onPress={handleCsvPress} title='Spara som CSV' />}
      </HStack>

      <Spacer height={8} />

      <Table>
        <Column align='start' flexGrow={mobileView ? 3 : 2} paddingHorizontal={4} />
        <Column align='end' paddingHorizontal={4} />
        <Column align='end' hidden={mobileView} paddingHorizontal={4} />
        <Column align='end' hidden={mobileView} paddingHorizontal={4} />
        <Column align='end' paddingHorizontal={4} />
        <Column align='end' hidden={mobileView} paddingHorizontal={4} />
        <Column align='end' hidden={mobileView} paddingHorizontal={4} />

        <Row>
          <Cell onPress={createSortPressHandler('name', SortOrder.ASC)}>
            <SortButton field='name' sortCriteria={sortCriteria} title='Namn' />
          </Cell>
          <Cell onPress={createSortPressHandler('count', SortOrder.DESC)}>
            <SortButton field='count' sortCriteria={sortCriteria} title='Antal' />
          </Cell>
          <Cell onPress={createSortPressHandler('price', SortOrder.DESC)}>
            <SortButton field='price' sortCriteria={sortCriteria} title={splitProductsByPrice ? 'Pris' : 'Snittpris'} />
          </Cell>
          <Cell onPress={createSortPressHandler('discountAmount', SortOrder.DESC)}>
            <SortButton field='discountAmount' sortCriteria={sortCriteria} title='Rabatt' />
          </Cell>
          <Cell onPress={createSortPressHandler('grossAmount', SortOrder.DESC)}>
            <SortButton field='grossAmount' sortCriteria={sortCriteria} title='Brutto' />
          </Cell>
          <Cell onPress={createSortPressHandler('vatAmount', SortOrder.DESC)}>
            <SortButton field='vatAmount' sortCriteria={sortCriteria} title='Moms' />
          </Cell>
          <Cell onPress={createSortPressHandler('netAmount', SortOrder.DESC)}>
            <SortButton field='netAmount' sortCriteria={sortCriteria} title='Netto' />
          </Cell>
        </Row>

        {rows.map((row) => (
          <RowGroup key={row.id}>
            <Row onPress={createRowPressHandler(row)} paddingVertical={2}>
              <Cell>
                <Text>{(row.__typename === 'RestaurantStatisticsRefundsByProduct' || row.__typename === 'RestaurantStatisticsSalesByProduct') ? '' : ' \u2022 '}{row.name}</Text>
              </Cell>
              <Cell>
                <Text>{row.count}</Text>
              </Cell>
              <Cell>
                <Text>{'price' in row ? formatCurrency(Math.round(row.price ?? 0)) : null}</Text>
              </Cell>
              <Cell>
                <Text>{'discountAmount' in row ? formatCurrency(row.discountAmount ?? 0) : null}</Text>
              </Cell>
              <Cell>
                <Text>{'grossAmount' in row ? formatCurrency(row.grossAmount ?? 0) : null}</Text>
              </Cell>
              <Cell>
                <Text>{'vatAmount' in row ? formatCurrency(row.vatAmount ?? 0) : null}</Text>
              </Cell>
              <Cell>
                <Text>{'netAmount' in row ? formatCurrency(row.netAmount ?? 0) : null}</Text>
              </Cell>
            </Row>

            {((('addons' in row) ? row.addons : null) ?? []).map(addon => (
              <Row key={addon.id} onPress={createRowPressHandler(addon)}>
                <Cell>
                  <Text>{' \u2022 '}{addon.name}</Text>
                </Cell>
                <Cell>
                  <Text>{addon.count}</Text>
                </Cell>
                <Cell>
                  <Text>{'price' in addon ? formatCurrency(Math.round(addon.price ?? 0)) : null}</Text>
                </Cell>
                <Cell>
                  <Text>{'discountAmount' in addon ? formatCurrency(addon.discountAmount ?? 0) : null}</Text>
                </Cell>
                <Cell>
                  <Text>{'grossAmount' in addon ? formatCurrency(addon.grossAmount ?? 0) : null}</Text>
                </Cell>
                <Cell>
                  <Text>{'vatAmount' in addon ? formatCurrency(addon.vatAmount ?? 0) : null}</Text>
                </Cell>
                <Cell>
                  <Text>{'netAmount' in addon ? formatCurrency(addon.netAmount ?? 0) : null}</Text>
                </Cell>
              </Row>
            ))}

            {((('alternativeItems' in row) ? row.alternativeItems : null) ?? []).map(item => (
              <Row key={item.name}>
                <Cell>
                  <Text>{' \u2022 '}{item.name}</Text>
                </Cell>
                <Cell>
                  <Text>{item.count}</Text>
                </Cell>
                <Cell />
                <Cell />
                <Cell />
                <Cell />
                <Cell />
              </Row>
            ))}
          </RowGroup>
        ))}

        <Divider />

        <Row backgroundColor='transparent' paddingVertical={2}>
          <Cell>
            <Text>Summa</Text>
          </Cell>
          <Cell>
            <Text>{summary.count}</Text>
          </Cell>
          <Cell />
          <Cell>
            <Text>{formatCurrency(summary.discountAmount)}</Text>
          </Cell>
          <Cell>
            <Text>{formatCurrency(summary.grossAmount)}</Text>
          </Cell>
          <Cell>
            <Text>{formatCurrency(summary.vatAmount)}</Text>
          </Cell>
          <Cell>
            <Text>{formatCurrency(summary.netAmount)}</Text>
          </Cell>
        </Row>
      </Table>
    </>
  )
}

interface ProductsListingProps {
  mobileView: boolean
  refunds: readonly RefundsByProduct[]
  sales: readonly SalesByProduct[]
}

const ProductsListing: React.FC<ProductsListingProps> = ({ mobileView, refunds, sales }) => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantStatisticsView'>()

  const [searchText, setSearchText] = useState('')
  const [showAlternativeItems, setShowAlternativeItems] = useState(false)
  const [splitProductsByPrice, setSplitProductsByPrice] = useState(false)
  const [sumAdaptions, setSumAddons] = useState(false)

  const handleShowAlternativesChange = useCallback(() => setShowAlternativeItems(value => !value), [setShowAlternativeItems])
  const handleSplitProductsByPrice = useCallback(() => setSplitProductsByPrice(value => !value), [setSplitProductsByPrice])
  const handleSumAddonsChange = useCallback(() => setSumAddons(value => !value), [setSumAddons])

  const handleOnAddonProductPress = useCallback((menuAddonProductId: string) => {
    navigation.navigate('MenuAddonProductEdit', { restaurantId, menuAddonProductId })
  }, [navigation, restaurantId])

  const handleOnProductPress = useCallback((menuProductId: string) => {
    navigation.navigate('MenuProductEdit', { restaurantId, menuProductId })
  }, [navigation, restaurantId])

  const filteredRefunds = useMemo(() => showAlternativeItems ? refunds : refunds.map(p => ({ ...p, alternativeItems: [] })), [refunds, showAlternativeItems])
  const filteredSales = useMemo(() => showAlternativeItems ? sales : sales.map(p => ({ ...p, alternativeItems: [] })), [sales, showAlternativeItems])

  const predicate = useMemo(() => {
    const sanitized = searchText.trim()
    const searchRegExArray = sanitized.length === 0 ? null : sanitized.split(/(\s+)/).map(pattern => new RegExp(pattern, 'i'))

    return (row: Omit<ProductRow, 'addons' | 'alternativeItems'> | AddonRow | AlternativeItemRow) => searchRegExArray?.every(regEx => regEx.test(row.name ?? '')) ?? true
  }, [searchText])

  return (
    <StatisticsContainer>
      <HStack alignItems='center' justifyContent='center' wrap>
        <VStack grow={1}>
          <TextField icon={<MaterialIcons color='#333' name='search' size={20} style={{ marginRight: 4 }} />} iconLeft id='product-filter' onChange={setSearchText} placeholder='Namn på produkt/alternativ' title='Sök' value={searchText} />
        </VStack>

        <HStack wrap>
          <CheckBox checked={splitProductsByPrice} onPress={handleSplitProductsByPrice} title={`Separera produkter där ${'\n'}prisförändring har skett`} />

          <CheckBox checked={sumAdaptions} onPress={handleSumAddonsChange} title='Visa alternativ på egen rad' />

          <CheckBox checked={showAlternativeItems} onPress={handleShowAlternativesChange} title='Visa alla alternativ' />
        </HStack>
      </HStack>

      <Spacer height={16} />

      <ProductsTable
        csvFileName='sales-by-product.csv'
        mobileView={mobileView}
        onAddonProductPress={handleOnAddonProductPress}
        onProductPress={handleOnProductPress}
        predicate={predicate}
        productRows={filteredSales}
        splitProductsByPrice={splitProductsByPrice}
        sumAdaptions={sumAdaptions}
        title='Sålda produkter'
      />

      <Spacer height={16} />

      <ProductsTable
        csvFileName='refunds-by-product.csv'
        mobileView={mobileView}
        onAddonProductPress={handleOnAddonProductPress}
        onProductPress={handleOnProductPress}
        predicate={predicate}
        productRows={filteredRefunds}
        splitProductsByPrice={splitProductsByPrice}
        sumAdaptions={sumAdaptions}
        title='Återbetalda produkter'
      />
    </StatisticsContainer>
  )
}

const RestaurantStatisticsView: React.FC = () => {
  const [, { restaurantId }] = useNavigation<'RestaurantStatisticsView'>()

  const { data: initialData, loading: initialLoading } = useGetRestaurantStatisticsViewDataQuery({ variables: { restaurantId } })

  const dataTimeZone = initialData?.restaurant?.timeZone ?? 'Europe/Stockholm'
  const deviceTimeZone = getDeviceTimeZone()

  const initialDateRange = dateRangeFromTimePeriod({ kind: 'Today' }, Temporal.Now.instant(), dataTimeZone)

  const [dateRange, setDateRange] = useState<PlainDateRange>(initialDateRange)
  const [error, setError] = useState<string | null>(null)
  const [filter, setFilter] = useState<RestaurantStatisticsFilter>({})
  const [lastUpdated, setLastUpdated] = useState<Temporal.Instant | null>(null)
  const [selectedDateRange, setSelectedDateRange] = useState<PlainDateRange>(initialDateRange)
  const [selectedFilter, setSelectedFilter] = useState<RestaurantStatisticsFilter>(filter)

  const dateTimeRangeLocal = useMemo(() => {
    return {
      start: dateRange.start.toZonedDateTime(dataTimeZone),
      end: dateRange.end.add({ days: 1 }).toZonedDateTime(dataTimeZone)
    }
  }, [dataTimeZone, dateRange])

  const variables = useMemo<GetRestaurantSalesQueryVariables>(() => {
    return {
      dateTimeRange: serializeZonedDateTimeRange(dateTimeRangeLocal),
      filter,
      resolution: resolutionForDailySales(dateTimeRangeLocal),
      restaurantId
    }
  }, [dateTimeRangeLocal, filter, restaurantId])

  const { data, loading, refetch } = useGetRestaurantSalesQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setLastUpdated(Temporal.Now.instant()),
    variables
  })

  const lastUpdatededLabel = useMemo(() => lastUpdated == null ? '' : getLastUpdatedLabel(lastUpdated, deviceTimeZone), [lastUpdated, deviceTimeZone])

  const mobileView = useWindowDimensions().width < MOBILE_VIEW_TRESHOLD_WIDTH

  const handleDateRangeChange = (value: PlainDateRange): void => {
    setError(null)
    setSelectedDateRange(value)
  }

  const handleRefetch = useCallback(() => {
    refetch(variables).catch(logError)
  }, [variables])

  const handleSubmit = useCallback(() => {
    setDateRange(selectedDateRange)
    setFilter(selectedFilter)
  }, [selectedDateRange, selectedFilter])

  return (
    <Layout screenType={ScreenType.Statistics} title='Statistik'>
      <StatisticsContainer>
        {error == null
          ? null
          : <Warning message={error} paddingBottom={8} />}

        <HStack alignItems='center' justifyContent='space-around' wrap>
          <VStack paddingBottom={8}>
            <HStack alignItems='baseline' wrap>
              <DateRangeSelect onDateRangeChange={handleDateRangeChange} timeZone={dataTimeZone} />
            </HStack>

            <SalesFilter
              filter={selectedFilter}
              onChange={setSelectedFilter}
              showDeliveryHero={initialData?.restaurant?.hasDeliveryHeroAccess ?? false}
            />
          </VStack>

          <VStack alignItems='center' alignSelf='start' grow={1}>
            {lastUpdated == null ? null : (
              <>
                <Text>Senast uppdaterad</Text>
                <Spacer height={4} />
                <Text>{lastUpdatededLabel}</Text>
                <Spacer height={8} />
                <UpdateButton disabled={loading} onPress={handleRefetch} title='Uppdatera' />
              </>
            )}
          </VStack>
        </HStack>

        <Spacer height={16} />

        <PrimaryButton
          disabled={selectedDateRange === dateRange && selectedFilter === filter}
          onPress={handleSubmit}
          title='Sök'
        />
      </StatisticsContainer>

      {(loading || initialLoading) ? (
        <StatisticsContainer>
          <ActivityIndicator size='large' />
        </StatisticsContainer>
      ) : (
        <>
          <StatisticsContainer>
            {(data?.restaurant?.statistics?.salesByMenuType?.length === 0) ? (
              <HStack padding={16}>
                <Text>Ingen försäljning hittades för denna tidsperiod</Text>
              </HStack>
            ) : (
              <>
                <VStack alignItems='center'>
                  <SalesByMenuTypeView menuTypeData={data?.restaurant?.statistics?.salesByMenuType} />
                </VStack>

                <DailySalesPlot data={data?.restaurant?.statistics?.salesByMenuTypeAndTimePeriod} dateTimeRange={dateTimeRangeLocal} resolution={variables.resolution} />
              </>
            )}
          </StatisticsContainer>

          <ReportGroupListing
            mobileView={mobileView}
            reportGroupRows={data?.restaurant?.statistics?.salesByReportGroup ?? []}
          />

          <ProductsListing
            mobileView={mobileView}
            refunds={data?.restaurant?.statistics?.refundsByProduct ?? []}
            sales={data?.restaurant?.statistics?.salesByProduct ?? []}
          />

          <StatisticsContainer>
            <DataTable csvFileName='sales-payment-name.csv' data={data?.restaurant?.statistics?.salesByPaymentName ?? []} initialSort='paymentName' title='Betalningsmetoder för försäljning'>
              <DataColumn<SalesByPaymentName> field='paymentName' title='Betalningsmetod' type='string' />
              <DataColumn<SalesByPaymentName> field='count' title='Antal' type='number' />
              <DataColumn<SalesByPaymentName> field='tipAmount' hidden={mobileView} minimumFractionDigits={2} title='Dricks' type='currency' />
              <DataColumn<SalesByPaymentName> field='grossAmount' minimumFractionDigits={2} title='Brutto' type='currency' />
            </DataTable>

            <Spacer height={16} />

            <DataTable csvFileName='refunds-payment-name.csv' data={data?.restaurant?.statistics?.refundsByPaymentName ?? []} initialSort='paymentName' title='Betalningsmetoder för återbetalningar'>
              <DataColumn<RefundsByPaymentName> field='paymentName' title='Betalningsmetod' type='string' />
              <DataColumn<RefundsByPaymentName> field='count' title='Antal' type='number' />
              <DataColumn<RefundsByPaymentName> field='tipAmount' hidden={mobileView} minimumFractionDigits={2} title='Dricks' type='currency' />
              <DataColumn<RefundsByPaymentName> field='grossAmount' minimumFractionDigits={2} title='Brutto' type='currency' />
            </DataTable>
          </StatisticsContainer>
        </>
      )}

      {variables.filter == null ? null : (
        <StatisticsContainer>
          <HStack justifyContent='center'>
            <DownloadSalesForm
              dateTimeRange={dateTimeRangeLocal}
              menuTypes={filter.menuTypes}
              restaurantId={restaurantId}
            />
          </HStack>
        </StatisticsContainer>
      )}
    </Layout>
  )
}

export default RestaurantStatisticsView

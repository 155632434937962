import React from 'react'
import { useForm } from 'react-hook-form'

import { type FullMenuCourseFragment, type MenuCourseInput } from '../../types/graphql'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'

interface MenuCourseFormProps {
  deleting?: boolean
  initialValues?: FullMenuCourseFragment | null
  onCancel: () => void
  onDelete?: () => void
  onSubmit: (input: MenuCourseInput) => void
  saving: boolean
}

const schema: ObjectSchema<MenuCourseInput> = yup.object().shape({
  name: yup.string().required('Name is required')
})

const MenuCourseForm: React.FC<MenuCourseFormProps> = ({ deleting, initialValues, onCancel, onDelete, onSubmit, saving }) => {
  const form = useForm<MenuCourseInput>({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    values: initialValues == null ? undefined : schema.cast(initialValues, { stripUnknown: true })
  })

  return (
    <FormContainer gap={16}>
      <TextField
        autoFocus
        form={form}
        name='name'
        title='Namn'
      />

      <SubmitFormButtons
        deleting={deleting}
        disableSaveButton={false}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSubmit))}
        saving={saving}
      />
    </FormContainer>
  )
}

export default MenuCourseForm

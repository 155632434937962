import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Text } from 'react-stacked'

import { type OpeningHoursPeriodInput, useGetRestaurantOpeningHoursQuery, useSetRestaurantOpeningHoursMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import OpeningHoursForm from '../components/OpeningHoursForm'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { openingHoursPeriodsSchema } from '../components/fields/OpeningHoursPeriodsField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  mutation SetRestaurantOpeningHours($restaurantId: ID!, $periods: [OpeningHoursPeriodInput!]!) {
    setRestaurantOpeningHours(
      restaurantId: $restaurantId
      periods: $periods
    ) {
      id

      periods {
        open {
          day
          time
        }
        close {
          day
          time
        }
      }
    }
  }
`

interface FormData {
  periods: OpeningHoursPeriodInput[]
}

const schema: ObjectSchema<FormData> = yup.object({
  periods: openingHoursPeriodsSchema.required('Var vänlig och lägg till tider').typeError('Var vänlig och lägg till tider')
})

const RestaurantOpeningHoursView: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantOpeningHoursView'>()

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['OpeningHoursList', { restaurantId }], title: 'Öppettider' }
  ], [restaurantId])

  const { data, loading } = useGetRestaurantOpeningHoursQuery({ variables: { restaurantId } })
  const [setRestaurantOpeningHours, { loading: loadingSave }] = useSetRestaurantOpeningHoursMutation({
    onCompleted: () => navigation.navigate('OpeningHoursList', { restaurantId }),
    onError: (error) => logAndShowError(error)
  })

  const form = useForm<FormData>({
    resetOptions: { keepDirtyValues: true, keepErrors: true },
    resolver: yupResolver(schema),
    values: data?.restaurant?.openingHours == null ? undefined : schema.cast(data?.restaurant?.openingHours, { stripUnknown: true })
  })

  const handleSave = ignoreAsync(async (input: FormData) => {
    await setRestaurantOpeningHours({ variables: { periods: input.periods, restaurantId } })
  })

  return (
    <Layout breadcrumbs={breadcrumbs} hideTitle loading={loading} screenType={ScreenType.Form} title='Restaurangöppettider'>
      <FormContainer gap={24} title='Restaurangöppettider' wide>
        <Text color='grey'>Markera vilka dagar och tider din verksamhet har öppet och tryck på spara</Text>

        <OpeningHoursForm
          form={form}
        />

        <SubmitFormButtons
          disableSaveButton={form.formState.isSubmitting}
          onCancel={() => navigation.navigate('OpeningHoursList', { restaurantId })}
          onSave={ignoreAsync(form.handleSubmit(handleSave))}
          saving={loadingSave}
        />
      </FormContainer>
    </Layout>
  )
}

export default RestaurantOpeningHoursView

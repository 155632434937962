import { type ApolloError } from '@apollo/client'
import luhn from 'fast-luhn'
import gql from 'graphql-tag'
import parsePhoneNumber from 'libphonenumber-js'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image } from 'react-native'
import { HStack, Text, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type CreateUserMutationVariables, GetCreateUserDataDocument, type GetCreateUserDataQuery, useCreateAccessTokenMutation, useCreateOrganizationMutation, useCreateUserMutation } from '../../types/graphql'
import client from '../client/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import FormContainer from '../components/FormContainer'
import OrganizationForm from '../components/OrganizationForm'
import { TextField } from '../components/TextField'
import Warning from '../components/Warning'
import { clearAccessToken, setAccessToken } from '../lib/token-storage'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import { flag } from '../util/flag'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

gql`
  query GetCreateUserData {
    me {
      id

      username
      isSuperUser

      organizationsWithAccess(access:Read) {
        id

        restaurants {
          id

          name
        }
      }

      restaurantsWithAccess(access:Write) {
        id

        name
      }
    }
  }

  mutation CreateUser($email: EmailAddress!, $name: String!, $password: String!, $phoneNumber: E164!) {
    createUser(
      input: { email: $email, name: $name, password: $password, phoneNumber: $phoneNumber}
    ) {
      id

      username
      isSuperUser

      name
      phone
      city
    }
  }
`

interface UserCreateSchema extends CreateUserMutationVariables {
  organizationName: string
  organizationNumber: string
  passwordConfirmation?: string
}

const luhnValidation = (value: string): boolean => {
  return luhn(value.replace(/-/g, ''))
}

const userSchema: ObjectSchema<UserCreateSchema> = yup.object({
  email: yup.string().email('E-postadressen du angivit är inte giltig').required('Var vänlig ange din e-postadress'),
  name: yup.string().trim().min(1, 'Organisationsnamn med minst ett tecken krävs').max(50, 'Organisationsnamn med max 50 tecken tillåts').required(),
  organizationName: yup.string().trim().min(1, 'Organisationsnamn med minst ett tecken krävs').max(50, 'Organisationsnamn med max 50 tecken tillåts').required(),
  organizationNumber: yup.string().trim()
    .required('Organisationsnummer måste anges')
    .matches(/((^\d{6}-{1}\d{4}$)|(^\d{10}$))/, 'Ett giltigt organisationsnummer skrivs med 10 siffror på formen: 551234-5678 eller 5512345678')
    .test(
      'luhn-check',
      'Ange ett giltigt organisationsnummer',
      luhnValidation
    ),
  password: yup.string().trim().min(6, 'Lösenordet måste innehålla minst 6 tecken').required('Lösenord krävs'),
  passwordConfirmation: yup.string().trim().equals([yup.ref('password')], 'Lösenorden måste matcha'),
  phoneNumber: yup.string().required('Telefonnummer måste anges').test('phoneNumberIsValid', 'Telefonnumret är inte giltigt', function (phoneNumber) {
    if (typeof phoneNumber !== 'string') return false
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber ?? '', 'SE')
    return parsedPhoneNumber?.isValid() ?? false
  })
})

const UserCreate: React.FC = () => {
  const [navigation] = useNavigation<'UserCreate'>()

  const form = useForm<UserCreateSchema>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    resolver: yupResolver(userSchema)
  })

  const [createAccessToken, { error: errorCreateAccessToken }] = useCreateAccessTokenMutation()
  const [createOrganization, { error: errorCreateOrganization }] = useCreateOrganizationMutation({
    awaitRefetchQueries: true,
    onCompleted: (response) => {
      if (response.createOrganization == null) {
        logError(new Error("crateOrganization didn't return an Organization"))
      } else {
        navigation.navigate('RestaurantCreate', { organizationId: response.createOrganization.id })
      }
    },
    refetchQueries: [{ query: GetCreateUserDataDocument }]
  })
  const [createUser, { error: errorCreateUser }] = useCreateUserMutation()

  const phoneNumber: string = form.watch('phoneNumber') ?? ''
  const parsedPhoneNumber = useMemo(() => parsePhoneNumber(phoneNumber, 'SE'), [phoneNumber])
  const phoneNumberIsValid = useMemo(() => parsedPhoneNumber?.isValid() ?? false, [parsedPhoneNumber])
  const [loading, setLoading] = useState(false)

  const handleSave = ({ email, name, organizationName, organizationNumber, password, phoneNumber }: UserCreateSchema): void => {
    async function createAndLoginUser (): Promise<void> {
      setLoading(true)
      await clearAccessToken()

      try {
        await createUser({ variables: { email: email.toLowerCase(), name, password, phoneNumber: unwrap(parsePhoneNumber(phoneNumber, 'SE')?.number) } })
      } catch (error) {
        logError(error)

        if ((error as ApolloError).graphQLErrors.some(graphQlError => graphQlError?.extensions?.code !== 'USER_CREATION_EMAIL_TAKEN')) {
          setLoading(false)
          return
        }
      }

      const { data: createAccessTokenData } = await createAccessToken({ variables: { password, username: email.toLowerCase() } })

      await setAccessToken(unwrap(createAccessTokenData?.createAccessToken?.parseToken))

      const { data } = await client.query<GetCreateUserDataQuery>({ query: GetCreateUserDataDocument, errorPolicy: 'all', fetchPolicy: 'network-only' })

      let organizationId = data?.me?.organizationsWithAccess?.[0]?.id
      if (organizationId == null) {
        organizationId = (await createOrganization({ variables: { input: { email, name: organizationName, organizationNumber } } })).data?.createOrganization?.id
      }

      navigation.reset({ index: 0, routes: [{ name: 'RestaurantCreate', params: { organizationId } }] })
      setLoading(false)
    }

    createAndLoginUser().catch((e) => {
      setLoading(false)
      logError(e)
    })
  }

  return (
    <VStack backgroundColor='black' grow={1}>
      <Image source={require('../../assets/icon.png')} style={{ height: normalize(75, 90), width: normalize(75, 90) }} />

      <HStack alignItems='center' justifyContent='center'>
        <FormContainer gap={20}>
          <HStack paddingBottom={8}>
            <SecondaryButton
              icon='navigate-before'
              onPress={() => navigation.navigate('Login', {})}
              title='Tillbaka'
            />
          </HStack>

          <Text size={24}>Registrera användare</Text>

          {errorCreateUser == null || (errorCreateAccessToken == null && errorCreateUser.graphQLErrors.some(graphQlError => graphQlError.extensions?.code === 'USER_CREATION_EMAIL_TAKEN'))
            ? null
            : <Warning message={errorCreateUser.message} paddingBottom={8} />}

          {errorCreateAccessToken == null
            ? null
            : <Warning message={errorCreateAccessToken.message} paddingBottom={8} />}

          {errorCreateOrganization == null
            ? null
            : <Warning message={errorCreateOrganization.message} paddingBottom={8} />}

          <OrganizationForm form={form} />

          <TextField
            form={form}
            name='password'
            secureTextEntry
            title='Välj lösenord'
          />

          <TextField
            form={form}
            name='passwordConfirmation'
            secureTextEntry
            title='Bekräfta lösenord'
          />

          <TextField
            form={form}
            name='name'
            title='Ditt namn'
          />

          <HStack alignItems='end' gap={16} wrap>
            <TextField
              estimatedNumberOfCharacters={12}
              form={form}
              hint='Ange ditt telefonnummer'
              name='phoneNumber'
              title='Telefon'
            />

            {!phoneNumberIsValid ? null : (
              <Text height={28}>
                {`${flag(parsedPhoneNumber?.country) ?? ''} ${parsedPhoneNumber?.formatNational() ?? ''}`}
              </Text>
            )}
          </HStack>

          <PrimaryButton
            icon='account-circle'
            loading={loading}
            onPress={ignoreAsync(form.handleSubmit(handleSave))}
            title='Registrera användare'
          />
        </FormContainer>
      </HStack>
    </VStack>
  )
}

export default UserCreate

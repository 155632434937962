import gql from 'graphql-tag'
import React from 'react'
import { useForm } from 'react-hook-form'

import { type DeliveryLocationInput, DeliveryLocationType, ListDeliveryLocationsDocument, useCreateDeliveryLocationMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import Warning from '../components/Warning'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateDeliveryLocation($restaurantId: ID!, $input: DeliveryLocationInput!) {
    createDeliveryLocation(restaurantId: $restaurantId, input: $input) {
      ...DeliveryLocationFields
    }
  }
`

const schema: ObjectSchema<DeliveryLocationInput> = yup.object({
  disabled: yup.boolean().required(),
  name: yup.string().trim().min(1, 'Namn med minst ett tecken krävs').required(),
  type: yup.mixed<DeliveryLocationType>().oneOf([DeliveryLocationType.CashDesk, DeliveryLocationType.Table]).required()
})

const DeliveryLocationCreate: React.FC = () => {
  const [navigation, { restaurantId, type }] = useNavigation<'DeliveryLocationCreate'>()

  const navigateToList = (): void => {
    navigation.navigate('DeliveryLocationList', { restaurantId })
  }

  const [createDeliveryLocation, { error, loading: isSubmitting }] = useCreateDeliveryLocationMutation({ awaitRefetchQueries: true, onCompleted: navigateToList })

  const form = useForm<DeliveryLocationInput>({
    criteriaMode: 'all',
    defaultValues: {
      disabled: false,
      type
    },
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const handleSave = (input: DeliveryLocationInput): void => {
    createDeliveryLocation({
      refetchQueries: [{ query: ListDeliveryLocationsDocument, variables: { restaurantId, type } }],
      variables: { input, restaurantId }
    }).catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[{ link: ['DeliveryLocationList', { restaurantId }], title: 'Bord / Kassa' }]}
      hideTitle
      loading={isSubmitting}
      screenType={ScreenType.Form}
      title={`Skapa ${type === DeliveryLocationType.Table ? 'bord' : 'kassa'}`}
    >
      <FormContainer gap={20} title={`Skapa ${type === DeliveryLocationType.Table ? 'bord' : 'kassa'}`}>
        <TextField
          autoFocus
          estimatedNumberOfCharacters={10}
          form={form}
          largeTitle
          name='name'
          title={`Vad vill du att namnet på ${type === DeliveryLocationType.Table ? 'bordet' : 'kassan'} ska vara?`}
        />

        <Warning message={error?.message} />

        <SubmitFormButtons
          disableSaveButton={form.formState.isSubmitting}
          onCancel={navigateToList}
          onSave={ignoreAsync(form.handleSubmit(handleSave))}
          saving={isSubmitting}
        />
      </FormContainer>
    </Layout>
  )
}

export default DeliveryLocationCreate

import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { Text, VStack } from 'react-stacked'

import { useLinkFortnoxAccountingImportAccountMutation } from '../../types/graphql'
import Layout from '../components/Layout'
import Warning from '../components/Warning'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation LinkFortnoxAccountingImportAccount($organizationId: ID!, $credentials: FortnoxAccountingImportCredentials!) {
    linkFortnoxAccountingImportAccount(organizationId: $organizationId, credentials: $credentials) {
      id

      hasFortnoxAccountingImportCredentials
    }
  }
`

const FortnoxAccountingImportComplete: React.FC = () => {
  const [navigation, { code: authorizationCode, error, error_description: errorDescription, state: organizationId }] = useNavigation<'FortnoxAccountingImportComplete'>()

  const redirectUrl = window.location.protocol + '//' + window.location.host + '/hooks/v1/fortnox-accounting-import-complete'

  const [linkAccount, { error: linkAccountError, loading: linkingAccount }] = useLinkFortnoxAccountingImportAccountMutation({
    onCompleted: () => {
      if (organizationId == null) return

      navigation.replace('OrganizationFortnoxAccountingImportView', { organizationId })
    }
  })

  useEffect(() => {
    if (authorizationCode == null || organizationId == null) return

    linkAccount({ variables: { organizationId, credentials: { authorizationCode, redirectUrl } } }).catch(logError)
  }, [authorizationCode, redirectUrl, organizationId])

  return (
    <Layout title='Fortnox Bokföring Integration'>
      <VStack alignItems='center' justifyContent='center' maxWidth={700} padding={20}>
        {linkAccountError?.message == null ? null : <Warning message={linkAccountError.message} paddingBottom={12} />}
        {error == null ? null : <Warning message={error} paddingBottom={12} />}
        {errorDescription == null ? null : <Warning message={errorDescription} paddingBottom={12} />}

        {!linkingAccount
          ? <Text>Ingen pågående registreringsprocess för Fortnox Bokföring hittades</Text>
          : (
            <>
              <Text>Vänligen avvakta några sekunder medan vi länkar ditt Fortnox med Loco</Text>

              <Spacer height={8} />

              <ActivityIndicator />
            </>
          )}
      </VStack>
    </Layout>
  )
}

export default FortnoxAccountingImportComplete

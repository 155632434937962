import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { VStack } from 'react-stacked'
import unreachable from 'ts-unreachable'
import unwrap from 'ts-unwrap'

import yup, { type ObjectSchema, yupResolver } from '../../lib/validation'
import formatCurrency from '../../util/formatCurrency'
import ignoreAsync from '../../util/ignoreAsync'
import { parsePrice } from '../../util/price'
import { type ChangeFn } from '../../util/useMultiEditProducts'
import { MoneyField, RadioButton } from '../fields'

import { Content } from './MultiEditForm'

function calculatePrice (currentPrice: number, kind: 'decrease' | 'increase' | 'replace', amount: number): number {
  function endPrice (): number {
    switch (kind) {
      case 'decrease':
        return currentPrice - amount
      case 'increase':
        return currentPrice + amount
      case 'replace':
        return amount
      default:
        unreachable(kind)
    }
  }

  return Math.max(endPrice(), 300)
}
interface EditPriceProps {
  onDismiss: () => void
  onNext: (changeFn: ChangeFn) => void
}

interface Form {
  amount: string
}

const schema: ObjectSchema<Form> = yup.object({
  amount: yup.string().trim().matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').required()
})

const EditPrice: React.FC<EditPriceProps> = ({ onDismiss, onNext }) => {
  const form = useForm<Form>({
    criteriaMode: 'all',
    mode: 'onTouched',
    resolver: yupResolver(schema)
  })

  const [kind, setKind] = useState<'decrease' | 'increase' | 'replace' | null>(null)

  const handleOnNext = ignoreAsync(form.handleSubmit(useCallback(({ amount }) =>
    onNext(properties => {
      const change = parsePrice(amount)
      const price = calculatePrice(unwrap(properties?.price), unwrap(kind), unwrap(change))

      return {
        from: formatCurrency(unwrap(properties?.price)),
        to: formatCurrency(price),
        patch: { price }
      }
    }), [kind, onNext])))

  return (
    <Content
      disableNext={kind == null || !form.formState.isDirty}
      onDismiss={onDismiss}
      onNext={handleOnNext}
    >
      <VStack gap={8} padding={8}>
        <VStack gap={12} paddingVertical={12}>
          <RadioButton checked={kind === 'increase'} onPress={() => setKind('increase')} title='Öka med' />
          <RadioButton checked={kind === 'decrease'} onPress={() => setKind('decrease')} title='Minska med' />
          <RadioButton checked={kind === 'replace'} onPress={() => setKind('replace')} title='Ersätt med' />
        </VStack>

        {kind == null ? null : (
          <MoneyField
            autoFocus
            form={form}
            name='amount'
            title='Belopp'
          />
        )}
      </VStack>
    </Content>
  )
}

export default EditPrice

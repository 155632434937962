import React, { useCallback, useMemo, useState } from 'react'
import { HStack } from 'react-stacked'

import { GetPageMenuDocument, type MenuProductFieldsFragment, type MenuType, useUpdateProductIsActiveMutation } from '../../../types/graphql'
import logError from '../../util/logError'
import * as MenuTypes from '../../util/menuTypes'
import useNavigation from '../../util/useNavigation'
import { PanicButton } from '../PanicButton'
import VerificationDialog from '../VerificationDialog'

import { titleFromMenuType } from './MenuTypeToggleButton'

interface ProductVariantToggleProps {
  menuType: MenuType
  product: MenuProductFieldsFragment
}

const ProductVariantToggle: React.FC<ProductVariantToggleProps> = ({ menuType, product }) => {
  const [, { menuPageId, restaurantId }] = useNavigation<'MenuPageView'>()

  const [updateIsActive, { loading: isSubmitting }] = useUpdateProductIsActiveMutation({ awaitRefetchQueries: true })

  const [showTemporarilyDisabledConfirmation, setShowTemporarilyDisabledConfirmation] = useState(false)

  const isDisabled = useMemo(() => product[MenuTypes.productFieldName(menuType)]?.isActive !== true, [menuType, product])

  const handleActivation = useCallback(() => {
    const update = async (): Promise<void> => {
      await updateIsActive({
        refetchQueries: [{ query: GetPageMenuDocument, variables: { restaurantId, menuPageId } }],
        variables: {
          menuProductId: product.id,
          patch: {
            [MenuTypes.productFieldName(menuType)]: { isActive: isDisabled }
          },
          restaurantId
        }
      })
    }

    update().catch(logError)
  }, [isDisabled, menuPageId, menuType, product.id, restaurantId, updateIsActive])

  return (
    <HStack alignItems='center' paddingBottom={2} paddingRight={4}>
      <PanicButton
        isDisabled={isDisabled}
        isLoading={isSubmitting}
        onActivation={() => {
          if (isDisabled) {
            handleActivation()
          } else {
            setShowTemporarilyDisabledConfirmation(true)
          }
        }}
        size={12}
      />
      {!showTemporarilyDisabledConfirmation ? null : (
        <VerificationDialog
          callToActionLabel='Bekräfta'
          errorMessage={undefined}
          loading={isSubmitting}
          onDismiss={() => {
            setShowTemporarilyDisabledConfirmation(false)
          }}
          onSubmit={() => {
            setShowTemporarilyDisabledConfirmation(false)
            handleActivation()
          }}
          open
          prompt={`Är du säker på att du vill inaktivera ${product.name ?? 'produkten'}? Om du avaktiverar det kommer dina gäster inte att kunna beställa ${product.name ?? 'produkten'} från ${titleFromMenuType(menuType)} i vår beställningsapp.`}
          title={`Inaktivera ${product.name ?? 'produkten'} för ${titleFromMenuType(menuType)}`}
        />
      )}
    </HStack>
  )
}

export default ProductVariantToggle

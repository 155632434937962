import { type OpeningHoursPeriodInput } from '../../types/graphql'

import { WEEK_DAYS } from './getBunchedPeriodsShortform'
import getDaysBetween from './getDaysBetween'
import getTimeBetween from './getTimeBetween'

const DAY_IN_MS = 24 * 60 * 60 * 1000

export default function extractPeriods (openingPeriods: readonly OpeningHoursPeriodInput[] | null | undefined): OpeningHoursPeriodInput[] {
  const openingHoursPeriodInput: OpeningHoursPeriodInput[] = []

  if (openingPeriods == null || openingPeriods.length === 0) return openingHoursPeriodInput

  // Handle 24/7
  if (openingPeriods?.length === 1) {
    if (openingPeriods[0].close == null) {
      return WEEK_DAYS.map(day => ({
        open: {
          day,
          time: '00:00:00'
        },
        close: {
          day,
          time: '24:00:00'
        }
      }))
    }
  }

  for (const period of openingPeriods) {
    if (period.close == null) {
      throw new Error('Incorrectly formatted opening hours: null close is not allowed when many periods are defined')
    }

    // Close more or equal to 24h after
    if (getTimeBetween(period.open, period.close, 0) >= DAY_IN_MS) {
      const daysOpen = getDaysBetween(period.open.day, period.close.day, [])

      for (const day of daysOpen) {
        if (day === period.open.day) {
          openingHoursPeriodInput.push({
            open: {
              day,
              time: period.open.time
            },
            close: {
              day,
              time: '24:00:00'
            }
          })

          continue
        }

        if (day === period.close.day) {
          openingHoursPeriodInput.push({
            open: {
              day,
              time: '00:00:00'
            },
            close: {
              day,
              time: period.close.time
            }
          })

          continue
        }

        openingHoursPeriodInput.push({
          open: {
            day,
            time: '00:00:00'
          },
          close: {
            day,
            time: '24:00:00'
          }
        })
      }

      continue
    }

    openingHoursPeriodInput.push({
      open: {
        day: period.open.day,
        time: period.open.time
      },
      close: {
        day: period.close.day,
        time: period.close.time
      }
    })
  }

  return openingHoursPeriodInput
}

import gql from 'graphql-tag'
import React from 'react'
import { Image } from 'react-native'
import { HStack, Text, VStack } from 'react-stacked'

import { useGetWhiteLabelQuery } from '../../types/graphql'
import Layout from '../components/Layout'
import Restaurants from '../components/WhiteLabel/Restaurants'
import WhiteLabelForm from '../components/WhiteLabel/WhiteLabelForm'
import useNavigation from '../util/useNavigation'

gql`
  fragment WhiteLabelFields on WhiteLabel {
    id
    label
    createdAt
    updatedAt
    restaurants(filter: { active: All }, first: 999) {
      edges {
        node {
          id
          name
          city
          hasEatInMenu
          hasDeliveryMenu
          hasTakeAwayMenu
          design {
            restaurantPreviewImage
          }
        }
      }
    }
    androidFeatureGraphicUrl
    androidGoogleServices
    androidPackageName
    appIconUrl
    appName
    appSplashUrl
    buildNumber
    deliveryEnabled
    description
    easProjectId
    googleMapsApiKey
    iosAppstoreId
    iosBundleIdentifier
    iosGoogleServiceInfoPlist
    isPublishing
    isReadyForPublishing
    iPadPro129Screenshots
    iPhone55Screenshots
    iPhone65Screenshots
    keywords
    scheme
    shortDescription
    shortUrlPrefix

    theme {
      backgroundColor
      borderColor
      cardColor
      notificationColor
      primaryColor
      textColor

      menuTypeSelectionTitleColor
      menuTypeSelectionButtonBackgroundColor
      menuTypeSelectionButtonBorderColor
      menuTypeSelectionButtonTextColor

      menuTypeSelectionBackgroundColor
      menuTypeSelectionBackgroundImage

      drawerBackgroundColor
      drawerListItemTitleColor
      drawerListItemSubtitleColor
      drawerListItemIconColor
      drawerVersionTextColor
      drawerLogoImage

      listMenuIconColor
      listTextInputColor
      listTextInputPlaceholderTextColor
      listSearchIconColor
      listClearIconColor
      listEmptyListButtonBackgroundColor
      listItemSeparatorColor
      listTabBarActiveTextColor
      listTabBarInactiveTextColor
      listTabBarUnderlineColor
      listItemBackgroundColor
      listItemTextColor

      listBackgroundColor
      listBackgroundImage
    }
  }

  query GetWhiteLabel($whiteLabelId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      ...WhiteLabelFields
    }
  }
`

const WhiteLabelView: React.FC = () => {
  const [, { whiteLabelId }] = useNavigation<'WhiteLabelView'>()

  const { data, loading } = useGetWhiteLabelQuery({ variables: { whiteLabelId } })

  const whiteLabel = data?.whiteLabel

  return (
    <Layout
      breadcrumbs={[{ link: ['WhiteLabelList', {}], title: 'Applikationer' }]}
      loading={loading}
      title={whiteLabel?.appName}
    >
      <VStack maxWidth={1366}>
        <HStack gap={16} padding={16}>
          {whiteLabel?.appIconUrl == null ? null : <Image source={{ uri: whiteLabel?.appIconUrl }} style={{ width: 40, height: 40 }} />}

          <Text size={32}>{whiteLabel?.appName}</Text>
        </HStack>

        {whiteLabel == null ? null : (
          <>
            <Restaurants whiteLabel={whiteLabel} />
            <WhiteLabelForm whiteLabel={whiteLabel} />
          </>
        )}
      </VStack>
    </Layout>
  )
}

export default WhiteLabelView

import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'

import { GetMenuPagesDocument, MenuActiveFilter, MenuType, useDeleteMenuPageMutation, useGetEditPageDataQuery, useUpdateMenuPageMutation } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import MenuPageForm, { type MenuPageSchema } from '../components/MenuPageForm'
import VerificationDialog from '../components/VerificationDialog'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

gql`
  query GetEditPageData($restaurantId: ID!, $menuPageId: ID!) {
    restaurant(id: $restaurantId) {
      id

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        page(id: $menuPageId) {
          id
          name
          categories {
            id
          }
        }
      }
    }
  }

  mutation UpdateMenuPage($restaurantId: ID!, $menuPageId: ID!, $patch: MenuPagePatch!) {
    updateMenuPage(
      restaurantId: $restaurantId
      menuPageId: $menuPageId
      patch: $patch
    ) {
      id
    }
  }

  mutation DeleteMenuPage($restaurantId: ID!, $menuPageId: ID!) {
    deleteMenuPage(
      restaurantId: $restaurantId
      menuPageId: $menuPageId
    )
  }
`

const MenuPageEdit: React.FC = () => {
  const [navigation, { restaurantId, menuPageId }] = useNavigation<'MenuPageEdit'>()
  const { data, loading } = useGetEditPageDataQuery({ variables: { restaurantId, menuPageId } })
  const [deleteMenuPage, { loading: loadingDeleteMenuPage, error: deleteError }] = useDeleteMenuPageMutation()
  const [updateMenuPage, { loading: loadingUpdateMenuPage }] = useUpdateMenuPageMutation()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const menuPageName = data?.restaurant?.menu?.page?.name

  const defaultValues: MenuPageSchema = {
    name: menuPageName ?? ''
  }

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['MenuView', { restaurantId }], title: 'Meny' },
    { link: ['MenuPageView', { restaurantId, menuPageId }], title: menuPageName ?? '' }
  ], [menuPageName, menuPageId, restaurantId])

  const handleCancel = (): void => {
    navigation.navigate('MenuView', { restaurantId })
  }

  const handleSave = ignoreAsync(async (patch: MenuPageSchema) => {
    await updateMenuPage({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetMenuPagesDocument, variables: { restaurantId, filter: { active: MenuActiveFilter.All, includeProductsWithOpenPrice: true, types: [MenuType.Delivery, MenuType.EatIn, MenuType.TakeAway] } } }],
      variables: { restaurantId, menuPageId, patch }
    })

    navigation.navigate('MenuView', { restaurantId })
  })

  const doDeleteMenuPage = ignoreAsync(async () => {
    setShowDeleteDialog(false)

    await deleteMenuPage({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetMenuPagesDocument, variables: { restaurantId, filter: { active: MenuActiveFilter.All, includeProductsWithOpenPrice: true, types: [MenuType.Delivery, MenuType.EatIn, MenuType.TakeAway] } } }],
      variables: { restaurantId, menuPageId }
    })

    navigation.navigate('MenuView', { restaurantId })
  })

  if (loading) {
    return <Layout breadcrumbs={breadcrumbs} loading title='Redigera ...' />
  }

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title={`Redigera ${menuPageName ?? '...'}`}>
      <MenuPageForm
        defaultValues={defaultValues}
        disableDeleteButton={(data?.restaurant?.menu?.page?.categories?.length ?? 0) > 0}
        onCancel={handleCancel}
        onDelete={() => setShowDeleteDialog(true)}
        onSave={handleSave}
        saving={loadingUpdateMenuPage}
      />

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={loadingDeleteMenuPage}
        onDelete={doDeleteMenuPage}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={`Vill du radera huvudkategorin ${menuPageName ?? '...'}?`}
        title='Radera huvudkategori'
      />
    </Layout>
  )
}

export default MenuPageEdit

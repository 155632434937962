import { type ApolloError } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, FlatList, View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type FullPosTerminalFragment, type FullTillFragment, type NetsConnectCloudCredentials, PaymentProvider, useGetTillsQuery, useLinkNetsConnectCloudAccountMutation, useRefreshPosTerminalsMutation } from '../../types/graphql'
import { AddButton, CancelButton } from '../components/Buttons'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import RestaurantPosTerminalListItem from '../components/RestaurantPosTerminalListItem'
import RestaurantTillListItem from '../components/RestaurantTillListItem'
import Warning from '../components/Warning'
import { PasswordField, TextField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import extractSupportedTillPaymentMethods from '../util/extractSupportedTillPaymentMethods'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import paymentProviderLabel from '../util/paymentProviderLabel'
import useNavigation from '../util/useNavigation'

gql`
  mutation LinkNetsConnectCloudAccount($restaurantId: ID!, $credentials: NetsConnectCloudCredentials!) {
    linkNetsConnectCloudAccount(
      restaurantId: $restaurantId,
      credentials: $credentials,
    ) {
      id

      hasNetsConnectCloudLink
      netsConnectCloudUsername

      posTerminals {
        ...FullPosTerminal
      }
    }
  }

  mutation RefreshPosTerminals($restaurantId: ID!) {
    refreshPosTerminals(restaurantId: $restaurantId) {
      id

      hasNetsConnectCloudLink
      netsConnectCloudUsername

      posTerminals {
        ...FullPosTerminal
      }
    }
  }
`

const schema: ObjectSchema<NetsConnectCloudCredentials> = yup.object({
  username: yup.string().trim().required('Vänligen ange användarnamn för ditt konto på nets'),
  password: yup.string().trim().required('Vänligen ange lösenord för ditt konto på nets')
})

interface PaymentProviderItemProps {
  description?: JSX.Element
  errors?: ApolloError | null
  hasNetsConnectCloudLink?: boolean | null
  loading: boolean
  onSave?: (credentials: NetsConnectCloudCredentials) => void
  paymentProvider: PaymentProvider
  restaurantId: string
  saving: boolean
  username?: string | null
}

const PaymentProviderItem: React.FC<PaymentProviderItemProps> = ({ description, errors, hasNetsConnectCloudLink, loading, onSave, paymentProvider, restaurantId, saving, username }) => {
  const [adding, setAdding] = useState(false)
  const [refresh, { error: refreshError, loading: refreshing }] = useRefreshPosTerminalsMutation({ variables: { restaurantId } })

  const form = useForm<NetsConnectCloudCredentials>({
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const handleAddPress = (): void => {
    setAdding(!adding)
  }

  const handleCancelPress = (): void => {
    setAdding(!adding)
    form.reset()
  }

  const handleEditPress = (): void => {
    setAdding(!adding)
    form.reset({ username: username ?? undefined })
  }

  const handleRefreshPress = (): void => {
    refresh().catch(logError)
  }

  const handleSave = (input: NetsConnectCloudCredentials): void => {
    onSave?.(input)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key !== 'Enter') return

    event.preventDefault()
    form.handleSubmit(handleSave)().catch(logError)
  }

  return (
    <View style={{ backgroundColor: 'white', borderRadius: 5, padding: 12 }}>
      <HStack alignItems='center' justifyContent='space-between' wrap>
        <Text size={16}>{paymentProviderLabel(paymentProvider)}</Text>

        <Spacer width={16} />

        {(loading || refreshing)
          ? <ActivityIndicator />
          : username == null
          ? adding
            ? <CancelButton onPress={handleCancelPress} title='Avbryt' />
            : <AddButton onPress={handleAddPress} title='Lägg till' />
          : (
            <HStack alignItems='center'>
              {hasNetsConnectCloudLink == null ? <Warning message='Ingen respons från Nets servrar' />
                : !hasNetsConnectCloudLink
                ? (
                  <>
                    <Text color='red'>
                      Felaktiga inloggningsuppgifter
                    </Text>

                    <VStack padding={12}>
                      <MaterialIcons color='red' name='error' size={32} style={{ borderRadius: 16, fontWeight: 'bold' }} />
                    </VStack>
                  </>
                )
                : (
                  <VStack padding={12}>
                    <MaterialIcons color='white' name='done' size={32} style={{ backgroundColor: 'green', borderColor: 'green', borderRadius: 16, fontWeight: 'bold' }} />
                  </VStack>
                )}

              {refreshError == null ? null : <Warning message={refreshError.message} />}

              <MaterialIcons name='refresh' onPress={handleRefreshPress} size={24} style={{ padding: 12 }} />
              <MaterialIcons name='edit' onPress={handleEditPress} size={24} style={{ padding: 12 }} />
            </HStack>
          )}
      </HStack>

      {!adding ? null : (
        <VStack>
          {description}

          <Spacer height={24} />

          <HStack gap={8} wrap>
            <VStack grow={1}>
              <TextField
                form={form}
                name='username'
                title='Användarnamn'
              />
            </VStack>

            <VStack grow={1}>
              <PasswordField
                form={form}
                name='password'
                onKeyDown={handleKeyDown}
                title='Lösenord'
              />
            </VStack>
          </HStack>

          <Spacer height={24} />

          {errors == null
            ? null
            : <Warning message={errors.message} paddingBottom={12} />}

          <VStack>
            <AddButton disabled={saving || !form.formState.isValid} icon='add-circle' loading={saving} onPress={ignoreAsync(form.handleSubmit(handleSave))} title={`Skapa anslutning till ${paymentProviderLabel(paymentProvider)}`} />
          </VStack>
        </VStack>
      )}
    </View>
  )
}

const RestaurantTillList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantTillList'>()

  const { data, loading } = useGetTillsQuery({ errorPolicy: 'all', variables: { restaurantId } })
  const [linkNetsConnectCloudAccount, { error: credentialsError, loading: credentialsSaving }] = useLinkNetsConnectCloudAccountMutation()

  const supportedPaymentMethods = extractSupportedTillPaymentMethods(data?.restaurant?.paymentMethods)
  const renderTillListItem = (item: FullTillFragment): JSX.Element => <RestaurantTillListItem hasSkipSlipPrintOnPrinterQueuesAccess={data?.restaurant?.hasSkipSlipPrintOnPrinterQueuesAccess ?? false} item={item} onEditPress={() => navigation.navigate('RestaurantTillEdit', { restaurantId, tillId: item.id })} supportedPaymentMethods={supportedPaymentMethods} />
  const renderPosTerminalListItem = (item: FullPosTerminalFragment): JSX.Element => <RestaurantPosTerminalListItem item={item} onEditPress={() => navigation.navigate('RestaurantPosTerminalEdit', { restaurantId, posTerminalId: item.id })} />

  const handleAddCardTerminalCredentials = (credentials: NetsConnectCloudCredentials): void => {
    linkNetsConnectCloudAccount({ variables: { restaurantId, credentials } }).catch(logError)
  }

  return (
    <Layout screenType={ScreenType.List} title='Kassor för POS'>
      <ListContainer maxWidth={700}>
        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('RestaurantTillCreate', { restaurantId })}
          title='Skapa ny kassa'
        />

        {loading
          ? <ActivityIndicator />
          : (
            <FlatList
              data={data?.restaurant?.tills}
              ItemSeparatorComponent={() => <Spacer height={8} />}
              keyExtractor={item => item.id}
              renderItem={({ item }) => renderTillListItem(item)}
            />
          )}
      </ListContainer>

      <ListContainer maxWidth={700}>
        <VStack>
          <Text size={24}>Kortterminaler</Text>
          <Text size={16}>Kortterminaler vi i dagsläget har stöd för:</Text>
        </VStack>

        <PaymentProviderItem
          description={
            <VStack gap={16} paddingTop={20}>
              <Text size={16} weight='bold'>Viktig information för Nets Connect@Cloud</Text>
              <Text size={16}>Ingen kontoinformation, så som kortinnehavarens data eller känslig autentiseringsdata, varken lagras, behandlas, eller överförs i LocoPOS Order. För varje transaktion sparas endast trunkerad eller krypterad kontodata.</Text>
              <Text size={16}>Denna integrerade lösning får endast användas till gällande system av Loco och den kassalösning som finns tillgänlig. All annan användning är förbjuden.</Text>
              <Text size={16}>Dessa kortterminaler erbjuder ej manuellt inmatade transaktioner.</Text>
              <Text size={16}>Pris per transaktion blir enligt gällande prislista dels ifrån kortinlösen och dels ifrån Locos prisbild.</Text>
            </VStack>
          }
          errors={credentialsError}
          hasNetsConnectCloudLink={data?.restaurant?.hasNetsConnectCloudLink}
          loading={loading}
          onSave={handleAddCardTerminalCredentials}
          paymentProvider={PaymentProvider.NetsConnectCloud}
          restaurantId={restaurantId}
          saving={credentialsSaving}
          username={data?.restaurant?.netsConnectCloudUsername}
        />

        {loading
          ? <ActivityIndicator />
          : (
            <FlatList
              data={data?.restaurant?.posTerminals}
              ItemSeparatorComponent={() => <Spacer height={8} />}
              keyExtractor={item => item.id}
              renderItem={({ item }) => renderPosTerminalListItem(item)}
            />
          )}
      </ListContainer>
    </Layout>
  )
}

export default RestaurantTillList

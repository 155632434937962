import { Temporal } from '@js-temporal/polyfill'
import { useEffect, useState } from 'react'
import unreachable from 'ts-unreachable'

type Interval = 'second' | 'minute' | 'hour'

function nextCallback (now: Date, interval: Interval): number {
  switch (interval) {
    case 'second':
      return 1000 - now.getMilliseconds()
    case 'minute':
      return 60 * 1000 - now.getMilliseconds() - now.getSeconds() * 1000
    case 'hour':
      return (
        60 * 60 * 1000 -
        now.getMilliseconds() -
        now.getSeconds() * 1000 -
        now.getMinutes() * 60 * 1000
      )
    default:
      unreachable(interval)
  }
}

export default function useDate ({ interval }: { interval: Interval }): Temporal.Instant {
  const [date, setDate] = useState(Temporal.Now.instant())

  useEffect(() => {
    let timeoutHandle: NodeJS.Timeout

    const bump = (): void => {
      setDate(Temporal.Now.instant())
      scheduleNext()
    }

    const scheduleNext = (): void => {
      timeoutHandle = setTimeout(bump, nextCallback(new Date(), interval))
    }

    scheduleNext()

    return () => {
      clearTimeout(timeoutHandle)
    }
  }, [interval])

  return date
}

import gql from 'graphql-tag'
import React, { useMemo } from 'react'

import { ApiEnvironment, FixedDeliveryTime, GetTillsDocument, MenuTypeFilter, PaymentProvider, SendToKitchen, type TillInput, TillLayout, useCreateTillMutation, useGetTillsQuery } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import TillForm from '../components/TillForm'
import extractSupportedTillPaymentMethods from '../util/extractSupportedTillPaymentMethods'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateTill($restaurantId: ID!, $input: TillInput!, $orderDisplaySystemEnabled: Boolean) {
    createTill(
      restaurantId: $restaurantId,
      input: $input,
    ) {
      ...FullTill
    }

    updateRestaurant(
      restaurantId: $restaurantId,
      patch: { orderDisplaySystemEnabled: $orderDisplaySystemEnabled }
    ) {
      id

      orderDisplaySystemEnabled
    }
  }
`

const RestaurantTillCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantTillCreate'>()

  const { data, loading } = useGetTillsQuery({ errorPolicy: 'all', variables: { restaurantId } })

  const [createTill, { loading: saving }] = useCreateTillMutation({ onCompleted: () => navigation.navigate('RestaurantTillList', { restaurantId }), awaitRefetchQueries: true, refetchQueries: [{ query: GetTillsDocument, variables: { restaurantId } }] })

  const supportedPaymentMethods = extractSupportedTillPaymentMethods(data?.restaurant?.paymentMethods)

  const netsConnectCloudIsOffline = !loading && data?.restaurant?.hasNetsConnectCloudLink == null

  const defaultValues: TillInput = {
    fixedDeliveryLocationId: null,
    fixedDeliveryTime: FixedDeliveryTime.Any,
    layout: TillLayout.List,
    name: '',
    paymentMethodIds: supportedPaymentMethods.filter(method => method.paymentProvider !== PaymentProvider.NetsConnectCloud).map(paymentMethod => paymentMethod.id),
    preferredApiEnvironment: !(data?.restaurant?.isBetaTesting ?? false) ? undefined : ApiEnvironment.Stable,
    receiptPrinterQueueId: null,
    sendOrderToKitchen: SendToKitchen.Always,
    showsCategoryBar: false,
    showsDiscountField: true,
    showsEmptyShoppingCartButton: true,
    showsHoldKitchenPrint: false,
    showsLogo: true,
    showsMenuType: MenuTypeFilter.All,
    showsOpeningStatus: true,
    showsOpenOrders: true,
    showsSearch: true,
    showsTip: false
  }

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantTillList', { restaurantId }], title: 'Kassor för POS' }
  ], [restaurantId])

  const handleSave = (input: TillInput, orderDisplaySystemEnabled: false | null): void => {
    createTill({ variables: { restaurantId, input, orderDisplaySystemEnabled } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title='Skapa kassa'>
      {data?.restaurant?.deliveryLocations == null ? null : (
        <TillForm
          deliveryLocationOptions={data.restaurant.deliveryLocations}
          hasSkipSlipPrintOnPrinterQueuesAccess={data.restaurant.hasSkipSlipPrintOnPrinterQueuesAccess ?? false}
          initialValues={defaultValues}
          isSuperUser={data?.me?.isSuperUser ?? false}
          netsConnectCloudIsOffline={netsConnectCloudIsOffline}
          onDismiss={() => navigation.navigate('RestaurantTillList', { restaurantId })}
          onSave={handleSave}
          orderDisplaySystemEnabled={data.restaurant.orderDisplaySystemEnabled ?? false}
          paymentMethodOptions={supportedPaymentMethods}
          posTerminalOptions={data?.restaurant?.posTerminals ?? []}
          receiptPrinterOptions={data.restaurant.printerQueues ?? []}
          restaurantIsBetaTesting={data.restaurant.isBetaTesting}
          saving={saving}
        />
      )}
    </Layout>
  )
}

export default RestaurantTillCreate

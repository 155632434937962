import gql from 'graphql-tag'
import React, { useCallback, useMemo, useState } from 'react'

import { type DiscountPatch, GetMetaOrganizationDocument, useDeleteDiscountMutation, useGetDiscountQuery, useUpdateDiscountMutation } from '../../types/graphql'
import DiscountEditForm from '../components/DiscountEditForm'
import Layout, { ScreenType } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import logAndShowError from '../util/logAndShowError'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetDiscount($metaOrganizationId: ID!, $discountId: ID!) {
    metaOrganization(id: $metaOrganizationId) {
      id
      name
      createdAt
      updatedAt

      discount(id: $discountId) {
        ...FullDiscount
      }
    }
  }

  mutation DeleteDiscount($metaOrganizationId: ID!, $discountId: ID!) {
    deleteDiscount(metaOrganizationId: $metaOrganizationId, discountId: $discountId)
  }

  mutation UpdateDiscount($metaOrganizationId: ID!, $discountId: ID!, $patch: DiscountPatch!) {
    updateDiscount(metaOrganizationId: $metaOrganizationId, discountId: $discountId, patch: $patch) {
      id
    }
  }
`

const MetaOrganizationDiscountEdit: React.FC = () => {
  const [navigation, { metaOrganizationId, discountId }] = useNavigation<'MetaOrganizationDiscountEdit'>()

  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('MetaOrganizationView', { metaOrganizationId })
  }, [navigation])

  const { data } = useGetDiscountQuery({ variables: { metaOrganizationId, discountId } })
  const [deleteDiscount, { loading: deleting, error: deleteError }] = useDeleteDiscountMutation({ onCompleted: handleCancel })
  const [updateDiscount, { error: saveError, loading: saving }] = useUpdateDiscountMutation({ onCompleted: handleCancel, onError: logAndShowError })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const discount = useMemo(() => data?.metaOrganization?.discount, [data])

  const handleDelete = useCallback(() => {
    deleteDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetMetaOrganizationDocument, variables: { id: metaOrganizationId } }],
      variables: { metaOrganizationId, discountId }
    }).catch(logAndShowError)
  }, [deleteDiscount, metaOrganizationId, discountId])

  const handleUpdate = (patch: DiscountPatch): void => {
    updateDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetMetaOrganizationDocument, variables: { id: metaOrganizationId } }],
      variables: { metaOrganizationId, discountId, patch }
    }).catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['MetaOrganizationList', {}], title: 'Metaorganisationer' },
        { link: ['MetaOrganizationView', { metaOrganizationId }], title: data?.metaOrganization?.name ?? '...' }
      ]}
      loading={discount == null}
      screenType={ScreenType.Form}
      title={`Redigera ${discount?.title ?? discount?.code ?? '...'}`}
    >
      {discount == null ? null : (
        <DiscountEditForm
          discount={discount}
          error={saveError}
          onCancel={handleCancel}
          onDelete={() => setShowDeleteDialog(true)}
          onSave={handleUpdate}
          saving={saving}
        />
      )}

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={`Är du säker på att du vill ta bort ${discount?.title ?? `rabatten med kod ${discount?.code ?? ''}`}?`}
        title='Bekräfta'
      />
    </Layout>
  )
}

export default MetaOrganizationDiscountEdit

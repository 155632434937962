import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import unwrap from 'ts-unwrap'

import { GetPageMenuDocument, type MenuProductDefaultValuesFragment, type MenuProductInput, MenuType, useCreateMenuProductMutation, useGetCreateProductDataQuery } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import ProductForm, { type MenuProductInputSchema } from '../components/ProductForm'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

const MENU_TYPES = [MenuType.EatIn, MenuType.TakeAway, MenuType.Delivery]

function extractNewProductDefaultValues (input: MenuProductDefaultValuesFragment | null | undefined): Partial<MenuProductInput> {
  return {
    defaultCourseId: input?.defaultCourse?.id ?? undefined,
    delivery: input?.delivery == null
      ? null
      : {
        alternativeGroupIds: input?.delivery?.alternativeGroups?.map(group => group.id) ?? undefined,
        isActive: true,
        openingHoursId: input?.delivery?.openingHours?.id ?? undefined,
        reportGroupId: input?.delivery?.reportGroup?.id ?? undefined,
        printAt: input?.delivery?.printAt?.map(printer => printer.id) ?? undefined,
        secondaryPrinterIds: input?.delivery?.secondaryPrinters?.map(printer => printer.id) ?? undefined
      },
    eatIn: input?.eatIn == null
      ? null
      : {
        alternativeGroupIds: input?.eatIn?.alternativeGroups?.map(group => group.id) ?? undefined,
        isActive: true,
        openingHoursId: input?.eatIn?.openingHours?.id ?? undefined,
        reportGroupId: input?.eatIn?.reportGroup?.id ?? undefined,
        printAt: input?.eatIn?.printAt?.map(printer => printer.id) ?? undefined,
        secondaryPrinterIds: input?.eatIn?.secondaryPrinters?.map(printer => printer.id) ?? undefined
      },
    takeAway: input?.takeAway == null
      ? null
      : {
        alternativeGroupIds: input?.takeAway?.alternativeGroups?.map(group => group.id) ?? undefined,
        isActive: true,
        openingHoursId: input?.takeAway?.openingHours?.id ?? undefined,
        reportGroupId: input?.takeAway?.reportGroup?.id ?? undefined,
        printAt: input?.takeAway?.printAt?.map(printer => printer.id) ?? undefined,
        secondaryPrinterIds: input?.takeAway?.secondaryPrinters?.map(printer => printer.id) ?? undefined
      }
  }
}

gql`
  fragment MenuProductVariantDefaultFields on MenuProductVariantDefaultValues {
    id

    alternativeGroups {
      id
    }

    openingHours {
      id
    }

    printAt {
      id
    }

    reportGroup {
      ...FullMenuReportGroup
    }

    secondaryPrinters {
      id
    }
  }

  fragment MenuProductDefaultValues on MenuProductDefaultValues {
    id

    defaultCourse {
      id
    }

    delivery {
      ...MenuProductVariantDefaultFields
    }

    eatIn {
      ...MenuProductVariantDefaultFields
    }

    takeAway {
      ...MenuProductVariantDefaultFields
    }
  }

  query GetCreateProductData($restaurantId: ID!, $menuCategoryId: ID!) {
    restaurant(id: $restaurantId) {
      id

      hasMenuWriteAccess: hasAccess(access:MenuWrite)
      menuSystem

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        pages {
          id

          name

          categories {
            id

            name
          }
        }

        courses {
          id

          name
        }

        productOpeningHours {
          id

          name
        }

        reportGroups {
          ...FullMenuReportGroup
        }

        alternativeGroups {
          id

          name
        }

        category(id: $menuCategoryId) {
          id

          name

          newProductDefaultValues {
            ...MenuProductDefaultValues
          }

          page {
            id
          }
        }
      }

      printerQueues {
        id

        name
      }
    }
  }

  mutation CreateMenuProduct($restaurantId: ID!, $menuCategoryId: ID!, $input: MenuProductInput!) {
    createMenuProduct(
      restaurantId: $restaurantId
      menuCategoryId: $menuCategoryId
      input: $input
    ) {
      id

      category {
        id

        page {
          id

          productCount
        }
      }
    }
  }
`

const MenuProductCreate: React.FC = () => {
  const [navigation, { restaurantId, menuCategoryId }] = useNavigation<'MenuProductCreate'>()
  const { data, loading } = useGetCreateProductDataQuery({
    fetchPolicy: 'network-only',
    skip: !navigation.isFocused(),
    variables: { restaurantId, menuCategoryId }
  })
  const [createMenuProduct, { error: createMenuProductError, loading: isSubmitting }] = useCreateMenuProductMutation({ awaitRefetchQueries: true })

  const menuPageId = data?.restaurant?.menu?.category?.page?.id

  const breadcrumbs = useMemo<Breadcrumb[]>(() => {
    return [
      { link: ['MenuView', { restaurantId }], title: 'Meny' },
      { link: ['MenuPageView', { restaurantId, menuPageId }], title: data?.restaurant?.menu?.pages?.find(page => page.id === menuPageId)?.name ?? '' }
    ]
  }, [data, menuCategoryId, restaurantId])

  const handleRedirect = (menuPageId: string): void => {
    navigation.navigate('MenuPageView', { restaurantId, menuPageId })
  }

  const handleSave = (input: MenuProductInputSchema, menuCategoryId: string, menuPageId: string): void => {
    let { delivery, eatIn, name, takeAway } = input

    if (delivery?.priceIsOpen ?? false) delivery = { ...delivery, price: null }
    if (eatIn?.priceIsOpen ?? false) eatIn = { ...eatIn, price: null }
    if (takeAway?.priceIsOpen ?? false) takeAway = { ...takeAway, price: null }

    createMenuProduct({
      onCompleted: () => handleRedirect(menuPageId),
      refetchQueries: [
        { query: GetPageMenuDocument, variables: { restaurantId, menuPageId } }
      ],
      variables: {
        input: {
          delivery,
          eatIn,
          name,
          quantityAvailable: !input.hasQuantityAvailable
            ? null
            : input.quantityAvailable ?? 0,
          takeAway
        },
        menuCategoryId,
        restaurantId
      }
    }).catch(logError)

    handleRedirect(menuPageId)
  }

  if (loading) {
    return <Layout breadcrumbs={breadcrumbs} loading title='Skapa produkt' />
  }

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title='Skapa produkt'>
      {data?.restaurant?.menu == null
        ? null
        : (
          <ProductForm
            error={createMenuProductError}
            hasMenuWriteAccess={data?.restaurant?.hasMenuWriteAccess ?? false}
            initialValues={extractNewProductDefaultValues(data?.restaurant?.menu?.category?.newProductDefaultValues)}
            menuCategoryId={menuCategoryId}
            onDismiss={() => handleRedirect(unwrap(menuPageId))}
            onSave={handleSave}
            restaurant={data?.restaurant}
            saving={isSubmitting}
            types={MENU_TYPES}
          />
        )}
    </Layout>
  )
}

export default MenuProductCreate

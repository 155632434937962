import React from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'

import { TextField } from '../TextField'

interface ControlledMoneyFieldProps {
  /**
   *  The autoFocus prop is used to automatically focus on the input when the page loads.
   */
  autoFocus?: boolean
  errorMessage?: string
  /**
   *  The estimated number of characters is used to estimate the width of the text field. This is useful when the text field is used in a form with multiple inputs. As per the GOV.UK Design System, the width of the text field will be adjusted based on the estimated number of characters that you think will be used in the field.
   */
  estimatedNumberOfCharacters?: number
  /**
   *  Hints are used to help users understand what is expected of them when filling in a form field. The hint will be displayed below the title and above the input, in gray.
   */
  hint?: string
  /**
   *  The large title is typically used for forms with one input.
   */
  largeTitle?: boolean
  /**
   *  The onBlur prop is used to handle when the user has finished editing the input.
   */
  onBlur?: () => void
  onChange: (value: string | undefined) => void
  /**
   * Fires when the user wants to submit the form, e.g. when enter is pressed on the keyboard.
   */
  onSubmitEditing?: () => void
  title?: string
  value?: string | null
}

interface UncontrolledMoneyFieldProps<TFieldValues extends FieldValues> {
  /**
   *  The autoFocus prop is used to automatically focus on the input when the page loads.
   */
  autoFocus?: boolean
  /**
   *  The estimated number of characters is used to estimate the width of the text field. This is useful when the text field is used in a form with multiple inputs. As per the GOV.UK Design System, the width of the text field will be adjusted based on the estimated number of characters that you think will be used in the field.
   */
  estimatedNumberOfCharacters?: number
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  /**
   *  Hints are used to help users understand what is expected of them when filling in a form field. The hint will be displayed below the title and above the input, in gray.
   */
  hint?: string
  /**
   *  The large title is typically used for forms with one input.
   */
  largeTitle?: boolean
  name: Path<TFieldValues>
  /**
   * Fires when the user wants to submit the form, e.g. when enter is pressed on the keyboard.
   */
  onSubmitEditing?: () => void
  required?: boolean
  title?: string
}

export default function MoneyField<T extends FieldValues> (props: UncontrolledMoneyFieldProps<T> | ControlledMoneyFieldProps): JSX.Element {
  if ('form' in props) {
    const errorMessage = get(props.form.formState.errors, props.name)?.message

    return (
      <Controller
        control={props.form.control}
        name={props.name}
        render={({ field: { onBlur, onChange, value } }) => (
          <MoneyField
            autoFocus={props.autoFocus}
            errorMessage={errorMessage}
            estimatedNumberOfCharacters={props.estimatedNumberOfCharacters}
            onBlur={onBlur}
            onChange={onChange}
            onSubmitEditing={props.onSubmitEditing}
            required={props.required}
            title={props.title}
            value={value}
          />
        )}
      />
    )
  }

  return (
    <TextField
      autoFocus={props.autoFocus}
      errorMessage={props.errorMessage}
      estimatedNumberOfCharacters={props.estimatedNumberOfCharacters}
      hint={props.hint}
      largeTitle={props.largeTitle}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onSubmitEditing={props.onSubmitEditing}
      suffix='kr'
      title={props.title}
      value={props.value == null ? '' : props.value.toString()}
    />
  )
}

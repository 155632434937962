import { Dimensions, PixelRatio, Platform } from 'react-native'

const {
  width: SCREEN_WIDTH
} = Dimensions.get('window')

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 320

export function normalize (size: number, max: number): number {
  const newSize = size * scale
  if (Platform.OS === 'ios') {
    return Math.min(max, Math.round(PixelRatio.roundToNearestPixel(newSize)))
  } else {
    return Math.min(max, Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2)
  }
}

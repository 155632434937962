import gql from 'graphql-tag'
import React, { useCallback } from 'react'

import { type DiscountInput, GetWhiteLabelDiscountListDataDocument, useCreateWhiteLabelDiscountMutation, useGetWhiteLabelDiscountCreateDataQuery } from '../../types/graphql'
import DiscountCreateForm from '../components/DiscountCreateForm'
import Layout, { ScreenType } from '../components/Layout'
import logAndShowError from '../util/logAndShowError'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateWhiteLabelDiscount($whiteLabelId: ID!, $input: DiscountInput!) {
    createWhiteLabelDiscount(whiteLabelId: $whiteLabelId, input: $input) {
      id
    }
  }

  query GetWhiteLabelDiscountCreateData ($whiteLabelId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      guestGroups(first: 1000) {
        edges {
          node {
            id

            name
          }
        }
      }

      restaurants(first: 1000) {
        edges {
          node {
            id

            name
          }
        }
      }
    }
  }
`

const WhiteLabelDiscountCreate: React.FC = () => {
  const [navigation, { whiteLabelId }] = useNavigation<'WhiteLabelDiscountCreate'>()

  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('WhiteLabelDiscountList', { whiteLabelId })
  }, [navigation])

  const { data, loading } = useGetWhiteLabelDiscountCreateDataQuery({ variables: { whiteLabelId } })
  const guestGroups = data?.whiteLabel?.guestGroups?.edges ?? []
  const restaurants = data?.whiteLabel?.restaurants?.edges ?? []

  const [createWhiteLabelDiscount, { error: createError, loading: creating }] = useCreateWhiteLabelDiscountMutation({ onCompleted: handleCancel, onError: logAndShowError })

  const handleCreate = useCallback((input: DiscountInput) => {
    createWhiteLabelDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetWhiteLabelDiscountListDataDocument, variables: { id: whiteLabelId } }],
      variables: { whiteLabelId, input }
    }).catch(logError)
  }, [createWhiteLabelDiscount, whiteLabelId, navigation])

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'Dashboard' },
        { link: ['WhiteLabelDiscountList', { whiteLabelId }], title: 'Rabatter' }
      ]}
      loading={loading}
      screenType={ScreenType.Form}
      title='Skapa rabatt'
    >
      <DiscountCreateForm
        error={createError}
        guestGroupOptions={guestGroups.map(guestGroup => ({ id: guestGroup.node.id, name: guestGroup.node.name ?? 'n/a' })) ?? []}
        onCancel={handleCancel}
        onSave={handleCreate}
        restaurantOptions={restaurants.map(restaurant => ({ id: restaurant.node.id, name: restaurant.node.name ?? 'n/a' })) ?? []}
        saving={creating}
      />
    </Layout>
  )
}

export default WhiteLabelDiscountCreate

import { Ionicons } from '@expo/vector-icons'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

interface InfoIconProps {
  content: string
}

const InfoIcon: React.FC<InfoIconProps> = ({ content }) => {
  return (
    <Tooltip placement='right' title={content}>
      <IconButton>
        <Ionicons color='black' name='md-information-circle-outline' size={24} />
      </IconButton>
    </Tooltip>
  )
}

export default InfoIcon

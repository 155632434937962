import { type ApolloError } from '@apollo/client'
import { Temporal } from '@js-temporal/polyfill'
import { useIsFocused } from '@react-navigation/native'
import React, { type ReactNode, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unimplemented from 'ts-unimplemented'
import unreachable from 'ts-unreachable'

import { GetTimeSlotsDocument, MenuType, Periodicity, useGetRestaurantTimezoneQuery, useGetTimeSlotsQuery, useUpdateRestaurantMutation } from '../../types/graphql'
import ButtonGroup from '../components/ButtonGroup'
import Layout from '../components/Layout'
import TimeSlot from '../components/RestaurantTimeSlots/TimeSlot'
import Warning from '../components/Warning'
import { Select } from '../components/fields'
import DateField from '../components/fields/DateField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import logError from '../util/logError'
import serializeZonedDateTimeRange, { type ZonedDateTimeRange } from '../util/serializeZonedDateTimeRange'
import useDate from '../util/useDate'
import useNavigation from '../util/useNavigation'
import useSelectedTimeSlotSettings, { type TimeSlotPeriod } from '../util/useSelectedTimeSlotSettings'

const InfoContainer: React.FC<{ backgroundColor?: string, children: ReactNode }> = ({ backgroundColor = '#54CC7C', children }) => (
  <VStack
    alignItems='center'
    backgroundColor={backgroundColor}
    borderColor='#F8FAFC'
    borderRadius={12}
    borderWidth={2}
    justifyContent='center'
    padding={8}
    paddingHorizontal={24}
  >
    {children}
  </VStack>
)

interface DateFormSchema {
  endPeriodicity: string
  maxDeliveryTime?: string | null
  periodicity: Periodicity
  start: string
  timePeriod: TimeSlotPeriod
}

const dateFormSchema: ObjectSchema<DateFormSchema> = yup.object({
  endPeriodicity: yup.string().matches(/^\d{4}-(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])$/, 'Felaktigt datum (åååå-mm-dd)').required('Ange datum (åååå-mm-dd)'),
  maxDeliveryTime: yup.string().nullable(),
  periodicity: yup.mixed<Periodicity>().oneOf([Periodicity.Daily, Periodicity.Monthly, Periodicity.None, Periodicity.WeekDays, Periodicity.WeekEnds, Periodicity.Weekly]).required(),
  start: yup.string().matches(/^\d{4}-(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])$/, 'Felaktigt datum (åååå-mm-dd)').required('Ange datum (åååå-mm-dd)'),
  timePeriod: yup.mixed<TimeSlotPeriod>().oneOf(['selectDate', 'now']).required()
})

function timeSlotFromMenuType (menuType: MenuType.Delivery | MenuType.EatIn | MenuType.TakeAway): 'deliveryDeliveryTimeSlots' | 'eatInDeliveryTimeSlots' | 'takeAwayDeliveryTimeSlots' {
  switch (menuType) {
    case MenuType.Delivery:
      return 'deliveryDeliveryTimeSlots'
    case MenuType.EatIn:
      return 'eatInDeliveryTimeSlots'
    case MenuType.TakeAway:
      return 'takeAwayDeliveryTimeSlots'
    default:
      unreachable(menuType)
  }
}

function labelFromDuration (duration: string): string {
  switch (duration) {
    case 'PT10H':
      return '10 timmar'
    case 'PT16H':
      return '16 timmar'
    case 'P1DT':
      return '1 dag'
    case 'P2DT':
      return '2 dagar'
    case 'P3DT':
      return '3 dagar'
    case 'P4DT':
      return '4 dagar'
    case 'P5DT':
      return '5 dagar'
    case 'P6DT':
      return '6 dagar'
    case 'P1W':
      return '1 vecka'
    case 'P2W':
      return '2 veckor'
    case 'P3W':
      return '3 veckor'
    case 'P4W':
      return '4 veckor'
    default:
      return unimplemented()
  }
}

function currentPlainDate (now: Temporal.Instant, timeZone: string): Temporal.PlainDate {
  return now.toZonedDateTimeISO(timeZone).toPlainDate()
}

function dateTimeRangeForTwoDays (startDate: Temporal.PlainDate, timeZone: string): ZonedDateTimeRange {
  return { start: startDate.toZonedDateTime({ timeZone }), end: startDate.add({ days: 2 }).toZonedDateTime({ timeZone }) }
}

function dateTimeRangeUntilEnd (startDate: Temporal.PlainDate, endDate: Temporal.PlainDate, timeZone: string): ZonedDateTimeRange {
  return { start: startDate.toZonedDateTime({ timeZone }), end: endDate.toZonedDateTime({ timeZone }) }
}

const TimeSlotsView: React.FC = () => {
  const [, { restaurantId }] = useNavigation<'TimeSlotsView'>()

  const now = useDate({ interval: 'minute' })
  const timeZone = useGetRestaurantTimezoneQuery({ variables: { restaurantId } }).data?.restaurant?.timeZone ?? 'Europe/Stockholm'

  const [defaultDateFormLoaded, setDefaultDateFormLoaded] = useState<boolean>(false)
  const [selectedTimeSlotSettings, setSelectedTimeSlotSettings] = useSelectedTimeSlotSettings()

  const dateForm = useForm<DateFormSchema>({
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(dateFormSchema)
  })

  const selectedMaxDeliveryTime = dateForm.watch().maxDeliveryTime
  const selectedPeriodicity = dateForm.watch().periodicity
  const selectedTimePeriod = dateForm.watch().timePeriod

  const startString = dateForm.watch().start ?? ''
  const start = startString === '' ? null : Temporal.PlainDate.from(startString)
  const selectedDate = start ?? currentPlainDate(now, timeZone)

  const endPeriodicityString = dateForm.watch().endPeriodicity ?? ''
  const endPeriodicity = endPeriodicityString === '' ? null : Temporal.PlainDate.from(endPeriodicityString)

  const dateTimeRange = start == null ? null : dateTimeRangeForTwoDays(start, timeZone)
  const periodicityDateTimeRange = endPeriodicity == null ? null : dateTimeRangeUntilEnd(selectedDate, endPeriodicity, timeZone)

  const periodicitySelectOptions = [
    { title: 'Repetera inte', value: Periodicity.None },
    { title: 'Veckodagar', value: Periodicity.WeekDays },
    { title: 'Helger', value: Periodicity.WeekEnds },
    { title: 'Varje dag', value: Periodicity.Daily },
    { title: `Varje vecka på ${selectedDate.toLocaleString('sv-SE', { weekday: 'long' })}ar`, value: Periodicity.Weekly },
    { title: `Varje månad den ${selectedDate.toLocaleString('sv-SE', { day: 'numeric' })}`, value: Periodicity.Monthly }
  ]
  const timePeriodSelectOptions = [{ title: 'Nu', value: 'now' }, { title: 'Välj datum', value: 'selectDate' }]

  const isFocused = useIsFocused()
  const getTimeSlotsVariables = { restaurantId, dateTimeRange: (selectedTimePeriod !== 'selectDate' || dateTimeRange == null) ? null : serializeZonedDateTimeRange(dateTimeRange) }
  const { data, loading: loadingTimeSlots, refetch } = useGetTimeSlotsQuery({
    onCompleted: () => setSelectedTimeSlotSettings({ dateRange: start == null ? null : { start: start.toString() }, timePeriod: selectedTimePeriod ?? 'now' }),
    pollInterval: isFocused ? 10000 : undefined,
    variables: getTimeSlotsVariables
  })
  const [updateRestaurant, { loading: updating }] = useUpdateRestaurantMutation({ awaitRefetchQueries: true, refetchQueries: [{ query: GetTimeSlotsDocument, variables: getTimeSlotsVariables }] })

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [statusText, setStatusText] = useState('Laddar tidsslotter för menytyper')

  const loading = loadingTimeSlots || updating
  const maxDeliveryTime = data?.restaurant?.maxDeliveryTime

  const buttons: Array<{ value: MenuType.EatIn | MenuType.TakeAway | MenuType.Delivery, label: string }> = []
  if (data?.restaurant?.hasEatInMenu === true) buttons.push({ value: MenuType.EatIn, label: 'Äta här' })
  if (data?.restaurant?.hasTakeAwayMenu === true) buttons.push({ value: MenuType.TakeAway, label: 'Ta med' })
  if (data?.restaurant?.hasDeliveryMenu === true) buttons.push({ value: MenuType.Delivery, label: 'Leverans' })

  const selectedMenuType = useMemo(() => buttons[selectedIndex]?.value, [buttons, selectedIndex])
  const queueTime = useMemo(() => data?.restaurant?.queueTime == null ? null : Temporal.Duration.from(data.restaurant.queueTime), [data?.restaurant?.queueTime])

  const handleError = (error?: ApolloError | null): void => {
    setErrorMessage(error?.message ?? null)
  }

  const handleUpdateRestaurant = (maxDeliveryTime: string): void => {
    updateRestaurant({ variables: { restaurantId, patch: { maxDeliveryTime } } }).catch(logError)
  }

  useEffect(() => {
    if (buttons.length === 0) {
      setTimeout(() => {
        setStatusText('Laddar tidsslotter, långsam uppkoppling...')
      }, 4000)
      setTimeout(() => {
        setStatusText('Timeout. Inga tidsslotter hittades. Kontrollera att antingen Äta här, Ta med eller Leverans är aktiverat för din restaurang.')
      }, 20000)
    }
  }, [buttons.length])

  useEffect(() => {
    if (defaultDateFormLoaded) return
    if (selectedTimeSlotSettings?.timePeriod == null) return
    if (maxDeliveryTime == null) return

    setDefaultDateFormLoaded(true)

    dateForm.reset({ maxDeliveryTime, periodicity: Periodicity.None, start: selectedTimeSlotSettings?.dateRange?.start, timePeriod: selectedTimeSlotSettings.timePeriod })
  }, [defaultDateFormLoaded, maxDeliveryTime, selectedPeriodicity, selectedTimeSlotSettings?.dateRange?.start, selectedTimeSlotSettings?.timePeriod])

  useEffect(() => {
    handleError(null)
  }, [endPeriodicity, selectedPeriodicity, selectedTimePeriod, start])

  useEffect(() => {
    if (selectedMaxDeliveryTime == null) return

    handleUpdateRestaurant(selectedMaxDeliveryTime)
  }, [selectedMaxDeliveryTime])

  return (
    <Layout title='Öppettidsslotter'>
      <VStack maxWidth={768} padding={16}>
        <ButtonGroup
          buttons={buttons}
          onPress={(menuType) => {
            setSelectedIndex(buttons.findIndex(b => b.value === menuType))
            refetch().catch(logError)
          }}
          selected={selectedMenuType}
        />

        <Spacer height={16} />

        <VStack>
          <Select form={dateForm} name='timePeriod' options={timePeriodSelectOptions} title='Tidsperiod' />
        </VStack>

        {selectedTimePeriod !== 'selectDate'
          ? null
          : (
            <VStack padding={16}>
              <DateField
                autoFocus
                form={dateForm}
                name='start'
                title='Datum'
              />
            </VStack>
          )}

        <Spacer height={16} />

        <VStack>
          <Select form={dateForm} name='periodicity' options={periodicitySelectOptions} title='Periodicitet för ändringar' />
        </VStack>

        {selectedPeriodicity === Periodicity.None
          ? null
          : (
            <VStack padding={16}>
              <DateField
                autoFocus
                form={dateForm}
                name='endPeriodicity'
                title='Repetera tills (datum)'
              />
            </VStack>
          )}

        <Spacer height={16} />

        <VStack>
          <Select
            form={dateForm}
            name='maxDeliveryTime'
            options={[
              { title: labelFromDuration('PT10H'), value: 'PT10H' },
              { title: labelFromDuration('PT16H'), value: 'PT16H' },
              { title: labelFromDuration('P1DT'), value: 'P1DT' },
              { title: labelFromDuration('P2DT'), value: 'P2DT' },
              { title: labelFromDuration('P3DT'), value: 'P3DT' },
              { title: labelFromDuration('P4DT'), value: 'P4DT' },
              { title: labelFromDuration('P5DT'), value: 'P5DT' },
              { title: labelFromDuration('P6DT'), value: 'P6DT' },
              { title: labelFromDuration('P1W'), value: 'P1W' },
              { title: labelFromDuration('P2W'), value: 'P2W' },
              { title: labelFromDuration('P3W'), value: 'P3W' },
              { title: labelFromDuration('P4W'), value: 'P4W' }
            ]}
            title='Maximal leveranstid'
          />
        </VStack>

        <Spacer height={16} />

        {buttons.length === 0 || loading
          ? (
            <VStack alignItems='center' justifyContent='center' padding={12}>
              {!loading ? null : <ActivityIndicator />}

              <Spacer height={8} />

              <Text>{statusText}</Text>
            </VStack>
          )
          : (
            <>
              {selectedTimePeriod === 'now' || (dateTimeRange == null && selectedTimePeriod === 'selectDate')
                ? (
                  <InfoContainer backgroundColor='#54CC7C'>
                    <Text color='white' size={20}>Live-vy av tidsslotter {selectedMaxDeliveryTime == null ? '' : `(${labelFromDuration(selectedMaxDeliveryTime)} framåt)`}</Text>

                    <Spacer height={8} />

                    <Text color='white' size={18}>{selectedDate.toLocaleString('sv-SE', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}</Text>

                    <Spacer height={4} />

                    {queueTime == null
                      ? null
                      : <Text color='white' size={16}>Kötid: {queueTime.total('minutes')} minuter</Text>}
                  </InfoContainer>
                )
                : dateTimeRange == null
                ? null
                : (
                  <InfoContainer backgroundColor='#F8FAFC'>
                    <Text size={18}>{selectedDate.toLocaleString('sv-SE', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}</Text>
                  </InfoContainer>
                )}
              <Spacer height={16} />

              {errorMessage == null
                ? null
                : <Warning message={errorMessage} paddingBottom={16} />}

              <VStack backgroundColor='#fff' maxWidth={768} paddingVertical={24}>
                <HStack alignItems='center' gap={10} justifyContent='center' wrap>
                  {data?.restaurant?.[timeSlotFromMenuType(selectedMenuType)]?.map(timeslot => (
                    <TimeSlot
                      key={timeslot.id}
                      dateTimeRange={selectedTimePeriod === 'now' ? null : (selectedPeriodicity === Periodicity.None ? dateTimeRange : periodicityDateTimeRange)}
                      now={now}
                      onError={handleError}
                      periodicity={selectedPeriodicity}
                      periodicityDateTimeRange={periodicityDateTimeRange}
                      queueTime={queueTime ?? new Temporal.Duration()}
                      restaurantId={restaurantId}
                      timeSlot={timeslot}
                    />
                  ))}
                </HStack>
              </VStack>
            </>
          )}
      </VStack>
    </Layout>
  )
}

export default TimeSlotsView

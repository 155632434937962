import React, { type ReactNode } from 'react'
import { ActivityIndicator, View, type ViewStyle } from 'react-native'
import Spacer from 'react-spacer'
import { VStack } from 'react-stacked'

interface DisabledOverlayProps {
  backgroundColor?: string
  children: ReactNode
  disabled: boolean
  loading?: boolean
  message?: JSX.Element
  style?: ViewStyle
}

const DisabledOverlay: React.FC<DisabledOverlayProps> = ({ backgroundColor, children, disabled, loading, message, style }) => {
  return (
    <>
      {children}

      {!disabled
        ? null
        : loading == null
        ? message == null
          ? <View style={{ ...style, backgroundColor: backgroundColor ?? '#ffffff77', bottom: 0, left: 0, overflow: 'hidden', position: 'absolute', right: 0, top: 0 }} />
          : (
            <View style={{ ...style, backgroundColor: backgroundColor ?? '#ffffff77', bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 }}>
              <Spacer grow={1} height={0} />

              <VStack alignItems='center' justifyContent='center'>
                {message}
              </VStack>

              <Spacer grow={1} height={0} />
            </View>
          )
        : (
          <View style={{ ...style, backgroundColor: backgroundColor ?? '#ffffff77', bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 }}>
            <Spacer grow={1} height={0} />

            <ActivityIndicator size='large' />

            <Spacer grow={1} height={0} />
          </View>
        )}
    </>
  )
}

export default DisabledOverlay

import gql from 'graphql-tag'

import { GetWhiteLabelGuestGroupListDataDocument, useCreateWhiteLabelGuestGroupMutation } from '../../types/graphql'
import GuestGroupForm, { type FormInput } from '../components/GuestGroupForm'
import Layout, { ScreenType } from '../components/Layout'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateWhiteLabelGuestGroup($whiteLabelId: ID!, $input: WhiteLabelGuestGroupInput!) {
    createWhiteLabelGuestGroup(whiteLabelId: $whiteLabelId, input: $input) {
      id

      name
    }
  }
`

const WhiteLabelGuestGroupCreate: React.FC = () => {
  const [navigation, { whiteLabelId }] = useNavigation<'WhiteLabelGuestGroupCreate'>()
  const [createWhiteLabelGuestGroup, { error: createWhiteLabelGuestGroupError, loading: saving }] = useCreateWhiteLabelGuestGroupMutation()

  const handleCancel = (): void => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('WhiteLabelGuestGroupList', { whiteLabelId })
  }

  const handleSave = (input: FormInput): void => {
    createWhiteLabelGuestGroup({
      onCompleted: () => {
        navigation.navigate('WhiteLabelGuestGroupList', { whiteLabelId })
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetWhiteLabelGuestGroupListDataDocument, variables: { id: whiteLabelId } }],
      variables: {
        input,
        whiteLabelId
      }
    }).catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' },
        { link: ['WhiteLabelGuestGroupList', { whiteLabelId }], title: 'Lojalitetsgrupper' }
      ]}
      screenType={ScreenType.Form}
      title='Skapa lojalitetsgrupp'
    >
      <GuestGroupForm
        errorMessage={createWhiteLabelGuestGroupError?.message}
        onCancel={handleCancel}
        onSave={handleSave}
        saving={saving}
      />
    </Layout>
  )
}

export default WhiteLabelGuestGroupCreate

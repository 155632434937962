import { MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, Image } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useGetResetPasswordDataQuery, useRequestResetUserPasswordEmailMutation, useResetUserPasswordMutation } from '../../types/graphql'
import { PrimaryButton, RedoButton, SecondaryButton } from '../components/Buttons'
import { TextField } from '../components/fields'
import { setAccessToken } from '../lib/token-storage'
import yup, { yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import { normalize } from '../util/normalize'
import redirectToLanding from '../util/redirectToLanding'
import useDate from '../util/useDate'
import useNavigation from '../util/useNavigation'

gql`
  query GetResetPasswordData($token: String!) {
    passwordResetRequest(
      token: $token
    ) {
      id

      email
      expiresAt
      usedAt
    }
  }

  mutation ResetUserPassword($password: String!, $token: String!) {
    resetUserPassword(password: $password, token: $token) {
      parseToken
    }
  }
`

const userSchema = yup.object({
  password: yup.string().min(6, 'Lösenordet måste innehålla minst 6 tecken').required('Lösenord krävs'),
  passwordConfirmation: yup.string().equals([yup.ref('password')], 'Lösenorden måste matcha')
})

const PasswordResetConfirm: React.FC = () => {
  const [navigation, { organizationUserSignup, token, whiteLabelUserSignup }] = useNavigation<'PasswordResetConfirm'>()

  const fromInvite = organizationUserSignup != null || whiteLabelUserSignup != null

  const { data, loading: loadingData } = useGetResetPasswordDataQuery({ variables: { token } })

  const [requestResetUserPasswordEmail] = useRequestResetUserPasswordEmailMutation()
  const [resetUserPassword, { loading: loadingReset, error: resetPasswordError }] = useResetUserPasswordMutation({
    onCompleted: ignoreAsync(async (data) => {
      if (data?.resetUserPassword?.parseToken == null) return

      await setAccessToken(data.resetUserPassword.parseToken)
      await redirectToLanding(navigation)
    })
  })

  const form = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    resolver: yupResolver(userSchema)
  })

  const now = useDate({ interval: 'minute' })

  const [emailSentTo, setEmailSentTo] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState(false)

  const tokenUsed = useMemo(() => (
    data?.passwordResetRequest?.usedAt != null
  ), [data?.passwordResetRequest?.usedAt])

  const tokenExpired = useMemo(() => {
    if (data?.passwordResetRequest?.expiresAt == null) return false

    return Temporal.Instant.compare(now, data.passwordResetRequest.expiresAt) >= 0
  }, [now, data?.passwordResetRequest?.expiresAt])

  const handleRedo = (): void => {
    if (data?.passwordResetRequest?.email != null) {
      requestResetUserPasswordEmail({ variables: { email: data.passwordResetRequest.email } }).catch(logError)
      setEmailSentTo(data.passwordResetRequest.email)

      return
    }

    navigation.navigate('PasswordReset', {})
  }

  const handleSave = (): void => {
    resetUserPassword({ variables: { password: form.getValues().password, token } }).catch(logError)
  }

  return (
    <VStack backgroundColor='black' grow={1}>
      <Image source={require('../../assets/icon.png')} style={{ height: normalize(75, 90), width: normalize(75, 90) }} />

      <HStack alignItems='center' justifyContent='center'>
        <VStack backgroundColor='white' grow={1} maxWidth={500} padding={24}>
          <HStack>
            <SecondaryButton
              icon='navigate-before'
              onPress={() => navigation.navigate('Login', {})}
              title='Tillbaka'
            />
          </HStack>

          <Spacer height={16} />

          <Text size={24}>{fromInvite ? 'Skapa lösenord' : 'Återställ lösenord'}</Text>

          <Spacer height={24} />
          {loadingData
            ? <ActivityIndicator />
            : (
              <>
                {resetPasswordError == null
                  ? null
                  : (
                    <>
                      <HStack alignItems='center' justifyContent='center'>
                        <MaterialIcons color='red' name='warning' size={24} />

                        <Spacer width={12} />

                        <VStack>
                          <Text color='red' weight='bold'>{resetPasswordError.name}</Text>
                          <Text color='red'>{resetPasswordError.message}</Text>
                        </VStack>
                      </HStack>

                      <Spacer height={36} />

                      {emailSentTo != null
                        ? <Text>Ett e-postmeddelande med information om hur du går tillväga för att {fromInvite ? 'skapa' : 'återställa'} ditt lösenord har skickats till {emailSentTo}</Text>
                        : (
                          <>
                            <Text>Vill du efterfråga en ny länk?</Text>

                            <Spacer height={12} />

                            <RedoButton
                              onPress={handleRedo}
                              title='Efterfråga ny länk'
                            />
                          </>
                        )}
                    </>
                  )}

                {!((tokenExpired || tokenUsed) && resetPasswordError == null)
                  ? null
                  : (
                    <>
                      <HStack alignItems='center' justifyContent='center'>
                        <MaterialIcons color='red' name='warning' size={24} />

                        <Spacer width={12} />

                        <VStack>
                          <Text color='red' weight='bold'>
                            {tokenUsed
                              ? 'Denna länk har redan använts'
                              : 'Denna länk har utgått'}
                          </Text>

                          <Text color='red'>
                            {tokenUsed
                              ? `Du kan endast använda denna funktion en gång per länk. För att ${fromInvite ? 'skapa' : 'återställa'} ditt lösenord kan du efterfråga en ny länk.`
                              : `Länkarna för att ${fromInvite ? 'skapa' : 'återställa'} lösenord har en 30 minuters timer, och denna länks timer har utgått.`}
                          </Text>
                        </VStack>
                      </HStack>

                      <Spacer height={36} />

                      {emailSentTo != null
                        ? <Text>Ett e-postmeddelande med information om hur du går tillväga för att {fromInvite ? 'skapa' : 'återställa'} ditt lösenord har skickats till {emailSentTo}</Text>
                        : (
                          <>
                            <Text>Vill du efterfråga en ny länk?</Text>

                            <Spacer height={12} />

                            <RedoButton
                              onPress={handleRedo}
                              title='Efterfråga ny länk'
                            />
                          </>
                        )}
                    </>
                  )}

                {tokenExpired || tokenUsed || resetPasswordError != null
                  ? null
                  : (
                    <>
                      <TextField
                        form={form}
                        helperText='Minst 6 tecken'
                        icon={<MaterialIcons color={showPassword ? 'black' : 'grey'} name='visibility' onPress={() => setShowPassword(!showPassword)} size={24} />}
                        name='password'
                        title='Välj lösenord'
                        type={showPassword ? 'text' : 'password'}
                      />

                      <TextField
                        form={form}
                        name='passwordConfirmation'
                        title='Bekräfta lösenord'
                        type='password'
                      />

                      <Spacer height={24} />

                      <PrimaryButton
                        icon='account-circle'
                        loading={loadingReset}
                        onPress={ignoreAsync(form.handleSubmit(handleSave))}
                        title={`${fromInvite ? 'Skapa' : 'Återställ'} lösenord`}
                      />
                    </>
                  )}
              </>
            )}
        </VStack>
      </HStack>
    </VStack>
  )
}

export default PasswordResetConfirm

import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useCallback, useMemo, useState } from 'react'
import { get, useForm } from 'react-hook-form'
import { View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { EatInServicePolicy, KitchenOrderQueueSorting, MenuSystem, OrderSlipLayout, RestaurantMarketSegment, type RestaurantPatch, useDeleteRestaurantMutation, useGetRestaurantDataQuery, useGetRestaurantSuperUserDataQuery, useUpdateOrganizationMutation, useUpdateRestaurantMutation } from '../../types/graphql'
import { SecondaryButton } from '../components/Buttons'
import CopyIcon from '../components/CopyIcon'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import { ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR } from '../components/PanicButton'
import RestaurantDeliveryLocationShortUrlForm from '../components/RestaurantDeliveryLocationShortUrlForm'
import Select from '../components/Select'
import ShortUrlForm from '../components/ShortUrlForm'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import VerificationDialog from '../components/VerificationDialog'
import Warning from '../components/Warning'
import Heading from '../components/atoms/Heading'
import { CheckBox, FloatField, IntegerField, MoneyField, MultiSelectCheckbox } from '../components/fields'
import { ACCENT_COLOR, SECONDARY_TEXT_COLOR } from '../lib/color'
import { LOCO_ORDER_ENDPOINT } from '../lib/config'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import openLink from '../util/openLink'
import { parsePrice } from '../util/price'
import useNavigation from '../util/useNavigation'

function range (start: number, end: number, step: number = 1): number[] {
  const result: number[] = []

  for (let i = start; i <= end; i += step) {
    result.push(i)
  }

  return result
}

gql`
  fragment RestaurantWhiteLabelFields on WhiteLabel {
    id

    appName
    label
  }

  fragment RestaurantFields on Restaurant {
    id

    active
    city
    createdAt
    discountsEnabled
    eatInServicePolicy
    isBetaTesting
    hasClientHeaderMessageAccess
    hasOrderDisplaySystemAccess

    hasDeliveryMenu
    hasEatInMenu
    hasTakeAwayMenu

    hasSkipSlipPrintOnPrinterQueuesAccess

    humanOrderNumberRange {
      minimum
      maximum
    }
    kitchenOrderQueueSorting

    marketSegment
    maxDeliveryTime
    menuSystem
    name
    orderDisplaySystemEnabled
    orderMergeThreshold
    orderSlipLayout
    phoneNumber
    postalCode
    queueTime
    streetAddress
    temporarilyDisabled

    organization {
      id

      createdAt
      email
      name
      organizationNumber
    }

    featuredDiscount {
      id

      code
    }

    location {
      latitude
      longitude
    }

    whiteLabels {
      ...RestaurantWhiteLabelFields
    }
  }

  query GetRestaurantSuperUserData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      activationCode
      applicationFeeCents
      applicationFeePercentage
      isBetaTesting
    }

    whiteLabels(first: 999) {
      edges {
        node {
          id

          appName
          label
        }
      }
    }
  }

  query GetRestaurantData($restaurantId: ID!) {
    me {
      id

      isSuperUser
    }

    restaurant(id: $restaurantId) {
      ...RestaurantFields
    }
  }

  mutation DeleteRestaurant($restaurantId: ID!) {
    deleteRestaurant(restaurantId: $restaurantId)
  }

  mutation UpdateOrganization($organizationId: ID!, $patch: OrganizationPatch!) {
    updateOrganization(organizationId: $organizationId, patch: $patch) {
      id

      name
    }
  }

  mutation UpdateRestaurant($restaurantId: ID!, $patch: RestaurantPatch!) {
    updateRestaurant(restaurantId: $restaurantId, patch: $patch) {
      ...RestaurantFields
    }
  }
`

interface OrganizationForm {
  organizationEmail?: string
  organizationName?: string
}

type RestaurantForm =
  & Pick<
    RestaurantPatch,
    | 'active'
    | 'applicationFeePercentage'
    | 'city'
    | 'discountsEnabled'
    | 'eatInServicePolicy'
    | 'featuredDiscountCode'
    | 'location'
    | 'hasClientHeaderMessageAccess'
    | 'hasEatInMenu'
    | 'hasTakeAwayMenu'
    | 'hasDeliveryMenu'
    | 'hasOrderDisplaySystemAccess'
    | 'hasSkipSlipPrintOnPrinterQueuesAccess'
    | 'humanOrderNumberRange'
    | 'isBetaTesting'
    | 'marketSegment'
    | 'menuSystem'
    | 'kitchenOrderQueueSorting'
    | 'name'
    | 'orderMergeThreshold'
    | 'orderSlipLayout'
    | 'phoneNumber'
    | 'postalCode'
    | 'queueTime'
    | 'streetAddress'
    | 'temporarilyDisabled'
    | 'whiteLabelIds'
  >
  & { applicationFee?: string | null }

type Form = OrganizationForm & RestaurantForm

const schema: ObjectSchema<Form> = yup.object({
  active: yup.boolean(),
  // Application fee is given in SEK
  applicationFee: yup.string().trim().matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').nullable(),
  applicationFeePercentage: yup.number(),
  city: yup.string().trim().min(1).required(),
  discountsEnabled: yup.boolean().nullable(),
  eatInServicePolicy: yup.mixed<EatInServicePolicy>().oneOf([EatInServicePolicy.CompleteAutomatically, EatInServicePolicy.CompleteManually, EatInServicePolicy.DeliverToCashDesk], 'Välj serveringspolicy').nullable(),
  featuredDiscountCode: yup.string().nullable(),
  location: yup.object({
    latitude: yup.number().min(-90).max(90).required(),
    longitude: yup.number().min(-90).max(90).required()
  }),
  hasClientHeaderMessageAccess: yup.boolean().nullable(),
  hasEatInMenu: yup.boolean().nullable(),
  hasTakeAwayMenu: yup.boolean().nullable(),
  hasDeliveryMenu: yup.boolean().nullable(),
  hasOrderDisplaySystemAccess: yup.boolean().nullable(),
  hasSkipSlipPrintOnPrinterQueuesAccess: yup.boolean().nullable(),
  humanOrderNumberRange: yup.object({
    minimum: yup.number().required(),
    maximum: yup.number().required()
  }),
  isBetaTesting: yup.boolean().nullable(),
  marketSegment: yup.mixed<RestaurantMarketSegment>().oneOf([
    RestaurantMarketSegment.BarsAndPub,
    RestaurantMarketSegment.CasualDining,
    RestaurantMarketSegment.ContemporaryCasual,
    RestaurantMarketSegment.FastCasual,
    RestaurantMarketSegment.FastFood,
    RestaurantMarketSegment.FineDining,
    RestaurantMarketSegment.FoodTruck,
    RestaurantMarketSegment.FullService,
    RestaurantMarketSegment.LunchRestaurant
  ], 'Välj restauranttyp').nullable(),
  menuSystem: yup.mixed<MenuSystem>().oneOf([MenuSystem.Legacy, MenuSystem.Neue], 'Välj menysystem'),
  kitchenOrderQueueSorting: yup.mixed<KitchenOrderQueueSorting>().oneOf([KitchenOrderQueueSorting.EstimatedDelivery, KitchenOrderQueueSorting.OrderPlacedAt], 'Välj sorteringsalternativ för Loco POS Kitchen').nullable(),
  name: yup.string().trim().min(1).required(),
  orderMergeThreshold: yup.string().trim().matches(/^P(?:\d+Y)?(?:\d+M)?(?:\d+D)?(?:T(?:\d+H)?(?:\d+M)?(?:\d+(?:\.\d+)?S)?)?$/, 'Välj en tid').nullable(),
  orderSlipLayout: yup.mixed<OrderSlipLayout>().oneOf([OrderSlipLayout.Default, OrderSlipLayout.Premium], 'Välj bonglayout').nullable(),
  organizationEmail: yup.string().trim().min(1).optional(),
  organizationName: yup.string().trim().min(1).optional(),
  phoneNumber: yup.string().trim().matches(/^\+[1-9]\d{1,14}$/, 'Skriv i följande format: +46XXXXXXXXX').required(),
  postalCode: yup.string().trim().min(1).required(),
  queueTime: yup.string().trim().matches(/^PT\d+M$/, 'Välj en tid').nullable(),
  streetAddress: yup.string().trim().min(1).required(),
  temporarilyDisabled: yup.boolean(),
  whiteLabelIds: yup.array().of(yup.string().required()).optional()
}).required()

const marketSegmentOptions = [
  { title: 'Barer och pubar', value: RestaurantMarketSegment.BarsAndPub },
  { title: 'Casual dining', value: RestaurantMarketSegment.CasualDining },
  { title: 'Contemporary casual', value: RestaurantMarketSegment.ContemporaryCasual },
  { title: 'Fast casual', value: RestaurantMarketSegment.FastCasual },
  { title: 'Fast food', value: RestaurantMarketSegment.FastFood },
  { title: 'Fine dining', value: RestaurantMarketSegment.FineDining },
  { title: 'Food truck', value: RestaurantMarketSegment.FoodTruck },
  { title: 'Full service', value: RestaurantMarketSegment.FullService },
  { title: 'Lunchrestaurang', value: RestaurantMarketSegment.LunchRestaurant }
]

const marketSegmentDescriptionMap = {
  [RestaurantMarketSegment.BarsAndPub]: 'En bar eller pub inkluderar ofta en bar och serverar alkoholhaltiga drycker och enklare maträtter.',
  [RestaurantMarketSegment.CasualDining]: 'En casual dining restaurang är en full-service restaurang med en avslappnad atmosfär. Casual dining restauranger erbjuder omfattande, prisvärda menyer med alkohol, förrätter och huvudrätter.',
  [RestaurantMarketSegment.ContemporaryCasual]: 'Contemporary casual restauranger är mer utbredda i urbana och metropolitana områden. Vad som skiljer detta koncept från casual dining är att det finns fokus på att vara ett distinkt varumärke.',
  [RestaurantMarketSegment.FastCasual]: 'Ett fast-casual restaurangkoncept är den perfekta blandningen av snabbmat och avslappnad mat. Det erbjuder snabbmatens enkelhet och bekvämlighet men med högre kvalitet på ingredienserna och/eller tillagningsmetoderna.',
  [RestaurantMarketSegment.FastFood]: 'Vanligtvis är dessa restauranger kedjor som McDonald’s eller Taco Bell. Fokus ligger på hastighet och bekvämlighet. Beställningar och betalningar kan tas i kö, online eller vid upphämtningsplatsen.',
  [RestaurantMarketSegment.FineDining]: 'Dagens fine dining restauranger erbjuder en exklusiv atmosfär och en lyxig upplevelse. Denna typ av restaurang har en exakt uppmärksamhet på detaljer och exceptionell service.',
  [RestaurantMarketSegment.FoodTruck]: 'Food truck-konceptet är i grunden en mobil restaurang. Med begränsat utrymme är det vanligt att food trucks erbjuder en begränsad meny som fokuserar på en specifik typ av mat.',
  [RestaurantMarketSegment.FullService]: 'Full service restauranger är där casual dining möter fine dining och skapar den perfekta kombinationen. Atmosfären är mindre formell än fine dining men gästerna får fortfarande hela matupplevelsen.',
  [RestaurantMarketSegment.LunchRestaurant]: 'En lunchrestaurang serverar ofta enklare lunchrätter och är öppen under lunchtid.'
}

// Options for the queue time, 5..90 with a step of 5 minutes
const queueTimeOptions = range(5, 90, 5).map(minutes => ({ title: `${minutes} minuter`, value: `PT${minutes}M` }))
// Options for the order merge threshold, 1..8 with a step of 1 minute
const orderMergeThresholdOptions = [{ title: 'Avstängd', value: 'PT0S' }, ...range(1, 8).map(minutes => ({ title: `${minutes} minut${minutes === 1 ? '' : 'er'}`, value: `PT${minutes}M` }))]

function formatOrganizationNumber (organizationNumber: string): string {
  return organizationNumber.replace(/^(\d{6})(\d{4})$/, '$1-$2')
}

const RestaurantEdit: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantEdit'>()

  const { data } = useGetRestaurantDataQuery({ variables: { restaurantId } })
  const { data: superUserData } = useGetRestaurantSuperUserDataQuery({ skip: !(data?.me?.isSuperUser ?? false), variables: { restaurantId } })

  const [deleteRestaurant, { error: deleteRestaurantError, loading: deleteRestaurantLoading }] = useDeleteRestaurantMutation()
  const [updateOrganization, { error: updateOrganizationError, loading: loadingUpdateOrganization }] = useUpdateOrganizationMutation()
  const [updateRestaurant, { error, loading }] = useUpdateRestaurantMutation()

  const [showingDeleteConfirmation, setShowingDeleteConfirmation] = useState(false)
  const [showingTemporarilyDisabledConfirmation, setShowingTemporarilyDisabledConfirmation] = useState(false)

  const values = useMemo(() => {
    if (data?.restaurant == null) return undefined

    const restaurantWriteValues = schema.cast(data.restaurant, { stripUnknown: true })

    return {
      ...restaurantWriteValues,
      featuredDiscountCode: data.restaurant.featuredDiscount?.code ?? undefined,
      kitchenOrderQueueSorting: data.restaurant.kitchenOrderQueueSorting ?? KitchenOrderQueueSorting.OrderPlacedAt,
      orderMergeThreshold: data.restaurant.orderMergeThreshold ?? 'PT0S',
      organizationEmail: data.restaurant.organization?.email ?? undefined,
      organizationName: data.restaurant.organization?.name ?? undefined,
      ...(superUserData == null
        ? {}
        : {
          applicationFee: superUserData.restaurant?.applicationFeeCents == null ? undefined : formatCurrency(superUserData.restaurant.applicationFeeCents, { hideCurrency: true, minimumFractionDigits: 2 }),
          applicationFeePercentage: superUserData.restaurant?.applicationFeePercentage ?? undefined,
          isBetaTesting: superUserData.restaurant?.isBetaTesting ?? undefined,
          whiteLabelIds: data.restaurant.whiteLabels?.map(label => label.id) ?? undefined
        })
    }
  }, [data?.restaurant, superUserData?.restaurant])

  const form = useForm<Form>({
    criteriaMode: 'all',
    mode: 'onTouched',
    resolver: yupResolver(schema),
    values
  })

  const restaurantActive = form.watch().active ?? false
  const selectedEatInServicePolicy = form.watch().eatInServicePolicy

  // Restrict non-superusers from specifying loco as whiteLabel to generate links for
  const shortUrlWhiteLabelOptions = data?.restaurant?.whiteLabels?.filter(whiteLabel => (data.me?.isSuperUser ?? false) ? true : whiteLabel.id !== 'UUNTNJnja8') ?? []

  const handleCancel = useCallback(() => {
    // FIXME: Replace with RestaurantView when it exists
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('Login', {})
  }, [navigation])

  const handleOpenLink = useCallback((query: string = '') => () => {
    openLink(`${LOCO_ORDER_ENDPOINT}/restaurants/${restaurantId}${query}`)
  }, [])

  const handleOpenWhitelabelLink = useCallback((label: string, query: string = '') => () => {
    openLink(`${LOCO_ORDER_ENDPOINT}/labels/${label}/restaurants/${restaurantId}${query}`)
  }, [])

  const handleOpenLocationHelp = useCallback(() => {
    openLink('https://support.google.com/maps/answer/18539?hl=sv')
  }, [])

  const handleRemoveRestaurant = (): void => {
    deleteRestaurant({
      onCompleted: () => {
        setShowingDeleteConfirmation(false)
        navigation.navigate('Login', {})
      },
      variables: {
        restaurantId
      }
    }).catch(logError)
  }

  const handleShowLocation = useCallback(
    ignoreAsync(async () => {
      const isValid = await form.trigger(['location.latitude', 'location.longitude'])
      if (isValid) {
        const { location } = form.getValues()
        openLink(`http://maps.google.com/maps?q=${String(location?.latitude)},${String(location?.longitude)}`)
      }
    }),
    [form.getValues, form.trigger]
  )

  const handleUpdate = (patch: Form): void => {
    const { active, applicationFee, applicationFeePercentage, isBetaTesting, organizationEmail, organizationName, whiteLabelIds, ...restaurantWritePatch } = patch

    let orderDisplaySystemEnabled: undefined | false
    if ((data?.restaurant?.orderDisplaySystemEnabled ?? false) && (patch.eatInServicePolicy === EatInServicePolicy.CompleteAutomatically)) {
      orderDisplaySystemEnabled = false
    }

    updateRestaurant({
      variables: {
        restaurantId,
        patch: {
          ...(!(data?.me?.isSuperUser ?? false) ? {} : {
            active,
            applicationFeeCents: applicationFee == null ? undefined : parsePrice(applicationFee),
            applicationFeePercentage,
            isBetaTesting,
            whiteLabelIds
          }),
          ...restaurantWritePatch,
          orderDisplaySystemEnabled
        }
      }
    }).catch(logError)

    if (data?.restaurant?.organization == null || (organizationEmail === data.restaurant.organization.email && organizationName === data.restaurant.organization.name)) return

    updateOrganization({
      variables: {
        organizationId: data.restaurant.organization.id,
        patch: {
          email: organizationEmail,
          name: organizationName
        }
      }
    }).catch(logError)
  }

  const temporarilyDisabled = form.watch('temporarilyDisabled') ?? false

  if (data?.restaurant == null) {
    return <Layout loading title='Inställningar' />
  }

  const selectedMarketSegment = form.watch('marketSegment')

  const marketSegmentDescription = selectedMarketSegment == null ? null : marketSegmentDescriptionMap[selectedMarketSegment] as string | undefined

  return (
    <Layout hideTitle screenType={ScreenType.Form} title='Inställningar'>
      {!showingDeleteConfirmation ? null : (
        <VerificationDialog
          callToActionLabel='Bekräfta borttagning'
          errorMessage={deleteRestaurantError?.message}
          loading={deleteRestaurantLoading}
          onDelete={handleRemoveRestaurant}
          onDismiss={() => setShowingDeleteConfirmation(false)}
          open
          prompt='Är du säker på att du vill ta bort restaurangen? Restaurangen kommer att tas bort från alla Gastrogates tjänster, men datan kommer att finnas kvar i databasen tillsvidare.'
          title='Ta bort restaurang'
        />
      )}

      <FormContainer gap={5} title='Inställningar'>
        <Heading size='m'>Restaurang {data.restaurant.name}</Heading>
        {data.restaurant?.createdAt == null ? null : <Text color='#5f5f5f' size={14}>Skapad: {formatDateTime(Temporal.Instant.from(data.restaurant?.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}</Text>}

        <Spacer height={8} />

        <Select
          estimatedNumberOfCharacters={20}
          form={form}
          hint='Välj den typ av restaurang som bäst beskriver din verksamhet. Med denna information anpassar vi våra tjänster för att passa just din restaurang bättre.'
          name='marketSegment'
          options={marketSegmentOptions}
          title='Typ av restaurang'
        />

        <Text color={SECONDARY_TEXT_COLOR} paddingBottom={10} size={14}>{marketSegmentDescription}</Text>

        <Spacer height={16} />

        {!showingTemporarilyDisabledConfirmation ? null : (
          <VerificationDialog
            callToActionLabel='Bekräfta'
            errorMessage={get(form.formState.errors, 'temporarilyDisabled')?.message}
            loading={false}
            onDismiss={() => {
              setShowingTemporarilyDisabledConfirmation(false)
            }}
            onSubmit={() => {
              setShowingTemporarilyDisabledConfirmation(false)
              form.setValue('temporarilyDisabled', true, { shouldDirty: true })
            }}
            open
            prompt='Är du säker på att du vill inaktivera restaurangen? Om du avaktiverar det kommer dina gäster inte att kunna beställa från vår beställningsapp, men du kommer fortfarande att vara synlig.'
            title='Inaktivera restaurangen'
          />
        )}
        <CheckBox
          backgroundColor={temporarilyDisabled ? INACTIVE_BUTTON_COLOR : ACTIVE_BUTTON_COLOR}
          checked={!temporarilyDisabled}
          checkedColor='white'
          onPress={() => {
            if (temporarilyDisabled) {
              form.setValue('temporarilyDisabled', false, { shouldDirty: true })
            } else {
              setShowingTemporarilyDisabledConfirmation(true)
            }
          }}
          textColor='white'
          title='Aktivera beställningar'
        />

        <Spacer height={16} />

        {superUserData == null ? null : <Text size={19}>Aktiveringskod: {superUserData?.restaurant?.activationCode ?? ''}</Text>}

        <Spacer height={16} />

        <Text size={19}>Organisation</Text>
        {data.restaurant.organization?.createdAt == null ? null : <Text color='#5f5f5f' size={14}>Skapad: {formatDateTime(Temporal.Instant.from(data.restaurant.organization?.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}</Text>}

        <HStack alignItems='center' gap={10}>
          <Text size={16}>Loco Org. ID: {data.restaurant.organization?.id}</Text>

          <CopyIcon textToCopy={data.restaurant.organization?.id ?? ''} />
        </HStack>

        {data.restaurant.organization?.organizationNumber == null ? null : (
          <HStack alignItems='center' gap={10}>
            <Text size={16}>Org. nr.: {formatOrganizationNumber(data.restaurant.organization.organizationNumber)}</Text>

            <CopyIcon textToCopy={data.restaurant.organization.organizationNumber} />
          </HStack>
        )}

        <Spacer height={16} />

        <TextField
          estimatedNumberOfCharacters={20}
          form={form}
          hint='Namnet ska vara samma som registrerats hos bolagsverket'
          name='organizationName'
          title='Organisationsnamn'
        />

        <Spacer height={16} />

        <TextField
          form={form}
          name='organizationEmail'
          title='Epost'
        />

        <Spacer height={16} />

        <Text size={19}>Länkar</Text>
        <HStack wrap>
          <SecondaryButton onPress={handleOpenLink()} title='Startsida' />
          <SecondaryButton onPress={handleOpenLink('?menuType=EatIn')} title='Äta här' />
          <SecondaryButton onPress={handleOpenLink('?menuType=TakeAway')} title='Ta med' />
          <SecondaryButton onPress={handleOpenLink('?menuType=Delivery')} title='Leverans' />
        </HStack>

        {data.restaurant.whiteLabels?.map(label => (
          label.appName == null
            ? null
            : label.label === 'loco' ? null
            : (
              <React.Fragment key={label.id}>
                <Text size={19}>Applänkar till {label.appName}</Text>
                <HStack wrap>
                  <SecondaryButton onPress={handleOpenWhitelabelLink(label.label)} title='Startsida' />
                  <SecondaryButton onPress={handleOpenWhitelabelLink(label.label, '?menuType=EatIn')} title='Äta här' />
                  <SecondaryButton onPress={handleOpenWhitelabelLink(label.label, '?menuType=TakeAway')} title='Ta med' />
                  <SecondaryButton onPress={handleOpenWhitelabelLink(label.label, '?menuType=Delivery')} title='Leverans' />
                </HStack>
              </React.Fragment>
            )
        ))}

        <Spacer height={16} />

        <Text size={19}>Menystöd</Text>
        <HStack wrap>
          <CheckBox
            form={form}
            name='hasEatInMenu'
            title='Äta här'
          />
          <CheckBox
            form={form}
            name='hasTakeAwayMenu'
            title='Ta med'
          />
          <CheckBox
            form={form}
            name='hasDeliveryMenu'
            title='Leverans'
          />
        </HStack>

        <Spacer height={16} />

        {!(data?.me?.isSuperUser ?? false)
          ? null
          : data?.restaurant?.menuSystem === MenuSystem.Legacy
          ? (
            <VStack backgroundColor='#fefefe' gap={8} padding={12}>
              <Text weight='bold'>Ändring av menysystem är permanent.</Text>
              <Text>Alla restauranger skapade efter juli 2020 har automatiskt Neue.</Text>
              <Text>I det gamla systemet behövde man skapa en produkt per menytyp. I det nya systemet består en produkt istället av olika produktvarianter: en för varje menytyp.</Text>
              <Text>Alltså, när du ändrar från Legacy till Neue, var medveten om att din "Skomakarlåda, 69kr" kan existera som två separata produkter, en för 'Äta här' och en för 'Ta med'.</Text>
              <Text>Vi rekommenderar att du då går in på exempelvis produkten för 'Äta här', och kopierar över den till en produktvariant 'Ta med', och sedan tar bort din förra 'Ta med'-produkt.</Text>

              <Select
                form={form}
                name='menuSystem'
                options={[
                  { title: 'Legacy - ej möjligt att lägga till menytyper på befintliga produkter', value: MenuSystem.Legacy },
                  { title: 'Neue - möjligt att lägga till menytyper på befintliga produkter', value: MenuSystem.Neue }
                ]}
                title='Menysystem'
              />
            </VStack>
          )
          : <Text>Menysystem: Neue</Text>}

        <Spacer height={24} />

        <VStack gap={16}>
          <Text size={16} weight='bold'>Loco POS Kitchen</Text>

          <Select
            form={form}
            name='eatInServicePolicy'
            options={[
              { title: 'Automatisk klarmarkering av ordrarna', value: EatInServicePolicy.CompleteAutomatically },
              { title: 'Manuell klarmarkering av ordrarna', value: EatInServicePolicy.CompleteManually },
              { title: 'Gästen hämtar beställningen själv', value: EatInServicePolicy.DeliverToCashDesk }
            ]}
            title='Serveringspolicy'
          />

          {((data?.restaurant?.orderDisplaySystemEnabled ?? false) && (selectedEatInServicePolicy === EatInServicePolicy.CompleteAutomatically)) && (
            <>
              <HStack alignItems='center' backgroundColor={ACCENT_COLOR} borderRadius={8} gap={12} padding={12}>
                <MaterialIcons color='white' name='warning' size={24} />
                <Text color='white' size={20}>Om du ändrar den här inställningen till "Automatisk klarmarkering av ordrarna" så inaktiveras orderskärmen.</Text>
              </HStack>

              <Spacer height={32} />
            </>
          )}

          <Select
            form={form}
            name='kitchenOrderQueueSorting'
            options={[
              { title: 'Tid för beställning (default)', value: KitchenOrderQueueSorting.OrderPlacedAt },
              { title: 'Beräknad leveranstid', value: KitchenOrderQueueSorting.EstimatedDelivery }
            ]}
            title='Sortera inkomna ordrar i Loco POS Kitchen efter'
          />

          <CheckBox
            form={form}
            name='discountsEnabled'
            title='Visa fältet för rabattkoder i varukorgen'
          />

          <Select
            form={form}
            name='queueTime'
            options={queueTimeOptions}
            title='Kötid'
          />

          <Select
            form={form}
            name='orderMergeThreshold'
            options={orderMergeThresholdOptions}
            title='Ihopbuntning av bord - maximal väntetid'
          />

          <Text size={16} weight='bold'>Numreringsinterval av ordrar</Text>

          <HStack alignItems='center' wrap>
            <IntegerField
              form={form}
              name='humanOrderNumberRange.minimum'
              title='Minimum'
            />

            <Spacer width={8} />

            <Text paddingTop={20}>-</Text>

            <Spacer width={8} />

            <IntegerField
              form={form}
              name='humanOrderNumberRange.maximum'
              title='Maximum'
            />
          </HStack>

          <Spacer height={8} />

          <Text size={16} weight='bold'>Namn och adress</Text>

          <TextField
            form={form}
            name='name'
            title='Namn'
          />

          <TextField
            form={form}
            name='phoneNumber'
            title='Telefonnummer'
          />

          <TextField
            form={form}
            name='streetAddress'
            title='Gatuadress'
          />

          <TextField
            form={form}
            name='postalCode'
            title='Postnummer'
          />

          <TextField
            form={form}
            name='city'
            title='Stad'
          />

          <HStack>
            <VStack>
              <FloatField
                form={form}
                name='location.latitude'
                title='Latitud'
              />
            </VStack>

            <Spacer width={8} />

            <VStack>
              <FloatField
                form={form}
                name='location.longitude'
                title='Longitud'
              />
            </VStack>

            <VStack padding={8}>
              <MaterialCommunityIcons color={ACCENT_COLOR} name='map-marker-question' onPress={handleShowLocation} size={32} />
            </VStack>
          </HStack>

          <SecondaryButton onPress={handleOpenLocationHelp} title='Hur du hittar koordinaterna' />

          <TextField
            form={form}
            name='featuredDiscountCode'
            title='"Featured discount" rabattkod'
          />
        </VStack>

        {superUserData == null
          ? null
          : (
            <>
              <Spacer height={16} />

              <VStack>
                <MultiSelectCheckbox
                  form={form}
                  name='whiteLabelIds'
                  options={sortOn(superUserData?.whiteLabels?.edges.map(edge => ({ title: edge.node.appName ?? edge.node.label, value: edge.node.id })) ?? [], ['title', 'value'])}
                  title='Whitelabels'
                />
              </VStack>

              <Spacer height={16} />

              <CheckBox
                form={form}
                name='isBetaTesting'
                title='Lägg till restaurangen som betatestare'
              />

              <Spacer height={24} />

              <Text size={16} weight='bold'>Premiumfunktioner</Text>

              <Spacer height={16} />

              <Select
                form={form}
                name='orderSlipLayout'
                options={[{ title: 'Default', value: OrderSlipLayout.Default }, { title: 'Premium, 99 kr/mån', value: OrderSlipLayout.Premium }]}
                title='Köksbongslayout'
              />

              <Spacer height={16} />

              <CheckBox
                form={form}
                name='hasSkipSlipPrintOnPrinterQueuesAccess'
                title='Möjlighet att lägga till ignorerade bongskrivarköer per kassa, 99 kr/mån'
              />

              <Spacer height={16} />

              <CheckBox
                form={form}
                name='hasClientHeaderMessageAccess'
                title='Kan ge meddelanden högst upp i appen'
              />

              <Spacer height={16} />

              <CheckBox
                form={form}
                name='hasOrderDisplaySystemAccess'
                title='Kan använda orderskärmar'
              />

              <Spacer height={24} />

              <VStack gap={16}>
                <Text size={16} weight='bold'>Transaktionsavgifter (inkl. 25% moms)</Text>

                <MoneyField
                  estimatedNumberOfCharacters={3}
                  form={form}
                  name='applicationFee'
                  title='Pris per transaktion för Stripe'
                />

                <TextField
                  estimatedNumberOfCharacters={3}
                  form={form}
                  name='applicationFeePercentage'
                  suffix='%'
                  title='Procent per transaktion för Stripe'
                />
              </VStack>

              <Spacer height={24} />

              <Text size={16} weight='bold'>Restaurangens synlighet</Text>

              <Spacer height={8} />

              <View style={restaurantActive ? undefined : { backgroundColor: '#efefef', borderRadius: 8, padding: 16 }}>
                {restaurantActive
                  ? null
                  : <Warning message='Restaurangen är inte markerad som aktiv och syns således inte i Loco & whitelabel app och köp kan heller inte göras i LocoPOS Order.' paddingBottom={8} />}

                <CheckBox
                  checkedColor={ACTIVE_BUTTON_COLOR}
                  form={form}
                  name='active'
                  title='Restaurangen är aktiv i hela Gastrogates ekosystem'
                  uncheckedColor={INACTIVE_BUTTON_COLOR}
                />
              </View>
            </>
          )}

        <Spacer height={24} />

        <SubmitFormButtons
          disableCancelButton={loading}
          disableDeleteButton={deleteRestaurantLoading}
          disableSaveButton={!form.formState.isDirty || loading}
          error={error ?? updateOrganizationError}
          onCancel={handleCancel}
          onDelete={superUserData == null ? undefined : () => setShowingDeleteConfirmation(true)}
          onSave={ignoreAsync(form.handleSubmit(handleUpdate))}
          saving={loading || loadingUpdateOrganization}
          titleForDelete='Ta bort restaurang'
          titleForSubmit='Uppdatera'
        />
      </FormContainer>

      {shortUrlWhiteLabelOptions.length < 1 ? null : (
        <>
          <ShortUrlForm whiteLabels={shortUrlWhiteLabelOptions} />
          <RestaurantDeliveryLocationShortUrlForm restaurantId={restaurantId} restaurantName={data?.restaurant.name ?? ''} whiteLabels={shortUrlWhiteLabelOptions} />
        </>
      )}
    </Layout>
  )
}

export default RestaurantEdit

import React from 'react'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'

import { type FullAddonProductFragment } from '../../../types/graphql'
import formatCurrency from '../../util/formatCurrency'
import Badge from '../Badge'

interface Props {
  product: FullAddonProductFragment
}

export const AddonProductCard: React.FC<Props> = ({ product }) => {
  const addonPrice = product?.properties?.price ?? 0
  const addonIsActive = product?.properties?.isActive ?? false

  return (
    <HStack grow={1} paddingHorizontal={15} wrap>
      <HStack alignItems='center' basis={1} grow={3} minWidth={200}>
        <Badge status={addonIsActive ? 'success' : 'error'} />

        <Spacer width={12} />

        <Text grow={1} size={16}>
          {product.name}
        </Text>
      </HStack>

      <Text color='#00000077' paddingLeft={15} size={14} width={54}>
        {(product.properties?.hasLegacyFormat ?? false) ? '[OLD]' : ''}
      </Text>

      <Text basis={1} color='#00000077' grow={3} minWidth={200} paddingLeft={15} size={14}>
        {product.properties?.reportGroup?.name}, {product.properties?.reportGroup?.vatRate ?? '-'}%
      </Text>

      <Text paddingLeft={15} size={14}>
        {formatCurrency(addonPrice)}
      </Text>
    </HStack>
  )
}

import React, { type ReactNode } from 'react'
import { type StackProps, Text, VStack } from 'react-stacked'

interface CardProps extends Pick<StackProps, 'alignSelf' | 'gap' | 'height' | 'width'> {
  children: ReactNode
  title?: string
}

const Card: React.FC<CardProps> = ({ children, title, ...props }) => (
  <VStack backgroundColor='white' borderColor='#eee' borderStyle='solid' borderWidth={2} padding={12} {...props}>
    {title == null ? null : <Text align='center' paddingBottom={16} size={24} weight='bold'>{title}</Text>}

    {children}
  </VStack>
)

export default Card

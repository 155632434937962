import gql from 'graphql-tag'
import React, { useState } from 'react'

import { GetWhiteLabelUserListDataDocument, type WhiteLabelUserInput, useDeleteWhiteLabelUserMutation, useGetWhiteLabelUserEditDataQuery, useResendWhiteLabelUserInviteMutation, useUpdateWhiteLabelUserMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import WhiteLabelUserForm from '../components/WhiteLabelUserForm'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullWhiteLabelUserDetails on WhiteLabelUser {
    id

    email
    hasDiscountAccess
    hasGuestRegistryAccess
    hasMarketingPushNotificationAccess
    hasSettingsAccess
    hasWhiteLabelUserAccess
    isOwner
    name
    userSignupCompleted
  }

  query GetWhiteLabelUserEditData($whiteLabelId: ID!, $userId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      isOwner: hasAccess(access:Write)

      user(id: $userId) {
        ...FullWhiteLabelUserDetails
      }
    }
  }

  mutation UpdateWhiteLabelUser($whiteLabelId: ID!, $whiteLabelUserId: ID!, $patch: WhiteLabelUserPatch!) {
    updateWhiteLabelUser(whiteLabelId: $whiteLabelId, whiteLabelUserId: $whiteLabelUserId, patch: $patch) {
      ...FullWhiteLabelUserDetails
    }
  }

  mutation DeleteWhiteLabelUser($whiteLabelId: ID!, $whiteLabelUserId: ID!) {
    deleteWhiteLabelUser(whiteLabelId: $whiteLabelId, whiteLabelUserId: $whiteLabelUserId) {
      id
    }
  }

  mutation ResendWhiteLabelUserInvite($whiteLabelId: ID!, $whiteLabelUserId: ID!) {
    resendWhiteLabelUserInvite(whiteLabelId: $whiteLabelId, whiteLabelUserId: $whiteLabelUserId) {
      id
    }
  }
`

const WhiteLabelUserEdit: React.FC = () => {
  const [navigation, { whiteLabelId, whiteLabelUserId }] = useNavigation<'WhiteLabelUserEdit'>()

  const { data, loading } = useGetWhiteLabelUserEditDataQuery({ variables: { whiteLabelId, userId: whiteLabelUserId } })

  const [deleteWhiteLabelUser, { loading: deleting, error: deleteError }] = useDeleteWhiteLabelUserMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('WhiteLabelUserList', { whiteLabelId }),
    onError: (error) => logAndShowError(error),
    refetchQueries: [{ query: GetWhiteLabelUserListDataDocument, variables: { whiteLabelId } }],
    variables: { whiteLabelId, whiteLabelUserId }
  })

  const [updateWhiteLabelUser, { error: saveError, loading: saving }] = useUpdateWhiteLabelUserMutation({
    onCompleted: () => navigation.navigate('WhiteLabelUserList', { whiteLabelId }),
    onError: (error) => logAndShowError(error)
  })

  const [resendWhiteLabelUserInvite] = useResendWhiteLabelUserInviteMutation({
    onCompleted: () => showAlert('Inbjudan skickad', 'Inbjudan har skickats till administratören.'),
    onError: (error) => logAndShowError(error),
    variables: { whiteLabelId, whiteLabelUserId }
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleCancel = (): void => {
    navigation.navigate('WhiteLabelUserList', { whiteLabelId })
  }

  const handleDelete = ignoreAsync(async () => {
    await deleteWhiteLabelUser()
  })

  const handleResendInvite = ignoreAsync(async () => {
    await resendWhiteLabelUserInvite()
  })

  const handleSubmit = ignoreAsync(async (formData: WhiteLabelUserInput): Promise<void> => {
    const { email: _, ...whiteLabelUserData } = formData

    await updateWhiteLabelUser({
      variables: {
        whiteLabelId,
        whiteLabelUserId,
        patch: whiteLabelUserData
      }
    })
  })

  return (
    <Layout
      breadcrumbs={[{ link: ['WhiteLabelUserList', { whiteLabelId }], title: 'Administratörer' }]}
      loading={loading}
      screenType={ScreenType.Form}
      title={data?.whiteLabel?.user?.name}
    >
      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt='Är du säker på att du vill ta bort administratören?'
        title='Radera Administratören'
      />

      <FormContainer gap={20}>
        <WhiteLabelUserForm
          canUpdateOwners={data?.whiteLabel?.isOwner ?? false}
          error={saveError}
          initialValues={data?.whiteLabel?.user}
          onCancel={handleCancel}
          onDelete={() => setShowDeleteDialog(true)}
          onResendInvite={handleResendInvite}
          onSubmit={handleSubmit}
          saving={saving}
        />
      </FormContainer>
    </Layout>
  )
}

export default WhiteLabelUserEdit

import { type ProductOpeningHoursFragment, type WeekDayTimeInput } from '../../types/graphql'

import nextDay from './getNextDay'
import { periodEndingToDayTime } from './getTime'

type OpeningPeriodsClose = NonNullable<NonNullable<ProductOpeningHoursFragment['periods']>[number]['close']>
type OpeningPeriodsOpen = NonNullable<NonNullable<ProductOpeningHoursFragment['periods']>[number]['open']>

export default function getTimeBetween (open: OpeningPeriodsOpen | WeekDayTimeInput, close: OpeningPeriodsClose | WeekDayTimeInput, input: number): number {
  if (open.day !== close.day) {
    return getTimeBetween(
      { ...open, day: nextDay(open.day), time: '00:00:00' },
      close,
      input + (
        (periodEndingToDayTime({ ...open, time: '24:00:00' }) ?? 0) -
        (periodEndingToDayTime(open) ?? 0)
      )
    )
  }

  return (
    input + (
      (periodEndingToDayTime(close) ?? 0) -
      (periodEndingToDayTime(open) ?? 0)
    )
  )
}

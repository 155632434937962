import React, { type ReactNode } from 'react'
import { HStack } from 'react-stacked'

interface ListItemWrapperProps {
  backgroundColor?: string
  children: ReactNode
}

const ListItemWrapper: React.FC<ListItemWrapperProps> = ({ backgroundColor, children }) => {
  return (
    <HStack backgroundColor={backgroundColor ?? '#fefefe'} borderRadius={5} grow={1} justifyContent='space-between' paddingHorizontal={8} paddingVertical={12}>
      {children}
    </HStack>
  )
}

export default ListItemWrapper

import { type WeekDay } from '../../types/graphql'

import nextDay from './getNextDay'

export default function getDaysBetween (open: WeekDay, close: WeekDay, input: WeekDay[]): WeekDay[] {
  if (nextDay(open) !== close) {
    return getDaysBetween(nextDay(open), close, [...input, open])
  }

  return [...input, open, close]
}

import { type ApolloError } from '@apollo/client'
import React from 'react'
import { useForm } from 'react-hook-form'
import { HStack } from 'react-stacked'

import { type FullWhiteLabelUserDetailsFragment, type WhiteLabelUserInput } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import { RedoButton } from './Buttons'
import InfoIcon from './InfoIcon'
import SubmitFormButtons from './SubmitFormButtons'
import { TextField } from './TextField'
import Warning from './Warning'
import { CheckBox } from './fields'

const globalPermissions = [
  { key: 'hasGuestRegistryAccess', label: 'Lojalitet', tooltip: 'Kommer åt sidan för att redigera och hantera lojalitetsgrupper.' },
  { key: 'hasDiscountAccess', label: 'Rabattkoder', tooltip: 'Kommer åt sidan för att redigera och lägga till nya rabattkoder, samt koppla dem till grupper.' },
  { key: 'hasMarketingPushNotificationAccess', label: 'Pushnotiser', tooltip: 'Kommer åt sidan för att skicka pushnotiser till användare.' },
  { key: 'hasWhiteLabelUserAccess', label: 'Administratörer', tooltip: 'Kommer åt sidan för att redigera och lägga till nya administratörer.' },
  { key: 'hasSettingsAccess', label: 'Inställningar', tooltip: 'Kommer åt sidan för att redigera inställningar för appen.' }
] as const

interface WhiteLabelUserFormProps {
  canUpdateOwners: boolean
  deleting?: boolean
  error: ApolloError | null | undefined
  initialValues?: FullWhiteLabelUserDetailsFragment | null
  onCancel: () => void
  onDelete?: () => void
  onResendInvite?: () => void
  onSubmit: (input: WhiteLabelUserInput) => void
  saving: boolean
}

const schema: ObjectSchema<WhiteLabelUserInput> = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email is required'),
  hasDiscountAccess: yup.boolean(),
  hasGuestRegistryAccess: yup.boolean(),
  hasMarketingPushNotificationAccess: yup.boolean(),
  hasSettingsAccess: yup.boolean(),
  hasWhiteLabelUserAccess: yup.boolean(),
  isOwner: yup.boolean(),
  name: yup.string().required('Name is required')
})

const WhiteLabelUserForm: React.FC<WhiteLabelUserFormProps> = ({
  canUpdateOwners,
  deleting,
  error,
  initialValues,
  onCancel,
  onDelete,
  onResendInvite,
  onSubmit,
  saving
}) => {
  const form = useForm<WhiteLabelUserInput>({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    values: initialValues == null ? undefined : {
      email: initialValues.email ?? '',
      hasDiscountAccess: initialValues.hasDiscountAccess ?? false,
      hasGuestRegistryAccess: initialValues.hasGuestRegistryAccess ?? false,
      hasMarketingPushNotificationAccess: initialValues.hasMarketingPushNotificationAccess ?? false,
      hasSettingsAccess: initialValues.hasSettingsAccess ?? false,
      hasWhiteLabelUserAccess: initialValues.hasWhiteLabelUserAccess ?? false,
      isOwner: initialValues.isOwner ?? false,
      name: initialValues.name ?? ''
    }
  })

  const permissions = form.watch()

  return (
    <>
      <TextField
        autoFocus
        estimatedNumberOfCharacters={20}
        form={form}
        name='name'
        title='Namn'
      />
      <TextField
        estimatedNumberOfCharacters={30}
        form={form}
        hint='E-postadress kan endast ändras av användaren själv'
        name='email'
        readOnly={Boolean(initialValues)}
        title='Email'
      />

      {!canUpdateOwners ? null : (
        <HStack alignItems='center'>
          <CheckBox
            disabled={!canUpdateOwners}
            form={form}
            name='isOwner'
            title='Ägare'
          />

          <InfoIcon content='Fullständiga rättigheter. Varning: Kan ta bort andra ägare.' />
        </HStack>
      )}

      {(permissions.isOwner ?? false) ? null : (
        globalPermissions.map((permission) => (
          <HStack key={permission.key} alignItems='center'>
            <CheckBox
              form={form}
              name={permission.key}
              title={permission.label}
            />

            <InfoIcon content={permission.tooltip} />
          </HStack>
        ))
      )}

      {(initialValues?.userSignupCompleted === false && onResendInvite != null) && (
        <HStack justifyContent='end'>
          <RedoButton
            onPress={onResendInvite}
            title='Skicka e-post igen'
          />
        </HStack>
      )}

      {error == null ? null : <Warning message={error.message} />}

      <SubmitFormButtons
        deleting={deleting}
        disableSaveButton={saving}
        onCancel={onCancel}
        onDelete={!canUpdateOwners ? undefined : onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSubmit))}
        saving={saving}
      />
    </>
  )
}

export default WhiteLabelUserForm

import { Image } from 'react-native'
import { HStack, Text, VStack } from 'react-stacked'

import { ACCENT_COLOR } from '../lib/color'
import showAlert from '../util/showAlert'

import { AddButton, SecondaryButton } from './Buttons'
import Link from './Link'

interface BusinessBoosterItemProps {
  description: string
  iconUrl: string
  isActive: boolean
  onActivationPress?: () => void
  price: string
  productPageUrl?: string
  readMoreUrl: string
  title: string
}

const BusinessBoosterItem: React.FC<BusinessBoosterItemProps> = ({ description, iconUrl, isActive, onActivationPress, price, readMoreUrl, title }) => {
  const handlePress = (): void => {
    if (onActivationPress == null) {
      showAlert('Behörighet saknas', 'Du saknar behörighet för att aktivera denna tjänst. Kontakta din systemadministratör för att få tillgång.')

      return
    }

    onActivationPress()
  }

  return (
    <HStack alignItems='start' basis={1} borderColor='#bbb' borderRadius={3} borderStyle='solid' borderWidth={0.1} gap={12} padding={12} width='100%'>
      <VStack paddingTop={4}>
        <Image source={{ uri: iconUrl }} style={{ borderRadius: 4, height: 40, width: 40 }} />
      </VStack>

      <VStack alignItems='start' gap={4} grow={1} justifyContent='start'>
        <Text color={ACCENT_COLOR} size={16} weight='bold'>{title}</Text>

        <Text color='#555' size={12}>{description}</Text>

        <Link title='Läs mer' url={readMoreUrl} />

        <HStack alignItems='center' justifyContent='space-between' width='100%'>
          <Text color={ACCENT_COLOR} size={16} weight='300'>{price}</Text>

          {isActive ? <SecondaryButton onPress={handlePress} title={`Gå till ${title}`} /> : <AddButton onPress={handlePress} title={`Lägg till ${title}`} />}
        </HStack>
      </VStack>
    </HStack>
  )
}

export default BusinessBoosterItem

import React, { type ReactNode } from 'react'
import { VStack } from 'react-stacked'

import Heading from './atoms/Heading'

interface ListContainerProps {
  children: ReactNode
  maxWidth?: number
  title?: string | null
}

const ListContainer: React.FC<ListContainerProps> = ({ children, maxWidth, title }) => {
  return (
    <VStack borderRadius={5} gap={16} grow={1} maxWidth={maxWidth ?? 1024}>
      {title == null ? null : (
        <Heading size='l'>
          {title}
        </Heading>
      )}

      {children}
    </VStack>
  )
}

export default ListContainer

import gql from 'graphql-tag'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type RequestResetUserPasswordEmailMutationVariables, useRequestResetUserPasswordEmailMutation } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import { TextField } from '../components/fields'
import yup, { yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

gql`
  mutation RequestResetUserPasswordEmail($email: EmailAddress!) {
    requestResetUserPasswordEmail(
      email: $email
    )
  }
`

const userSchema = yup.object({
  email: yup.string().email('E-postadressen du angivit är inte giltig').required('Var vänlig ange din e-postadress')
})

const PasswordReset: React.FC = () => {
  const [navigation] = useNavigation<'PasswordReset'>()

  const [requestResetUserPasswordEmail] = useRequestResetUserPasswordEmailMutation()

  const form = useForm<RequestResetUserPasswordEmailMutationVariables>({
    criteriaMode: 'all',
    mode: 'onBlur',
    resolver: yupResolver(userSchema)
  })

  const [emailSentTo, setEmailSentTo] = useState<string | null>(null)

  const handleSave = (): void => {
    requestResetUserPasswordEmail({ variables: { ...form.getValues() } }).catch(logError)
    setEmailSentTo(form.getValues().email)
  }

  return (
    <VStack backgroundColor='black' grow={1}>
      <Image source={require('../../assets/icon.png')} style={{ height: normalize(75, 90), width: normalize(75, 90) }} />

      <HStack alignItems='center' justifyContent='center'>
        <VStack backgroundColor='white' grow={1} maxWidth={500} padding={24}>
          <HStack>
            <SecondaryButton
              icon='navigate-before'
              onPress={() => navigation.navigate('Login', {})}
              title='Tillbaka'
            />
          </HStack>

          <Spacer height={16} />

          <Text size={24}>Återställ lösenord</Text>

          <Spacer height={24} />

          {emailSentTo != null
            ? <Text>Ett e-postmeddelande med information om hur du går tillväga för att återställa ditt lösenord har skickats till {emailSentTo}</Text>
            : (
              <>
                <TextField
                  form={form}
                  name='email'
                  title='Email'
                />

                <Spacer height={24} />

                <PrimaryButton
                  icon='account-circle'
                  onPress={ignoreAsync(form.handleSubmit(handleSave))}
                  title='Återställ lösenord'
                />
              </>
            )}
        </VStack>
      </HStack>
    </VStack>
  )
}

export default PasswordReset

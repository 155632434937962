import React from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { HStack, Text, VStack } from 'react-stacked'
import unimplemented from 'ts-unimplemented'
import unreachable from 'ts-unreachable'

import { type MenuProductPropertiesInput as UpstreamMenuProductPropertiesInput, MenuType } from '../../types/graphql'

import { AddButton, CopyToButton, DeleteButton } from './Buttons'
import { ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR } from './PanicButton'
import EditProductImage from './RestaurantMenu/EditProductImage'
import { titleFromMenuType } from './RestaurantMenu/MenuTypeToggleButton'
import Select from './Select'
import { TextField } from './TextField'
import { MoneyField, MultiSelectCheckbox } from './fields'
import CheckBox from './fields/CheckBox'

interface MenuProductPropertiesInput extends Omit<UpstreamMenuProductPropertiesInput, 'price'> {
  price?: string | null
}
export interface ProductSchema {
  defaultCourseId?: string | null
  delivery?: MenuProductPropertiesInput | null
  eatIn?: MenuProductPropertiesInput | null
  hasQuantityAvailable: boolean
  name: string
  quantityAvailable?: number | null
  takeAway?: MenuProductPropertiesInput | null
}

interface ProductVariantFormProps<T extends string | number> {
  alternativeGroupOptions: Array<{ value: T, title: string }>
  copyToButtons?: MenuType[]
  form: UseFormReturn<ProductSchema>
  openingHourOptions: Array<{ value: string | null, title: string }>
  printers: Array<{ value: string, title: string }>
  reportGroupOptions: Array<{ value: string | null, title: string }>
  type: MenuType
}

type MenuTypeKey = 'delivery' | 'eatIn' | 'takeAway'

function keyPrefixFromMenuType (menuType: MenuType): 'delivery' | 'eatIn' | 'takeAway' {
  switch (menuType) {
    case MenuType.Delivery:
      return 'delivery'
    case MenuType.EatIn:
      return 'eatIn'
    case MenuType.GiftCard:
      return unimplemented()
    case MenuType.TakeAway:
      return 'takeAway'
    default:
      unreachable(menuType)
  }
}

export default function ProductVariantForm<T extends string | number> ({ alternativeGroupOptions, copyToButtons, form, openingHourOptions, printers, reportGroupOptions, type }: ProductVariantFormProps<T>): JSX.Element {
  const typeKey = keyPrefixFromMenuType(type)
  const isActive: boolean = form.watch(`${typeKey}.isActive`) ?? false
  const priceIsOpen: boolean = form.watch(`${typeKey}.priceIsOpen`) ?? false

  const typeState = form.watch(typeKey)
  const primaryPrinterIds = form.watch(`${typeKey}.printAt`) ?? []
  const secondaryPrinterIds = form.watch(`${typeKey}.secondaryPrinterIds`) ?? []

  const primaryPrinterOptions = printers.filter((printer) => {
    return primaryPrinterIds.includes(printer.value) || !secondaryPrinterIds.includes(printer.value)
  })

  const secondaryPrinterOptions = printers.filter((printer) => {
    return secondaryPrinterIds.includes(printer.value) || !primaryPrinterIds.includes(printer.value)
  })

  const copyTo = (type: MenuType): void => {
    const copyTypeKey = keyPrefixFromMenuType(type)
    form.reset({
      ...form.getValues(),
      [copyTypeKey]: {
        ...form.getValues()[typeKey],
        isActive: true
      }
    })
  }

  const addTypeToProduct = (typeKey: MenuTypeKey): void => {
    form.reset({
      ...form.getValues(),
      [typeKey]: {
        isActive: true
      }
    })
  }

  const handleAdd = (): void => {
    addTypeToProduct(typeKey)
  }

  const handleDelete = (): void => {
    form.reset({
      ...form.getValues(),
      [typeKey]: null
    })
  }

  return (
    <HStack grow={1} padding={8}>
      <VStack backgroundColor='#EEE' gap={24} grow={1} maxWidth={340} padding={10} width={220}>
        <HStack alignItems='center' justifyContent='space-between'>
          <Text size={20}>{titleFromMenuType(type)}</Text>

          <VStack>
            {form.getValues()[typeKey] == null
              ? (
                <AddButton
                  onPress={handleAdd}
                  title='Lägg till'
                />
              )
              : (
                <DeleteButton
                  onPress={handleDelete}
                  title='Ta bort'
                />
              )}
          </VStack>
        </HStack>

        {typeState == null ? null : (
          <VStack gap={16}>
            <VStack>
              <CheckBox
                backgroundColor={isActive ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR}
                checkedColor='white'
                form={form}
                name={`${typeKey}.isActive`}
                textColor='white'
                title={isActive ? `${titleFromMenuType(type)} är synlig i Loco` : `${titleFromMenuType(type)} är ej synlig i Loco`}
              />
            </VStack>

            <EditProductImage
              form={form}
              name={`${typeKey}.imageUrl`}
            />

            <TextField
              form={form}
              name={`${typeKey}.description`}
              title='Beskrivning'
            />

            <HStack alignItems='end' justifyContent='space-between' wrap>
              <CheckBox
                backgroundColor='none'
                form={form}
                name={`${typeKey}.priceIsOpen`}
                title='Öppet pris'
              />

              {priceIsOpen
                ? null
                : (
                  <MoneyField
                    estimatedNumberOfCharacters={5}
                    form={form}
                    name={`${typeKey}.price`}
                    title='Pris'
                  />
                )}
            </HStack>

            <Select
              form={form}
              name={`${typeKey}.reportGroupId`}
              options={reportGroupOptions}
              title='Rapportgrupp'
            />

            <Select
              form={form}
              name={`${typeKey}.openingHoursId`}
              options={openingHourOptions}
              title='Öppettid för produkt'
            />

            <VStack gap={12}>
              <MultiSelectCheckbox
                form={form}
                name={`${typeKey}.alternativeGroupIds`}
                options={alternativeGroupOptions}
                title='Alternativ för produkt'
              />

              <MultiSelectCheckbox
                form={form}
                name={`${typeKey}.printAt`}
                options={primaryPrinterOptions}
                title='Skrivare för produkt'
              />

              <MultiSelectCheckbox
                form={form}
                name={`${typeKey}.secondaryPrinterIds`}
                options={secondaryPrinterOptions}
                title='Sekundärskrivare för produkt'
              />
            </VStack>

            {copyToButtons?.map(type => (
              <CopyToButton
                key={type}
                onPress={() => copyTo(type)}
                title={`Kopiera till ${titleFromMenuType(type)}`}
              />
            ))}
          </VStack>
        )}
      </VStack>
    </HStack>
  )
}

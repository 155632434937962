import { NetworkStatus } from '@apollo/client'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, VStack } from 'react-stacked'
import { type ObjectSchema } from 'yup'

import { InvitationStatus, useGetWhiteLabelGuestGroupCustomerRegisterDataQuery } from '../../types/graphql'
import { AddButton, PrimaryButton, SecondaryButton } from '../components/Buttons'
import { DataColumn, DataTable } from '../components/DataTable'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import Select from '../components/Select'
import { TextField } from '../components/TextField'
import yup, { yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetWhiteLabelGuestGroupCustomerRegisterData($whiteLabelId: ID!, $first: Int!, $cursor: String, $filter: WhiteLabelGuestFilter) {
    whiteLabel(id: $whiteLabelId) {
      id

      guests(first: $first, after: $cursor, filter: $filter) {
        edges {
          node {
            id

            amountSpent
            createdAt
            email
            invitationStatus
            name
            orderCount

            group {
              id

              name
            }
          }
        }

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

interface FormInput {
  email?: string | null
}

const schema: ObjectSchema<FormInput> = yup.object().shape({
  email: yup.string().email().nullable()
})

interface GuestList {
  id: string
  amountSpent?: number | null
  createdAt?: number | null
  email?: string | null
  guestGroupId?: string | null
  guestGroupName?: string | null
  invitationStatus?: string | null
  name?: string | null
  orderCount?: number | null
}

const WhiteLabelGuestGroupCustomerRegister: React.FC = () => {
  const [navigation, { whiteLabelId }] = useNavigation<'WhiteLabelGuestGroupCustomerRegister'>()

  const [filter, setFilter] = useState<FormInput | null>(null)
  const [numberOfMembersPerPage, setNumberOfMembersPerPage] = useState(25)

  const { data, fetchMore, networkStatus } = useGetWhiteLabelGuestGroupCustomerRegisterDataQuery({ notifyOnNetworkStatusChange: true, variables: { whiteLabelId, first: numberOfMembersPerPage, filter } })

  const form = useForm<FormInput>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const loading = networkStatus === NetworkStatus.loading
  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  const memberRows = data?.whiteLabel?.guests?.edges.map(({ node }) => ({
    id: node.id,
    amountSpent: node.amountSpent ?? 0,
    createdAt: node.createdAt == null ? undefined : new Date(node.createdAt).getTime(),
    email: node.email,
    guestGroupId: node.group?.id,
    guestGroupName: node.group?.name,
    invitationStatus: node.invitationStatus === InvitationStatus.Accepted ? '✔️' : '⏱️',
    name: node.name,
    orderCount: node.orderCount ?? 0
  })) ?? []

  const handleFetchMore = (): void => {
    fetchMore({
      variables: { cursor: data?.whiteLabel?.guests?.pageInfo?.endCursor, whiteLabelId },

      updateQuery (previousResult, { fetchMoreResult }) {
        if (previousResult.whiteLabel?.guests == null) {
          return previousResult
        }

        const newEdges = fetchMoreResult?.whiteLabel?.guests?.edges

        if (newEdges == null || newEdges.length === 0) {
          return previousResult
        }

        return dotProp.set(previousResult, 'whiteLabel.guests', {
          __typename: previousResult.whiteLabel.guests.__typename,
          edges: [...previousResult.whiteLabel.guests.edges, ...newEdges],
          pageInfo: fetchMoreResult?.whiteLabel?.guests?.pageInfo
        })
      }
    }).catch(logError)
  }

  const handleSearch = (input: FormInput): void => {
    if (input.email == null || input.email.trim() === '') return setFilter(null)

    setFilter({ ...input })
  }

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' },
    { link: ['WhiteLabelGuestGroupList', { whiteLabelId }], title: 'Lojalitet' }
  ], [whiteLabelId])

  return (
    <Layout
      breadcrumbs={breadcrumbs}
      loading={loading && filter == null}
      screenType={ScreenType.List}
      title='Kundregister'
    >
      <ListContainer>
        <AddButton
          onPress={() => navigation.push('WhiteLabelGuestGroupMemberCreate', { whiteLabelId, guestGroupId: undefined })}
          title='Lägg till medlem'
        />

        <HStack justifyContent='space-between' wrap>
          <HStack alignItems='end' gap={8} justifyContent='center'>
            <TextField
              estimatedNumberOfCharacters={30}
              form={form}
              name='email'
              onSubmitEditing={ignoreAsync(form.handleSubmit(handleSearch))}
              title='Sök e-post'
            />

            <VStack alignItems='center' justifyContent='center' padding={3}>
              <PrimaryButton
                loading={loading && filter != null}
                onPress={ignoreAsync(form.handleSubmit(handleSearch))}
                title='Sök'
              />
            </VStack>
          </HStack>

          <Select
            estimatedNumberOfCharacters={4}
            onChange={(input) => {
              const inputAsNumber = parseInt(input)
              if (isNaN(inputAsNumber)) return

              setNumberOfMembersPerPage(parseInt(input))
            }}
            options={[
              { title: '10', value: '10' },
              { title: '25', value: '25' },
              { title: '100', value: '100' },
              { title: '1000', value: '1000' },
              { title: 'Alla', value: '1000000' }
            ]}
            title='Resultat'
            value={numberOfMembersPerPage.toString()}
          />
        </HStack>

        <Spacer height={8} />

        <DataTable
          csvFileName='report-group-sales.csv'
          data={memberRows}
          hideSummaryRow
          initialSort='-createdAt'
          onDataRowPress={({ id, guestGroupId }) => {
            // guestGroupId is nullable here, but it shouldn't normally happen and would indicate an issue.
            // We allow assigning the guest to a group by allowing access to the member view and alert sentry..
            if (guestGroupId == null) {
              logError(new Error('Guest group is null in WhiteLabelGuestGroupCustomerRegister.tsx onDataRowPress.'))
            }

            navigation.navigate('WhiteLabelGuestGroupMemberView', { whiteLabelId, guestGroupId: guestGroupId ?? '', memberId: id })
          }}
          title='Lista över registrerade kunder'
        >
          <DataColumn<GuestList> field='email' grow={2} title='E-post' type='string' />
          <DataColumn<GuestList> field='name' grow={1} title='Namn' type='string' />
          <DataColumn<GuestList> field='invitationStatus' grow={0.2} title='' type='string' />
          <DataColumn<GuestList> field='guestGroupName' grow={1.1} title='Gruppnamn' type='string' />
          <DataColumn<GuestList> field='createdAt' hidden title='Skapad' type='string' />
          <DataColumn<GuestList> field='orderCount' grow={0.4} title='Köp' type='number' />
          <DataColumn<GuestList> field='amountSpent' grow={0.6} maximumFractionDigits={0} title='Spenderat' type='currency' />
        </DataTable>

        <Spacer height={24} />

        {!(data?.whiteLabel?.guests?.pageInfo.hasNextPage ?? false) ? null : (
          <SecondaryButton
            loading={fetchingMore}
            onPress={handleFetchMore}
            title='Visa fler'
          />
        )}
      </ListContainer>
    </Layout>
  )
}

export default WhiteLabelGuestGroupCustomerRegister

import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useDeleteDeliveryCostAreaMutation, useDeleteDeliveryTimeAreaMutation, useGetDeliveryLocoEditDataQuery, useUpdateDeliveryLocoEditMutation, useUpdateDeliveryLocoEnabledMutation } from '../../types/graphql'
import AddonProductList from '../components/AddonProductList'
import { AddButton, PrimaryButton, SecondaryButton } from '../components/Buttons'
import DeliveryAreaListItem from '../components/DeliveryAreaListItem'
import ExtraDeliveryTimeField, { type ExtraDeliveryTime } from '../components/ExtraDeliveryTimeField'
import FormContainer from '../components/FormContainer'
import GeoJsonForm, { type AreaType } from '../components/GeoJsonForm'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import Link from '../components/Link'
import ListContainer from '../components/ListContainer'
import { PanicButton } from '../components/PanicButton'
import SetupContainer from '../components/SetupContainer'
import VerificationDialog from '../components/VerificationDialog'
import Warning from '../components/Warning'
import { ACCENT_COLOR } from '../lib/color'
import { durationToMinutes } from '../util/durationToMinutes'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullDeliveryCostArea on DeliveryCostArea {
    id

    name

    geometry {
      latitude
      longitude
    }

    product {
      id

      name
      properties {
        price
      }
    }
  }

  fragment FullDeliveryTimeArea on DeliveryTimeArea {
    id

    name
    time

    geometry {
      latitude
      longitude
    }
  }

  query GetDeliveryLocoEditData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      extraDeliveryTime
      hasDeliveryMenu
      name

      deliveryCostAreas {
        ...FullDeliveryCostArea
      }

      deliveryTimeAreas {
        ...FullDeliveryTimeArea
      }

      location {
        latitude
        longitude
      }

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        id

        addonProducts {
          id

          isDeliveryCost
          name

          properties {
            id

            hasLegacyFormat
            imageUrl
            isActive
            price
            stateId

            reportGroup {
              ...FullMenuReportGroup
            }
          }
        }

        reportGroups {
          ...FullMenuReportGroup
        }
      }
    }
  }

  mutation DeleteDeliveryCostArea($restaurantId: ID!, $deliveryCostAreaId: ID!) {
    deleteDeliveryCostArea(
      restaurantId: $restaurantId,
      deliveryCostAreaId: $deliveryCostAreaId
    ) {
      id

      deliveryCostAreas {
        id
      }
    }
  }

  mutation DeleteDeliveryTimeArea($restaurantId: ID!, $deliveryTimeAreaId: ID!) {
    deleteDeliveryTimeArea(
      restaurantId: $restaurantId,
      deliveryTimeAreaId: $deliveryTimeAreaId
    ) {
      id

      deliveryTimeAreas {
        id
      }
    }
  }

  mutation UpdateDeliveryLocoEdit($restaurantId: ID!, $patch: RestaurantPatch!) {
    updateRestaurant(restaurantId: $restaurantId, patch: $patch) {
      id

      extraDeliveryTime
    }
  }

  mutation UpdateDeliveryLocoEnabled ($restaurantId: ID!, $hasDeliveryMenu: Boolean!) {
    updateRestaurant(restaurantId: $restaurantId, patch: { hasDeliveryMenu: $hasDeliveryMenu }) {
      id

      hasDeliveryMenu
    }
  }
`

const DeliveryLocoEdit: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'DeliveryLocoEdit'>()

  const { data, loading } = useGetDeliveryLocoEditDataQuery({ variables: { restaurantId } })

  const [deleteDeliveryCostArea, { loading: deletingDeliveryCostArea, error: errorDeliveryCostArea }] = useDeleteDeliveryCostAreaMutation()
  const [deleteDeliveryTimeArea, { loading: deletingDeliveryTimeArea, error: errorDeliveryTimeArea }] = useDeleteDeliveryTimeAreaMutation()
  const [updateDeliveryLocoEdit, { error: errorUpdateDeliveryLocoEdit, loading: loadingUpdateDeliveryLocoEdit }] = useUpdateDeliveryLocoEditMutation()
  const [updateDeliveryLocoEnabled, { error: deliveryLocoEnabledError, loading: updatingDeliveryLocoEnabled }] = useUpdateDeliveryLocoEnabledMutation()

  const [areaType, setAreaType] = useState<AreaType | null>(null)
  const [deleteDeliveryArea, setdeleteDeliveryArea] = useState<{ id: string, name: string | null } | null>(null)
  const [nullError, setNullError] = useState<string | null>(null)

  const hasDeliveryMenu = data?.restaurant?.hasDeliveryMenu ?? false

  const handleAcceptPress = (): void => {
    updateDeliveryLocoEnabled({ variables: { restaurantId, hasDeliveryMenu: !hasDeliveryMenu } }).catch(logError)
  }

  const handleBinPress = (id: string, name: string | null): void => {
    setdeleteDeliveryArea({ id, name })
  }

  const handledeleteDeliveryArea = (): void => {
    if (deleteDeliveryArea == null) {
      setNullError('Leveransområdet kunde inte tas bort, vänligen ladda om sidan och försök igen, eller kontakta support.')
      return
    }

    setdeleteDeliveryArea(null)

    if (areaType === 'cost') {
      deleteDeliveryCostArea({ variables: { restaurantId, deliveryCostAreaId: deleteDeliveryArea?.id } }).catch(logError)
    }

    if (areaType === 'time') {
      deleteDeliveryTimeArea({ variables: { restaurantId, deliveryTimeAreaId: deleteDeliveryArea?.id } }).catch(logError)
    }
  }

  const handleDeliveryLocoEnabledUpdate = (): void => {
    updateDeliveryLocoEnabled({ variables: { restaurantId, hasDeliveryMenu: !hasDeliveryMenu } }).catch(logError)
  }

  const handleUpdateExtraDeliveryTime = (patch: ExtraDeliveryTime): void => {
    const extraDeliveryTime = `PT${patch.extraDeliveryTime}M`

    updateDeliveryLocoEdit({ variables: { restaurantId, patch: { extraDeliveryTime } } }).catch(logError)
  }

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantDeliveryList', { restaurantId }], title: 'Leverans' }
  ], [restaurantId])

  if (loading) {
    return <Layout breadcrumbs={breadcrumbs} loading title='Leverans - Loco' />
  }

  if (!hasDeliveryMenu) {
    return (
      <Layout breadcrumbs={breadcrumbs} hideTitle screenType={ScreenType.List} title='Leverans - Loco'>
        <ListContainer maxWidth={700} title='Leverans - Loco'>
          <HStack>
            <SecondaryButton
              icon='chevron-left'
              onPress={() => navigation.navigate('RestaurantDeliveryList', { restaurantId })}
              title='Tillbaka'
            />
          </HStack>

          <SetupContainer
            disabled={hasDeliveryMenu}
            disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
          >
            <HStack alignItems='center' gap={8}>
              <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                <Text color='white' size={16}>1</Text>
              </VStack>

              <Text color={ACCENT_COLOR} size={24}>Acceptera Terms of Service</Text>
            </HStack>

            <Text size={12}>För att kunna ta emot beställningar för leverans som hanteras med ett av restaurangen ordnat leveranssätt behöver du aktivera det härnedan.</Text>
            <Text size={12}>Denna tjänst möjliggör</Text>
            <Text paddingLeft={8} size={12}>☑️ Lägga till Leveransmeny utöver Äta här och Ta med</Text>
            <Text paddingLeft={8} size={12}>☑️ Avgränsa utkörningsområden</Text>
            <Text paddingLeft={8} size={12}>☑️ Sätta utkörningspriser</Text>
            <Text paddingLeft={8} size={12}>☑️ Full statistik över försäljning av leverans</Text>
            <Text paddingLeft={8} size={12}>☑️ Samlingsbong för att skicka med utkörare inkluderat adress och karta över leveransplats</Text>

            <Text size={12}>
              Genom att acceptera villkoren för användandet av denna funktion bekräftar du att du läst våra <Link title='Terms of Service' url='https://gastrogate.notion.site/Terms-of-Service-Loco-d501714a78054cb5af6315c7ca0df83d' /> och att priset sätts i enlighet med rådande <Link title='prislista på gastrogate.io' url='https://gastrogate.io/' />.
            </Text>

            <Spacer height={12} />

            {deliveryLocoEnabledError?.message == null ? null : <Warning message={deliveryLocoEnabledError.message} paddingBottom={12} />}

            <PrimaryButton
              loading={updatingDeliveryLocoEnabled}
              onPress={handleAcceptPress}
              title='Acceptera användarvillkor'
            />
          </SetupContainer>
        </ListContainer>
      </Layout>
    )
  }

  return (
    <Layout breadcrumbs={breadcrumbs} hideTitle screenType={ScreenType.List} title='Leverans - Loco'>
      <VerificationDialog
        errorMessage={areaType === 'cost' ? errorDeliveryCostArea?.message : errorDeliveryTimeArea?.message}
        loading={deletingDeliveryCostArea || deletingDeliveryTimeArea}
        onDelete={handledeleteDeliveryArea}
        onDismiss={() => setdeleteDeliveryArea(null)}
        open={deleteDeliveryArea != null}
        prompt={`Vill du permanent ta bort leveransområdet "${deleteDeliveryArea?.name ?? 'Ej namngiven'}"?`}
        title='Radera leveransområde'
      />

      <ListContainer maxWidth={700} title='Leverans - Loco'>
        <HStack justifyContent='space-between'>
          <SecondaryButton
            icon='chevron-left'
            onPress={() => navigation.navigate('RestaurantDeliveryList', { restaurantId })}
            title='Tillbaka'
          />

          {deliveryLocoEnabledError?.message == null ? null : <Warning message={deliveryLocoEnabledError.message} paddingBottom={12} />}

          <HStack alignItems='center' gap={12}>
            <PanicButton
              isDisabled={!hasDeliveryMenu}
              isLoading={updatingDeliveryLocoEnabled}
              onActivation={handleDeliveryLocoEnabledUpdate}
            />

            <Text>Inaktivera Locos leveransfunktion</Text>
          </HStack>
        </HStack>

        <VStack>
          <Text size={37}>Leveranskostnader</Text>
          <Text>På denna sida kan ni specificera leveranskostnader. Mer funktionalitet angående leverans kommer läggas till framöver.</Text>
        </VStack>

        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('MenuAddonProductCreate', { restaurantId, isDeliveryCost: true })}
          title='Skapa ny leveranskostnad'
        />

        <AddonProductList
          data={data ?? null}
          hideItem={product => product.isDeliveryCost !== true}
          loading={loading}
          onPress={product => navigation.navigate('MenuAddonProductEdit', { restaurantId, menuAddonProductId: product.id, isDeliveryCost: true })}
          restaurantId={restaurantId}
        />
      </ListContainer>

      <ListContainer>
        <GeoJsonForm
          deliveryCosts={data?.restaurant?.menu?.addonProducts?.filter(addonProduct => addonProduct.isDeliveryCost) ?? null}
          onAreaTypeChange={(areaType) => setAreaType(areaType)}
          restaurantId={restaurantId}
          restaurantLocation={data?.restaurant?.location ?? null}
        />

        <VStack gap={8} maxWidth={700} paddingHorizontal={20}>
          {nullError == null
            ? null
            : <Warning message={nullError} paddingBottom={16} />}

          {areaType !== 'cost'
            ? null
            : data?.restaurant?.deliveryCostAreas?.map((area) => <DeliveryAreaListItem key={area.id} data={area} onBinPress={handleBinPress} />)}

          {areaType !== 'time'
            ? null
            : data?.restaurant?.deliveryTimeAreas?.map((area) => <DeliveryAreaListItem key={area.id} data={area} onBinPress={handleBinPress} />)}
        </VStack>

        <FormContainer gap={16}>
          <VStack justifyContent='center'>
            {errorUpdateDeliveryLocoEdit == null ? null : <Warning message={errorUpdateDeliveryLocoEdit?.message} paddingBottom={8} />}

            {loading
              ? <ActivityIndicator />
              : (
                <>
                  <ExtraDeliveryTimeField
                    defaultValues={{ extraDeliveryTime: durationToMinutes(data?.restaurant?.extraDeliveryTime ?? 'PT0M') }}
                    loading={loadingUpdateDeliveryLocoEdit}
                    onSave={handleUpdateExtraDeliveryTime}
                  />

                  <Spacer height={8} />

                  <Text size={10}>
                    Denna tid läggs på beräknad väntetid för gästen vid köp av leverans. Exempelvis: Om den extra leveranstiden är 15 minuter, kökets väntetid är 20 minuter, och någon har valt leverans till ett område som har en leveranstid på 30 minuter, kommer den totala väntetiden beräknas som 15 + 20 + 30 minuter = 1 timme och 5 minuter.
                  </Text>
                </>
              )}
          </VStack>
        </FormContainer>
      </ListContainer>
    </Layout>
  )
}

export default DeliveryLocoEdit

// Gov.uk colors
// ref: https://design-system.service.gov.uk/styles/colour/
const GOVUK_RED = '#d4351c'
const GOVUK_BLUE = '#1d70b8'
const GOVUK_BLACK = '#0b0c0c'
const GOVUK_DARK_GRAY = '#505a5f'

// Text
export const TEXT_COLOR = GOVUK_BLACK
export const SECONDARY_TEXT_COLOR = GOVUK_DARK_GRAY

// Links
export const LINK_COLOR = GOVUK_BLUE

// Border
export const INPUT_BORDER_COLOR = '#c3c3c3'

// Error state
export const ERROR_COLOR = GOVUK_RED

// Brand color
export const ACCENT_COLOR = '#ff9900'

import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'

import { type EmployeeInput, GetEmployeesDocument, useDeleteEmployeeMutation, useGetRestaurantEmployeeEditDataQuery, useUpdateEmployeeMutation } from '../../types/graphql'
import EmployeeForm from '../components/EmployeeForm'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantEmployeeEditData($restaurantId: ID!, $employeeId: ID!) {
    restaurant(id: $restaurantId) {
      id

      employee(id: $employeeId) {
        ...EmployeeFields
      }

      employeeGroups {
        ...EmployeeGroupFields
      }
    }
  }

  mutation UpdateEmployee($restaurantId: ID!, $employeeId: ID!, $patch: EmployeePatch!) {
    updateEmployee(
      restaurantId: $restaurantId,
      employeeId: $employeeId,
      patch: $patch
    ) {
      ...EmployeeFields
    }
  }

  mutation DeleteEmployee($restaurantId: ID!, $employeeId: ID!) {
    deleteEmployee(
      restaurantId: $restaurantId,
      employeeId: $employeeId,
    )
  }
`

const RestaurantEmployeeEdit: React.FC = () => {
  const [navigation, { restaurantId, employeeId }] = useNavigation<'RestaurantEmployeeEdit'>()

  const { data, loading } = useGetRestaurantEmployeeEditDataQuery({ variables: { restaurantId, employeeId } })

  const [deleteEmployee, { loading: deleting, error: deleteError }] = useDeleteEmployeeMutation({ onCompleted: () => navigation.navigate('RestaurantEmployeeList', { restaurantId }), awaitRefetchQueries: true, refetchQueries: [{ query: GetEmployeesDocument, variables: { restaurantId } }] })
  const [updateEmployee, { loading: saving }] = useUpdateEmployeeMutation({ onCompleted: () => navigation.navigate('RestaurantEmployeeList', { restaurantId }) })

  const employee = data?.restaurant?.employee
  const employeeGroups = data?.restaurant?.employeeGroups

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantEmployeeList', { restaurantId }], title: 'Anställda' }
  ], [restaurantId])

  const handleDelete = (): void => {
    setShowDeleteDialog(false)
    deleteEmployee({ variables: { restaurantId, employeeId } }).catch(logError)
  }

  const handleDismiss = (): void => {
    navigation.navigate('RestaurantEmployeeList', { restaurantId })
  }

  const handleSave = (input: EmployeeInput): void => {
    updateEmployee({ variables: { restaurantId, employeeId, patch: input } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title='Redigera anställd'>
      {employee?.groups == null || employee?.name == null || employeeGroups == null ? null : (
        <EmployeeForm
          employeeGroups={employeeGroups}
          initialValues={{ groupIds: employee.groups.map(group => group.id), name: employee.name }}
          onDelete={() => setShowDeleteDialog(true)}
          onDismiss={handleDismiss}
          onSave={handleSave}
          saving={saving}
        />
      )}

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt='Vill du ta bort denna anställda?'
        title='Radera anställd'
      />
    </Layout>
  )
}

export default RestaurantEmployeeEdit

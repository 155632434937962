import React, { type ReactNode } from 'react'
import { Pressable } from 'react-native'
import { VStack } from 'react-stacked'

// The PressableStateCallbackType in react native only expose pressed even though focused and hovered are present?!
interface PressableStateCallbackType {
  readonly focused?: boolean
  readonly hovered?: boolean
  readonly pressed: boolean
}

interface ListItemProps {
  children: ReactNode
  onPress?: () => void
}

const ListItem: React.FC<ListItemProps> = ({ children, onPress }) => (
  <Pressable onPress={onPress} style={{ borderRadius: 8, overflow: 'hidden' }}>
    {({ hovered }: PressableStateCallbackType) => (
      <VStack backgroundColor={(hovered ?? false) ? '#ddd' : '#efefef'}>
        {children}
      </VStack>
    )}
  </Pressable>
)

export default ListItem

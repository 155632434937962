import { PaymentProvider } from '../../types/graphql'

export default function paymentProviderLabel (paymentProvider: PaymentProvider): string {
  switch (paymentProvider) {
    case PaymentProvider.Cash:
      return 'Kontanter'
    case PaymentProvider.External:
      return 'Extern'
    case PaymentProvider.Nets:
      return 'Nets'
    case PaymentProvider.NetsConnectCloud:
      return 'Nets Connect@Cloud'
    case PaymentProvider.Multiple:
      return 'Flera (vid splittad nota)'
    case PaymentProvider.PosTerminal:
      return 'Extern kortterminal'
    case PaymentProvider.Stripe:
      return 'Stripe'
    case PaymentProvider.Swish:
      return 'Swish'
    default:
      return String(paymentProvider)
  }
}

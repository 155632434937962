import { NetworkStatus } from '@apollo/client'
import { Temporal } from '@js-temporal/polyfill'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import React from 'react'
import { useForm } from 'react-hook-form'
import { FlatList } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import { type ObjectSchema } from 'yup'

import { GetMarketingPushNotificationCreateDataDocument, useGetMarketingPushNotificationCreateDataQuery, useSendMarketingPushNotificationMutation } from '../../types/graphql'
import { PrimaryButton } from '../components/Buttons'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import ListItemWrapper from '../components/ListItemWrapper'
import { TextField } from '../components/TextField'
import Warning from '../components/Warning'
import Heading from '../components/atoms/Heading'
import Paragraph from '../components/atoms/Paragraph'
import { MultiSelectCheckbox } from '../components/fields'
import yup, { yupResolver } from '../lib/validation'
import formatDateTime from '../util/formatDateTime'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetMarketingPushNotificationCreateData($whiteLabelId: ID!, $first: Int!, $cursor: String,) {
    whiteLabel(id: $whiteLabelId) {
      id

      appName
      label

      guestGroups {
        edges {
          node {
            id

            memberCount
            name
          }
        }
      }

      marketingPushNotifications(first: $first, after: $cursor) {
        edges {
          node {
            id

            createdAt
            message
            userCount

            guestGroups {
              id

              name
            }

            sentBy {
              id

              name
              username
            }
          }
        }

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }

  mutation SendMarketingPushNotification($whiteLabelId: ID!, $message: String!, $filter: MarketingPushNotificationFilter) {
    sendMarketingPushNotification(whiteLabelId: $whiteLabelId, message: $message, filter: $filter) {
      id

      userCount
    }
  }
`

interface FormInput {
  guestGroupIds?: string[] | null
  message: string
}

const schema: ObjectSchema<FormInput> = yup.object().shape({
  guestGroupIds: yup.array().of(yup.string().required()).nullable(),
  message: yup.string().required('För att skicka en pushnotis måste du fylla i ett meddelande som kommer visas för användarna.')
})

const MarketingPushNotificationCreate: React.FC = () => {
  const [, { whiteLabelId }] = useNavigation<'MarketingPushNotificationCreate'>()

  const form = useForm<FormInput>({
    defaultValues: {
      guestGroupIds: null,
      message: ''
    },
    mode: 'onTouched',
    resolver: yupResolver(schema),
    criteriaMode: 'all'
  })

  const { data, fetchMore, networkStatus } = useGetMarketingPushNotificationCreateDataQuery({ notifyOnNetworkStatusChange: true, variables: { whiteLabelId, first: 25 } })
  const loading = networkStatus === NetworkStatus.loading
  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  const [sendMarketingPushNotification, { error: sendError, loading: sending }] = useSendMarketingPushNotificationMutation()

  const handleFetchMore = (): void => {
    fetchMore({
      variables: { cursor: data?.whiteLabel?.marketingPushNotifications?.pageInfo?.endCursor, whiteLabelId },

      updateQuery (previousResult, { fetchMoreResult }) {
        if (previousResult.whiteLabel?.marketingPushNotifications == null) {
          return previousResult
        }

        const newEdges = fetchMoreResult?.whiteLabel?.marketingPushNotifications?.edges

        if (newEdges == null || newEdges.length === 0) {
          return previousResult
        }

        return dotProp.set(previousResult, 'whiteLabel.marketingPushNotifications', {
          __typename: previousResult.whiteLabel.marketingPushNotifications.__typename,
          edges: [...previousResult.whiteLabel.marketingPushNotifications.edges, ...newEdges],
          pageInfo: fetchMoreResult?.whiteLabel?.marketingPushNotifications?.pageInfo
        })
      }
    }).catch(logError)
  }

  const handleSendPushNotification = (input: FormInput): void => {
    sendMarketingPushNotification({
      awaitRefetchQueries: true,
      onCompleted: () => form.reset(),
      refetchQueries: [{ query: GetMarketingPushNotificationCreateDataDocument, variables: { whiteLabelId, first: 25 } }, 'GetMarketingPushNotificationCreateData'],
      variables: {
        whiteLabelId,
        message: input.message,
        filter: {
          guestGroupIds: input.guestGroupIds
        }
      }
    }).catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' }
      ]}
      loading={loading}
      screenType={ScreenType.Form}
      title='Pushnotiser'
    >
      <FormContainer gap={20}>
        <Heading size='l'>Skicka ny pushnotis</Heading>

        <Paragraph>Använd denna funktion för att skicka en pushnotis till alla användare som handlat i din app, eller direkt till olika lojalitetsgrupper!</Paragraph>

        <TextField
          form={form}
          multiline
          name='message'
          numberOfLines={5}
          title='Meddelande'
        />

        <MultiSelectCheckbox
          form={form}
          name='guestGroupIds'
          options={data?.whiteLabel?.guestGroups?.edges.map(({ node }) => ({
            title: `${node.name ?? ''} (${node.memberCount ?? 0} medlemmar)`,
            value: node.id
          })) ?? []}
          title='Välj grupper'
        />

        {sendError == null ? null : <Warning message={sendError.message} />}

        <PrimaryButton
          disabled={sending}
          loading={sending}
          onPress={ignoreAsync(form.handleSubmit(handleSendPushNotification))}
          title='Skicka'
        />
      </FormContainer>

      {(data?.whiteLabel?.marketingPushNotifications?.edges.length ?? 0) === 0 ? null : (
        <ListContainer>
          <Heading size='l'>Historik</Heading>

          <FlatList
            data={data?.whiteLabel?.marketingPushNotifications?.edges}
            ItemSeparatorComponent={() => <Spacer height={8} />}
            keyExtractor={({ node }) => node.id}
            renderItem={({ item }) => (
              <ListItemWrapper>
                <VStack gap={8} grow={1}>
                  <HStack alignItems='center' gap={8} justifyContent='space-between' width='100%'>
                    <Paragraph>{item.node.message}</Paragraph>

                    {item.node.createdAt == null ? null : <Paragraph>{formatDateTime(Temporal.Instant.from(item.node.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}</Paragraph>}
                  </HStack>

                  <HStack alignItems='center' gap={8} justifyContent='space-between' width='100%'>
                    <Text>Skickades till {item.node.userCount} användare</Text>

                    <Text>Av: {item.node.sentBy?.name} ({item.node.sentBy?.username})</Text>
                  </HStack>

                  {item.node.guestGroups == null ? null : (
                    <HStack alignItems='center' width='100%'>
                      <Text>{(item.node.guestGroups?.length ?? 0) < 2 ? 'Lojalitetsgrupp' : 'Lojalitetsgrupper'}: {item.node.guestGroups.map(group => group.name).join(', ')}</Text>
                    </HStack>
                  )}
                </VStack>
              </ListItemWrapper>
            )}
          />

          {!(data?.whiteLabel?.marketingPushNotifications?.pageInfo?.hasNextPage ?? false) ? null : (
            <PrimaryButton
              disabled={fetchingMore}
              loading={fetchingMore}
              onPress={handleFetchMore}
              title='Hämta fler'
            />
          )}
        </ListContainer>
      )}
    </Layout>
  )
}

export default MarketingPushNotificationCreate

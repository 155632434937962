import { MaterialIcons } from '@expo/vector-icons'
import React, { useState } from 'react'
import { type FieldValues, type Path, type UseFormReturn } from 'react-hook-form'

import TextField from './TextField'

interface PasswordFieldProps<TFieldValues extends FieldValues> {
  error?: boolean
  helperText?: string
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  title: string
}

export default function PasswordField<T extends FieldValues> ({ error, form, helperText, name, onKeyDown, title }: PasswordFieldProps<T>): JSX.Element {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      error={error}
      form={form}
      helperText={helperText}
      icon={<MaterialIcons color={showPassword ? 'black' : 'grey'} name='visibility' onPress={() => setShowPassword(!showPassword)} size={24} />}
      name={name}
      onKeyDown={onKeyDown}
      title={title}
      type={showPassword ? 'text' : 'password'}
    />
  )
}

import React from 'react'
import { useForm } from 'react-hook-form'

import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'
import { TextField } from './TextField'

export interface SwishFormInput {
  swishNumber?: string | null
}

interface SwishFormProps {
  deleting?: boolean
  loading?: boolean
  onDelete?: () => void
  onDismiss: () => void
  onSave: (input: SwishFormInput) => void
  saving: boolean
  swishNumber: string | null
}

const schema: ObjectSchema<SwishFormInput> = yup.object({
  swishNumber: yup.string()
    .nullable()
    .required('Swish Handel-nummer är obligatoriskt.')
    .matches(/^123\d{7}$/, 'Swish Handel-nummer måste vara 10 siffror och börja med 123.')
})

const SwishForm: React.FC<SwishFormProps> = ({ deleting, onDelete, onDismiss, onSave, saving, swishNumber }) => {
  const form = useForm<SwishFormInput>({
    criteriaMode: 'all',
    defaultValues: { swishNumber },
    resolver: yupResolver(schema)
  })

  return (
    <FormContainer gap={12}>
      <TextField
        form={form}
        hint='Numret anges på formen 123 456 78 90, och börjar alltid med 123. Det är viktigt att du anger rätt nummer, annars kan det leda till att du inte får dina pengar.'
        name='swishNumber'
        title='Vad är ditt Swish Handel-nummer?'
      />

      <SubmitFormButtons
        deleting={deleting ?? false}
        disableDeleteButton={saving || (deleting ?? false)}
        disableSaveButton={saving || (deleting ?? false)}
        onCancel={onDismiss}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSave))}
        saving={saving ?? false}
      />
    </FormContainer>
  )
}

export default SwishForm

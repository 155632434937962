import { FormControl as MuFormControl, FormHelperText as MuFormHelperText, InputLabel as MuInputLabel, ListSubheader as MuListSubheader, MenuItem as MuMenuItem, Select as MuSelect, type SelectChangeEvent } from '@mui/material'
import React, { useCallback } from 'react'

interface NestedSelectProps<T extends string | number> {
  disabled?: boolean
  error?: boolean
  helperText?: string
  id: string
  onChange: (value: T) => void
  options: Array<{ value: T, title: string, subOptions: Array<{ value: T, title: string }> }>
  placeholder?: string
  required?: boolean
  title: string
  value?: T | null
}

export default function NestedSelect<T extends string | number> ({ disabled, error, helperText, id, onChange, options, placeholder, required, title, value }: NestedSelectProps<T>): JSX.Element {
  const handleChange = useCallback((ev: SelectChangeEvent<T>) => {
    // @ts-expect-error Typings indicate that `ev.target.value` can be string even when `T` is not string, which shouldn't be possible
    onChange(ev.target.value ?? null)
  }, [onChange])

  return (
    <MuFormControl error={error ?? false} required={required ?? false}>
      <MuInputLabel id={`${id}-label`}>{title}</MuInputLabel>
      <MuSelect
        disabled={disabled ?? false}
        id={id}
        label={title}
        labelId={`${id}-label`}
        MenuProps={{
          disableScrollLock: true
        }}
        onChange={handleChange}
        value={value ?? ''}
      >
        {placeholder !== '' ? <MuMenuItem>{placeholder}</MuMenuItem> : null}
        {options?.flatMap((option) => [
          <MuListSubheader key={option.value}>{option.title}</MuListSubheader>,
          ...option.subOptions.map((subOption) => <MuMenuItem key={subOption.value} style={{ marginLeft: 20 }} value={subOption.value}>{subOption.title}</MuMenuItem>)
        ])}
      </MuSelect>
      {helperText !== '' ? <MuFormHelperText>{helperText}</MuFormHelperText> : null}
    </MuFormControl>
  )
}

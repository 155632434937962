import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { GetMenuOpeningHoursDocument, type OpeningHoursInput, useCreateOpeningHoursMutation, useGetMenuOpeningHoursQuery } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import OpeningHoursForm, { productVisibilitySchema } from '../components/OpeningHoursForm'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import { openingHoursPeriodsSchema } from '../components/fields/OpeningHoursPeriodsField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateOpeningHours($input: OpeningHoursInput!, $restaurantId: ID!) {
    createOpeningHours(
      input: $input
      restaurantId: $restaurantId
    ) {
      id
      displayHours {
        id
        name
      }
      name
      productVisibility

      periods {
        open {
          day
          time
        }
        close {
          day
          time
        }
      }
    }
  }
`

const schema: ObjectSchema<OpeningHoursInput> = yup.object({
  displayHoursId: yup.string().nullable(),
  name: yup.string().min(1).required(),
  periods: openingHoursPeriodsSchema.required('Var vänlig och lägg till tider').typeError('Var vänlig och lägg till tider'),
  productVisibility: productVisibilitySchema
})

const MenuOpeningHoursCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'MenuOpeningHoursCreate'>()

  const { data: menuData } = useGetMenuOpeningHoursQuery({ variables: { restaurantId } })

  const [createOpeningHours, { loading: loadingCreate }] = useCreateOpeningHoursMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('OpeningHoursList', { restaurantId }),
    onError: (error) => logAndShowError(error),
    refetchQueries: [{ query: GetMenuOpeningHoursDocument, variables: { restaurantId } }]
  })

  const form = useForm<OpeningHoursInput>({ resolver: yupResolver(schema) })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['OpeningHoursList', { restaurantId }], title: 'Öppettider' }
  ], [restaurantId])

  const handleSave = ignoreAsync(async (input: OpeningHoursInput) => {
    await createOpeningHours({ variables: { input, restaurantId } })
  })

  return (
    <Layout breadcrumbs={breadcrumbs} hideTitle screenType={ScreenType.Form} title='Lägg till serveringstid'>
      <FormContainer gap={24} title='Lägg till serveringstid' wide>
        <TextField
          autoFocus
          form={form}
          largeTitle
          name='name'
          title='Vad är namnet på din serveringstid?'
        />

        <OpeningHoursForm
          form={form}
          openingHours={menuData?.restaurant?.menu?.productOpeningHours ?? null}
        />

        <SubmitFormButtons
          disableSaveButton={form.formState.isSubmitting}
          onCancel={() => navigation.navigate('OpeningHoursList', { restaurantId })}
          onSave={ignoreAsync(form.handleSubmit(handleSave))}
          saving={loadingCreate}
        />
      </FormContainer>
    </Layout>
  )
}

export default MenuOpeningHoursCreate

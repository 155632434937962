import React, { useCallback } from 'react'
import { ActivityIndicator, FlatList } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type ProductOpeningHoursFragment } from '../../../types/graphql'
import getBunchedOpeningPeriods from '../../util/getBunchedOpeningPeriods'
import { type ChangeFn } from '../../util/useMultiEditProducts'
import { Summary as OpeningHoursSummary } from '../OpeningHoursForm'

import ListItem from './ListItem'
import { Content } from './MultiEditForm'

const NULL_OPENING_HOURS = {
  displayHours: null,
  id: null,
  name: 'När restaurangen har öppet',
  periods: null,
  productVisibility: null
}

interface EditOpeningHoursProps {
  onDismiss: () => void
  onNext: (changeFn: ChangeFn) => void
  openingHours?: readonly ProductOpeningHoursFragment[] | null
}

const EditOpeningHours: React.FC<EditOpeningHoursProps> = ({ onDismiss, onNext, openingHours }) => {
  const handleOnPress = useCallback(({ id, name }: typeof NULL_OPENING_HOURS | ProductOpeningHoursFragment) => () =>
    onNext(properties => ({
      from: (properties?.openingHours ?? NULL_OPENING_HOURS).name ?? '',
      to: unwrap(name),
      patch: { openingHoursId: id }
    })), [onNext])

  return (
    <Content onDismiss={onDismiss}>
      {openingHours == null
        ? <ActivityIndicator />
        : (
          <FlatList
            data={[NULL_OPENING_HOURS, ...openingHours]}
            ItemSeparatorComponent={() => <Spacer height={4} />}
            keyExtractor={item => item.id ?? 'null'}
            renderItem={({ item }) => (
              <ListItem onPress={handleOnPress(item)}>
                <HStack alignItems='center' borderRadius={8} minHeight={item === NULL_OPENING_HOURS ? 48 : undefined} paddingLeft={8} paddingTop={item === NULL_OPENING_HOURS ? undefined : 8}>
                  <Text size={16} weight='bold'>{item.name}</Text>
                </HStack>

                {item === NULL_OPENING_HOURS
                  ? null
                  : (
                    <OpeningHoursSummary
                      bunchedPeriods={getBunchedOpeningPeriods(item.periods?.slice())}
                      displayHours={item.displayHours == null ? undefined : openingHours?.find(poh => poh.id === item.displayHours?.id)}
                      productVisibility={unwrap(item.productVisibility)}
                    />
                  )}
              </ListItem>
            )}
          />
        )}
    </Content>
  )
}

export default EditOpeningHours

import React from 'react'
import { useForm } from 'react-hook-form'

import { type FullAddonProductFragment, type FullMenuReportGroupFragment, type MenuAddonProductInput } from '../../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../../lib/validation'
import formatCurrency from '../../util/formatCurrency'
import ignoreAsync from '../../util/ignoreAsync'
import { parsePrice } from '../../util/price'
import FormContainer from '../FormContainer'
import Select from '../Select'
import SubmitFormButtons from '../SubmitFormButtons'
import { TextField } from '../TextField'
import Warning from '../Warning'
import { CheckBox, MoneyField } from '../fields'

interface ProductFormProps {
  errorMessage?: string
  initialValues?: FullAddonProductFragment | null
  isSubmitting: boolean
  onDismiss: () => void
  onRemove?: () => void
  onSave: (product: MenuAddonProductInput) => void
  reportGroups: readonly FullMenuReportGroupFragment[] | null | undefined
}

interface FormSchema {
  isDeliveryCost?: boolean | null
  name: string
  properties: {
    description?: string | null
    imageUrl?: string | null
    isActive?: boolean | null
    price: string
    reportGroupId: string
  }
}

const schema: ObjectSchema<FormSchema> = yup.object({
  isDeliveryCost: yup.boolean().nullable(),
  name: yup.string().trim().min(1).max(256).required(),
  properties: yup.object({
    description: yup.string().trim().max(65536),
    imageUrl: yup.string().nullable(),
    isActive: yup.boolean().nullable(),
    price: yup.string().trim().matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').required(),
    reportGroupId: yup.string().required()
  }).required()
})

const AddonProductForm: React.FC<ProductFormProps> = ({ errorMessage, initialValues, isSubmitting, onDismiss, onRemove, onSave, reportGroups }) => {
  const form = useForm<FormSchema>({
    criteriaMode: 'all',
    defaultValues: {
      name: initialValues?.name ?? '',
      properties: {
        isActive: initialValues?.properties?.isActive ?? true,
        price: initialValues?.properties?.price == null ? undefined : formatCurrency(initialValues.properties.price, { hideCurrency: true }),
        reportGroupId: initialValues?.properties?.reportGroup?.id ?? ''
      }
    },
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const handleSave = (data: FormSchema): void => {
    onSave({
      isDeliveryCost: data.isDeliveryCost,
      name: data.name,
      properties: {
        description: data.properties.description,
        imageUrl: data.properties.imageUrl,
        isActive: data.properties.isActive,
        price: parsePrice(data.properties.price),
        reportGroupId: data.properties.reportGroupId
      }
    })
  }

  return (
    <FormContainer gap={16} wide>
      <TextField
        estimatedNumberOfCharacters={18}
        form={form}
        name='name'
        title='Namn'
      />

      <MoneyField
        estimatedNumberOfCharacters={5}
        form={form}
        name='properties.price'
        title='Pris'
      />

      <CheckBox
        form={form}
        name='properties.isActive'
        title='Aktiv'
      />

      <Select
        form={form}
        name='properties.reportGroupId'
        options={reportGroups?.map(({ id, name, vatRate }) => ({ value: id, title: `${name ?? '-'}, ${vatRate ?? '-'}%` })) ?? []}
        title='Rapportgrupp'
      />

      <Warning message={errorMessage} paddingBottom={12} />

      <SubmitFormButtons
        deleting={false}
        disableDeleteButton={isSubmitting}
        disableSaveButton={isSubmitting}
        onCancel={onDismiss}
        onDelete={onRemove}
        onSave={ignoreAsync(form.handleSubmit(handleSave))}
        saving={isSubmitting}
      />
    </FormContainer>
  )
}

export default AddonProductForm

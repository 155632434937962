import { Temporal, toTemporalInstant } from '@js-temporal/polyfill'
import React, { useState } from 'react'
import { HStack, VStack } from 'react-stacked'

import type TimePeriod from '../util/TimePeriod'
import dateTimeRangeFromTimePeriod from '../util/dateTimeRangeFromTimePeriod'
import { type ZonedDateTimeRange } from '../util/serializeZonedDateTimeRange'

import { DateTimeField } from './fields'
import Select from './fields/Select'

interface DateTimeRangeSelectProps {
  initialValues?: ZonedDateTimeRange
  onDateTimeRangeChange: (value: ZonedDateTimeRange) => void
  timeZone: string
}

const DateTimeRangeSelect: React.FC<DateTimeRangeSelectProps> = ({ initialValues, onDateTimeRangeChange, timeZone }) => {
  const [end, setEnd] = useState(initialValues?.end ?? null)
  const [start, setStart] = useState(initialValues?.start ?? null)
  const [timePeriod, setTimePeriod] = useState<TimePeriod['kind']>(initialValues == null ? 'Today' : 'Custom')

  return (
    <HStack gap={12} grow={1} wrap>
      <VStack grow={1} minWidth={186}>
        <Select
          id='timePeriod'
          onChange={(kind: TimePeriod['kind']) => {
            setTimePeriod(kind)

            if (kind === 'Custom') {
              setStart(null)
              setEnd(null)
            } else {
              const dateTimeRange = dateTimeRangeFromTimePeriod({ kind }, Temporal.Now.instant(), timeZone)
              setStart(dateTimeRange.start)
              setEnd(dateTimeRange.end)
              onDateTimeRangeChange(dateTimeRange)
            }
          }}
          options={[
            { title: 'Eget datumintervall', value: 'Custom' },
            { title: 'Idag', value: 'Today' },
            { title: 'Igår', value: 'Yesterday' },
            { title: 'Denna vecka', value: 'ThisWeek' },
            { title: 'Förra veckan', value: 'LastWeek' },
            { title: 'Denna månad', value: 'ThisMonth' },
            { title: 'Förra månaden', value: 'LastMonth' },
            { title: 'I år', value: 'ThisYear' },
            { title: 'Förra året', value: 'LastYear' }
          ]}
          title='Vald tidperiod'
          value={timePeriod}
        />
      </VStack>

      <HStack gap={12} grow={1} wrap>
        <VStack grow={1} minWidth={176}>
          <DateTimeField
            clearable
            label='Från'
            onChange={(value) => {
              const start = value == null ? null : toTemporalInstant.call(value).toZonedDateTimeISO(timeZone)

              setStart(start)
              setTimePeriod('Custom')

              if (end != null && start != null) onDateTimeRangeChange({ end, start })
            }}
            value={start == null ? null : new Date(start.epochMilliseconds)}
          />
        </VStack>

        <VStack grow={1} minWidth={176}>
          <DateTimeField
            clearable
            label='Till'
            onChange={(value) => {
              const end = value == null ? null : toTemporalInstant.call(value).toZonedDateTimeISO(timeZone)

              setEnd(end)
              setTimePeriod('Custom')

              if (end != null && start != null) onDateTimeRangeChange({ end, start })
            }}
            value={end == null ? null : new Date(end.epochMilliseconds)}
          />
        </VStack>
      </HStack>
    </HStack>
  )
}

export default DateTimeRangeSelect

import React from 'react'
import { Text } from 'react-stacked'

import { type BunchedPeriodShortform } from '../util/getBunchedPeriodsShortform'

const OpeningHoursShortform: React.FC<BunchedPeriodShortform & { placeholder?: boolean }> = ({ closeTime, firstDay, lastDay, openTime, placeholder }) => {
  let shortform: string
  if (closeTime == null) {
    shortform = 'Må-Sö 00-24'
  } else {
    if (firstDay === lastDay) {
      shortform = `${firstDay} ${openTime}-${closeTime}`
    } else {
      shortform = `${firstDay}-${lastDay} ${openTime}-${closeTime}`
    }
  }

  return <Text color={(placeholder ?? false) ? '#888' : '#000'}>{shortform}</Text>
}

export default OpeningHoursShortform

import unimplemented from 'ts-unimplemented'
import unreachable from 'ts-unreachable'

import { MenuType } from '../../types/graphql'

export const ALL: readonly MenuType[] = [MenuType.EatIn, MenuType.TakeAway, MenuType.Delivery]

type ProductFieldName = 'delivery' | 'eatIn' | 'takeAway'

export function productFieldName (menuType: MenuType): ProductFieldName {
  switch (menuType) {
    case MenuType.Delivery:
      return 'delivery'
    case MenuType.EatIn:
      return 'eatIn'
    case MenuType.GiftCard:
      return unimplemented()
    case MenuType.TakeAway:
      return 'takeAway'
    default:
      unreachable(menuType)
  }
}

export function title (menuType: MenuType): 'Leverans' | 'Äta här' | 'Ta med' | 'Presentkort' {
  switch (menuType) {
    case MenuType.Delivery:
      return 'Leverans'
    case MenuType.EatIn:
      return 'Äta här'
    case MenuType.GiftCard:
      return 'Presentkort'
    case MenuType.TakeAway:
      return 'Ta med'
    default:
      unreachable(menuType)
  }
}

import { Temporal } from '@js-temporal/polyfill'
import { Divider } from '@mui/material'
import gql from 'graphql-tag'
import React from 'react'
import { ActivityIndicator, Pressable, ScrollView, View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import WithSeparator from 'react-with-separator'
import tinycolor from 'tinycolor2'
import unwrap from 'ts-unwrap'

import { type FullOrderFragment, type OrderAlternative, type OrderStatus, PaymentProvider } from '../../types/graphql'
import { orderStatusStyle } from '../screen/ReceiptListItem'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'
import openLink from '../util/openLink'
import { transactionChannelName } from '../util/transactionChannelName'

import Dialog, { Action } from './Dialog'
import DynamicIcon from './DynamicIcon'
import Link from './Link'
import WebView from './WebView'

function formatOrderAlternative (alternative: OrderAlternative): string | null {
  const isChosen = alternative.isChosen ?? false
  const count = alternative.count ?? 0
  const prefix = (count != null && count > 0) ? count : (isChosen ?? false) ? '\u2022' : '-'

  return `${prefix} ${alternative.addonProduct == null ? alternative.name ?? '' : `${unwrap(alternative.addonProduct.name)} (${formatCurrency(unwrap(alternative.addonProduct.properties?.price))})`}`
}

gql`
  fragment FullOrder on Order {
    id

    createdAt
    pickedUpAt
    readyForPickupAt

    discountAmount
    grossAmount
    tipAmount

    deliveryHeroLongCode
    employeeName
    paymentProvider
    receiptViewKey
    status
    transactionChannel
    userMessage

    items {
      id

      count
      totalAmount
      message

      alternatives {
        id

        count
        isChosen
        name
        totalAmount

        addonProduct {
          id

          name

          properties {
            id

            price
            stateId
          }
        }
      }

      product {
        id

        name
      }
    }

    paymentIntents {
      id

      tipAmount
      totalAmount

      paymentMethod {
        id

        name
        receiptPaymentMethodLabel
      }
    }

    receipt {
      id

      html
      humanOrderNumber
    }

    refundReceipts {
      id

      html
      humanOrderNumber
      refund
    }

    restaurant {
      id

      name
    }
  }
`

const VerticalTimeLineItemSeparator: React.FC = () => (
  <View
    style={{
      backgroundColor: '#eee',
      height: 24,
      marginLeft: 11,
      width: 2
    }}
  />
)

const TimeLineDateItem: React.FC<{ date: string }> = ({ date }) => (
  <HStack alignItems='center' gap={32} paddingLeft={3}>
    <DynamicIcon color='#aaa' name='calendar' size={20} type='font-awesome-5' />

    <Text color='#555' size={14}>{date}</Text>
  </HStack>
)

const TimeLineItem: React.FC<{ time: string, title: string }> = ({ time, title }) => (
  <HStack alignItems='center' gap={32}>
    <DynamicIcon color='#43956b' name='check-circle' size={24} type='font-awesome-5' />

    <Text size={16}>{title}</Text>

    <Spacer grow={1} />

    <Text color='#555' size={16}>{time}</Text>
  </HStack>
)

const injectedReceiptCSS = `
  html {
    background-color: white;
  }

  #receipt {
    padding-top: 0.5em;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 6px;
  }
`

const OrderStatusBadge: React.FC<{ status: OrderStatus | null | undefined }> = ({ status }) => {
  const { color, localized } = orderStatusStyle(status)

  const backgroundColor = tinycolor(color).setAlpha(0.1).toRgbString()

  return <Text backgroundColor={backgroundColor} borderRadius={4} color={color} padding={6} size={14} weight='bold'>{localized.toUpperCase()}</Text>
}

interface ReceiptSummaryDialogProps {
  loading: boolean
  onDismiss: () => void
  order?: FullOrderFragment | null
}

const ReceiptSummaryDialog: React.FC<ReceiptSummaryDialogProps> = ({ loading, onDismiss, order }) => {
  const [showReceipt, setShowReceipt] = React.useState(false)
  const [showRefundReceipt, setShowRefundReceipt] = React.useState(false)

  const orderReceiptHtml = order?.receipt?.html?.replace('<head>', `<head><style>${injectedReceiptCSS}</style>`) ?? ''
  const refundReceiptHtml = order?.refundReceipts?.[0]?.html?.replace('<head>', `<head><style>${injectedReceiptCSS}</style>`) ?? ''

  const createdAt = order?.createdAt == null ? null : Temporal.Instant.from(order.createdAt).toZonedDateTimeISO('Europe/Stockholm')
  const readyForPickupAt = order?.readyForPickupAt == null ? null : Temporal.Instant.from(order.readyForPickupAt).toZonedDateTimeISO('Europe/Stockholm')
  const pickedUpAt = order?.pickedUpAt == null ? null : Temporal.Instant.from(order.pickedUpAt).toZonedDateTimeISO('Europe/Stockholm')

  const discountAmount = order?.discountAmount ?? 0
  const grossAmount = order?.grossAmount ?? 0
  const tipAmount = order?.tipAmount ?? 0

  const showPayments = (order?.paymentIntents?.length ?? 0) > 0
  const showReceipts = order?.receiptViewKey != null

  return (
    <Dialog
      actions={[
        ...(!(showReceipt || showRefundReceipt) ? [] : [
          <Action
            key='back'
            onPress={() => {
              setShowReceipt(false)
              setShowRefundReceipt(false)
            }}
            text='Tillbaka'
          />
        ]),
        <Action key='ok' onPress={onDismiss} text='Stäng' />
      ]}
      fullHeight={showReceipt != null}
      maxWidth={700}
      onDismiss={onDismiss}
      visible
    >
      {showReceipt || showRefundReceipt
        ? (
          <VStack grow={1}>
            <Pressable onPress={() => openLink(`https://kvitto.link/${order?.id ?? ''}${order?.receiptViewKey ?? ''}${showRefundReceipt ? 'R' : ''}`)}>
              <Text color='#ff9900' padding={12} paddingHorizontal={24} size={18}>Öppna i webbläsare</Text>
            </Pressable>

            <WebView html={showRefundReceipt ? refundReceiptHtml : orderReceiptHtml} style={{ alignSelf: 'center', maxWidth: 480, width: '100%' }} />
          </VStack>
        ) : (
          <ScrollView>
            {loading ?
              (
                <VStack alignItems='center' gap={24} grow={1} justifyContent='center' padding={24}>
                  <ActivityIndicator />

                  <Text size={20} weight='500'>Laddar...</Text>
                </VStack>
              )
              : (
                <VStack gap={24} paddingVertical={24}>
                  <WithSeparator separator={<Divider />}>
                    <VStack gap={12} paddingHorizontal={24}>
                      <HStack alignItems='center' justifyContent='space-between'>
                        <Text size={24} weight='700'>Beställnings-ID: {order?.id ?? ''}</Text>

                        <Text backgroundColor='#333' borderRadius={5} color='#fff' paddingHorizontal={12} paddingVertical={6} size={24} weight='bold'>{order?.receipt?.humanOrderNumber}</Text>
                      </HStack>

                      <Text color='#555' size={20} weight='500'>{order?.restaurant?.name ?? ''}</Text>

                      <HStack gap={16} wrap>
                        {order?.paymentProvider !== PaymentProvider.DeliveryHero ? null : <Text backgroundColor='#df0f67' borderRadius={4} color='#ffffff' padding={6} size={14} weight='bold'>FOODORA{order?.deliveryHeroLongCode == null ? '' : `: ${order.deliveryHeroLongCode}`}</Text>}

                        <OrderStatusBadge status={order?.status} />
                      </HStack>
                    </VStack>

                    <VStack gap={12} paddingHorizontal={24}>
                      <Text size={20} weight='500'>Tidslinje för beställning</Text>

                      <WithSeparator separator={<VerticalTimeLineItemSeparator />}>
                        {createdAt == null ? null : (
                          <TimeLineDateItem
                            date={formatDateTime(createdAt, { dateStyle: 'full' })}
                          />
                        )}

                        {createdAt == null ? null : (
                          <TimeLineItem
                            time={formatDateTime(createdAt, { timeStyle: 'short' })}
                            title='Beställning mottagen'
                          />
                        )}

                        {readyForPickupAt == null || createdAt == null || readyForPickupAt.toPlainDate().equals(createdAt.toPlainDate()) ? null : (
                          <TimeLineDateItem
                            date={formatDateTime(readyForPickupAt, { dateStyle: 'full' })}
                          />
                        )}

                        {readyForPickupAt == null ? null : (
                          <TimeLineItem
                            time={formatDateTime(readyForPickupAt, { timeStyle: 'short' })}
                            title='Redo att hämtas'
                          />
                        )}

                        {pickedUpAt == null || readyForPickupAt == null || pickedUpAt.toPlainDate().equals(readyForPickupAt.toPlainDate()) ? null : (
                          <TimeLineDateItem
                            date={formatDateTime(pickedUpAt, { dateStyle: 'full' })}
                          />
                        )}

                        {pickedUpAt == null ? null : (
                          <TimeLineItem
                            time={formatDateTime(pickedUpAt, { timeStyle: 'short' })}
                            title='Beställning upphämtad'
                          />
                        )}
                      </WithSeparator>
                    </VStack>

                    <VStack gap={12} paddingHorizontal={24}>
                      <Text size={20} weight='500'>Information om beställning</Text>

                      {order?.items?.map(item => (
                        <HStack key={item.id} gap={8} paddingLeft={12}>
                          <Text size={16}>{item.count}×</Text>

                          <VStack grow={1}>
                            <HStack>
                              <Text size={16}>{item.product?.name}</Text>

                              <Spacer grow={1} />

                              {item.totalAmount == null ? null : <Text size={16}>{formatCurrency(item.totalAmount)}</Text>}
                            </HStack>

                            {item.alternatives?.map((alternative) => <Text key={alternative.id} color='#6c6c6c' size={14}>{formatOrderAlternative(alternative)}</Text>)}

                            {item.message == null ? null : <Text color='#6c6c6c' size={14}>Meddelande: {item.message}</Text>}
                          </VStack>
                        </HStack>
                      ))}

                      {order?.userMessage == null ? null : (
                        <VStack paddingLeft={12}>
                          <Text size={16}>Meddelande</Text>

                          <Text color='#6c6c6c' size={14}>{order.userMessage}</Text>
                        </VStack>
                      )}

                      <Spacer height={12} />

                      <HStack alignItems='center' gap={8} justifyContent='space-between'>
                        <Text color='#6c6c6c' size={16}>Subtotal</Text>

                        <Text color='#6c6c6c' size={16}>{formatCurrency(grossAmount + discountAmount - tipAmount)}</Text>
                      </HStack>

                      <HStack alignItems='center' gap={8} justifyContent='space-between'>
                        <Text color='#6c6c6c' size={16}>Rabatt</Text>

                        <Text color='#6c6c6c' size={16}>{discountAmount === 0 ? '' : '-'}{formatCurrency(discountAmount)}</Text>
                      </HStack>

                      <HStack alignItems='center' gap={8} justifyContent='space-between'>
                        <Text color='#6c6c6c' size={16}>Dricks</Text>

                        <Text color='#6c6c6c' size={16}>{formatCurrency(tipAmount)}</Text>
                      </HStack>

                      <HStack alignItems='center' justifyContent='space-between'>
                        <Text size={20}>Total</Text>

                        <Text size={20}>{formatCurrency(grossAmount)}</Text>
                      </HStack>
                    </VStack>

                    {!(showPayments || showReceipts) ? null : (
                      <VStack gap={12} paddingHorizontal={24}>
                        <Text size={20} weight='500'>Ytterligare orderinformation</Text>

                        {order?.transactionChannel == null ? null : (
                          <HStack alignItems='center' justifyContent='space-between' paddingLeft={12}>
                            <Text size={16}>Transaktionskanal</Text>

                            <Text size={16}>{transactionChannelName(order.transactionChannel)}</Text>
                          </HStack>
                        )}

                        {order?.employeeName == null ? null : (
                          <HStack alignItems='center' justifyContent='space-between' paddingLeft={12}>
                            <Text size={16}>Kassör</Text>

                            <Text size={16}>{order.employeeName}</Text>
                          </HStack>
                        )}

                        <Spacer height={4} />

                        {!showPayments ? null : <Text size={18}>Betalningar</Text>}

                        {order?.paymentIntents?.map(paymentIntent => (
                          <HStack key={paymentIntent.id} paddingLeft={12}>
                            <Text size={16}>{paymentIntent.paymentMethod?.receiptPaymentMethodLabel ?? paymentIntent.paymentMethod?.name}</Text>

                            <Spacer grow={1} />

                            <Text size={16}>{formatCurrency(paymentIntent.totalAmount)}</Text>
                          </HStack>
                        ))}

                        <Spacer height={12} />

                        {!showReceipts ? null : (
                          <WithSeparator leading separator={<Divider />} trailing>
                            <Pressable onPress={() => setShowReceipt(true)}>
                              <HStack
                                alignItems='center'
                                paddingLeft={12}
                              >
                                <DynamicIcon color='#ff9900' name='receipt' size={20} type='material-community' />

                                <Spacer width={12} />

                                <Text color='#ff9900' size={18}>Kvitto</Text>
                              </HStack>
                            </Pressable>

                            {order?.refundReceipts?.map(refundReceipt => (
                              <Pressable key={refundReceipt?.id} onPress={() => setShowRefundReceipt(true)}>
                                <HStack
                                  alignItems='center'
                                  paddingLeft={12}
                                >
                                  <DynamicIcon color='#ff9900' name='receipt' size={20} type='material-community' />

                                  <Spacer width={12} />

                                  <Text color='#ff9900' size={18}>Returkvitto</Text>
                                </HStack>
                              </Pressable>
                            ))}
                          </WithSeparator>
                        )}
                      </VStack>
                    )}

                    <HStack gap={6} paddingHorizontal={24}>
                      <Text>
                        Behöver du hjälp?
                      </Text>

                      <Text>
                        <Link title='Se vårt helpcenter' url='https://help.gastrogate.io' />
                      </Text>
                    </HStack>
                  </WithSeparator>
                </VStack>
              )}
          </ScrollView>
        )}
    </Dialog>
  )
}

export default ReceiptSummaryDialog

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

import { BUGSNAG_API_KEY, INTERNAL_VERSION, MARKETING_VERSION } from '../lib/config'

// https://docs.bugsnag.com/platforms/react-native/expo/

const client = Bugsnag.createClient({
  apiKey: BUGSNAG_API_KEY,
  appVersion: `${MARKETING_VERSION}+${INTERNAL_VERSION}`,
  enabledReleaseStages: ['production'],
  plugins: [new BugsnagPluginReact(React)]
})

export default client

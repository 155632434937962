import { useForm } from 'react-hook-form'
import { Image } from 'react-native'
import Spacer from 'react-spacer'
import { Text, VStack } from 'react-stacked'
import tinycolor from 'tinycolor2'
import { type ObjectSchema } from 'yup'

import { type WhiteLabelGuestGroup } from '../../types/graphql'
import yup, { emptyStringToNullTransform, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'
import { TextField } from './TextField'
import Warning from './Warning'
import { ColorField, ImageField } from './fields'

export interface FormInput {
  cardBackgroundColor: string
  cardImageUrl: string
  invitationCode?: string | null
  name: string
}

const schema: ObjectSchema<FormInput> = yup.object({
  cardBackgroundColor: yup.string().trim().required(),
  cardImageUrl: yup.string().url().trim().required(),
  invitationCode: yup.string().min(6, 'Minst 6 tecken krävs').trim().transform(emptyStringToNullTransform).nullable(),
  name: yup.string().trim().required()
})

interface GuestGroupFormProps {
  errorMessage?: string | null
  guestGroup?: WhiteLabelGuestGroup | null
  onCancel: () => void
  onDelete?: () => void
  onSave: (input: FormInput) => void
  saving?: boolean
}

const GuestGroupForm: React.FC<GuestGroupFormProps> = ({ errorMessage, guestGroup, onCancel, onDelete, onSave, saving }) => {
  const form = useForm<FormInput>({
    criteriaMode: 'all',
    mode: 'onTouched',
    resolver: yupResolver(schema),
    values: guestGroup == null ? undefined : schema.cast(guestGroup, { stripUnknown: true })
  })

  const cardBackgroundColor = form.watch('cardBackgroundColor') ?? undefined
  const groupCardTextColor = tinycolor(cardBackgroundColor ?? '#eeeeee').isDark() ? 'white' : 'black'
  const cardImageUrl = form.watch('cardImageUrl') ?? undefined
  const name = form.watch('name')

  return (
    <>
      <FormContainer gap={12}>
        <TextField
          form={form}
          name='name'
          title='Namn'
        />

        <ImageField
          cropToolProps={{ maxWidth: 2000, ratio: 86 / 54 }}
          form={form}
          name='cardImageUrl'
          title='Lojalitetskortets bild (CR80 och ISO-7810 ID-1 format: 86 bred, 54 hög)'
          viewOptions={{ height: 54 * 5, width: 86 * 5 }}
        />

        <ColorField
          form={form}
          name='cardBackgroundColor'
          title='Bakgrundsfärg'
        />

        <TextField
          estimatedNumberOfCharacters={10}
          form={form}
          hint='En kod på minst 6 tecken som gäster kan använda för att ansluta till gruppen direkt i appen genom fliken Lojalitet som syns i inloggat läge. Lämna tomt om du inte vill att gäster ska kunna ansluta till gruppen med kod, exempelvis om du endast vill bjuda in gästerna själv.'
          name='invitationCode'
          title='Inbjudningskod (ej obligatorisk)'
        />

        {errorMessage == null ? null : <Warning message={errorMessage} />}

        <SubmitFormButtons
          disableDeleteButton={form.formState.isSubmitting}
          disableSaveButton={!form.formState.isDirty}
          onCancel={onCancel}
          onDelete={onDelete}
          onSave={ignoreAsync(form.handleSubmit(onSave))}
          saving={form.formState.isSubmitting || saving}
        />
      </FormContainer>

      <VStack
        backgroundColor={cardBackgroundColor}
        borderColor='#fff'
        borderRadius={8}
        borderStyle='solid'
        borderWidth={2}
        grow={1}
        maxHeight={640}
        maxWidth={420}
        paddingBottom={12}
        paddingTop={32}
      >
        <Image
          source={{ uri: cardImageUrl }}
          style={{
            borderRadius: 8,
            aspectRatio: 86 / 54,
            height: '100%',
            objectFit: 'cover',
            width: '100%'
          }}
        />

        <VStack alignItems='center' paddingBottom={24}>
          <Text color={groupCardTextColor} size={24} weight='bold'>{name}</Text>

          <Spacer height={24} />

          <Text color={groupCardTextColor} size={32} weight='bold'>Sven Svensson</Text>

          <Text color={groupCardTextColor} size={16}>medlem sedan maj 2024</Text>
        </VStack>
      </VStack>
    </>
  )
}

export default GuestGroupForm

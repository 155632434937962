import gql from 'graphql-tag'
import React, { useCallback } from 'react'

import { type FullDiscountFragment, useUpdateDiscountDisabledStatusMutation, useUpdateRestaurantDiscountDisabledStatusMutation } from '../../types/graphql'
import logAndShowError from '../util/logAndShowError'

import { PanicButton } from './PanicButton'

gql`
  mutation UpdateDiscountDisabledStatus($metaOrganizationId: ID!, $discountId: ID!, $isDisabled: Boolean!) {
    updateDiscount(metaOrganizationId: $metaOrganizationId, discountId: $discountId, patch: { isDisabled: $isDisabled }) {
      id
      isDisabled
    }
  }

  mutation UpdateRestaurantDiscountDisabledStatus($restaurantId: ID!, $discountId: ID!, $isDisabled: Boolean!) {
    updateRestaurantDiscount(restaurantId: $restaurantId, discountId: $discountId, patch: { isDisabled: $isDisabled }) {
      id
      isDisabled
    }
  }
`

interface MetaOrganizationDiscountToggleButtonProps {
  discount: FullDiscountFragment
  owner: { metaOrganizationId: string } | { restaurantId: string }
}

const DiscountToggleButton: React.FC<MetaOrganizationDiscountToggleButtonProps> = ({ discount, owner }) => {
  const isDisabled = discount.isDisabled ?? true

  const [toggleMetaOrganizationDiscount, { loading: metaOrganizationDiscountLoading }] = useUpdateDiscountDisabledStatusMutation()
  const [toggleRestaurantDiscount, { loading: restaurantDiscountLoading }] = useUpdateRestaurantDiscountDisabledStatusMutation()

  const handleToggle = useCallback(() => {
    if ('metaOrganizationId' in owner) {
      toggleMetaOrganizationDiscount({
        variables: {
          metaOrganizationId: owner.metaOrganizationId,
          discountId: discount.id,
          isDisabled: !isDisabled
        },
        optimisticResponse: { updateDiscount: { __typename: 'Discount', id: discount.id, isDisabled: !isDisabled } }
      }).catch(logAndShowError)
    } else {
      toggleRestaurantDiscount({
        variables: {
          restaurantId: owner.restaurantId,
          discountId: discount.id,
          isDisabled: !isDisabled
        },
        optimisticResponse: { updateRestaurantDiscount: { __typename: 'Discount', id: discount.id, isDisabled: !isDisabled } }
      }).catch(logAndShowError)
    }
  }, [discount, owner, toggleMetaOrganizationDiscount, toggleRestaurantDiscount])

  return (
    <PanicButton
      isDisabled={isDisabled}
      isLoading={metaOrganizationDiscountLoading || restaurantDiscountLoading}
      onActivation={handleToggle}
    />
  )
}

export default DiscountToggleButton

import gql from 'graphql-tag'
import React from 'react'

import { GetMenuCourseListDocument, type MenuCourseInput, useCreateMenuCourseMutation } from '../../types/graphql'
import Layout, { ScreenType } from '../components/Layout'
import MenuCourseForm from '../components/MenuCourseForm'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateMenuCourse($restaurantId: ID!, $input: MenuCourseInput!) {
    createMenuCourse(restaurantId: $restaurantId, input: $input) {
      id
    }
  }
`

const MenuCourseCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'MenuCourseCreate'>()

  const [createMenuCourse, { loading }] = useCreateMenuCourseMutation({
    onCompleted: () => navigation.navigate('MenuCourseList', { restaurantId }),
    onError: logAndShowError,
    refetchQueries: [
      {
        query: GetMenuCourseListDocument,
        variables: { restaurantId }
      }
    ]
  })

  const handleCancel = (): void => {
    navigation.navigate('MenuCourseList', { restaurantId })
  }

  const handleSubmit = ignoreAsync(async (input: MenuCourseInput) => {
    await createMenuCourse({ variables: { input, restaurantId } })
  })

  return (
    <Layout
      breadcrumbs={[{ link: ['MenuCourseList', { restaurantId }], title: 'Rätter' }]}
      screenType={ScreenType.Form}
      title='Skapa en ny rätt'
    >
      <MenuCourseForm onCancel={handleCancel} onSubmit={handleSubmit} saving={loading} />
    </Layout>
  )
}

export default MenuCourseCreate

import { MaterialIcons } from '@expo/vector-icons'
import * as DocumentPicker from 'expo-document-picker'
import { type DocumentPickerResult } from 'expo-document-picker'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Pressable } from 'react-native'
import Spacer from 'react-spacer'
import unwrap from 'ts-unwrap'

import { type FullAddonProductFragment, useCreateDeliveryCostAreaMutation, useCreateDeliveryTimeAreaMutation } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import base64ToGeoJson from '../util/base64ToGeoJson'
import formatCurrency from '../util/formatCurrency'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import openLink from '../util/openLink'

import ButtonGroup from './ButtonGroup'
import { CreateButton, SecondaryButton } from './Buttons'
import FormContainer from './FormContainer'
import Warning from './Warning'
import { CheckBox, Select, TextField } from './fields'

gql`
  mutation CreateDeliveryCostArea($restaurantId: ID!, $input: DeliveryCostAreaInput!) {
    createDeliveryCostArea(
      restaurantId: $restaurantId,
      input: $input
    ) {
      id

      deliveryCostAreas {
        id

        geometry {
          latitude
          longitude
        }
        name

        product {
          id
        }
      }
    }
  }

  mutation CreateDeliveryTimeArea($restaurantId: ID!, $input: DeliveryTimeAreaInput!) {
    createDeliveryTimeArea(
      restaurantId: $restaurantId,
      input: $input
    ) {
      id

      deliveryTimeAreas {
        id

        geometry {
          latitude
          longitude
        }
        name
        time
      }
    }
  }
`

interface GeoJsonObject {
  features: Array<{
    geometry: {
      coordinates: Array<Array<[number, number]>>
    }
  }>
}

export type AreaType = 'cost' | 'time'

interface GeoJsonFormInput {
  areaType: AreaType
  createBothAreas?: boolean
  deliveryCost?: string
  geoJsonUri: string
  name: string
  time?: number
}

const geoJsonSchema: ObjectSchema<GeoJsonFormInput> = yup.object({
  areaType: yup.mixed<AreaType>().oneOf(['cost', 'time']).required(),
  createBothAreas: yup.boolean().when('areaType', {
    is: 'cost',
    then: schema => schema.required('Kryssa i om du vill att ett tidsområde ska skapas med samma geojson-fil')
  }),
  deliveryCost: yup.string().when('areaType', {
    is: 'cost',
    then: schema => schema.required('Välj leveranskostnad')
  }),
  geoJsonUri: yup.string().required('Ladda upp en GeoJson-fil'),
  name: yup.string().trim().min(1, 'Ange namn').required('Ange namn'),
  time: yup.number().when('areaType', {
    is: 'time',
    then: schema => schema.required('Välj leveranstid'),
    otherwise: schema =>
      schema.when('createBothAreas', {
        is: true,
        then: schema => schema.required('Välj leveranstid')
      })
  })
})

interface GeoJsonFormProps {
  deliveryCosts: readonly FullAddonProductFragment[] | null
  onAreaTypeChange: (areaType: AreaType) => void
  restaurantLocation: { latitude: number, longitude: number } | null
  restaurantId: string
}

const GeoJsonForm: React.FC<GeoJsonFormProps> = ({ deliveryCosts, onAreaTypeChange, restaurantLocation, restaurantId }) => {
  const form = useForm<GeoJsonFormInput>({
    criteriaMode: 'all',
    defaultValues: { areaType: 'time', createBothAreas: true },
    mode: 'onChange',
    resolver: yupResolver(geoJsonSchema)
  })

  const [addDeliveryCostArea, { loading: creatingCostArea, error: errorCostArea }] = useCreateDeliveryCostAreaMutation()
  const [addDeliveryTimeArea, { loading: creatingTimeArea }] = useCreateDeliveryTimeAreaMutation()

  const areaType = form.watch().areaType
  const createBothAreas = form.watch().createBothAreas
  const [geoJson, setGeoJson] = useState<GeoJsonObject | null>(null)
  const [path, setPath] = useState<string | null>(null)
  const [response, setResponse] = useState<DocumentPickerResult | null>(null)
  const loading = creatingCostArea || creatingTimeArea
  const [fileError, setFileError] = useState<string | null>(null)
  const [timeError, setTimeError] = useState<string | null>(null)

  const handleCreateArea = (input: GeoJsonFormInput): void => {
    if (geoJson == null) {
      setFileError('Kunde inte ladda in geoJson')
      return
    }

    const coordinates = geoJson.features[0].geometry.coordinates[0].map(([longitude, latitude]) => ({ latitude, longitude }))

    if (areaType === 'cost') {
      addDeliveryCostArea({ variables: { restaurantId, input: { geometry: coordinates, name: input.name, productId: unwrap(input.deliveryCost) } } }).catch(logError)
    }

    if (areaType === 'time' || (createBothAreas ?? false)) {
      if (input.time == null) {
        setTimeError('Tid måste anges i hela minuter')
        return
      }
      addDeliveryTimeArea({ variables: { restaurantId, input: { geometry: coordinates, name: input.name, time: unwrap(`PT${input.time ?? 0}M`).toString() } } }).catch(logError)
    }

    form.reset({ areaType })
  }

  const handleOpenGeoJsonDotIo = (): void => {
    openLink(restaurantLocation == null ? 'https://geojson.io' : `https://geojson.io/#map=11/${restaurantLocation.latitude}/${restaurantLocation.longitude}`)
  }

  const handleUploadFile = (): void => {
    setFileError(null)

    DocumentPicker.getDocumentAsync({ copyToCacheDirectory: true, type: 'application/geo+json' }).then(response => setResponse(response)).catch(logError)
  }

  useEffect(() => {
    if (response == null) return
    if (response.canceled) return
    if (response.assets[0].name == null) return
    const uriArray = response.assets[0].uri.split('base64,')

    if ((uriArray ?? [])?.length !== 2) {
      setFileError('Felaktigt decodeformat, observera att filen måste ha filändelsen .geojson och vara på rätt format. På geojson.io kan du exportera en sådan fil under Save > GeoJSON')
      return
    }

    const b64String = uriArray[1]
    setPath(response.assets[0].name)

    try {
      setGeoJson(base64ToGeoJson(b64String))
    } catch {
      setFileError('Kunde inte läsa filen, observera att filen måste ha filändelsen .geojson och vara på rätt format. På geojson.io kan du exportera en sådan fil under Save > GeoJSON')
    }
  }, [response])

  useEffect(() => {
    if (timeError == null) return

    setTimeError(null)
  }, [form.getValues().time])

  useEffect(() => {
    if (path == null) return

    form.reset({ ...form.getValues(), geoJsonUri: path })
  }, [path])

  // Set initial values
  useEffect(() => {
    if (deliveryCosts == null) return

    onAreaTypeChange(areaType)

    form.reset({ ...form.getValues(), createBothAreas: areaType === 'cost', deliveryCost: areaType === 'time' ? undefined : form.getValues().deliveryCost ?? deliveryCosts?.[0]?.id })
  }, [areaType, deliveryCosts])

  return (
    <FormContainer gap={16}>
      <ButtonGroup
        buttons={[{ label: 'Kostnad', value: 'cost' }, { label: 'Tid', value: 'time' }]}
        form={form}
        name='areaType'
        selected={areaType}
      />

      <TextField
        form={form}
        name='name'
        title='Namn'
      />

      {areaType === 'time' ? null : (
        <Select
          form={form}
          name='deliveryCost'
          options={deliveryCosts?.map(deliveryCost => ({ title: `${deliveryCost.name ?? ''}, ${formatCurrency(deliveryCost.properties?.price ?? 0)}`, value: deliveryCost.id })) ?? []}
          title='Leveranskostnad'
        />
      )}

      {areaType !== 'cost' ? null : (
        <CheckBox
          form={form}
          name='createBothAreas'
          title='Skapa både kostnads- och tidszon'
        />
      )}

      {!(areaType === 'time' || (createBothAreas ?? false)) ? null : (
        <>
          {timeError == null ? null : <Warning message={timeError} paddingBottom={8} />}

          <TextField
            form={form}
            name='time'
            title='Leveranstid (minuter)'
          />
        </>
      )}

      {fileError == null
        ? null
        : <Warning message={fileError} paddingBottom={8} />}

      <TextField
        form={form}
        icon={
          <Pressable onPress={handleUploadFile}>
            <MaterialIcons name='note-add' size={24} />
          </Pressable>
        }
        name='geoJsonUri'
        readOnly
        title='Ladda upp GeoJson-fil'
      />

      <SecondaryButton onPress={handleOpenGeoJsonDotIo} title='Gå till geojson.io' />

      <Spacer height={8} />

      {errorCostArea?.message == null
        ? null
        : <Warning message={errorCostArea.message} paddingBottom={8} />}

      {!(areaType === 'cost' && !(createBothAreas ?? true))
        ? null
        : <Warning message='Varning! Notera att alla kostnadszoner måste ha ett eller flera motsvarande tidsområden för att leverans ska fungera. Om kostnadsområdet sträcker sig utanför tillgängliga tidsområden kan leveranstiden inte beräknas.' paddingBottom={8} />}

      <CreateButton
        disabled={!form.formState.isValid || loading}
        loading={loading}
        onPress={ignoreAsync(form.handleSubmit(handleCreateArea))}
        title='Skapa leveransområde'
      />
    </FormContainer>
  )
}

export default GeoJsonForm

import { createGlobalPersistedVariable } from './createGlobalPersistedVariable'

export type TimeSlotPeriod = 'selectDate' | 'now'

interface SelectedTimeSlotSettings {
  dateRange: { start: string } | null
  timePeriod?: TimeSlotPeriod
}

export default createGlobalPersistedVariable<SelectedTimeSlotSettings | null>(null, 'selectedTimeSlotSettings')

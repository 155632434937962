import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { type OpeningHoursPatch, useGetMenuOpeningHoursQuery, useUpdateOpeningHoursMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import OpeningHoursForm, { productVisibilitySchema } from '../components/OpeningHoursForm'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { openingHoursPeriodsSchema } from '../components/fields/OpeningHoursPeriodsField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  mutation UpdateOpeningHours($openingHoursId: ID!, $patch: OpeningHoursPatch!, $restaurantId: ID!) {
    updateOpeningHours(
      openingHoursId: $openingHoursId
      patch: $patch
      restaurantId: $restaurantId
    ) {
      id
      displayHours {
        id
        name
      }
      name
      productVisibility

      periods {
        open {
          day
          time
        }
        close {
          day
          time
        }
      }
    }
  }
`

const schema: ObjectSchema<OpeningHoursPatch> = yup.object({
  displayHoursId: yup.string().nullable(),
  periods: openingHoursPeriodsSchema.required('Var vänlig och lägg till tider'),
  productVisibility: productVisibilitySchema
})

const MenuOpeningHoursEdit: React.FC = () => {
  const [navigation, { restaurantId, menuOpeningHoursId: inputMenuOpeningHoursId }] = useNavigation<'MenuOpeningHoursEdit'>()

  // FIXME: https://github.com/react-navigation/react-navigation/issues/11506
  const openingHoursId = inputMenuOpeningHoursId.replace(/%3D/g, '=')

  const { data: menuData, loading } = useGetMenuOpeningHoursQuery({ variables: { restaurantId } })

  const [updateOpeningHours, { loading: loadingUpdate }] = useUpdateOpeningHoursMutation({
    onCompleted: () => navigation.navigate('OpeningHoursList', { restaurantId }),
    onError: (error) => logAndShowError(error)
  })

  const menuOpeningHours = menuData?.restaurant?.menu?.productOpeningHours?.find(openingHour => openingHour.id === openingHoursId)

  const values = useMemo(() => {
    if (menuOpeningHours == null) return undefined

    return schema.cast({
      displayHoursId: menuOpeningHours.displayHours?.id,
      periods: menuOpeningHours.periods,
      productVisibility: menuOpeningHours.productVisibility
    }, { stripUnknown: true })
  }, [menuOpeningHours])

  const form = useForm<OpeningHoursPatch>({
    criteriaMode: 'all',
    resetOptions: { keepDirtyValues: true, keepErrors: true },
    resolver: yupResolver(schema),
    values
  })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['OpeningHoursList', { restaurantId }], title: 'Öppettider' }
  ], [restaurantId])

  const handleSave = ignoreAsync(async (patch: OpeningHoursPatch) => {
    await updateOpeningHours({ variables: { openingHoursId, patch, restaurantId } })
  })

  return (
    <Layout breadcrumbs={breadcrumbs} hideTitle loading={loading} screenType={ScreenType.Form} title={`Redigera ${menuOpeningHours?.name ?? '...'}`}>
      <FormContainer gap={20} title={`Redigera ${menuOpeningHours?.name ?? '...'}`} wide>
        <OpeningHoursForm
          form={form}
          openingHours={menuData?.restaurant?.menu?.productOpeningHours ?? null}
          openingHoursId={menuOpeningHours?.id}
        />

        <SubmitFormButtons
          disableSaveButton={form.formState.isSubmitting}
          onCancel={() => navigation.navigate('OpeningHoursList', { restaurantId })}
          onSave={ignoreAsync(form.handleSubmit(handleSave))}
          saving={loadingUpdate}
        />
      </FormContainer>
    </Layout>
  )
}

export default MenuOpeningHoursEdit

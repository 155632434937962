import gql from 'graphql-tag'
import React from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useGetCashregisterQuery } from '../../types/graphql'
import { PrimaryButton } from '../components/Buttons'
import DownloadCashregisterJournal from '../components/DownloadCashregisterJournal'
import Layout from '../components/Layout'
import useNavigation from '../util/useNavigation'

gql`
  query GetCashregister($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      hasReportReadAccess: hasAccess(access: ReportRead)

      cashregister {
        id

        isRegistered
        manufacturer
        model
        name
        softwareName
        softwareVersion

        controlUnit {
          address
          identificationNumber
          isRegistered
          manufacturer
          model
          productionNumber
          serialNumber
        }
      }
    }
  }
`

function formatBool (value: boolean | null | undefined): string | null {
  return (value == null) ? null : (value ? 'Ja' : 'Nej')
}

const KeyValue: React.FC<{ label: string, value?: string | null }> = ({ label, value }) => (
  <HStack paddingVertical={4}>
    <Text weight='bold'>{label}{':'}</Text>
    <Text>&ensp;{value}</Text>
  </HStack>
)

const CashregisterView: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'CashregisterView'>()

  const { data, loading } = useGetCashregisterQuery({ variables: { restaurantId } })

  return (
    <Layout title='Kassaregister'>
      {loading
        ? (
          <>
            <Spacer height={24} />
            <ActivityIndicator />
          </>
        )
        : (
          <VStack maxWidth={480} padding={20}>
            {(data?.restaurant?.hasReportReadAccess ?? false) && (
              <PrimaryButton
                onPress={() => navigation.navigate('CashregisterReportList', { restaurantId })}
                title='Se rapporter för detta kassaregister'
              />
            )}

            <Spacer height={24} />
            <Text size={24} weight='bold'>Kontrollenhet</Text>

            <KeyValue label='Tillverkare' value={data?.restaurant?.cashregister?.controlUnit?.manufacturer} />
            <KeyValue label='Modell' value={data?.restaurant?.cashregister?.controlUnit?.model} />
            <KeyValue label='Tillverkningsnummer' value={data?.restaurant?.cashregister?.controlUnit?.serialNumber} />
            <KeyValue label='Identifikationsnummer' value={data?.restaurant?.cashregister?.controlUnit?.identificationNumber} />
            <KeyValue label='Adress' value={data?.restaurant?.cashregister?.controlUnit?.address} />
            <KeyValue label='Har registrerats hos Skatteverket' value={formatBool(data?.restaurant?.cashregister?.controlUnit?.isRegistered)} />

            <Spacer height={24} />
            <Text size={24} weight='bold'>Kassaregister</Text>

            <KeyValue label='Kassabeteckning' value={data?.restaurant?.cashregister?.softwareName} />
            <KeyValue label='Modell/Program' value={data?.restaurant?.cashregister?.softwareName} />
            <KeyValue label='Modellbeteckning' value={data?.restaurant?.cashregister?.model} />
            <KeyValue label='Version' value={data?.restaurant?.cashregister?.softwareVersion} />
            <KeyValue label='Tillverkningsnummer/namn' value={data?.restaurant?.cashregister?.name} />
            <KeyValue label='Har registrerats hos Skatteverket' value={formatBool(data?.restaurant?.cashregister?.isRegistered)} />

            <Spacer height={24} />

            <DownloadCashregisterJournal restaurantId={restaurantId} />
          </VStack>
        )}
    </Layout>
  )
}

export default CashregisterView

import sortOn from 'sort-on'

import { WeekDay } from '../../types/graphql'

import { type BunchedPeriods } from './getBunchedOpeningPeriods'
import getWeekDayShortform, { type WeekDayShortForm } from './getWeekDayShortform'

export const WEEK_DAYS = [
  WeekDay.Monday,
  WeekDay.Tuesday,
  WeekDay.Wednesday,
  WeekDay.Thursday,
  WeekDay.Friday,
  WeekDay.Saturday,
  WeekDay.Sunday
]

function getTimeShortform (time: string): string {
  return time.replace(/^0/, '').replace(/(:00)*$/, '')
}

export interface BunchedPeriodShortform {
  closeTime: string | null
  firstDay: WeekDayShortForm
  lastDay: WeekDayShortForm
  openTime: string
}

interface InternalBunchedPeriodShortform extends BunchedPeriodShortform {
  sortingIndex: number
}

export default function getBunchedPeriodsShortform (bunchedPeriods: BunchedPeriods[]): BunchedPeriodShortform[] {
  const bunchedPeriodShortform: InternalBunchedPeriodShortform[] = []

  for (const bunchedPeriod of bunchedPeriods) {
    const allDays = bunchedPeriod.days.map(day => WEEK_DAYS.indexOf(day))
    const sortedDays = sortOn(allDays, '')

    let lastDayIndex = null
    let firstDayIndex = sortedDays[0]
    for (const day of sortedDays) {
      if (lastDayIndex == null) {
        lastDayIndex = day

        continue
      }

      if (Math.abs(day - lastDayIndex) !== 1) {
        bunchedPeriodShortform.push({
          closeTime: bunchedPeriod.closeTime == null ? bunchedPeriod.closeTime : getTimeShortform(bunchedPeriod.closeTime),
          firstDay: getWeekDayShortform(WEEK_DAYS[firstDayIndex]),
          lastDay: getWeekDayShortform(WEEK_DAYS[lastDayIndex]),
          openTime: getTimeShortform(bunchedPeriod.openTime),
          sortingIndex: firstDayIndex
        })

        firstDayIndex = day
      }

      lastDayIndex = day
    }

    if (lastDayIndex == null) lastDayIndex = firstDayIndex

    bunchedPeriodShortform.push({
      closeTime: bunchedPeriod.closeTime == null ? bunchedPeriod.closeTime : getTimeShortform(bunchedPeriod.closeTime),
      firstDay: getWeekDayShortform(WEEK_DAYS[firstDayIndex]),
      lastDay: getWeekDayShortform(WEEK_DAYS[lastDayIndex]),
      openTime: getTimeShortform(bunchedPeriod.openTime),
      sortingIndex: firstDayIndex
    })
  }

  return sortOn(bunchedPeriodShortform, 'sortingIndex')
}

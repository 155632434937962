import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useCallback, useMemo, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'
import unreachable from 'ts-unreachable'

import { useGetStatisticsOverviewDataQuery } from '../../types/graphql'
import { PrimaryButton } from '../components/Buttons'
import DailySalesPlot from '../components/DailySalesPlot'
import DateRangeSelect from '../components/DateRangeSelect'
import Layout, { ScreenType } from '../components/Layout'
import StatisticsContainer from '../components/StatisticsContainer'
import StatisticsTable from '../components/StatisticsTable'
import Warning from '../components/Warning'
import dateRangeFromTimePeriod, { type PlainDateRange } from '../util/dateRangeFromTimePeriod'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'
import getDeviceTimeZone from '../util/getDeviceTimeZone'
import { resolutionForDailySales } from '../util/resolutionForDailySales'
import serializeZonedDateTimeRange from '../util/serializeZonedDateTimeRange'

export enum Columns {
  GrossAmount,
  OrderCount,
  RestaurantName
}

export function getLastUpdatedLabel (date: Temporal.Instant, timeZone: string): string {
  return formatDateTime(date.toZonedDateTimeISO(timeZone), { dateStyle: 'short', timeStyle: 'short' })
}

gql`
  query GetStatisticsOverviewData($dateTimeRange: DateTimeRangeInput!, $resolution: TimeResolution!) {
    statistics(dateTimeRange: $dateTimeRange, resolution: $resolution) {
      id

      salesByMenuTypeAndTimePeriod {
        id

        count
        grossAmount
        menuType
        when
      }

      salesByRestaurant {
        id

        count
        grossAmount

        restaurant {
          id

          name
        }
      }
    }
  }
`

const StatisticsOverview: React.FC = () => {
  const dataTimeZone = 'Europe/Stockholm'
  const deviceTimeZone = getDeviceTimeZone()

  const initialDateRange = dateRangeFromTimePeriod({ kind: 'Today' }, Temporal.Now.instant(), dataTimeZone)

  const [dateRange, setDateRange] = useState<PlainDateRange>(initialDateRange)
  const [lastUpdated, setLastUpdated] = useState<Temporal.Instant | null>(null)
  const [sortColumn, setSortColumn] = useState(Columns.GrossAmount)
  const [sortAsc, setSortAsc] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState<PlainDateRange>(initialDateRange)

  const dateTimeRangeLocal = useMemo(() => {
    return {
      start: dateRange.start.toZonedDateTime(dataTimeZone),
      end: dateRange.end.add({ days: 1 }).toZonedDateTime(dataTimeZone)
    }
  }, [dateRange, dataTimeZone])
  const resolution = useMemo(() => resolutionForDailySales(dateTimeRangeLocal), [dateTimeRangeLocal])

  const { data, error, loading } = useGetStatisticsOverviewDataQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setLastUpdated(Temporal.Now.instant()),
    pollInterval: 60000,
    variables: { dateTimeRange: serializeZonedDateTimeRange(dateTimeRangeLocal), resolution }
  })

  const title = useMemo(() => {
    if (data?.statistics?.salesByRestaurant == null) return 'Försäljning'

    const orderCount = data.statistics.salesByRestaurant.reduce((mem, group) => mem + (group.count ?? 0), 0)
    const grossAmount = data.statistics.salesByRestaurant.reduce((mem, group) => mem + (group.grossAmount ?? 0), 0)

    return `Försäljning - ${formatCurrency(grossAmount)} (${orderCount})`
  }, [data?.statistics?.salesByRestaurant])

  const salesByRestaurant = useMemo(() => {
    if (data?.statistics?.salesByRestaurant == null) return []

    switch (sortColumn) {
      case Columns.GrossAmount:
        return sortOn(data.statistics.salesByRestaurant, [sortAsc ? 'grossAmount' : '-grossAmount'])
      case Columns.OrderCount:
        return sortOn(data.statistics.salesByRestaurant, [sortAsc ? 'count' : '-count'])
      case Columns.RestaurantName:
        return sortOn(data.statistics.salesByRestaurant, [sortAsc ? 'restaurant.name' : '-restaurant.name'])
      default:
        unreachable(sortColumn)
    }
  }, [data?.statistics?.salesByRestaurant, sortColumn, sortAsc])

  const lastUpdatededLabel = useMemo(() => lastUpdated == null ? '' : getLastUpdatedLabel(lastUpdated, deviceTimeZone), [lastUpdated, deviceTimeZone])

  const handleDateRangeChange = (value: PlainDateRange): void => {
    setSelectedDateRange(value)
  }

  const handlePress = useCallback((column: Columns) => {
    setSortAsc(value => !value)
    setSortColumn(column)
  }, [])

  const handleSubmit = useCallback(() => {
    setDateRange(selectedDateRange)
  }, [selectedDateRange])

  return (
    <Layout screenType={ScreenType.Statistics} title='Försäljningsstatistik'>
      <StatisticsContainer maxWidth={700}>
        {error == null
          ? null
          : <Warning message={String(error)} paddingBottom={8} />}

        <DateRangeSelect onDateRangeChange={handleDateRangeChange} timeZone={dataTimeZone} />

        <Spacer height={16} />

        <PrimaryButton
          disabled={selectedDateRange === dateRange}
          onPress={handleSubmit}
          title='Sök'
        />
      </StatisticsContainer>

      {lastUpdated == null ? null : (
        <StatisticsContainer maxWidth={700}>
          <VStack alignItems='center' justifyContent='center' padding={8}>
            <Text>Senast uppdaterad: {lastUpdatededLabel}</Text>
          </VStack>
        </StatisticsContainer>
      )}

      {!loading ? null : (
        <StatisticsContainer maxWidth={80}>
          <ActivityIndicator size='large' />
        </StatisticsContainer>
      )}

      {(data?.statistics?.salesByMenuTypeAndTimePeriod == null || data.statistics.salesByMenuTypeAndTimePeriod.length === 0) ? null : (
        <StatisticsContainer maxWidth={700}>
          <DailySalesPlot data={data?.statistics?.salesByMenuTypeAndTimePeriod} dateTimeRange={dateTimeRangeLocal} resolution={resolution} />
        </StatisticsContainer>
      )}

      {(salesByRestaurant.length === 0) ? null : (
        <StatisticsContainer maxWidth={700}>
          <Text size={22}>{title}</Text>

          <Spacer height={16} />

          <StatisticsTable
            data={salesByRestaurant}
            onPress={handlePress}
            sortAsc={sortAsc}
            sortColumn={sortColumn}
          />
        </StatisticsContainer>
      )}
    </Layout>
  )
}

export default StatisticsOverview

import gql from 'graphql-tag'
import React, { useState } from 'react'
import { VStack } from 'react-stacked'

import { type FullMenuReportGroupFragment, GetReportGroupsDocument, useDeleteMenuReportGroupMutation, useGetReportGroupsQuery } from '../../types/graphql'
import { AddButton } from '../components/Buttons'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import { ReportGroupCard } from '../components/ReportGroupList/ReportGroupCard'
import VerificationDialog from '../components/VerificationDialog'
import ignoreAsync from '../util/ignoreAsync'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullMenuReportGroup on MenuReportGroup {
    id

    name
    vatRate
  }

  query GetReportGroups($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        reportGroups {
          ...FullMenuReportGroup
        }
      }
    }
  }

  mutation DeleteMenuReportGroup($restaurantId: ID!, $menuReportGroupId: ID!) {
    deleteMenuReportGroup(
      restaurantId: $restaurantId
      menuReportGroupId: $menuReportGroupId
    )
  }
`

const ReportGroupList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'ReportGroupList'>()

  const { data, loading } = useGetReportGroupsQuery({ variables: { restaurantId } })
  const [deleteMenuReportGroup, { loading: deleting, error: deleteError }] = useDeleteMenuReportGroupMutation({ awaitRefetchQueries: true })

  const [selectedReportGroup, setSelectedReportGroup] = useState<FullMenuReportGroupFragment | null>(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const handleSelectedBin = (reportGroup: FullMenuReportGroupFragment): void => {
    setSelectedReportGroup(reportGroup)
    setIsDeleting(true)
  }

  const handleDelete = ignoreAsync(async (): Promise<void> => {
    if (selectedReportGroup == null) return

    setIsDeleting(false)

    try {
      await deleteMenuReportGroup({
        refetchQueries: [{ query: GetReportGroupsDocument, variables: { restaurantId } }],
        variables: { restaurantId, menuReportGroupId: selectedReportGroup.id }
      })
    } catch (error) {
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'MENU_REPORT_GROUP_IS_CONNECTED') {
        showAlert('Denna rapportgrupp är för närvarande kopplad till en eller flera varor, och kan därför inte tas bort')
      } else {
        showAlert('Ett fel uppstod på vår server, vänligen ladda om sidan och försök igen.')
      }
    }
  })

  return (
    <Layout hideTitle loading={loading} screenType={ScreenType.List} title='Rapportgrupper'>
      <ListContainer title='Rapportgrupper'>
        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('ReportGroupCreate', { restaurantId })}
          title='Skapa ny rapportgrupp'
        />

        <VStack gap={4}>
          {data?.restaurant?.menu?.reportGroups?.map((reportGroup) => <ReportGroupCard key={reportGroup.id} onSelected={handleSelectedBin} reportGroup={reportGroup} />)}
        </VStack>
      </ListContainer>

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setIsDeleting(false)}
        open={isDeleting}
        prompt={`Är du säker på att du vill ta bort rapportgruppen "${selectedReportGroup?.name ?? ''}" (${selectedReportGroup?.vatRate ?? ''}%)?`}
        title='Ta bort rapportgrupp'
      />
    </Layout>
  )
}

export default ReportGroupList

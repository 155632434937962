import * as yup from 'yup'

export { yupResolver } from '@hookform/resolvers/yup'
export type { ArraySchema, ObjectSchema } from 'yup'

// This is required since e.g., the IntegerField and MoneyField can use an empty string as its default value
// Unfortunately react-hook-form does not play well with an undefined default value
// https://github.com/react-hook-form/react-hook-form/discussions/2797
export function emptyStringToUndefinedTransform<T> (_: unknown, value: T | ''): T | undefined {
  return value === '' ? undefined : value
}

// This is used when an empty string from the user means that the value should be "unset" in the api
export function emptyStringToNullTransform<T> (_: unknown, value: T | ''): T | null {
  return value === '' ? null : value
}

yup.setLocale({
  mixed: {
    required: 'Var vänlig fyll i ett värde'
  },
  number: {
    integer: 'Måste vara ett heltal',
    min: ({ min }) => `Minsta tillåtna värde är ${min}`,
    max: ({ max }) => `Högsta tillåtna värde är ${max}`,
    negative: 'Måste vara ett negativt nummer',
    positive: 'Måste vara ett positivt nummer'
  },
  string: {
    min: ({ min }) => `Minst ${min} tecken`,
    max: ({ max }) => `Högst ${max} tecken`,
    url: 'Måste vara en giltig URL'
  }
})

export default yup

import gql from 'graphql-tag'
import React, { useCallback } from 'react'

import { type DiscountInput, GetRestaurantDiscountsDocument, useCreateRestaurantDiscountMutation } from '../../types/graphql'
import DiscountCreateForm from '../components/DiscountCreateForm'
import Layout, { ScreenType } from '../components/Layout'
import logAndShowError from '../util/logAndShowError'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateRestaurantDiscount($restaurantId: ID!, $input: DiscountInput!) {
    createRestaurantDiscount(restaurantId: $restaurantId, input: $input) {
      id
    }
  }
`

const RestaurantDiscountCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantDiscountCreate'>()

  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('RestaurantDiscountsList', { restaurantId })
  }, [navigation])

  const [createDiscount, { error: createError, loading: creating }] = useCreateRestaurantDiscountMutation({ onCompleted: handleCancel, onError: logAndShowError })

  const handleCreate = useCallback((input: DiscountInput) => {
    createDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetRestaurantDiscountsDocument, variables: { restaurantId } }],
      variables: { restaurantId, input }
    }).catch(logError)
  }, [createDiscount, navigation, restaurantId])

  return (
    <Layout
      breadcrumbs={[{ link: ['RestaurantDiscountsList', { restaurantId }], title: 'Rabatter' }]}
      screenType={ScreenType.Form}
      title='Skapa rabatt'
    >
      <DiscountCreateForm
        error={createError}
        onCancel={handleCancel}
        onSave={handleCreate}
        saving={creating}
      />
    </Layout>
  )
}

export default RestaurantDiscountCreate

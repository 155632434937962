import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { type UseFormReturn } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unreachable from 'ts-unreachable'

import { type ProductOpeningHoursFragment, ProductVisibility } from '../../types/graphql'
import yup from '../lib/validation'
import getBunchedOpeningPeriods, { type BunchedPeriods } from '../util/getBunchedOpeningPeriods'

import { Select } from './fields'
import { ServingTime } from './fields/OpeningHourShortform'
import { OpeningHoursPeriodsField } from './fields/OpeningHoursPeriodsField'

interface SummaryProps {
  bunchedPeriods: BunchedPeriods[]
  displayHours?: ProductOpeningHoursFragment
  productVisibility?: ProductVisibility | null
}

export const Summary: React.FC<SummaryProps> = ({ bunchedPeriods, displayHours, productVisibility }) => (
  <HStack alignItems='start' gap={10} paddingVertical={8} wrap>
    <VStack backgroundColor='#eee' basis={1} borderRadius={5} grow={1} padding={8}>
      <HStack justifyContent='space-between'>
        <Text weight='500'>Visningstid:</Text>
        <MaterialIcons name='visibility' size={24} />
      </HStack>

      {productVisibility !== ProductVisibility.AlwaysVisible ? null : (
        <Text paddingLeft={20}>
          Alltid
        </Text>
      )}

      {productVisibility !== ProductVisibility.DuringOpeningHours ? null : (
        <Text>
          Samma som serveringstiden
        </Text>
      )}

      {!isCustomDisplayHour(productVisibility) || displayHours == null ? null : (
        <>
          <ServingTime openingHours={displayHours} />

          {productVisibility !== ProductVisibility.DuringOpeningHoursAndDisplayHours ? null : (
            <Text paddingLeft={20} paddingTop={20}>
              Visas även under serveringstiden
            </Text>
          )}
        </>
      )}
    </VStack>

    <VStack backgroundColor='#eee' basis={1} borderRadius={5} grow={1} padding={8}>
      <HStack justifyContent='space-between'>
        <Text weight='500'>Serveringstid:</Text>
        <MaterialIcons name='schedule' size={24} />
      </HStack>

      <ServingTime bunchedPeriods={bunchedPeriods} />
    </VStack>
  </HStack>
)

const DISPLAY_HOURS = [ProductVisibility.DuringOpeningHours, ProductVisibility.AlwaysVisible, ProductVisibility.DuringDisplayHours, ProductVisibility.DuringOpeningHoursAndDisplayHours]

function isCustomDisplayHour (productVisibility?: ProductVisibility | null): boolean {
  return productVisibility === ProductVisibility.DuringDisplayHours || productVisibility === ProductVisibility.DuringOpeningHoursAndDisplayHours
}

function productVisibilityLabel (productVisibility: ProductVisibility): string {
  switch (productVisibility) {
    case ProductVisibility.AlwaysVisible:
      return 'Alltid'
    case ProductVisibility.DuringDisplayHours:
      return 'Under visningstid'
    case ProductVisibility.DuringOpeningHours:
      return 'Samma som serveringstid'
    case ProductVisibility.DuringOpeningHoursAndDisplayHours:
      return 'Under visningstid och serveringstid'
    default:
      unreachable(productVisibility)
  }
}

export const productVisibilitySchema = yup.mixed<ProductVisibility>().oneOf([ProductVisibility.AlwaysVisible, ProductVisibility.DuringDisplayHours, ProductVisibility.DuringOpeningHours, ProductVisibility.DuringOpeningHoursAndDisplayHours], 'Välj ett alternativ').typeError('Välj ett alternativ')

interface Props {
  // FIXME: `any` is used here beecause I cannot figure out how to type it as just the required fields
  form: UseFormReturn<any>
  openingHours?: readonly ProductOpeningHoursFragment[] | null
  openingHoursId?: string | null
}

const OpeningHoursForm: React.FC<Props> = ({ form, openingHours, openingHoursId }) => {
  const selectedBunchedPeriods = getBunchedOpeningPeriods(form.watch().periods)
  const selectedDisplayHoursId = form.watch().displayHoursId
  const selectedDisplayHours = openingHours?.find(openingHour => openingHour.id === selectedDisplayHoursId)
  const selectedProductVisibility = form.watch().productVisibility

  return (
    <VStack>
      <OpeningHoursPeriodsField
        form={form}
        name='periods'
      />

      <Spacer height={24} />

      {openingHours == null ? null : (
        <VStack>
          <Select
            form={form}
            name='productVisibility'
            options={DISPLAY_HOURS.map(productVisibility => ({ title: productVisibilityLabel(productVisibility), value: productVisibility }))}
            title='Alternativ för visning av produkter'
          />

          {!isCustomDisplayHour(selectedProductVisibility) ? null : (
            <>
              <Spacer height={8} />

              <Select
                form={form}
                name='displayHoursId'
                options={(openingHours ?? []).filter(openingHour => openingHour.id !== openingHoursId).map(openingHour => ({ title: openingHour.name ?? '', value: openingHour.id }))}
                title='Visningstid'
              />
            </>
          )}
        </VStack>
      )}

      {selectedBunchedPeriods == null ? null : (
        <>
          <Spacer height={16} />

          <Text size={16}>Sammanfattning</Text>

          <Summary
            bunchedPeriods={selectedBunchedPeriods}
            displayHours={selectedDisplayHours}
            productVisibility={selectedProductVisibility}
          />

          <Spacer height={10} />

          <Text size={11}>Visningstid: Tiden då produkten visas för användare i appen</Text>
          <Text size={11}>Serveringstid: Tiden då produkten går att beställa för användare i appen. Gäller även förbeställningar.</Text>

          <Spacer height={10} />
        </>
      )}
    </VStack>
  )
}

export default OpeningHoursForm

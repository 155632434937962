import React, { useState } from 'react'
import { Image } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import openLink from '../../util/openLink'
import { PrimaryButton, SecondaryButton } from '../Buttons'
import Card from '../Card'

interface PersonalkollenServicesGuideProps {
  page: number
}

const descriptions = [
  'Logga in på Personalkollen och klicka på din profil 🚶‍♂️',
  'I sidomenyn, klicka på Inställningar',
  'Klicka på Integrationer',
  'Klicka på Kassakoppling API',
  'Klicka på Visa nyckel',
  'Klicka på Kopiera',
  'Klista in din nyckel i fältet nedan 👇 och klicka sedan på den stora knappen "Länka Personalkollen och Loco"'
]

const imageUris = [
  'https://locousercontent.com/4zkEOvW9pHZLHT_kUCjo1GVK2VZLLorMhXTZaKOCNTsvpkLReZ1WyBVtOAW0x0nU/original.png',
  'https://locousercontent.com/JLlORytRnoibo9cENF90oNcZodlxjbKEgSs-I2H1fZYml1nTVR_iXY48eJg23P0j/original.png',
  'https://locousercontent.com/oNnbW5s09sP7glVXkHd1GmzMmhZwTR1RYdmB1tJu_6AOqDU7q8I9q5uyVrpa6Qax/original.png',
  'https://locousercontent.com/e_yVC17d7nEcDRi4Ub10ol537O5Y0qTW4yGS6h4HeMDLdLlwv3_jWQWmERPkPqsG/original.png',
  'https://locousercontent.com/0MPsV2Oy2CdE9Y-JGA1JwImogU43WGH5j68ex3A93M-EehMWeQ70VKahtkUtTk0Q/original.png',
  'https://locousercontent.com/ohj6VJ29HIAfowqqUIItlENCxulW9I67ifqJrWpPjAglnMx0ypOx_D445F9f66eF/original.png',
  'https://locousercontent.com/QP8yupyXYErp-X0966mZg9nm6pbQPOeuj0YlYz5QPs9UUC-tEd6sloUlHIIR39R6/original.png'
]

const PersonalkollenServicesGuide: React.FC<PersonalkollenServicesGuideProps> = ({ page }) => {
  return (
    <VStack alignItems='center' grow={1} justifyContent='center'>
      <Text size={32}>Steg {page + 1}</Text>

      <Spacer height={12} />

      <Text size={24}>{descriptions[page]}</Text>

      <Spacer height={24} />

      {page !== 0 ? null : (
        <SecondaryButton
          icon='open-in-new'
          onPress={() => openLink('https://personalkollen.se/')}
          title='Öppna Personalkollen i nytt fönster'
        />
      )}

      {page < imageUris.length - 1
        ? <Image source={{ uri: imageUris[page], height: 1504 / 3, width: 2384 / 3 }} />
        : page > imageUris.length ? null
        : <Image source={{ uri: imageUris[page], height: 462 / 3, width: 1682 / 3 }} />}

      {page !== 7
        ? null
        : (
          <>
            <Spacer height={12} />
            <Text size={16}>Klicka "Länka Personalkollen och Loco" i boxen under för att spara ändringarnan❗❗❗</Text>
          </>
        )}
    </VStack>
  )
}

const PersonalkollenServices: React.FC = () => {
  const [guidePage, setGuidePage] = useState(0)

  const maxIndex = imageUris.length

  return (
    <Card title='Sync av Personalkollen med Loco'>
      <VStack>
        <HStack justifyContent='center'>
          <HStack basis={0} grow={1} justifyContent='end' paddingRight={8}>
            {guidePage === 0
              ? null
              : <PrimaryButton onPress={() => setGuidePage(page => page - 1)} title='< Tillbaka' />}
          </HStack>

          <HStack basis={0} grow={1} justifyContent='start' paddingLeft={8}>
            {guidePage >= maxIndex
              ? null
              : <PrimaryButton onPress={() => setGuidePage(page => page + 1)} title='Nästa >' />}
          </HStack>
        </HStack>

        <Spacer height={24} />

        <VStack grow={1} justifyContent='center'>
          <PersonalkollenServicesGuide page={guidePage} />
        </VStack>
      </VStack>
    </Card>
  )
}

export default PersonalkollenServices

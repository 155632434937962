import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { HStack, VStack } from 'react-stacked'

import { useGetStripeReportHtmlQuery } from '../../types/graphql'
import { SecondaryButton } from '../components/Buttons'
import Layout, { type Breadcrumb } from '../components/Layout'
import useNavigation from '../util/useNavigation'

gql`
  query GetStripeReportHtml($organizationId: ID!, $reportId: ID!) {
    organization (id: $organizationId) {
      id

      stripeReport(id: $reportId) {
        id

        html
      }
    }
  }
`

const StripeReportView: React.FC = () => {
  const [navigation, { organizationId, reportId }] = useNavigation<'StripeReportView'>()

  const [height, setHeight] = useState<number>()
  const { data, loading } = useGetStripeReportHtmlQuery({ variables: { organizationId, reportId } })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['StripeReportList', { organizationId }], title: 'Stripe' }
  ], [organizationId])

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} title='Stripe-rapport'>
      <VStack gap={12} maxWidth={1024} padding={16}>
        <HStack>
          <SecondaryButton
            icon='chevron-left'
            onPress={() => navigation.canGoBack() ? navigation.pop() : navigation.navigate('StripeReportList', { organizationId })}
            title='Tillbaka'
          />
        </HStack>

        {loading ? null : (
          <iframe
            onLoad={(ev) => setHeight(ev.currentTarget.contentDocument?.documentElement.scrollHeight)}
            srcDoc={data?.organization?.stripeReport?.html ?? undefined}
            style={{ border: 0, height, maxWidth: 760, width: '100%' }}
          />
        )}
      </VStack>
    </Layout>
  )
}

export default StripeReportView

import gql from 'graphql-tag'
import React, { useCallback, useState } from 'react'
import { ActivityIndicator, FlatList, type ListRenderItemInfo } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'
import sortOn from 'sort-on'
import unwrap from 'ts-unwrap'

import { type FullPhysicalControlUnitOutageFragment, GetControlUnitViewDataDocument, useCreatePhysicalControlUnitOutageMutation, useGetControlUnitViewDataQuery, useRestorePhysicalControlUnitOutageMutation } from '../../types/graphql'
import { DeleteButton } from '../components/Buttons'
import ControlUnitListItem from '../components/ControlUnitListItem'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import PhysicalControlUnitOutageItem from '../components/PhysicalControlUnitOutageItem'
import VerificationDialog from '../components/VerificationDialog'
import { GRAPHQL_API_ENDPOINT } from '../lib/config'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

// Only show "Create outage" button in the test-dev environment for now
const SHOW_CREATE_OUTAGE = GRAPHQL_API_ENDPOINT.includes('.buzz')

const keyExtractor = (item: { id: string }): string => item.id

const BREADCRUMBS: Breadcrumb[] = [
  { link: ['ControlUnitList', {}], title: 'Kontrollenheter' }
]

gql`
  query GetControlUnitViewData($controlUnitId: ID!) {
    physicalControlUnit(id: $controlUnitId) {
      id

      address
      createdAt
      manufacturer
      model
      serialNumber
      updatedAt

      outages(first: 999) {
        edges {
          node {
            id

            createdAt
            restoredAt
          }
        }
      }
    }
  }

  mutation CreatePhysicalControlUnitOutage($physicalControlUnitId: ID!) {
    createPhysicalControlUnitOutage(
      physicalControlUnitId: $physicalControlUnitId,
    ) {
      id
    }
  }

  mutation RestorePhysicalControlUnitOutage($controlUnitOutageId: ID!) {
    restorePhysicalControlUnitOutage(
      controlUnitOutageId: $controlUnitOutageId,
    ) {
      id

      restoredAt
    }
  }
`

const ControlUnitView: React.FC = () => {
  const [navigation, { controlUnitId }] = useNavigation<'ControlUnitView'>()

  const [creatingOutage, setCreatingOutage] = useState(false)
  const [restoringOutageId, setRestoringOutageId] = useState<string | null>(null)

  const { data, loading } = useGetControlUnitViewDataQuery({ variables: { controlUnitId } })

  const [createPhysicalControlUnitOutage, { error: creatingOutageError, loading: creatingOutageLoading }] = useCreatePhysicalControlUnitOutageMutation({ onCompleted: () => setCreatingOutage(false) })
  const [restorePhysicalControlUnitOutage, { error: restoringOutageError, loading: restoringOutageLoading }] = useRestorePhysicalControlUnitOutageMutation({ onCompleted: () => setRestoringOutageId(null) })

  const controlUnit = data?.physicalControlUnit
  const outages = sortOn(controlUnit?.outages?.edges.map(edge => edge.node) ?? [], '-createdAt')

  const handleCreateOutage = useCallback((): void => {
    createPhysicalControlUnitOutage({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetControlUnitViewDataDocument, variables: { controlUnitId } }],
      variables: {
        physicalControlUnitId: controlUnitId
      }
    }).catch(logError)
  }, [controlUnitId])

  const handleRestoreOutage = useCallback((): void => {
    restorePhysicalControlUnitOutage({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetControlUnitViewDataDocument, variables: { controlUnitId } }],
      variables: {
        controlUnitOutageId: unwrap(restoringOutageId)
      }
    }).catch(logError)
  }, [controlUnitId, restoringOutageId])

  const renderItem = useCallback(({ item }: ListRenderItemInfo<FullPhysicalControlUnitOutageFragment>): JSX.Element => {
    const handleRestoreOutagePress = (): void => {
      setRestoringOutageId(item.id)
    }

    return (
      <PhysicalControlUnitOutageItem
        item={item}
        onPress={handleRestoreOutagePress}
      />
    )
  }, [navigation])

  return (
    <Layout breadcrumbs={BREADCRUMBS} hideTitle screenType={ScreenType.List} title={controlUnit?.serialNumber}>
      <VerificationDialog
        callToActionLabel='Skapa driftstopp'
        errorMessage={creatingOutageError?.message}
        loading={creatingOutageLoading}
        onDismiss={() => setCreatingOutage(false)}
        onSubmit={handleCreateOutage}
        open={creatingOutage}
        prompt='Genom att bekräfta skapandet av ett driftstopp kommer transaktionerna att stoppas på denna kontrollenhet'
        title='Bekräfta skapande av driftstopp'
      />

      <VerificationDialog
        callToActionLabel='Återställ driftstopp'
        errorMessage={restoringOutageError?.message}
        loading={restoringOutageLoading}
        onDismiss={() => setRestoringOutageId(null)}
        onSubmit={handleRestoreOutage}
        open={restoringOutageId != null}
        prompt='Genom att bekräfta återställningen av driftstoppet kommer transaktionerna åter börja gå igenom denna kontrollenhet'
        title='Bekräfta återställning av driftstopp'
      />

      {loading
        ? (
          <ListContainer>
            <ActivityIndicator />
          </ListContainer>
        )
        : (
          <>
            {controlUnit == null
              ? null
              : (
                <ListContainer>
                  <ControlUnitListItem item={controlUnit} />
                </ListContainer>
              )}

            <ListContainer title={controlUnit?.serialNumber}>
              <HStack justifyContent='space-between'>
                <Text size={24}>Driftstopp</Text>

                {SHOW_CREATE_OUTAGE && (
                  <DeleteButton
                    onPress={() => setCreatingOutage(true)}
                    title='Skapa driftstopp'
                  />
                )}
              </HStack>

              <FlatList
                data={outages}
                ItemSeparatorComponent={() => <Spacer height={8} />}
                keyExtractor={keyExtractor}
                ListEmptyComponent={<Text size={18}>Inga driftstopp har skett på denna kontrollenhet</Text>}
                renderItem={renderItem}
              />
            </ListContainer>
          </>
        )}
    </Layout>
  )
}

export default ControlUnitView

import pTimeout from 'p-timeout'

import logError from './logError'

async function blobToImageData (input: Blob): Promise<ImageData> {
  const image = new Image()
  const blobUrl = URL.createObjectURL(input)

  try {
    image.src = blobUrl
    await image.decode()
  } finally {
    URL.revokeObjectURL(blobUrl)
  }

  const canvas = document.createElement('canvas')
  canvas.width = image.naturalWidth
  canvas.height = image.naturalHeight

  const context = canvas.getContext('2d')
  if (context === null) throw new Error('Failed to get 2D context')

  context.drawImage(image, 0, 0)

  return context.getImageData(0, 0, canvas.width, canvas.height)
}

interface WebpOptions {
  lossless?: 0 | 1
  target_size?: number
}

type Webp = (data: ImageData, options?: WebpOptions) => Promise<ArrayBuffer>

async function loadWebp (): Promise<Webp> {
  const key = `webp_${Math.random().toString(36).slice(2)}`

  return await new Promise((resolve, reject) => {
    ;(window as any)[key] = resolve

    const script = document.createElement('script')
    script.type = 'module'
    script.textContent = `import encode from 'https://unpkg.com/@jsquash/webp@1.2.0/encode.js?module'\nwindow['${key}'](encode)`
    script.onerror = (_event, _source, _line, _col, error) => reject(error ?? new Error('Failed to load WebP from unpkg'))
    document.body.appendChild(script)
  })
}

export default async function optimizeImage (input: Blob): Promise<Blob | ArrayBuffer> {
  try {
    const webp = await pTimeout(loadWebp(), { milliseconds: 15_000 })
    const data = await pTimeout(blobToImageData(input), { milliseconds: 1_000 })
    const result = await pTimeout(webp(data, { lossless: 0, target_size: 950_000 }), { milliseconds: 30_000 })
    return (result.byteLength < input.size) ? result : input
  } catch (error) {
    logError(error)
    return input
  }
}

import React from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { VStack } from 'react-stacked'

import { type WhiteLabelFieldsFragment } from '../../../types/graphql'
import { ImageSetField } from '../fields/ImageField'

import { type WhiteLabelFormPatch } from './WhiteLabelForm'

const ScreenShots: React.FC<{ form: UseFormReturn<WhiteLabelFormPatch>, whiteLabel: WhiteLabelFieldsFragment }> = ({ form, whiteLabel }) => {
  return (
    <VStack gap={16} padding={16}>
      <ImageSetField
        cropToolProps={{ maxWidth: 1242, ratio: 1242 / 2208 }}
        form={form}
        name='iPhone55Screenshots'
        title='iPhone 5.5" Screenshots (iPhone 8 Plus, 1242x2208) '
        viewOptions={{ height: 2208 / 6, width: 1242 / 6 }}
      />

      <ImageSetField
        cropToolProps={{ maxWidth: 1242, ratio: 1242 / 2688 }}
        form={form}
        name='iPhone65Screenshots'
        title='iPhone 6.5" Screenshots (iPhone Xs Max & iPhone 11 Pro Max, 1242x2688)'
        viewOptions={{ height: 2688 / 6, width: 1242 / 6 }}
      />

      <ImageSetField
        cropToolProps={{ maxWidth: 2048, ratio: 2048 / 2732 }}
        form={form}
        name='iPadPro129Screenshots'
        title='iPad Pro 12.9" Screenshots (2048x2732)'
        viewOptions={{ height: 2732 / 6, width: 2048 / 6 }}
      />
    </VStack>
  )
}

export default ScreenShots

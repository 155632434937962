import React from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { VStack } from 'react-stacked'

import { TextField } from './TextField'

interface OrganizationFormProps {
  form: UseFormReturn<any>
}

const OrganizationForm: React.FC<OrganizationFormProps> = ({ form }) => {
  return (
    <VStack gap={20}>
      <TextField
        form={form}
        hint='Namnet ska vara samma som registrerats hos bolagsverket'
        inputMode='numeric'
        name='organizationName'
        title='Organisationsnamn'
      />

      <TextField
        estimatedNumberOfCharacters={10}
        form={form}
        hint='Anges med 10 siffror på formen: 5512345678'
        name='organizationNumber'
        title='Organisationsnummer'
      />

      <TextField
        form={form}
        name='email'
        title='Din e-postadress'
      />
    </VStack>
  )
}

export default OrganizationForm

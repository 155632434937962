import React, { type ReactElement, type ReactNode } from 'react'
import { StyleSheet, TouchableHighlight, type TouchableHighlightProps, useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import WithSeparator from 'react-with-separator'

import Modal, { type ModalProps } from './Modal'

interface ActionProps {
  text: string
  disabled?: TouchableHighlightProps['disabled']
  onPress?: TouchableHighlightProps['onPress']
}

export const Action: React.FC<ActionProps> = ({ disabled = false, text, onPress }) => {
  return (
    <TouchableHighlight
      disabled={disabled}
      onPress={onPress}
      style={{ flexBasis: 0, flexGrow: 1, paddingVertical: 16, width: '100%' }}
      underlayColor='#F1F2F2'
    >
      <Text align='center' color={disabled ? '#CCC' : '#044DE0'} size={16} weight='500'>{text}</Text>
    </TouchableHighlight>
  )
}

interface DialogProps {
  actions?: ReactElement[]
  children?: ReactNode
  fullHeight?: boolean
  maxWidth?: number | string
  noModal?: boolean
  onDismiss?: () => void
  onShow?: ModalProps['onShow']
  title?: string | null
  visible?: ModalProps['visible']
}

const Dialog: React.FC<DialogProps> = (props) => {
  const { actions = [], children, fullHeight = false, maxWidth = 260, noModal, onDismiss, onShow, visible, title } = props
  if (!(visible ?? false)) return null

  const insets = useSafeAreaInsets()
  const dimensions = useWindowDimensions()

  if (!(noModal ?? false)) {
    return (
      <Modal onDismiss={onDismiss} onShow={onShow} visible={visible}>
        <Dialog {...props} noModal />
      </Modal>
    )
  }

  return (
    <>
      <Spacer grow={fullHeight ? 0 : 1} height={insets.top + 6} />

      <VStack
        backgroundColor='#ffffff'
        borderRadius={8}
        grow={fullHeight ? 1 : 0}
        maxHeight={dimensions.height * 0.8}
        maxWidth={maxWidth}
        width='100%'
      >
        {(title == null)
          ? null
          : (
            <VStack alignItems='center' alignSelf='stretch' backgroundColor='#F9F9FB' borderTopLeftRadius={8} borderTopRightRadius={8}>
              <Text align='center' color='#151822' padding={14} paddingHorizontal={18} size={15} weight='500'>{title}</Text>

              <HStack alignSelf='stretch' backgroundColor='#DAD9DC' height={StyleSheet.hairlineWidth} />
            </VStack>
          )}

        {children}

        {(actions.length === 0)
          ? null
          : (
            <VStack>
              <HStack alignSelf='stretch' backgroundColor='#CCD0D5' height={StyleSheet.hairlineWidth} />

              <HStack borderBottomLeftRadius={8} borderBottomRightRadius={8}>
                <WithSeparator separator={<VStack backgroundColor='#CCD0D5' width={StyleSheet.hairlineWidth} />}>{actions}</WithSeparator>
              </HStack>
            </VStack>
          )}
      </VStack>

      <Spacer grow={fullHeight ? 0 : 1} height={insets.bottom + 6} />
    </>
  )
}

export default Dialog

import gql from 'graphql-tag'
import sortOn from 'sort-on'

import { GetRedirectToLandingDataDocument, type GetRedirectToLandingDataQuery } from '../../types/graphql'
import client from '../client/graphql'

import { type NavigationHelpers } from './useNavigation'

gql`
  query GetRedirectToLandingData {
    me {
      id

      isSuperUser

      organizationsWithAccess(access:Read) {
        id

        ...OrganizationPermissions
      }

      restaurantsWithAccess(access: Read) {
        id

        active
        name

        organization {
          id
        }
      }

      whiteLabelsWithAccess(access: Read) {
        id

        appName
        label
      }
    }
  }
`

export default async function redirectToLanding (navigation: NavigationHelpers): Promise<void> {
  const { data } = await client.query<GetRedirectToLandingDataQuery>({
    fetchPolicy: 'network-only',
    query: GetRedirectToLandingDataDocument
  })

  if (data.me?.isSuperUser ?? false) {
    navigation.navigate('RestaurantList', {})

    return
  }

  const organizations = data?.me?.organizationsWithAccess ?? []
  const whiteLabels = data?.me?.whiteLabelsWithAccess ?? []

  if (organizations.length === 0 && whiteLabels.length === 0) {
    navigation.navigate('OrganizationCreate', {})

    return
  }

  if (organizations.length === 0 && whiteLabels.length === 1) {
    navigation.navigate('WhiteLabelDashboard', { whiteLabelId: whiteLabels[0].id })

    return
  }

  if (organizations.length > 1 || whiteLabels.length > 1) {
    navigation.navigate('OrganizationSelect', {})

    return
  }

  const organization = organizations[0]

  // For accounts that aren't owner and has accounting access we redirect to the SIE report view
  // because we assume that to be the most common use case for this type of user
  if (!(organization.isOwner ?? false) && (organization.hasOrganizationAccountingAccess ?? false)) {
    navigation.navigate('SieReportView', { organizationId: organization.id })

    return
  }

  const restaurants = sortOn(data.me?.restaurantsWithAccess ?? [], ['active', 'name'])

  if (restaurants.length > 0) {
    navigation.navigate('RestaurantGetStarted', { restaurantId: restaurants[0].id })

    return
  }

  // This should only happen if a user can administrate users but not restaurants
  if (organization.hasUserWriteAccess ?? false) {
    navigation.navigate('OrganizationUserList', { organizationId: organization.id })

    return
  }

  if (organization.isOwner ?? false) {
    navigation.navigate('RestaurantCreate', { organizationId: organizations[0].id })

    return
  }

  navigation.navigate('AccountEdit', {})
}

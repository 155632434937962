import React from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack } from 'react-stacked'
import WithSeparator from 'react-with-separator'

import Card from '../Card'
import Divider from '../Divider'
import { CheckBox, TextField as FormTextField } from '../fields'

import { type WhiteLabelFormPatch } from './WhiteLabelForm'

interface TextFieldProps {
  form: UseFormReturn<WhiteLabelFormPatch>
  helperText?: string
  multiline?: boolean
  name: keyof WhiteLabelFormPatch
  readOnlyIfSet?: boolean
  title: string
}

const TextField: React.FC<TextFieldProps> = ({ form, helperText, multiline, name, readOnlyIfSet, title }) => (
  <View style={{ width: 300, margin: 8 }}>
    <FormTextField
      form={form}
      helperText={helperText}
      multiline={multiline}
      name={name}
      readOnly={(readOnlyIfSet ?? false) && form.watch(name) != null}
      title={title}
    />
  </View>
)

const Settings: React.FC<{ form: UseFormReturn<WhiteLabelFormPatch> }> = ({ form }) => {
  return (
    <Card title='Settings'>
      <WithSeparator separator={<Divider spacing={16} />}>
        <HStack wrap>
          <TextField form={form} name='appName' title='Application name' />
          <TextField form={form} helperText='Usually thelocoapp-{label}' name='scheme' readOnlyIfSet title='Scheme' />
          <TextField form={form} name='shortUrlPrefix' readOnlyIfSet title='Short URL prefix' />
        </HStack>

        <TextField form={form} helperText='Usually com.loco.androidapp.{label}' name='androidPackageName' readOnlyIfSet title='Android package name' />

        <HStack wrap>
          <TextField form={form} helperText='Usually se.{label}.app' name='iosBundleIdentifier' readOnlyIfSet title='iOS bundle identifier' />
          <TextField form={form} helperText='Ten digits' name='iosAppstoreId' readOnlyIfSet title='Appstore id' />
        </HStack>

        <HStack alignItems='center' wrap>
          <View>
            <CheckBox form={form} name='deliveryEnabled' title='Delivery enabled' />
          </View>

          <Spacer width={12} />

          <TextField form={form} name='googleMapsApiKey' title='Google maps API key' />
        </HStack>

        <HStack gap={12} wrap>
          <TextField form={form} name='keywords' title='Keywords (Apple only)' />
          <TextField form={form} multiline name='shortDescription' title='Short Description (Google only)' />
          <TextField form={form} multiline name='description' title='Description' />
          <TextField form={form} multiline name='easProjectId' title='EAS Project Id' />
        </HStack>
      </WithSeparator>
    </Card>
  )
}

export default Settings

import { type OpeningHoursPeriodInput, type WeekDay } from '../../types/graphql'

export interface BunchedPeriods {
  days: WeekDay[]
  openTime: string
  closeTime: string | null
}

export default function getBunchedOpeningPeriods (openingPeriods: OpeningHoursPeriodInput[] | null | undefined): BunchedPeriods[] {
  const bunchedOpeningPeriods: BunchedPeriods[] = []

  for (const openingPeriod of openingPeriods ?? []) {
    let didGetBunched = false

    for (const similarPeriod of bunchedOpeningPeriods) {
      if (
        openingPeriod.open.time === similarPeriod.openTime &&
        openingPeriod.close?.time === similarPeriod.closeTime
      ) {
        similarPeriod.days.push(openingPeriod.open.day)
        didGetBunched = true
      }
    }

    if (!didGetBunched) {
      bunchedOpeningPeriods.push({ days: [openingPeriod.open.day], openTime: openingPeriod.open.time, closeTime: openingPeriod.close?.time ?? null })
    }
  }
  return bunchedOpeningPeriods
}

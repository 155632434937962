import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'
import { Pressable, View } from 'react-native'
import { HStack, VStack } from 'react-stacked'
import tinycolor, { type ColorInput } from 'tinycolor2'

import ColorPickerDialog from '../ColorPickerDialog'

import ErrorField from './ErrorField'

interface ColorFieldProps<TFieldValues extends FieldValues> {
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState' | 'setValue' | 'watch'>
  name: Path<TFieldValues>
  opaque?: boolean
  title: string
}

export default function ColorField<T extends FieldValues> (props: ColorFieldProps<T>): JSX.Element {
  const [isPickerVisible, setPickerVisible] = useState(false)

  return (
    <VStack>
      <Controller
        control={props.form.control}
        name={props.name}
        render={({ field: { onBlur, onChange, value } }) => (
          <HStack alignItems='baseline'>
            {!isPickerVisible
              ? null
              : (
                <ColorPickerDialog
                  initialColor={value ?? undefined}
                  name={props.title}
                  onDismiss={() => setPickerVisible(false)}
                  onSubmit={newColor => {
                    onChange(newColor)
                    setPickerVisible(false)
                  }}
                  opaque={props.opaque}
                />
              )}

            <TextField
              label={props.title}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />

            <Pressable onPress={() => setPickerVisible(true)}>
              <View style={{ right: 75, top: 12, width: 62, height: 32, borderWidth: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: value }} />
            </Pressable>
          </HStack>
        )}
        rules={{
          validate: (data: ColorInput) => {
            const tinycolorInstance = tinycolor(data)
            if (props.opaque === true && tinycolorInstance.getAlpha() !== 1) return 'Måste vara opak'
            return tinycolorInstance.isValid() ? undefined : 'Ogiltig färg'
          }
        }}
      />

      <ErrorField
        message={get(props.form.formState.errors, props.name)?.message}
      />
    </VStack>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, FlatList } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type MenuProductPropertiesFieldsFragment } from '../../../types/graphql'
import { type ChangeFn } from '../../util/useMultiEditProducts'

import ListItem from './ListItem'
import { Content } from './MultiEditForm'
import TriStateCheckBox, { type TriState, nextState } from './TriStateCheckBox'

interface PrinterQueue {
  id: string
  name?: string | null
}

interface EditPrinterQueuesProps {
  onDismiss: () => void
  onNext: (changeFn: ChangeFn) => void
  printerQueues?: readonly PrinterQueue[] | null
  productProperties: readonly MenuProductPropertiesFieldsFragment[]
  secondaryPrinters?: readonly PrinterQueue[] | null
}

const EditPrinterQueues: React.FC<EditPrinterQueuesProps> = ({ onDismiss, onNext, printerQueues, productProperties, secondaryPrinters }) => {
  const [selections, setSelections] = useState<Record<string, TriState>>({})

  const initial = useMemo(() => {
    const appliedPrinters = printerQueues ?? secondaryPrinters

    if (appliedPrinters == null) return null

    const description: Record<string, string | null> = {}
    const selections: Record<string, TriState> = {}

    const hasPrinterOrSecondaryPrinter = (appliedPrinterId: string, printerQueue: PrinterQueue[] | null | undefined): boolean => printerQueue?.some(printer => printer.id === appliedPrinterId) ?? false

    for (const { id } of appliedPrinters) {
      const count = productProperties.reduce((sum, properties) => sum + (hasPrinterOrSecondaryPrinter(id, printerQueues == null ? properties.secondaryPrinters : properties.printAt) ? 1 : 0), 0)
      const selection = count === 0 ? 'unchecked' : count === productProperties.length ? 'checked' : 'indeterminate'

      selections[id] = selection
      description[id] = selection === 'indeterminate' ? `${count} av ${productProperties.length} produkter använder denna skrivare` : null
    }

    return { description, selections }
  }, [printerQueues, productProperties, secondaryPrinters])

  const disableNext = useMemo(() => initial?.selections == null || Object.keys(selections).every(id => selections[id] === initial?.selections?.[id]), [initial, selections])

  useEffect(() => {
    if (initial != null) setSelections(initial.selections)
  }, [initial])

  const handleOnNext = useCallback(() => {
    const filterPrinters = (p: PrinterQueue, printers: PrinterQueue[] | null | undefined): boolean => {
      if (selections[p.id] === 'checked') return true
      if (selections[p.id] === 'unchecked') return false

      return printers?.some(other => p.id === other.id) ?? false
    }

    if (printerQueues != null) {
      return onNext(properties => {
        const result = unwrap(printerQueues).filter(p => filterPrinters(p, properties?.printAt))

        return {
          from: (properties?.printAt?.length ?? 0) === 0 ? 'Ingen skrivare' : unwrap(printerQueues).filter(p => properties?.printAt?.find(o => o.id === p.id)).map(p => unwrap(p.name)).join(', '),
          to: result.length === 0 ? 'Ingen skrivare' : result.map(p => unwrap(p.name)).join(', '),
          patch: { printAt: result.map(p => p.id) }
        }
      })
    } else if (secondaryPrinters != null) {
      return onNext(properties => {
        const result = unwrap(secondaryPrinters).filter(p => filterPrinters(p, properties?.secondaryPrinters))

        return {
          from: (properties?.secondaryPrinters?.length ?? 0) === 0 ? 'Ingen sekundärskrivare' : unwrap(secondaryPrinters).filter(p => properties?.secondaryPrinters?.find(o => o.id === p.id)).map(p => unwrap(p.name)).join(', '),
          to: result.length === 0 ? 'Ingen sekundärskrivare' : result.map(p => unwrap(p.name)).join(', '),
          patch: { secondaryPrinterIds: result.map(p => p.id) }
        }
      })
    }
  }, [onNext, printerQueues, secondaryPrinters, selections])

  const handleOnPress = useCallback((printerId: string) => () => {
    setSelections(value => ({ ...value, [printerId]: nextState(value[printerId]) }))
  }, [])

  return (
    <Content
      disableNext={disableNext}
      onDismiss={onDismiss}
      onNext={handleOnNext}
    >
      {initial == null
        ? <ActivityIndicator />
        : (
          <FlatList
            data={printerQueues ?? secondaryPrinters}
            ItemSeparatorComponent={() => <Spacer height={8} />}
            keyExtractor={item => item.id}
            renderItem={({ item }) => (
              <ListItem onPress={handleOnPress(item.id)}>
                <HStack alignItems='center' height={48} paddingHorizontal={8}>
                  <VStack>
                    <Text size={16}>{item.name}</Text>
                    <Text color='#333' size={12}>{initial.description[item.id]}</Text>
                  </VStack>

                  <Spacer grow={1} width={12} />

                  <TriStateCheckBox onPress={handleOnPress(item.id)} state={selections[item.id]} />
                </HStack>
              </ListItem>
            )}
          />
        )}
    </Content>
  )
}

export default EditPrinterQueues

import gql from 'graphql-tag'
import React, { useState } from 'react'
import { ActivityIndicator, FlatList, type ListRenderItemInfo, Pressable, View, useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Text, VStack } from 'react-stacked'

import { type FullOrderFragment, type ReceiptListOrderFragment } from '../../types/graphql'
import Dialog, { Action } from '../components/Dialog'
import ListEmptyComponent from '../components/ListEmptyComponent'
import ReceiptSummaryDialog from '../components/ReceiptSummaryDialog'

import ReceiptListItem from './ReceiptListItem'

gql`
  fragment ReceiptListOrder on Order {
    id
    status
    grossAmount
    createdAt
    receiptViewKey

    restaurant {
      name
    }

    receipt {
      id
      humanOrderNumber
      refund
    }

    refundReceipts {
      id
      humanOrderNumber
      refund
    }
  }
`

function keyExtractor (order: { id: string }): string {
  return order.id
}

const Separator: React.FC = () => (
  <VStack paddingHorizontal={8}>
    <VStack backgroundColor='black' height={1} />
  </VStack>
)

interface ReceiptListProps {
  canFetchMore: boolean
  fetchingMore: boolean
  loading: boolean
  loadingSelectedOrder: boolean
  onCloseDialog: () => void
  onFetchMore: () => void
  onRefresh: () => void
  onSelectOrder: (orderId: string) => void
  orders: ReceiptListOrderFragment[]
  refetching: boolean
  selectedOrder: FullOrderFragment | null
  showReceiptSummaryDialog: boolean
}

const ReceiptList: React.FC<ReceiptListProps> = ({ canFetchMore, fetchingMore, loading, loadingSelectedOrder, onCloseDialog, onFetchMore, onRefresh, onSelectOrder, orders, refetching, selectedOrder, showReceiptSummaryDialog }) => {
  const windowWidth = useWindowDimensions().width
  const [errorDialogVisible, setErrorDialogVisible] = useState(false)
  const insets = useSafeAreaInsets()

  const renderErrorDialog = (): React.ReactNode => {
    if (!errorDialogVisible) return null

    const actions = [
      <Action key='cancel' onPress={() => setErrorDialogVisible(false)} text='Avbryt' />,
      <Action key='retry' onPress={onRefresh} text='Försök igen' />
    ]

    return (
      <Dialog actions={actions} maxWidth={windowWidth * 0.8} title='Ett fel inträffade' visible>
        <Text padding={16}>
          {'Misslyckades att uppdatera beställningshistoriken.\n\nVar vänlig se över din internetuppkoppling'}
        </Text>
      </Dialog>
    )
  }

  const renderEmpty = (): React.ReactElement => <ListEmptyComponent text={loading ? 'Laddar kvitton ...' : 'Inga kvitton hittades'} />

  const renderFooter = (): React.ReactElement => {
    if (canFetchMore) {
      return (
        <Pressable
          disabled={fetchingMore}
          onPress={onFetchMore}
          style={{
            alignItems: 'center',
            backgroundColor: '#eee',
            justifyContent: 'center',
            minHeight: 40 + insets.bottom,
            paddingBottom: insets.bottom
          }}
        >
          {fetchingMore
            ? <ActivityIndicator />
            : <Text>Ladda fler</Text>}
        </Pressable>
      )
    }

    return <View style={{ height: insets.bottom }} />
  }

  const renderItem = (row: ListRenderItemInfo<ReceiptListOrderFragment>): React.ReactElement => {
    const handleOrderPress = (): void => {
      onSelectOrder(row.item.id)
    }

    return <ReceiptListItem onPress={handleOrderPress} order={row.item} />
  }

  return (
    <>
      {!showReceiptSummaryDialog
        ? null
        : (
          <ReceiptSummaryDialog
            loading={loadingSelectedOrder}
            onDismiss={onCloseDialog}
            order={selectedOrder}
          />
        )}

      {renderErrorDialog()}

      <FlatList
        data={orders}
        ItemSeparatorComponent={Separator}
        keyExtractor={keyExtractor}
        ListEmptyComponent={renderEmpty}
        ListFooterComponent={renderFooter}
        onRefresh={onRefresh}
        refreshing={loading || refetching}
        renderItem={renderItem}
        style={{ flex: 1, backgroundColor: 'white' }}
      />
    </>
  )
}

export default ReceiptList

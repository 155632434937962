import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/link-context'
import { Platform } from 'react-native'

import types from '../../types/graphql'
import { GRAPHQL_API_ENDPOINT, INTERNAL_VERSION } from '../lib/config'
import { getAccessToken } from '../lib/token-storage'

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessToken()
  return token == null ? {} : { headers: { ...headers, authorization: `Bearer ${token}` } }
})

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: types.possibleTypes,
    typePolicies: {
      Restaurant: {
        fields: {
          deliveryCostAreas: {
            // Use incoming data and mute console warning
            merge: false
          },
          deliveryTimeAreas: {
            // Use incoming data and mute console warning
            merge: false
          }
        }
      },
      Statistics: {
        keyFields: []
      },
      Menu: {
        merge: true
      },
      MenuAlternativeGroups: {
        fields: {
          alternativeItems: {
            // Use incoming data and mute console warning
            merge: false
          }
        }
      },
      MenuProductProperties: {
        fields: {
          alternativeGroups: {
            // Use incoming data and mute console warning
            merge: false
          },
          printAt: {
            // Use incoming data and mute console warning
            merge: false
          },
          secondaryPrinters: {
            // Use incoming data and mute console warning
            merge: false
          }
        }
      },
      RestaurantEdge: {
        fields: {
          node: {
            merge: true
          }
        }
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    }
  },
  link: authLink.concat(new HttpLink({ uri: GRAPHQL_API_ENDPOINT })),
  name: `react-native-${Platform.OS}-admin`,
  version: INTERNAL_VERSION
})

export default client

import { type ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import { useForm } from 'react-hook-form'

import { useCreateWhiteLabelGuestMutation, useGetWhiteLabelGuestGroupMemberCreateDataQuery } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import Select from '../components/Select'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import Heading from '../components/atoms/Heading'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetWhiteLabelGuestGroupMemberCreateData($whiteLabelId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      guestGroups(first: 10000) {
        edges {
          node {
            id

            name
          }
        }
      }
    }
  }

  mutation CreateWhiteLabelGuest($whiteLabelId: ID!, $input: WhiteLabelGuestInput!) {
    createWhiteLabelGuest(whiteLabelId: $whiteLabelId, input: $input) {
      id

      email
      name
    }
  }
`

interface FormInput {
  email: string
  groupId: string
  name: string
}

const schema: ObjectSchema<FormInput> = yup.object({
  email: yup.string().email().trim().required(),
  groupId: yup.string().required(),
  name: yup.string().trim().required()
})

interface GuestGroupMemberFormProps {
  defaultGroupId?: string | null
  error: ApolloError | undefined
  guestGroupOptions: Array<{ value: string | null, title: string }>
  onCancel: () => void
  onSave: (input: FormInput) => void
  saving?: boolean
}

const GuestGroupMemberForm: React.FC<GuestGroupMemberFormProps> = ({ defaultGroupId, error, guestGroupOptions, onCancel, onSave, saving }) => {
  const form = useForm<FormInput>({
    criteriaMode: 'all',
    mode: 'onTouched',
    resolver: yupResolver(schema),
    values: schema.cast({ email: '', groupId: defaultGroupId ?? '', name: '' }, { stripUnknown: true })
  })

  return (
    <FormContainer gap={12}>
      <TextField
        form={form}
        name='name'
        title='Namn'
      />

      <TextField
        form={form}
        name='email'
        title='E-post'
      />

      <Select
        disabled={defaultGroupId != null}
        estimatedNumberOfCharacters={20}
        form={form}
        name='groupId'
        options={guestGroupOptions}
        title='Grupp'
      />

      <SubmitFormButtons
        error={error}
        onCancel={onCancel}
        onSave={ignoreAsync(form.handleSubmit(onSave))}
        saving={saving}
      />
    </FormContainer>
  )
}

const WhiteLabelGuestGroupMemberCreate: React.FC = () => {
  const [navigation, { whiteLabelId, guestGroupId }] = useNavigation<'WhiteLabelGuestGroupMemberCreate'>()

  const { data, loading } = useGetWhiteLabelGuestGroupMemberCreateDataQuery({
    variables: { whiteLabelId }
  })

  const [createGuestGroupMember, { error: createError, loading: creating }] = useCreateWhiteLabelGuestMutation()

  const handleCancel = (): void => {
    if (navigation.canGoBack()) {
      navigation.pop()

      return
    }

    if (guestGroupId == null) {
      navigation.replace('WhiteLabelGuestGroupCustomerRegister', { whiteLabelId })

      return
    }

    navigation.replace('WhiteLabelGuestGroupView', { whiteLabelId, guestGroupId })
  }

  const handleSave = (input: FormInput): void => {
    createGuestGroupMember({
      awaitRefetchQueries: true,
      onCompleted: handleCancel,
      onError: logAndShowError,
      refetchQueries: [
        'GetWhiteLabelGuestGroupCustomerRegisterData',
        'GetWhiteLabelGuestGroupViewData'
      ],
      variables: {
        whiteLabelId,
        input: {
          ...input,
          groupId: input.groupId
        }
      }
    }).catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' },
        { link: ['WhiteLabelGuestGroupList', { whiteLabelId }], title: 'Lojalitet' },
        guestGroupId == null
          ? { link: ['WhiteLabelGuestGroupCustomerRegister', { whiteLabelId }], title: 'Kundregister' }
          : { link: ['WhiteLabelGuestGroupView', { whiteLabelId, guestGroupId }], title: 'Grupp' }
      ]}
      hideTitle
      loading={loading}
      screenType={ScreenType.Form}
      title='Lägg till medlem'
    >
      <FormContainer gap={8}>
        <Heading size='m'>Lägg till medlem</Heading>

        <GuestGroupMemberForm
          defaultGroupId={guestGroupId}
          error={createError}
          guestGroupOptions={data?.whiteLabel?.guestGroups?.edges.map(({ node }) => ({ title: node.name ?? 'n/a', value: node.id })) ?? []}
          onCancel={handleCancel}
          onSave={handleSave}
          saving={creating}
        />
      </FormContainer>
    </Layout>
  )
}

export default WhiteLabelGuestGroupMemberCreate

import gql from 'graphql-tag'
import React, { useState } from 'react'

import { type OrganizationUserInput, useDeleteOrganizationUserMutation, useGetOrganizationUserEditDataQuery, useResendOrganizationUserInviteMutation, useUpdateOrganizationUserMutation } from '../../types/graphql'
import Layout, { ScreenType } from '../components/Layout'
import OrganizationUserForm from '../components/OrganizationUserForm'
import VerificationDialog from '../components/VerificationDialog'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullOrganizationUserDetails on OrganizationUser {
    id

    email
    hasCreateCashAdjustmentAccess
    hasDiscountAccess
    hasDiscountedCheckoutAccess
    hasEditMenuAccess
    hasEditMenuStockAccess
    hasEditOpeningHoursAccess
    hasEditOrganizationUserAccess
    hasEditReportsAccess
    hasExternalPaymentAccess
    hasOpenOrderAccess
    hasOpenPriceAccess
    hasOrderHistoryAccess
    hasOrderRefundAccess
    hasOrganizationAccountingAccess
    hasPosAccess
    hasReadReportsAccess
    hasSettingsAccess
    isOwner
    name
    userSignupCompleted

    restaurantPermissions {
      id

      hasCreateCashAdjustmentAccess
      hasDiscountAccess
      hasDiscountedCheckoutAccess
      hasEditMenuAccess
      hasEditMenuStockAccess
      hasEditOpeningHoursAccess
      hasEditReportsAccess
      hasExternalPaymentAccess
      hasOpenOrderAccess
      hasOpenPriceAccess
      hasOrderHistoryAccess
      hasOrderRefundAccess
      hasPosAccess
      hasReadReportsAccess
      hasSettingsAccess

      restaurant {
        id

        name
      }
    }
  }

  mutation UpdateOrganizationUser($organizationId: ID!, $organizationUserId: ID!, $patch: OrganizationUserPatch!) {
    updateOrganizationUser(organizationId: $organizationId, organizationUserId: $organizationUserId, patch: $patch) {
      ...FullOrganizationUserDetails
    }
  }

  mutation DeleteOrganizationUser($organizationId: ID!, $organizationUserId: ID!) {
    deleteOrganizationUser(organizationId: $organizationId, organizationUserId: $organizationUserId) {
      id
    }
  }

  mutation ResendOrganizationUserInvite($organizationId: ID!, $organizationUserId: ID!) {
    resendOrganizationUserInvite(organizationId: $organizationId, organizationUserId: $organizationUserId) {
      id
    }
  }

  query GetOrganizationUserEditData($organizationId: ID!, $userId: ID!) {
    organization(id: $organizationId) {
      id

      isOwner: hasAccess(access:Write)

      restaurants {
        id

        name
      }

      user(id: $userId) {
        ...FullOrganizationUserDetails
      }
    }
  }
`

const OrganizationUserEdit: React.FC = () => {
  const [navigation, { organizationId, organizationUserId }] = useNavigation<'OrganizationUserEdit'>()

  const { data, loading } = useGetOrganizationUserEditDataQuery({
    variables: { organizationId, userId: organizationUserId }
  })

  const [deleteOrganizationUser, { loading: deleting, error: deleteError }] = useDeleteOrganizationUserMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('OrganizationUserList', { organizationId }),
    onError: (error) => logAndShowError(error),
    refetchQueries: ['GetOrganizationUserList'],
    variables: { organizationId, organizationUserId }
  })

  const [updateOrganizationUser, { loading: saving }] = useUpdateOrganizationUserMutation({
    onCompleted: () => navigation.navigate('OrganizationUserList', { organizationId }),
    onError: (error) => logAndShowError(error)
  })

  const [resendOrganizationUserInvite] = useResendOrganizationUserInviteMutation({
    onCompleted: () => showAlert('Inbjudan skickad', 'Inbjudan har skickats till användaren.'),
    onError: (error) => logAndShowError(error),
    variables: { organizationId, organizationUserId }
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleCancel = (): void => {
    navigation.navigate('OrganizationUserList', { organizationId })
  }

  const handleDelete = ignoreAsync(async () => {
    await deleteOrganizationUser()
  })

  const handleResendInvite = ignoreAsync(async () => {
    await resendOrganizationUserInvite()
  })

  const handleSubmit = ignoreAsync(async (formData: OrganizationUserInput): Promise<void> => {
    const { email: _, ...organizationUserData } = formData

    await updateOrganizationUser({
      variables: {
        organizationId,
        organizationUserId,
        patch: organizationUserData
      }
    })
  })

  return (
    <Layout
      breadcrumbs={[{ link: ['OrganizationUserList', { organizationId }], title: 'Användare' }]}
      loading={loading}
      screenType={ScreenType.Form}
      title={data?.organization?.user?.name}
    >
      <OrganizationUserForm
        canUpdateOwners={data?.organization?.isOwner ?? false}
        initialValues={data?.organization?.user}
        onCancel={handleCancel}
        onDelete={() => setShowDeleteDialog(true)}
        onResendInvite={handleResendInvite}
        onSubmit={handleSubmit}
        restaurants={data?.organization?.restaurants}
        saving={saving}
      />

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt='Är du säker på att du vill ta bort användaren?'
        title='Radera Användare'
      />
    </Layout>
  )
}

export default OrganizationUserEdit

import gql from 'graphql-tag'
import React from 'react'
import { ActivityIndicator, Image } from 'react-native'
import { HStack, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { useGetOrganizationSelectDataQuery } from '../../types/graphql'
import { ListItemsPrimaryButton } from '../components/Buttons'
import Divider from '../components/Divider'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

gql`
  query GetOrganizationSelectData {
    me {
      organizationsWithAccess(access: Read) {
        id

        name

        restaurants {
          id

          name

          cashregister {
            id

            isRegistered

            controlUnit {
              isRegistered
            }
          }

          setupProgress {
            hasEnteredActivationCode
          }
        }
      }

      whiteLabelsWithAccess(access: Read) {
        id

        appName
        label
      }
    }
  }
`

const OrganizationSelect: React.FC = () => {
  const [navigation] = useNavigation<'OrganizationSelect'>()

  const { data, loading: loadingData } = useGetOrganizationSelectDataQuery()

  const organizationList = sortOn(data?.me?.organizationsWithAccess ?? [], 'name')

  const handleOrganizationSelect = (organizationId: string): void => {
    const selectedOrganization = data?.me?.organizationsWithAccess?.find(
      (organization) => organization.id === organizationId
    )

    const organizationRestaurants = selectedOrganization?.restaurants ?? []
    const organizationRestaurant = sortOn(organizationRestaurants, 'name')[0]

    const hasCompletedSetup = (organizationRestaurant?.cashregister?.controlUnit?.isRegistered ?? false) &&
      (organizationRestaurant?.cashregister?.isRegistered ?? false) &&
      (organizationRestaurant?.setupProgress?.hasEnteredActivationCode ?? false)

    if (organizationRestaurant == null) {
      navigation.navigate('RestaurantCreate', { organizationId })
    } else {
      if (hasCompletedSetup) {
        navigation.navigate('RestaurantDashboard', { restaurantId: organizationRestaurant.id })
      } else {
        navigation.navigate('RestaurantGetStarted', { restaurantId: organizationRestaurant.id })
      }
    }
  }

  return (
    <VStack backgroundColor='black' grow={1}>
      <Image source={require('../../assets/icon.png')} style={{ height: normalize(75, 90), width: normalize(75, 90) }} />

      <HStack alignItems='center' justifyContent='center'>
        <VStack grow={1} maxWidth={480}>
          {loadingData
            ? <ActivityIndicator color='white' size='large' />
            : (
              organizationList?.map(({ id, name }) => (
                <VStack key={id} backgroundColor='#fff' padding={24}>
                  <ListItemsPrimaryButton
                    onPress={() => handleOrganizationSelect(id)}
                    title={name ?? ''}
                  />
                </VStack>
              ))
            )}

          <Divider />

          {data?.me?.whiteLabelsWithAccess?.map(({ id, appName, label }) => (
            <VStack key={id} backgroundColor='#fff' padding={24}>
              <ListItemsPrimaryButton
                onPress={() => navigation.navigate('WhiteLabelDashboard', { whiteLabelId: id })}
                title={`${appName ?? label ?? ''} (app)`}
              />
            </VStack>
          ))}
        </VStack>
      </HStack>
    </VStack>
  )
}

export default OrganizationSelect

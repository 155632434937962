import { MaterialIcons } from '@expo/vector-icons'
import { getDocumentAsync } from 'expo-document-picker'
import React, { useState } from 'react'
import { Controller, type UseFormReturn } from 'react-hook-form'
import { Image, View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import logError from '../../util/logError'
import openLink from '../../util/openLink'
import { DeleteButton, PrimaryButton, SecondaryButton } from '../Buttons'
import Card from '../Card'

import { type WhiteLabelFormPatch } from './WhiteLabelForm'

interface AndroidGoogleServicesGuideProps {
  androidPackageName: string
  appName: string
  page: number
}

const descriptions = [
  'Gå in på Firebase 🔥🚶‍♂️',
  'Välj "Loco-3"',
  'Klicka "Add app" och sedan "Android"',
  'Mata in "Android package name" och "App nickname"',
  'Klicka "Register app"',
  'Klicka "Download google-services.json"',
  'Ladda upp google-services.json här nedan 📂',
  'Android Google Services är uppladdat! 🎉 '
]

const imageUris = [
  'https://locousercontent.com/XR4uKYavxlKLlTNMMAQUXf-cZEFcTFJzQFQD9qaE7x5t8IShW3wkWPoJ0ZIIvvBU/original.png',
  'https://locousercontent.com/RyMHtUwHuGaqxKhKwdla4cnLRJvOlXgOlmiCTZt3QMeHBve9aV9gdhdfP2z5X-KZ/original.png',
  'https://locousercontent.com/B_xvAtinqEazmdnYPebg7q7gs0Gd7fCL39uypl2QwUP28qSjHiWrdryi0epCkzBW/original.png',
  'https://locousercontent.com/_HhhbaS05jmXpjw6pGc7kleZDJ_Pk1ZW4GDw722QZbMVCBh2A7uY5QSwu4lE3k2v/original.png',
  'https://locousercontent.com/1VWN1fHytAh4Uz0bm8thWb-VjD_Cw9ltQ9Nl349Ia0RGeCRAB3SeLG66SUbONkdW/original.png',
  'https://locousercontent.com/_pJT1fFtXUGYCpkicpbEFeHg0cln82UIE4ejEJgx0pDohgYaH1t7RTiwFwjoOmO4/original.png'
]

const AndroidGoogleServicesGuide: React.FC<AndroidGoogleServicesGuideProps> = ({ androidPackageName, appName, page }) => {
  return (
    <VStack alignItems='center' grow={1} justifyContent='center'>
      <Text size={32}>Steg {page + 1}</Text>

      <Spacer height={12} />

      <Text size={24}>{descriptions[page]}</Text>

      <Spacer height={24} />

      {page !== 0 ? null : (
        <SecondaryButton
          icon='open-in-new'
          onPress={() => openLink('https://console.firebase.google.com/')}
          title='Öppna Firebase i nytt fönster'
        />
      )}

      {page !== 3
        ? null
        : (
          <>
            <Spacer height={12} />
            <Text size={16}>
              Android package name: <TextStyle weight='bold'>{androidPackageName}</TextStyle>
            </Text>
            <Text size={16}>
              App nickname: <TextStyle weight='bold'>{appName}</TextStyle>
            </Text>
          </>
        )}

      {page >= imageUris.length
        ? null
        : <Image source={{ uri: imageUris[page], height: 866, width: 947 }} />}

      {page !== 7
        ? null
        : (
          <>
            <Spacer height={12} />
            <Text size={16}>Klicka spara längst ner på sidan för att spara ändringarna på whitelabeln❗❗❗</Text>
          </>
        )}
    </VStack>
  )
}

const AndroidGoogleServices: React.FC<{ form: UseFormReturn<WhiteLabelFormPatch> }> = ({ form }) => {
  const androidPackageName = form.watch('androidPackageName') ?? ''
  const appName = form.watch('appName') ?? ''

  const [guidePage, setGuidePage] = useState(0)

  return (
    <Card title='Android Google Services'>
      <Controller
        control={form.control}
        name='androidGoogleServices'
        render={({ field: { onChange, value } }) => {
          const maxIndex = descriptions.length - (value == null ? 2 : 1)

          const handleUpload = (): void => {
            getDocumentAsync().then(async (result) => {
              if (result.canceled) return

              const response = await fetch(result.assets[0].uri)
              const data = await response.json()

              onChange(data)
              setGuidePage(descriptions.length - 1)
            }).catch(logError)
          }

          const handleAndroidGoogleServicesDelete = (): void => {
            onChange(null)
          }

          return (
            <VStack>
              <HStack justifyContent='center'>
                <HStack basis={0} grow={1} justifyContent='end' paddingRight={8}>
                  {guidePage === 0
                    ? null
                    : <PrimaryButton onPress={() => setGuidePage(page => page - 1)} title='< Tillbaka' />}
                </HStack>

                <HStack basis={0} grow={1} justifyContent='start' paddingLeft={8}>
                  {guidePage >= maxIndex
                    ? null
                    : <PrimaryButton onPress={() => setGuidePage(page => page + 1)} title='Nästa >' />}
                </HStack>
              </HStack>

              <Spacer height={24} />

              <VStack grow={1} justifyContent='center'>
                <AndroidGoogleServicesGuide
                  androidPackageName={androidPackageName}
                  appName={appName}
                  page={guidePage}
                />
              </VStack>

              <Spacer height={32} />

              {guidePage !== 6
                ? null
                : (
                  <VStack alignItems='center' justifyContent='center'>
                    {value == null
                      ? (
                        <PrimaryButton
                          onPress={handleUpload}
                          title='Ladda upp Android Google Services'
                        />
                      )
                      : (
                        <HStack alignItems='center' borderRadius={3} justifyContent='center'>
                          <View
                            style={{
                              alignItems: 'center',
                              borderWidth: 0.2,
                              borderRadius: 3,
                              flexDirection: 'row',
                              justifyContent: 'center',
                              paddingHorizontal: 24,
                              paddingVertical: 8
                            }}
                          >
                            <Text size={20}>Android Google Services</Text>

                            <Spacer width={16} />

                            <MaterialIcons color='green' name='check' size={32} />

                            <Spacer width={16} />

                            <DeleteButton
                              icon='delete'
                              onPress={handleAndroidGoogleServicesDelete}
                              title='Ta bort'
                            />
                          </View>
                        </HStack>
                      )}

                    <Spacer height={16} />
                  </VStack>
                )}
            </VStack>
          )
        }}
      />
    </Card>
  )
}

export default AndroidGoogleServices

import React from 'react'
import { type Control, Controller, type FieldValues, type UseFormReturn, get } from 'react-hook-form'
import { TextInput } from 'react-native'
import { HStack, Text } from 'react-stacked'

import { INPUT_BORDER_COLOR } from '../lib/color'

import FormControl from './FormControl'

const textInputBaseStyle = {
  borderRadius: 4,
  borderWidth: 1,
  flex: 1,
  fontSize: 18,
  minHeight: 40,
  paddingHorizontal: 5,
  zIndex: 10
}

export const textInputStyle = {
  ...textInputBaseStyle,
  borderColor: INPUT_BORDER_COLOR
}

export const textInputErrorStyle = {
  ...textInputBaseStyle,
  borderColor: 'red',
  color: 'red'
}

const textInputReadOnlyStyle = {
  ...textInputBaseStyle,
  borderColor: '#bbb',
  color: '#999'
}

export const characterWidth = (numberOfCharacters: number): number => {
  const govUkHorizontalPadding = 5
  const govUkBorderWidth = 2

  const govUkExtraWidth = govUkHorizontalPadding * 2 + govUkBorderWidth * 2
  const textFieldExtraWidth = textInputBaseStyle.paddingHorizontal * 2 + textInputBaseStyle.borderWidth * 2

  const estimatedWidthPerCharacter = (referenceWidth: number, numberOfCharacters: number): number => (referenceWidth - govUkExtraWidth) / numberOfCharacters

  if (numberOfCharacters >= 20) {
    return estimatedWidthPerCharacter(328, 20) * numberOfCharacters + textFieldExtraWidth
  } else if (numberOfCharacters >= 10) {
    return estimatedWidthPerCharacter(184, 10) * numberOfCharacters + textFieldExtraWidth
  } else if (numberOfCharacters >= 5) {
    return estimatedWidthPerCharacter(88, 5) * numberOfCharacters + textFieldExtraWidth
  } else if (numberOfCharacters >= 4) {
    return estimatedWidthPerCharacter(72, 4) * numberOfCharacters + textFieldExtraWidth
  } else if (numberOfCharacters >= 3) {
    return estimatedWidthPerCharacter(60, 3) * numberOfCharacters + textFieldExtraWidth
  } else {
    return estimatedWidthPerCharacter(44, 2) * numberOfCharacters + textFieldExtraWidth
  }
}

interface TextFieldProps<TFieldValues extends FieldValues> {
  /**
   *  The autoFocus prop is used to automatically focus on the input when the page loads.
   */
  autoFocus?: boolean
  form: UseFormReturn<TFieldValues>
  /**
   *  The estimated number of characters is used to estimate the width of the text field. This is useful when the text field is used in a form with multiple inputs. As per the GOV.UK Design System, the width of the text field will be adjusted based on the estimated number of characters that you think will be used in the field.
   */
  estimatedNumberOfCharacters?: number
  /**
   *  Hints are used to help users understand what is expected of them when filling in a form field. The hint will be displayed below the title and above the input, in gray.
   */
  hint?: string
  /**
   * The `inputMode` hints at the type of data that might be entered by the user while editing the input. This allows a device to display an appropriate virtual keyboard.
   *
   * @default 'text'
   */
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url'
  /**
   *  The large title is typically used for forms with one input.
   */
  largeTitle?: boolean
  multiline?: boolean
  name: (keyof TFieldValues & string) | string
  numberOfLines?: number
  /**
   * Fires when the user wants to submit the form, e.g. when enter is pressed on the keyboard.
   */
  onSubmitEditing?: () => void
  prefix?: string
  /**
   * The Boolean readOnly attribute, when present, makes the element not mutable, meaning the user can not edit the control.
   */
  readOnly?: boolean
  /**
   * If `true`, the text input obscures the text entered so that sensitive text like passwords stay secure.
   *
   * @default false
   */
  secureTextEntry?: boolean
  suffix?: string
  /**
   *  The title is used to describe the input. If using largeTitle it is recommended to be formulated as a question.
   */
  title?: string
}

interface ControlledTextFieldProps {
  /**
   *  The autoFocus prop is used to automatically focus on the input when the page loads.
   */
  autoFocus?: boolean
  errorMessage?: string
  /**
   *  The estimated number of characters is used to estimate the width of the text field. This is useful when the text field is used in a form with multiple inputs. As per the GOV.UK Design System, the width of the text field will be adjusted based on the estimated number of characters that you think will be used in the field.
   */
  estimatedNumberOfCharacters?: number
  /**
   *  Hints are used to help users understand what is expected of them when filling in a form field. The hint will be displayed below the title and above the input, in gray.
   */
  hint?: string
  /**
   * The `inputMode` hints at the type of data that might be entered by the user while editing the input. This allows a device to display an appropriate virtual keyboard.
   *
   * @default 'text'
   */
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url'
  /**
   *  The large title is typically used for forms with one input.
   */
  largeTitle?: boolean
  multiline?: boolean
  numberOfLines?: number
  /**
   *  The onBlur prop is used to handle when the user has finished editing the input.
   */
  onBlur?: () => void
  /**
   *  The onChange prop is used to handle when the user has changed the input.
   */
  onChange: (input: string) => void
  /**
   * Fires when the user wants to submit the form, e.g. when enter is pressed on the keyboard.
   */
  onSubmitEditing?: () => void
  prefix?: string
  /**
   * The Boolean readOnly attribute, when present, makes the element not mutable, meaning the user can not edit the control.
   */
  readOnly?: boolean
  /**
   * If `true`, the text input obscures the text entered so that sensitive text like passwords stay secure.
   *
   * @default false
   */
  secureTextEntry?: boolean
  suffix?: string
  /**
   *  The title is used to describe the input. If using largeTitle it is recommended to be formulated as a question.
   */
  title?: string
  /**
   *  The value prop is used to set the value of the input.
   */
  value: string | null | undefined
}

export function TextField<T extends FieldValues> (props: TextFieldProps<T> | ControlledTextFieldProps): JSX.Element {
  if ('form' in props) {
    const errorMessage = get(props.form.formState.errors, props.name)?.message

    return (
      <Controller
        control={props.form.control as Control<Record<string, any>> | undefined}
        name={props.name}
        render={({ field: { onBlur, onChange, value } }) => (
          <TextField
            autoFocus={props.autoFocus}
            errorMessage={errorMessage}
            estimatedNumberOfCharacters={props.estimatedNumberOfCharacters}
            hint={props.hint}
            inputMode={props.inputMode}
            largeTitle={props.largeTitle}
            multiline={props.multiline}
            numberOfLines={props.numberOfLines}
            onBlur={onBlur}
            onChange={(input) => onChange(input)}
            onSubmitEditing={props.onSubmitEditing}
            prefix={props.prefix}
            readOnly={props.readOnly}
            secureTextEntry={props.secureTextEntry}
            suffix={props.suffix}
            title={props.title}
            value={value}
          />
        )}
      />
    )
  }

  return (
    <FormControl
      errorMessage={props.errorMessage}
      hint={props.hint}
      largeTitle={props.largeTitle}
      title={props.title}
    >
      <HStack alignItems='center'>
        {props.prefix == null ? null : (
          <HStack
            alignItems='center'
            backgroundColor='#f3f2f1'
            borderBottomLeftRadius={4}
            borderColor={INPUT_BORDER_COLOR}
            borderRightWidth={0}
            borderStyle='solid'
            borderTopLeftRadius={4}
            borderWidth={1}
            justifyContent='center'
            minHeight={40}
            minWidth={40}
          >
            <Text
              size={18}
              weight='600'
            >
              {props.prefix}
            </Text>
          </HStack>
        )}

        <TextInput
          autoCapitalize='none'
          autoComplete='off'
          autoFocus={props.autoFocus}
          inputMode={props.inputMode}
          multiline={props.multiline}
          numberOfLines={props.numberOfLines}
          onBlur={props.onBlur}
          onChangeText={props.onChange}
          onSubmitEditing={props.onSubmitEditing}
          // @ts-expect-error https://github.com/facebook/react-native/pull/39281
          readOnly={props.readOnly}
          secureTextEntry={props.secureTextEntry}
          style={[
            (props.readOnly ?? false) ? textInputReadOnlyStyle : (props.errorMessage == null) ? textInputStyle : textInputErrorStyle,
            { maxWidth: props.estimatedNumberOfCharacters == null ? undefined : characterWidth(props.estimatedNumberOfCharacters) },
            props.suffix == null ? {} : { borderBottomRightRadius: 0, borderTopRightRadius: 0 },
            props.prefix == null ? {} : { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }
          ]}
          value={props.value ?? undefined}
        />

        {props.suffix == null ? null : (
          <HStack
            alignItems='center'
            backgroundColor='#f3f2f1'
            borderBottomRightRadius={4}
            borderColor={INPUT_BORDER_COLOR}
            borderLeftWidth={0}
            borderStyle='solid'
            borderTopRightRadius={4}
            borderWidth={1}
            justifyContent='center'
            minHeight={40}
            minWidth={40}
          >
            <Text
              size={18}
              weight='600'
            >
              {props.suffix}
            </Text>
          </HStack>
        )}
      </HStack>
    </FormControl>
  )
}

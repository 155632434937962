import React, { useCallback } from 'react'
import { ActivityIndicator, FlatList } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'

import { type ChangeFn } from '../../util/useMultiEditProducts'

import ListItem from './ListItem'
import { Content } from './MultiEditForm'

interface Course {
  id: string
  name?: string | null
}

interface CourseOption {
  value: Course['id'] | null
  title: Course['name'] | null
}

interface EditCourseProps {
  courseOptions: CourseOption[]
  onDismiss: () => void
  onNext: (changeFn: ChangeFn) => void
}

const EditCourse: React.FC<EditCourseProps> = ({ courseOptions, onDismiss, onNext }) => {
  const handleOnPress = useCallback((defaultCourse: CourseOption) => () =>
    onNext((_, product) => ({
      from: product?.defaultCourse?.name ?? '',
      to: defaultCourse?.title ?? '',
      patch: { defaultCourseId: defaultCourse.value }
    })), [onNext])

  return (
    <Content onDismiss={onDismiss}>
      {courseOptions == null
        ? <ActivityIndicator />
        : (
          <FlatList
            data={courseOptions}
            ItemSeparatorComponent={() => <Spacer height={4} />}
            keyExtractor={item => item.value ?? 'removeOption'}
            renderItem={({ item }) => (
              <ListItem onPress={handleOnPress(item)}>
                <HStack alignItems='center' grow={1} height={40} paddingHorizontal={8}>
                  <Text size={16}>{item.title}</Text>
                </HStack>
              </ListItem>
            )}
          />
        )}
    </Content>
  )
}

export default EditCourse

import React from 'react'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'

import { type FullMenuReportGroupFragment } from '../../../types/graphql'
import Bin from '../Bin'

interface ReportGroupCardProps {
  onSelected: (reportGroup: FullMenuReportGroupFragment) => void
  reportGroup: FullMenuReportGroupFragment
}

export const ReportGroupCard: React.FC<ReportGroupCardProps> = ({ onSelected, reportGroup }) => {
  return (
    <HStack alignItems='center'>
      <HStack alignItems='center' backgroundColor='#FFF' borderRadius={8} grow={1} padding={12}>
        <Text size={16}>{reportGroup.name}</Text>

        <Spacer grow={1} width={12} />

        <Text size={16}>{reportGroup.vatRate}%</Text>
      </HStack>

      <Spacer width={10} />

      <Bin onSelected={() => onSelected(reportGroup)} />
    </HStack>
  )
}

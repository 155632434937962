import gql from 'graphql-tag'
import React, { useMemo } from 'react'

import { GetRestaurantPaymentMethodListDataDocument, type PaymentMethodInput, PaymentProvider, useCreatePaymentMethodMutation } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import PaymentMethodForm from '../components/PaymentMethodForm'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreatePaymentMethod($restaurantId: ID!, $input: PaymentMethodInput!) {
    createPaymentMethod(
      restaurantId: $restaurantId,
      input: $input
    ) {
      ...FullPaymentMethod
    }
  }
`

const RestaurantPaymentMethodCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantPaymentMethodCreate'>()

  const [createPaymentMethod, { error, loading }] = useCreatePaymentMethodMutation({
    onCompleted: () => navigation.navigate('RestaurantPaymentMethodList', { restaurantId })
  })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantPaymentMethodList', { restaurantId }], title: 'Betalsätt' }
  ], [restaurantId])

  const handleDismiss = (): void => {
    navigation.navigate('RestaurantPaymentMethodList', { restaurantId })
  }

  const handleSave = (input: PaymentMethodInput): void => {
    createPaymentMethod({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetRestaurantPaymentMethodListDataDocument, variables: { restaurantId } }],
      variables: { restaurantId, input }
    }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title='Skapa betalsätt'>
      <PaymentMethodForm
        defaultValues={{
          name: '',
          paymentProvider: PaymentProvider.External,
          receiptPaymentMethodLabel: '',
          sieAccountNumber: ''
        }}
        errorMessage={error?.message}
        mode='create'
        onDismiss={handleDismiss}
        onSave={handleSave}
        saving={loading}
      />
    </Layout>
  )
}

export default RestaurantPaymentMethodCreate

import gql from 'graphql-tag'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { GetRestaurantPrintersViewDataDocument, type PrinterQueueInput, useCreatePrinterQueueMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreatePrinterQueue($restaurantId: ID!, $input: PrinterQueueInput!) {
    createPrinterQueue(restaurantId: $restaurantId, input: $input) {
      id

      name
    }
  }
`

const schema: ObjectSchema<PrinterQueueInput> = yup.object({
  name: yup.string().trim().min(1).max(256).required()
})

const RestaurantPrinterQueueCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantPrinterQueueCreate'>()

  const form = useForm<PrinterQueueInput>({ criteriaMode: 'all', resolver: yupResolver(schema) })

  const [createPrinterQueue, { error, loading }] = useCreatePrinterQueueMutation({
    onCompleted: () => navigation.navigate('RestaurantPrintersView', { restaurantId }),
    refetchQueries: [{ query: GetRestaurantPrintersViewDataDocument, variables: { restaurantId } }]
  })

  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('RestaurantPrintersView', { restaurantId })
  }, [navigation])

  const handleSubmit = (input: PrinterQueueInput): void => {
    createPrinterQueue({ variables: { restaurantId, input } }).catch(logError)
  }

  if (loading) {
    return <Layout loading />
  }

  return (
    <Layout
      breadcrumbs={[{ link: ['RestaurantPrintersView', { restaurantId }], title: 'Skrivare' }]}
      hideTitle
      screenType={ScreenType.Form}
      title='Skapa skrivarkö'
    >
      <FormContainer gap={16} title='Skapa skrivarkö'>
        <TextField
          form={form}
          name='name'
          title='Namn'
        />

        <SubmitFormButtons
          disableCancelButton={loading}
          disableSaveButton={!form.formState.isDirty || loading}
          error={error}
          onCancel={handleCancel}
          onSave={ignoreAsync(form.handleSubmit(handleSubmit))}
          saving={loading}
          titleForSubmit='Skapa'
        />
      </FormContainer>
    </Layout>
  )
}

export default RestaurantPrinterQueueCreate

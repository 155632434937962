import React from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { HStack } from 'react-stacked'

import Card from '../Card'
import { ColorField } from '../fields'

import { type WhiteLabelFormPatch } from './WhiteLabelForm'

const Colors: React.FC<{ form: UseFormReturn<WhiteLabelFormPatch> }> = ({ form }) => {
  return (
    <>
      <Card title='Generella färger'>
        <HStack gap={24} wrap>
          <ColorField form={form} name='theme.backgroundColor' opaque title='Bakgrund' />
          <ColorField form={form} name='theme.borderColor' opaque title='Ram' />
          <ColorField form={form} name='theme.cardColor' opaque title='Kort' />
          <ColorField form={form} name='theme.notificationColor' opaque title='Notis' />
          <ColorField form={form} name='theme.primaryColor' opaque title='Primär' />
          <ColorField form={form} name='theme.textColor' opaque title='Text' />
        </HStack>
      </Card>

      <Card title='Färger i val av meny'>
        <HStack gap={24} wrap>
          <ColorField form={form} name='theme.menuTypeSelectionBackgroundColor' opaque title='Bakgrund' />
          <ColorField form={form} name='theme.menuTypeSelectionTitleColor' title='Ram' />
          <ColorField form={form} name='theme.menuTypeSelectionButtonBackgroundColor' title='Bakgrund, knapp' />
          <ColorField form={form} name='theme.menuTypeSelectionButtonBorderColor' title='Ram, knapp' />
          <ColorField form={form} name='theme.menuTypeSelectionButtonTextColor' title='Text, knapp' />
        </HStack>
      </Card>

      <Card title='Färger i sidomenyn'>
        <HStack gap={24} wrap>
          <ColorField form={form} name='theme.drawerBackgroundColor' opaque title='Bakgrund' />
          <ColorField form={form} name='theme.drawerVersionTextColor' title='Versionstext' />
          <ColorField form={form} name='theme.drawerListItemTitleColor' title='Rubrik, element' />
          <ColorField form={form} name='theme.drawerListItemSubtitleColor' title='Underrubrik, element' />
          <ColorField form={form} name='theme.drawerListItemIconColor' title='Ikon, element' />
        </HStack>
      </Card>

      <Card gap={32} title='Färger i restauranglistan'>
        <HStack gap={24} wrap>
          <ColorField form={form} name='theme.listBackgroundColor' opaque title='Bakgrund' />
          <ColorField form={form} name='theme.listMenuIconColor' title='Sidomenyikon' />
          <ColorField form={form} name='theme.listItemSeparatorColor' title='Avskiljare' />
          <ColorField form={form} name='theme.listEmptyListButtonBackgroundColor' title='Bakgrund, knapp' />
        </HStack>

        <HStack gap={24} wrap>
          <ColorField form={form} name='theme.listTextInputColor' title='Text, sökrutan' />
          <ColorField form={form} name='theme.listTextInputPlaceholderTextColor' title='Platshållare, sökrutan' />
          <ColorField form={form} name='theme.listSearchIconColor' title='Sökikon, sökrutan' />
          <ColorField form={form} name='theme.listClearIconColor' title='Rensaikon, sökrutan' />
        </HStack>

        <HStack gap={24} wrap>
          <ColorField form={form} name='theme.listTabBarActiveTextColor' title='Aktiv flik' />
          <ColorField form={form} name='theme.listTabBarInactiveTextColor' title='Inaktiv flik' />
          <ColorField form={form} name='theme.listTabBarUnderlineColor' title='Understryka flik' />
        </HStack>

        <HStack gap={24} wrap>
          <ColorField form={form} name='theme.listItemTextColor' title='Text, element' />
          <ColorField form={form} name='theme.listItemBackgroundColor' title='Bakgrund, element' />
        </HStack>
      </Card>
    </>
  )
}

export default React.memo(Colors)

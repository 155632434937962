import gql from 'graphql-tag'
import React, { useCallback, useMemo, useState } from 'react'

import { type DiscountPatch, GetRestaurantDiscountsDocument, useDeleteRestaurantDiscountMutation, useGetRestaurantDiscountQuery, useUpdateRestaurantDiscountMutation } from '../../types/graphql'
import DiscountEditForm from '../components/DiscountEditForm'
import Layout, { ScreenType } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import logAndShowError from '../util/logAndShowError'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantDiscount($restaurantId: ID!, $discountId: ID!) {
    restaurant(id: $restaurantId) {
      id

      discount(id: $discountId) {
        ...FullDiscount
      }
    }
  }

  mutation DeleteRestaurantDiscount($restaurantId: ID!, $discountId: ID!) {
    deleteRestaurantDiscount(restaurantId: $restaurantId, discountId: $discountId)
  }

  mutation UpdateRestaurantDiscount($restaurantId: ID!, $discountId: ID!, $patch: DiscountPatch!) {
    updateRestaurantDiscount(restaurantId: $restaurantId, discountId: $discountId, patch: $patch) {
      id
    }
  }
`

const DiscountRestaurantEdit: React.FC = () => {
  const [navigation, { restaurantId, discountId }] = useNavigation<'RestaurantDiscountEdit'>()

  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('RestaurantDiscountsList', { restaurantId })
  }, [navigation])

  const { data } = useGetRestaurantDiscountQuery({ variables: { restaurantId, discountId } })
  const [deleteDiscount, { loading: deleting, error: deleteError }] = useDeleteRestaurantDiscountMutation({ onCompleted: handleCancel })
  const [updateDiscount, { error: saveError, loading: saving }] = useUpdateRestaurantDiscountMutation({ onCompleted: handleCancel, onError: logAndShowError })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const discount = useMemo(() => data?.restaurant?.discount, [data])

  const handleDelete = useCallback(() => {
    deleteDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetRestaurantDiscountsDocument, variables: { restaurantId } }],
      variables: { restaurantId, discountId }
    }).catch(logAndShowError)
  }, [deleteDiscount, discountId, restaurantId])

  const handleUpdate = useCallback((patch: DiscountPatch) => {
    updateDiscount({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetRestaurantDiscountsDocument, variables: { restaurantId } }],
      variables: { restaurantId, discountId, patch }
    }).catch(logError)
  }, [discountId, restaurantId, updateDiscount])

  return (
    <Layout
      breadcrumbs={[{ link: ['RestaurantDiscountsList', { restaurantId }], title: 'Rabatter' }]}
      loading={discount == null}
      screenType={ScreenType.Form}
      title={`Redigera ${discount?.title ?? discount?.code ?? '...'}`}
    >
      {discount == null ? null : (
        <DiscountEditForm
          discount={discount}
          error={saveError}
          onCancel={handleCancel}
          onDelete={() => setShowDeleteDialog(true)}
          onSave={handleUpdate}
          saving={saving}
        />
      )}

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={`Är du säker på att du vill ta bort ${discount?.title ?? `rabatten med kod ${discount?.code ?? ''}`}?`}
        title='Ta bort rabatt'
      />
    </Layout>
  )
}

export default DiscountRestaurantEdit

import React from 'react'
import { useForm } from 'react-hook-form'

import { TextField } from '../components/TextField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'

export interface MenuPageSchema {
  readonly name: string
}

const menuPageSchema: ObjectSchema<MenuPageSchema> = yup.object({
  name: yup.string().trim().min(1, 'Kategorinamn med minst ett tecken krävs').max(256, 'Kategorinamn med max 256 tecken tillåts').required()
})

interface MenuPageFormProps {
  defaultValues: MenuPageSchema
  deleting?: boolean
  disableDeleteButton?: boolean
  onCancel: () => void
  onDelete?: () => void
  onSave: (values: MenuPageSchema) => void
  saving: boolean
}

const MenuPageForm: React.FC<MenuPageFormProps> = ({ defaultValues, deleting, disableDeleteButton, onCancel, onDelete, onSave, saving }) => {
  const form = useForm({
    criteriaMode: 'all',
    defaultValues,
    resolver: yupResolver(menuPageSchema)
  })

  return (
    <FormContainer gap={16}>
      <TextField
        autoFocus
        form={form}
        hint='Exempel på huvudkategori skulle kunna vara Mat eller Dryck'
        name='name'
        title='Namn på kategori'
      />

      <SubmitFormButtons
        deleting={deleting}
        disableDeleteButton={saving || disableDeleteButton}
        disableSaveButton={form.formState.isSubmitting}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSave))}
        saving={saving}
      />
    </FormContainer>
  )
}

export default MenuPageForm

import 'core-js/modules/es.error.cause'
import './src/client/bugsnag'

import { ApolloProvider } from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { LocalizationProvider, svSE } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import sv from 'date-fns/locale/sv'
import React from 'react'

import client from './src/client/graphql'
import { linkingOptions } from './src/lib/navigation'
import AccountEdit from './src/screen/AccountEdit'
import CashregisterReportList from './src/screen/CashregisterReportList'
import CashregisterReportPreview from './src/screen/CashregisterReportPreview'
import CashregisterReportView from './src/screen/CashregisterReportView'
import CashregisterView from './src/screen/CashregisterView'
import ControlUnitList from './src/screen/ControlUnitList'
import ControlUnitView from './src/screen/ControlUnitView'
import DeliveryDeliveryHeroEdit from './src/screen/DeliveryDeliveryHeroEdit'
import DeliveryHeroRestaurantIntegration from './src/screen/DeliveryHeroRestaurantIntegration'
import DeliveryLocationCreate from './src/screen/DeliveryLocationCreate'
import DeliveryLocationEdit from './src/screen/DeliveryLocationEdit'
import DeliveryLocationList from './src/screen/DeliveryLocationList'
import DeliveryLocoEdit from './src/screen/DeliveryLocoEdit'
import EditUsername from './src/screen/EditUsername'
import FortnoxAccountingImportComplete from './src/screen/FortnoxAccountingImportComplete'
import Login from './src/screen/Login'
import MarketingPushNotificationCreate from './src/screen/MarketingPushNotificationCreate'
import MenuAddonProductCreate from './src/screen/MenuAddonProductCreate'
import MenuAddonProductEdit from './src/screen/MenuAddonProductEdit'
import MenuAlternativeGroupCreate from './src/screen/MenuAlternativeGroupCreate'
import MenuAlternativeGroupEdit from './src/screen/MenuAlternativeGroupEdit'
import MenuAlternativeGroupList from './src/screen/MenuAlternativeGroupList'
import MenuCategoryCreate from './src/screen/MenuCategoryCreate'
import MenuCategoryEdit from './src/screen/MenuCategoryEdit'
import MenuCourseCreate from './src/screen/MenuCourseCreate'
import MenuCourseEdit from './src/screen/MenuCourseEdit'
import MenuCourseList from './src/screen/MenuCourseList'
import MenuOpeningHoursCreate from './src/screen/MenuOpeningHoursCreate'
import MenuOpeningHoursEdit from './src/screen/MenuOpeningHoursEdit'
import MenuPageCreate from './src/screen/MenuPageCreate'
import MenuPageEdit from './src/screen/MenuPageEdit'
import MenuPageView from './src/screen/MenuPageView'
import MenuProductCreate from './src/screen/MenuProductCreate'
import MenuProductEdit from './src/screen/MenuProductEdit'
import MenuView from './src/screen/MenuView'
import MetaOrganizationDiscountCreate from './src/screen/MetaOrganizationDiscountCreate'
import MetaOrganizationDiscountEdit from './src/screen/MetaOrganizationDiscountEdit'
import MetaOrganizationList from './src/screen/MetaOrganizationList'
import MetaOrganizationView from './src/screen/MetaOrganizationView'
import OpeningHoursList from './src/screen/OpeningHoursList'
import OrganizationCreate from './src/screen/OrganizationCreate'
import OrganizationFortnoxAccountingImportView from './src/screen/OrganizationFortnoxAccountingImportView'
import OrganizationSelect from './src/screen/OrganizationSelect'
import OrganizationUserCreate from './src/screen/OrganizationUserCreate'
import OrganizationUserEdit from './src/screen/OrganizationUserEdit'
import OrganizationUserList from './src/screen/OrganizationUserList'
import PasswordReset from './src/screen/PasswordReset'
import PasswordResetConfirm from './src/screen/PasswordResetConfirm'
import ReportGroupCreate from './src/screen/ReportGroupCreate'
import ReportGroupList from './src/screen/ReportGroupList'
import RestaurantControlUnitOutageList from './src/screen/RestaurantControlUnitOutageList'
import RestaurantCreate from './src/screen/RestaurantCreate'
import RestaurantDashboard from './src/screen/RestaurantDashboard'
import RestaurantDeliveryList from './src/screen/RestaurantDeliveryList'
import RestaurantDesignView from './src/screen/RestaurantDesignView'
import RestaurantDiscountCreate from './src/screen/RestaurantDiscountCreate'
import RestaurantDiscountEdit from './src/screen/RestaurantDiscountEdit'
import RestaurantDiscountsList from './src/screen/RestaurantDiscountsList'
import RestaurantEdit from './src/screen/RestaurantEdit'
import RestaurantEmployeeCreate from './src/screen/RestaurantEmployeeCreate'
import RestaurantEmployeeEdit from './src/screen/RestaurantEmployeeEdit'
import RestaurantEmployeeGroupCreate from './src/screen/RestaurantEmployeeGroupCreate'
import RestaurantEmployeeGroupEdit from './src/screen/RestaurantEmployeeGroupEdit'
import RestaurantEmployeeList from './src/screen/RestaurantEmployeeList'
import RestaurantGetStarted from './src/screen/RestaurantGetStarted'
import RestaurantList from './src/screen/RestaurantList'
import RestaurantOpeningHoursView from './src/screen/RestaurantOpeningHoursView'
import RestaurantOrderDisplaySystem from './src/screen/RestaurantOrderDisplaySystem'
import RestaurantPaymentMethodCreate from './src/screen/RestaurantPaymentMethodCreate'
import RestaurantPaymentMethodEdit from './src/screen/RestaurantPaymentMethodEdit'
import RestaurantPaymentMethodList from './src/screen/RestaurantPaymentMethodList'
import RestaurantPersonalkollenView from './src/screen/RestaurantPersonalkollenView'
import RestaurantPhysicalPrinterCreate from './src/screen/RestaurantPhysicalPrinterCreate'
import RestaurantPhysicalPrinterEdit from './src/screen/RestaurantPhysicalPrinterEdit'
import RestaurantPosTerminalEdit from './src/screen/RestaurantPosTerminalEdit'
import RestaurantPrinterQueueCreate from './src/screen/RestaurantPrinterQueueCreate'
import RestaurantPrinterQueueEdit from './src/screen/RestaurantPrinterQueueEdit'
import RestaurantPrintersView from './src/screen/RestaurantPrintersView'
import RestaurantReceiptList from './src/screen/RestaurantReceiptList'
import RestaurantSalesReportList from './src/screen/RestaurantSalesReportList'
import RestaurantSalesReportView from './src/screen/RestaurantSalesReportView'
import RestaurantStatisticsView from './src/screen/RestaurantStatisticsView'
import RestaurantStripeEdit from './src/screen/RestaurantStripeEdit'
import RestaurantSwishEdit from './src/screen/RestaurantSwishEdit'
import RestaurantTillCreate from './src/screen/RestaurantTillCreate'
import RestaurantTillEdit from './src/screen/RestaurantTillEdit'
import RestaurantTillList from './src/screen/RestaurantTillList'
import SieAccountList from './src/screen/SieAccountList'
import SieReportView from './src/screen/SieReportView'
import StatisticsOverview from './src/screen/StatisticsOverview'
import StripeReportList from './src/screen/StripeReportList'
import StripeReportView from './src/screen/StripeReportView'
import StripeSetupComplete from './src/screen/StripeSetupComplete'
import SuperUserDashboard from './src/screen/SuperUserDashboard'
import TimeSlotsView from './src/screen/TimeSlotsView'
import UserCreate from './src/screen/UserCreate'
import WhiteLabelCreate from './src/screen/WhiteLabelCreate'
import WhiteLabelDashboard from './src/screen/WhiteLabelDashboard'
import WhiteLabelDiscountCreate from './src/screen/WhiteLabelDiscountCreate'
import WhiteLabelDiscountEdit from './src/screen/WhiteLabelDiscountEdit'
import WhiteLabelDiscountList from './src/screen/WhiteLabelDiscountList'
import WhiteLabelGuestGroupCreate from './src/screen/WhiteLabelGuestGroupCreate'
import WhiteLabelGuestGroupCustomerRegister from './src/screen/WhiteLabelGuestGroupCustomerRegister'
import WhiteLabelGuestGroupEdit from './src/screen/WhiteLabelGuestGroupEdit'
import WhiteLabelGuestGroupList from './src/screen/WhiteLabelGuestGroupList'
import WhiteLabelGuestGroupMemberCreate from './src/screen/WhiteLabelGuestGroupMemberCreate'
import WhiteLabelGuestGroupMemberView from './src/screen/WhiteLabelGuestGroupMemberView'
import WhiteLabelGuestGroupView from './src/screen/WhiteLabelGuestGroupView'
import WhiteLabelList from './src/screen/WhiteLabelList'
import WhiteLabelUserCreate from './src/screen/WhiteLabelUserCreate'
import WhiteLabelUserEdit from './src/screen/WhiteLabelUserEdit'
import WhiteLabelUserList from './src/screen/WhiteLabelUserList'
import WhiteLabelView from './src/screen/WhiteLabelView'
import YouniumUsageExport from './src/screen/YouniumUsageExport'

const RootStack = createStackNavigator()
const theme = createTheme({}, svSE)

if (__DEV__) { // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

// Show a special cursor over Christmas
if (new Date() < new Date('2023-12-26T00:00:00.000Z')) {
  document.head.appendChild(document.createElement('style')).textContent = `
    body {
      cursor: url(https://locousercontent.com/p4PCfraIYn7QStZU8RzfbsBLEr7WnXixPw-wNzJVbz20863DxxJofB4L31PKN0Lo/original.png) 1 1, auto;
      animation: cursor 200ms infinite;
    }

    @keyframes cursor {
      0% { cursor: url(https://locousercontent.com/p4PCfraIYn7QStZU8RzfbsBLEr7WnXixPw-wNzJVbz20863DxxJofB4L31PKN0Lo/original.png) 1 1, auto; }
      50% { cursor: url(https://locousercontent.com/O9xyXN1yXXWMlTVxTe1-aNAig6HTIr-DcY2ltvkCnbSwS_PyHjJI9EiZ8Rx418SY/original.png) 1 1, auto; }
      100% { cursor: url(https://locousercontent.com/p4PCfraIYn7QStZU8RzfbsBLEr7WnXixPw-wNzJVbz20863DxxJofB4L31PKN0Lo/original.png) 1 1, auto; }
    }
  `
}

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider adapterLocale={sv} dateAdapter={AdapterDateFns}>
          <NavigationContainer documentTitle={{ formatter: (options) => options?.title ?? 'Loco Backoffice' }} linking={linkingOptions}>
            <RootStack.Navigator initialRouteName='Login'>
              <RootStack.Screen component={AccountEdit} name='AccountEdit' options={{ header: () => null }} />
              <RootStack.Screen component={CashregisterReportList} name='CashregisterReportList' options={{ header: () => null }} />
              <RootStack.Screen component={CashregisterReportPreview} name='CashregisterReportPreview' options={{ header: () => null }} />
              <RootStack.Screen component={CashregisterReportView} name='CashregisterReportView' options={{ header: () => null }} />
              <RootStack.Screen component={CashregisterView} name='CashregisterView' options={{ header: () => null }} />
              <RootStack.Screen component={ControlUnitList} name='ControlUnitList' options={{ header: () => null }} />
              <RootStack.Screen component={ControlUnitView} name='ControlUnitView' options={{ header: () => null }} />
              <RootStack.Screen component={DeliveryLocationCreate} name='DeliveryLocationCreate' options={{ header: () => null }} />
              <RootStack.Screen component={DeliveryLocationEdit} name='DeliveryLocationEdit' options={{ header: () => null }} />
              <RootStack.Screen component={DeliveryLocationList} name='DeliveryLocationList' options={{ header: () => null }} />
              <RootStack.Screen component={DeliveryDeliveryHeroEdit} name='DeliveryDeliveryHeroEdit' options={{ header: () => null }} />
              <RootStack.Screen component={DeliveryHeroRestaurantIntegration} name='DeliveryHeroRestaurantIntegration' options={{ header: () => null }} />
              <RootStack.Screen component={DeliveryLocoEdit} name='DeliveryLocoEdit' options={{ header: () => null }} />
              <RootStack.Screen component={EditUsername} name='EditUsername' options={{ header: () => null }} />
              <RootStack.Screen component={FortnoxAccountingImportComplete} name='FortnoxAccountingImportComplete' options={{ header: () => null }} />
              <RootStack.Screen component={Login} name='Login' options={{ header: () => null }} />
              <RootStack.Screen component={MarketingPushNotificationCreate} name='MarketingPushNotificationCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MenuAddonProductCreate} name='MenuAddonProductCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MenuAddonProductEdit} name='MenuAddonProductEdit' options={{ header: () => null }} />
              <RootStack.Screen component={MenuAlternativeGroupCreate} name='MenuAlternativeGroupCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MenuAlternativeGroupEdit} name='MenuAlternativeGroupEdit' options={{ header: () => null }} />
              <RootStack.Screen component={MenuAlternativeGroupList} name='MenuAlternativeGroupList' options={{ header: () => null }} />
              <RootStack.Screen component={MenuCategoryCreate} name='MenuCategoryCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MenuCategoryEdit} name='MenuCategoryEdit' options={{ header: () => null }} />
              <RootStack.Screen component={MenuCourseCreate} name='MenuCourseCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MenuCourseEdit} name='MenuCourseEdit' options={{ header: () => null }} />
              <RootStack.Screen component={MenuCourseList} name='MenuCourseList' options={{ header: () => null }} />
              <RootStack.Screen component={MenuOpeningHoursCreate} name='MenuOpeningHoursCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MenuOpeningHoursEdit} name='MenuOpeningHoursEdit' options={{ header: () => null }} />
              <RootStack.Screen component={MenuPageCreate} name='MenuPageCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MenuPageEdit} name='MenuPageEdit' options={{ header: () => null }} />
              <RootStack.Screen component={MenuPageView} name='MenuPageView' options={{ header: () => null }} />
              <RootStack.Screen component={MenuProductCreate} name='MenuProductCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MenuProductEdit} name='MenuProductEdit' options={{ header: () => null }} />
              <RootStack.Screen component={MenuView} name='MenuView' options={{ header: () => null }} />
              <RootStack.Screen component={MetaOrganizationDiscountCreate} name='MetaOrganizationDiscountCreate' options={{ header: () => null }} />
              <RootStack.Screen component={MetaOrganizationDiscountEdit} name='MetaOrganizationDiscountEdit' options={{ header: () => null }} />
              <RootStack.Screen component={MetaOrganizationList} name='MetaOrganizationList' options={{ header: () => null }} />
              <RootStack.Screen component={MetaOrganizationView} name='MetaOrganizationView' options={{ header: () => null }} />
              <RootStack.Screen component={OpeningHoursList} name='OpeningHoursList' options={{ header: () => null }} />
              <RootStack.Screen component={OrganizationCreate} name='OrganizationCreate' options={{ header: () => null }} />
              <RootStack.Screen component={OrganizationSelect} name='OrganizationSelect' options={{ header: () => null }} />
              <RootStack.Screen component={OrganizationUserList} name='OrganizationUserList' options={{ header: () => null }} />
              <RootStack.Screen component={OrganizationUserCreate} name='OrganizationUserCreate' options={{ header: () => null }} />
              <RootStack.Screen component={OrganizationUserEdit} name='OrganizationUserEdit' options={{ header: () => null }} />
              <RootStack.Screen component={PasswordReset} name='PasswordReset' options={{ header: () => null }} />
              <RootStack.Screen component={PasswordResetConfirm} name='PasswordResetConfirm' options={{ header: () => null }} />
              <RootStack.Screen component={ReportGroupCreate} name='ReportGroupCreate' options={{ header: () => null }} />
              <RootStack.Screen component={ReportGroupList} name='ReportGroupList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantControlUnitOutageList} name='RestaurantControlUnitOutageList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantCreate} name='RestaurantCreate' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantDashboard} name='RestaurantDashboard' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantDeliveryList} name='RestaurantDeliveryList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantDesignView} name='RestaurantDesignView' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantDiscountCreate} name='RestaurantDiscountCreate' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantDiscountEdit} name='RestaurantDiscountEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantDiscountsList} name='RestaurantDiscountsList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantEdit} name='RestaurantEdit' options={{ header: () => null }} />
              <RootStack.Screen component={OrganizationFortnoxAccountingImportView} name='OrganizationFortnoxAccountingImportView' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantEmployeeCreate} name='RestaurantEmployeeCreate' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantEmployeeEdit} name='RestaurantEmployeeEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantEmployeeGroupCreate} name='RestaurantEmployeeGroupCreate' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantEmployeeGroupEdit} name='RestaurantEmployeeGroupEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantEmployeeList} name='RestaurantEmployeeList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantGetStarted} name='RestaurantGetStarted' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantList} name='RestaurantList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantOpeningHoursView} name='RestaurantOpeningHoursView' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantOrderDisplaySystem} name='RestaurantOrderDisplaySystem' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPaymentMethodCreate} name='RestaurantPaymentMethodCreate' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPaymentMethodEdit} name='RestaurantPaymentMethodEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPaymentMethodList} name='RestaurantPaymentMethodList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPersonalkollenView} name='RestaurantPersonalkollenView' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPhysicalPrinterCreate} name='RestaurantPhysicalPrinterCreate' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPhysicalPrinterEdit} name='RestaurantPhysicalPrinterEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPosTerminalEdit} name='RestaurantPosTerminalEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPrinterQueueCreate} name='RestaurantPrinterQueueCreate' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPrinterQueueEdit} name='RestaurantPrinterQueueEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantPrintersView} name='RestaurantPrintersView' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantReceiptList} name='RestaurantReceiptList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantSalesReportList} name='RestaurantSalesReportList' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantSalesReportView} name='RestaurantSalesReportView' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantStatisticsView} name='RestaurantStatisticsView' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantStripeEdit} name='RestaurantStripeEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantSwishEdit} name='RestaurantSwishEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantTillCreate} name='RestaurantTillCreate' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantTillEdit} name='RestaurantTillEdit' options={{ header: () => null }} />
              <RootStack.Screen component={RestaurantTillList} name='RestaurantTillList' options={{ header: () => null }} />
              <RootStack.Screen component={SieAccountList} name='SieAccountList' options={{ header: () => null }} />
              <RootStack.Screen component={SieReportView} name='SieReportView' options={{ header: () => null }} />
              <RootStack.Screen component={StatisticsOverview} name='StatisticsOverview' options={{ header: () => null }} />
              <RootStack.Screen component={StripeReportList} name='StripeReportList' options={{ header: () => null }} />
              <RootStack.Screen component={StripeReportView} name='StripeReportView' options={{ header: () => null }} />
              <RootStack.Screen component={StripeSetupComplete} name='StripeSetupComplete' options={{ header: () => null }} />
              <RootStack.Screen component={SuperUserDashboard} name='SuperUserDashboard' options={{ header: () => null }} />
              <RootStack.Screen component={TimeSlotsView} name='TimeSlotsView' options={{ header: () => null }} />
              <RootStack.Screen component={UserCreate} name='UserCreate' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelCreate} name='WhiteLabelCreate' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelDashboard} name='WhiteLabelDashboard' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelDiscountCreate} name='WhiteLabelDiscountCreate' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelDiscountEdit} name='WhiteLabelDiscountEdit' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelDiscountList} name='WhiteLabelDiscountList' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelGuestGroupCreate} name='WhiteLabelGuestGroupCreate' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelGuestGroupCustomerRegister} name='WhiteLabelGuestGroupCustomerRegister' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelGuestGroupEdit} name='WhiteLabelGuestGroupEdit' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelGuestGroupList} name='WhiteLabelGuestGroupList' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelGuestGroupMemberCreate} name='WhiteLabelGuestGroupMemberCreate' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelGuestGroupMemberView} name='WhiteLabelGuestGroupMemberView' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelGuestGroupView} name='WhiteLabelGuestGroupView' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelList} name='WhiteLabelList' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelUserCreate} name='WhiteLabelUserCreate' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelUserEdit} name='WhiteLabelUserEdit' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelUserList} name='WhiteLabelUserList' options={{ header: () => null }} />
              <RootStack.Screen component={WhiteLabelView} name='WhiteLabelView' options={{ header: () => null }} />
              <RootStack.Screen component={YouniumUsageExport} name='YouniumUsageExport' options={{ header: () => null }} />
            </RootStack.Navigator>
          </NavigationContainer>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App

import gql from 'graphql-tag'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

import { GetRestaurantPrintersViewDataDocument, type PrinterQueuePatch, useDeletePrinterQueueMutation, useGetRestaurantPrinterQueueEditDataQuery, useUpdatePrinterQueueMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import VerificationDialog from '../components/VerificationDialog'
import { TextField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantPrinterQueueEditData($restaurantId: ID!, $printerQueueId: ID!) {
    restaurant(id: $restaurantId) {
      id

      printerQueue(id: $printerQueueId) {
        id

        name
      }
    }
  }

  mutation DeletePrinterQueue($restaurantId: ID!, $printerQueueId: ID!) {
    deletePrinterQueue(restaurantId: $restaurantId, printerQueueId: $printerQueueId) {
      id

      printerQueues {
        id

        name
      }
    }
  }

  mutation UpdatePrinterQueue($restaurantId: ID!, $printerQueueId: ID!, $patch: PrinterQueuePatch!) {
    updatePrinterQueue(restaurantId: $restaurantId, printerQueueId: $printerQueueId, patch: $patch) {
      id

      name
    }
  }
`

const schema: ObjectSchema<PrinterQueuePatch> = yup.object({
  name: yup.string().trim().min(1).max(256).required()
})

const RestaurantPrinterQueueEdit: React.FC = () => {
  const [navigation, { restaurantId, printerQueueId }] = useNavigation<'RestaurantPrinterQueueEdit'>()

  const { data, loading } = useGetRestaurantPrinterQueueEditDataQuery({ variables: { restaurantId, printerQueueId } })

  const [showDeleteVerification, setShowDeleteVerification] = useState(false)

  const form = useForm<PrinterQueuePatch>({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    values: data?.restaurant?.printerQueue == null ? undefined : schema.cast(data?.restaurant?.printerQueue, { stripUnknown: true })
  })

  const handleCancel = useCallback(() => {
    setShowDeleteVerification(false)

    navigation.canGoBack() ? navigation.goBack() : navigation.replace('RestaurantPrintersView', { restaurantId })
  }, [navigation])

  const [deletePrinterQueue, { error: deleteError, loading: deleting }] = useDeletePrinterQueueMutation({
    onCompleted: handleCancel
  })

  const [updatePrinterQueue, { error, loading: submitting }] = useUpdatePrinterQueueMutation({
    onCompleted: handleCancel,
    refetchQueries: [{ query: GetRestaurantPrintersViewDataDocument, variables: { restaurantId } }]
  })

  const handleDelete = (): void => {
    deletePrinterQueue({ variables: { restaurantId, printerQueueId } }).catch(logError)
  }

  const handleSubmit = (patch: PrinterQueuePatch): void => {
    updatePrinterQueue({ variables: { restaurantId, printerQueueId, patch } }).catch(logError)
  }

  if (loading || submitting) {
    return <Layout loading />
  }

  return (
    <>
      {!showDeleteVerification ? null : (
        <VerificationDialog
          callToActionLabel='Bekräfta radera skrivare'
          errorMessage={deleteError?.message}
          loading={deleting}
          onDelete={handleDelete}
          onDismiss={() => setShowDeleteVerification(false)}
          open
          prompt='Är du säker på att du vill radera skrivaren? Detta går inte att ångra.'
          title='Är du säker?'
        />
      )}

      <Layout
        breadcrumbs={[{ link: ['RestaurantPrintersView', { restaurantId }], title: 'Skrivare' }]}
        hideTitle
        screenType={ScreenType.Form}
        title={data?.restaurant?.printerQueue?.name ?? 'Ändra'}
      >
        <FormContainer gap={16} title={`Ändra ${data?.restaurant?.printerQueue?.name ?? 'Skrivarens namn'}`}>
          <TextField
            form={form}
            name='name'
            title='Namn'
          />

          <SubmitFormButtons
            deleting={deleting}
            disableCancelButton={loading}
            disableDeleteButton={deleting}
            disableSaveButton={!form.formState.isDirty || loading}
            error={error}
            onCancel={handleCancel}
            onDelete={() => setShowDeleteVerification(true)}
            onSave={ignoreAsync(form.handleSubmit(handleSubmit))}
            saving={loading}
            titleForDelete='Radera'
            titleForSubmit='Skapa'
          />
        </FormContainer>
      </Layout>
    </>
  )
}

export default RestaurantPrinterQueueEdit

import { Dimensions } from 'react-native'

const { width } = Dimensions.get('window')

// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350

const scale = (size: number): number => width / guidelineBaseWidth * size
const moderateScale = (size: number, factor = 0.5): number => size + (scale(size) - size) * factor

export { moderateScale }

import React, { type ReactNode } from 'react'
import { VStack } from 'react-stacked'

import Heading from './atoms/Heading'

const FormContainer: React.FC<{ children: ReactNode, gap: number, title?: string, wide?: true }> = ({ children, gap, title, wide }) => {
  return (
    <VStack backgroundColor='#fefefe' borderRadius={5} gap={gap} grow={1} maxWidth={wide ? 1024 : 700} padding={16}>
      {title == null ? null : (
        <Heading size='l'>
          {title}
        </Heading>
      )}

      {children}
    </VStack>
  )
}

export default FormContainer

import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import { GetPageMenuDocument, type MenuProductFieldsFragment, type MenuProductPatch, MenuType, useUpdateProductIsActiveMutation } from '../../../types/graphql'
import logError from '../../util/logError'
import * as MenuTypes from '../../util/menuTypes'
import { setProductIsActive } from '../../util/setProductIsActive'
import showAlert from '../../util/showAlert'
import useNavigation from '../../util/useNavigation'
import { PanicButton } from '../PanicButton'
import VerificationDialog from '../VerificationDialog'

gql`
  mutation UpdateProductIsActive($restaurantId: ID!, $menuProductId: ID!, $patch: MenuProductPatch!) {
    updateMenuProduct(
      restaurantId: $restaurantId
      menuProductId: $menuProductId
      patch: $patch
    ) { id }
  }
`

interface ProductToggleProps {
  product: MenuProductFieldsFragment
}

export const ProductToggle: React.FC<ProductToggleProps> = ({ product }) => {
  const [, { menuPageId, restaurantId }] = useNavigation<'MenuPageView'>()

  const [updateIsActive, { loading: isSubmitting }] = useUpdateProductIsActiveMutation({ awaitRefetchQueries: true })

  const [showTemporarilyDisabledConfirmation, setShowTemporarilyDisabledConfirmation] = useState(false)

  const [isDisabled, setIsDisabled] = useState(
    !(product.delivery?.isActive ?? false) &&
      !(product.takeAway?.isActive ?? false) &&
      !(product.eatIn?.isActive ?? false)
  )

  const doUpdateIsActive = async (patch: MenuProductPatch, menuPageId: string): Promise<void> => {
    await updateIsActive({
      refetchQueries: [{ query: GetPageMenuDocument, variables: { restaurantId, menuPageId } }],
      variables: { restaurantId, menuProductId: product.id, patch }
    })
  }

  const handleActivation = (): void => {
    if (product.delivery?.reportGroup == null && product.eatIn?.reportGroup == null && product.takeAway?.reportGroup == null) {
      showAlert('Kunde inte toggla aktiveringen av produkten pga avsaknad av rapportgrupp')
      return
    }

    if (
      (product.delivery?.price == null && !(product.delivery?.priceIsOpen ?? false)) &&
      (product.eatIn?.price == null && !(product.eatIn?.priceIsOpen ?? false)) &&
      (product.takeAway?.price == null && !(product.takeAway?.priceIsOpen ?? false))
    ) {
      showAlert('Kunde inte toggla aktiveringen av produkten pga avsaknad av pris')
      return
    }

    setIsDisabled(!isDisabled)

    const menuTypes = [
      ...(product.delivery?.reportGroup == null ? [] : [MenuType.Delivery]),
      ...(product.eatIn?.reportGroup == null ? [] : [MenuType.EatIn]),
      ...(product.takeAway?.reportGroup == null ? [] : [MenuType.TakeAway])
    ]

    const getMenuTypeIsActive = (menuType: MenuType): boolean | null => product[MenuTypes.productFieldName(menuType)]?.isActive ?? null

    const productVariantIsActiveStatus = menuTypes.map(menuType => getMenuTypeIsActive(menuType)) ?? []
    const productVariantsNewStatus = Object.fromEntries(menuTypes.map(menuType => [
      MenuTypes.productFieldName(menuType),
      { isActive: setProductIsActive(getMenuTypeIsActive(menuType), productVariantIsActiveStatus) }
    ]))

    doUpdateIsActive(productVariantsNewStatus, menuPageId).catch(logError)
  }

  useEffect(() => {
    setIsDisabled(
      !(product.delivery?.isActive ?? false) &&
        !(product.takeAway?.isActive ?? false) &&
        !(product.eatIn?.isActive ?? false)
    )
  })

  return (
    <>
      <PanicButton
        inActivationMessage='Produkten är inaktiverad'
        isDisabled={isDisabled}
        isLoading={isSubmitting}
        onActivation={() => {
          if (isDisabled) {
            handleActivation()
          } else {
            setShowTemporarilyDisabledConfirmation(true)
          }
        }}
      />
      {!showTemporarilyDisabledConfirmation ? null : (
        <VerificationDialog
          callToActionLabel='Bekräfta'
          errorMessage={undefined}
          loading={isSubmitting}
          onDismiss={() => {
            setShowTemporarilyDisabledConfirmation(false)
          }}
          onSubmit={() => {
            setShowTemporarilyDisabledConfirmation(false)
            handleActivation()
          }}
          open
          prompt={`Är du säker på att du vill inaktivera produkten? Om du gör det så kommer ${product.name ?? 'produkten'} inte vara synlig i vår beställningsapp.`}
          title={`Inaktivera ${product.name ?? 'produkten'}`}
        />
      )}
    </>
  )
}

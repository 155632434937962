import gql from 'graphql-tag'
import React, { useState } from 'react'

import { useSetCategoryTemporarilyDisabledMutation } from '../../../types/graphql'
import logError from '../../util/logError'
import { PanicButton } from '../PanicButton'
import VerificationDialog from '../VerificationDialog'

gql`
  mutation SetCategoryTemporarilyDisabled($menuCategoryId: ID!, $restaurantId: ID!, $temporarilyDisabled: Boolean!) {
    updateMenuCategory(
      restaurantId: $restaurantId
      menuCategoryId: $menuCategoryId
      patch: {
        temporarilyDisabled: $temporarilyDisabled
      }
    ) {
      id
      temporarilyDisabled
    }
  }
`

interface CategoryToggleButtonProps {
  disabled: boolean
  menuCategoryId: string
  restaurantId: string
}

const CategoryToggleButton: React.FC<CategoryToggleButtonProps> = ({ disabled, menuCategoryId, restaurantId }) => {
  const [toggleTemporarilyDisabled, { loading }] = useSetCategoryTemporarilyDisabledMutation({
    variables: {
      menuCategoryId,
      restaurantId,
      temporarilyDisabled: !disabled
    },
    optimisticResponse: { updateMenuCategory: { __typename: 'MenuCategory', id: menuCategoryId, temporarilyDisabled: !disabled } }
  })

  const [showTemporarilyDisabledConfirmation, setShowTemporarilyDisabledConfirmation] = useState(false)

  return (
    <>
      <PanicButton
        isDisabled={disabled}
        isLoading={loading}
        onActivation={() => {
          if (disabled) {
            toggleTemporarilyDisabled().catch(logError)
          } else {
            setShowTemporarilyDisabledConfirmation(true)
          }
        }}
      />
      {!showTemporarilyDisabledConfirmation ? null : (
        <VerificationDialog
          callToActionLabel='Bekräfta'
          errorMessage={undefined}
          loading={loading}
          onDismiss={() => {
            setShowTemporarilyDisabledConfirmation(false)
          }}
          onSubmit={() => {
            setShowTemporarilyDisabledConfirmation(false)
            toggleTemporarilyDisabled().catch(logError)
          }}
          open
          prompt='Är du säker på att du vill inaktivera kategorin? Om du gör det kommer varken kategorin eller dess innehåll vara synliga i vår beställningsapp.'
          title='Inaktivera kategorin'
        />
      )}
    </>
  )
}

export default CategoryToggleButton

import gql from 'graphql-tag'
import React from 'react'
import { HStack } from 'react-stacked'

import { MenuType, useGetMenutypeToggleInfoQuery } from '../../../types/graphql'

import MenuTypeToggleButton from './MenuTypeToggleButton'

gql`
  query GetMenutypeToggleInfo($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      deliveryTemporarilyDisabled
      eatInTemporarilyDisabled
      takeAwayTemporarilyDisabled

      hasDeliveryMenu
      hasEatInMenu
      hasTakeAwayMenu
    }
  }
`

interface MenuTypeToggleProps {
  restaurantId: string
}

const MenuTypeToggle: React.FC<MenuTypeToggleProps> = ({ restaurantId }) => {
  const { data } = useGetMenutypeToggleInfoQuery({ variables: { restaurantId } })

  return (
    <HStack alignItems='stretch' gap={8} maxWidth={1024} padding={8}>
      {!(data?.restaurant?.hasEatInMenu ?? true)
        ? null
        : <MenuTypeToggleButton disabled={data?.restaurant?.eatInTemporarilyDisabled ?? false} menuType={MenuType.EatIn} restaurantId={restaurantId} />}

      {!(data?.restaurant?.hasTakeAwayMenu ?? true)
        ? null
        : <MenuTypeToggleButton disabled={data?.restaurant?.takeAwayTemporarilyDisabled ?? false} menuType={MenuType.TakeAway} restaurantId={restaurantId} />}

      {!(data?.restaurant?.hasDeliveryMenu ?? true)
        ? null
        : <MenuTypeToggleButton disabled={data?.restaurant?.deliveryTemporarilyDisabled ?? false} menuType={MenuType.Delivery} restaurantId={restaurantId} />}
    </HStack>
  )
}

export default MenuTypeToggle

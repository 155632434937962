import { NetworkStatus } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { ActivityIndicator, useWindowDimensions } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { ListCashregisterReportsDocument, useCreateCashregisterReportMutation, useListCashregisterReportsQuery, useScheduleCashregisterReportGenerationMutation } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import Layout from '../components/Layout'
import { Cell, Column, Row, Table } from '../components/Table'
import Warning from '../components/Warning'
import { CheckBox, Select } from '../components/fields'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'
import logError from '../util/logError'
import { normalize } from '../util/normalize'
import serializeZonedDateTime from '../util/serializeZonedDateTime'
import useDate from '../util/useDate'
import useNavigation from '../util/useNavigation'

const DEFAULT_REPORT_GENERATION_TIME = '05:00'

const SMALL_SCREEN_WIDTH_THRESHOLD = 500

gql`
  query ListCashregisterReports($restaurantId: ID!, $cursor: String) {
    restaurant(id: $restaurantId) {
      id

      nextCashregisterReportGenerationTime
      timeZone

      xReport {
        net {
          grossAmount
          transactionCount
        }
      }

      zReports(first: 25, after: $cursor) {
        edges {
          cursor

          node {
            id

            serialNumber

            datePeriod {
              start
              end
            }

            net {
              grossAmount
              transactionCount
            }
          }
        }

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }

  mutation ScheduleCashregisterReportGeneration($restaurantId: ID!, $at: DateTime) {
    scheduleCashregisterReportGeneration(restaurantId: $restaurantId, at: $at) {
      id

      nextCashregisterReportGenerationTime
    }
  }

  mutation CreateCashregisterReport($restaurantId: ID!) {
    createCashregisterReport(restaurantId: $restaurantId) {
      id
    }
  }
`

function money (value: number | null | undefined): string {
  return value == null ? '' : formatCurrency(value)
}

interface ReportGenerationFormProps {
  initialValue: string | null | undefined
  restaurantId: string
  timeZone: string
}

export const ReportGenerationForm: React.FC<ReportGenerationFormProps> = ({ initialValue, restaurantId, timeZone }) => {
  const [reportCreated, setReportCreated] = useState(false)
  const [scheduleCashregisterReportGeneration, { error: reportScheduleError }] = useScheduleCashregisterReportGenerationMutation()
  const [createCashregisterReport, { error: reportCreateError, loading: creating }] = useCreateCashregisterReportMutation({ awaitRefetchQueries: true, onCompleted: () => setReportCreated(true), refetchQueries: [{ query: ListCashregisterReportsDocument, variables: { restaurantId } }] })
  const [value, setValue] = useState(initialValue)
  const now = useDate({ interval: 'minute' }).toZonedDateTimeISO(timeZone)

  const options = useMemo(() => {
    const start = now.startOfDay()
    const end = now.add({ days: 1 }).startOfDay()
    const options = []

    for (let date = start; Temporal.ZonedDateTime.compare(date, end) < 0; date = date.add({ minutes: 15 })) {
      options.push({
        title: formatDateTime(date, { timeStyle: 'short' }),
        value: serializeZonedDateTime(date.add({ days: Temporal.ZonedDateTime.compare(date, now) <= 0 ? 1 : 0 }))
      })
    }

    return options
  }, [now])

  const defaultValue = useMemo(() => {
    return unwrap(options.find(({ title }) => title === DEFAULT_REPORT_GENERATION_TIME)).value
  }, [options])

  const handleUpdate = (value: string | null): void => {
    setValue(value)
    scheduleCashregisterReportGeneration({ variables: { at: value, restaurantId } }).catch(logError)
  }

  const handleManualUpdate = (): void => {
    createCashregisterReport({ variables: { restaurantId } }).catch(logError)
  }

  return (
    <VStack>
      {reportScheduleError?.message == null
        ? null
        : <Warning message={reportScheduleError.message} paddingBottom={8} />}

      {reportCreateError?.message == null
        ? null
        : <Warning message={reportCreateError.message} paddingBottom={8} />}

      <HStack backgroundColor='#fff' borderRadius={8} justifyContent='space-between' minHeight={75} padding={8}>
        <HStack alignItems='center'>
          <CheckBox
            checked={value != null}
            onPress={() => handleUpdate(value != null ? null : defaultValue)}
          />

          <Text>Automatiskt dagsavslut</Text>
        </HStack>

        {value == null
          ? null
          : (
            <Select
              additionalStyle={{ minWidth: 100 }}
              id='nextCashregisterReportGenerationTime'
              onChange={handleUpdate}
              options={options}
              title='Tid'
              value={value}
            />
          )}
      </HStack>

      {value != null
        ? null
        : (
          <>
            <Spacer height={8} />

            <SecondaryButton
              disabled={creating || reportCreated}
              loading={creating}
              onPress={handleManualUpdate}
              title={reportCreated ? 'Skapar dagsavslut,\n detta kan ta upp till 15 sekunder' : 'Manuellt dagsavslut'}
            />
          </>
        )}
    </VStack>
  )
}

const CashregisterReportList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'CashregisterReportList'>()

  const { data, error, fetchMore, networkStatus } = useListCashregisterReportsQuery({ notifyOnNetworkStatusChange: true, variables: { restaurantId } })

  const fetchingMore = networkStatus === NetworkStatus.fetchMore
  const loading = networkStatus === NetworkStatus.loading

  const handleFetchMore = (): void => {
    fetchMore({
      variables: { cursor: data?.restaurant?.zReports?.pageInfo?.endCursor, restaurantId },

      updateQuery (previousResult, { fetchMoreResult }) {
        if (previousResult.restaurant?.zReports == null) {
          return previousResult
        }

        const newEdges = fetchMoreResult?.restaurant?.zReports?.edges

        if (newEdges == null || newEdges.length === 0) {
          return previousResult
        }

        return dotProp.set(previousResult, 'restaurant.zReports', {
          __typename: previousResult.restaurant.zReports.__typename,
          edges: [...previousResult.restaurant.zReports.edges, ...newEdges],
          pageInfo: fetchMoreResult?.restaurant?.zReports?.pageInfo
        })
      }
    }).catch(logError)
  }

  // Roughly adapt the table to fit on small screens
  const isSmallScreen = useWindowDimensions().width < SMALL_SCREEN_WIDTH_THRESHOLD
  const textSize = isSmallScreen ? 12 : undefined

  return (
    <Layout
      breadcrumbs={[{ link: ['CashregisterView', { restaurantId }], title: 'Kassaregister' }]}
      title='Rapporter'
    >
      <VStack maxWidth={1024} padding={normalize(8, 20)}>
        {loading
          ? <ActivityIndicator />
          : (
            <>
              <Text size={24} weight='bold'>Rapporter från Kassaregistret</Text>

              <Spacer height={24} />

              <VStack maxWidth={640}>
                <Text>Här visas alla Z-rapporter (och nuvarande X-rapport) från kassaregistret.</Text>

                <Spacer height={8} />

                <Text>Dessa rapporter innehåller inte försäljning som gått utanför kassaregistret. För total försäljning hänvisa till Försäljningsrapporter.</Text>

                <Spacer height={8} />

                <PrimaryButton
                  onPress={() => navigation.navigate('RestaurantSalesReportList', { restaurantId })}
                  title='Gå till Försäljningsrapporter'
                />

                <Spacer height={16} />

                <Text weight='bold'>Vi rekommenderar att ni använder vårt automatiska dagsavslut. Välj en tid efter stängning för att få varje dags försäljning på samma rapport. Exakta tidpunkten rapporten skapas kan vara efter den tidpunkten du väljer, men aldrig före.</Text>
              </VStack>

              <Spacer height={24} />

              <HStack gap={10} justifyContent='space-between' wrap>
                <HStack gap={10} maxWidth={490} paddingTop={20} wrap>
                  <SecondaryButton
                    icon='open-in-app'
                    iconType='material-community'
                    onPress={() => navigation.navigate('CashregisterReportPreview', { restaurantId })}
                    title='Visa X-rapport'
                  />
                </HStack>

                <VStack grow={1} maxWidth={420}>
                  {data?.restaurant == null
                    ? null
                    : <ReportGenerationForm initialValue={data.restaurant.nextCashregisterReportGenerationTime} restaurantId={data.restaurant.id} timeZone={data.restaurant.timeZone ?? 'Europe/Stockholm'} />}
                </VStack>
              </HStack>

              <Spacer height={24} />

              <Text>
                <TextStyle weight='bold'>Försäljning nuvarande X-rapport, netto:</TextStyle>
                &ensp;
                {money(data?.restaurant?.xReport?.net?.grossAmount)} ({data?.restaurant?.xReport?.net?.transactionCount} kvitton)
              </Text>

              <Spacer height={24} />

              {error?.message == null
                ? null
                : <Warning message={error.message} paddingBottom={16} />}

              <Table borderRadius={4}>
                <Column align='center' paddingHorizontal={4} width={isSmallScreen ? 56 : 144} />
                <Column align='start' paddingHorizontal={4} width={isSmallScreen ? 80 : undefined} />
                <Column align='end' paddingHorizontal={4} />
                <Column align='end' paddingHorizontal={4} />
                <Column width={isSmallScreen ? 32 : 48} />

                <Row align='center' paddingVertical={8}>
                  <Cell>
                    <Text size={textSize} weight='bold'>{isSmallScreen ? '#' : 'Löpnummer'}</Text>
                  </Cell>
                  <Cell>
                    <Text size={textSize} weight='bold'>Datum</Text>
                  </Cell>
                  <Cell>
                    <Text size={textSize} weight='bold'>Antal kvitton</Text>
                  </Cell>
                  <Cell>
                    <Text size={textSize} weight='bold'>Total försäljning</Text>
                  </Cell>
                  <Cell width={isSmallScreen ? 32 : 48} />
                </Row>

                {data?.restaurant?.zReports?.edges?.map((edge) => (
                  <Row key={edge.cursor} align='center' onPress={() => navigation.navigate('CashregisterReportView', { restaurantId, reportId: edge.node.id })} paddingVertical={4}>
                    <Cell>
                      <Text size={textSize}>{edge.node.serialNumber}</Text>
                    </Cell>
                    <Cell>
                      <Text size={textSize}>{edge.node.datePeriod?.start?.slice(0, 10)}</Text>
                    </Cell>
                    <Cell>
                      <Text size={textSize}>{edge.node.net?.transactionCount}</Text>
                    </Cell>
                    <Cell>
                      <Text size={textSize}>{money(edge.node.net?.grossAmount)}</Text>
                    </Cell>
                    <Cell>
                      <MaterialIcons name='open-in-new' size={isSmallScreen ? 20 : 24} />
                    </Cell>
                  </Row>
                ))}
              </Table>

              <Spacer height={8} />

              {!(data?.restaurant?.zReports?.pageInfo.hasNextPage ?? false)
                ? null
                : <SecondaryButton loading={fetchingMore} onPress={handleFetchMore} title='Hämta fler' />}
            </>
          )}
      </VStack>
    </Layout>
  )
}

export default CashregisterReportList

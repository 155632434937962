import Upstream from 'react-native-step-indicator'
import { Text } from 'react-stacked'

import { ACCENT_COLOR } from '../lib/color'

const customStyles = {
  currentStepIndicatorLabelFontSize: 13,
  currentStepIndicatorSize: 30,
  currentStepStrokeWidth: 5,
  labelAlign: 'flex-start' as const,
  labelColor: '#999999',
  labelSize: 11,
  separatorFinishedColor: ACCENT_COLOR,
  separatorStrokeWidth: 2,
  separatorUnFinishedColor: '#ffc670',
  stepIndicatorCurrentColor: ACCENT_COLOR,
  stepIndicatorFinishedColor: ACCENT_COLOR,
  stepIndicatorLabelCurrentColor: ACCENT_COLOR,
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelFontSize: 11,
  stepIndicatorLabelUnFinishedColor: '#ffffffaa',
  stepIndicatorSize: 30,
  stepIndicatorUnFinishedColor: '#ffc670',
  stepStrokeCurrentColor: '#f4f4f4',
  stepStrokeFinishedColor: ACCENT_COLOR,
  stepStrokeUnFinishedColor: '#f4f4f4',
  stepStrokeWidth: 3
}

const allStepsFinishedStyles = {
  ...customStyles,
  currentStepIndicatorLabelFontSize: 0,
  currentStepStrokeWidth: 4,
  stepIndicatorCurrentColor: ACCENT_COLOR
}

interface StepsProps {
  currentPosition: number
  direction: 'horizontal' | 'vertical'
  labels?: string[]
  stepCount: number
}

const StepIndicator: React.FC<StepsProps> = ({ currentPosition, direction, labels, stepCount }) => {
  return (
    <Upstream
      currentPosition={currentPosition}
      customStyles={currentPosition === stepCount ? allStepsFinishedStyles : customStyles}
      direction={direction}
      labels={labels}
      renderLabel={({ label }) => <Text maxWidth={600} paddingLeft={direction === 'vertical' ? 8 : 0}>{label}</Text>}
      stepCount={stepCount}
    />
  )
}

export default StepIndicator

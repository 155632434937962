import { VStack } from 'react-stacked'
import unreachable from 'ts-unreachable'

interface BadgeProps {
  status: 'error' | 'success' | 'warning'
}

const Badge: React.FC<BadgeProps> = ({ status }) => {
  let color
  switch (status) {
    case 'error':
      color = '#ff190c'
      break
    case 'success':
      color = '#52c41a'
      break
    case 'warning':
      color = '#faad14'
      break
    default:
      unreachable(status)
  }

  return (
    <VStack
      backgroundColor={color}
      borderColor='white'
      borderRadius={4}
      borderStyle='solid'
      borderWidth={1}
      height={8}
      width={8}
    />
  )
}

export default Badge

import React, { useCallback, useState } from 'react'

import { type MenuProductFieldsFragment, type MenuType } from '../../../types/graphql'
import * as MenuTypes from '../../util/menuTypes'
import { CheckBox } from '../fields'

import { Content, type FilterState, type MultiEditChangeType } from './MultiEditForm'

function retainMenuTypes (products: readonly MenuProductFieldsFragment[], selectedMenuTypes: Partial<Record<MenuType, boolean>>): readonly MenuProductFieldsFragment[] {
  return products.map(p => {
    const product = { ...p }

    for (const menuType of MenuTypes.ALL) {
      if (selectedMenuTypes[menuType] !== true) product[MenuTypes.productFieldName(menuType)] = null
    }

    return product
  }).filter(product => MenuTypes.ALL.some(menuType => product[MenuTypes.productFieldName(menuType)] != null))
}

interface FilterStepProps extends FilterState {
  changeType: MultiEditChangeType
  onDismiss: () => void
  onNext: (products: readonly MenuProductFieldsFragment[]) => void
}

const FilterStep: React.FC<FilterStepProps> = ({ changeType, onDismiss, onNext, menuTypes, products }) => {
  const [selectedMenuTypes, setSelectedMenuTypes] = useState(menuTypes.reduce<Partial<Record<MenuType, boolean>>>((mem, menuType) => ({ ...mem, [menuType]: true }), {}))

  const handleOnPress = useCallback((menuType: MenuType) => () => {
    setSelectedMenuTypes(value => ({ ...value, [menuType]: value[menuType] !== true }))
  }, [setSelectedMenuTypes])

  return (
    <Content
      disableNext={Object.values(selectedMenuTypes).every(v => !v)}
      onDismiss={onDismiss}
      onNext={() => onNext(retainMenuTypes(products, selectedMenuTypes))}
    >
      {(Object.keys(selectedMenuTypes) as MenuType[]).map(menuType => <CheckBox key={menuType} checked={selectedMenuTypes[menuType] ?? false} disabled={changeType === 'course'} onPress={handleOnPress(menuType)} title={MenuTypes.title(menuType)} />)}
    </Content>
  )
}

export default FilterStep

import React, { useState } from 'react'
import { ChromePicker } from 'react-color'
import tinycolor from 'tinycolor2'
import unwrap from 'ts-unwrap'

import Dialog, { Action } from './Dialog'

interface ColorPickerDialogProps {
  initialColor?: string
  name: string
  opaque?: boolean
  onDismiss: () => void
  onSubmit: (color: string) => void
}

const ColorPickerDialog: React.FC<ColorPickerDialogProps> = ({ initialColor, name, opaque, onDismiss, onSubmit }) => {
  const [color, setColor] = useState(initialColor)

  return (
    <Dialog
      actions={[
        <Action key='cancel' onPress={onDismiss} text='Cancel' />,
        <Action key='ok' onPress={() => onSubmit(unwrap(color))} text='Ok' />
      ]}
      title={name}
      visible
    >
      <ChromePicker
        color={color}
        disableAlpha={opaque === true}
        onChangeComplete={newColor => {
          const tc = tinycolor(newColor.rgb)
          setColor(opaque === true ? tc.toHexString() : tc.toHex8String())
        }}
        styles={{ default: { picker: { width: '100%' } } }}
      />
    </Dialog>
  )
}

export default ColorPickerDialog

import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HStack, Text, TextStyle } from 'react-stacked'

import { type RestaurantWhiteLabelFieldsFragment, useCreateRestaurantDeliveryLocationShortUrlsMutation } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import downloadCsvAsFile from '../util/downloadCsvAsFile'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import showAlert from '../util/showAlert'

import { AddButton } from './Buttons'
import FormContainer from './FormContainer'
import { titleFromMenuType } from './RestaurantMenu/MenuTypeToggleButton'
import Warning from './Warning'
import { Select } from './fields'

gql`
  mutation CreateRestaurantDeliveryLocationShortUrls($restaurantId: ID!, $whiteLabelId: ID!) {
    createRestaurantDeliveryLocationShortUrls(
      restaurantId: $restaurantId,
      whiteLabelId: $whiteLabelId,
    ) {
      menuType

      deliveryLocation {
        id

        name
      }

      restaurant {
        id
      }

      shortUrl {
        smartLink
        target
        url
      }

      whiteLabel {
        id

        label
      }
    }
  }
`

interface ShortUrlSchema {
  whiteLabelId: string
}

const schema: ObjectSchema<ShortUrlSchema> = yup.object({
  whiteLabelId: yup.string().trim().min(1, 'Välj vilken whitelabel du vill att länken ska öppna upp i').required('Välj vilken whitelabel du vill att länken ska öppna upp i')
})

interface RestaurantDeliveryLocationShortUrlFormProps {
  restaurantId: string
  restaurantName: string
  whiteLabels: readonly RestaurantWhiteLabelFieldsFragment[]
}

const RestaurantDeliveryLocationShortUrlForm: React.FC<RestaurantDeliveryLocationShortUrlFormProps> = ({ restaurantId, restaurantName, whiteLabels }) => {
  const form = useForm<ShortUrlSchema>({
    criteriaMode: 'all',
    defaultValues: {
      whiteLabelId: whiteLabels.length === 1 ? whiteLabels[0].id : undefined
    },
    resolver: yupResolver(schema)
  })

  const [loading, setLoading] = useState(false)
  const [hasDownloaded, setHasDownloaded] = useState(false)

  const [createRestaurantDeliveryLocationShortUrls, { error }] = useCreateRestaurantDeliveryLocationShortUrlsMutation()

  const csvFileName = `${restaurantName.replace(' ', '_')}_gastrogate_loco_kortlänkar.csv`

  const generateAndDownloadCsv = async (input: ShortUrlSchema): Promise<void> => {
    const { data } = await createRestaurantDeliveryLocationShortUrls({
      variables: {
        restaurantId,
        whiteLabelId: input.whiteLabelId
      }
    })

    const urls = data?.createRestaurantDeliveryLocationShortUrls

    if (urls == null) {
      showAlert('Inga urler hittades', 'Det finns inga urler att generera')

      return
    }

    try {
      downloadCsvAsFile(
        csvFileName,
        urls.map(url => ({
          'Bord / kassa': url.deliveryLocation?.name ?? '',
          Menytyp: url.menuType == null ? 'Ej tillgänglig' : titleFromMenuType(url.menuType),
          'rg-länk': url.shortUrl?.url ?? ''
        }))
      )

      setHasDownloaded(true)
    } catch (error) {
      logError(error)
    }
  }

  const handleCreate = async (input: ShortUrlSchema): Promise<void> => {
    if (hasDownloaded) {
      showAlert('Du har redan hämtat denna fil', `Kolla din nedladdningsfolder efter fil med namnet ${csvFileName}. Om du inte hittar den, kontrollera att du inte har blockerat nedladdningar från denna webbsida. Skulle du behöva generera nya länkar kan du göra det genom att ladda om sidan.`)

      return
    }

    setLoading(true)
    try {
      await generateAndDownloadCsv(input)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (form.getValues('whiteLabelId') != null || whiteLabels.length > 1) return

    form.setValue('whiteLabelId', whiteLabels[0].id)
  }, [whiteLabels])

  return (
    <FormContainer gap={8}>
      <Text size={16} weight='bold'>Massgenerera länkar för bord/kassa</Text>

      <Controller
        control={form.control}
        name='whiteLabelId'
        render={({ field: { onChange, value } }) => {
          if (whiteLabels.length === 1) {
            const selectedWhiteLabel = whiteLabels.find(({ id }) => id === value)

            return (
              <Text size={16}>
                Länken öppnas i appen <TextStyle weight='bold'>{selectedWhiteLabel?.appName ?? selectedWhiteLabel?.label ?? 'N/A'}</TextStyle>
              </Text>
            )
          }

          return (
            <Select
              id='whiteLabelId'
              onChange={onChange}
              options={whiteLabels.map(whiteLabel => ({ title: whiteLabel.appName ?? whiteLabel.label, value: whiteLabel.id })) ?? []}
              title='Välj vilken app som länken öppnas i'
              value={value}
            />
          )
        }}
      />

      {error == null
        ? null
        : <Warning message={error.message} paddingBottom={12} />}

      <HStack justifyContent='end'>
        <AddButton key='update' disabled={loading} loading={loading} onPress={ignoreAsync(form.handleSubmit(handleCreate))} title='Skapa länkar' />
      </HStack>
    </FormContainer>
  )
}

export default RestaurantDeliveryLocationShortUrlForm

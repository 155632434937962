import gql from 'graphql-tag'
import { useState } from 'react'

import { GetWhiteLabelGuestGroupListDataDocument, useDeleteWhiteLabelGuestGroupMutation, useEditWhiteLabelGuestGroupMutation, useGetWhiteLabelGuestGroupEditDataQuery } from '../../types/graphql'
import GuestGroupForm, { type FormInput } from '../components/GuestGroupForm'
import Layout, { ScreenType } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation EditWhiteLabelGuestGroup($whiteLabelId: ID!, $whiteLabelGuestGroupId: ID!, $patch: WhiteLabelGuestGroupPatch!) {
    updateWhiteLabelGuestGroup(whiteLabelId: $whiteLabelId, whiteLabelGuestGroupId: $whiteLabelGuestGroupId, patch: $patch) {
      id

      cardBackgroundColor
      cardImageUrl
      invitationCode
      name
    }
  }

  mutation DeleteWhiteLabelGuestGroup($whiteLabelId: ID!, $whiteLabelGuestGroupId: ID!) {
    deleteWhiteLabelGuestGroup(whiteLabelId: $whiteLabelId, whiteLabelGuestGroupId: $whiteLabelGuestGroupId) {
      id
    }
  }

  query GetWhiteLabelGuestGroupEditData($whiteLabelId: ID!, $whiteLabelGuestGroupId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      guestGroup(id: $whiteLabelGuestGroupId) {
        id

        cardBackgroundColor
        cardImageUrl
        invitationCode
        name
      }
    }
  }
`

const WhiteLabelGuestGroupEdit: React.FC = () => {
  const [navigation, { whiteLabelId, guestGroupId }] = useNavigation<'WhiteLabelGuestGroupEdit'>()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [editWhiteLabelGuestGroup, { error: editWhiteLabelGuestGroupError, loading: saving }] = useEditWhiteLabelGuestGroupMutation()
  const [deleteWhiteLabelGuestGroup, { error: deleteWhiteLabelGuestGroupError, loading: deleting }] = useDeleteWhiteLabelGuestGroupMutation()

  const { data, loading } = useGetWhiteLabelGuestGroupEditDataQuery({ variables: { whiteLabelId, whiteLabelGuestGroupId: guestGroupId } })

  const handleCancel = (): void => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('WhiteLabelGuestGroupList', { whiteLabelId })
  }

  const handleDelete = (): void => {
    deleteWhiteLabelGuestGroup({
      onCompleted: () => {
        navigation.navigate('WhiteLabelGuestGroupList', { whiteLabelId })
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetWhiteLabelGuestGroupListDataDocument, variables: { id: whiteLabelId } }],
      variables: { whiteLabelId, whiteLabelGuestGroupId: guestGroupId }
    }).catch(logError)
  }

  const handleSave = (patch: FormInput): void => {
    editWhiteLabelGuestGroup({
      onCompleted: () => {
        navigation.navigate('WhiteLabelGuestGroupList', { whiteLabelId })
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetWhiteLabelGuestGroupListDataDocument, variables: { id: whiteLabelId } }],
      variables: { patch, whiteLabelGuestGroupId: guestGroupId, whiteLabelId }
    }).catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' },
        { link: ['WhiteLabelGuestGroupList', { whiteLabelId }], title: 'Lojalitetsgrupper' }
      ]}
      loading={loading}
      screenType={ScreenType.Dashboard}
      title='Redigera lojalitetsgrupp'
    >
      <VerificationDialog
        errorMessage={deleteWhiteLabelGuestGroupError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={`Är du säker på att du vill ta bort lojalitetsgruppen ${data?.whiteLabel?.guestGroup?.name ?? ''}?\n\nVarning! Alla medlemmar i gruppen kommer att tas bort, och tillgång till gruppen kommer försvinna i appen.`}
        title='Ta bort grupp'
      />

      <GuestGroupForm
        errorMessage={editWhiteLabelGuestGroupError?.message}
        guestGroup={data?.whiteLabel?.guestGroup}
        onCancel={handleCancel}
        onDelete={() => setShowDeleteDialog(true)}
        onSave={handleSave}
        saving={saving}
      />
    </Layout>
  )
}

export default WhiteLabelGuestGroupEdit

import { Ionicons } from '@expo/vector-icons'
import { IconButton, Tooltip } from '@mui/material'
import * as Clipboard from 'expo-clipboard'
import React, { useState } from 'react'

import ignoreAsync from '../util/ignoreAsync'

interface CopyIconProps {
  textToCopy: string
}

const CopyIcon: React.FC<CopyIconProps> = ({ textToCopy }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  const handleCopyToClipboard = ignoreAsync(async () => {
    await Clipboard.setStringAsync(textToCopy)
    setCopiedToClipboard(true)
    setTimeout(() => setCopiedToClipboard(false), 5000)
  })

  return (
    <Tooltip placement='right' title={copiedToClipboard ? 'Kopierad' : 'Kopiera'}>
      <IconButton>
        <Ionicons color='black' name='copy' onPress={handleCopyToClipboard} size={24} />
      </IconButton>
    </Tooltip>
  )
}

export default CopyIcon

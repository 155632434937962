import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Text, VStack } from 'react-stacked'

import { GetReportGroupsDocument, useCreateMenuReportGroupMutation } from '../../types/graphql'
import ButtonGroup from '../components/ButtonGroup'
import FormContainer from '../components/FormContainer'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

const VAT_RATE_LIST = [0, 6, 12, 25]

gql`
  mutation CreateMenuReportGroup($restaurantId: ID!, $input: MenuReportGroupInput!) {
    createMenuReportGroup(
      restaurantId: $restaurantId
      input: $input
    ) { id }
  }
`

interface ReportGroupCreateForm {
  name: string
  vatRate: number
}

const reportGroupSchema: ObjectSchema<ReportGroupCreateForm> = yup.object({
  name: yup.string().trim().min(1, 'Namn med minst ett tecken krävs').max(50, 'Namn med max 50 tecken tillåts').required(),
  vatRate: yup.number().oneOf(VAT_RATE_LIST).required('Var vänlig välj moms')
})

const ReportGroupCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'ReportGroupCreate'>()

  const [createMenuReportGroup, { error, loading: isSubmitting }] = useCreateMenuReportGroupMutation({ awaitRefetchQueries: true })

  const form = useForm<ReportGroupCreateForm>({
    resolver: yupResolver(reportGroupSchema)
  })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['ReportGroupList', { restaurantId }], title: 'Rapportgrupper' }
  ], [restaurantId])

  const handleCancel = (): void => {
    navigation.navigate('ReportGroupList', { restaurantId })
  }

  const handleSubmitForm = async (input: ReportGroupCreateForm): Promise<void> => {
    await createMenuReportGroup({
      refetchQueries: [
        { query: GetReportGroupsDocument, variables: { restaurantId } },
        'GetSIEReport'
      ],
      variables: { restaurantId, input }
    })

    navigation.navigate('ReportGroupList', { restaurantId })
  }

  if (isSubmitting) {
    return <Layout breadcrumbs={breadcrumbs} loading title='Skapa ny rapportgrupp' />
  }

  return (
    <Layout breadcrumbs={breadcrumbs} hideTitle screenType={ScreenType.Form} title='Skapa ny rapportgrupp'>
      <FormContainer gap={24} title='Skapa ny rapportgrupp'>
        <TextField
          estimatedNumberOfCharacters={20}
          form={form}
          largeTitle
          name='name'
          title='Vad ska din rapportgrupp heta?'
        />

        <Controller
          control={form.control}
          name='vatRate'
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <VStack padding={10} width={240}>
              <Text size={16} weight='bold'>Moms</Text>

              <ButtonGroup
                buttons={VAT_RATE_LIST.map(value => ({ label: `${value}%`, value }))}
                onPress={onChange}
                selected={value}
              />

              {error == null ? null : <Text color='red' weight='bold'>{error.message}</Text>}
            </VStack>
          )}
        />

        <SubmitFormButtons
          disableSaveButton={form.formState.isSubmitting}
          onCancel={handleCancel}
          onSave={ignoreAsync(form.handleSubmit(handleSubmitForm))}
          saving={isSubmitting}
        />

        {error == null
          ? null
          : <Text color='red' weight='bold'>{error.message}</Text>}
      </FormContainer>
    </Layout>
  )
}

export default ReportGroupCreate

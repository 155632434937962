import gql from 'graphql-tag'
import React from 'react'

import { GetWhiteLabelUserListDataDocument, type WhiteLabelUserInput, useCreateWhiteLabelUserMutation, useGetWhiteLabelUserCreateDataQuery } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import WhiteLabelUserForm from '../components/WhiteLabelUserForm'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  query GetWhiteLabelUserCreateData($whiteLabelId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      isOwner: hasAccess(access:Write)
    }
  }

  mutation CreateWhiteLabelUser($whiteLabelId: ID!, $input: WhiteLabelUserInput!) {
    createWhiteLabelUser(whiteLabelId: $whiteLabelId, input: $input) {
      id
    }
  }
`

const WhiteLabelUserCreate: React.FC = () => {
  const [navigation, { whiteLabelId }] = useNavigation<'WhiteLabelUserCreate'>()

  const { data, loading } = useGetWhiteLabelUserCreateDataQuery({ variables: { whiteLabelId } })

  const [createWhiteLabelUser, { error: createError, loading: creating }] = useCreateWhiteLabelUserMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('WhiteLabelUserList', { whiteLabelId }),
    onError: logAndShowError,
    refetchQueries: [{ query: GetWhiteLabelUserListDataDocument, variables: { whiteLabelId } }]
  })

  const handleCancel = (): void => {
    navigation.navigate('WhiteLabelUserList', { whiteLabelId })
  }

  const handleSubmit = ignoreAsync(async (input: WhiteLabelUserInput) => {
    await createWhiteLabelUser({ variables: { input, whiteLabelId } })
  })

  return (
    <Layout
      breadcrumbs={[{ link: ['WhiteLabelUserList', { whiteLabelId }], title: 'Administratörer' }]}
      loading={loading}
      screenType={ScreenType.Form}
      title='Lägg till administratör'
    >
      <FormContainer gap={20}>
        <WhiteLabelUserForm
          canUpdateOwners={data?.whiteLabel?.isOwner ?? false}
          error={createError}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          saving={creating}
        />
      </FormContainer>
    </Layout>
  )
}

export default WhiteLabelUserCreate

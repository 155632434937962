const rePrice = /^(-?[1-9]\d*|0)([,.](\d{2}))?$/

export function parsePrice (value: string): number {
  const match = rePrice.exec(value)
  if (match == null) throw new Error('Invalid price')
  return (Number(match[1]) * 100) + Number(match[3] ?? '0')
}

export function isValidPrice (value: string): boolean {
  return rePrice.test(value)
}

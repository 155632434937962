export default function getSelectableClosingHours (): string[] {
  const times = []

  for (let hour = 6; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hh = hour.toFixed(0).padStart(2, '0')
      const mm = minute.toFixed(0).padStart(2, '0')
      times.push(`${hh}:${mm}`)
    }
  }

  times.push('24:00')
  times.push('00:15')
  times.push('00:30')
  times.push('00:45')

  for (let hour = 1; hour < 6; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hh = hour.toFixed(0).padStart(2, '0')
      const mm = minute.toFixed(0).padStart(2, '0')
      times.push(`${hh}:${mm}`)
    }
  }

  return times
}

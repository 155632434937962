import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import unreachable from 'ts-unreachable'

export type DynamicIconType = 'font-awesome-5' | 'material' | 'material-community'

interface DynamicIconProps {
  color?: string
  name: string
  size?: number
  type?: DynamicIconType
}

function getIconComponent (type: DynamicIconType): typeof FontAwesome5 | typeof MaterialIcons | typeof MaterialCommunityIcons {
  switch (type) {
    case 'font-awesome-5':
      return FontAwesome5
    case 'material':
      return MaterialIcons
    case 'material-community':
      return MaterialCommunityIcons
    default:
      unreachable(type)
  }
}

const DynamicIcon: React.FC<DynamicIconProps> = ({ color, name, size, type = 'material' }) => {
  const Icon = getIconComponent(type)

  return <Icon color={color} name={name} size={size} />
}

export default DynamicIcon

import gql from 'graphql-tag'
import { useCallback, useState } from 'react'

import { type MenuProductFieldsFragment, type MenuProductPatch, type MenuProductPropertiesFieldsFragment, type MenuProductPropertiesPatch, useUpdateMenuProductMutation } from '../../types/graphql'
import * as MenuTypes from '../util/menuTypes'

import logError from './logError'

gql`
  mutation UpdateMenuProduct($restaurantId: ID!, $menuProductId: ID!, $patch: MenuProductPatch!) {
    updateMenuProduct(restaurantId: $restaurantId, menuProductId: $menuProductId, patch: $patch) {
      ...MenuProductFields
    }
  }
`

export type ChangeFn = (properties: MenuProductPropertiesFieldsFragment | null, product?: MenuProductFieldsFragment) => { from: string, to: string, patch: MenuProductPropertiesPatch & { defaultCourseId?: string | null } }

function calculateProductPatch (product: MenuProductFieldsFragment, changeFn: ChangeFn): MenuProductPatch {
  let result: MenuProductPatch = { delivery: undefined, eatIn: undefined, takeAway: undefined }

  for (const menuType of MenuTypes.ALL) {
    const fieldName = MenuTypes.productFieldName(menuType)
    const properties = product[fieldName]

    if (properties != null) {
      const { defaultCourseId, ...propertiesPatch } = changeFn(properties, product).patch

      result = {
        ...result,
        defaultCourseId,
        [fieldName]: propertiesPatch
      }
    }
  }

  return result
}

type UpdateFunction = (products: readonly MenuProductFieldsFragment[], changeFn: ChangeFn) => Promise<void>

interface UpdateResult {
  errorMessage?: string
  updating: boolean
}

export default function useMultiEditProducts (restaurantId: string): readonly [UpdateFunction, UpdateResult] {
  const [updateMenuProduct] = useUpdateMenuProductMutation()

  const [errorMessage, setErrorMessage] = useState<string>()
  const [updating, setUpdating] = useState(false)

  const update = useCallback<UpdateFunction>(async (products: readonly MenuProductFieldsFragment[], changeFn: ChangeFn): Promise<void> => {
    setErrorMessage(undefined)
    setUpdating(true)

    let hasError = false

    try {
      await Promise.all(products.map(async product => {
        const patch = calculateProductPatch(product, changeFn)

        return await updateMenuProduct({
          variables: { restaurantId, menuProductId: product.id, patch }
        })
      }))
    } catch (error) {
      logError(error)
      hasError = true
    } finally {
      setErrorMessage(hasError ? 'Ett fel inträffade vid uppdateringen' : undefined)
      setUpdating(false)
    }
  }, [restaurantId])

  return [update, { errorMessage, updating }] as const
}

import { Temporal } from '@js-temporal/polyfill'
import React, { useState } from 'react'
import { HStack, VStack } from 'react-stacked'

import type TimePeriod from '../util/TimePeriod'
import dateRangeFromTimePeriod, { type PlainDateRange } from '../util/dateRangeFromTimePeriod'

import DateField from './fields/DateField'
import Select from './fields/Select'

function plainDateRangeFromStrings (start: string, end: string): PlainDateRange | null {
  try {
    return {
      end: Temporal.PlainDate.from(end),
      start: Temporal.PlainDate.from(start)
    }
  } catch {
    return null
  }
}

interface DateRangeSelectProps {
  initialValues?: { plainDateRange?: PlainDateRange, timePeriodKind: TimePeriod['kind'] }
  onDateRangeChange: (value: PlainDateRange) => void
  timeZone?: string
}

const DateRangeSelect: React.FC<DateRangeSelectProps> = ({ initialValues, onDateRangeChange, timeZone }) => {
  const [end, setEnd] = useState(initialValues?.plainDateRange?.end?.toString() ?? '')
  const [start, setStart] = useState(initialValues?.plainDateRange?.start?.toString() ?? '')
  const [timePeriod, setTimePeriod] = useState<TimePeriod['kind']>(initialValues == null ? 'Today' : (initialValues?.timePeriodKind == null ? 'Custom' : initialValues.timePeriodKind))

  return (
    <HStack gap={12} grow={1} wrap>
      <VStack grow={1} minWidth={186}>
        <Select
          id='timePeriod'
          onChange={(kind: TimePeriod['kind']) => {
            setTimePeriod(kind)

            if (kind === 'Custom') {
              setStart('')
              setEnd('')
            } else {
              const dateTimeRange = dateRangeFromTimePeriod({ kind }, Temporal.Now.instant(), timeZone)
              setStart(dateTimeRange.start.toString())
              setEnd(dateTimeRange.end.toString())
              onDateRangeChange(dateTimeRange)
            }
          }}
          options={[
            { title: 'Eget datumintervall', value: 'Custom' },
            { title: 'Idag', value: 'Today' },
            { title: 'Igår', value: 'Yesterday' },
            { title: 'Denna vecka', value: 'ThisWeek' },
            { title: 'Förra veckan', value: 'LastWeek' },
            { title: 'Denna månad', value: 'ThisMonth' },
            { title: 'Förra månaden', value: 'LastMonth' },
            { title: 'I år', value: 'ThisYear' },
            { title: 'Förra året', value: 'LastYear' }
          ]}
          title='Vald tidperiod'
          value={timePeriod}
        />
      </VStack>

      <HStack gap={12} grow={1} wrap>
        <VStack grow={1} minWidth={176}>
          <DateField
            onChange={(value) => {
              setStart(value)
              const range = plainDateRangeFromStrings(value, end)
              if (range != null) onDateRangeChange(range)
            }}
            title='Från'
            value={start}
          />
        </VStack>

        <VStack grow={1} minWidth={176}>
          <DateField
            onChange={(value) => {
              setEnd(value)
              const range = plainDateRangeFromStrings(start, value)
              if (range != null) onDateRangeChange(range)
            }}
            title='Till'
            value={end}
          />
        </VStack>
      </HStack>
    </HStack>
  )
}

export default DateRangeSelect

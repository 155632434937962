import React, { useMemo } from 'react'
import { FlatList } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type MenuProductFieldsFragment, type MenuType } from '../../../types/graphql'
import * as MenuTypes from '../../util/menuTypes'

import { type ConfirmState, Content } from './MultiEditForm'

interface ConfirmStepProps extends ConfirmState {
  onDismiss: () => void
  onNext: () => void
}

const ConfirmStep: React.FC<ConfirmStepProps> = ({ changeFn, onDismiss, onNext, products }) => {
  const data = useMemo(() => {
    const result: Array<{ product: MenuProductFieldsFragment, entries: Array<{ description: string, menuType?: MenuType }> }> = []

    for (const product of products) {
      const entries: Array<{ menuType?: MenuType, description: string }> = []

      for (const menuType of MenuTypes.ALL) {
        const properties = product[MenuTypes.productFieldName(menuType)]
        if (properties == null || properties?.price == null) continue

        const { from, patch, to } = changeFn(properties, product)
        if (from === to) continue

        if (patch.defaultCourseId !== undefined) {
          entries.push({
            description: `${from} -> ${to}`
          })

          break
        }

        entries.push({
          description: `${from} -> ${to}`,
          menuType
        })
      }

      if (entries.length > 0) result.push({ product, entries })
    }

    return result
  }, [products])

  return data.length === 0
    ? (
      <Content
        nextButtonTitle='OK'
        onNext={onDismiss}
        title='Inga förändringar gjorda'
      />
    )
    : (
      <Content
        nextButtonTitle='Spara'
        onDismiss={onDismiss}
        onNext={onNext}
        title='Dessa förändringar kommer genomföras'
      >
        <FlatList
          data={data}
          ItemSeparatorComponent={() => <HStack backgroundColor='#ccc' height={1} />}
          keyExtractor={item => item.product.id}
          renderItem={({ item: { product, entries } }) => (
            <VStack paddingVertical={4}>
              <Text weight='bold'>{product.name ?? ''}</Text>
              {entries.map(({ description, menuType }, index) => (
                <HStack key={menuType ?? `${product.name ?? ''}${index}`} paddingLeft={8}>
                  {menuType == null ? null : <Text>{MenuTypes.title(menuType)}</Text>}
                  <Spacer grow={1} width={12} />
                  <Text>{description}</Text>
                </HStack>
              ))}
            </VStack>
          )}
        />
      </Content>
    )
}

export default ConfirmStep

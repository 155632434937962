import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'

import { GetRestaurantPaymentMethodListDataDocument, type PaymentMethodPatch, useDeletePaymentMethodMutation, useEditPaymentMethodMutation, useGetRestaurantPaymentMethodEditDataQuery } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import PaymentMethodForm from '../components/PaymentMethodForm'
import VerificationDialog from '../components/VerificationDialog'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantPaymentMethodEditData($restaurantId: ID!, $paymentMethodId: ID!) {
    restaurant(id: $restaurantId) {
      id

      paymentMethod (id: $paymentMethodId) {
        ...FullPaymentMethod
      }
    }
  }

  mutation DeletePaymentMethod($restaurantId: ID!, $paymentMethodId: ID!) {
    deletePaymentMethod(
      restaurantId: $restaurantId,
      paymentMethodId: $paymentMethodId
    )
  }

  mutation EditPaymentMethod($restaurantId: ID!, $paymentMethodId: ID!, $patch: PaymentMethodPatch!) {
    updatePaymentMethod(
      restaurantId: $restaurantId,
      paymentMethodId: $paymentMethodId,
      patch: $patch
    ) {
      ...FullPaymentMethod
    }
  }
`

const RestaurantPaymentMethodCreate: React.FC = () => {
  const [navigation, { restaurantId, paymentMethodId }] = useNavigation<'RestaurantPaymentMethodEdit'>()

  const [showingVerificationDialog, setShowingVerificationDialog] = useState(false)

  const { data, loading } = useGetRestaurantPaymentMethodEditDataQuery({ variables: { restaurantId, paymentMethodId } })
  const [deletePaymentMethod, { error: errorDelete, loading: deleting }] = useDeletePaymentMethodMutation({
    onCompleted: () => {
      setShowingVerificationDialog(false)
      navigation.navigate('RestaurantPaymentMethodList', { restaurantId })
    }
  })
  const [editPaymentMethod, { error: errorEdit, loading: saving }] = useEditPaymentMethodMutation({ onCompleted: () => navigation.navigate('RestaurantPaymentMethodList', { restaurantId }) })

  const paymentMethod = data?.restaurant?.paymentMethod

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantPaymentMethodList', { restaurantId }], title: 'Betalsätt' }
  ], [restaurantId])

  const handleDelete = (): void => {
    deletePaymentMethod({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetRestaurantPaymentMethodListDataDocument, variables: { restaurantId } }],
      variables: { restaurantId, paymentMethodId }
    }).catch(logError)
  }

  const handleDismiss = (): void => {
    navigation.navigate('RestaurantPaymentMethodList', { restaurantId })
  }

  const handleSave = (patch: PaymentMethodPatch): void => {
    editPaymentMethod({ variables: { restaurantId, paymentMethodId, patch } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title='Ändra betalsätt'>
      <VerificationDialog
        errorMessage={errorDelete?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowingVerificationDialog(false)}
        open={showingVerificationDialog}
        prompt={`Vill du permanent ta bort betalsättet "${paymentMethod?.name ?? 'Ej namngiven'}"?`}
        title='Radera betalsättet'
      />

      {loading ? null : (
        <PaymentMethodForm
          defaultValues={{
            iconUrl: paymentMethod?.iconUrl,
            name: paymentMethod?.name ?? '',
            receiptPaymentMethodLabel: paymentMethod?.receiptPaymentMethodLabel ?? '',
            sieAccountNumber: paymentMethod?.sieAccountNumber ?? ''
          }}
          errorMessage={errorDelete?.message ?? errorEdit?.message}
          mode='edit'
          onDelete={() => setShowingVerificationDialog(true)}
          onDismiss={handleDismiss}
          onSave={handleSave}
          saving={saving}
        />
      )}
    </Layout>
  )
}

export default RestaurantPaymentMethodCreate

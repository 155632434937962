export default function getSelectableOpeningHours (): string[] {
  const times = []

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hh = hour.toFixed(0).padStart(2, '0')
      const mm = minute.toFixed(0).padStart(2, '0')
      times.push(`${hh}:${mm}`)
    }
  }

  return times
}

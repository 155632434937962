import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'

import { FixedDeliveryTime, GetTillsDocument, MenuTypeFilter, SendToKitchen, type TillInput, TillLayout, useDeleteTillMutation, useEditTillMutation, useGetTillQuery } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import TillForm from '../components/TillForm'
import VerificationDialog from '../components/VerificationDialog'
import extractSupportedTillPaymentMethods from '../util/extractSupportedTillPaymentMethods'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetTill($restaurantId: ID!, $tillId: ID!) {
    me {
      id
      isSuperUser
    }

    restaurant(id: $restaurantId) {
      id

      hasNetsConnectCloudLink
      hasSkipSlipPrintOnPrinterQueuesAccess
      isBetaTesting
      orderDisplaySystemEnabled

      deliveryLocations {
        id

        name
      }

      paymentMethods {
        ...FullPaymentMethod
      }

      posTerminals {
        ...FullPosTerminal
      }

      printerQueues {
        id
        name
      }

      till(id: $tillId) {
        ...FullTill

        featuredDiscounts{
          id

          code
        }
      }
    }
  }

  mutation EditTill($restaurantId: ID!, $tillId: ID!, $patch: TillPatch!, $orderDisplaySystemEnabled: Boolean) {
    updateTill(
      restaurantId: $restaurantId,
      tillId: $tillId,
      patch: $patch,
    ) {
      ...FullTill
    }

    updateRestaurant(
      restaurantId: $restaurantId,
      patch: { orderDisplaySystemEnabled: $orderDisplaySystemEnabled }
    ) {
      id

      orderDisplaySystemEnabled
    }
  }

  mutation DeleteTill($restaurantId: ID!, $tillId: ID!) {
    deleteTill(
      restaurantId: $restaurantId,
      tillId: $tillId,
    )
  }
`

const RestaurantTillEdit: React.FC = () => {
  const [navigation, { restaurantId, tillId }] = useNavigation<'RestaurantTillEdit'>()

  const { data, loading } = useGetTillQuery({ errorPolicy: 'all', variables: { restaurantId, tillId } })

  const [editTill, { loading: saving }] = useEditTillMutation({ onCompleted: () => navigation.navigate('RestaurantTillList', { restaurantId }) })
  const [deleteTill, { loading: deleting, error: deleteError }] = useDeleteTillMutation({ onCompleted: () => navigation.navigate('RestaurantTillList', { restaurantId }), awaitRefetchQueries: true, refetchQueries: [{ query: GetTillsDocument, variables: { restaurantId } }] })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const netsConnectCloudIsOffline = !loading && data?.restaurant?.hasNetsConnectCloudLink == null

  const supportedPaymentMethods = extractSupportedTillPaymentMethods(data?.restaurant?.paymentMethods)
  const till = data?.restaurant?.till

  const initialValues: TillInput | null = useMemo(() => {
    if (till == null || till?.name == null) return null

    return ({
      featuredDiscountCode: till?.featuredDiscounts?.[0]?.code ?? null,
      fixedDeliveryLocationId: till.fixedDeliveryLocation?.id ?? null,
      fixedDeliveryTime: till.fixedDeliveryTime ?? FixedDeliveryTime.Any,
      layout: till.layout ?? TillLayout.Modern,
      name: till.name,
      paymentMethodIds: till?.paymentMethods?.map(paymentMethod => paymentMethod.id) ?? undefined,
      posTerminalIds: till?.posTerminals?.map(posTerminal => posTerminal.id) ?? undefined,
      preferredApiEnvironment: till.preferredApiEnvironment ?? undefined,
      receiptPrinterQueueId: till.receiptPrinterQueue?.id ?? null,
      sendOrderToKitchen: till.sendOrderToKitchen ?? SendToKitchen.Always,
      showsCategoryBar: till.showsCategoryBar ?? false,
      showsDiscountField: till.showsDiscountField ?? false,
      showsEmptyShoppingCartButton: till.showsEmptyShoppingCartButton ?? false,
      showsHoldKitchenPrint: till.showsHoldKitchenPrint ?? false,
      showsLogo: till.showsLogo ?? false,
      showsMenuType: till.showsMenuType ?? MenuTypeFilter.All,
      showsOpeningStatus: till.showsOpeningStatus ?? false,
      showsOpenOrders: till.showsOpenOrders ?? false,
      showsSearch: till.showsSearch ?? false,
      showsTip: till.showsTip ?? true,
      skipSlipPrintOnPrinterQueueIds: till.skipSlipPrintOnPrinterQueues?.map(printerQueue => printerQueue.id) ?? undefined
    })
  }, [data?.restaurant?.posTerminals, till])

  const handleDelete = (): void => {
    deleteTill({ variables: { restaurantId, tillId } }).catch(logError)
  }

  const handleSave = (patch: TillInput, orderDisplaySystemEnabled: false | null): void => {
    editTill({ variables: { restaurantId, tillId, patch, orderDisplaySystemEnabled } }).catch(logError)
  }

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantTillList', { restaurantId }], title: 'Kassor för POS' }
  ], [restaurantId])

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title='Redigera kassa'>
      {(data?.restaurant?.deliveryLocations == null || initialValues == null) ? null : (
        <TillForm
          deliveryLocationOptions={data.restaurant.deliveryLocations}
          hasSkipSlipPrintOnPrinterQueuesAccess={data.restaurant.hasSkipSlipPrintOnPrinterQueuesAccess ?? false}
          initialValues={initialValues}
          isSuperUser={data?.me?.isSuperUser ?? false}
          netsConnectCloudIsOffline={netsConnectCloudIsOffline}
          onDelete={() => setShowDeleteDialog(true)}
          onDismiss={() => navigation.navigate('RestaurantTillList', { restaurantId })}
          onSave={handleSave}
          orderDisplaySystemEnabled={data.restaurant.orderDisplaySystemEnabled ?? false}
          paymentMethodOptions={supportedPaymentMethods}
          posTerminalOptions={data?.restaurant?.posTerminals ?? []}
          receiptPrinterOptions={data.restaurant.printerQueues ?? []}
          restaurantIsBetaTesting={data.restaurant.isBetaTesting}
          saving={saving}
        />
      )}
      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt='Vill du ta bort denna kassa?'
        title='Radera kassa'
      />
    </Layout>
  )
}

export default RestaurantTillEdit

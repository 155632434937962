import React from 'react'
import { type UseFormReturn, useForm } from 'react-hook-form'
import { HStack, VStack } from 'react-stacked'

import { type PaymentMethodInput, type PaymentMethodPatch, PaymentProvider } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'
import Warning from './Warning'
import { ImageField, Select, TextField } from './fields'

const createSchema: ObjectSchema<PaymentMethodInput> = yup.object({
  iconUrl: yup.string().url().nullable(),
  name: yup.string().required('Namn måste anges'),
  paymentProvider: yup.mixed<PaymentProvider>().oneOf([PaymentProvider.External]).required(),
  receiptPaymentMethodLabel: yup.string().required('Betalsättets namn på kvittot måste anges'),
  sieAccountNumber: yup.string().matches(/^[1-8][0-9]{3}$/, 'SIE kontonummer ser inte giltigt ut').required('SIE kontonummer måste anges')
})

const editSchema: ObjectSchema<PaymentMethodPatch> = yup.object({
  iconUrl: yup.string().url().nullable(),
  name: yup.string().required('Namn måste anges'),
  receiptPaymentMethodLabel: yup.string().required('Betalsättets namn på kvittot måste anges'),
  sieAccountNumber: yup.string().matches(/^[1-8][0-9]{3}$/, 'SIE kontonummer ser inte giltigt ut').required('SIE kontonummer måste anges')
})

interface PaymentMethodFormProps {
  deleting?: boolean
  errorMessage?: string
  onDelete?: () => void
  onDismiss: () => void
  saving: boolean
}

interface PaymentMethodFormProps_Create extends PaymentMethodFormProps {
  defaultValues: PaymentMethodInput
  mode: 'create'
  onSave: (paymentMethod: PaymentMethodInput) => void
}

interface PaymentMethodFormProps_Edit extends PaymentMethodFormProps {
  defaultValues: PaymentMethodPatch
  mode: 'edit'
  onSave: (paymentMethod: PaymentMethodPatch) => void
}

const PaymentMethodForm: React.FC<PaymentMethodFormProps_Create | PaymentMethodFormProps_Edit> = ({ deleting, errorMessage, onDelete, onDismiss, onSave, saving, ...props }) => {
  // FIXME: `any` is used here beecause I cannot figure out how to type it as `PaymentMethodPatch | PaymentMethodInput`
  const form: UseFormReturn<any> = props.mode === 'edit'
    ? useForm<PaymentMethodPatch>({ criteriaMode: 'all', defaultValues: props.defaultValues, mode: 'onTouched', resolver: yupResolver(editSchema) })
    : useForm<PaymentMethodInput>({ criteriaMode: 'all', defaultValues: props.defaultValues, mode: 'onTouched', resolver: yupResolver(createSchema) })

  const disableSaveButton = saving || (deleting ?? false)

  return (
    <FormContainer gap={16}>
      {errorMessage == null ? null : <Warning message={errorMessage} paddingBottom={16} />}

      <TextField
        form={form}
        name='name'
        title='Namn'
      />

      <HStack alignItems='end' gap={16} grow={1} width='100%' wrap>
        <ImageField
          cropToolProps={{ maxWidth: 2000, ratio: 1 }}
          form={form}
          name='iconUrl'
          title='Ikon'
          viewOptions={{ height: 150, width: 150 }}
        />

        <VStack gap={8} grow={1}>
          {props.mode === 'edit' ? null : (
            <Select
              form={form}
              name='paymentProvider'
              options={[{ title: 'Extern leverantör', value: PaymentProvider.External }]}
              title='Betallösningsleverantör'
            />
          )}

          <TextField
            form={form}
            name='receiptPaymentMethodLabel'
            title='Betalsättets namn på kvittot'
          />

          <TextField
            form={form}
            name='sieAccountNumber'
            title='SIE kontonummer'
          />
        </VStack>
      </HStack>

      <SubmitFormButtons
        deleting={deleting}
        disableSaveButton={disableSaveButton}
        onCancel={onDismiss}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSave))}
        saving={saving}
      />
    </FormContainer>
  )
}

export default PaymentMethodForm

import * as upstream from '@react-navigation/native'
import { type LinkingOptions } from '@react-navigation/native'

import { type DeliveryLocationType } from '../../types/graphql'

type EmptyObject = Record<string, never>

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ParamList = {
  AccountEdit: EmptyObject
  CashregisterReportList: { restaurantId: string }
  CashregisterReportPreview: { restaurantId: string }
  CashregisterReportView: { restaurantId: string, reportId: string }
  CashregisterView: { restaurantId: string }
  ControlUnitList: EmptyObject
  ControlUnitView: { controlUnitId: string }
  DeliveryHeroRestaurantIntegration: EmptyObject
  DeliveryLocationCreate: { restaurantId: string, type: DeliveryLocationType.CashDesk | DeliveryLocationType.Table }
  DeliveryLocationEdit: { restaurantId: string, deliveryLocationId: string }
  DeliveryLocationList: { restaurantId: string }
  DeliveryDeliveryHeroEdit: { restaurantId: string }
  DeliveryLocoEdit: { restaurantId: string }
  FortnoxAccountingImportComplete: { code?: string, error?: string, error_description?: string, state?: string }
  EditUsername: EmptyObject
  Login: EmptyObject
  MarketingPushNotificationCreate: { whiteLabelId: string }
  MenuAddonProductCreate: { restaurantId: string, isDeliveryCost?: boolean }
  MenuAddonProductEdit: { restaurantId: string, menuAddonProductId: string, isDeliveryCost?: boolean }
  MenuAddonProductList: { restaurantId: string }
  MenuAlternativeGroupCreate: { restaurantId: string }
  MenuAlternativeGroupEdit: { restaurantId: string, menuAlternativeGroupId: string }
  MenuAlternativeGroupList: { restaurantId: string }
  MenuCategoryCreate: { restaurantId: string, menuPageId: string }
  MenuCategoryEdit: { restaurantId: string, menuCategoryId: string }
  MenuCourseCreate: { restaurantId: string }
  MenuCourseEdit: { restaurantId: string, menuCourseId: string }
  MenuCourseList: { restaurantId: string }
  MenuOpeningHoursCreate: { restaurantId: string }
  MenuOpeningHoursEdit: { restaurantId: string, menuOpeningHoursId: string }
  MenuPageCreate: { restaurantId: string }
  MenuPageEdit: { restaurantId: string, menuPageId: string }
  MenuPageView: { restaurantId: string, menuPageId: string }
  MenuProductCreate: { restaurantId: string, menuCategoryId: string }
  MenuProductEdit: { restaurantId: string, menuProductId: string }
  MenuView: { restaurantId: string }
  MetaOrganizationDiscountCreate: { metaOrganizationId: string }
  MetaOrganizationDiscountEdit: { metaOrganizationId: string, discountId: string }
  MetaOrganizationList: EmptyObject
  MetaOrganizationView: { metaOrganizationId: string }
  OpeningHoursList: { restaurantId: string }
  OrganizationCreate: EmptyObject
  OrganizationFortnoxAccountingImportView: { organizationId: string }
  OrganizationSelect: EmptyObject
  OrganizationUserList: { organizationId: string }
  OrganizationUserCreate: { organizationId: string }
  OrganizationUserEdit: { organizationId: string, organizationUserId: string }
  PasswordReset: EmptyObject
  PasswordResetConfirm: { organizationUserSignup?: '', token: string, whiteLabelUserSignup?: '' }
  ReportGroupCreate: { restaurantId: string }
  ReportGroupList: { restaurantId: string }
  RestaurantControlUnitOutageList: { restaurantId: string }
  RestaurantCreate: { organizationId: string }
  RestaurantDashboard: { restaurantId: string }
  RestaurantDeliveryList: { restaurantId: string }
  RestaurantDesignView: { restaurantId: string }
  RestaurantDiscountCreate: { restaurantId: string }
  RestaurantDiscountEdit: { restaurantId: string, discountId: string }
  RestaurantDiscountsList: { restaurantId: string }
  RestaurantEdit: { restaurantId: string }
  RestaurantEmployeeCreate: { restaurantId: string }
  RestaurantEmployeeEdit: { restaurantId: string, employeeId: string }
  RestaurantEmployeeGroupCreate: { restaurantId: string }
  RestaurantEmployeeGroupEdit: { restaurantId: string, employeeGroupId: string }
  RestaurantEmployeeList: { restaurantId: string }
  RestaurantGetStarted: { restaurantId: string }
  RestaurantList: { q?: string }
  RestaurantOpeningHoursView: { restaurantId: string }
  RestaurantOrderDisplaySystem: { restaurantId: string }
  RestaurantPaymentMethodCreate: { restaurantId: string }
  RestaurantPaymentMethodEdit: { restaurantId: string, paymentMethodId: string }
  RestaurantPaymentMethodList: { restaurantId: string }
  RestaurantPersonalkollenView: { restaurantId: string }
  RestaurantPhysicalPrinterCreate: { restaurantId: string }
  RestaurantPhysicalPrinterEdit: { restaurantId: string, physicalPrinterId: string }
  RestaurantPosTerminalEdit: { restaurantId: string, posTerminalId: string }
  RestaurantPrinterQueueCreate: { restaurantId: string }
  RestaurantPrinterQueueEdit: { restaurantId: string, printerQueueId: string }
  RestaurantPrintersView: { restaurantId: string }
  RestaurantReceiptList: { restaurantId: string }
  RestaurantSalesReportList: { restaurantId: string }
  RestaurantSalesReportView: { restaurantId: string, dateTimeRangeEnd: string, dateTimeRangeStart: string }
  RestaurantStatisticsView: { restaurantId: string }
  RestaurantStripeEdit: { restaurantId: string }
  RestaurantSwishEdit: { restaurantId: string }
  RestaurantTillCreate: { restaurantId: string }
  RestaurantTillEdit: { restaurantId: string, tillId: string }
  RestaurantTillList: { restaurantId: string }
  SieAccountList: { organizationId: string }
  SieReportView: { organizationId: string }
  StatisticsOverview: EmptyObject
  StripeReportList: { organizationId: string }
  StripeReportView: { organizationId: string, reportId: string }
  StripeSetupComplete: { code: string, state: string }
  SuperUserDashboard: EmptyObject
  TimeSlotsView: { restaurantId: string }
  UserCreate: EmptyObject
  WhiteLabelCreate: EmptyObject
  WhiteLabelDashboard: { whiteLabelId: string }
  WhiteLabelDiscountCreate: { whiteLabelId: string }
  WhiteLabelDiscountEdit: { whiteLabelId: string, discountId: string }
  WhiteLabelDiscountList: { whiteLabelId: string }
  WhiteLabelGuestGroupCreate: { whiteLabelId: string }
  WhiteLabelGuestGroupCustomerRegister: { whiteLabelId: string }
  WhiteLabelGuestGroupEdit: { whiteLabelId: string, guestGroupId: string }
  WhiteLabelGuestGroupList: { whiteLabelId: string }
  WhiteLabelGuestGroupMemberCreate: { whiteLabelId: string, guestGroupId?: string | null }
  WhiteLabelGuestGroupMemberView: { whiteLabelId: string, guestGroupId: string, memberId: string }
  WhiteLabelGuestGroupView: { whiteLabelId: string, guestGroupId: string }
  WhiteLabelList: EmptyObject
  WhiteLabelUserCreate: { whiteLabelId: string }
  WhiteLabelUserEdit: { whiteLabelId: string, whiteLabelUserId: string }
  WhiteLabelUserList: { whiteLabelId: string }
  WhiteLabelView: { whiteLabelId: string }
  YouniumUsageExport: EmptyObject
}

export const linkingOptions: LinkingOptions<ParamList> = {
  prefixes: [],
  config: {
    screens: {
      AccountEdit: { path: '/account' },
      CashregisterReportList: { path: 'restaurants/:restaurantId/reports/cashregister' },
      CashregisterReportPreview: { path: 'restaurants/:restaurantId/reports/cashregister/preview' },
      CashregisterReportView: { path: 'restaurants/:restaurantId/reports/cashregister/:reportId' },
      CashregisterView: { path: 'restaurants/:restaurantId/cashregister' },
      ControlUnitList: { path: 'control-units' },
      ControlUnitView: { path: 'control-units/:controlUnitId' },
      DeliveryHeroRestaurantIntegration: { path: 'delivery-hero-restaurant-integration' },
      DeliveryLocationCreate: { path: 'restaurants/:restaurantId/delivery-locations/create' },
      DeliveryLocationEdit: { path: 'restaurants/:restaurantId/delivery-locations/:deliveryLocationId/edit' },
      DeliveryLocationList: { path: 'restaurants/:restaurantId/delivery-locations' },
      DeliveryDeliveryHeroEdit: { path: 'restaurants/:restaurantId/delivery/delivery-hero' },
      DeliveryLocoEdit: { path: 'restaurants/:restaurantId/delivery/loco' },
      EditUsername: { path: '/account/edit/username' },
      FortnoxAccountingImportComplete: { path: 'hooks/v1/fortnox-accounting-import-complete' },
      Login: { path: '' },
      MarketingPushNotificationCreate: { path: 'white-labels/:whiteLabelId/marketing-push-notifications/create' },
      MenuAddonProductCreate: { path: 'restaurants/:restaurantId/menu/addon-products/create' },
      MenuAddonProductEdit: { path: 'restaurants/:restaurantId/menu/addon-products/:menuAddonProductId/edit' },
      MenuAddonProductList: { path: 'restaurants/:restaurantId/menu/addon-products' },
      MenuAlternativeGroupCreate: { path: 'restaurants/:restaurantId/menu/alternative-groups/create' },
      MenuAlternativeGroupEdit: { path: 'restaurants/:restaurantId/menu/alternative-groups/:menuAlternativeGroupId/edit' },
      MenuAlternativeGroupList: { path: 'restaurants/:restaurantId/menu/alternative-groups' },
      MenuCategoryCreate: { path: 'restaurants/:restaurantId/menu/pages/:menuPageId/categories/create' },
      MenuCategoryEdit: { path: 'restaurants/:restaurantId/menu/categories/:menuCategoryId/edit' },
      MenuCourseCreate: { path: 'restaurants/:restaurantId/menu/courses/create' },
      MenuCourseEdit: { path: 'restaurants/:restaurantId/menu/courses/:menuCourseId/edit' },
      MenuCourseList: { path: 'restaurants/:restaurantId/menu/courses' },
      MenuOpeningHoursCreate: { path: 'restaurants/:restaurantId/opening-hours/menu/create' },
      MenuOpeningHoursEdit: { path: 'restaurants/:restaurantId/opening-hours/menu/:menuOpeningHoursId' },
      MenuPageCreate: { path: 'restaurants/:restaurantId/menu/pages/create' },
      MenuPageEdit: { path: 'restaurants/:restaurantId/menu/pages/:menuPageId/edit' },
      MenuPageView: { path: 'restaurants/:restaurantId/menu/pages/:menuPageId' },
      MenuProductCreate: { path: 'restaurants/:restaurantId/menu/categories/:menuCategoryId/products/create' },
      MenuProductEdit: { path: 'restaurants/:restaurantId/menu/products/:menuProductId/edit' },
      MenuView: { path: 'restaurants/:restaurantId/menu' },
      MetaOrganizationDiscountCreate: { path: 'meta-organizations/:metaOrganizationId/discounts/create' },
      MetaOrganizationDiscountEdit: { path: 'meta-organizations/:metaOrganizationId/discounts/:discountId/edit' },
      MetaOrganizationList: { path: 'meta-organizations' },
      MetaOrganizationView: { path: 'meta-organizations/:metaOrganizationId' },
      OpeningHoursList: { path: 'restaurants/:restaurantId/opening-hours' },
      OrganizationCreate: { path: 'organizations/create' },
      OrganizationFortnoxAccountingImportView: { path: 'organizations/:organizationId/fortnox-accounting-import' },
      OrganizationSelect: { path: 'organizations/select' },
      OrganizationUserList: { path: 'organization/:organizationId/users' },
      OrganizationUserCreate: { path: 'organizations/:organizationId/users/create' },
      OrganizationUserEdit: { path: 'organization/:organizationId/users/:organizationUserId' },
      PasswordReset: { path: 'users/reset-password' },
      PasswordResetConfirm: { path: 'users/reset-password/confirm' },
      ReportGroupCreate: { path: 'restaurants/:restaurantId/menu/report-groups/create' },
      ReportGroupList: { path: 'restaurants/:restaurantId/menu/report-groups' },
      RestaurantControlUnitOutageList: { path: 'restaurants/:restaurantId/control-unit-outages' },
      RestaurantCreate: { path: 'organizations/:organizationId/restaurants/create' },
      RestaurantDashboard: { path: 'restaurants/:restaurantId/dashboard' },
      RestaurantDeliveryList: { path: 'restaurants/:restaurantId/delivery' },
      RestaurantDesignView: { path: 'restaurants/:restaurantId/design' },
      RestaurantDiscountCreate: { path: 'restaurants/:restaurantId/discounts/create' },
      RestaurantDiscountEdit: { path: 'restaurants/:restaurantId/discounts/:discountId/edit' },
      RestaurantDiscountsList: { path: 'restaurants/:restaurantId/discounts' },
      RestaurantEdit: { path: 'restaurants/:restaurantId/edit' },
      RestaurantEmployeeCreate: { path: 'restaurants/:restaurantId/employees/create' },
      RestaurantEmployeeEdit: { path: 'restaurants/:restaurantId/employees/:employeeId/edit' },
      RestaurantEmployeeGroupCreate: { path: 'restaurants/:restaurantId/employees/groups/create' },
      RestaurantEmployeeGroupEdit: { path: 'restaurants/:restaurantId/employees/groups/:employeeGroupId/edit' },
      RestaurantEmployeeList: { path: 'restaurants/:restaurantId/employees' },
      RestaurantGetStarted: { path: 'restaurants/:restaurantId/get-started' },
      RestaurantList: { path: 'restaurants' },
      RestaurantOpeningHoursView: { path: 'restaurants/:restaurantId/opening-hours/restaurant' },
      RestaurantOrderDisplaySystem: { path: 'restaurants/:restaurantId/order-display-system' },
      RestaurantPaymentMethodCreate: { path: 'restaurants/:restaurantId/payment-methods/create' },
      RestaurantPaymentMethodEdit: { path: 'restaurants/:restaurantId/payment-methods/:paymentMethodId/edit' },
      RestaurantPaymentMethodList: { path: 'restaurants/:restaurantId/payment-methods' },
      RestaurantPersonalkollenView: { path: 'restaurants/:restaurantId/personalkollen' },
      RestaurantPhysicalPrinterCreate: { path: 'restaurants/:restaurantId/physical-printers/create' },
      RestaurantPhysicalPrinterEdit: { path: 'restaurants/:restaurantId/physical-printers/:physicalPrinterId/edit' },
      RestaurantPosTerminalEdit: { path: 'restaurants/:restaurantId/pos-terminals/:posTerminalId/edit' },
      RestaurantPrinterQueueCreate: { path: 'restaurants/:restaurantId/printer-queues/create' },
      RestaurantPrinterQueueEdit: { path: 'restaurants/:restaurantId/printer-queues/:printerQueueId/edit' },
      RestaurantPrintersView: { path: 'restaurants/:restaurantId/printers' },
      RestaurantReceiptList: { path: 'restaurants/:restaurantId/receipts' },
      RestaurantSalesReportList: { path: 'restaurants/:restaurantId/sales-reports' },
      RestaurantSalesReportView: { path: 'restaurants/:restaurantId/sales-reports/view' },
      RestaurantStatisticsView: { path: 'restaurants/:restaurantId/statistics' },
      RestaurantStripeEdit: { path: 'restaurants/:restaurantId/payment-methods/builtin-stripe/edit' },
      RestaurantSwishEdit: { path: 'restaurants/:restaurantId/payment-methods/builtin-swish/edit' },
      RestaurantTillCreate: { path: 'restaurants/:restaurantId/tills/create' },
      RestaurantTillEdit: { path: 'restaurants/:restaurantId/tills/:tillId/edit' },
      RestaurantTillList: { path: 'restaurants/:restaurantId/tills' },
      SieAccountList: { path: 'organization/:organizationId/sie/accounts' },
      SieReportView: { path: 'organization/:organizationId/sie' },
      StatisticsOverview: { path: 'restaurants/statistics' },
      StripeReportList: { path: 'organization/:organizationId/stripe' },
      StripeReportView: { path: 'organization/:organizationId/stripe/:reportId' },
      StripeSetupComplete: { path: 'hooks/v1/stripe-setup-complete' },
      SuperUserDashboard: { path: 'dashboard' },
      TimeSlotsView: { path: 'restaurants/:restaurantId/time-slots' },
      UserCreate: { path: 'users/create' },
      WhiteLabelCreate: { path: 'white-labels/create' },
      WhiteLabelDashboard: { path: 'white-labels/:whiteLabelId' },
      WhiteLabelDiscountCreate: { path: 'white-labels/:whiteLabelId/discounts/create' },
      WhiteLabelDiscountEdit: { path: 'white-labels/:whiteLabelId/discounts/:discountId/edit' },
      WhiteLabelDiscountList: { path: 'white-labels/:whiteLabelId/discounts' },
      WhiteLabelGuestGroupCreate: { path: 'white-labels/:whiteLabelId/guest-groups/create' },
      WhiteLabelGuestGroupCustomerRegister: { path: 'white-labels/:whiteLabelId/guest-groups/customer-register' },
      WhiteLabelGuestGroupEdit: { path: 'white-labels/:whiteLabelId/guest-groups/:guestGroupId/edit' },
      WhiteLabelGuestGroupList: { path: 'white-labels/:whiteLabelId/guest-groups' },
      WhiteLabelGuestGroupMemberCreate: { path: 'white-labels/:whiteLabelId/member/create' },
      WhiteLabelGuestGroupMemberView: { path: 'white-labels/:whiteLabelId/guest-groups/:guestGroupId/member/:memberId' },
      WhiteLabelGuestGroupView: { path: 'white-labels/:whiteLabelId/guest-groups/:guestGroupId' },
      WhiteLabelList: { path: 'white-labels' },
      WhiteLabelUserCreate: { path: 'white-labels/:whiteLabelId/users/create' },
      WhiteLabelUserEdit: { path: 'white-labels/:whiteLabelId/users/:whiteLabelUserId/edit' },
      WhiteLabelUserList: { path: 'white-labels/:whiteLabelId/users' },
      WhiteLabelView: { path: 'white-labels/:whiteLabelId/edit' },
      YouniumUsageExport: { path: 'younium-usage-export' }
    }
  },
  getPathFromState: upstream.getPathFromState,
  getStateFromPath: upstream.getStateFromPath
}

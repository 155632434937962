import { type ApolloError } from '@apollo/client'
import React from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import unreachable from 'ts-unreachable'

import { type DiscountInput, MenuType, type OpeningHoursPeriodInput } from '../../types/graphql'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { CheckBox, DateTimeField, IntegerField, MoneyField, MultiSelectCheckbox } from '../components/fields'
import yup, { type ObjectSchema, emptyStringToUndefinedTransform, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import { parsePrice } from '../util/price'

import { discountValidFromSchema, discountValidUntilSchema } from './DiscountEditForm'
import FormContainer from './FormContainer'
import Select from './Select'
import { TextField } from './TextField'
import Warning from './Warning'
import { OpeningHoursPeriodsField, openingHoursPeriodsSchema } from './fields/OpeningHoursPeriodsField'

function titleForMenuType (menuType: MenuType): string {
  switch (menuType) {
    case MenuType.Delivery:
      return 'Leverans'
    case MenuType.EatIn:
      return 'Äta här'
    case MenuType.GiftCard:
      return 'Presentkort'
    case MenuType.TakeAway:
      return 'Ta med'
    default:
      unreachable(menuType)
  }
}

export const menuTypeSelectOptions = [
  { title: 'Alla', value: null },
  ...[MenuType.Delivery, MenuType.EatIn, MenuType.TakeAway].map(value => ({ title: titleForMenuType(value), value }))
]

export interface FormInput {
  code?: string | null
  description?: string
  discountAmount?: string | null
  discountLimit?: string | null
  discountPercentage?: string | null
  isDisabled?: boolean
  maxAmount?: string | null
  menuType?: MenuType | null
  minAmount?: string | null
  numberOfTimesPerUser?: number | null
  openingHours?: OpeningHoursPeriodInput[] | null
  requiresGuestGroupIds?: string[] | null
  requiresRestaurantIds?: string[] | null
  title?: string
  validFrom?: string | null
  validUntil?: string | null
}

const discountCreateSchema: ObjectSchema<FormInput> = yup.object({
  code: yup.string().trim().min(1).transform(emptyStringToUndefinedTransform).nullable(),
  description: yup.string().trim().max(200),
  discountAmount: yup.string()
    .when('discountPercentage', {
      is: (value: number | null | undefined) => value != null,
      then: schema => schema.transform(emptyStringToUndefinedTransform).oneOf([undefined], 'Både procentsats och belopp stödjs inte'),
      otherwise: schema => schema.transform(emptyStringToUndefinedTransform).required('Välj antingen en procentsats eller ett belopp')
    }).trim().matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').nullable(),
  discountLimit: yup.string().transform(emptyStringToUndefinedTransform).trim().matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').nullable(),
  discountPercentage: yup.string().transform(emptyStringToUndefinedTransform).trim().matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').test(
    'mustBeLessThanOrEqualTo100',
    'Måste vara mindre än eller lika med 100',
    function (value) {
      if (value == null) return true

      try {
        const parsedValue = parseFloat(value)
        return parsedValue <= 100
      } catch (e) {
        return false
      }
    }
  ).nullable(),
  isDisabled: yup.boolean(),
  maxAmount: yup.string().transform(emptyStringToUndefinedTransform).trim().matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').test(
    'mustBeBigger',
    'Måste vara större än minimalt belopp ',
    function (value) {
      if (value == null || this.parent.minAmount == null) return true

      try {
        const parsedValue = parsePrice(value)
        const parsedMinAmount = parsePrice(this.parent.minAmount)

        return parsedValue > parsedMinAmount
      } catch (e) {
        return false
      }
    }
  ).nullable(),
  menuType: yup.mixed<MenuType>().oneOf([MenuType.Delivery, MenuType.EatIn, MenuType.TakeAway]).nullable(),
  minAmount: yup.string().transform(emptyStringToUndefinedTransform).matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').nullable(),
  numberOfTimesPerUser: yup.number().integer().transform(emptyStringToUndefinedTransform).min(1),
  openingHours: openingHoursPeriodsSchema.nullable(),
  requiresGuestGroupIds: yup.array().of(yup.string().required()).nullable(),
  requiresRestaurantIds: yup.array().of(yup.string().required()).nullable(),
  title: yup.string().trim().max(100),
  validFrom: discountValidFromSchema,
  validUntil: discountValidUntilSchema
})

interface DiscountCreateFormProps {
  error: ApolloError | null | undefined
  guestGroupOptions?: Array<{ id: string, name: string }>
  onCancel: () => void
  onSave: (input: DiscountInput) => void
  restaurantOptions?: Array<{ id: string, name: string }>
  saving?: boolean
}

const DiscountCreateForm: React.FC<DiscountCreateFormProps> = ({ error, guestGroupOptions, onCancel, onSave, restaurantOptions, saving }) => {
  const form = useForm<FormInput>({
    criteriaMode: 'all',
    mode: 'onTouched',
    resolver: yupResolver(discountCreateSchema)
  })

  const handleSave = ignoreAsync(form.handleSubmit((input: FormInput) => {
    const discountInput: DiscountInput = {
      ...input,
      discountAmount: input.discountAmount == null ? null : parsePrice(input.discountAmount),
      discountLimit: input.discountLimit == null ? null : parsePrice(input.discountLimit),
      discountPercentage: input.discountPercentage == null ? null : parseInt(input.discountPercentage),
      maxAmount: input.maxAmount == null ? null : parsePrice(input.maxAmount),
      minAmount: input.minAmount == null ? null : parsePrice(input.minAmount)
    }

    return onSave(discountInput)
  }))

  const { isDirty, isSubmitting } = form.formState

  return (
    <FormContainer gap={12} wide>
      <CheckBox
        form={form}
        name='isDisabled'
        title='Inaktiverad'
      />

      <TextField
        estimatedNumberOfCharacters={12}
        form={form}
        name='code'
        title='Kod'
      />

      <TextField
        estimatedNumberOfCharacters={24}
        form={form}
        name='title'
        title='Titel'
      />

      <TextField
        form={form}
        name='description'
        title='Beskrivning'
      />

      <Spacer height={16} />

      <TextField
        estimatedNumberOfCharacters={6}
        form={form}
        name='discountPercentage'
        suffix='%'
        title='Rabatt i procent'
      />

      <MoneyField
        estimatedNumberOfCharacters={6}
        form={form}
        name='discountAmount'
        title='Rabattbelopp'
      />

      <MoneyField
        estimatedNumberOfCharacters={6}
        form={form}
        name='discountLimit'
        title='Gräns för totalbelopp (kr)'
      />

      <MoneyField
        estimatedNumberOfCharacters={6}
        form={form}
        name='minAmount'
        title='Minimalt belopp'
      />

      <MoneyField
        estimatedNumberOfCharacters={6}
        form={form}
        name='maxAmount'
        title='Maximalt belopp'
      />

      <IntegerField
        estimatedNumberOfCharacters={6}
        form={form}
        name='numberOfTimesPerUser'
        title='Antal gånger per användare'
      />

      <Spacer height={16} />

      <DateTimeField
        clearable
        form={form}
        label='Giltig från och med'
        name='validFrom'
      />

      <DateTimeField
        clearable
        form={form}
        label='Giltig till och med'
        name='validUntil'
      />

      <Select
        form={form}
        name='menuType'
        options={menuTypeSelectOptions}
        title='Menytyp'
      />

      <OpeningHoursPeriodsField
        form={form}
        name='openingHours'
        title='Tillgänglighet'
      />

      {guestGroupOptions == null ? null : (
        <MultiSelectCheckbox
          form={form}
          name='requiresGuestGroupIds'
          options={guestGroupOptions?.map(guestGroup => ({ title: guestGroup.name ?? guestGroup.id, value: guestGroup.id })) ?? []}
          title='Lojalitetsgrupp'
        />
      )}

      {restaurantOptions == null ? null : (
        <MultiSelectCheckbox
          form={form}
          name='requiresRestaurantIds'
          options={restaurantOptions?.map(restaurant => ({ title: restaurant.name ?? restaurant.id, value: restaurant.id })) ?? []}
          title='Restauranger'
        />
      )}

      {error?.message == null ? null : <Warning message={error.message} paddingBottom={12} />}

      <SubmitFormButtons
        disableSaveButton={!isDirty}
        onCancel={onCancel}
        onSave={handleSave}
        saving={isSubmitting || saving}
      />
    </FormContainer>
  )
}

export default DiscountCreateForm

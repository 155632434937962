import React from 'react'

import { LINK_COLOR } from '../lib/color'

import type { LinkProps } from './Link'

const style: React.CSSProperties = {
  color: LINK_COLOR,
  overflowWrap: 'anywhere'
}

const Link: React.FC<LinkProps> = ({ url, title }) => {
  return (
    <a href={url} rel='noopener noreferrer' style={style} target='_blank'>
      {title}
    </a>
  )
}

export default Link

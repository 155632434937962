import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { Text, VStack } from 'react-stacked'

import { useSetStripeAccountIdMutation } from '../../types/graphql'
import Layout from '../components/Layout'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation SetStripeAccountId($authorizationCode: String!, $organizationId: ID!) {
    setStripeAccountId(
      authorizationCode: $authorizationCode
      organizationId: $organizationId
    ) {
      id

      setupProgress {
        hasCompletedStripeSetup
        hasStripeAccount
      }
    }
  }
`

const StripeSetupComplete: React.FC = () => {
  const [navigation, { code, state }] = useNavigation<'StripeSetupComplete'>()
  const { organizationId, restaurantId } = JSON.parse(state)
  const [setStripeAccountId, { loading }] = useSetStripeAccountIdMutation({ variables: { authorizationCode: code, organizationId } })

  useEffect(() => {
    const doSetStripeAccountId = async (): Promise<void> => {
      await setStripeAccountId()

      navigation.navigate('RestaurantStripeEdit', { restaurantId })
    }

    doSetStripeAccountId().catch(logError)
  }, [])

  return (
    <Layout>
      <VStack alignItems='center' justifyContent='center' maxWidth={700} padding={20}>
        {!loading
          ? <Text>Ingen pågående registreringsprocess för Stripe hittades</Text>
          : (
            <>
              <Text>Vänligen avvakta några sekunder medan vi länkar ditt Stripekonto med Loco</Text>

              <Spacer height={8} />

              <ActivityIndicator />
            </>
          )}
      </VStack>
    </Layout>
  )
}

export default StripeSetupComplete

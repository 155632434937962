import unwrap from 'ts-unwrap'

import { type OpeningHoursPeriodInput } from '../../types/graphql'

import { periodEndingToWeekTime } from './getTime'

export function openingPeriodOverlap (periodA: OpeningHoursPeriodInput, periodB: OpeningHoursPeriodInput): boolean {
  const periodAOpenInWeekTime = unwrap(periodEndingToWeekTime({ day: periodA.open.day, time: periodA.open.time }))
  const periodACloseInWeekTime = periodEndingToWeekTime({ day: periodA?.close?.day ?? null, time: periodA?.close?.time ?? null })
  const periodBOpenInWeekTime = unwrap(periodEndingToWeekTime({ day: periodB.open.day, time: periodB.open.time }))
  const periodBCloseInWeekTime = periodEndingToWeekTime({ day: periodB?.close?.day ?? null, time: periodB?.close?.time ?? null })

  // Check overlap
  return (
    // Either period is open 24h and thus overlaps the other one
    (periodACloseInWeekTime == null || periodBCloseInWeekTime == null) ||
    // Period A opens during period B
    (periodAOpenInWeekTime >= periodBOpenInWeekTime && periodAOpenInWeekTime < periodBCloseInWeekTime) ||
    // Period B opens during period A
    (periodBOpenInWeekTime >= periodAOpenInWeekTime && periodACloseInWeekTime > periodBOpenInWeekTime)
  )
}

interface OpeningPeriodsError {
  message: string
  conflictingPeriods: OpeningHoursPeriodInput[]
}

export default function validateOpeningPeriods (bunchedOpeningPeriods: OpeningHoursPeriodInput[]): OpeningPeriodsError | null {
  for (const [exclude, periodA] of bunchedOpeningPeriods.entries()) {
    for (const periodB of bunchedOpeningPeriods.filter((_, index) => index !== exclude)) {
      if (openingPeriodOverlap(periodA, periodB)) {
        return ({
          message: 'Överlappande öppettider',
          conflictingPeriods: [periodA, periodB]
        })
      }
    }
  }
  return null
}

import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'

import { GetPageMenuDocument, useDeleteMenuCategoryMutation, useGetMenuCategoryEditDataQuery, useUpdateMenuCategoryMutation } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import MenuCategoryForm, { type MenuCategorySchema } from '../components/MenuCategoryForm'
import VerificationDialog from '../components/VerificationDialog'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

gql`
  query GetMenuCategoryEditData($restaurantId: ID!, $menuCategoryId: ID!) {
    restaurant(id: $restaurantId) {
      id

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        pages {
          id
          name
        }

        category(id: $menuCategoryId) {
          id
          name
          page {
            id
            name
          }
          products {
            id
          }
        }
      }
    }
  }

  mutation UpdateMenuCategory($restaurantId: ID!, $menuCategoryId: ID!, $menuPageId: ID!, $fromMenuPageId: ID!, $patch: MenuCategoryPatch!) {
    updateMenuCategory(
      restaurantId: $restaurantId
      menuCategoryId: $menuCategoryId
      patch: $patch
    ) { id }
    relocateMenuCategory(
      restaurantId: $restaurantId
      menuCategoryId: $menuCategoryId
      fromMenuPageId: $fromMenuPageId
      toMenuPageId: $menuPageId
    )
  }

  mutation DeleteMenuCategory($restaurantId: ID!, $menuCategoryId: ID!, $recursive: Boolean!) {
    deleteMenuCategory(
      restaurantId: $restaurantId
      menuCategoryId: $menuCategoryId
      recursive: $recursive
    )
  }
`

const MenuCategoryEdit: React.FC = () => {
  const [navigation, { restaurantId, menuCategoryId }] = useNavigation<'MenuCategoryEdit'>()
  const { data, loading } = useGetMenuCategoryEditDataQuery({ variables: { restaurantId, menuCategoryId } })
  const [deleteMenuCategory, { loading: loadingDeleteMenuCategory, error: deleteError }] = useDeleteMenuCategoryMutation()
  const [updateMenuCategory, { loading: loadingUpdateMenuCategory }] = useUpdateMenuCategoryMutation()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const menuCategoryName = data?.restaurant?.menu?.category?.name
  const menuPageId = data?.restaurant?.menu?.category?.page?.id
  const menuPageName = data?.restaurant?.menu?.category?.page?.name
  const pages = data?.restaurant?.menu?.pages ?? []
  const productCount = data?.restaurant?.menu?.category?.products?.length ?? 0

  const defaultValues: MenuCategorySchema = {
    menuPageId: menuPageId ?? '',
    name: data?.restaurant?.menu?.category?.name ?? ''
  }

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['MenuView', { restaurantId }], title: 'Meny' },
    { link: ['MenuPageView', { restaurantId, menuPageId }], title: menuPageName ?? '' }
  ], [menuPageName, menuPageId, pages, restaurantId])

  const redirect = (menuPageId: string): void => {
    navigation.navigate('MenuPageView', { restaurantId, menuPageId })
  }

  const handleCancel = (): void => {
    if (menuPageId != null) redirect(menuPageId)
  }

  const handleDelete = (): void => {
    setShowDeleteDialog(true)
  }

  const handleSave = ignoreAsync(async (values: MenuCategorySchema) => {
    if (menuPageId == null) return

    await updateMenuCategory({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: GetPageMenuDocument, variables: { restaurantId, menuPageId } },
        { query: GetPageMenuDocument, variables: { restaurantId, menuPageId: values.menuPageId } }
      ],
      variables: { restaurantId, menuCategoryId, menuPageId: values.menuPageId, fromMenuPageId: menuPageId, patch: { name: values.name } }
    })

    redirect(values.menuPageId)
  })

  const doDeleteMenuCategory = ignoreAsync(async () => {
    setShowDeleteDialog(false)

    await deleteMenuCategory({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetPageMenuDocument, variables: { restaurantId, menuPageId } }],
      variables: { restaurantId, menuCategoryId, recursive: productCount > 0 }
    })

    if (menuPageId != null) redirect(menuPageId)
  })

  if (loading) {
    return <Layout breadcrumbs={breadcrumbs} loading title='Redigera ...' />
  }

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title={`Redigera ${menuCategoryName ?? '...'}`}>
      <MenuCategoryForm
        defaultValues={defaultValues}
        menuPageOptions={pages.map(({ id, name }) => ({ value: id, title: name ?? '' }))}
        onCancel={handleCancel}
        onDelete={handleDelete}
        onSave={handleSave}
        saving={loadingDeleteMenuCategory || loadingUpdateMenuCategory}
      />

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={loadingDeleteMenuCategory}
        onDelete={doDeleteMenuCategory}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={productCount === 0
          ? `Vill du radera kategorin ${menuCategoryName ?? ''}?`
          : `Är du säker på att du vill ta bort ${menuCategoryName ?? ''} och dess produkter?\n\nVänligen notera att denna åtgärd kommer att permanent radera samtliga ${productCount} produkter som hör till denna underkategori.`}
        title='Radera kategori'
      />
    </Layout>
  )
}

export default MenuCategoryEdit

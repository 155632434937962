import MuTextField, { type TextFieldProps as MuTextFieldProps } from '@mui/material/TextField'
import React, { type ChangeEvent, useCallback } from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'
import { VStack } from 'react-stacked'

import ErrorField from './ErrorField'

interface UncontrolledDateFieldProps<TFieldValues extends FieldValues> {
  autoFocus?: boolean
  fontSize?: number
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  helperText?: string
  icon?: JSX.Element
  name: Path<TFieldValues>
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  placeholder?: string
  required?: boolean
  onSelect?: () => void
  type?: string
  title?: string
}

interface ControlledDateFieldProps {
  autoFocus?: boolean
  error?: boolean
  fontSize?: number
  helperText?: string
  icon?: JSX.Element
  id?: string
  onBlur?: MuTextFieldProps['onBlur']
  onChange: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  placeholder?: string
  required?: boolean
  onSelect?: () => void
  title?: string
  type?: string
  value: string
}

export default function DateField<T extends FieldValues> (props: UncontrolledDateFieldProps<T> | ControlledDateFieldProps): JSX.Element {
  if ('form' in props) {
    const error = get(props.form.formState.errors, props.name)
    return (
      <VStack>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onBlur, onChange, value } }) => (
            <DateField
              autoFocus={props.autoFocus}
              error={error != null}
              fontSize={props.fontSize}
              helperText={props.helperText}
              icon={props.icon}
              id={props.name}
              onBlur={onBlur}
              onChange={onChange}
              onKeyDown={props.onKeyDown}
              onSelect={props.onSelect}
              placeholder={props.placeholder}
              required={props.required}
              title={props.title}
              type={props.type}
              value={value}
            />
          )}
        />

        <ErrorField message={error?.message} />
      </VStack>
    )
  }

  const handleChange = useCallback((ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.onChange(ev.target.value)
  }, [props.onChange])

  return (
    <MuTextField
      autoFocus={props.autoFocus ?? false}
      error={props.error ?? false}
      helperText={props.helperText}
      id={props.id}
      InputProps={{
        endAdornment: props.icon ?? undefined
      }}
      inputProps={{ style: { fontSize: props.fontSize, paddingBottom: 10, flexGrow: 1, minHeight: 30, minWidth: 80 } }}
      label={props.title}
      onBlur={props.onBlur}
      onChange={handleChange}
      onClick={props.onSelect}
      onKeyDown={props.onKeyDown}
      placeholder={props.placeholder}
      required={props.required ?? false}
      style={{ flexGrow: 1, minWidth: 120 }}
      type='date'
      value={props.value}
    />
  )
}

import { Text } from 'react-stacked'

import { TEXT_COLOR } from '../../lib/color'

interface HeadingProps {
  children: React.ReactNode
  padding?: string | number
  size: 'l' | 'm'
}

const Heading: React.FC<HeadingProps> = ({ children, padding, size }) => {
  return <Text color={TEXT_COLOR} padding={padding} size={size === 'l' ? 24 : 18}>{children}</Text>
}

export default Heading

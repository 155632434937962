import gql from 'graphql-tag'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HStack, Text, VStack } from 'react-stacked'

import { MenuType, useGenerateRestaurantOrderSalesBreakdownMutation } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import serializeZonedDateTimeRange, { type ZonedDateTimeRange } from '../util/serializeZonedDateTimeRange'
import showAlert from '../util/showAlert'

import { PrimaryButton } from './Buttons'
import { TextField } from './TextField'
import Warning from './Warning'

gql`
  mutation GenerateRestaurantOrderSalesBreakdown($restaurantId: ID!, $input: RestaurantOrderSalesBreakdownInput!) {
    generateRestaurantOrderSalesBreakdown(
      restaurantId: $restaurantId,
      input: $input
    )
  }
`

interface RestaurantOrderSalesBreakdownFormInput {
  emailAddress: string
}

const schema: ObjectSchema<RestaurantOrderSalesBreakdownFormInput> = yup.object({
  emailAddress: yup.string().email('E-postadressen du angivit är inte giltig').required('Var vänlig och fyll i till vilken e-postadress du vill skicka statistiken')
})

interface DownloadSalesFormProps {
  dateTimeRange: ZonedDateTimeRange | null
  menuTypes?: readonly MenuType[] | null
  restaurantId: string
}

const DownloadSalesForm: React.FC<DownloadSalesFormProps> = ({ dateTimeRange, menuTypes, restaurantId }) => {
  const [sendToEmail, setSendToEmail] = useState<string | null>(null)

  const form = useForm<RestaurantOrderSalesBreakdownFormInput>({
    criteriaMode: 'all',
    resolver: yupResolver(schema)
  })

  const [generateRestaurantOrderSalesBreakdown, { error, loading: generating }] = useGenerateRestaurantOrderSalesBreakdownMutation()

  const handleOnPress = useCallback((input: RestaurantOrderSalesBreakdownFormInput) => {
    if (dateTimeRange == null) {
      showAlert('Datumintervall saknas', 'Du måste välja ett datumintervall för att kunna generera statistik')

      return
    }

    generateRestaurantOrderSalesBreakdown({
      onCompleted: () => setSendToEmail(input.emailAddress),
      variables: {
        restaurantId,
        input: {
          emailAddress: input.emailAddress,
          menuTypes: menuTypes != null ? [...menuTypes] : [MenuType.Delivery, MenuType.EatIn, MenuType.TakeAway],
          dateTimeRange: serializeZonedDateTimeRange(dateTimeRange)
        }
      }
    }).catch(logError)
  }, [dateTimeRange, menuTypes, restaurantId, generateRestaurantOrderSalesBreakdown])

  useEffect(() => {
    setSendToEmail(null)
  }, [dateTimeRange, menuTypes])

  return (
    <VStack gap={16}>
      <Text paddingHorizontal={10} size={16} weight='bold'>Generera CSV av försäljningsstatistik</Text>

      {error == null
        ? null
        : <Warning message={error.message} paddingBottom={16} />}

      {sendToEmail != null
        ? (
          <VStack alignItems='center' gap={16} justifyContent='center' paddingHorizontal={10}>
            <Text size={16}>Vi skickar statistiken till {sendToEmail} så snart den är klar.</Text>

            <Text size={16}>♻️ Tänk på att kolla skräpposten!</Text>
          </VStack>
        )
        : (
          <>
            <Text paddingHorizontal={10} size={14}>Ange till vilken e-postadress du vill skicka försäljningsstatistiken när den genererats klart, så skickar vi en länk dit när den är klar. Tänk på att kolla i din e-posts skräppost om den inte trillat in i din inbox inom några minuter.</Text>

            <HStack alignItems='end' gap={8} wrap>
              <TextField
                form={form}
                name='emailAddress'
                title='E-postadress'
              />

              <PrimaryButton
                disabled={generating}
                icon='file-csv'
                iconType='font-awesome-5'
                loading={generating}
                onPress={ignoreAsync(form.handleSubmit(handleOnPress))}
                title='Generera csv'
              />
            </HStack>
          </>
        )}
    </VStack>
  )
}

export default DownloadSalesForm

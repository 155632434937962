import { Temporal } from '@js-temporal/polyfill'
import { addHours, format, getWeek, parse } from 'date-fns'
import { sv } from 'date-fns/locale'
import React, { useMemo } from 'react'
import unimplemented from 'ts-unimplemented'
import unreachable from 'ts-unreachable'

import { MenuType, TimeResolution } from '../../types/graphql'
import { ACCENT_COLOR } from '../lib/color'
import formatCurrency from '../util/formatCurrency'
import { type ZonedDateTimeRange } from '../util/serializeZonedDateTimeRange'

import { titleFromMenuType } from './RestaurantMenu/MenuTypeToggleButton'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryStack, VictoryTooltip } from './Victory'

const AXIS_COLOR = 'hsl(150, 1%, 37%)'
const BLACK = '#333d47'
const GRAY = '#475059'
const WHITE = '#fbfefb'

const MENU_TYPES = [MenuType.EatIn, MenuType.TakeAway, MenuType.Delivery]

const sharedAxisStyles = {
  axis: {
    stroke: 'transparent'
  },
  tickLabels: {
    fill: AXIS_COLOR,
    fontSize: 9
  },
  axisLabel: {
    fill: AXIS_COLOR,
    fontSize: 15,
    fontStyle: 'italic',
    padding: 36
  }
}

const domainPadding = (tickValues: Date[]): number => {
  if (tickValues.length > 31) return 5
  if (tickValues.length > 24) return 10
  if (tickValues.length > 7) return 12
  return 25
}

interface TooltipInput {
  date: Date
  grossAmount: number
  menuType: MenuType
  resolution: TimeResolution
  total: number
}

function getTooltipText ({ date, grossAmount, menuType, resolution, total }: TooltipInput): string {
  if (grossAmount === 0) {
    return ''
  }

  const timePeriodText = (resolution === TimeResolution.Hour)
    ? `${format(date, 'HH:mm')}-${format(addHours(date, 1), 'HH:mm')}`
    : date.toLocaleDateString()

  return [
    timePeriodText,
    titleFromMenuType(menuType),
    formatCurrency(grossAmount),
    ' ',
    'Av totalt:',
    formatCurrency(total)
  ].join('\n')
}

function getDates (range: ZonedDateTimeRange, resolution: TimeResolution): Date[] {
  let start: Temporal.ZonedDateTime
  let step: Temporal.DurationLike

  switch (resolution) {
    case TimeResolution.Hour:
      start = range.start.with({ minute: 0, second: 0, millisecond: 0 })
      step = { hours: 1 }
      break
    case TimeResolution.Day:
      start = range.start.with({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      step = { days: 1 }
      break
    case TimeResolution.Week:
      start = range.start.subtract({ days: range.start.dayOfWeek - 1 }).with({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      step = { weeks: 1 }
      break
    default:
      unimplemented()
  }

  const result: Date[] = []
  for (let now = start; Temporal.ZonedDateTime.compare(now, range.end) < 0; now = now.add(step)) {
    result.push(new Date(now.epochMilliseconds))
  }

  return result
}

function getSalesPlaceholders (tickValues: Date[], types: MenuType[]): BarChartData {
  return tickValues.flatMap(tick => {
    return types.map(menuType => ({ _x: tick, _y: 0, label: '', menuType }))
  })
}

function getTickCount (resolution: TimeResolution, totalNumberOfTicks: number): number {
  switch (resolution) {
    case TimeResolution.Hour:
      return Math.min(totalNumberOfTicks, 25)
    case TimeResolution.Day:
      return Math.min(totalNumberOfTicks, 15)
    case TimeResolution.Week:
      return Math.min(totalNumberOfTicks, 26)
    default:
      unimplemented()
  }
}

function getXAxisTitle (resolution: TimeResolution): string {
  switch (resolution) {
    case TimeResolution.Hour:
      return 'Tid på dagen (timmar)'
    case TimeResolution.Day:
      return 'Veckodag (datum)'
    case TimeResolution.Week:
      return 'Vecka'
    default:
      unimplemented()
  }
}

function menuTypeBarColor (menuType: MenuType): string {
  switch (menuType) {
    case MenuType.Delivery:
      return ACCENT_COLOR
    case MenuType.EatIn:
      return '#11273c'
    case MenuType.TakeAway:
      return '#ef808a'
    case MenuType.GiftCard:
      return '#c55fa9'
    default:
      unreachable(menuType)
  }
}

function parseWhen (resolution: TimeResolution, period: string): Date {
  switch (resolution) {
    case TimeResolution.Hour:
      return new Date(`${period}:00:00.000`)
    case TimeResolution.Day:
      return new Date(`${period}T00:00:00.000`)
    case TimeResolution.Week:
      return parse(period, 'RRRR-II', new Date(0))
    default:
      unimplemented()
  }
}

type BarChartData = Array<{ _x: Date, _y: number, label: string, menuType: MenuType }>

interface DailySales {
  count?: number | null
  grossAmount?: number | null
  menuType?: MenuType | null
  periodStart?: string | null
  when?: string | null
}

interface DailySalesPlotProps {
  data?: readonly DailySales[] | null
  dateTimeRange: ZonedDateTimeRange
  resolution: TimeResolution
}

const DailySalesPlot: React.FC<DailySalesPlotProps> = ({ data, dateTimeRange, resolution }) => {
  const localDateRange = { start: new Date(dateTimeRange.start.epochMilliseconds), end: new Date(dateTimeRange.end.epochMilliseconds) }
  const tickValues = useMemo(() => getDates(dateTimeRange, resolution), [dateTimeRange, resolution])
  const types = useMemo(() => MENU_TYPES.filter(type => data?.some(entry => entry.menuType === type) ?? false), [data])
  const salesPlaceholders = useMemo(() => getSalesPlaceholders(tickValues, types), [tickValues, types])

  const sales = useMemo(() => {
    if (data == null) return salesPlaceholders

    return salesPlaceholders.map((placeholder) => {
      const sale = data.find(datum => {
        const time = datum.periodStart != null ? new Date(datum.periodStart) : datum.when != null ? parseWhen(resolution, datum.when) : null

        return (
          time != null &&
          placeholder._x.getTime() === time.getTime() &&
          placeholder.menuType === datum.menuType
        )
      })

      return { ...placeholder, _y: sale?.grossAmount ?? 0 }
    })
  }, [data, salesPlaceholders])

  const totalYPerXInterval = useMemo(() => {
    return tickValues.map(_x => ({ _x, total: sales.filter(sale => _x === sale._x).reduce((mem, sale) => sale._y + mem, 0) }))
  }, [sales])

  const barChartData: BarChartData = useMemo(() =>
    sales.map((sale, index) => ({
      ...sale,
      label: getTooltipText({
        date: index < types.length ? localDateRange.start : sale._x,
        grossAmount: sale?._y ?? 0,
        menuType: sale.menuType,
        resolution,
        total: totalYPerXInterval.find(entry => entry._x === sale._x)?.total ?? 0
      })
    })), [localDateRange.start, resolution, sales, totalYPerXInterval, types])

  return (
    <VictoryChart
      containerComponent={<VictoryContainer style={{ touchAction: 'auto' }} />}
      domainPadding={{ x: domainPadding(tickValues) }}
      padding={{ bottom: 60, left: 60, right: 0, top: 0 }}
      style={{ parent: { userSelect: 'none' } }}
    >
      <VictoryAxis
        dependentAxis
        label='Bruttoförsäljning (tkr)'
        style={{
          ...sharedAxisStyles,
          grid: {
            fill: AXIS_COLOR,
            stroke: AXIS_COLOR,
            pointerEvents: 'painted',
            strokeWidth: 0.5
          }
        }}
        tickFormat={(tick) => tick / 100000}
      />
      <VictoryAxis
        label={getXAxisTitle(resolution)}
        scale='time'
        style={{
          ...sharedAxisStyles,
          axisLabel: { ...sharedAxisStyles.axisLabel, padding: 35 }
        }}
        tickCount={getTickCount(resolution, tickValues.length)}
        tickFormat={(tick: Date, index: number, ticks: Date[]) => {
          if (resolution === TimeResolution.Hour) {
            if (ticks?.[index - 1]?.getHours() === tick.getHours()) return `${tick.getHours().toString()}\n${tick.getMonth() > 6 ? 'Vintertid' : 'Sommartid'}`
            return tick.getHours()
          }
          if (resolution === TimeResolution.Week) return getWeek(tick) - 1
          if (ticks?.[index - 1]?.getMonth() !== tick.getMonth()) return format(tick, "d'\n'MMM", { locale: sv })
          if (resolution === TimeResolution.Day) return tick.getDate()

          return format(tick, 'dd MMM', { locale: sv })
        }}
        tickValues={tickValues}
      />

      <VictoryStack>
        {types.map(type => (
          <VictoryBar
            key={type}
            alignment={resolution === TimeResolution.Hour ? 'start' : 'middle'}
            animate={{ duration: 100 }}
            barRatio={1}
            colorScale={[menuTypeBarColor(type)]}
            cornerRadius={2}
            data={barChartData?.filter(entry => entry.menuType === type)}
            domain={{ x: [localDateRange.start, localDateRange.end] }}
            events={[{
              target: 'data',
              eventHandlers: {
                onMouseOver: () => [
                  {
                    target: 'data',
                    mutation: () => ({ style: { fill: '#f4c045' } })
                  },
                  {
                    target: 'labels',
                    mutation: () => ({ active: true })
                  }
                ],
                onMouseOut: () => [
                  {
                    target: 'data',
                    mutation: () => {}
                  },
                  {
                    target: 'labels',
                    mutation: () => ({ active: false })
                  }
                ]
              }
            }]}
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                cornerRadius={0}
                dx={resolution === TimeResolution.Hour ? 7.5 : 0}
                flyoutStyle={{
                  fill: GRAY,
                  stroke: BLACK,
                  strokeWidth: 0.5
                }}
                style={{
                  fill: WHITE,
                  fontSize: 11
                }}
              />
            }
          />
        ))}
      </VictoryStack>
    </VictoryChart>
  )
}

export default DailySalesPlot

import React from 'react'
import { useForm } from 'react-hook-form'

import { type EmployeeGroupFieldsFragment, type EmployeeInput } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'
import MultiSelectCheckbox from './fields/MultiSelectCheckbox'
import TextField from './fields/TextField'

interface EmployeeFormProps {
  deleting?: boolean
  employeeGroups: EmployeeGroupFieldsFragment[] | null | undefined
  initialValues?: { groupIds: string[], name: string }
  onDelete?: () => void
  onDismiss: () => void
  onSave: (input: EmployeeInput) => void
  saving: boolean
}

const schema: ObjectSchema<{ groupIds?: string[] | null, name: string }> = yup.object({
  groupIds: yup.array().of(yup.string().required()).optional(),
  name: yup.string().trim().min(1, 'Namn med minst 1 tecken måste anges').required()
})

const EmployeeForm: React.FC<EmployeeFormProps> = ({ deleting, employeeGroups, initialValues, onDelete, onDismiss, onSave, saving }) => {
  const form = useForm<EmployeeInput>({
    criteriaMode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  })

  return (
    <FormContainer gap={24}>
      <TextField
        form={form}
        name='name'
        title='Namn'
      />

      <MultiSelectCheckbox
        form={form}
        name='groupIds'
        options={employeeGroups?.map(group => ({ title: group.name ?? group.id, value: group.id })) ?? []}
        title='Grupp'
      />

      <SubmitFormButtons
        deleting={deleting ?? false}
        disableDeleteButton={saving || (deleting ?? false)}
        disableSaveButton={saving || (deleting ?? false)}
        onCancel={onDismiss}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSave))}
        saving={saving ?? false}
      />
    </FormContainer>
  )
}

export default EmployeeForm

import gql from 'graphql-tag'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HStack, Text, VStack } from 'react-stacked'

import { GetRestaurantPrintersViewDataDocument, type PhysicalPrinterPatch, useDeletePhysicalPrinterMutation, useGetRestaurantPhysicalPrinterEditDataQuery, useUpdatePhysicalPrinterMutation } from '../../types/graphql'
import CopyIcon from '../components/CopyIcon'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import VerificationDialog from '../components/VerificationDialog'
import { MultiSelectCheckbox, TextField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantPhysicalPrinterEditData($restaurantId: ID!, $physicalPrinterId: ID!) {
    restaurant(id: $restaurantId) {
      id

      physicalPrinter(id: $physicalPrinterId) {
        id

        cloudPrntServerUrl
        name

        printerQueues {
          id

          name
        }
      }

      printerQueues {
        id

        name
      }
    }
  }

  mutation DeletePhysicalPrinter($restaurantId: ID!, $physicalPrinterId: ID!) {
    deletePhysicalPrinter(restaurantId: $restaurantId, physicalPrinterId: $physicalPrinterId) {
      id

      physicalPrinters {
        id

        name
      }
    }
  }

  mutation UpdatePhysicalPrinter($restaurantId: ID!, $physicalPrinterId: ID!, $patch: PhysicalPrinterPatch!) {
    updatePhysicalPrinter(restaurantId: $restaurantId, physicalPrinterId: $physicalPrinterId, patch: $patch) {
      id

      cloudPrntServerUrl
      name

      printerQueues {
        id

        name
      }
    }
  }
`

const schema: ObjectSchema<PhysicalPrinterPatch> = yup.object({
  name: yup.string().trim().min(1).max(256).required(),
  printerQueueIds: yup.array().of(yup.string().required()).optional()
})

const RestaurantPhysicalPrinterEdit: React.FC = () => {
  const [navigation, { restaurantId, physicalPrinterId }] = useNavigation<'RestaurantPhysicalPrinterEdit'>()

  const { data, loading } = useGetRestaurantPhysicalPrinterEditDataQuery({ variables: { restaurantId, physicalPrinterId } })

  const [showDeleteVerification, setShowDeleteVerification] = useState(false)

  const form = useForm<PhysicalPrinterPatch>({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    values: schema.cast({
      name: data?.restaurant?.physicalPrinter?.name ?? '',
      printerQueueIds: data?.restaurant?.physicalPrinter?.printerQueues?.map(printerQueue => printerQueue.id)
    }, { stripUnknown: true })
  })

  const handleCancel = useCallback(() => {
    setShowDeleteVerification(false)

    navigation.canGoBack() ? navigation.goBack() : navigation.replace('RestaurantPrintersView', { restaurantId })
  }, [navigation])

  const [deletePhysicalPrinter, { error: deleteError, loading: deleting }] = useDeletePhysicalPrinterMutation({
    onCompleted: handleCancel
  })

  const [updatePhysicalPrinter, { error, loading: submitting }] = useUpdatePhysicalPrinterMutation({
    onCompleted: handleCancel,
    refetchQueries: [{ query: GetRestaurantPrintersViewDataDocument, variables: { restaurantId } }]
  })

  const handleDelete = (): void => {
    deletePhysicalPrinter({ variables: { restaurantId, physicalPrinterId } }).catch(logError)
  }

  const handleSubmit = (patch: PhysicalPrinterPatch): void => {
    updatePhysicalPrinter({ variables: { restaurantId, physicalPrinterId, patch } }).catch(logError)
  }

  if (loading || submitting) {
    return <Layout loading />
  }

  return (
    <>
      {!showDeleteVerification ? null : (
        <VerificationDialog
          callToActionLabel='Bekräfta radera skrivare'
          errorMessage={deleteError?.message}
          loading={deleting}
          onDelete={handleDelete}
          onDismiss={() => setShowDeleteVerification(false)}
          open
          prompt='Är du säker på att du vill radera skrivaren? Detta går inte att ångra.'
          title='Är du säker?'
        />
      )}

      <Layout
        breadcrumbs={[{ link: ['RestaurantPrintersView', { restaurantId }], title: 'Skrivare' }]}
        hideTitle
        screenType={ScreenType.Form}
        title={data?.restaurant?.physicalPrinter?.name ?? 'Ändra'}
      >
        <FormContainer gap={16} title={`Ändra ${data?.restaurant?.physicalPrinter?.name ?? 'Skrivare'}`}>
          <HStack justifyContent='space-between'>
            <VStack>
              <Text weight='bold'>CloudPRNT server url:</Text>
              <Text>{data?.restaurant?.physicalPrinter?.cloudPrntServerUrl}</Text>
            </VStack>
            <CopyIcon textToCopy={data?.restaurant?.physicalPrinter?.cloudPrntServerUrl ?? ''} />
          </HStack>

          <TextField
            form={form}
            name='name'
            title='Namn'
          />

          <MultiSelectCheckbox
            form={form}
            name='printerQueueIds'
            options={data?.restaurant?.printerQueues?.map(({ id, name }) => ({ value: id, title: name ?? '' })) ?? []}
            title='Köer att skriva ut ifrån'
          />

          <SubmitFormButtons
            deleting={deleting}
            disableCancelButton={loading}
            disableDeleteButton={deleting}
            disableSaveButton={!form.formState.isDirty || loading}
            error={error}
            onCancel={handleCancel}
            onDelete={() => setShowDeleteVerification(true)}
            onSave={ignoreAsync(form.handleSubmit(handleSubmit))}
            saving={loading}
            titleForDelete='Radera'
          />
        </FormContainer>
      </Layout>
    </>
  )
}

export default RestaurantPhysicalPrinterEdit

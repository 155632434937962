import { type Temporal } from '@js-temporal/polyfill'
import unreachable from 'ts-unreachable'

import type TimePeriod from './TimePeriod'

export interface PlainDateRange {
  end: Temporal.PlainDate
  start: Temporal.PlainDate
}

function startOfWeek (date: Temporal.PlainDate): Temporal.PlainDate {
  return date.subtract({ days: date.dayOfWeek - 1 })
}

export default function dateRangeFromTimePeriod (timePeriod: TimePeriod, now: Temporal.Instant, timeZone?: string): PlainDateRange {
  const today = now.toZonedDateTimeISO(timeZone ?? 'Europe/Stockholm').toPlainDate()

  switch (timePeriod.kind) {
    case 'Custom':
      return { start: timePeriod.start, end: timePeriod.end }
    case 'Today':
      return { start: today, end: today }
    case 'Yesterday': {
      const start = today.subtract({ days: 1 })
      return { start, end: start }
    }
    case 'ThisWeek': {
      const start = startOfWeek(today)
      return { start, end: start.add({ days: 6 }) }
    }
    case 'LastWeek': {
      const start = startOfWeek(today).subtract({ weeks: 1 })
      return { start, end: start.add({ days: 6 }) }
    }
    case 'ThisMonth': {
      const start = today.with({ day: 1 })
      return { start, end: start.with({ day: start.daysInMonth }) }
    }
    case 'LastMonth': {
      const start = today.with({ day: 1 }).subtract({ months: 1 })
      return { start, end: start.with({ day: start.daysInMonth }) }
    }
    case 'ThisYear': {
      const start = today.with({ month: 1, day: 1 })
      return { start, end: today }
    }
    case 'LastYear': {
      const start = today.with({ year: today.year - 1, month: 1, day: 1 })
      return { start, end: start.add({ days: start.daysInYear - 1 }) }
    }
    default:
      return unreachable(timePeriod)
  }
}

interface FormatCurrencyOptions {
  hideCurrency?: boolean
  maximumFractionDigits?: 0
  minimumFractionDigits?: 2
}

export default function formatCurrency (value: number, options?: FormatCurrencyOptions): string {
  if (!Number.isInteger(value)) {
    throw new Error(`Invalid currency amount: ${String(value)}`)
  }

  const sign = value < 0 ? '-' : ''
  const unSignedValue = Math.abs(value)

  const cents = unSignedValue % 100
  const digits = Math.floor(unSignedValue / 100).toFixed(0)

  // Add first group of 0, 1, or 2 digits
  let index = digits.length % 3
  let result = digits.slice(0, digits.length % 3)

  // Add rest groups of 3 digits
  while (index < digits.length) {
    result += ` ${digits.slice(index, index + 3)}`
    index += 3
  }

  // Handle cents
  if (options?.maximumFractionDigits !== 0) {
    if (cents > 0) {
      result += `,${cents.toFixed(0).padStart(2, '0')}`
    } else if (options?.minimumFractionDigits === 2) {
      result += ',00'
    }
  }

  return sign + result.trim() + ((options?.hideCurrency ?? false) ? '' : ' kr')
}

import React from 'react'
import { useForm } from 'react-hook-form'

import Select from '../components/Select'
import { TextField } from '../components/TextField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'

export interface MenuCategorySchema {
  readonly menuPageId: string
  readonly name: string
}

const menuCategorySchema: ObjectSchema<MenuCategorySchema> = yup.object({
  menuPageId: yup.string().trim().min(1).required(),
  name: yup.string().trim().min(1, 'Kategorinamn med minst ett tecken krävs').max(256, 'Kategorinamn med max 256 tecken tillåts').required()
})

interface MenuCategoryFormProps {
  defaultValues: MenuCategorySchema
  deleting?: boolean
  disableDeleteButton?: boolean
  menuPageOptions: Array<{ title: string, value: string }>
  onCancel: () => void
  onDelete?: () => void
  onSave: (values: MenuCategorySchema) => void
  saving: boolean
}

const MenuCategoryForm: React.FC<MenuCategoryFormProps> = ({ defaultValues, deleting, disableDeleteButton, menuPageOptions, onCancel, onDelete, onSave, saving }) => {
  const form = useForm({
    criteriaMode: 'all',
    defaultValues,
    resolver: yupResolver(menuCategorySchema)
  })

  return (
    <FormContainer gap={24}>
      <TextField
        autoFocus
        form={form}
        hint='Exempel på kategori skulle kunna vara Varmrätt eller Kalla drycker'
        name='name'
        title='Namn på kategori'
      />

      <Select
        form={form}
        name='menuPageId'
        options={menuPageOptions}
        title='Huvudkategori'
      />

      <SubmitFormButtons
        deleting={deleting}
        disableDeleteButton={saving || disableDeleteButton}
        disableSaveButton={form.formState.isSubmitting}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSave))}
        saving={saving}
      />
    </FormContainer>
  )
}

export default MenuCategoryForm

import { MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import React from 'react'
import { Pressable } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type FullPosTerminalFragment } from '../../types/graphql'
import formatDateTime from '../util/formatDateTime'

import Badge from './Badge'
import ListItemWrapper from './ListItemWrapper'

interface RestaurantPosTerminalListItemProps {
  onEditPress: () => void
  item: FullPosTerminalFragment
}

const RestaurantPosTerminalListItem: React.FC<RestaurantPosTerminalListItemProps> = ({ onEditPress, item }) => {
  const isActive = item?.isActive ?? true
  const isOnline = item?.isOnline ?? false

  return (
    <Pressable onPress={onEditPress} style={{ opacity: isActive ? 1 : 0.5 }}>
      <ListItemWrapper>
        <HStack alignItems='center' grow={1} justifyContent='space-between'>
          <HStack alignItems='center' paddingHorizontal={12}>
            <VStack gap={4}>
              {isActive ? (
                <HStack alignItems='center'>
                  <Badge status={isOnline ? 'success' : 'error'} />

                  <Spacer width={4} />

                  <Text size={12}>{isOnline ? 'Ansluten' : 'Ej ansluten'}</Text>
                </HStack>
              ) : (
                <HStack alignItems='center' gap={4}>
                  <MaterialIcons color='red' name='warning' size={16} />

                  <Text size={12}>Terminal inaktiverad</Text>
                </HStack>
              )}

              <VStack>
                <Text size={12}>Senast sedd:</Text>

                <Text size={12}>{item?.lastOnlineAt == null ? 'Aldrig' : formatDateTime(Temporal.Instant.from(item.lastOnlineAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}</Text>
              </VStack>
            </VStack>

            <Spacer width={12} />

            <VStack>
              <Text size={16}>{item.name ?? item.externalId}</Text>
              <Text size={12}>Terminalid: {item.externalId}</Text>
            </VStack>
          </HStack>

          <VStack>
            <Text>Terminaltyp: {item.paymentProvider}</Text>
          </VStack>
        </HStack>

        <Spacer width={16} />

        <MaterialIcons name='edit' size={24} />
      </ListItemWrapper>
    </Pressable>
  )
}

export default RestaurantPosTerminalListItem

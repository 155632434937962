import React, { type ReactNode } from 'react'
import { VStack } from 'react-stacked'

interface WidgetContainerProps {
  children: ReactNode
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({ children }) => {
  return (
    <VStack grow={1} maxWidth={700} paddingLeft={16} paddingTop={16}>
      <VStack backgroundColor='#fefefe' borderRadius={5} padding={16}>
        {children}
      </VStack>
    </VStack>
  )
}

export default WidgetContainer

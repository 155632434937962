import { FontAwesome5 } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import React from 'react'
import { Pressable, type PressableProps } from 'react-native'
import { HStack, Text, VStack, ZStack } from 'react-stacked'

import { type OrderStatus, type ReceiptListOrderFragment } from '../../types/graphql'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'

export const orderStatusStyle = (orderStatus: OrderStatus | null | undefined): { color: string, localized: string } => {
  switch (orderStatus) {
    case 'Delivered':
      return { color: '#393', localized: 'Klar' }
    case 'Failed':
      return { color: '#F00', localized: 'Misslyckad' }
    case 'Paid':
      return { color: '#393', localized: 'Betalad' }
    case 'Pending':
      return { color: '#F90', localized: 'Obekräftad' }
    case 'RefundFailed':
      return { color: '#36F', localized: 'Återbetalning misslyckad' }
    case 'RefundPending':
      return { color: '#36F', localized: 'Återbetalas' }
    case 'Refunded':
      return { color: '#36F', localized: 'Återbetald' }
    default:
      return { color: '#F00', localized: 'Okänd' }
  }
}

interface ReceiptListItemProps {
  order: ReceiptListOrderFragment
  onPress: PressableProps['onPress']
}

const ReceiptListItem: React.FC<ReceiptListItemProps> = ({ order, onPress }) => {
  const date = order.createdAt == null ? null : Temporal.Instant.from(order.createdAt)
  const dateLabel = date == null ? null : formatDateTime(date.toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'full', timeStyle: 'short' })
  const humanOrderNumber = order.receipt?.humanOrderNumber
  const name = order.restaurant?.name
  const price = order.grossAmount == null ? null : formatCurrency(order.grossAmount)

  const { color: statusColor, localized: statusLocalized } = orderStatusStyle(order.status)

  return (
    <Pressable onPress={onPress}>
      <HStack padding={8}>
        <ZStack align='center' height={60} width={44}>
          <FontAwesome5 color='#333' name='receipt' size={60} />
          <Text align='center' allowFontScaling={false} backgroundColor='#333' color='white' size={16} weight='500' width={44}>{humanOrderNumber}</Text>
        </ZStack>

        <VStack grow={1} justifyContent='center' paddingLeft={12} shrink={1}>
          <Text color='black' paddingBottom={2} size={18} weight='500'>{name}</Text>
          <Text color='black' paddingBottom={6} size={16}>{dateLabel}</Text>
        </VStack>

        <VStack justifyContent='center'>
          <Text align='right' color='black' paddingBottom={2} size={18}>{price}</Text>
          <Text align='right' color={statusColor} paddingBottom={6} size={16}>{statusLocalized}</Text>
        </VStack>
      </HStack>
    </Pressable>
  )
}

export default ReceiptListItem

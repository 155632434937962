import gql from 'graphql-tag'
import React, { useMemo } from 'react'

import { GetMenuAlternativeGroupListDataDocument, type MenuAlternativeGroupInput, useCreateAlternativeGroupMutation } from '../../types/graphql'
import AlternativeGroupForm from '../components/AlternativeGroupForm'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateAlternativeGroup($input: MenuAlternativeGroupInput!, $restaurantId: ID!) {
    createMenuAlternativeGroup(
      input: $input
      restaurantId: $restaurantId
    ) {
      id
    }
  }
`

const MenuAlternativeGroupCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'MenuAlternativeGroupCreate'>()

  const [createAlternativeGroup, { loading: creating }] = useCreateAlternativeGroupMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('MenuAlternativeGroupList', { restaurantId }),
    onError: (error) => logAndShowError(error),
    refetchQueries: [{ query: GetMenuAlternativeGroupListDataDocument, variables: { restaurantId } }]
  })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['MenuAlternativeGroupList', { restaurantId }], title: 'Tillägg och Alternativgrupper' }
  ], [restaurantId])

  const handleDismiss = (): void => {
    navigation.navigate('MenuAlternativeGroupList', { restaurantId })
  }

  const handleSave = ignoreAsync(async (input: MenuAlternativeGroupInput) => {
    await createAlternativeGroup({ variables: { input, restaurantId } })
  })

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title='Skapa'>
      <AlternativeGroupForm
        onDismiss={handleDismiss}
        onSave={handleSave}
        restaurantId={restaurantId}
        saving={creating}
      />
    </Layout>
  )
}

export default MenuAlternativeGroupCreate

import { type StackProps, Text, VStack } from 'react-stacked'

import { ERROR_COLOR, SECONDARY_TEXT_COLOR } from '../lib/color'

export const containerErrorStyle: StackProps = {
  borderLeftColor: 'red',
  borderLeftWidth: 5,
  borderStyle: 'solid',
  borderWidth: 0,
  paddingLeft: 15
}

interface FormControlProps {
  children: React.ReactNode
  errorMessage?: string
  hint?: string
  largeTitle?: boolean
  title?: string
}

/**
 * Wraps a form input with a title, hint and error message.
 *
 * Follows the design from the GOV.UK Design System:
 * https://design-system.service.gov.uk/components/text-input/
 */
const FormControl: React.FC<FormControlProps> = ({ children, errorMessage, hint, largeTitle = false, title }) => {
  return (
    <VStack {...(errorMessage == null ? {} : containerErrorStyle)}>
      {title == null
        ? null
        : <Text paddingBottom={largeTitle ? 15 : 5} size={16 * (largeTitle ? 1.5 : 1)} weight={largeTitle ? 'bold' : undefined}>{title}</Text>}

      {hint == null
        ? null
        : <Text color={SECONDARY_TEXT_COLOR} paddingBottom={largeTitle ? 15 : 10} size={14}>{hint}</Text>}

      {errorMessage == null
        ? null
        : <Text color={ERROR_COLOR} paddingBottom={largeTitle ? 15 : 10} weight='bold'>{errorMessage}</Text>}

      {children}
    </VStack>
  )
}

export default FormControl

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, FlatList } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type FullAlternativeGroupFragment, type MenuProductPropertiesFieldsFragment } from '../../../types/graphql'
import { type ChangeFn } from '../../util/useMultiEditProducts'

import ListItem from './ListItem'
import { Content } from './MultiEditForm'
import TriStateCheckBox, { type TriState, nextState } from './TriStateCheckBox'

interface EditAlternativeGroupsProps {
  alternativeGroups?: readonly FullAlternativeGroupFragment[] | null
  onDismiss: () => void
  onNext: (changeFn: ChangeFn) => void
  productProperties: readonly MenuProductPropertiesFieldsFragment[]
}

const EditAlternativeGroups: React.FC<EditAlternativeGroupsProps> = ({ alternativeGroups, onDismiss, onNext, productProperties }) => {
  const [selections, setSelections] = useState<Record<string, TriState>>({})

  const initial = useMemo(() => {
    if (alternativeGroups == null) return null

    const description: Record<string, string | null> = {}
    const selections: Record<string, TriState> = {}

    for (const { id } of alternativeGroups) {
      const count = productProperties.reduce((sum, properties) => sum + ((properties.alternativeGroups?.some(group => group.id === id) ?? false) ? 1 : 0), 0)
      const selection = count === 0 ? 'unchecked' : count === productProperties.length ? 'checked' : 'indeterminate'

      selections[id] = selection
      description[id] = selection === 'indeterminate' ? `${count} av ${productProperties.length} produkter använder detta alternativ` : null
    }

    return { description, selections }
  }, [alternativeGroups, productProperties])

  const disableNext = useMemo(() => initial == null || Object.keys(selections).every(id => selections[id] === initial?.selections?.[id]), [initial, selections])

  useEffect(() => {
    if (initial != null) setSelections(initial.selections)
  }, [initial])

  const handleOnNext = useCallback(() =>
    onNext(properties => {
      const result = unwrap(alternativeGroups).filter(p => {
        if (selections[p.id] === 'checked') return true
        if (selections[p.id] === 'unchecked') return false

        return properties?.alternativeGroups?.some(other => p.id === other.id) ?? false
      })

      return {
        from: (properties?.alternativeGroups?.length ?? 0) === 0 ? 'Inga alternativ' : unwrap(alternativeGroups).filter(p => properties?.alternativeGroups?.find(o => o.id === p.id)).map(p => unwrap(p.name)).join(', '),
        to: result.length === 0 ? 'Inga alternativ' : result.map(p => unwrap(p.name)).join(', '),
        patch: { alternativeGroupIds: result.map(p => p.id) }
      }
    }), [onNext, alternativeGroups, selections])

  const handleOnPress = useCallback((alternativeGroupId: string) => () => {
    setSelections(value => ({ ...value, [alternativeGroupId]: nextState(value[alternativeGroupId]) }))
  }, [])

  return (
    <Content
      disableNext={disableNext}
      onDismiss={onDismiss}
      onNext={handleOnNext}
    >
      {initial == null
        ? <ActivityIndicator />
        : (
          <FlatList
            data={alternativeGroups}
            ItemSeparatorComponent={() => <Spacer height={8} />}
            keyExtractor={item => item.id}
            renderItem={({ item }) => (
              <ListItem onPress={handleOnPress(item.id)}>
                <HStack alignItems='center' height={48} paddingHorizontal={8}>
                  <VStack>
                    <Text size={16}>{item.name}</Text>
                    <Text color='#333' size={12}>{initial.description[item.id]}</Text>
                  </VStack>

                  <Spacer grow={1} width={12} />

                  <TriStateCheckBox onPress={handleOnPress(item.id)} state={selections[item.id]} />
                </HStack>
              </ListItem>
            )}
          />
        )}
    </Content>
  )
}

export default EditAlternativeGroups

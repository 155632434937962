import gql from 'graphql-tag'
import React, { useCallback, useMemo } from 'react'
import { VStack } from 'react-stacked'

import { GetAddonProductsDocument, type MenuAddonProductInput, useCreateMenuAddonProductMutation, useGetCreateAddonProductDataQuery } from '../../types/graphql'
import AddonProductForm from '../components/AddonProductList/AddonProductForm'
import Layout, { type Breadcrumb } from '../components/Layout'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetCreateAddonProductData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        addonProducts {
          id

          name

          properties {
            id

            description
            imageUrl
            isActive
            price
            stateId

            reportGroup {
              ...FullMenuReportGroup
            }
          }
        }

        reportGroups {
          ...FullMenuReportGroup
        }
      }
    }
  }

  mutation CreateMenuAddonProduct($restaurantId: ID!, $input: MenuAddonProductInput!) {
    createMenuAddonProduct(
      restaurantId: $restaurantId
      input: $input
    ) { id }
  }
`

const MenuAddonProductCreate: React.FC = () => {
  const [navigation, { restaurantId, isDeliveryCost }] = useNavigation<'MenuAddonProductCreate'>()

  const { data, loading } = useGetCreateAddonProductDataQuery({ variables: { restaurantId } })

  const navigateToList = useCallback(() => navigation.navigate(isDeliveryCost === true ? 'DeliveryLocoEdit' : 'MenuAlternativeGroupList', { restaurantId }), [isDeliveryCost])

  const [createAddonProduct, { error, loading: isSubmitting }] = useCreateMenuAddonProductMutation({
    awaitRefetchQueries: true,
    onCompleted: navigateToList,
    refetchQueries: [{ query: GetAddonProductsDocument, variables: { restaurantId } }]
  })

  const { breadcrumbs, title } = useMemo<{ breadcrumbs: Breadcrumb[], title: string }>(() => {
    if (isDeliveryCost !== true) {
      return {
        breadcrumbs: [
          { link: ['MenuAlternativeGroupList', { restaurantId }], title: 'Tillägg och Alternativgrupper' }
        ],
        title: 'Skapa ny tilläggsprodukt'
      }
    }
    return {
      breadcrumbs: [
        { link: ['DeliveryLocoEdit', { restaurantId }], title: 'Leverans' }
      ],
      title: 'Skapa ny leveranskostnad'
    }
  }, [isDeliveryCost, restaurantId])

  const handleSave = useCallback((input: MenuAddonProductInput): void => {
    createAddonProduct({ variables: { restaurantId, input: { ...input, isDeliveryCost } } }).catch(logError)
  }, [isDeliveryCost])

  if (loading) {
    return <Layout breadcrumbs={breadcrumbs} loading title={title} />
  }

  return (
    <Layout breadcrumbs={breadcrumbs} title={title}>
      <VStack gap={24} maxWidth={1024} padding={20}>
        {data?.restaurant?.menu == null ? null : (
          <AddonProductForm
            errorMessage={error?.message}
            isSubmitting={isSubmitting}
            onDismiss={navigateToList}
            onSave={handleSave}
            reportGroups={data?.restaurant?.menu?.reportGroups}
          />
        )}
      </VStack>
    </Layout>
  )
}

export default MenuAddonProductCreate

import React, { type ReactNode } from 'react'
import { VStack } from 'react-stacked'

const DashboardContainer: React.FC<{ children: ReactNode, gap?: number }> = ({ children, gap }) => {
  return (
    <VStack backgroundColor='#fefefe' borderRadius={5} gap={gap} grow={1} maxWidth={700} padding={16}>
      {children}
    </VStack>
  )
}

export default DashboardContainer

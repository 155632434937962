import gql from 'graphql-tag'
import React, { useMemo } from 'react'

import { GetPageMenuDocument, useCreateMenuCategoryMutation, useGetCreateMenuCategoryDataQuery } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import MenuCategoryForm, { type MenuCategorySchema } from '../components/MenuCategoryForm'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

gql`
  query GetCreateMenuCategoryData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id
      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        pages {
          id
          name
        }
      }
    }
  }

  mutation CreateMenuCategory($restaurantId: ID!, $menuPageId: ID!, $name: String!) {
    createMenuCategory(
      restaurantId: $restaurantId
      menuPageId: $menuPageId
      input: {
        name: $name
      }
    ) {
      id
    }
  }
`

const MenuCategoryCreate: React.FC = () => {
  const [navigation, { restaurantId, menuPageId }] = useNavigation<'MenuCategoryCreate'>()
  const { data, loading } = useGetCreateMenuCategoryDataQuery({ variables: { restaurantId } })
  const [createMenuCategory, { loading: saving }] = useCreateMenuCategoryMutation()

  const pages = data?.restaurant?.menu?.pages ?? []

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['MenuView', { restaurantId }], title: 'Meny' },
    { link: ['MenuPageView', { restaurantId, menuPageId }], title: pages.find(page => page.id === menuPageId)?.name ?? '' }
  ], [menuPageId, pages, restaurantId])

  const redirect = (menuPageId: string): void => {
    navigation.navigate('MenuPageView', { restaurantId, menuPageId })
  }

  const handleCancel = (): void => {
    redirect(menuPageId)
  }

  const handleSave = ignoreAsync(async ({ menuPageId, name }: MenuCategorySchema) => {
    await createMenuCategory({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetPageMenuDocument, variables: { restaurantId, menuPageId } }],
      variables: { restaurantId, menuPageId, name }
    })

    redirect(menuPageId)
  })

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title='Skapa kategori'>
      <MenuCategoryForm
        defaultValues={{ menuPageId, name: '' }}
        menuPageOptions={pages.map(({ id, name }) => ({ value: id, title: name ?? '' }))}
        onCancel={handleCancel}
        onSave={handleSave}
        saving={saving}
      />
    </Layout>
  )
}

export default MenuCategoryCreate

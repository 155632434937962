import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import { type FullPhysicalControlUnitOutageFragment, useCreatePhysicalControlUnitOutageReportMutation, useGetPhysicalControlUnitOutageReportQuery } from '../../types/graphql'
import formatDateTime from '../util/formatDateTime'
import ignoreAsync from '../util/ignoreAsync'
import openLink from '../util/openLink'

import { PrimaryButton } from './Buttons'
import Link from './Link'
import ListItemWrapper from './ListItemWrapper'

gql`
  fragment FullPhysicalControlUnitOutageReport on PhysicalControlUnitOutageReport {
    id

    link
    status
  }

  query GetPhysicalControlUnitOutageReport($physicalControlUnitOutageReportId: ID!) {
    physicalControlUnitOutageReport(id: $physicalControlUnitOutageReportId) {
      ...FullPhysicalControlUnitOutageReport
    }
  }

  mutation CreatePhysicalControlUnitOutageReport($physicalControlUnitOutageId: ID!) {
    createPhysicalControlUnitOutageReport(physicalControlUnitOutageId: $physicalControlUnitOutageId) {
      ...FullPhysicalControlUnitOutageReport
    }
  }
`

interface PhysicalControlUnitOutageItemProps {
  item: FullPhysicalControlUnitOutageFragment
  onPress: () => void
}

const PhysicalControlUnitOutageItem: React.FC<PhysicalControlUnitOutageItemProps> = ({ item, onPress }) => {
  const isOngoing = item.restoredAt == null

  const [createReport, createReportResponse] = useCreatePhysicalControlUnitOutageReportMutation({
    variables: {
      physicalControlUnitOutageId: item.id
    }
  })

  const getReportResponse = useGetPhysicalControlUnitOutageReportQuery({
    pollInterval: 5000,
    skip: createReportResponse.data?.createPhysicalControlUnitOutageReport?.id == null,
    variables: createReportResponse.data?.createPhysicalControlUnitOutageReport?.id == null ? undefined : {
      physicalControlUnitOutageReportId: createReportResponse.data.createPhysicalControlUnitOutageReport.id
    }
  })

  useEffect(() => {
    if (getReportResponse.data?.physicalControlUnitOutageReport?.link == null) return

    getReportResponse.stopPolling()
    openLink(getReportResponse.data.physicalControlUnitOutageReport.link)
  }, [getReportResponse.data?.physicalControlUnitOutageReport?.link])

  return (
    <ListItemWrapper backgroundColor={!isOngoing ? undefined : '#ffd0cd'}>
      <VStack
        alignItems='start'
        borderRadius={3}
        grow={1}
        padding={!isOngoing ? undefined : 8}
        paddingHorizontal={12}
      >
        <HStack alignItems='center' justifyContent='start'>
          <Text size={16}>ID: {item.id} {!isOngoing ? '' : '(pågående driftstopp)'}</Text>
        </HStack>

        <Spacer height={16} />

        <VStack alignItems='start'>
          {item.createdAt == null ? null : (
            <Text size={14}>
              <TextStyle weight='bold'>Inträffade:</TextStyle> {formatDateTime(Temporal.Instant.from(item.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}
            </Text>
          )}
          {item.restoredAt == null ? null : (
            <Text size={14}>
              <TextStyle weight='bold'>Återställdes:</TextStyle> {formatDateTime(Temporal.Instant.from(item.restoredAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}
            </Text>
          )}
        </VStack>
      </VStack>

      <Spacer width={16} />

      {isOngoing ? (
        <PrimaryButton
          onPress={onPress}
          title='Återställ driftstopp'
        />
      ) : (
        getReportResponse.data?.physicalControlUnitOutageReport?.link != null ? (
          <Text padding={10}>
            <Link title='driftstopp.csv' url={getReportResponse.data.physicalControlUnitOutageReport.link} />
          </Text>
        ) : (
          <PrimaryButton
            loading={createReportResponse.loading || createReportResponse.called}
            onPress={ignoreAsync(async () => {
              await createReport()
            })}
            title='Hämta rapport'
          />
        )
      )}
    </ListItemWrapper>
  )
}

export default PhysicalControlUnitOutageItem

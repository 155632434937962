import React, { type ReactNode } from 'react'
import { useWindowDimensions } from 'react-native'
import { ReactNativeModal as Upstream } from 'react-native-modal'

export interface ModalProps {
  children: ReactNode
  dismissWithSwipe?: boolean
  enableSwipe?: boolean
  hideBackdrop?: boolean
  onDismiss?: () => void
  onShow?: () => void
  visible?: boolean
}

const Modal: React.FC<ModalProps> = ({ children, enableSwipe, hideBackdrop, dismissWithSwipe, onDismiss, onShow, visible }) => {
  const { height, width } = useWindowDimensions()

  return (
    <Upstream
      backdropOpacity={(hideBackdrop ?? false) ? 0 : 0.5}
      deviceHeight={height}
      deviceWidth={width}
      isVisible={visible}
      onBackButtonPress={onDismiss}
      onBackdropPress={onDismiss}
      onModalShow={onShow}
      onSwipeComplete={(enableSwipe ?? false) ? () => undefined : (dismissWithSwipe ?? false) ? onDismiss : undefined}
      style={{ alignItems: 'center' }}
      swipeDirection={(enableSwipe ?? false) ? ['left', 'right', 'up', 'down'] : (dismissWithSwipe ?? false) ? ['left', 'right'] : undefined}
    >
      {/* FIXME: React-Native-Modal can't have null children, https://github.com/react-native-modal/react-native-modal/issues/100 */}
      {children ?? <></>}
    </Upstream>
  )
}

export default Modal

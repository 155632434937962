import { gql } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Switch } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { EatInServicePolicy, useAcceptOrderDisplaySystemTermsOfServiceMutation, useGetRestaurantOrderDisplaySystemDataQuery, useUpdateRestaurantDisplaySystemMutation } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import CopyIcon from '../components/CopyIcon'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import Link from '../components/Link'
import ListContainer from '../components/ListContainer'
import SetupContainer from '../components/SetupContainer'
import StepIndicator from '../components/StepIndicator'
import SubmitFormButtons from '../components/SubmitFormButtons'
import Warning from '../components/Warning'
import { ColorField, ImageField } from '../components/fields'
import { ACCENT_COLOR } from '../lib/color'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

gql`
  fragment RestaurantFieldsForDisplaySystem on Restaurant {
    id

    hasClientHeaderMessageAccess
    hasOrderDisplaySystemAccess
    orderDisplaySystemAccentColor
    orderDisplaySystemEnabled
    orderDisplaySystemLogoUrl
    orderDisplaySystemUrl
    eatInServicePolicy

    tills {
      id

      sendOrderToKitchen
    }
  }

  query GetRestaurantOrderDisplaySystemData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      ...RestaurantFieldsForDisplaySystem
    }
  }

  mutation AcceptOrderDisplaySystemTermsOfService($restaurantId: ID!) {
    acceptOrderDisplaySystemTermsOfService(restaurantId: $restaurantId) {
      id

      ...RestaurantFieldsForDisplaySystem
    }
  }

  mutation UpdateRestaurantDisplaySystem($restaurantId: ID!, $orderDisplaySystemAccentColor: OpaqueColor, $orderDisplaySystemEnabled: Boolean!, $orderDisplaySystemLogoUrl: URL) {
    updateRestaurant(
      restaurantId: $restaurantId
      patch: {
        orderDisplaySystemEnabled: $orderDisplaySystemEnabled,
        orderDisplaySystemAccentColor: $orderDisplaySystemAccentColor,
        orderDisplaySystemLogoUrl: $orderDisplaySystemLogoUrl
      }
    ) {
      id

      orderDisplaySystemAccentColor
      orderDisplaySystemEnabled
      orderDisplaySystemLogoUrl
    }
  }
`

interface RestaurantOrderDisplaySystemForm {
  orderDisplaySystemAccentColor?: string | null
  orderDisplaySystemEnabled: boolean
  orderDisplaySystemLogoUrl?: string | null
}

const schema: ObjectSchema<RestaurantOrderDisplaySystemForm> = yup.object({
  orderDisplaySystemAccentColor: yup.string().nullable(),
  orderDisplaySystemEnabled: yup.boolean().required('Ange om orderskärmen ska vara aktiverad'),
  orderDisplaySystemLogoUrl: yup.string().url().nullable()
})

const RestaurantOrderDisplaySystem: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantOrderDisplaySystem'>()

  const { data, loading } = useGetRestaurantOrderDisplaySystemDataQuery({ variables: { restaurantId }, errorPolicy: 'all' })
  const [acceptOrderDisplaySystemTermsOfService, { error: acceptTermsOfServiceError, loading: acceptingTermsOfService }] = useAcceptOrderDisplaySystemTermsOfServiceMutation()
  const [updateRestaurant, { loading: saving, error, called }] = useUpdateRestaurantDisplaySystemMutation()

  const form = useForm<RestaurantOrderDisplaySystemForm>({
    criteriaMode: 'all',
    resetOptions: { keepDirtyValues: true, keepErrors: true },
    resolver: yupResolver(schema),
    values: schema.cast({ ...data?.restaurant, orderDisplaySystemEnabled: data?.restaurant?.orderDisplaySystemEnabled ?? false }, { stripUnknown: true })
  })

  const hasOrderDisplaySystemAccess = data?.restaurant?.hasOrderDisplaySystemAccess ?? false

  const handleAcceptPress = (): void => {
    acceptOrderDisplaySystemTermsOfService({ variables: { restaurantId } }).catch(logError)
  }

  const toggleSwitch = (): void => {
    if (!isEnabled) {
      switch (step) {
        case 0:
          showAlert('Serveringspolicy', 'Ej kompatibel serveringspolicy: Slå på "Manuell klarmarkering av ordrar" eller "Gästen hämtar beställningen själv" under sidomenyn > Inställningar.')
          return
        case 1:
          showAlert('Alla kassor måste ha inställningen "Varorna tillverkas av köket (skicka meddelande till köket)”')
          return
      }
    }

    form.setValue('orderDisplaySystemEnabled', !isEnabled, { shouldDirty: true, shouldValidate: true })
  }

  const isEnabled = form.watch('orderDisplaySystemEnabled')

  const handleUpdateDisplaySystem = ({ orderDisplaySystemEnabled, orderDisplaySystemAccentColor, orderDisplaySystemLogoUrl }: RestaurantOrderDisplaySystemForm): void => {
    updateRestaurant({ variables: { restaurantId, orderDisplaySystemEnabled, orderDisplaySystemAccentColor, orderDisplaySystemLogoUrl } }).catch(logError)
  }

  const step = useMemo(() => {
    if (data?.restaurant?.eatInServicePolicy === EatInServicePolicy.CompleteAutomatically) return 0
    if (data?.restaurant?.tills?.some(till => till.sendOrderToKitchen !== 'Always') ?? false) return 1

    return 2
  }, [data?.restaurant])

  const labels = [
    'Under backoffice → Inställningar och rubriken Loco Pos Server behöver du under serveringspolicy ha valt “Manuell klarmarkering av ordrar" eller "Gästen hämtar beställningen själv"',
    'Under backoffice → Kassor för POS behöver samtliga expresskassor vara inställda på “varorna tillverkas av köket (skicka meddelande till köket)” under inställningen “Notifieringar i samband med köp”'
  ]

  const handleReset = (): void => {
    form.reset()
  }

  if (loading) {
    return <Layout loading title='Orderskärm' />
  }

  if (!hasOrderDisplaySystemAccess) {
    return (
      <Layout hideTitle screenType={ScreenType.List} title='Orderskärm'>
        <ListContainer maxWidth={700} title='Orderskärm'>
          <HStack>
            <SecondaryButton
              icon='chevron-left'
              onPress={() => navigation.navigate('RestaurantDashboard', { restaurantId })}
              title='Tillbaka'
            />
          </HStack>

          <SetupContainer
            disabled={hasOrderDisplaySystemAccess}
            disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
          >
            <HStack alignItems='center' gap={8}>
              <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                <Text color='white' size={16}>1</Text>
              </VStack>

              <Text color={ACCENT_COLOR} size={24}>Acceptera Terms of Service</Text>
            </HStack>

            <Text size={12}>För att kunna använda orderskärmen måste du acceptera våra Terms of Service.</Text>
            <Text size={12}>Denna tjänst möjliggör</Text>
            <Text paddingLeft={8} size={12}>☑️ Säker webblänk till sida för att visa orderskärmen</Text>
            <Text paddingLeft={8} size={12}>☑️ Kunna lägga upp egen logga högst upp skärmen</Text>
            <Text paddingLeft={8} size={12}>☑️ Kunna ändra accentfärg på färdiga beställningar gjorda i Loco</Text>
            <Text paddingLeft={8} size={12}>☑️ Fullt fungerande med alla våra integrationer, som Foodora mm.</Text>

            <Text size={12}>
              Genom att acceptera villkoren för användandet av denna funktion bekräftar du att du läst våra <Link title='Terms of Service' url='https://gastrogate.notion.site/Terms-of-Service-Loco-d501714a78054cb5af6315c7ca0df83d' /> och att priset sätts i enlighet med rådande <Link title='prislista på gastrogate.io' url='https://gastrogate.io/orderskarm/' />.
            </Text>

            <Spacer height={12} />

            {acceptTermsOfServiceError?.message == null ? null : <Warning message={acceptTermsOfServiceError.message} paddingBottom={12} />}

            <PrimaryButton
              loading={acceptingTermsOfService}
              onPress={handleAcceptPress}
              title='Acceptera användarvillkor'
            />
          </SetupContainer>
        </ListContainer>
      </Layout>
    )
  }

  return (
    <Layout hideTitle screenType={ScreenType.Form} title='Orderskärm'>
      <FormContainer gap={20} title='Orderskärm'>
        <Text>För att orderskärmen ska fungera på ett bra sätt behöver restaurangen ha följande inställningar i Loco Backoffice:</Text>

        <VStack minHeight={120}>
          <StepIndicator
            currentPosition={step}
            direction='vertical'
            labels={labels}
            stepCount={labels.length}
          />
        </VStack>

        <Text>När du säkerställt att din restaurang har följande inställningar kan du aktivera orderskärmen genom att slå på knappen Aktivera Orderskärm nedan och trycka på Spara. Sedan är det bara att kopiera länken som visas längst ner till din Orderskärms webbläsare. Beställningar som kommer in hamnar i kolumnen "Tillagas" och de som markerats som “Klar” från köket kommer att flytas till kolumnen "Hämta". På köksskärmen klickar du på "Hämtad" för att den ska försvinna.</Text>

        <ImageField
          backgroundColor='#000'
          cropToolProps={{ maxWidth: 2000, ratio: 1 }}
          form={form}
          name='orderDisplaySystemLogoUrl'
          title='Bild för logga på orderskärm (1:1)'
          viewOptions={{ height: 250, width: 250 }}
        />

        <ColorField
          form={form}
          name='orderDisplaySystemAccentColor'
          opaque
          title='Accentfärg'
        />

        <HStack alignItems='center' gap={20}>
          <Text weight='bold'>Aktivera orderskärm</Text>

          <Switch
            ios_backgroundColor='#3e3e3e'
            onValueChange={toggleSwitch}
            thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
            trackColor={{ false: '#767577', true: '#81b0ff' }}
            value={isEnabled}
          />
        </HStack>

        <SubmitFormButtons
          called={called}
          disableSaveButton={!form.formState.isDirty || saving}
          error={error}
          isDirty={form.formState.isDirty}
          onReset={handleReset}
          onSave={ignoreAsync(form.handleSubmit(handleUpdateDisplaySystem))}
          saving={saving ?? false}
        />

        <Text size={24}>Länk till orderskärm</Text>
        <Text size={16}>Orderskärmen nås på följande krypterade länk från vilken enhet som helst, och du behöver inte vara inloggad för att komma åt den:</Text>
        <HStack alignItems='center' gap={10}>
          {(data?.restaurant?.orderDisplaySystemUrl != null) && <Link title={data?.restaurant?.orderDisplaySystemUrl} url={data?.restaurant?.orderDisplaySystemUrl} />}
          <CopyIcon textToCopy={data?.restaurant?.orderDisplaySystemUrl ?? ''} />
        </HStack>
      </FormContainer>
    </Layout>
  )
}

export default RestaurantOrderDisplaySystem

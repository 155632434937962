import { MaterialIcons } from '@expo/vector-icons'
import React, { useState } from 'react'

interface BinProps {
  backgroundColor?: string
  onSelected: () => void
}

const Bin: React.FC<BinProps> = ({ backgroundColor, onSelected }) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <div
      onClick={onSelected}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        alignItems: 'center',
        backgroundColor: isHovering ? '#E99' : (backgroundColor ?? '#DDD'),
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        flexShrink: 0,
        height: 40,
        justifyContent: 'center',
        width: 40
      }}
    >
      <MaterialIcons
        color={isHovering ? '#FFF' : '#000'}
        name={isHovering ? 'delete-forever' : 'delete'}
        size={21}
      />
    </div>
  )
}

export default Bin

import gql from 'graphql-tag'
import React, { useMemo } from 'react'

import { type EmployeeInput, GetEmployeesDocument, useCreateEmployeeMutation, useGetRestaurantEmployeeCreateDataQuery } from '../../types/graphql'
import EmployeeForm from '../components/EmployeeForm'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantEmployeeCreateData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      employeeGroups {
        ...EmployeeGroupFields
      }
    }
  }

  mutation CreateEmployee($restaurantId: ID!, $input: EmployeeInput!) {
    createEmployee(
      restaurantId: $restaurantId,
      input: $input
    ) {
      ...EmployeeFields
    }
  }
`

const RestaurantEmployeeCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantEmployeeCreate'>()

  const { data, loading } = useGetRestaurantEmployeeCreateDataQuery({ variables: { restaurantId } })

  const [createEmployee, { loading: saving }] = useCreateEmployeeMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('RestaurantEmployeeList', { restaurantId }),
    refetchQueries: [{ query: GetEmployeesDocument, variables: { restaurantId } }]
  })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantEmployeeList', { restaurantId }], title: 'Anställda' }
  ], [restaurantId])

  const handleDismiss = (): void => {
    navigation.navigate('RestaurantEmployeeList', { restaurantId })
  }

  const handleSave = (input: EmployeeInput): void => {
    createEmployee({ variables: { restaurantId, input } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title='Skapa ny anställd'>
      <EmployeeForm
        employeeGroups={data?.restaurant?.employeeGroups}
        onDismiss={handleDismiss}
        onSave={handleSave}
        saving={saving}
      />
    </Layout>
  )
}

export default RestaurantEmployeeCreate

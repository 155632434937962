import React, { type ReactNode } from 'react'
import { useWindowDimensions } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import ReactNativeModal from 'react-native-modal'

interface SidebarProps {
  children: ReactNode
  onDismiss?: () => void
  visible?: boolean
}

export const Sidebar: React.FC<SidebarProps> = ({ children, onDismiss, visible }) => {
  const { height, width } = useWindowDimensions()

  return (
    <ReactNativeModal
      animationIn='fadeInLeft'
      animationOut='fadeOutLeft'
      backdropOpacity={0.5}
      deviceHeight={height}
      deviceWidth={width}
      isVisible={visible}
      onBackButtonPress={onDismiss}
      onBackdropPress={onDismiss}
      style={{ alignSelf: 'flex-start', backgroundColor: 'white', margin: 0, minWidth: 250 }}
    >
      <ScrollView>
        {children}
      </ScrollView>
    </ReactNativeModal>
  )
}

import gql from 'graphql-tag'
import React, { useMemo } from 'react'

import { GetCreateMenuCategoryDataDocument, useCreateMenuPageMutation } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import MenuPageForm, { type MenuPageSchema } from '../components/MenuPageForm'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateMenuPage($restaurantId: ID!, $input: MenuPageInput!) {
    createMenuPage(
      restaurantId: $restaurantId
      input: $input
    ) {
      id
    }
  }
`

const MenuPageCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'MenuPageCreate'>()
  const [createMenuPage, { loading: saving }] = useCreateMenuPageMutation()

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['MenuView', { restaurantId }], title: 'Meny' }
  ], [restaurantId])

  const handleCancel = (): void => {
    navigation.navigate('MenuView', { restaurantId })
  }

  const handleSave = ignoreAsync(async (values: MenuPageSchema) => {
    await createMenuPage({
      awaitRefetchQueries: true,
      refetchQueries: ['GetMenuPages', { query: GetCreateMenuCategoryDataDocument, variables: { restaurantId } }],
      variables: { restaurantId, input: { name: values.name } }
    })

    navigation.navigate('MenuView', { restaurantId })
  })

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title='Skapa huvudkategori'>
      <MenuPageForm
        defaultValues={{ name: '' }}
        onCancel={handleCancel}
        onSave={handleSave}
        saving={saving}
      />
    </Layout>
  )
}

export default MenuPageCreate

import { MaterialIcons } from '@expo/vector-icons'
import { useNavigationState } from '@react-navigation/native'
import gql from 'graphql-tag'
import React, { type ReactNode, useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, Image, Pressable, View, useWindowDimensions } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import WithSeparator from 'react-with-separator'
import sortOn from 'sort-on'

import { type WhiteLabelLayoutFieldsFragment, useGetLayoutInfoQuery } from '../../types/graphql'
import { ACCENT_COLOR } from '../lib/color'
import { type ParamList } from '../lib/navigation'
import { clearAccessToken, hasAccessToken } from '../lib/token-storage'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import openLink from '../util/openLink'
import redirectToLanding from '../util/redirectToLanding'
import { moderateScale } from '../util/scale'
import useNavigation, { type NavigateFunction } from '../util/useNavigation'

import { AppBar } from './AppBar'
import Badge from './Badge'
import Divider from './Divider'
import DynamicIcon, { type DynamicIconType } from './DynamicIcon'
import { Sidebar } from './Sidebar'
import Heading from './atoms/Heading'

const Separator: React.FC = () => <MaterialIcons color='black' name='chevron-right' size={24} style={{ paddingHorizontal: 4 }} />

gql`
  fragment WhiteLabelPermissions on WhiteLabel {
    hasDiscountWriteAccess: hasAccess(access:DiscountWrite)
    hasGuestWriteAccess: hasAccess(access:GuestWrite)
    hasMarketingPushNotificationWriteAccess: hasAccess(access:MarketingPushNotificationWrite)
    hasUserWriteAccess: hasAccess(access:UserWrite)
    isOwner: hasAccess(access:Write)
  }

  fragment OrganizationPermissions on Organization {
    hasOrganizationAccountingAccess: hasAccess(access:AccountingWrite)
    hasUserWriteAccess: hasAccess(access:UserWrite)
    isOwner: hasAccess(access:Write)
  }

  fragment RestaurantPermissions on Restaurant {
    hasDiscountWriteAccess: hasAccess(access:DiscountWrite)
    hasMenuStockWriteAccess: hasAccess(access:MenuStockWrite)
    hasMenuWriteAccess: hasAccess(access:MenuWrite)
    hasOngoingControlUnitOutage
    hasOpeningHoursWriteAccess: hasAccess(access:OpeningHoursWrite)
    hasOrderDisplaySystemAccess
    hasPosWriteAccess: hasAccess(access:PosWrite)
    hasReportReadAccess: hasAccess(access:ReportRead)
    hasReportWriteAccess: hasAccess(access:ReportWrite)
    hasSettingsWriteAccess: hasAccess(access:SettingsWrite)
    isOwner: hasAccess(access:Write)
  }

  fragment RestaurantLayoutFields on Restaurant {
    id

    active
    name

    ...RestaurantPermissions

    organization {
      id

      ...OrganizationPermissions
    }

    setupProgress {
      hasEnteredActivationCode
    }
  }

  fragment OrganizationLayoutFields on Organization {
    id

    name

    ...OrganizationPermissions

    restaurants {
      ...RestaurantLayoutFields
    }
  }

  fragment WhiteLabelLayoutFields on WhiteLabel {
    id

    appName
    label

    ...WhiteLabelPermissions
  }

  query GetLayoutInfo($organizationId: ID!, $hasOrganizationId: Boolean!, $restaurantId: ID!, $hasRestaurantId: Boolean!, $whiteLabelId: ID!, $hasWhiteLabelId: Boolean!) {
    me {
      id

      username
      isSuperUser

      organizationsWithAccess(access: Read) {
        ...OrganizationLayoutFields
      }

      restaurantsWithAccess(access: Read) {
        ...RestaurantLayoutFields
      }

      whiteLabelsWithAccess(access: Read) {
        ...WhiteLabelLayoutFields
      }
    }

    organization(id: $organizationId) @include(if: $hasOrganizationId) {
      ...OrganizationLayoutFields
    }

    restaurant(id: $restaurantId) @include(if: $hasRestaurantId) {
      id

      active
      name

      ...RestaurantPermissions

      cashregister {
        id

        isRegistered

        controlUnit {
          isRegistered
        }
      }

      organization {
        ...OrganizationLayoutFields
      }

      setupProgress {
        hasEnteredActivationCode
      }
    }

    whiteLabel(id: $whiteLabelId) @include(if: $hasWhiteLabelId) {
      ...WhiteLabelLayoutFields
    }
  }
`

interface ListItemProps {
  badgeStatus?: 'error' | 'warning'
  hasActivatedLoco?: boolean
  icon: string
  iconColor?: string
  iconType?: DynamicIconType
  name: string
  onPress: () => void
  selected?: boolean
  showBadge?: boolean
}

const ListItem: React.FC<ListItemProps> = ({ badgeStatus = 'error', hasActivatedLoco = false, icon, iconColor = ACCENT_COLOR, iconType = 'material', name, onPress, selected = false, showBadge = false }) => {
  return (
    <Pressable onPress={onPress}>
      <HStack alignItems='center' backgroundColor={selected ? '#00000011' : undefined} paddingHorizontal={16} paddingVertical={12}>
        <DynamicIcon color={iconColor} name={icon} size={24} type={iconType} />

        <Spacer width={32} />

        <Text size={16}>{name}</Text>

        <Spacer grow={1} width={0} />

        {hasActivatedLoco ? <MaterialIcons color={ACCENT_COLOR} name='warning' /> : null}

        {!showBadge ? null : <Badge status={badgeStatus} />}
      </HStack>
    </Pressable>
  )
}

interface SuperUserLinksProps {
  currentScreen: keyof ParamList
  navigate: NavigateFunction
}

const SuperUserLinks: React.FC<SuperUserLinksProps> = ({ currentScreen, navigate }) => (
  <>
    <ListItem icon='desktop-mac-dashboard' iconType='material-community' name='Dashboard' onPress={() => navigate('SuperUserDashboard', {})} selected={currentScreen === 'SuperUserDashboard'} />
    <ListItem icon='restaurant' name='Restauranger' onPress={() => navigate('RestaurantList', {})} selected={currentScreen === 'RestaurantList'} />
    <ListItem icon='apps' name='White Labels' onPress={() => navigate('WhiteLabelList', {})} selected={currentScreen === 'WhiteLabelList'} />
    <ListItem icon='business' name='Metaorganisationer' onPress={() => navigate('MetaOrganizationList', {})} selected={currentScreen === 'MetaOrganizationList'} />
    <ListItem icon='server' iconType='material-community' name='Kontrollenheter' onPress={() => navigate('ControlUnitList', {})} selected={currentScreen === 'ControlUnitList'} />
    <ListItem icon='attach-money' name='Försäljning' onPress={() => navigate('StatisticsOverview', {})} selected={currentScreen === 'StatisticsOverview'} />
    <ListItem icon='file-invoice' iconType='font-awesome-5' name='Younium' onPress={() => navigate('YouniumUsageExport', {})} selected={currentScreen === 'YouniumUsageExport'} />
  </>
)

interface RestaurantLinksProps {
  active: boolean
  currentScreen?: keyof ParamList
  hasActivatedLoco: boolean
  hasDiscountWriteAccess: boolean
  hasMenuStockWriteAccess: boolean
  hasMenuWriteAccess: boolean
  hasOngoingControlUnitOutage: boolean
  hasOpeningHoursWriteAccess: boolean
  hasOrderDisplaySystemAccess: boolean
  hasPosWriteAccess: boolean
  hasReportReadAccess: boolean
  hasReportWriteAccess: boolean
  hasSettingsWriteAccess: boolean
  isOwner: boolean
  isGettingStarted: boolean
  isSelected: boolean
  navigate: NavigateFunction
  needsToDownloadOutageData: boolean
  onSelected: (id: string) => void
  restaurantId: string
  restaurantName: string | null | undefined
}

const RestaurantLinks: React.FC<RestaurantLinksProps> = ({ active, currentScreen, hasActivatedLoco, hasDiscountWriteAccess, hasMenuStockWriteAccess, hasMenuWriteAccess, hasOngoingControlUnitOutage, hasOpeningHoursWriteAccess, hasOrderDisplaySystemAccess, hasPosWriteAccess, hasReportReadAccess, hasReportWriteAccess, hasSettingsWriteAccess, isOwner, isGettingStarted, isSelected, navigate, needsToDownloadOutageData, onSelected, restaurantId, restaurantName }) => (
  <>
    <ListItem
      badgeStatus={needsToDownloadOutageData ? 'error' : 'warning'}
      icon={isSelected ? 'keyboard-arrow-down' : 'keyboard-arrow-right'}
      iconColor='#777'
      name={`${restaurantName ?? ''}${active ? '' : ' (inaktiv)'}`}
      onPress={() => onSelected(restaurantId)}
      showBadge={hasOngoingControlUnitOutage || needsToDownloadOutageData}
    />

    {!isSelected
      ? null
      : (
        <>
          {isGettingStarted ? null : <ListItem icon='home' name='Dashboard' onPress={() => navigate('RestaurantDashboard', { restaurantId })} selected={currentScreen === 'RestaurantDashboard'} />}
          {!isGettingStarted ? null : <ListItem hasActivatedLoco={!hasActivatedLoco} icon='assignment-turned-in' name='Kom igång' onPress={() => navigate('RestaurantGetStarted', { restaurantId })} selected={currentScreen === 'RestaurantGetStarted'} />}
          {!hasReportReadAccess ? null : <ListItem icon='description' name='Rapporter' onPress={() => navigate('RestaurantSalesReportList', { restaurantId })} selected={currentScreen === 'RestaurantSalesReportList'} />}
          {!hasReportReadAccess ? null : <ListItem icon='cash-register' iconType='font-awesome-5' name='Kontrollenheten' onPress={() => navigate('RestaurantControlUnitOutageList', { restaurantId })} selected={currentScreen === 'RestaurantControlUnitOutageList'} showBadge={hasOngoingControlUnitOutage || needsToDownloadOutageData} />}
          {!hasReportReadAccess ? null : <ListItem icon='insert-chart' name='Statistik' onPress={() => navigate('RestaurantStatisticsView', { restaurantId })} selected={currentScreen === 'RestaurantStatisticsView'} />}
          {!isOwner ? null : <ListItem icon='receipt' name='Kvitton' onPress={() => navigate('RestaurantReceiptList', { restaurantId })} selected={currentScreen === 'RestaurantReceiptList'} />}
          {!hasOpeningHoursWriteAccess ? null : <ListItem icon='schedule' name='Öppettider' onPress={() => navigate('OpeningHoursList', { restaurantId })} selected={currentScreen === 'OpeningHoursList'} />}
          {!hasOpeningHoursWriteAccess ? null : <ListItem icon='event-available' name='Öppettidsslotter' onPress={() => navigate('TimeSlotsView', { restaurantId })} selected={currentScreen === 'TimeSlotsView'} />}
          <ListItem icon='account-balance' name='Kassaregister' onPress={() => navigate('CashregisterView', { restaurantId })} selected={currentScreen === 'CashregisterView'} />
          {!hasSettingsWriteAccess ? null : <ListItem icon='table-chair' iconType='material-community' name='Bord / Kassa' onPress={() => navigate('DeliveryLocationList', { restaurantId })} selected={currentScreen === 'DeliveryLocationList'} />}
          {!hasPosWriteAccess ? null : <ListItem icon='account-circle' name='Anställda' onPress={() => navigate('RestaurantEmployeeList', { restaurantId })} selected={currentScreen === 'RestaurantEmployeeList'} />}
          {!hasPosWriteAccess ? null : <ListItem icon='beenhere' name='Kassor för POS' onPress={() => navigate('RestaurantTillList', { restaurantId })} selected={currentScreen === 'RestaurantTillList'} />}
          {!isOwner || !hasOrderDisplaySystemAccess ? null : <ListItem icon='cash-register' iconType='font-awesome-5' name='Orderskärm' onPress={() => navigate('RestaurantOrderDisplaySystem', { restaurantId })} selected={currentScreen === 'RestaurantOrderDisplaySystem'} />}
          {!hasSettingsWriteAccess ? null : <ListItem icon='printer' iconType='material-community' name='Skrivare' onPress={() => navigate('RestaurantPrintersView', { restaurantId })} selected={currentScreen === 'RestaurantPrintersView'} />}
          {!hasMenuStockWriteAccess ? null : <ListItem icon='local-dining' name='Meny' onPress={() => navigate('MenuView', { restaurantId })} selected={currentScreen === 'MenuView'} />}
          {!hasMenuWriteAccess ? null : <ListItem icon='storage' name='Tillägg och Alternativgrupper' onPress={() => navigate('MenuAlternativeGroupList', { restaurantId })} selected={currentScreen === 'MenuAlternativeGroupList'} />}
          {!hasMenuWriteAccess ? null : <ListItem icon='newspaper-variant-outline' iconType='material-community' name='Rätter' onPress={() => navigate('MenuCourseList', { restaurantId })} selected={currentScreen === 'MenuCourseList'} />}
          {!isOwner ? null : <ListItem icon='truck-fast' iconType='material-community' name='Leverans' onPress={() => navigate('RestaurantDeliveryList', { restaurantId })} selected={currentScreen === 'RestaurantDeliveryList'} />}
          {!hasMenuWriteAccess && !hasReportWriteAccess ? null : <ListItem icon='description' name='Rapportgrupper' onPress={() => navigate('ReportGroupList', { restaurantId })} selected={currentScreen === 'ReportGroupList'} />}
          {!hasDiscountWriteAccess ? null : <ListItem icon='tag' iconType='material-community' name='Rabatter' onPress={() => navigate('RestaurantDiscountsList', { restaurantId })} selected={currentScreen === 'RestaurantDiscountsList'} />}
          {!hasReportWriteAccess ? null : <ListItem icon='payment' iconType='material' name='Betalsätt' onPress={() => navigate('RestaurantPaymentMethodList', { restaurantId })} selected={currentScreen === 'RestaurantPaymentMethodList'} />}
          {!hasSettingsWriteAccess ? null : <ListItem icon='palette' name='Design' onPress={() => navigate('RestaurantDesignView', { restaurantId })} selected={currentScreen === 'RestaurantDesignView'} />}
          {!hasSettingsWriteAccess ? null : <ListItem icon='edit' name='Inställningar' onPress={() => navigate('RestaurantEdit', { restaurantId })} selected={currentScreen === 'RestaurantEdit'} />}
        </>
      )}
  </>
)

interface WhiteLabelLinksProps {
  currentScreen?: keyof ParamList
  isSelected: boolean
  isSuperUser: boolean
  navigate: NavigateFunction
  onSelected: (id: string) => void
  whiteLabel: WhiteLabelLayoutFieldsFragment | null | undefined
}

const WhiteLabelLinks: React.FC<WhiteLabelLinksProps> = ({ currentScreen, isSelected, isSuperUser, navigate, onSelected, whiteLabel }) => {
  if (whiteLabel == null) return null

  const whiteLabelId = whiteLabel.id

  return (
    <>
      <ListItem
        icon={isSelected ? 'keyboard-arrow-down' : 'keyboard-arrow-right'}
        iconColor='#777'
        name={`${whiteLabel.appName ?? whiteLabel.label ?? ''} (app)`}
        onPress={() => onSelected(whiteLabelId)}
      />

      {!isSelected
        ? null
        : (
          <>
            {/* Dashboard */}
            <ListItem icon='desktop-mac-dashboard' iconType='material-community' name='Dashboard' onPress={() => navigate('WhiteLabelDashboard', { whiteLabelId })} selected={currentScreen === 'WhiteLabelDashboard'} />
            {/* Loyalty program */}
            {!(whiteLabel.hasGuestWriteAccess ?? false) ? null : <ListItem icon='emoji-people' name='Lojalitet' onPress={() => navigate('WhiteLabelGuestGroupList', { whiteLabelId })} selected={currentScreen === 'WhiteLabelGuestGroupList'} />}
            {/* Discount codes */}
            {!(whiteLabel.hasDiscountWriteAccess ?? false) ? null : <ListItem icon='tag' name='Rabattkoder' onPress={() => navigate('WhiteLabelDiscountList', { whiteLabelId })} selected={currentScreen === 'WhiteLabelDiscountList'} />}
            {/* Push notifications */}
            {!(whiteLabel.hasMarketingPushNotificationWriteAccess ?? false) ? null : <ListItem icon='notifications-active' name='Pushnotiser' onPress={() => navigate('MarketingPushNotificationCreate', { whiteLabelId })} selected={currentScreen === 'MarketingPushNotificationCreate'} />}
            {/* Administratörer */}
            {!(whiteLabel.hasUserWriteAccess ?? false) ? null : <ListItem icon='account-multiple' iconType='material-community' name='Användare' onPress={() => navigate('WhiteLabelUserList', { whiteLabelId })} selected={currentScreen === 'WhiteLabelUserList'} />}
            {/* App design */}
            {!(isSuperUser ?? false) ? null : <ListItem icon='palette' name='Design' onPress={() => navigate('WhiteLabelView', { whiteLabelId })} selected={currentScreen === 'WhiteLabelView'} />}
          </>
        )}
    </>
  )
}

export interface Breadcrumb<RouteName extends keyof ParamList = keyof ParamList> {
  link: [RouteName, ParamList[RouteName]]
  title: string
}

export enum ScreenType {
  Dashboard = 'Dashboard',
  Form = 'Form',
  List = 'List',
  Statistics = 'Statistics'
}

const ApplyScreenStyle: React.FC<{ children: ReactNode, screenType: ScreenType | null }> = ({ children, screenType }) => {
  switch (screenType) {
    case ScreenType.Dashboard:
      return (
        <HStack alignItems='start' gap={16} padding={16} wrap>
          {children}
        </HStack>
      )

    case ScreenType.Form:
      return (
        <HStack alignItems='start' gap={16} padding={16} wrap>
          {children}
        </HStack>
      )

    case ScreenType.List:
      return (
        <HStack gap={16} padding={16} wrap>
          {children}
        </HStack>
      )

    case ScreenType.Statistics:
      return (
        <VStack grow={1} padding={16}>
          {children}
        </VStack>
      )

    default:
      return <>{children}</>
  }
}

interface LayoutProps {
  breadcrumbs?: readonly Breadcrumb[]
  children?: ReactNode
  hideTitle?: boolean
  loading?: boolean
  screenType?: ScreenType
  title?: string | null
}

const Layout: React.FC<LayoutProps> = ({ breadcrumbs = [], children, hideTitle = false, loading = false, screenType, title }) => {
  const [navigation, params] = useNavigation()

  const currentOrganizationId = ('organizationId' in params ? params.organizationId : null) ?? null
  const currentRestaurantId = ('restaurantId' in params ? params.restaurantId : null) ?? null
  const currentWhiteLabelId = ('whiteLabelId' in params ? params.whiteLabelId : null) ?? null

  const { data } = useGetLayoutInfoQuery({
    variables: {
      organizationId: currentOrganizationId ?? '',
      hasOrganizationId: currentOrganizationId != null,

      restaurantId: currentRestaurantId ?? '',
      hasRestaurantId: currentRestaurantId != null,

      whiteLabelId: currentWhiteLabelId ?? '',
      hasWhiteLabelId: currentWhiteLabelId != null
    }
  })

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(currentRestaurantId)
  const [selectedWhiteLabelId, setSelectedWhiteLabelId] = useState(currentWhiteLabelId)

  const hideLogo = useWindowDimensions().width < 500
  const hideWarning = useWindowDimensions().width < 800

  const isGettingStarted = !((data?.restaurant?.cashregister?.isRegistered ?? false) && (data?.restaurant?.cashregister?.controlUnit?.isRegistered ?? false) && (data?.restaurant?.setupProgress?.hasEnteredActivationCode ?? false)) ?? false

  const currentScreen = useNavigationState(state => state.routes[state.routes.length - 1].name) as keyof ParamList
  const isSuperUser = data?.me?.isSuperUser ?? false

  const restaurants = useMemo(() => {
    if (isSuperUser) {
      if (currentOrganizationId == null) {
        return sortOn(data?.restaurant?.organization?.restaurants ?? [], ['active', 'name'])
      }

      return sortOn(data?.organization?.restaurants ?? [], ['active', 'name'])
    }

    return sortOn(data?.me?.restaurantsWithAccess ?? [], ['active', 'name'])
  }, [data?.me?.restaurantsWithAccess, data?.restaurant?.organization, isSuperUser])

  const currentWhiteLabel = useMemo(() => data?.whiteLabel ?? data?.me?.whiteLabelsWithAccess?.find(whiteLabel => whiteLabel.id === currentWhiteLabelId), [currentWhiteLabelId, data?.me?.whiteLabelsWithAccess, data?.whiteLabel])
  const visibleWhiteLabels = useMemo(() => sortOn(data?.me?.whiteLabelsWithAccess ?? [], (item) => item.appName ?? item.label), [data?.me?.whiteLabelsWithAccess])

  const showBadge = restaurants?.some(restaurant => restaurant.hasOngoingControlUnitOutage ?? false)
  const badgeStatus = restaurants?.some(restaurant => restaurant.hasOngoingControlUnitOutage ?? false) ? 'error' : 'warning'
  const hasActivatedLoco = data?.restaurant?.setupProgress?.hasEnteredActivationCode

  const visibleOrganization = data?.organization?.id === (currentOrganizationId ?? data?.restaurant?.organization?.id)
    ? data?.organization
    : data?.restaurant?.organization?.id === (currentOrganizationId ?? data?.restaurant?.organization?.id)
    ? data?.restaurant?.organization
    : null
  const visibleRestaurants = restaurants.filter(restaurant => restaurant.organization?.id === visibleOrganization?.id)

  useEffect(() => {
    navigation.setOptions({ title: title ?? undefined })
  }, [navigation, title])

  const handleMenuDismiss = (): void => {
    setMenuIsOpen(false)
  }

  const navigate: NavigateFunction = (...args) => {
    handleMenuDismiss()
    // @ts-expect-error I cannot figure out how to make TypeScript understand that the arguments are always valid
    navigation.navigate(...args)
  }

  const handleLogout = (): void => {
    clearAccessToken().then(
      () => {
        navigate('Login', {})
      },
      (err) => logError(err)
    )
  }

  const handleRestaurantSelected = (id: string): void => {
    if (id === selectedRestaurantId) {
      setSelectedRestaurantId(null)
      return
    }

    setSelectedRestaurantId(id)
  }

  const handleWhiteLabelSelected = (id: string): void => {
    setSelectedWhiteLabelId(id === selectedWhiteLabelId ? null : id)
  }

  useEffect(() => {
    async function checkLoginStatus (): Promise<void> {
      const isLoggedIn = await hasAccessToken()
      if (!isLoggedIn) {
        navigation.navigate('Login', {})
      }
    }
    checkLoginStatus().catch(logError)
  }, [])

  return (
    <>
      <AppBar>
        <HStack alignItems='center' justifyContent='space-between' minHeight={64}>
          <HStack>
            <VStack alignItems='center' justifyContent='center' padding={24}>
              <MaterialIcons
                color='white'
                name='menu'
                onPress={() => setMenuIsOpen(!menuIsOpen)}
                size={24}
              />

              {!showBadge
                ? null
                : (
                  <View style={{ top: -22, right: -10 }}>
                    <Badge status={badgeStatus} />
                  </View>
                )}
            </VStack>

            {hideLogo
              ? null
              : (
                <>
                  <Pressable onPress={ignoreAsync(async () => await redirectToLanding(navigation))}>
                    <Image source={require('../../assets/icon.png')} style={{ height: moderateScale(55, 0.1), width: moderateScale(55, 0.1) }} />
                  </Pressable>
                  <Spacer width={12} />
                </>
              )}
          </HStack>

          <HStack alignItems='center' gap={10}>
            {(currentRestaurantId == null) || (hasActivatedLoco ?? true) ? null : (
              <Pressable onPress={() => navigate('RestaurantGetStarted', { restaurantId: currentRestaurantId })}>
                <HStack alignItems='center' gap={10}>
                  <MaterialIcons
                    color={ACCENT_COLOR}
                    name='warning'
                  />

                  {!hideWarning ?
                    <Text>Du har inte färdigställt Kom igång</Text> : null}
                </HStack>
              </Pressable>
            )}

            <Pressable onPress={() => openLink('https://help.gastrogate.io')}>
              <Text size={16}>Helpcenter</Text>
            </Pressable>

            <MaterialIcons
              color='white'
              name='refresh'
              onPress={() => location.reload()}
              size={24}
              style={{ paddingVertical: 24, paddingHorizontal: 12 }}
            />
          </HStack>
        </HStack>
      </AppBar>

      <Sidebar onDismiss={handleMenuDismiss} visible={menuIsOpen}>
        <VStack alignItems='center' backgroundColor='black' height={96} justifyContent='center'>
          <Image source={require('../../assets/icon.png')} style={{ height: 75, width: 75 }} />
        </VStack>

        <VStack>
          <WithSeparator separator={<Divider />} trailing>
            {!isSuperUser
              ? null
              : (
                <SuperUserLinks
                  currentScreen={currentScreen}
                  navigate={navigate}
                />
              )}

            {(currentRestaurantId == null && currentOrganizationId == null) ? null : (
              <>
                <HStack alignItems='center' paddingHorizontal={16} paddingVertical={12}>
                  <DynamicIcon color='#777' name='office-building' size={24} type='material-community' />

                  <Spacer width={32} />

                  <Text size={16} weight='bold'>{data?.organization?.name ?? data?.restaurant?.organization?.name ?? ''}</Text>

                  <Spacer grow={1} width={0} />

                  {(data?.me?.organizationsWithAccess?.length ?? 0) <= 1 ? null : (
                    <Pressable onPress={() => navigate('OrganizationSelect', {})}>
                      <Text color={ACCENT_COLOR}>Byt</Text>
                    </Pressable>
                  )}
                </HStack>

                {(visibleOrganization == null || !(visibleOrganization.hasUserWriteAccess ?? false)) ? null : (
                  <ListItem
                    icon='account-multiple'
                    iconColor='#777'
                    iconType='material-community'
                    name='Användare'
                    onPress={() => navigate('OrganizationUserList', { organizationId: visibleOrganization.id })}
                    selected={currentScreen === 'OrganizationUserList'}
                  />
                )}

                {visibleOrganization == null || !(visibleOrganization.hasOrganizationAccountingAccess ?? false) ? null : (
                  <ListItem
                    icon='book-alphabet'
                    iconColor='#777'
                    iconType='material-community'
                    name='Bokföring'
                    onPress={() => navigate('SieReportView', { organizationId: visibleOrganization.id })}
                    selected={currentScreen === 'StripeReportList' || currentScreen === 'SieReportView'}
                  />
                )}

                {currentRestaurantId == null ? null : (
                  <RestaurantLinks
                    active={data?.restaurant?.active ?? false}
                    currentScreen={currentScreen}
                    hasActivatedLoco={data?.restaurant?.setupProgress?.hasEnteredActivationCode ?? false}
                    hasDiscountWriteAccess={data?.restaurant?.hasDiscountWriteAccess ?? false}
                    hasMenuStockWriteAccess={data?.restaurant?.hasMenuStockWriteAccess ?? false}
                    hasMenuWriteAccess={data?.restaurant?.hasMenuWriteAccess ?? false}
                    hasOngoingControlUnitOutage={data?.restaurant?.hasOngoingControlUnitOutage ?? false}
                    hasOpeningHoursWriteAccess={data?.restaurant?.hasOpeningHoursWriteAccess ?? false}
                    hasOrderDisplaySystemAccess={data?.restaurant?.hasOrderDisplaySystemAccess ?? false}
                    hasPosWriteAccess={data?.restaurant?.hasPosWriteAccess ?? false}
                    hasReportReadAccess={data?.restaurant?.hasReportReadAccess ?? false}
                    hasReportWriteAccess={data?.restaurant?.hasReportWriteAccess ?? false}
                    hasSettingsWriteAccess={data?.restaurant?.hasSettingsWriteAccess ?? false}
                    isGettingStarted={isGettingStarted}
                    isOwner={data?.restaurant?.isOwner ?? false}
                    isSelected={currentRestaurantId === selectedRestaurantId}
                    navigate={navigate}
                    // FIXME: Change to correct bool when implemented in api
                    needsToDownloadOutageData={data?.restaurant?.hasOngoingControlUnitOutage ?? false}
                    onSelected={handleRestaurantSelected}
                    restaurantId={currentRestaurantId}
                    restaurantName={data?.restaurant?.name}
                  />
                )}

                {visibleRestaurants.filter(restaurant => restaurant.id !== currentRestaurantId).map((restaurant) => (
                  <RestaurantLinks
                    key={restaurant.id}
                    active={restaurant.active ?? false}
                    hasActivatedLoco={restaurant.setupProgress?.hasEnteredActivationCode ?? false}
                    hasDiscountWriteAccess={restaurant.hasDiscountWriteAccess ?? false}
                    hasMenuStockWriteAccess={restaurant.hasMenuStockWriteAccess ?? false}
                    hasMenuWriteAccess={restaurant.hasMenuWriteAccess ?? false}
                    hasOngoingControlUnitOutage={restaurant.hasOngoingControlUnitOutage ?? false}
                    hasOpeningHoursWriteAccess={restaurant.hasOpeningHoursWriteAccess ?? false}
                    hasOrderDisplaySystemAccess={restaurant.hasOrderDisplaySystemAccess ?? false}
                    hasPosWriteAccess={restaurant.hasPosWriteAccess ?? false}
                    hasReportReadAccess={restaurant.hasReportReadAccess ?? false}
                    hasReportWriteAccess={restaurant.hasReportWriteAccess ?? false}
                    hasSettingsWriteAccess={restaurant.hasSettingsWriteAccess ?? false}
                    isGettingStarted={false}
                    isOwner={restaurant.isOwner ?? false}
                    isSelected={restaurant.id === selectedRestaurantId}
                    navigate={navigate}
                    // FIXME: Change to correct bool when implemented in api
                    needsToDownloadOutageData={restaurant.hasOngoingControlUnitOutage ?? false}
                    onSelected={handleRestaurantSelected}
                    restaurantId={restaurant.id}
                    restaurantName={restaurant.name}
                  />
                ))}

                {(visibleOrganization == null || !(visibleOrganization?.isOwner ?? false)) ? null : (
                  <ListItem
                    icon='add'
                    iconColor={ACCENT_COLOR}
                    name='Ny restaurang'
                    onPress={() => navigate('RestaurantCreate', { organizationId: visibleOrganization.id })}
                    selected={currentScreen === 'RestaurantCreate'}
                  />
                )}
              </>
            )}

            {currentWhiteLabelId == null
              ? null
              : (
                <WhiteLabelLinks
                  currentScreen={currentScreen}
                  isSelected={currentWhiteLabelId === selectedWhiteLabelId}
                  isSuperUser={isSuperUser}
                  navigate={navigate}
                  onSelected={handleWhiteLabelSelected}
                  whiteLabel={currentWhiteLabel}
                />
              )}

            {visibleWhiteLabels.map((whiteLabel) => (
              whiteLabel.id === currentWhiteLabelId
                ? null
                : (
                  <WhiteLabelLinks
                    key={whiteLabel.id}
                    isSelected={whiteLabel.id === selectedWhiteLabelId}
                    isSuperUser={isSuperUser}
                    navigate={navigate}
                    onSelected={handleWhiteLabelSelected}
                    whiteLabel={whiteLabel}
                  />
                )
            ))}

            {visibleRestaurants.length > 0 ? null : data?.me?.organizationsWithAccess?.map((organization) => {
              const restaurantId = organization.restaurants?.[0]?.id

              if (restaurantId == null) return null

              return (
                <ListItem
                  key={organization.id}
                  icon='office-building'
                  iconType='material-community'
                  name={organization.name ?? 'Organisationsnamn saknas'}
                  onPress={() => navigate('RestaurantGetStarted', { restaurantId })}
                />
              )
            })}

            {(data?.me?.organizationsWithAccess == null || data.me.organizationsWithAccess.length > 0) ? null : (
              <ListItem
                icon='add'
                iconColor='#777'
                name='Skapa organisation'
                onPress={() => navigate('OrganizationCreate', {})}
                selected={currentScreen === 'OrganizationCreate'}
              />
            )}
          </WithSeparator>

          <Text color='#00000066' paddingHorizontal={16} paddingVertical={8} size={16}>
            {data?.me?.username}
          </Text>

          <ListItem
            icon='account-circle'
            iconColor={ACCENT_COLOR}
            name='Konto'
            onPress={() => navigate('AccountEdit', {})}
            selected={currentScreen === 'AccountEdit'}
          />

          <ListItem
            icon='exit-to-app'
            iconColor={ACCENT_COLOR}
            name='Logga ut'
            onPress={handleLogout}
          />
        </VStack>
      </Sidebar>

      <VStack minHeight='100vh' paddingBottom={200} shrink={0}>
        {currentOrganizationId == null && currentRestaurantId == null && breadcrumbs.length === 0 ? null : (
          <HStack padding={16} wrap>
            <WithSeparator separator={<Separator />}>
              {currentRestaurantId == null
                ? currentOrganizationId == null
                  ? null : (
                    <Text decorationLine='underline' size={18}>
                      {data?.organization?.name}
                    </Text>
                  )
                : (
                  <Pressable onPress={() => navigate('RestaurantGetStarted', { restaurantId: currentRestaurantId })}>
                    <Text decorationLine='underline' size={18}>
                      {data?.restaurant?.name}
                    </Text>
                  </Pressable>
                )}

              {breadcrumbs.map((breadcrumb) => (
                // @ts-expect-error I cannot figure out how to make TypeScript understand that link[0] will always match up with link[1]
                <Pressable key={breadcrumb.link[0]} onPress={() => navigation.navigate(...breadcrumb.link)}>
                  <Text decorationLine='underline' size={18}>
                    {breadcrumb.title}
                  </Text>
                </Pressable>
              ))}
            </WithSeparator>
          </HStack>
        )}

        {hideTitle || title == null ? null : (
          <Heading padding={16} size='l'>
            {title}
          </Heading>
        )}

        {loading ? (
          <VStack alignItems='center' gap={24} grow={1} justifyContent='center'>
            <ActivityIndicator color={ACCENT_COLOR} size='large' />

            <Text size={24}>Laddar...</Text>
          </VStack>
        ) : (
          <ApplyScreenStyle screenType={screenType ?? null}>
            {children}
          </ApplyScreenStyle>
        )}
      </VStack>
    </>
  )
}

export default Layout

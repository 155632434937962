import React, { useCallback } from 'react'
import { HStack } from 'react-stacked'

import { MenuType, type RestaurantStatisticsFilter, TransactionChannel } from '../../types/graphql'
import { INPUT_BORDER_COLOR } from '../lib/color'

import { titleFromMenuType } from './RestaurantMenu/MenuTypeToggleButton'
import { CheckBox } from './fields'

const allMenuTypes = [MenuType.EatIn, MenuType.TakeAway, MenuType.Delivery]

interface SalesFilterProps {
  filter: RestaurantStatisticsFilter
  onChange: (value: RestaurantStatisticsFilter) => void
  showDeliveryHero?: boolean
}

const SalesFilter: React.FC<SalesFilterProps> = ({ filter, onChange, showDeliveryHero = false }) => {
  const handleOnMenuTypePress = useCallback((menuType: MenuType) => {
    const checkedMenuTypes = filter.menuTypes == null ? [] : [...filter.menuTypes]
    const index = checkedMenuTypes.indexOf(menuType)
    if (index === -1) {
      checkedMenuTypes.push(menuType)
    } else {
      checkedMenuTypes.splice(index, 1)
    }
    const newFilter = { ...filter }
    newFilter.menuTypes = checkedMenuTypes.length > 0 ? checkedMenuTypes : undefined
    onChange(newFilter)
  }, [filter, onChange])

  const handleTransactionChannelPress = useCallback((transactionChannel: TransactionChannel) => {
    const checkedTransactionChannels = filter.transactionChannels == null ? [] : [...filter.transactionChannels]

    const index = checkedTransactionChannels.indexOf(transactionChannel)

    if (index === -1) {
      checkedTransactionChannels.push(transactionChannel)
    } else {
      checkedTransactionChannels.splice(index, 1)
    }

    onChange({
      ...filter,
      transactionChannels: checkedTransactionChannels.length > 0 ? checkedTransactionChannels : undefined
    })
  }, [filter, onChange])

  return (
    <HStack gap={12} paddingTop={12} wrap>
      <HStack borderColor={INPUT_BORDER_COLOR} borderRadius={5} borderStyle='solid' borderWidth={1} wrap>
        {allMenuTypes.map(mt => (
          <CheckBox
            key={mt}
            checked={(filter.menuTypes ?? []).includes(mt)}
            onPress={() => handleOnMenuTypePress(mt)}
            title={titleFromMenuType(mt)}
          />
        ))}
      </HStack>

      <HStack borderColor={INPUT_BORDER_COLOR} borderRadius={5} borderStyle='solid' borderWidth={1} wrap>
        <CheckBox
          checked={filter?.transactionChannels?.includes(TransactionChannel.Order) ?? false}
          onPress={() => handleTransactionChannelPress(TransactionChannel.Order)}
          title='Kassasystem'
        />

        <CheckBox
          checked={filter?.transactionChannels?.includes(TransactionChannel.SelfCheckout) ?? false}
          onPress={() => handleTransactionChannelPress(TransactionChannel.SelfCheckout)}
          title='Expresskassa'
        />
      </HStack>

      {!showDeliveryHero ? null : (
        <HStack borderColor={INPUT_BORDER_COLOR} borderRadius={5} borderStyle='solid' borderWidth={1} wrap>
          <CheckBox
            checked={filter?.transactionChannels?.includes(TransactionChannel.DeliveryHero) ?? false}
            onPress={() => handleTransactionChannelPress(TransactionChannel.DeliveryHero)}
            title='Foodora'
          />
        </HStack>
      )}

      <HStack borderColor={INPUT_BORDER_COLOR} borderRadius={5} borderStyle='solid' borderWidth={1} wrap>
        <CheckBox
          checked={filter?.transactionChannels?.includes(TransactionChannel.WhiteLabel) ?? false}
          onPress={() => handleTransactionChannelPress(TransactionChannel.WhiteLabel)}
          title='Beställningsapp'
        />

        <CheckBox
          checked={filter?.transactionChannels?.includes(TransactionChannel.Web) ?? false}
          onPress={() => handleTransactionChannelPress(TransactionChannel.Web)}
          title='Loco Web (QR/Takeaway)'
        />

        <CheckBox
          checked={filter?.transactionChannels?.includes(TransactionChannel.Loco) ?? false}
          onPress={() => handleTransactionChannelPress(TransactionChannel.Loco)}
          title='Loco App (QR/Takeaway)'
        />
      </HStack>
    </HStack>
  )
}

export default SalesFilter

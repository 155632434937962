import gql from 'graphql-tag'
import React from 'react'
import { useForm } from 'react-hook-form'
import { VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { useCreateWhiteLabelMutation } from '../../types/graphql'
import Layout, { type Breadcrumb } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateWhiteLabel($input: WhiteLabelInput!) {
    createWhiteLabel(
      input: $input
    ) {
      id

      appName
      label
    }
  }
`

interface WhiteLabelCreateInput {
  appName: string
}

const schema: ObjectSchema<WhiteLabelCreateInput> = yup.object({
  appName: yup.string().min(1).required('Namn måste anges')
})

const WhiteLabelCreate: React.FC = () => {
  const [navigation] = useNavigation<'WhiteLabelCreate'>()

  const form = useForm<WhiteLabelCreateInput>({
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const [createWhiteLabel, { loading: saving }] = useCreateWhiteLabelMutation({
    onCompleted: ({ createWhiteLabel }) => navigation.navigate('WhiteLabelView', { whiteLabelId: unwrap(createWhiteLabel?.id) })
  })

  const breadcrumbs: Breadcrumb[] = [
    { link: ['WhiteLabelList', {}], title: 'Whitelabels' }
  ]

  const handleSave = (input: WhiteLabelCreateInput): void => {
    createWhiteLabel({ variables: { input } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} title='Skapa ny Whitelabel'>
      <VStack gap={24} maxWidth={700} padding={16}>
        <TextField
          form={form}
          helperText='Appnamnet är det namn som syns i App Store och Google Play'
          name='appName'
          title='Appnamn'
        />

        <SubmitFormButtons
          disableSaveButton={!form.formState.isValid}
          onCancel={() => navigation.navigate('WhiteLabelList', {})}
          onSave={ignoreAsync(form.handleSubmit(handleSave))}
          saving={saving}
        />
      </VStack>
    </Layout>
  )
}

export default WhiteLabelCreate

// eslint-disable-next-line no-restricted-imports
import { type DocumentNode, type OperationVariables, type QueryHookOptions as UpstreamQueryHookOptions, type QueryResult as UpstreamQueryResult, type TypedDocumentNode, useQuery as upstream } from '@apollo/client'
import { NetworkStatus, isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { useIsFocused } from '@react-navigation/core'
import { useEffect, useMemo } from 'react'
import unreachable from 'ts-unreachable'

interface QueryHookOptions<TData, TVariables extends OperationVariables> extends Omit<UpstreamQueryHookOptions<TData, TVariables>, 'errorPolicy' | 'fetchPolicy' | 'notifyOnNetworkStatusChange' | 'partialRefetch' | 'returnPartialData'> {
  handlesPartialData?: boolean
  handlesStaleData: 'always' | 'briefly' | 'never'
}

interface QueryResult<TData, TVariables extends OperationVariables> extends Pick<UpstreamQueryResult<TData, TVariables>, 'data' | 'error' | 'refetch' | 'fetchMore'> {
  fetchingMore: boolean
  inFlight: boolean
  loading: boolean
  refetching: boolean
}

export default function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables> (query: DocumentNode | TypedDocumentNode<TData, TVariables>, options: QueryHookOptions<TData, TVariables>): QueryResult<TData, TVariables> {
  const { handlesPartialData, handlesStaleData, pollInterval, ...upstreamOptions_ } = options
  const upstreamOptions: UpstreamQueryHookOptions<TData, TVariables> = upstreamOptions_

  upstreamOptions.notifyOnNetworkStatusChange = true

  if (handlesPartialData ?? false) {
    upstreamOptions.errorPolicy = 'all'
    upstreamOptions.partialRefetch = true
    upstreamOptions.returnPartialData = true
  }

  switch (handlesStaleData) {
    case 'always':
      break
    case 'briefly':
      upstreamOptions.fetchPolicy = 'cache-and-network'
      upstreamOptions.nextFetchPolicy = 'cache-first'
      break
    case 'never':
      upstreamOptions.fetchPolicy = 'network-only'
      break
    default:
      unreachable(handlesStaleData)
  }

  const isFocused = useIsFocused()
  const result = upstream(query, upstreamOptions)

  useEffect(() => {
    if (!isFocused || pollInterval == null) return

    result.startPolling(pollInterval)

    return () => result.stopPolling()
  }, [isFocused, pollInterval])

  return useMemo(() => ({
    data: result.data,
    error: result.error,
    fetchingMore: result.networkStatus === NetworkStatus.fetchMore,
    fetchMore: result.fetchMore,
    inFlight: isNetworkRequestInFlight(result.networkStatus),
    loading: result.networkStatus === NetworkStatus.loading || result.networkStatus === NetworkStatus.setVariables,
    refetch: result.refetch,
    refetching: result.networkStatus === NetworkStatus.refetch
  }), [result.data, result.error, result.fetchMore, result.networkStatus, result.refetch])
}

import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type SieAccountInput, useListSieAccountsQuery, useSetAccountingPlanMutation } from '../../types/graphql'
import { PrimaryButton } from '../components/Buttons'
import Layout, { type Breadcrumb } from '../components/Layout'
import { Cell, Column, Row, Table } from '../components/Table'
import { ErrorField, TextField } from '../components/fields'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query ListSIEAccounts($organizationId: ID!) {
    organization (id: $organizationId) {
      id

      sieAccounts {
        id

        defaultDescription
        defaultNumber
        description
        key
        number
      }
    }
  }

  mutation SetAccountingPlan($organizationId: ID!, $accounts: [SIEAccountInput!]!) {
    setOrganizationAccountingPlan(organizationId: $organizationId, accounts: $accounts) {
      id

      sieAccounts {
        id

        defaultDescription
        defaultNumber
        description
        key
        number
      }
    }
  }
`

interface AccountingPlanFormProps {
  initialValues: SieAccountInput[]
  labels: Map<string, { description: string, number: string }>
  onSave: (accounts: SieAccountInput[]) => void
}

const AccountingPlanForm: React.FC<AccountingPlanFormProps> = ({ initialValues, labels, onSave }) => {
  const form = useForm<{ accounts: SieAccountInput[] }>({ defaultValues: { accounts: initialValues } })
  const { fields } = useFieldArray<{ accounts: SieAccountInput[] }>({ control: form.control, name: 'accounts' })

  const handleSubmit = ignoreAsync(form.handleSubmit(({ accounts }) => {
    const result: SieAccountInput[] = []

    for (const account of accounts) {
      if (account.description !== '' || account.number !== '') {
        result.push({
          description: account.description === '' ? null : account.description,
          key: account.key,
          number: account.number === '' ? null : account.number
        })
      }
    }

    onSave(result)
  }))

  return (
    <>
      <Table>
        <Column paddingHorizontal={8} />
        <Column paddingHorizontal={8} width={128} />

        <Row paddingVertical={8}>
          <Cell>
            <Text weight='bold'>Beskrivning av konto</Text>
          </Cell>
          <Cell>
            <Text weight='bold'>Kontonummer</Text>
          </Cell>
        </Row>

        {fields.map((account, index) => (
          <Row key={account.key} paddingVertical={8}>
            <Cell>
              <Controller
                control={form.control}
                name={`accounts.${index}.key`}
                render={() => <></>}
              />

              <TextField
                form={form}
                name={`accounts.${index}.description`}
                title={labels.get(unwrap(account.key))?.description ?? ''}
              />
            </Cell>

            <Cell>
              <TextField
                form={form}
                name={`accounts.${index}.number`}
                title={labels.get(unwrap(account.key))?.number ?? ''}
              />
            </Cell>
          </Row>
        ))}
      </Table>

      <Spacer height={24} />

      <HStack justifyContent='end'>
        <PrimaryButton onPress={handleSubmit} title='Spara' />
      </HStack>
    </>
  )
}

const SieAccountList: React.FC = () => {
  const [, { organizationId }] = useNavigation<'SieAccountList'>()

  const { data, loading } = useListSieAccountsQuery({ variables: { organizationId } })
  const [setAccountingPlan, { error }] = useSetAccountingPlanMutation()

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['SieReportView', { organizationId }], title: 'Bokföringsexport' }
  ], [organizationId])

  const initialValues = data?.organization?.sieAccounts?.map((account) => ({
    description: account.description ?? undefined,
    key: unwrap(account.key),
    number: account.number ?? undefined
  }))

  const labels = new Map(
    data?.organization?.sieAccounts?.map((account) => {
      return [unwrap(account.key), { description: `(${account.defaultDescription ?? ''})`, number: `(${account.defaultNumber ?? ''})` }]
    })
  )

  const handleSave = (accounts: SieAccountInput[]): void => {
    const organizationId = unwrap(data?.organization?.id)

    setAccountingPlan({ variables: { accounts, organizationId } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} title='Bokföringskonton'>
      <VStack gap={24} maxWidth={1024} padding={16}>
        <VStack gap={8} maxWidth={640}>
          <Text>Här kan du välja vilka konton som ska användas i bokföringsexporten. Det kan vara bra om du vill strukturera dina konton på annat sätt.</Text>
          <Text>Du ser också vilket konto som används om du inte angivit något annat, och dessa konton är verifierade med revisorer så att de följer god bokföringssed.</Text>
        </VStack>

        <AccountingPlanForm
          initialValues={unwrap(initialValues)}
          labels={labels}
          onSave={handleSave}
        />

        <ErrorField message={error?.message} />
      </VStack>
    </Layout>
  )
}

export default SieAccountList

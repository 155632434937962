import { Checkbox as MuCheckbox, Chip as MuChip, FormControl as MuFormControl, FormHelperText as MuFormHelperText, Input as MuInput, InputLabel as MuInputLabel, ListItemText as MuListItemText, MenuItem as MuMenuItem, Select as MuSelect, type SelectChangeEvent } from '@mui/material'
import React, { useCallback } from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'
import { HStack } from 'react-stacked'

import ErrorField from './ErrorField'

interface UncontrolledMultiSelectCheckboxProps<TFieldValues extends FieldValues, T extends string | number> {
  chipHeight?: number
  error?: boolean
  helperText?: string
  height?: number
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
  options: Array<{ value: T, title: string }>
  required?: boolean
  title: string
}

interface ControlledMultiSelectCheckboxProps<T extends string | number> {
  chipHeight?: number
  error?: boolean
  height?: number
  helperText?: string
  id: string
  onChange: (value: T[]) => void
  options: Array<{ value: T, title: string }>
  required?: boolean
  title: string
  value?: T[] | null
}

export default function MultiSelectCheckbox<T extends string | number, TFieldValues extends FieldValues> (props: ControlledMultiSelectCheckboxProps<T> | UncontrolledMultiSelectCheckboxProps<TFieldValues, T>): JSX.Element {
  if ('form' in props) {
    const error = get(props.form.formState.errors, props.name)
    return (
      <>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onChange, value } }) => (
            <MultiSelectCheckbox
              chipHeight={props.chipHeight}
              error={props.error}
              height={props.height}
              helperText={props.helperText}
              id={props.name}
              onChange={onChange}
              options={props.options}
              required={props.required}
              title={props.title}
              value={value}
            />
          )}
        />

        <ErrorField message={error?.message} />
      </>
    )
  }

  const handleChange = useCallback((ev: SelectChangeEvent<T[] | null>) => {
    // @ts-expect-error Typings indicate that this can be string/null, but that shoulnd't be possible
    props.onChange(ev.target.value)
  }, [props.onChange])

  return (
    <MuFormControl error={props.error ?? false} required={props.required ?? false}>
      <MuInputLabel id={`${props.id}-label`}>{props.title}</MuInputLabel>
      <MuSelect
        id={props.id}
        input={<MuInput id={`${props.id}-chip`} />}
        labelId={`${props.id}-label`}
        MenuProps={{
          disableScrollLock: true
        }}
        multiple
        onChange={handleChange}
        renderValue={(selected) => (
          <HStack wrap>
            {selected.map((value) => {
              const label = props.options?.find(alt => alt.value === value)
              return <MuChip key={value} label={label?.title ?? ''} style={{ height: props.chipHeight ?? 32, margin: 2 }} />
            })}
          </HStack>
        )}
        style={{ minHeight: props.height ?? 'inherit' }}
        value={props.value ?? []}
      >
        {props.options.map(option => (
          <MuMenuItem key={String(option.value)} value={option.value}>
            <MuCheckbox checked={props.value?.includes(option.value) ?? false} color='primary' />
            <MuListItemText primary={option.title} />
          </MuMenuItem>
        ))}
      </MuSelect>
      {props.helperText !== '' ? <MuFormHelperText>{props.helperText}</MuFormHelperText> : null}
    </MuFormControl>
  )
}

import unreachable from 'ts-unreachable'

import { WeekDay } from '../../types/graphql'

export type WeekDayShortForm = 'Må' | 'Ti' | 'On' | 'To' | 'Fr' | 'Lö' | 'Sö'

export default function getWeekDayShortform (weekDay: WeekDay): WeekDayShortForm {
  switch (weekDay) {
    case WeekDay.Monday:
      return 'Må'
    case WeekDay.Tuesday:
      return 'Ti'
    case WeekDay.Wednesday:
      return 'On'
    case WeekDay.Thursday:
      return 'To'
    case WeekDay.Friday:
      return 'Fr'
    case WeekDay.Saturday:
      return 'Lö'
    case WeekDay.Sunday:
      return 'Sö'
    default:
      unreachable(weekDay)
  }
}

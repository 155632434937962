import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import { type RestaurantWhiteLabelFieldsFragment, type ShortUrlInput, useCreateShortUrlMutation } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'

import { AddButton } from './Buttons'
import FormContainer from './FormContainer'
import Warning from './Warning'
import { CheckBox, Select, TextField } from './fields'

gql`
  mutation CreateShortUrl($whiteLabelId: ID!, $input: ShortUrlInput! ) {
    createShortUrl(
      whiteLabelId: $whiteLabelId,
      input: $input
    ) {
      smartLink
      target
      url
    }
  }
`

interface ShortUrlSchema extends ShortUrlInput {
  whiteLabelId: string
}

const schema: ObjectSchema<ShortUrlSchema> = yup.object({
  smartLink: yup.boolean(),
  target: yup.string().url().required(),
  whiteLabelId: yup.string().trim().min(1, 'Välj vilken whitelabel du vill att länken ska öppna upp i').required('Välj vilken whitelabel du vill att länken ska öppna upp i')
})

interface ShortUrlFormProps {
  whiteLabels: readonly RestaurantWhiteLabelFieldsFragment[]
}

const ShortUrlForm: React.FC<ShortUrlFormProps> = ({ whiteLabels }) => {
  const form = useForm<ShortUrlSchema>({
    criteriaMode: 'all',
    resolver: yupResolver(schema)
  })

  const [shortUrl, setShortUrl] = useState<string | null>(null)

  const [createShortUrl, { loading: creatingShortUrl, error }] = useCreateShortUrlMutation({
    onCompleted: (response) => setShortUrl(response.createShortUrl?.url ?? null)
  })

  const handleCreate = (input: ShortUrlSchema): void => {
    createShortUrl({
      variables: {
        whiteLabelId: input.whiteLabelId,
        input: {
          smartLink: input.smartLink,
          target: input.target
        }
      }
    }).catch(logError)
  }

  useEffect(() => {
    if (form.getValues('whiteLabelId') != null || whiteLabels.length > 1) return

    form.setValue('whiteLabelId', whiteLabels[0].id)
  }, [whiteLabels])

  return (
    <FormContainer gap={8}>
      <Text size={16} weight='bold'>Kortlänk till whitelabel</Text>

      <Controller
        control={form.control}
        name='whiteLabelId'
        render={({ field: { onChange, value } }) => {
          if (whiteLabels.length === 1) {
            const selectedWhiteLabel = whiteLabels.find(({ id }) => id === value)

            return (
              <Text size={16}>
                Länken öppnas i appen <TextStyle weight='bold'>{selectedWhiteLabel?.appName ?? selectedWhiteLabel?.label ?? 'N/A'}</TextStyle>
              </Text>
            )
          }

          return (
            <Select
              id='whiteLabelId'
              onChange={onChange}
              options={whiteLabels.map(whiteLabel => ({ title: whiteLabel.appName ?? whiteLabel.label, value: whiteLabel.id })) ?? []}
              title='Whitelabel'
              value={value}
            />
          )
        }}
      />

      <TextField
        form={form}
        name='target'
        placeholder='https://order.thelocoapp.com/restaurants/np37E7KFWX?menuType=TakeAway'
        title='Länkaddress'
      />

      <CheckBox
        form={form}
        name='smartLink'
        title='Smart länk'
      />

      {error == null
        ? null
        : <Warning message={error.message} paddingBottom={12} />}

      <HStack justifyContent='end'>
        <AddButton key='update' disabled={creatingShortUrl} onPress={ignoreAsync(form.handleSubmit(handleCreate))} title='Skapa länk' />
      </HStack>

      {shortUrl == null ? null : (
        <VStack alignItems='center' backgroundColor='green' justifyContent='center' padding={24}>
          <Text color='white' size={16}>{shortUrl}</Text>
        </VStack>
      )}
    </FormContainer>
  )
}

export default ShortUrlForm

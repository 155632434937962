import gql from 'graphql-tag'
import React, { useCallback } from 'react'
import { FlatList, type ListRenderItemInfo } from 'react-native'
import Spacer from 'react-spacer'

import { type FullPhysicalControlUnitFragment, useGetControlUnitListDataQuery } from '../../types/graphql'
import ControlUnitListItem from '../components/ControlUnitListItem'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import useNavigation from '../util/useNavigation'

const keyExtractor = (item: { id: string }): string => item.id

gql`
  fragment FullPhysicalControlUnitOutage on PhysicalControlUnitOutage {
    id

    createdAt
    restoredAt

    cashregisters {
      id
    }
  }

  fragment FullPhysicalControlUnit on PhysicalControlUnit {
    id

    address
    createdAt
    manufacturer
    model
    serialNumber
    updatedAt

    outages {
      edges {
        node {
          ...FullPhysicalControlUnitOutage
        }
      }
    }
  }

  query GetControlUnitListData {
    physicalControlUnits {
      edges {
        node {
          ...FullPhysicalControlUnit
        }
      }
    }
  }
`

const ControlUnitList: React.FC = () => {
  const [navigation] = useNavigation<'ControlUnitList'>()

  const { data, loading } = useGetControlUnitListDataQuery()
  const controlUnits = data?.physicalControlUnits?.edges.map(edge => edge.node)

  const renderItem = useCallback(({ item }: ListRenderItemInfo<FullPhysicalControlUnitFragment>): JSX.Element => {
    const handleListItemPress = (): void => {
      navigation.navigate('ControlUnitView', { controlUnitId: item.id })
    }

    return (
      <ControlUnitListItem
        item={item}
        onPress={handleListItemPress}
      />
    )
  }, [navigation])

  return (
    <Layout hideTitle loading={loading} screenType={ScreenType.List} title='Kontrollenheter'>
      <ListContainer title='Kontrollenheter'>
        <FlatList
          data={controlUnits}
          ItemSeparatorComponent={() => <Spacer height={8} />}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      </ListContainer>
    </Layout>
  )
}

export default ControlUnitList

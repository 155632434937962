import gql from 'graphql-tag'
import parsePhoneNumber from 'libphonenumber-js'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { Text } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type RestaurantInput, useCreateRestaurantMutation } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import yup, { yupResolver } from '../lib/validation'
import { flag } from '../util/flag'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateRestaurant($organizationId: ID!, $input: RestaurantInput!) {
    createRestaurant(
      organizationId: $organizationId,
      input: $input
    ) {
      id
    }
  }
`

const schema = yup.object({
  city: yup.string().trim().required('Vänligen ange ort'),
  name: yup.string().trim().min(1, 'Namn med minst ett tecken krävs').max(256, 'Namn med max 256 tecken tillåts').required(),
  phoneNumber: yup.string().test('phoneNumberIsValid', 'Telefonnumret är inte giltigt', function (phoneNumber) {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber ?? '', 'SE')
    return parsedPhoneNumber?.isValid() ?? false
  }).required(),
  postalCode: yup.string().trim().required('Vänligen ange ditt postnummer').matches(/^[0-9]{3}\s?[0-9]{2}$/, 'Postnummer anges med 5 siffror'),
  streetAddress: yup.string().trim().required('Vänligen ange din gatuadress')
})

const RestaurantCreate: React.FC = () => {
  const [navigation, { organizationId }] = useNavigation<'RestaurantCreate'>()

  const [createRestaurant, { loading: loadingCreateRestaurant, error: errorCreateRestaurant }] = useCreateRestaurantMutation({
    onCompleted: (response) => navigation.navigate('RestaurantGetStarted', { restaurantId: unwrap(response?.createRestaurant?.id) })
  })

  const form = useForm<RestaurantInput>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const phoneNumber: string = form.watch('phoneNumber') ?? ''
  const postalCode: string = form.watch('postalCode') ?? ''
  const parsedPhoneNumber = useMemo(() => parsePhoneNumber(phoneNumber, 'SE'), [phoneNumber])
  const phoneNumberIsValid = useMemo(() => parsedPhoneNumber?.isValid() ?? false, [parsedPhoneNumber])

  const handleSave = (): void => {
    createRestaurant({
      variables: {
        organizationId,
        input: { ...form.getValues(), phoneNumber: unwrap(parsedPhoneNumber?.number), postalCode: postalCode.replace(/\s/g, '') }
      }
    }).catch(logError)
  }

  return (
    <Layout hideTitle screenType={ScreenType.Form} title='Lägg till ny restaurang'>
      <FormContainer gap={20} title='Lägg till ny restaurang'>
        <TextField
          form={form}
          name='name'
          title='Restaurangnamn'
        />

        <TextField
          form={form}
          name='phoneNumber'
          title='Telefon'
        />

        {!phoneNumberIsValid ? null : (
          <Text>
            {`${flag(parsedPhoneNumber?.country) ?? ''} ${parsedPhoneNumber?.formatNational() ?? ''}`}
          </Text>
        )}

        <TextField
          form={form}
          name='streetAddress'
          title='Gatuaddress'
        />

        <TextField
          form={form}
          name='postalCode'
          title='Postnummer'
        />

        <TextField
          form={form}
          name='city'
          title='Stad'
        />

        <Spacer height={8} />

        <SubmitFormButtons
          error={errorCreateRestaurant}
          onSave={ignoreAsync(form.handleSubmit(handleSave))}
          saving={loadingCreateRestaurant}
          titleForSubmit='Skapa ny restaurang'
        />
      </FormContainer>
    </Layout>
  )
}

export default RestaurantCreate

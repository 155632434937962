import gql from 'graphql-tag'
import React, { useMemo } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'
import { Text, TextStyle, VStack } from 'react-stacked'

import { useGetRestaurantSwishEditDataQuery, useLinkSwishNumberMutation, useUnlinkSwishNumberMutation } from '../../types/graphql'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import Link from '../components/Link'
import SwishForm, { type SwishFormInput } from '../components/SwishForm'
import Warning from '../components/Warning'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantSwishEditData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      swishNumber
    }
  }

  mutation LinkSwishNumber($restaurantId: ID!, $swishNumber: String!) {
    linkSwishNumber(
      restaurantId: $restaurantId,
      swishNumber: $swishNumber
    ) {
      id

      swishNumber
    }
  }

  mutation UnlinkSwishNumber($restaurantId: ID!) {
    unlinkSwishNumber(
      restaurantId: $restaurantId
    ) {
      id

      swishNumber
    }
  }
`

const RestaurantSwishEdit: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantSwishEdit'>()

  const [linkSwishNumber, { error: errorLink, loading: saving }] = useLinkSwishNumberMutation({ onCompleted: () => navigation.navigate('RestaurantPaymentMethodList', { restaurantId }) })
  const [unlinkSwishNumber, { error: errorUnlink, loading: deleting }] = useUnlinkSwishNumberMutation({ onCompleted: () => navigation.navigate('RestaurantPaymentMethodList', { restaurantId }) })
  const { data, loading } = useGetRestaurantSwishEditDataQuery({ variables: { restaurantId } })

  const swishNumber = data?.restaurant?.swishNumber ?? null

  const handleDelete = (): void => {
    unlinkSwishNumber({ awaitRefetchQueries: true, refetchQueries: ['GetRestaurantPaymentMethodListData'], variables: { restaurantId } }).catch(logError)
  }

  const handleSave = (input: SwishFormInput): void => {
    if (input.swishNumber == null) return

    linkSwishNumber({ awaitRefetchQueries: true, refetchQueries: ['GetRestaurantPaymentMethodListData'], variables: { restaurantId, swishNumber: input.swishNumber } }).catch(logError)
  }

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantPaymentMethodList', { restaurantId }], title: 'Betalsätt' },
    { link: ['RestaurantSwishEdit', { restaurantId }], title: 'Ändra betalsätt' }
  ], [restaurantId])

  return (
    <Layout breadcrumbs={breadcrumbs} screenType={ScreenType.Form} title='Swish'>
      <VStack grow={1} maxWidth={700}>
        {swishNumber != null ? null : (
          <VStack gap={12}>
            <Text size={24}>Ställa in Swish Handel för din restaurang</Text>

            <Text>
              För att integrera Swish i vår tjänst behöver du ett "Swish Handel"-nummer. Här är en steg-för-steg-guide för att förenkla processen:
            </Text>

            <Spacer height={2} />

            <Text size={16} weight='500'>1. Kontakta din bank:</Text>
            <Text>
              För att kunna mottaga betalningar genom Swish behöver du ha ett Swish Handel-avtal med din bank. Observera att du ska välja <TextStyle weight='bold'>Swish Handel-avtal</TextStyle> och inte Swish för Företag. Varje bank har egna avtal och prissättningar som gäller specifikt för Swish Handel.
            </Text>
            <Spacer height={2} />
            <Text weight='600'>❗Ange Gastrogate AB (556577-6720) som teknisk leverantör</Text>
            <Text>- När du ingår ett avtal med din bank om Swish Handel, se till att du anger Gastrogate AB som den tekniska leverantören. Detta steg är viktigt för att vi ska kunna processa dina Swish-betalningar.</Text>
            <Text>
              Läs mer i vårt <Link title='helpcenter' url='https://help.gastrogate.io/lägg-till-swish' />!
            </Text>

            <Spacer height={2} />

            <Text size={16} weight='500'>2. Ytterligare information:</Text>
            <Text>
              Vissa banker kan kräva ytterligare information relaterad till ditt företags verksamhet:
            </Text>

            <VStack gap={2}>
              <Text>- Köp- och leveransvillkor: Detta avser din restaurangs standardpolicyer för köp och leveranser.</Text>
              <Text>- Information om ångerrätt och reklamation: Här ger du tydlig information om hur kunder kan ångra ett köp eller göra reklamationer.</Text>
              <Text>- Information om priset är inkl/exkl. moms: Klargör enkelt din prismodell när det gäller moms.</Text>
            </VStack>
            <Text>
              Om din bank begär denna information är det viktigt att notera att vi, som din tjänsteleverantör, inte dikterar dina policyer inom dessa områden. Du kommer att behöva ge dessa detaljer baserat på din restaurangs specifika villkor.
            </Text>

            <Spacer height={2} />

            <Text size={16} weight='500'>3. Använda våra standardanvändarvillkor:</Text>
            <Text>
              Om din bank kräver detaljerade villkor kan du hänvisa till våra standardiserade användarvillkor som tillhandahålls ovan. Om banken dock efterfrågar specifika uppgifter om t.ex. reklamationer eller ångerrätt, måste du utforma dessa baserat på din restaurangs interna riktlinjer.
            </Text>

            <Spacer height={2} />

            <Text size={16} weight='500'>4. Integrera med vår tjänst:</Text>
            <Text>
              När du har fått ditt "Swish Handel"-nummer, ange det i vårt formulär på Swish-inställningssidan. Detta kommer att koppla ditt Swish-konto med vår tjänst, vilket möjliggör snabba och effektiva betalningar.
            </Text>

            <Text>
              Obs: Se alltid till att ditt Swish-nummer är korrekt innan du integrerar. Ett misstag kan leda till transaktionsfel eller andra relaterade problem.
            </Text>

            <Text>
              Kom ihåg, vårt huvudmål är att förenkla betalningsprocessen för din restaurang och dina kunder. Om du stöter på några problem eller har frågor under installationen, tveka inte att kontakta vår supportavdelning. Vi är här för att hjälpa till!
            </Text>
          </VStack>
        )}

        <Spacer height={24} />

        {errorLink == null ? null : <Warning message={errorLink.message} paddingBottom={12} />}
        {errorUnlink == null ? null : <Warning message={errorUnlink.message} paddingBottom={12} />}

        {loading ? <ActivityIndicator /> : (
          <SwishForm
            deleting={deleting}
            loading={loading}
            onDelete={swishNumber == null ? undefined : handleDelete}
            onDismiss={() => navigation.navigate('RestaurantPaymentMethodList', { restaurantId })}
            onSave={handleSave}
            saving={saving}
            swishNumber={swishNumber}
          />
        )}
      </VStack>
    </Layout>
  )
}

export default RestaurantSwishEdit

import { DateTimePicker, type DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import React from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'

import ErrorField from './ErrorField'

interface UncontrolledDateTimeFieldProps<TFieldValues extends FieldValues> extends Pick<DateTimePickerProps<Date>, 'label'> {
  clearable: true
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
}

interface ControlledDateTimeFieldProps extends Pick<DateTimePickerProps<Date>, 'label'> {
  clearable: true
  value: Date | null
  onChange: (value: Date | null) => void
}

export default function DateTimeField<T extends FieldValues> (props: UncontrolledDateTimeFieldProps<T> | ControlledDateTimeFieldProps): JSX.Element {
  if ('form' in props) {
    const error = get(props.form.formState.errors, props.name)
    return (
      <>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onChange, value } }) => (
            <DateTimeField
              clearable={props.clearable}
              label={props.label}
              onChange={(value) => onChange(value?.toISOString() ?? null)}
              value={value == null ? null : new Date(value)}
            />
          )}
        />

        <ErrorField message={error?.message} />
      </>
    )
  }

  return (
    <DateTimePicker
      ampm={false}
      label={props.label}
      onChange={(date) => {
        if (date != null && !Number.isNaN(date.getTime())) {
          props.onChange(date)
        } else {
          props.onChange(null)
        }
      }}
      slotProps={{
        actionBar: {
          actions: ['cancel', 'clear', 'accept']
        }
      }}
      value={props.value}
    />
  )
}

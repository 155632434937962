import { MaterialIcons } from '@expo/vector-icons'
import * as ImagePicker from 'expo-image-picker'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'
import { Image, type ImageResizeMode, Pressable, ScrollView, View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import logError from '../../util/logError'
import notNullish from '../../util/notNullish'
import Card from '../Card'
import CropTool, { type Props } from '../CropTool'
import Dialog from '../Dialog'

import ErrorField from './ErrorField'

interface Size {
  height: number
  width: number
}

interface ImageWrapperProps {
  backgroundColor?: string
  resizeMode?: ImageResizeMode
  size: Size
  uri: string
}

const ImageWrapper: React.FC<ImageWrapperProps> = React.memo(({ backgroundColor, resizeMode, size, uri }) => {
  const source = useMemo(() => ({ uri }), [uri])
  const [info, setInfo] = useState('Laddar ...')

  useEffect(() => {
    setInfo('Laddar ...')

    Image.getSize(
      uri,
      (width, height) => setInfo(`${width}x${height}`),
      (error) => setInfo(`Fel: ${String(error.message)}`)
    )
  }, [uri])

  return (
    <VStack alignItems='center'>
      <View style={{ backgroundColor }}>
        <Image resizeMode={resizeMode} source={source} style={size} />
      </View>
      <Spacer height={8} />
      <Text>{info}</Text>
    </VStack>
  )
})

interface ImagePlaceholderProps {
  info: string
  size: Size
}

const ImagePlaceholder: React.FC<ImagePlaceholderProps> = React.memo(({ info, size }) => {
  return (
    <VStack alignItems='center'>
      <Image source={require('../../../assets/placeholder.png')} style={size} />
      <Spacer height={8} />
      <Text>{info}</Text>
    </VStack>
  )
})

type CropToolProps = Pick<Props, 'maxWidth' | 'ratio'>

interface ViewOptions {
  backgroundColor?: string
  height: number
  resizeMode?: ImageResizeMode
  width: number
}

interface EditableImageProps {
  backgroundColor?: string
  cropToolProps: CropToolProps
  format?: 'png'
  initialUri?: string | null
  onChange: (uri: string | null) => void
  onUndo?: (uri: string) => void
  title?: string | null
  value?: string | null
  viewOptions: ViewOptions
}

const EditableImage: React.FC<EditableImageProps> = ({ backgroundColor, cropToolProps, format, initialUri, onChange, onUndo, title, value, viewOptions }) => {
  const [cropImage, setCropImage] = useState<string>()

  const handleCropToolAction = useCallback((uri: string) => {
    onChange(uri)
    setCropImage(undefined)
  }, [onChange, setCropImage])

  const handleCropToolCancel = useCallback(() => {
    setCropImage(undefined)
  }, [setCropImage])

  const { height, resizeMode, width } = viewOptions

  const rightIconProps = useMemo(() => {
    if (value != null) return { name: 'delete' as const, onPress: () => onChange(null) }
    if (initialUri != null && onUndo != null) return { name: 'undo' as const, onPress: () => onUndo(initialUri) }
  }, [initialUri, onChange, onUndo, value])

  const pickImage = async (): Promise<void> => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [width, height],
        base64: false,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 1
      })
      if (result.assets?.[0] != null) {
        setCropImage(result.assets[0].uri)
      }
    } catch (error) {
      logError(error)
    }
  }

  return (
    <VStack>
      <HStack justifyContent='space-between' paddingBottom={8}>
        <VStack>
          {title == null
            ? null
            : <Text size={16}>{title}</Text>}

          {backgroundColor == null
            ? null
            : (
              <HStack alignItems='center'>
                <Text size={13}>Bakgrundsfärg: {backgroundColor}</Text>
                <View style={{ backgroundColor, width: 20, height: 12, marginLeft: 8, borderRadius: 4 }} />
              </HStack>
            )}
        </VStack>

        {rightIconProps == null ? null : <MaterialIcons {...rightIconProps} />}
      </HStack>

      {cropImage == null
        ? null
        : (
          <Dialog title={title} visible>
            <CropTool backgroundColor={backgroundColor} format={format} image={cropImage} maxWidth={cropToolProps.maxWidth} onAction={handleCropToolAction} onCancel={handleCropToolCancel} ratio={cropToolProps.ratio} />
          </Dialog>
        )}

      <Pressable
        onPress={() => {
          pickImage().catch(logError)
        }}
      >
        {value == null
          ? <ImagePlaceholder info={initialUri == null ? 'Ingen bild' : 'Bilden är borttagen'} size={{ height, width }} />
          : <ImageWrapper backgroundColor={backgroundColor} resizeMode={resizeMode} size={{ height, width }} uri={value} />}
      </Pressable>
    </VStack>
  )
}

interface ImageFieldProps<TFieldValues extends FieldValues> {
  backgroundColor?: string
  cropToolProps: CropToolProps
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState' | 'setValue'>
  format?: 'png'
  name: Path<TFieldValues>
  title: string
  viewOptions: ViewOptions
}

export default function ImageField<T extends FieldValues> (props: ImageFieldProps<T>): JSX.Element {
  return (
    <>
      <Controller
        control={props.form.control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <Card alignSelf='start'>
            <EditableImage
              backgroundColor={props.backgroundColor}
              cropToolProps={props.cropToolProps}
              format={props.format}
              onChange={onChange}
              onUndo={uri => props.form.setValue(props.name, uri as T[string], { shouldDirty: true })}
              title={props.title}
              value={value}
              viewOptions={props.viewOptions}
            />
          </Card>
        )}
      />

      <ErrorField message={get(props.form.formState.errors, props.name)?.message} />
    </>
  )
}

interface ImageSetFieldProps<TFieldValues extends FieldValues> {
  cropToolProps: CropToolProps
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState' | 'setValue'>
  name: Path<TFieldValues>
  title: string
  viewOptions: ViewOptions
}

export function ImageSetField<T extends FieldValues> (props: ImageSetFieldProps<T>): JSX.Element {
  return (
    <>
      <Controller
        control={props.form.control}
        name={props.name}
        render={({ field: { onChange, value } }: { field: { onChange: (value: string[] | null | undefined) => void, value?: string[] | null } }) => (
          <Card>
            <Text size={16}>{props.title}</Text>

            <ScrollView horizontal>
              <HStack gap={16}>
                {value?.map((uri, index) => (
                  <EditableImage
                    key={`${uri}-${index}`}
                    cropToolProps={props.cropToolProps}
                    onChange={uri => onChange(value.map((_, i) => (i === index ? uri : value[i])).filter(notNullish))}
                    onUndo={uri => onChange(value.map((_, i) => (i === index ? uri : value[i])).filter(notNullish))}
                    value={uri}
                    viewOptions={props.viewOptions}
                  />
                ))}

                <EditableImage
                  cropToolProps={props.cropToolProps}
                  onChange={uri => onChange([...(value ?? []), uri].filter(notNullish))}
                  viewOptions={props.viewOptions}
                />
              </HStack>
            </ScrollView>
          </Card>
        )}
      />

      <ErrorField message={get(props.form.formState.errors, props.name)?.message} />
    </>
  )
}

import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React from 'react'
import { Pressable, useWindowDimensions } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useGetRestaurantSalesReportListDataQuery } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import Disclaimer from '../components/Disclaimer'
import Layout from '../components/Layout'
import ListItemWrapper from '../components/ListItemWrapper'
import Warning from '../components/Warning'
import formatDateTime from '../util/formatDateTime'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

import { ReportGenerationForm } from './CashregisterReportList'

gql`
  query GetRestaurantSalesReportListData ($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      nextCashregisterReportGenerationTime
      timeZone
    }
  }
`

const SMALL_SCREEN_WIDTH_THRESHOLD = 500

const RestaurantSalesReportList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantSalesReportList'>()
  const [reportGenerationRange, setReportGenerationRange] = React.useState(10)

  const { data, error } = useGetRestaurantSalesReportListDataQuery({ notifyOnNetworkStatusChange: true, variables: { restaurantId } })

  const isSmallScreen = useWindowDimensions().width < SMALL_SCREEN_WIDTH_THRESHOLD
  const textSize = isSmallScreen ? 12 : undefined

  const reportDateTimeRanges = React.useMemo(() => {
    if (data?.restaurant?.nextCashregisterReportGenerationTime == null) {
      return []
    }

    const { nextCashregisterReportGenerationTime, timeZone } = data.restaurant

    const startDate = Temporal.Instant.from(nextCashregisterReportGenerationTime).toZonedDateTimeISO(timeZone ?? 'Europe/Stockholm')

    return Array.from({ length: reportGenerationRange }, (_, i) => {
      return {
        start: startDate.subtract({ days: i + 1 }),
        end: startDate.subtract({ days: i })
      }
    })
  }, [data?.restaurant, reportGenerationRange])

  const handleShowOlderReports = (): void => setReportGenerationRange(r => r + 10)

  return (
    <Layout title='Försäljningsrapporter'>
      <VStack gap={24} maxWidth={1024} padding={normalize(8, 20)}>
        <Disclaimer description='Bokföringsexport och Stripe har flyttats till fliken Bokföring som ligger på organisationsnivå.' />

        <Text size={24} weight='bold'>Rapporter över all försäljning</Text>

        <VStack maxWidth={640}>
          <Text>Här kan du se rapporter som komplett sammanställer den försäljning du haft idag och tidigare.</Text>
          <Spacer height={8} />
          <Text weight='bold'>Vi rekommenderar att ni använder vårt automatiska dagsavslut. Välj en tid efter stängning för att få varje dags försäljning på samma rapport. Exakta tidpunkten rapporten skapas kan vara efter den tidpunkten du väljer, men aldrig före.</Text>
        </VStack>

        <HStack gap={10} justifyContent='space-between' wrap>
          <HStack gap={10} maxWidth={490} paddingTop={20} wrap>
            <PrimaryButton
              onPress={() =>
                navigation.navigate('RestaurantSalesReportView', {
                  restaurantId,
                  dateTimeRangeEnd: reportDateTimeRanges[0].end.toInstant().toString(),
                  dateTimeRangeStart: reportDateTimeRanges[0].start.toInstant().toString()
                })}
              title='Visa dagens rapport'
            />
          </HStack>

          <VStack grow={1} maxWidth={420}>
            {data?.restaurant == null
              ? null
              : <ReportGenerationForm initialValue={data.restaurant.nextCashregisterReportGenerationTime} restaurantId={data.restaurant.id} timeZone={data.restaurant.timeZone ?? 'Europe/Stockholm'} />}
          </VStack>
        </HStack>

        {error == null ? null : <Warning message={error.message} paddingBottom={12} />}

        <Text size={24}>Dagsavslut</Text>

        <VStack gap={8}>
          {reportDateTimeRanges.slice(1).map(reportDateTimeRange => (
            <Pressable
              key={JSON.stringify(reportDateTimeRange)}
              onPress={() =>
                navigation.navigate('RestaurantSalesReportView', {
                  restaurantId,
                  dateTimeRangeEnd: reportDateTimeRange.end.toInstant().toString(),
                  dateTimeRangeStart: reportDateTimeRange.start.toInstant().toString()
                })}
            >
              <ListItemWrapper>
                <Text size={textSize}>{formatDateTime(reportDateTimeRange.start, { dateStyle: 'full' })}</Text>
                <MaterialCommunityIcons name='open-in-app' size={24} />
              </ListItemWrapper>
            </Pressable>
          ))}

          <SecondaryButton
            onPress={handleShowOlderReports}
            title='Visa äldre rapporter'
          />
        </VStack>
      </VStack>
    </Layout>
  )
}

export default RestaurantSalesReportList

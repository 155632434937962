import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'

import { useGetZReportHtmlQuery } from '../../types/graphql'
import Layout, { type Breadcrumb } from '../components/Layout'
import useNavigation from '../util/useNavigation'

gql`
  query GetZReportHtml($restaurantId: ID!, $reportId: ID!) {
    restaurant(id: $restaurantId) {
      id

      zReport(id: $reportId) {
        id

        html
      }
    }
  }
`

const CashregisterReportView: React.FC = () => {
  const [, { restaurantId, reportId }] = useNavigation<'CashregisterReportView'>()

  const [height, setHeight] = useState<number>()
  const { data, loading } = useGetZReportHtmlQuery({ variables: { restaurantId, reportId } })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['CashregisterReportList', { restaurantId }], title: 'Kassaregister' }
  ], [restaurantId])

  return (
    <Layout breadcrumbs={breadcrumbs} title='Z-Rapport'>
      <Spacer height={24} />

      {loading
        ? <ActivityIndicator />
        : (
          <iframe
            onLoad={(ev) => setHeight(ev.currentTarget.contentDocument?.documentElement.scrollHeight)}
            srcDoc={data?.restaurant?.zReport?.html ?? undefined}
            style={{ border: 0, height, maxWidth: 760, width: '100%' }}
          />
        )}
    </Layout>
  )
}

export default CashregisterReportView

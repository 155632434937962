import luhn from 'fast-luhn'
import gql from 'graphql-tag'
import React from 'react'
import { useForm } from 'react-hook-form'

import { useCreateOrganizationMutation } from '../../types/graphql'
import { PrimaryButton } from '../components/Buttons'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import OrganizationForm from '../components/OrganizationForm'
import yup, { yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

const luhnValidation = (value: string): boolean => {
  return luhn(value.replace(/-/g, ''))
}

const organizationSchema = yup.object({
  email: yup.string().email('E-postadressen du angivit är inte giltig').required('Var vänlig ange din e-postadress'),
  organizationName: yup.string().trim().min(1, 'Organisationsnamn med minst ett tecken krävs').max(50, 'Organisationsnamn med max 50 tecken tillåts').required(),
  organizationNumber: yup.string().trim()
    .required('Organisationsnummer måste anges')
    .matches(/((^\d{6}-{1}\d{4}$)|(^\d{10}$))/, 'Ett giltigt organisationsnummer skrivs med 10 siffror på formen: 551234-5678 eller 5512345678')
    .test(
      'luhn-check',
      'Ange ett giltigt organisationsnummer',
      luhnValidation
    )
})

gql`
  mutation CreateOrganization($input: OrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`

const OrganizationCreate: React.FC = () => {
  const [navigation] = useNavigation<'OrganizationCreate'>()

  const [createOrganization, { loading: loadingCreateOrganization }] = useCreateOrganizationMutation({
    onCompleted: (response) => {
      if (response.createOrganization == null) {
        logError(new Error("crateOrganization didn't return an Organization"))
      } else {
        navigation.navigate('RestaurantCreate', { organizationId: response.createOrganization.id })
      }
    }
  })

  const form = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    resolver: yupResolver(organizationSchema)
  })

  const handleSave = ignoreAsync(async () => {
    await createOrganization({
      variables: {
        input: {
          email: form.getValues().email,
          name: form.getValues().organizationName,
          organizationNumber: form.getValues().organizationNumber.replace('-', '')
        }
      }
    })
  })

  return (
    <Layout hideTitle screenType={ScreenType.Form} title='Registrera en ny organisation'>
      <FormContainer gap={20} title='Registrera en ny organisation'>
        <OrganizationForm form={form} />

        <PrimaryButton
          loading={loadingCreateOrganization}
          onPress={ignoreAsync(form.handleSubmit(handleSave))}
          title='Skapa ny organisation'
        />
      </FormContainer>
    </Layout>
  )
}

export default OrganizationCreate

import React from 'react'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { rowColor } from './Table'

export interface DropdownFields {
  grossAmount?: string | null
  orderCount?: string | null
}

interface StatisticsDropdownProps {
  fields: DropdownFields
  index: number
}

const StatisticsDropdown: React.FC<StatisticsDropdownProps> = ({ fields, index }) => {
  return (
    <VStack backgroundColor={rowColor(index)} paddingHorizontal={8} paddingVertical={16}>
      <HStack alignItems='center'>
        <Text size={12}>Beställningar:</Text>

        <Spacer width={8} />

        <Text size={12}>{fields.orderCount}</Text>
      </HStack>

      <HStack alignItems='center'>
        <Text size={12}>Försäljning:</Text>

        <Spacer width={8} />

        <Text size={12}>{fields.grossAmount}</Text>
      </HStack>
    </VStack>
  )
}

export default StatisticsDropdown

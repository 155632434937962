import React from 'react'
import { StyleSheet } from 'react-native'
import { VStack } from 'react-stacked'

interface DividerProps {
  spacing?: number
}

const Divider: React.FC<DividerProps> = ({ spacing = 8 }) => (
  <VStack paddingVertical={spacing}>
    <VStack
      backgroundColor='#6665'
      height={StyleSheet.hairlineWidth}
    />
  </VStack>
)

export default Divider

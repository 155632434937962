import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'
import { Pressable } from 'react-native'
import { HStack, Text } from 'react-stacked'

import { ACCENT_COLOR } from '../../lib/color'

import ErrorField from './ErrorField'

interface UncontrolledRadioButtonProps<TFieldValues extends FieldValues> {
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
  title?: string
}

interface ControlledRadioButtonProps {
  checked: boolean
  onPress?: () => void
  title?: string
}

export default function RadioButton<T extends FieldValues> (props: UncontrolledRadioButtonProps<T> | ControlledRadioButtonProps): JSX.Element {
  if ('form' in props) {
    return (
      <>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onChange, value } }) => (
            <RadioButton
              checked={value}
              onPress={() => onChange(!(value as boolean))}
              title={props.title}
            />
          )}
        />

        <ErrorField message={get(props.form.formState.errors, props.name)?.message} />
      </>
    )
  }

  return (
    <Pressable accessibilityRole='radio' accessibilityState={{ checked: props.checked }} onPress={props.onPress}>
      <HStack alignItems='center' gap={10}>
        <MaterialIcons
          color={props.checked ? ACCENT_COLOR : '#bfbfbf'}
          name={props.checked ? 'radio-button-checked' : 'radio-button-unchecked'}
          size={24}
        />

        <Text weight='bold'>{props.title}</Text>
      </HStack>
    </Pressable>
  )
}

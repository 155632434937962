import React from 'react'
import { View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, VStack } from 'react-stacked'

const MINIMUM_TARGET_SIZE = 48
const SIZE = 28
const INSET = Math.round((MINIMUM_TARGET_SIZE - SIZE) / 2)

const Dot: React.FC<{ color: string, size: number }> = ({ color, size }) => (
  <VStack
    backgroundColor={color}
    borderRadius={size / 2}
    height={size}
    width={size}
  />
)

interface DragIndicatorProps {
  color: string
  size: number
}

const DragIndicator: React.FC<DragIndicatorProps> = ({ color, size }) => {
  let dotSize = 3
  if (size < 24) {
    dotSize = 2
  } else if (size === 24) {
    dotSize = 3
  } else if (size > 24) {
    dotSize = 4
  }

  return (
    <HStack alignItems='center' height={size} justifyContent='center' width={size}>
      <View>
        <Dot color={color} size={dotSize} />
        <Spacer height={1} />
        <Dot color={color} size={dotSize} />
        <Spacer height={1} />
        <Dot color={color} size={dotSize} />
      </View>
      <Spacer width={1} />
      <View>
        <Dot color={color} size={dotSize} />
        <Spacer height={1} />
        <Dot color={color} size={dotSize} />
        <Spacer height={1} />
        <Dot color={color} size={dotSize} />
      </View>
    </HStack>
  )
}

const DragHandle: React.FC = () => (
  <View hitSlop={{ bottom: INSET, left: INSET, right: INSET, top: INSET }}>
    <DragIndicator color='#517fa4' size={SIZE} />
  </View>
)

export default DragHandle

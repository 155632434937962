import { type ApolloError } from '@apollo/client'
import React from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'

import { type DiscountPatch, type FullDiscountFragment, MenuType } from '../../types/graphql'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { CheckBox, DateTimeField, MultiSelectCheckbox } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'

import { type FormInput as DiscountCreateFormInput, menuTypeSelectOptions } from './DiscountCreateForm'
import FormContainer from './FormContainer'
import Select from './Select'
import { TextField } from './TextField'
import Warning from './Warning'
import { OpeningHoursPeriodsField, openingHoursPeriodsSchema } from './fields/OpeningHoursPeriodsField'

export const discountValidFromSchema = yup.string()
  .test(
    'mustBeBefore',
    "Måste vara före 'Giltig till och med'",
    function (value) {
      const validUntil = this.parent.validUntil
      return value == null || validUntil == null || value < validUntil
    }
  )
  .nullable()

export const discountValidUntilSchema = yup.string()
  .test(
    'mustBeFuture',
    'Måste vara i framtiden',
    value => value == null ? true : new Date(value) > new Date()
  )
  .nullable()

type FormInput = Pick<
  DiscountCreateFormInput,
  | 'description'
  | 'isDisabled'
  | 'menuType'
  | 'openingHours'
  | 'requiresGuestGroupIds'
  | 'requiresRestaurantIds'
  | 'title'
  | 'validFrom'
  | 'validUntil'
>

const discountEditSchema: ObjectSchema<FormInput> = yup.object({
  description: yup.string().trim().max(200),
  isDisabled: yup.boolean(),
  menuType: yup.mixed<MenuType>().oneOf([MenuType.Delivery, MenuType.EatIn, MenuType.TakeAway]).nullable(),
  openingHours: openingHoursPeriodsSchema.nullable(),
  requiresGuestGroupIds: yup.array().of(yup.string().required()).nullable(),
  requiresRestaurantIds: yup.array().of(yup.string().required()).nullable(),
  title: yup.string().trim().max(100),
  validFrom: discountValidFromSchema,
  validUntil: discountValidUntilSchema
})

interface DiscountEditFormProps {
  discount: FullDiscountFragment
  error: ApolloError | null | undefined
  guestGroupOptions?: Array<{ title: string, value: string }>
  onCancel: () => void
  onDelete: () => void
  onSave: (patch: DiscountPatch) => void
  restaurantOptions?: Array<{ title: string, value: string }>
  saving?: boolean
}

const DiscountEditForm: React.FC<DiscountEditFormProps> = ({ discount, error, guestGroupOptions, onCancel, onDelete, onSave, restaurantOptions, saving }) => {
  const form = useForm<FormInput>({
    resolver: yupResolver(discountEditSchema),
    values: discountEditSchema.cast({
      ...discount,
      description: discount.description ?? undefined,
      requiresGuestGroupIds: discount.requiresGuestGroups?.map(guestGroup => guestGroup.id),
      requiresRestaurantIds: discount.requiresRestaurants?.map(restaurant => restaurant.id),
      title: discount.title ?? undefined
    }, { stripUnknown: true })
  })

  const { isDirty, isSubmitting } = form.formState

  return (
    <FormContainer gap={12}>
      <CheckBox
        form={form}
        name='isDisabled'
        title='Inaktiverad'
      />

      <TextField
        estimatedNumberOfCharacters={24}
        form={form}
        name='title'
        title='Titel'
      />

      <TextField
        form={form}
        name='description'
        title='Beskrivning'
      />

      <Spacer height={12} />

      <DateTimeField
        clearable
        form={form}
        label='Giltig från och med'
        name='validFrom'
      />

      <DateTimeField
        clearable
        form={form}
        label='Giltig till och med'
        name='validUntil'
      />

      <Select
        form={form}
        name='menuType'
        options={menuTypeSelectOptions}
        title='Menytyp'
      />

      <OpeningHoursPeriodsField
        form={form}
        name='openingHours'
        title='Tillgänglighet'
      />

      {guestGroupOptions == null ? null : (
        <MultiSelectCheckbox
          form={form}
          name='requiresGuestGroupIds'
          options={guestGroupOptions}
          title='Lojalitetsgrupp'
        />
      )}

      {restaurantOptions == null ? null : (
        <MultiSelectCheckbox
          form={form}
          name='requiresRestaurantIds'
          options={restaurantOptions}
          title='Restauranger'
        />
      )}

      {error?.message == null ? null : <Warning message={error.message} paddingBottom={12} />}

      <SubmitFormButtons
        disableDeleteButton={isSubmitting}
        disableSaveButton={!isDirty}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(onSave))}
        saving={isSubmitting || saving}
      />
    </FormContainer>
  )
}

export default DiscountEditForm

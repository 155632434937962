/* eslint-disable @typescript-eslint/no-dynamic-delete */
import React from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { HStack, VStack } from 'react-stacked'

import { type WhiteLabelFieldsFragment } from '../../../types/graphql'
import { ImageField } from '../fields'

import { type WhiteLabelFormPatch } from './WhiteLabelForm'

const Images: React.FC<{ form: UseFormReturn<WhiteLabelFormPatch>, whiteLabel: WhiteLabelFieldsFragment }> = ({ form, whiteLabel }) => {
  const drawerBackgroundColor = form.watch('theme.drawerBackgroundColor') ?? undefined
  const menuTypeSelectionBackgroundColor = form.watch('theme.menuTypeSelectionBackgroundColor') ?? undefined

  return (
    <HStack gap={16} wrap>
      <VStack gap={16}>
        <ImageField
          cropToolProps={{ maxWidth: 2048, ratio: 1 }}
          form={form}
          format='png'
          name='appIconUrl'
          title='Appikonen'
          viewOptions={{ height: 300, width: 300 }}
        />

        <ImageField
          cropToolProps={{ maxWidth: 1024, ratio: 1024 / 500 }}
          form={form}
          name='androidFeatureGraphicUrl'
          title='Android feature graphic'
          viewOptions={{ height: 300 / 1024 * 500, width: 300 }}
        />

        <ImageField
          backgroundColor={drawerBackgroundColor}
          cropToolProps={{ maxWidth: 4000, ratio: 300 / 140 }}
          form={form}
          name='theme.drawerLogoImage'
          title='Logo högst upp i sidomeny'
          viewOptions={{ height: 140, resizeMode: 'contain', width: 300 }}
        />
      </VStack>

      <ImageField
        cropToolProps={{ maxWidth: 4000, ratio: 1 }}
        form={form}
        format='png'
        name='appSplashUrl'
        title='Splash screen'
        viewOptions={{ height: 400, width: 400 }}
      />

      <ImageField
        backgroundColor={menuTypeSelectionBackgroundColor}
        cropToolProps={{ maxWidth: 4000, ratio: 0.5 }}
        form={form}
        name='theme.menuTypeSelectionBackgroundImage'
        title='Bakgrunden för menyvalet'
        viewOptions={{ height: 400, width: 200 }}
      />

      <ImageField
        cropToolProps={{ maxWidth: 4000, ratio: 0.5 }}
        form={form}
        name='theme.listBackgroundImage'
        title='Bakgrunden för restauranglistan'
        viewOptions={{ height: 400, width: 200 }}
      />
    </HStack>
  )
}

export default Images

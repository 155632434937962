import * as ImagePicker from 'expo-image-picker'
import React, { useState } from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'
import { Pressable } from 'react-native'
import { HStack, VStack } from 'react-stacked'

import logError from '../../util/logError'
import { DeleteButton } from '../Buttons'
import CropTool from '../CropTool'
import ErrorField from '../fields/ErrorField'

interface UncontrolledEditProductImageProps<TFieldValues extends FieldValues> {
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
}

interface ControlledEditProductImageProps {
  image: string | null
  onEdit: (imageUrl: string | null) => void
}

export default function EditProductImage<T extends FieldValues> (props: UncontrolledEditProductImageProps<T> | ControlledEditProductImageProps): JSX.Element {
  if ('form' in props) {
    const error = get(props.form.formState.errors, props.name)

    return (
      <>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onChange, value } }) => (
            <EditProductImage
              image={value ?? null}
              onEdit={onChange}
            />
          )}
        />

        <ErrorField message={error?.message} />
      </>
    )
  }

  const [showCropTool, setShowCropTool] = useState(false)
  const [cropImage, setCropImage] = useState('')

  const pickImage = async (): Promise<void> => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1
      })
      if (result.assets?.[0] != null) {
        setCropImage(result.assets[0].uri)
        setShowCropTool(true)
      }
    } catch (error) {
      logError(error)
    }
  }

  if (showCropTool) {
    return (
      <HStack>
        <CropTool
          image={cropImage}
          maxWidth={1440}
          onAction={(croppedImage) => {
            setShowCropTool(false)
            setCropImage('')
            props.onEdit(croppedImage)
          }}
          onCancel={() => setShowCropTool(false)}
          ratio={1.5}
        />
      </HStack>
    )
  }

  return (
    <VStack>
      <Pressable
        onPress={() => {
          pickImage().catch(logError)
        }}
      >
        <img
          src={props.image == null ? require('../../../assets/placeholder.png') : props.image}
          style={{ width: '100%', height: 'auto', borderRadius: 5, border: '1px solid #999', display: 'block' }}
        />
      </Pressable>

      {props.image == null
        ? null
        : (
          <HStack justifyContent='end' paddingTop={12}>
            <DeleteButton onPress={() => props.onEdit(null)} title='Ta bort bild' />
          </HStack>
        )}
    </VStack>
  )
}

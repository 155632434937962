import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'

import { GetMenuAlternativeGroupListDataDocument, type MenuAlternativeGroupInput, useDeleteAlternativeGroupMutation, useGetEditAlternativeGroupDataQuery, useUpdateAlternativeGroupMutation } from '../../types/graphql'
import AlternativeGroupForm from '../components/AlternativeGroupForm'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullMenuAlternativeGroup on MenuAlternativeGroup {
    id

    forceChoice
    isCountable
    maxChoices
    minChoices
    name

    alternativeItems {
      countableUsage
      isDefault
      name

      addonProduct {
        id

        name

        properties {
          id

          stateId
          description
          imageUrl
          isActive
          price

          reportGroup {
            ...FullMenuReportGroup
          }
        }
      }
    }
  }

  query GetEditAlternativeGroupData($menuAlternativeGroupId: ID!, $restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        id

        alternativeGroup(id: $menuAlternativeGroupId) {
          ...FullMenuAlternativeGroup
        }
      }
    }
  }

  mutation DeleteAlternativeGroup($menuAlternativeGroupId: ID!, $restaurantId: ID!) {
    deleteMenuAlternativeGroup(
      menuAlternativeGroupId: $menuAlternativeGroupId
      restaurantId: $restaurantId
    )
  }

  mutation UpdateAlternativeGroup($menuAlternativeGroupId: ID!, $patch: MenuAlternativeGroupPatch!, $restaurantId: ID!) {
    updateMenuAlternativeGroup(
      menuAlternativeGroupId: $menuAlternativeGroupId
      patch: $patch
      restaurantId: $restaurantId
    ) {
      ...FullMenuAlternativeGroup
    }
  }
`

const MenuAlternativeGroupEdit: React.FC = () => {
  const [navigation, { menuAlternativeGroupId, restaurantId }] = useNavigation<'MenuAlternativeGroupEdit'>()

  const { data, loading } = useGetEditAlternativeGroupDataQuery({ variables: { menuAlternativeGroupId, restaurantId } })

  const [updateAlternativeGroup, { loading: updating }] = useUpdateAlternativeGroupMutation({
    onCompleted: () => navigation.navigate('MenuAlternativeGroupList', { restaurantId }),
    onError: (error) => logAndShowError(error)
  })

  const [deleteAlternativeGroup, { error, loading: deleting }] = useDeleteAlternativeGroupMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('MenuAlternativeGroupList', { restaurantId }),
    onError: (error) => logAndShowError(error),
    refetchQueries: [{ query: GetMenuAlternativeGroupListDataDocument, variables: { restaurantId } }]
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['MenuAlternativeGroupList', { restaurantId }], title: 'Tillägg och Alternativgrupper' }
  ], [restaurantId])

  const handleDelete = ignoreAsync(async () => {
    await deleteAlternativeGroup({ variables: { menuAlternativeGroupId, restaurantId } })
  })

  const handleDismiss = (): void => {
    navigation.navigate('MenuAlternativeGroupList', { restaurantId })
  }

  const handleSave = ignoreAsync(async (patch: MenuAlternativeGroupInput) => {
    await updateAlternativeGroup({ variables: { menuAlternativeGroupId, patch, restaurantId } })
  })

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title={data?.restaurant?.menu?.alternativeGroup?.name}>
      <VerificationDialog
        errorMessage={error?.message}
        loading={deleting ?? false}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={`Vill du permanent ta bort alternativgruppen ${data?.restaurant?.menu?.alternativeGroup?.name ?? ''}?`}
        title='Radera alternativgrupp'
      />

      <AlternativeGroupForm
        initialValues={data?.restaurant?.menu?.alternativeGroup}
        onDelete={() => setShowDeleteDialog(true)}
        onDismiss={handleDismiss}
        onSave={handleSave}
        restaurantId={restaurantId}
        saving={updating}
      />
    </Layout>
  )
}

export default MenuAlternativeGroupEdit

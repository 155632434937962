import React from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'

import { TextField } from '../TextField'

function parse (value: string): '' | number | null {
  if (value.trim() === '') return null
  const intValue = Number.parseInt(value, 10)
  return Number.isNaN(intValue) ? null : intValue
}

interface IntegerFieldProps<TFieldValues extends FieldValues> {
  /**
   *  The estimated number of characters is used to estimate the width of the text field. This is useful when the text field is used in a form with multiple inputs. As per the GOV.UK Design System, the width of the text field will be adjusted based on the estimated number of characters that you think will be used in the field.
   */
  estimatedNumberOfCharacters?: number
  /** An empty string makes the input initially empty. Remember to handle the empty string if one is used. */
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
  title: string
}

export default function IntegerField<T extends FieldValues> (props: IntegerFieldProps<T>): JSX.Element {
  const errorMessage = get(props.form.formState.errors, props.name)?.message

  return (
    <Controller
      control={props.form.control}
      name={props.name}
      render={({ field: { onBlur, onChange, value } }) => (
        <TextField
          errorMessage={errorMessage}
          estimatedNumberOfCharacters={props.estimatedNumberOfCharacters}
          inputMode='numeric'
          onBlur={onBlur}
          onChange={(input) => onChange(parse(input))}
          title={props.title}
          value={value}
        />
      )}
    />
  )
}

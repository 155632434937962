import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useAcceptFortnoxAccountingImportIntegrationTermsOfServiceMutation, useGetOrganizationFortnoxAccountingImportViewDataQuery, useUnlinkFortnoxAccountingImportAccountMutation } from '../../types/graphql'
import { DeleteButton, PrimaryButton, SecondaryButton } from '../components/Buttons'
import Divider from '../components/Divider'
import Layout, { ScreenType } from '../components/Layout'
import Link from '../components/Link'
import ListContainer from '../components/ListContainer'
import SetupContainer from '../components/SetupContainer'
import VerificationDialog from '../components/VerificationDialog'
import Warning from '../components/Warning'
import { ACCENT_COLOR } from '../lib/color'
import logError from '../util/logError'
import openLink from '../util/openLink'
import useNavigation from '../util/useNavigation'

gql`
  query GetOrganizationFortnoxAccountingImportViewData($organizationId: ID!, $redirectUrl: URL!) {
    organization(id: $organizationId) {
      id

      fortnoxAccountingImportEnabled
      fortnoxAccountingImportAuthorizationUrl(redirectUrl: $redirectUrl)
      hasFortnoxAccountingImportAccess
      hasFortnoxAccountingImportCredentials

      restaurants {
        id

        name
      }
    }
  }

  mutation AcceptFortnoxAccountingImportIntegrationTermsOfService($organizationId: ID!) {
    acceptFortnoxAccountingImportIntegrationTermsOfService(organizationId: $organizationId) {
      id

      hasFortnoxAccountingImportAccess
    }
  }

  mutation UnlinkFortnoxAccountingImportAccount($organizationId: ID!) {
    unlinkFortnoxAccountingImportAccount(organizationId: $organizationId) {
      id

      fortnoxAccountingImportEnabled
      hasFortnoxAccountingImportAccess
      hasFortnoxAccountingImportCredentials
    }
  }
`

const OrganizationFortnoxAccountingImportView: React.FC = () => {
  const [navigation, { organizationId }] = useNavigation<'OrganizationFortnoxAccountingImportView'>()
  const [showUnlinkVerificationDialog, setShowUnlinkVerificationDialog] = useState(false)
  const redirectUrl = window.location.protocol + '//' + window.location.host + '/hooks/v1/fortnox-accounting-import-complete'

  const { data, loading } = useGetOrganizationFortnoxAccountingImportViewDataQuery({ variables: { organizationId, redirectUrl } })
  const [acceptTermsOfService, { error: acceptTermsOfServiceError, loading: acceptingTermsOfService }] = useAcceptFortnoxAccountingImportIntegrationTermsOfServiceMutation()
  const [unlinkFortnoxAccountingImportAccount, { error: unlinkError, loading: unlinking }] = useUnlinkFortnoxAccountingImportAccountMutation({
    onCompleted: () => {
      setShowUnlinkVerificationDialog(false)
    }
  })

  const restaurantId = data?.organization?.restaurants?.[0]?.id ?? null

  const hasFortnoxAccountingImportAccess = data?.organization?.hasFortnoxAccountingImportAccess ?? false
  const hasFortnoxAccountingImportCredentials = data?.organization?.hasFortnoxAccountingImportCredentials ?? false
  const fortnoxAccountingImportEnabled = data?.organization?.fortnoxAccountingImportEnabled ?? false
  const fortnoxAccountingImportAuthorizationUrl = data?.organization?.fortnoxAccountingImportAuthorizationUrl ?? ''

  const handleAcceptPress = (): void => {
    acceptTermsOfService({ variables: { organizationId } }).catch(logError)
  }

  const handleBackPress = (): void => {
    if (navigation.canGoBack()) {
      navigation.pop()

      return
    }

    navigation.navigate('SieReportView', { organizationId })
  }

  const handleUnlink = (): void => {
    unlinkFortnoxAccountingImportAccount({ variables: { organizationId } }).catch(logError)
  }

  return (
    <Layout breadcrumbs={[{ link: ['SieReportView', { organizationId }], title: 'Bokföring' }]} hideTitle loading={loading} screenType={ScreenType.List} title='Fortnox Bokföring'>
      <VerificationDialog
        callToActionLabel='Ta bort koppling'
        errorMessage={unlinkError?.message}
        loading={unlinking}
        onDelete={handleUnlink}
        onDismiss={() => setShowUnlinkVerificationDialog(false)}
        open={showUnlinkVerificationDialog}
        prompt='Är du säker på att du vill ta bort kopplingen till Fortnox?'
        title='Ta bort koppling till Fortnox'
      />

      <VStack gap={12}>
        {restaurantId == null ? null : (
          <HStack>
            <SecondaryButton
              icon='chevron-left'
              onPress={handleBackPress}
              title='Tillbaka'
            />
          </HStack>
        )}

        <ListContainer title='Fortnox Bokföring'>
          <SetupContainer
            disabled={hasFortnoxAccountingImportAccess}
            disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
          >
            <HStack alignItems='center' gap={8}>
              <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                <Text color='white' size={16}>1</Text>
              </VStack>

              <Text color={ACCENT_COLOR} size={24}>Acceptera Terms of Service</Text>
            </HStack>

            <Text size={12}>
              <Link title='Fortnox' url='https://fortnox.se/' /> är ett bokföringsprogram som används av över 100 000 företag i Sverige.
            </Text>

            <Text size={12}>För att kunna använda integrationen med Fortnox Bokföring måste du acceptera våra Terms of Service.</Text>
            <Text size={12}>Denna tjänst möjliggör</Text>
            <Text paddingLeft={8} size={12}>☑️ Synkronisering av bokföringsexport till Fortnox för följande restauranger:</Text>
            {data?.organization?.restaurants?.map((restaurant) => <Text key={restaurant.id} paddingLeft={24} size={12}>• {restaurant.name}</Text>)}

            <Text size={12}>
              Genom att acceptera villkoren för användandet av denna funktion bekräftar du att du läst våra <Link title='Terms of Service' url='https://gastrogate.notion.site/Terms-of-Service-Loco-d501714a78054cb5af6315c7ca0df83d' /> och att priset sätts i enlighet med rådande <Link title='prislista på gastrogate.io' url='https://gastrogate.io/fortnox/' />.
            </Text>

            <Spacer height={12} />

            {acceptTermsOfServiceError?.message == null ? null : <Warning message={acceptTermsOfServiceError.message} paddingBottom={12} />}

            <PrimaryButton
              loading={acceptingTermsOfService}
              onPress={handleAcceptPress}
              title='Acceptera användarvillkor'
            />
          </SetupContainer>

          {!hasFortnoxAccountingImportAccess ? null : (
            <SetupContainer
              disabled={hasFortnoxAccountingImportCredentials}
              disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
            >
              <HStack alignItems='center' gap={8}>
                <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                  <Text color='white' size={16}>2</Text>
                </VStack>

                <Text color={ACCENT_COLOR} size={24}>Länka Loco och Fortnox</Text>
              </HStack>

              <Text size={12}>
                För att kunna använda integrationen med Fortnox Bokföring måste du länka ditt Fortnox konto med Loco. Med detta kommer en verifikationsserie F (Kassa) skapas i Fortnox som all bokföring kommer att kopplas till. Om den verifikationsserien redan existerar så kopplas det till den.
              </Text>

              <Spacer height={12} />

              <PrimaryButton
                icon='open-in-new'
                onPress={() => openLink(fortnoxAccountingImportAuthorizationUrl)}
                title='Påbörja länkning mellan Loco och Fortnox'
              />
            </SetupContainer>
          )}

          {!fortnoxAccountingImportEnabled ? null : (
            <SetupContainer>
              <HStack alignItems='center' gap={8}>
                <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                  <Text color='white' size={16}>3</Text>
                </VStack>

                <Text color={ACCENT_COLOR} size={24}>Synkronisering av Fortnox Bokföring klar!</Text>
              </HStack>

              <Text size={12}>
                Din bokföring sköts nu automatiskt och all rapportering som normalt kommer med i SIE-filen kommer föras över till Fortnox i samband med varje dagsavslut som ställs in i Rapporter→Försäljningsstatistik.
              </Text>

              <Divider />

              <Text color={ACCENT_COLOR} size={16}>Borttagning av Fortnox</Text>

              <Text size={12}>
                Vill du ändra kontot som du länkar din Fortnox mot? Eller vill du helt enkelt ta bort din koppling till Fortnox? Tänk på att bokföring inte skickas retroaktivt om Fortnoxintegrationen är inaktiverad, så denna tjänst måste vara aktiv vid tidpunkten för dagsavslut för att det automatiskt ska föras över till Fortnox. Vill du fortsätta? Klicka på knappen nedan för att ta bort din koppling till Fortnox.
              </Text>

              <HStack justifyContent='end'>
                <DeleteButton
                  icon='delete'
                  onPress={() => setShowUnlinkVerificationDialog(true)}
                  title='Ta bort koppling till Fortnox'
                />
              </HStack>

              <Divider />

              <Text color={ACCENT_COLOR} size={16}>Säg upp avtalet för Fortnox Bokföring</Text>

              <Text size={12}>
                Vill du säga upp avtalet gällande Fortnox Bokföring? Kontakta oss på service@gastrogate.io
              </Text>
            </SetupContainer>
          )}
        </ListContainer>
      </VStack>
    </Layout>
  )
}

export default OrganizationFortnoxAccountingImportView

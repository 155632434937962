import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import Spacer from 'react-spacer'

import { useGetXReportHtmlQuery } from '../../types/graphql'
import Layout, { type Breadcrumb } from '../components/Layout'
import useNavigation from '../util/useNavigation'

gql`
  query GetXReportHtml($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      xReport {
        id

        html
      }
    }
  }
`

const CashregisterReportView: React.FC = () => {
  const [, { restaurantId }] = useNavigation<'CashregisterReportPreview'>()

  const [height, setHeight] = useState<number>()
  const { data, loading } = useGetXReportHtmlQuery({ variables: { restaurantId } })

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['CashregisterReportList', { restaurantId }], title: 'Kassaregister' }
  ], [restaurantId])

  return (
    <Layout breadcrumbs={breadcrumbs} title='X-Rapport'>
      <Spacer height={24} />

      {loading
        ? <ActivityIndicator />
        : (
          <iframe
            onLoad={(ev) => setHeight(ev.currentTarget.contentDocument?.documentElement.scrollHeight)}
            srcDoc={data?.restaurant?.xReport?.html ?? undefined}
            style={{ border: 0, height, maxWidth: 760, width: '100%' }}
          />
        )}
    </Layout>
  )
}

export default CashregisterReportView

import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { HStack, Text, VStack } from 'react-stacked'
import { type ObjectSchema } from 'yup'

import { GetWhiteLabelGuestGroupMemberViewDataDocument, type GetWhiteLabelGuestGroupMemberViewDataQuery, type GetWhiteLabelGuestGroupMemberViewDataQueryVariables, InvitationStatus, useDeleteWhiteLabelGuestMutation, useGetGuestReceiptSummaryDataQuery, useResendWhiteLabelGuestInvitationMutation, useUpdateWhiteLabelGuestMutation } from '../../types/graphql'
import { RedoButton } from '../components/Buttons'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import Select from '../components/Select'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import VerificationDialog from '../components/VerificationDialog'
import Warning from '../components/Warning'
import Heading from '../components/atoms/Heading'
import yup, { yupResolver } from '../lib/validation'
import formatCurrency from '../util/formatCurrency'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'
import useQuery from '../util/useQuery'

import DashboardContainer from './DashboardContainer'
import ReceiptList from './ReceiptList'

const year = new Intl.DateTimeFormat('sv-SE', { year: 'numeric' })
const dayMonth = new Intl.DateTimeFormat('sv-SE', { month: 'long', day: 'numeric' })

gql`
  query GetWhiteLabelGuestGroupMemberViewData($whiteLabelId: ID!, $guestGroupId: ID!, $memberId: ID!, $cursor: String) {
    whiteLabel(id: $whiteLabelId) {
      id

      guest(id: $memberId) {
        id

        amountSpent
        createdAt
        email
        invitationStatus
        name
        orderCount

        group {
          id

          name
        }

        orders(first: 10, after: $cursor) {
          edges {
            node {
              ...ReceiptListOrder
            }
          }

          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }

      guestGroup(id: $guestGroupId) {
        id

        name

        members(first: 25) {
          edges {
            node {
              id

              amountSpent
              email
              invitationStatus
              name
              orderCount
            }
          }
        }
      }

      guestGroups (first: 10000) {
        edges {
          node {
            id

            memberCount
            name
          }
        }
      }
    }
  }

  query GetGuestReceiptSummaryData ($whiteLabelId: ID!, $memberId: ID!, $orderId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      guest(id: $memberId) {
        id

        order (id: $orderId) {
          ...FullOrder
        }
      }
    }
  }

  mutation DeleteWhiteLabelGuest($whiteLabelId: ID!, $whiteLabelGuestId: ID!) {
    deleteWhiteLabelGuest(whiteLabelId: $whiteLabelId, whiteLabelGuestId: $whiteLabelGuestId) {
      id
    }
  }

  mutation ResendWhiteLabelGuestInvitation($whiteLabelId: ID!, $whiteLabelGuestId: ID!) {
    resendWhiteLabelGuestInvitation(whiteLabelId: $whiteLabelId, whiteLabelGuestId: $whiteLabelGuestId) {
      id
    }
  }

  mutation UpdateWhiteLabelGuest($whiteLabelId: ID!, $whiteLabelGuestId: ID!, $patch: WhiteLabelGuestPatch!) {
    updateWhiteLabelGuest(whiteLabelId: $whiteLabelId, whiteLabelGuestId: $whiteLabelGuestId, patch: $patch) {
      id

      amountSpent
      email
      invitationStatus
      name
      orderCount

      group {
        id

        name
      }
    }
  }
`

interface FormInput {
  email: string
  groupId: string
  name: string
}

const schema: ObjectSchema<FormInput> = yup.object().shape({
  email: yup.string().email('Ogiltig e-postadress').trim().required('E-postadress är obligatorisk'),
  groupId: yup.string().required('Grupp är obligatoriskt'),
  name: yup.string().trim().required('Namn är obligatoriskt')
})

const WhiteLabelGuestGroupMemberView: React.FC = () => {
  const [navigation, { whiteLabelId, guestGroupId, memberId }] = useNavigation<'WhiteLabelGuestGroupMemberView'>()

  const { data, error, fetchingMore, fetchMore, loading, refetch, refetching } = useQuery<GetWhiteLabelGuestGroupMemberViewDataQuery, GetWhiteLabelGuestGroupMemberViewDataQueryVariables>(GetWhiteLabelGuestGroupMemberViewDataDocument, { handlesStaleData: 'briefly', variables: { whiteLabelId, guestGroupId, memberId } })

  const [deleteWhiteLabelGuest, { error: deleteError, loading: deleting }] = useDeleteWhiteLabelGuestMutation()
  const [resendWhiteLabelGuestInvitation, { error: resendError, loading: resending }] = useResendWhiteLabelGuestInvitationMutation()
  const [updateWhiteLabelGuest, { loading: saving }] = useUpdateWhiteLabelGuestMutation()

  const form = useForm<FormInput>({
    criteriaMode: 'all',
    mode: 'onTouched',
    resolver: yupResolver(schema),
    values: data?.whiteLabel?.guest == null ? undefined : schema.cast({ ...data.whiteLabel.guest, groupId: data.whiteLabel.guest?.group?.id ?? '' }, { stripUnknown: true })
  })

  const orders = data?.whiteLabel?.guest?.orders?.edges?.map(edge => edge.node) ?? []

  const emailHasChanged = form.watch('email') !== data?.whiteLabel?.guest?.email
  const [inviteResent, setInviteResent] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showReceiptSummary, setShowReceiptSummary] = useState<string | null>(null)

  const { data: receiptSummaryData } = useGetGuestReceiptSummaryDataQuery({
    skip: showReceiptSummary == null,
    variables: showReceiptSummary == null ? undefined : { whiteLabelId, memberId, orderId: showReceiptSummary }
  })

  const member = data?.whiteLabel?.guest
  const createdAt = member?.createdAt == null ? null : new Date(member.createdAt)

  const guestGroupOptions = data?.whiteLabel?.guestGroups?.edges.map(({ node }) => ({ title: `${node.name ?? 'n/a'} , medlemmar: ${node.memberCount?.toString() ?? ''}`, value: node.id })) ?? []

  const handleCancel = (): void => {
    form.reset()

    navigation.canGoBack() ? navigation.pop() : navigation.navigate('WhiteLabelGuestGroupView', { whiteLabelId, guestGroupId })
  }

  const loadMore = ignoreAsync(async () => {
    const pageInfo = data?.whiteLabel?.guest?.orders?.pageInfo

    if (pageInfo?.hasNextPage ?? false) {
      await fetchMore({
        updateQuery (previousResult, { fetchMoreResult }) {
          if (previousResult.whiteLabel?.guest?.orders == null) {
            return previousResult
          }

          const newEdges = fetchMoreResult?.whiteLabel?.guest?.orders?.edges

          if (newEdges == null || newEdges.length === 0) {
            return previousResult
          }

          return dotProp.set(previousResult, 'whiteLabel.guest.orders', {
            __typename: previousResult.whiteLabel?.guest.orders.__typename,
            edges: [...previousResult.whiteLabel?.guest.orders.edges, ...newEdges],
            pageInfo: fetchMoreResult?.whiteLabel?.guest?.orders?.pageInfo
          })
        },
        variables: { whiteLabelId, guestGroupId, memberId, cursor: pageInfo?.endCursor ?? null }
      }).catch(logError)
    }
  })

  const handleRefresh = (): void => {
    refetch().catch(logError)
  }

  const handleDeleteMember = (): void => {
    deleteWhiteLabelGuest({
      awaitRefetchQueries: true,
      onCompleted: handleCancel,
      refetchQueries: [
        'GetWhiteLabelGuestGroupCustomerRegisterData',
        'GetWhiteLabelGuestGroupMemberViewData'
      ],
      variables: { whiteLabelId, whiteLabelGuestId: memberId }
    }).catch(logError)
  }

  const handleResendEmail = (): void => {
    resendWhiteLabelGuestInvitation({
      onCompleted: () => setInviteResent(true),
      variables: { whiteLabelId, whiteLabelGuestId: memberId }
    }).catch(logError)
  }

  const handleUpdateMember = (values: FormInput): void => {
    updateWhiteLabelGuest({
      awaitRefetchQueries: true,
      onCompleted: handleCancel,
      refetchQueries: [
        'GetWhiteLabelGuestGroupCustomerRegisterData',
        'GetWhiteLabelGuestGroupMemberViewData'
      ],
      variables: {
        whiteLabelId,
        whiteLabelGuestId: memberId,
        patch: {
          email: values.email,
          groupId: values.groupId,
          name: values.name
        }
      }
    }).catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' },
        { link: ['WhiteLabelGuestGroupList', { whiteLabelId }], title: 'Lojalitetsgrupper' },
        { link: ['WhiteLabelGuestGroupView', { whiteLabelId, guestGroupId }], title: data?.whiteLabel?.guestGroup?.name ?? '' }
      ]}
      loading={loading}
      screenType={ScreenType.Dashboard}
      title={member?.name ?? 'Medlem'}
    >
      <VerificationDialog
        callToActionLabel='Ta bort gäst'
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDeleteMember}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt='Genom att ta bort denna gäst försvinner den från lojalitetsprogrammets kundregister. Det går inte att ångra borttagningen. Är du säker på att du vill fortsätta?'
        title='Bekräfta borttagning av gäst'
      />
      <VStack gap={12} grow={1} maxWidth={700}>
        {member?.invitationStatus !== InvitationStatus.Accepted ? null : (
          <HStack gap={12}>
            {member.createdAt == null ? null : (
              <VStack alignItems='center' backgroundColor='#ffffff' borderRadius={12} grow={1} justifyContent='center' padding={12}>
                <Text size={12} weight='400'>Medlem sedan</Text>
                <Text size={32} weight='bold'>{createdAt == null ? null : year.format(createdAt)}</Text>
                <Text size={12} weight='400'>{createdAt == null ? null : dayMonth.format(createdAt)}</Text>
              </VStack>
            )}

            <VStack alignItems='center' backgroundColor='#ffffff' borderRadius={12} grow={1} justifyContent='center' padding={12}>
              <Text size={12} weight='400'>Antal köp</Text>
              <Text size={32} weight='bold'>{member.orderCount ?? 0}</Text>
            </VStack>

            <VStack alignItems='center' backgroundColor='#ffffff' borderRadius={12} grow={1} justifyContent='center' padding={12}>
              <Text size={12} weight='400'>Spenderat</Text>
              <Text size={32} weight='bold'>{formatCurrency(member.amountSpent ?? 0)}</Text>
            </VStack>
          </HStack>
        )}

        <FormContainer gap={16}>
          <Heading size='m'>{member?.name ?? 'Medlem'}</Heading>

          {emailHasChanged ? null :
            (
              <>
                {member?.invitationStatus === InvitationStatus.Accepted ? null : <Text>E-postinbjudan har ej accepterats. Inväntar svar från gäst. Vill du skicka e-postinbjudan en gång till?</Text>}

                {resendError == null ? null : <Warning message={resendError.message} />}

                {member?.invitationStatus === InvitationStatus.Accepted || inviteResent ? null : (
                  <HStack>
                    <RedoButton
                      loading={resending}
                      onPress={handleResendEmail}
                      title='Skicka e-post igen'
                    />
                  </HStack>
                )}

                {!inviteResent ? null : <Text>✅ E-postinbjudan har skickats på nytt</Text>}
              </>
            )}

          <Select
            estimatedNumberOfCharacters={20}
            form={form}
            name='groupId'
            options={guestGroupOptions}
            title='Grupp'
          />

          <TextField
            estimatedNumberOfCharacters={20}
            form={form}
            name='name'
            title='Namn'
          />

          {!emailHasChanged ? null
            : member?.invitationStatus === InvitationStatus.Accepted
            ? <Text>Om du ändrar e-postadressen kommer den nya adressen att användas för framtida kommunikation.</Text>
            : <Text>Om du ändrar e-postadressen skickas en ny e-postinbjudan till den nya adressen, och den tidigare inbjudan slutar fungera.</Text>}

          <TextField
            estimatedNumberOfCharacters={25}
            form={form}
            name='email'
            title='E-postadress'
          />

          <SubmitFormButtons
            disableSaveButton={!form.formState.isDirty}
            onCancel={handleCancel}
            onDelete={() => setShowDeleteDialog(true)}
            onSave={ignoreAsync(form.handleSubmit(handleUpdateMember))}
            saving={saving}
            titleForDelete='Ta bort'
            titleForSubmit='Spara'
          />
        </FormContainer>
      </VStack>

      <DashboardContainer gap={12}>
        <Heading size='m'>Beställningshistorik</Heading>

        {error == null ? null : <Warning message={error.message} />}

        <ReceiptList
          canFetchMore={data?.whiteLabel?.guest?.orders?.pageInfo.hasNextPage ?? false}
          fetchingMore={fetchingMore}
          loading={loading}
          loadingSelectedOrder={receiptSummaryData == null}
          onCloseDialog={() => setShowReceiptSummary(null)}
          onFetchMore={loadMore}
          onRefresh={handleRefresh}
          onSelectOrder={setShowReceiptSummary}
          orders={orders}
          refetching={refetching}
          selectedOrder={receiptSummaryData?.whiteLabel?.guest?.order ?? null}
          showReceiptSummaryDialog={showReceiptSummary != null}
        />
      </DashboardContainer>
    </Layout>
  )
}

export default WhiteLabelGuestGroupMemberView

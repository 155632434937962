import { FormControl as MuFormControl, InputLabel as MuInputLabel, Select as MuSelect, type SelectChangeEvent } from '@mui/material'
import React, { useCallback } from 'react'
import { Controller, type FieldValues, type Path, type UseFormReturn, get } from 'react-hook-form'

import ErrorField from './ErrorField'

const NULL_TOKEN = '4c3e936d-cdb7-4bcc-a75d-9bb3d62abcc2'

interface UncontrolledSelectProps<TFieldValues extends FieldValues> {
  autoFocus?: boolean
  disabled?: boolean
  form: Pick<UseFormReturn<TFieldValues>, 'control' | 'formState'>
  name: Path<TFieldValues>
  options: Array<{ value: string | null, title: string }>
  title: string
}

interface ControlledSelectProps<T extends null | string> {
  additionalStyle?: React.CSSProperties
  disabled?: boolean
  error?: boolean
  id: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange: (value: T) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  options: Array<{ value: T, title: string }>
  title: string
  value?: T | null
}

/**
 * @deprecated The method should not be used. Use ../components/Select.tsx instead
 */
export default function Select<TFieldValues extends FieldValues, TValue extends null | string> (props: UncontrolledSelectProps<TFieldValues> | ControlledSelectProps<TValue>): JSX.Element {
  if ('form' in props) {
    const error = get(props.form.formState.errors, props.name)
    return (
      <>
        <Controller
          control={props.form.control}
          name={props.name}
          render={({ field: { onBlur, onChange, value } }) => {
            const handleChange = (item: string | null): void => {
              onChange(item)
            }

            return (
              <Select
                disabled={props.disabled ?? false}
                error={error != null}
                id={props.name}
                onBlur={onBlur}
                onChange={handleChange}
                options={props.options}
                title={props.title}
                value={value}
              />
            )
          }}
        />

        <ErrorField message={error?.message} />
      </>
    )
  }

  const hasNullOption = props.options.some(option => option.value === null)
  const hasEmptyStringOption = props.options.some(option => option.value === '')

  const handleChange = useCallback((ev: SelectChangeEvent<typeof NULL_TOKEN | NonNullable<TValue>>) => {
    // @ts-expect-error Typings indicate that `ev.target.value` can be string even when `T` is not string, which shouldn't be possible
    props.onChange((ev.target.value === NULL_TOKEN ? null : ev.target.value) ?? null)
  }, [props.onChange])

  return (
    <MuFormControl error={props.error ?? false}>
      <MuInputLabel id={`${props.id}-label`}>{props.title}</MuInputLabel>
      <MuSelect
        disabled={props.disabled ?? false}
        id={props.id}
        label={props.title}
        labelId={`${props.id}-label`}
        native
        onBlur={props.onBlur}
        onChange={handleChange}
        onFocus={props.onFocus}
        style={{ minHeight: 45, ...props.additionalStyle }}
        value={props.value ?? NULL_TOKEN}
      >
        {props.value == null && !hasNullOption ? <option value={NULL_TOKEN} /> : null}
        {props.value === '' && !hasEmptyStringOption ? <option value='' /> : null}
        {props.options.map(option => <option key={option.value ?? NULL_TOKEN} value={option.value ?? NULL_TOKEN}>{option.title}</option>)}
      </MuSelect>
    </MuFormControl>
  )
}

import { MaterialIcons } from '@expo/vector-icons'
import React, { useCallback, useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { ApiEnvironment, FixedDeliveryTime, type FullPaymentMethodFragment, type FullPosTerminalFragment, type FullPrinterQueueFragment, MenuTypeFilter, PaymentProvider, SendToKitchen, type TillInput, TillLayout } from '../../types/graphql'
import { ACCENT_COLOR } from '../lib/color'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import formatPaymentMethodTitle from '../util/formatPaymentMethodTitle'
import ignoreAsync from '../util/ignoreAsync'

import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'
import Warning from './Warning'
import { MultiSelectCheckbox, Select } from './fields'
import CheckBox from './fields/CheckBox'
import TextField from './fields/TextField'

function paymentProviderRequiresLoggedIn (paymentProvider: PaymentProvider): boolean {
  switch (paymentProvider) {
    case PaymentProvider.Cash:
    case PaymentProvider.External:
    case PaymentProvider.PosTerminal:
      return true
    default:
      return false
  }
}

interface TillFormInput extends
  Pick<
    TillInput,
    | 'featuredDiscountCode'
    | 'fixedDeliveryLocationId'
    | 'fixedDeliveryTime'
    | 'layout'
    | 'name'
    | 'paymentMethodIds'
    | 'posTerminalIds'
    | 'preferredApiEnvironment'
    | 'receiptPrinterQueueId'
    | 'sendOrderToKitchen'
    | 'showsCategoryBar'
    | 'showsDiscountField'
    | 'showsEmptyShoppingCartButton'
    | 'showsHoldKitchenPrint'
    | 'showsLogo'
    | 'showsMenuType'
    | 'showsOpeningStatus'
    | 'showsOpenOrders'
    | 'showsSearch'
    | 'showsTip'
    | 'skipSlipPrintOnPrinterQueueIds'
  > {
  netsTerminalIds?: string[] | null
  hasSkipSlipPrintOnPrinterQueuesAccess: boolean
}

interface TillFormProps {
  deliveryLocationOptions?: ReadonlyArray<{ id: string, name?: string | null }>
  hasSkipSlipPrintOnPrinterQueuesAccess: boolean
  initialValues?: TillInput
  isSuperUser: boolean
  netsConnectCloudIsOffline: boolean
  onDelete?: () => void
  onDismiss: () => void
  onSave: (input: TillInput, orderDisplaySystemEnabled: false | null) => void
  orderDisplaySystemEnabled?: boolean
  paymentMethodOptions?: readonly FullPaymentMethodFragment[]
  posTerminalOptions?: readonly FullPosTerminalFragment[]
  receiptPrinterOptions?: readonly FullPrinterQueueFragment[]
  restaurantIsBetaTesting: boolean | null | undefined
  saving: boolean
}

const schema: ObjectSchema<TillFormInput> = yup.object({
  featuredDiscountCode: yup.string().nullable(),
  fixedDeliveryLocationId: yup.string().nullable(),
  fixedDeliveryTime: yup.mixed<FixedDeliveryTime>().oneOf([FixedDeliveryTime.Asap, FixedDeliveryTime.Any], 'Önskad inställning för menytyp måste sättas').optional().nullable(),
  sendOrderToKitchen: yup.mixed<SendToKitchen>().oneOf([SendToKitchen.Always, SendToKitchen.Ask, SendToKitchen.Never], 'Önskad inställning måste sättas').optional().nullable(),
  hasSkipSlipPrintOnPrinterQueuesAccess: yup.boolean().required(),
  layout: yup.mixed<TillLayout>().oneOf([TillLayout.List, TillLayout.Modern, TillLayout.Retro], 'Önskat utseende på LocoPOS Order måste väljas').optional().nullable(),
  name: yup.string().trim().min(1, 'Namn med minst 1 tecken måste anges').required(),
  netsTerminalIds: yup.array().of(yup.string().required()).optional().nullable().when('showsNetsConnectCloudPaymentProvider', {
    is: true,
    then: schema => schema.min(1, 'Välj åtminstone 1 terminal').required('Välj åtminstone 1 terminal')
  }),
  paymentMethodIds: yup.array().of(yup.string().defined()).optional(),
  posTerminalIds: yup.array().of(yup.string().defined()).optional(),
  preferredApiEnvironment: yup.mixed<ApiEnvironment>().oneOf([ApiEnvironment.Beta, ApiEnvironment.Dev, ApiEnvironment.Stable]),
  receiptPrinterQueueId: yup.string().nullable(),
  showsCategoryBar: yup.boolean().required(),
  showsDiscountField: yup.boolean().required(),
  showsEmptyShoppingCartButton: yup.boolean().required(),
  showsHoldKitchenPrint: yup.boolean(),
  showsLogo: yup.boolean().required(),
  showsMenuType: yup.mixed<MenuTypeFilter>().oneOf([MenuTypeFilter.TakeAway, MenuTypeFilter.EatIn, MenuTypeFilter.All], 'Önskad inställning för menytyp måste sättas'),
  showsOpeningStatus: yup.boolean().required(),
  showsOpenOrders: yup.boolean().required(),
  showsSearch: yup.boolean().required(),
  showsTip: yup.boolean().required(),
  skipSlipPrintOnPrinterQueueIds: yup.array().of(yup.string().defined()).optional()
})

const TillForm: React.FC<TillFormProps> = ({ deliveryLocationOptions, hasSkipSlipPrintOnPrinterQueuesAccess, initialValues, isSuperUser, netsConnectCloudIsOffline, onDelete, onDismiss, onSave, orderDisplaySystemEnabled, paymentMethodOptions, posTerminalOptions, receiptPrinterOptions, restaurantIsBetaTesting, saving }) => {
  const netsTerminalOptions = posTerminalOptions?.filter(posTerminal => posTerminal.paymentProvider === PaymentProvider.NetsConnectCloud).map(posTerminal => ({ title: `${posTerminal.name ?? posTerminal.externalId ?? 'Ej tillgänglig'}${(posTerminal.isActive ?? true) ? '' : ' (inaktiv)'}`, value: posTerminal.id })) ?? []
  const printerQueues = receiptPrinterOptions?.map(printer => ({ title: printer.name ?? printer.id, value: printer.id })) ?? []

  const form = useForm<TillFormInput>({
    criteriaMode: 'all',
    mode: 'onTouched',
    resolver: yupResolver(schema),
    values: schema.cast(
      {
        hasSkipSlipPrintOnPrinterQueuesAccess,
        netsTerminalIds: initialValues?.posTerminalIds?.filter(id => netsTerminalOptions.some(netsTerminal => netsTerminal.value === id)),
        ...initialValues
      },
      { stripUnknown: true }
    )
  })

  const paymentMethodIdsController = useController({ control: form.control, name: 'paymentMethodIds' })
  const selectedPaymentMethods = (paymentMethodIdsController.field.value as readonly string[] | undefined) ?? []
  const selectedSendOrderToKitchen = form.watch('sendOrderToKitchen')

  const handlePaymentMethodPress = useCallback((paymentMethodId: string): void => {
    if (selectedPaymentMethods.includes(paymentMethodId)) {
      paymentMethodIdsController.field.onChange(selectedPaymentMethods.filter(id => id !== paymentMethodId))
    } else {
      paymentMethodIdsController.field.onChange([...selectedPaymentMethods, paymentMethodId])
    }
  }, [selectedPaymentMethods])

  const handleSave = (input: TillFormInput): void => {
    const { hasSkipSlipPrintOnPrinterQueuesAccess, netsTerminalIds, ...tillInput } = input

    const posTerminalIds: string[] = []
    if (netsTerminalIds != null && netsTerminalIds.length > 0) posTerminalIds.push(...netsTerminalIds)

    let newOrderDisplaySystemEnabled: false | null = null
    if ((orderDisplaySystemEnabled ?? false) && (input.sendOrderToKitchen !== SendToKitchen.Always)) {
      newOrderDisplaySystemEnabled = false
    }

    onSave({ ...tillInput, posTerminalIds }, newOrderDisplaySystemEnabled)
  }

  // If there aren't any available options but nets is checked, uncheck nets (and keep checkbox disabled)
  useEffect(() => {
    const noNetsTerminals = netsTerminalOptions.length === 0
    if (!noNetsTerminals) return

    const netsId = paymentMethodOptions?.find(({ paymentProvider }) => (paymentProvider === PaymentProvider.NetsConnectCloud))?.id
    if (netsId == null) return

    const netsConnectCloudEnabled = selectedPaymentMethods.includes(netsId)
    if (!netsConnectCloudEnabled) return

    paymentMethodIdsController.field.onChange(selectedPaymentMethods.filter(id => id !== netsId))
  }, [netsTerminalOptions, paymentMethodIdsController, selectedPaymentMethods])

  return (
    <FormContainer gap={12}>
      <TextField
        form={form}
        name='name'
        title='Namn'
      />

      <Spacer height={4} />

      <Text size={20}>Utseende</Text>

      <CheckBox
        form={form}
        name='showsCategoryBar'
        title='Kategorier'
      />

      <CheckBox
        form={form}
        name='showsLogo'
        title='Logotyp'
      />

      <CheckBox
        form={form}
        name='showsTip'
        title='Visa fältet för dricks'
      />

      <CheckBox
        form={form}
        name='showsDiscountField'
        title='Rabatter, samt rabattkoder i expresskassan'
      />

      <CheckBox
        form={form}
        name='showsSearch'
        title='Sök'
      />

      <CheckBox
        form={form}
        name='showsEmptyShoppingCartButton'
        title='Töm kundvagn'
      />

      <CheckBox
        form={form}
        name='showsOpeningStatus'
        title='Öppettider'
      />

      <CheckBox
        form={form}
        name='showsOpenOrders'
        title='Öppna notor'
      />

      <CheckBox
        form={form}
        name='showsHoldKitchenPrint'
        title='Framma / vänta'
      />

      <Select
        form={form}
        name='showsMenuType'
        options={[{ title: 'Valbar', value: MenuTypeFilter.All }, { title: 'Ta med', value: MenuTypeFilter.TakeAway }, { title: 'Äta här', value: MenuTypeFilter.EatIn }]}
        title='Menytyp'
      />

      {!(restaurantIsBetaTesting ?? false)
        ? null
        : (
          <Select
            form={form}
            name='preferredApiEnvironment'
            options={[
              { title: 'Stabil (Default)', value: ApiEnvironment.Stable },
              { title: 'Beta', value: ApiEnvironment.Beta },
              ...(!isSuperUser ? [] : [{ title: 'Utveckling (endast för superadmin)', value: ApiEnvironment.Dev }])
            ]}
            title={isSuperUser ? 'Föredragen API-miljö' : 'Föredragen API-miljö (gäller ej i LocoPOS Order Beta)'}
          />
        )}

      <Select
        form={form}
        name='receiptPrinterQueueId'
        options={[{ title: 'Ej vald', value: null }, ...printerQueues]}
        title='Kvittoskrivare'
      />

      <Select
        form={form}
        name='fixedDeliveryTime'
        options={[{ title: 'Valbar', value: FixedDeliveryTime.Any }, { title: 'Snarast', value: FixedDeliveryTime.Asap }]}
        title='Tid'
      />

      <Select
        form={form}
        name='fixedDeliveryLocationId'
        options={[
          { title: 'Valbar', value: null },
          ...(deliveryLocationOptions?.map(location => ({ title: location.name ?? location.id, value: location.id })) ?? [])
        ]}
        title='Plats'
      />

      <Select
        form={form}
        name='sendOrderToKitchen'
        options={[{ title: 'Kunden får alltid varorna direkt (notifiera inte köket)', value: SendToKitchen.Never }, { title: 'Välj notifieringsalternativ i appen', value: SendToKitchen.Ask }, { title: 'Varorna görs av köket (skicka notis till köket)', value: SendToKitchen.Always }]}
        title='Notifieringar i samband med köp'
      />

      {((orderDisplaySystemEnabled ?? false) && (selectedSendOrderToKitchen !== SendToKitchen.Always)) && (
        <>
          <HStack alignItems='center' backgroundColor={ACCENT_COLOR} borderRadius={8} gap={12} padding={12}>
            <MaterialIcons color='white' name='warning' size={24} />
            <Text color='white' size={20}>Om du ändrar den här inställningen till ett annat val än "Varorna görs av köket" så inaktiveras orderskärmen.</Text>
          </HStack>

          <Spacer height={32} />
        </>
      )}

      {!hasSkipSlipPrintOnPrinterQueuesAccess ? null : (
        <MultiSelectCheckbox
          form={form}
          name='skipSlipPrintOnPrinterQueueIds'
          options={printerQueues}
          title='Ignorerade bongskrivarköer'
        />
      )}
      <TextField
        form={form}
        name='featuredDiscountCode'
        title='"Featured discount" rabattkod'
      />
      <Spacer height={4} />

      <Text size={20}>Betalsätt</Text>

      {paymentMethodOptions?.map(method => {
        const disabledNets = method.paymentProvider === PaymentProvider.NetsConnectCloud && netsTerminalOptions.length === 0
        const disabled = disabledNets
        const isUsed = selectedPaymentMethods.includes(method.id)
        const name = formatPaymentMethodTitle(method)
        const onlyWhenLoggedInDisclaimer = (method.paymentProvider != null && paymentProviderRequiresLoggedIn(method.paymentProvider)) ? ' (visas endast vid inloggade köp)' : ''
        const checkboxTitle = name + onlyWhenLoggedInDisclaimer

        return (
          <VStack key={method.id}>
            <CheckBox
              checked={isUsed}
              disabled={disabled}
              onPress={() => handlePaymentMethodPress(method.id)}
              title={checkboxTitle}
            />

            {/* Nets terminals select */}
            {!(isUsed && method.paymentProvider === PaymentProvider.NetsConnectCloud)
              ? null
              : (
                <VStack grow={1} paddingHorizontal={36}>
                  {!netsConnectCloudIsOffline ? null : <Warning message='Ingen respons från Nets servrar' paddingBottom={16} />}

                  <MultiSelectCheckbox
                    form={form}
                    name='netsTerminalIds'
                    options={netsTerminalOptions}
                    title='Netsterminaler'
                  />
                </VStack>
              )}
          </VStack>
        )
      })}

      <Spacer height={4} />

      <Text size={20}>Layout</Text>

      <VStack paddingRight={10} paddingTop={5}>
        <Select
          form={form}
          name='layout'
          options={[{ title: 'Lista (Rekommenderat för expresskassa)', value: TillLayout.List }, { title: 'Modern', value: TillLayout.Modern }, { title: 'Retro', value: TillLayout.Retro }]}
          title='Layout'
        />
      </VStack>

      <Spacer height={12} />

      <SubmitFormButtons
        disableDeleteButton={saving}
        disableSaveButton={saving}
        onCancel={onDismiss}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(handleSave))}
        saving={saving ?? false}
      />
    </FormContainer>
  )
}

export default TillForm

import { MaterialCommunityIcons } from '@expo/vector-icons'
import React, { useCallback, useMemo, useState } from 'react'
import { ActivityIndicator, FlatList, useWindowDimensions } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { type FullDiscountFragment } from '../../types/graphql'

import ButtonGroup from './ButtonGroup'
import DiscountCard from './DiscountCard'
import { Select } from './fields'

const TWO_COLUMNS_TRESHOLD = 800

function createButtonGroupLabel (label: string, data?: unknown[]): string {
  return data?.length == null ? label : `${label} (${data.length})`
}

interface DiscountListProps {
  discounts?: FullDiscountFragment[]
  onPress: (discountId: string) => void
  parentType: 'Restaurant' | 'MetaOrganization'
  timeZone?: string
}

const DiscountList: React.FC<DiscountListProps> = ({ discounts, onPress, parentType, timeZone }) => {
  const [filter, setFilter] = useState<'all' | 'active'>('all')

  const [sortBy, setSortBy] = useState<keyof Pick<FullDiscountFragment, 'code' | 'discountAmount' | 'discountPercentage' | 'totalDiscountAmount' | 'validFrom' | 'validUntil'>>('code')

  const [sortDesc, setSortDesc] = useState(false)

  const handleOnPress = useCallback((discountId: string) => () => onPress(discountId), [onPress])

  const handleSortDescToggle = useCallback(() => setSortDesc(value => !value), [setSortDesc])

  const sortedDiscounts = useMemo(() => discounts == null ? undefined : sortOn(discounts, (sortDesc ? '-' : '') + sortBy), [discounts, sortBy, sortDesc])

  const activeDiscounts = useMemo(() => sortedDiscounts?.filter(d => d.isDisabled === false), [sortedDiscounts])

  const numColumns = useWindowDimensions().width > TWO_COLUMNS_TRESHOLD ? 2 : 1

  return (
    <VStack gap={8}>
      <HStack gap={8} justifyContent='space-around' wrap>
        <VStack grow={1} maxWidth={400}>
          <ButtonGroup
            buttons={[
              { label: createButtonGroupLabel('Alla', sortedDiscounts), value: 'all' },
              { label: createButtonGroupLabel('Aktiva', activeDiscounts), value: 'active' }
            ]}
            onPress={setFilter}
            selected={filter}
          />
        </VStack>

        <HStack>
          <Select
            additionalStyle={{ width: 200 }}
            id='sort-discounts'
            onChange={setSortBy}
            options={[
              { title: 'Rabattkod', value: 'code' },
              { title: 'Rabatt i kronor', value: 'discountAmount' },
              { title: 'Rabatt i procent', value: 'discountPercentage' },
              { title: 'Startdatum', value: 'validFrom' },
              { title: 'Slutdatum', value: 'validUntil' },
              { title: 'Total rabattsumma', value: 'totalDiscountAmount' }
            ]}
            title='Sortera efter'
            value={sortBy}
          />

          <Spacer width={8} />

          <MaterialCommunityIcons
            color='#fff'
            name={sortDesc ? 'sort-descending' : 'sort-ascending'}
            onPress={handleSortDescToggle}
            reverse
            reverseColor='#333'
            type='material-community'
          />
        </HStack>
      </HStack>

      <FlatList
        key={numColumns}
        data={(filter === 'all' ? sortedDiscounts : activeDiscounts) ?? []}
        keyExtractor={item => item.id}
        ListEmptyComponent={sortedDiscounts == null ? <ActivityIndicator size='large' /> : (
          <HStack justifyContent='center' padding={24}>
            <Text size={18}>Det finns inga rabatter tillagda</Text>
          </HStack>
        )}
        numColumns={numColumns}
        renderItem={({ item }) => (
          <HStack padding={4} width='100%'>
            <DiscountCard discount={item} onPress={handleOnPress(item.id)} parentType={parentType} timeZone={timeZone} />
          </HStack>
        )}
      />
    </VStack>
  )
}

export default DiscountList

import { NetworkStatus } from '@apollo/client'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, VStack } from 'react-stacked'

import { InvitationStatus, useGetWhiteLabelGuestGroupViewDataQuery } from '../../types/graphql'
import { AddButton, PrimaryButton, SecondaryButton } from '../components/Buttons'
import { DataColumn, DataTable } from '../components/DataTable'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import Select from '../components/Select'
import { TextField } from '../components/TextField'
import Heading from '../components/atoms/Heading'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetWhiteLabelGuestGroupViewData($whiteLabelId: ID!, $guestGroupId: ID!, $cursor: String, $first: Int!, $filter: WhiteLabelGuestFilter) {
    whiteLabel(id: $whiteLabelId) {
      id

      guestGroup(id: $guestGroupId) {
        id

        cardBackgroundColor
        cardImageUrl
        memberCount
        name

        members(first: $first, after: $cursor, filter: $filter) {
          edges {
            cursor

            node {
              id

              amountSpent
              createdAt
              email
              invitationStatus
              name
              orderCount
            }
          }

          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`

interface FormInput {
  email?: string | null
}

const schema: ObjectSchema<FormInput> = yup.object().shape({
  email: yup.string().email().nullable()
})

interface MemberList {
  id: string
  amountSpent?: number | null
  createdAt?: number | null
  email?: string | null
  invitationStatus?: string | null
  name?: string | null
  orderCount?: number | null
}

const WhiteLabelGuestGroupView: React.FC = () => {
  const [navigation, { whiteLabelId, guestGroupId }] = useNavigation<'WhiteLabelGuestGroupView'>()

  const [numberOfMembersPerPage, setNumberOfMembersPerPage] = useState(25)
  const [filter, setFilter] = useState<FormInput | null>(null)

  const { data, fetchMore, networkStatus } = useGetWhiteLabelGuestGroupViewDataQuery({ notifyOnNetworkStatusChange: true, variables: { whiteLabelId, guestGroupId, first: numberOfMembersPerPage, filter } })
  const loading = networkStatus === NetworkStatus.loading
  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  const form = useForm<FormInput>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const guestGroup = data?.whiteLabel?.guestGroup

  const memberRows = guestGroup?.members?.edges.map(({ node }) => ({
    id: node.id,
    amountSpent: node.amountSpent ?? 0,
    createdAt: node.createdAt == null ? undefined : new Date(node.createdAt).getTime(),
    email: node.email,
    invitationStatus: node.invitationStatus === InvitationStatus.Accepted ? '✔️' : '⏱️',
    name: node.name,
    orderCount: node.orderCount ?? 0
  })) ?? []

  const handleFetchMore = (): void => {
    fetchMore({
      variables: { cursor: data?.whiteLabel?.guestGroup?.members?.pageInfo?.endCursor, whiteLabelId, guestGroupId },

      updateQuery (previousResult, { fetchMoreResult }) {
        if (previousResult.whiteLabel?.guestGroup?.members == null) {
          return previousResult
        }

        const newEdges = fetchMoreResult?.whiteLabel?.guestGroup?.members?.edges

        if (newEdges == null || newEdges.length === 0) {
          return previousResult
        }

        return dotProp.set(previousResult, 'whiteLabel.guestGroup.members', {
          __typename: previousResult.whiteLabel.guestGroup.members.__typename,
          edges: [...previousResult.whiteLabel.guestGroup.members.edges, ...newEdges],
          pageInfo: fetchMoreResult?.whiteLabel?.guestGroup?.members?.pageInfo
        })
      }
    }).catch(logError)
  }

  const handleSearch = (input: FormInput): void => {
    if (input.email == null || input.email.trim() === '') return setFilter(null)

    setFilter({ ...input })
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' },
        { link: ['WhiteLabelGuestGroupList', { whiteLabelId }], title: 'Lojalitetsgrupper' }
      ]}
      hideTitle
      loading={loading && filter == null}
      screenType={ScreenType.List}
      title={guestGroup?.name ?? 'Lojalitetsgrupp'}
    >
      <ListContainer>
        <HStack alignItems='center' justifyContent='space-between'>
          <Heading size='l'>{guestGroup?.name ?? 'Lojalitetsgrupp'}</Heading>

          <SecondaryButton
            icon='edit'
            loading={false}
            onPress={() => navigation.push('WhiteLabelGuestGroupEdit', { whiteLabelId, guestGroupId })}
            title='Redigera grupp'
          />
        </HStack>

        <Spacer height={24} />

        <AddButton
          onPress={() => navigation.push('WhiteLabelGuestGroupMemberCreate', { whiteLabelId, guestGroupId })}
          title='Lägg till medlem'
        />

        <Spacer height={24} />

        <HStack justifyContent='space-between' wrap>
          <HStack alignItems='end' gap={8} justifyContent='center'>
            <TextField
              estimatedNumberOfCharacters={30}
              form={form}
              name='email'
              onSubmitEditing={ignoreAsync(form.handleSubmit(handleSearch))}
              title='Sök e-post'
            />

            <VStack alignItems='center' justifyContent='center' padding={3}>
              <PrimaryButton
                loading={loading && filter != null}
                onPress={ignoreAsync(form.handleSubmit(handleSearch))}
                title='Sök'
              />
            </VStack>
          </HStack>

          <Select
            estimatedNumberOfCharacters={4}
            onChange={(input) => {
              const inputAsNumber = parseInt(input)
              if (isNaN(inputAsNumber)) return

              setNumberOfMembersPerPage(parseInt(input))
            }}
            options={[
              { title: '10', value: '10' },
              { title: '25', value: '25' },
              { title: '100', value: '100' },
              { title: '1000', value: '1000' },
              { title: 'Alla', value: '1000000' }
            ]}
            title='Resultat'
            value={numberOfMembersPerPage.toString()}
          />
        </HStack>

        <Spacer height={8} />

        <DataTable
          csvFileName='report-group-sales.csv'
          data={memberRows}
          hideSummaryRow
          initialSort='-createdAt'
          onDataRowPress={({ id }) => navigation.navigate('WhiteLabelGuestGroupMemberView', { whiteLabelId, guestGroupId, memberId: id })}
          title='Medlemmar'
        >
          <DataColumn<MemberList> field='email' grow={2} title='E-post' type='string' />
          <DataColumn<MemberList> field='name' grow={1} title='Namn' type='string' />
          <DataColumn<MemberList> field='invitationStatus' grow={0.2} title='' type='string' />
          <DataColumn<MemberList> field='createdAt' hidden title='Skapad' type='string' />
          <DataColumn<MemberList> field='orderCount' grow={0.4} title='Köp' type='number' />
          <DataColumn<MemberList> field='amountSpent' grow={0.6} maximumFractionDigits={0} title='Spenderat' type='currency' />
        </DataTable>

        <Spacer height={24} />

        {!(data?.whiteLabel?.guestGroup?.members?.pageInfo.hasNextPage ?? false) ? null : (
          <SecondaryButton
            loading={fetchingMore}
            onPress={handleFetchMore}
            title='Visa fler'
          />
        )}
      </ListContainer>
    </Layout>
  )
}

export default WhiteLabelGuestGroupView

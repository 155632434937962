import React, { useCallback } from 'react'
import { ActivityIndicator, FlatList } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type FullMenuReportGroupFragment } from '../../../types/graphql'
import { type ChangeFn } from '../../util/useMultiEditProducts'

import ListItem from './ListItem'
import { Content } from './MultiEditForm'

interface EditReportGroupProps {
  onDismiss: () => void
  onNext: (changeFn: ChangeFn) => void
  reportGroups?: readonly FullMenuReportGroupFragment[] | null
}

const EditReportGroup: React.FC<EditReportGroupProps> = ({ onDismiss, onNext, reportGroups }) => {
  const handleOnPress = useCallback((reportGroup: FullMenuReportGroupFragment) => () =>
    onNext(properties => ({
      from: `${unwrap(properties?.reportGroup?.name)} (${unwrap(properties?.reportGroup?.vatRate)} %)`,
      to: `${unwrap(reportGroup?.name)} (${unwrap(reportGroup?.vatRate)} %)`,
      patch: { reportGroupId: unwrap(reportGroup).id }
    })), [onNext])

  return (
    <Content onDismiss={onDismiss}>
      {reportGroups == null
        ? <ActivityIndicator />
        : (
          <FlatList
            data={reportGroups}
            ItemSeparatorComponent={() => <Spacer height={4} />}
            keyExtractor={item => item.id}
            renderItem={({ item }) => (
              <ListItem onPress={handleOnPress(item)}>
                <HStack alignItems='center' grow={1} height={40} paddingHorizontal={8}>
                  <Text size={16}>{item.name}</Text>

                  <Spacer grow={1} width={12} />

                  <Text size={16}>{item.vatRate}%</Text>
                </HStack>
              </ListItem>
            )}
          />
        )}
    </Content>
  )
}

export default EditReportGroup

import gql from 'graphql-tag'
import React, { useState } from 'react'

import { type MenuCourseInput, useDeleteMenuCourseMutation, useGetMenuCourseQuery, useUpdateMenuCourseMutation } from '../../types/graphql'
import Layout, { ScreenType } from '../components/Layout'
import MenuCourseForm from '../components/MenuCourseForm'
import VerificationDialog from '../components/VerificationDialog'
import ignoreAsync from '../util/ignoreAsync'
import logAndShowError from '../util/logAndShowError'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullMenuCourse on MenuCourse {
    id
    name
  }

  mutation UpdateMenuCourse($restaurantId: ID!, $menuCourseId: ID!, $patch: MenuCoursePatch!) {
    updateMenuCourse(restaurantId: $restaurantId, menuCourseId: $menuCourseId, patch: $patch) {
      ...FullMenuCourse
    }
  }

  mutation DeleteMenuCourse($restaurantId: ID!, $menuCourseId: ID!) {
    deleteMenuCourse(restaurantId: $restaurantId, menuCourseId: $menuCourseId)
  }

  query GetMenuCourse($restaurantId: ID!, $menuCourseId: ID!) {
    restaurant(id: $restaurantId) {
      id

      menu {
        course(id: $menuCourseId) {
          ...FullMenuCourse
        }
      }
    }
  }
`

const MenuCourseEdit: React.FC = () => {
  const [navigation, { restaurantId, menuCourseId }] = useNavigation<'MenuCourseEdit'>()

  const { data } = useGetMenuCourseQuery({ variables: { restaurantId, menuCourseId } })

  const [deleteMenuCourse, { loading: deleting, error: deleteError }] = useDeleteMenuCourseMutation({
    awaitRefetchQueries: true,
    onCompleted: () => navigation.navigate('MenuCourseList', { restaurantId }),
    onError: (error) => logAndShowError(error),
    refetchQueries: ['GetMenuCourseList'],
    variables: { restaurantId, menuCourseId }
  })

  const [updateMenuCourse, { loading }] = useUpdateMenuCourseMutation({
    onCompleted: () => navigation.navigate('MenuCourseList', { restaurantId }),
    onError: (error) => logAndShowError(error)
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleCancel = (): void => {
    navigation.navigate('MenuCourseList', { restaurantId })
  }

  const handleDelete = ignoreAsync(async () => {
    await deleteMenuCourse()
  })

  const handleSubmit = ignoreAsync(async (input: MenuCourseInput) => {
    await updateMenuCourse({
      variables: {
        restaurantId,
        menuCourseId,
        patch: input
      }
    })
  })

  return (
    <Layout
      breadcrumbs={[{ link: ['MenuCourseList', { restaurantId }], title: 'Rätter' }]}
      loading={loading}
      screenType={ScreenType.Form}
      title='Redigera rätt'
    >
      <MenuCourseForm
        initialValues={data?.restaurant?.menu?.course}
        onCancel={handleCancel}
        onDelete={() => setShowDeleteDialog(true)}
        onSubmit={handleSubmit}
        saving={loading}
      />

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={deleting}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt='Är du säker på att du vill ta bort denna rätt?'
        title='Radera rätt'
      />
    </Layout>
  )
}

export default MenuCourseEdit

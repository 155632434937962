import React, { useRef } from 'react'

import logError from '../util/logError'

import { UploadButton } from './Buttons'
import type { TextFilePickerProps } from './TextFilePicker'

const TextFilePicker: React.FC<TextFilePickerProps> = ({ loading, onData, title }) => {
  const ref = useRef<HTMLInputElement>(null)
  const [internalLoading, setInternalLoading] = React.useState<true | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]

    if (file == null) {
      return
    }

    setInternalLoading(true)

    const reader = new FileReader()

    reader.onload = (event): void => {
      if (event.target?.readyState !== 2) return

      onData(event.target.result as string)
      setInternalLoading(null)
    }

    reader.onerror = (event): void => {
      logError(event.target?.error ?? new Error('Failed to read file'))
      setInternalLoading(null)
    }

    reader.readAsText(file)
    event.target.value = ''
  }

  return (
    <>
      <UploadButton
        loading={internalLoading ?? loading}
        onPress={() => {
          ref.current?.click()
        }}
        title={title}
      />

      <input onChange={handleChange} ref={ref} style={{ position: 'absolute', visibility: 'hidden' }} type='file' />
    </>
  )
}

export default TextFilePicker

let cache: string | undefined

function getLegacyToken (): string | undefined {
  const currentUser = localStorage.getItem('Parse/loco-app/currentUser')
  if (currentUser == null) return undefined

  return JSON.parse(currentUser).sessionToken ?? undefined
}

function getHashToken (): string | undefined {
  const data = new URLSearchParams(window.location.hash ?? '')

  if (data.has('session-token')) {
    try {
      window.history.replaceState('', '', '#')
    } catch (_) {
      window.location.href = '#'
    }

    return data.get('session-token') ?? undefined
  }
}

/* eslint-disable-next-line @typescript-eslint/require-await */
export async function getAccessToken (): Promise<string | undefined> {
  if (cache == null) cache = getHashToken() ?? localStorage.getItem('session-token') ?? getLegacyToken() ?? undefined
  return cache
}

/* eslint-disable-next-line @typescript-eslint/require-await */
export async function setAccessToken (accessToken: string): Promise<void> {
  cache = accessToken
  localStorage.setItem('session-token', accessToken)
}

export async function hasAccessToken (): Promise<boolean> {
  return (await getAccessToken()) != null
}

/* eslint-disable-next-line @typescript-eslint/require-await */
export async function clearAccessToken (): Promise<void> {
  cache = undefined
  localStorage.removeItem('session-token')
  localStorage.removeItem('Parse/loco-app/currentUser')
}

import gql from 'graphql-tag'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { GetRestaurantPrintersViewDataDocument, type PhysicalPrinterInput, useCreatePhysicalPrinterMutation, useGetRestaurantPhysicalPrinterCreateDataQuery } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { MultiSelectCheckbox, TextField } from '../components/fields'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetRestaurantPhysicalPrinterCreateData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      printerQueues {
        id

        name
      }
    }
  }

  mutation CreatePhysicalPrinter($restaurantId: ID!, $input: PhysicalPrinterInput!) {
    createPhysicalPrinter(restaurantId: $restaurantId, input: $input) {
      id

      name
    }
  }
`

const schema: ObjectSchema<PhysicalPrinterInput> = yup.object({
  name: yup.string().trim().min(1).max(256).required(),
  printerQueueIds: yup.array().of(yup.string().required()).required()
})

const RestaurantPhysicalPrinterCreate: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantPhysicalPrinterCreate'>()

  const form = useForm<PhysicalPrinterInput>({ criteriaMode: 'all', resolver: yupResolver(schema) })

  const { data, loading } = useGetRestaurantPhysicalPrinterCreateDataQuery({ variables: { restaurantId } })

  const [createPhysicalPrinter, { error, loading: submitting }] = useCreatePhysicalPrinterMutation({
    onCompleted: () => navigation.navigate('RestaurantPrintersView', { restaurantId }),
    refetchQueries: [{ query: GetRestaurantPrintersViewDataDocument, variables: { restaurantId } }]
  })

  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('RestaurantPrintersView', { restaurantId })
  }, [navigation])

  const handleSubmit = (input: PhysicalPrinterInput): void => {
    createPhysicalPrinter({ variables: { restaurantId, input } }).catch(logError)
  }

  if (loading || submitting) {
    return <Layout loading />
  }

  return (
    <Layout
      breadcrumbs={[{ link: ['RestaurantPrintersView', { restaurantId }], title: 'Skrivare' }]}
      hideTitle
      screenType={ScreenType.Form}
      title='Skapa fysisk skrivare'
    >
      <FormContainer gap={16} title='Skapa fysisk skrivare'>
        <TextField
          form={form}
          name='name'
          title='Namn'
        />

        <MultiSelectCheckbox
          form={form}
          name='printerQueueIds'
          options={data?.restaurant?.printerQueues?.map(({ id, name }) => ({ value: id, title: name ?? '' })) ?? []}
          title='Köer att skriva ut ifrån'
        />

        <SubmitFormButtons
          disableCancelButton={loading}
          disableSaveButton={!form.formState.isDirty || loading}
          error={error}
          onCancel={handleCancel}
          onSave={ignoreAsync(form.handleSubmit(handleSubmit))}
          saving={loading}
          titleForSubmit='Skapa'
        />
      </FormContainer>
    </Layout>
  )
}

export default RestaurantPhysicalPrinterCreate

import { HStack, Text } from 'react-stacked'

const InactiveBadge: React.FC<{ background?: 'default' | 'green' | 'orange', label: string }> = ({ background, label }) => {
  const backgroundColor = background === 'green' ? '#7daa92cc' : background === 'orange' ? '#ff6600cc' : '#ee0000cc'

  return (
    <HStack
      alignItems='center'
      backgroundColor={backgroundColor}
      borderRadius={15}
      justifyContent='center'
      paddingHorizontal={10}
      paddingVertical={4}
    >
      <Text color='white'>{label}</Text>
    </HStack>
  )
}

export default InactiveBadge

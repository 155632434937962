import gql from 'graphql-tag'
import React, { useCallback, useMemo, useState } from 'react'
import { VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { GetAddonProductsDocument, type MenuProductPatch, useDeleteMenuAddonProductMutation, useGetEditAddonProductDataQuery, useUpdateMenuAddonProductMutation } from '../../types/graphql'
import AddonProductForm from '../components/AddonProductList/AddonProductForm'
import Layout, { type Breadcrumb } from '../components/Layout'
import VerificationDialog from '../components/VerificationDialog'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  mutation DeleteMenuAddonProduct($restaurantId: ID!, $menuAddonProductId: ID!) {
    deleteMenuAddonProduct(
      restaurantId: $restaurantId
      menuAddonProductId: $menuAddonProductId
    )
  }

  query GetEditAddonProductData($restaurantId: ID!, $menuAddonProductId: ID!) {
    restaurant(id: $restaurantId) {
      id

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        addonProduct(id: $menuAddonProductId) {
          id

          isDeliveryCost
          name

          properties {
            id

            description
            imageUrl
            isActive
            price
            stateId

            reportGroup {
              ...FullMenuReportGroup
            }
          }
        }

        reportGroups {
          ...FullMenuReportGroup
        }
      }
    }
  }

  mutation UpdateMenuAddonProduct($restaurantId: ID!, $menuAddonProductId: ID!, $patch: MenuAddonProductPatch!) {
    updateMenuAddonProduct(
      restaurantId: $restaurantId
      menuAddonProductId: $menuAddonProductId
      patch: $patch
    ) { id }
  }
`

const MenuAddonProductEdit: React.FC = () => {
  const [navigation, { restaurantId, menuAddonProductId, isDeliveryCost }] = useNavigation<'MenuAddonProductEdit'>()

  const { data, loading } = useGetEditAddonProductDataQuery({ variables: { restaurantId, menuAddonProductId } })

  const navigateToList = useCallback(() => navigation.navigate(isDeliveryCost === true ? 'DeliveryLocoEdit' : 'MenuAlternativeGroupList', { restaurantId }), [isDeliveryCost])

  const [deleteMenuAddonProduct, { error: deleteError, loading: isDeleteing }] = useDeleteMenuAddonProductMutation({ awaitRefetchQueries: true, onCompleted: navigateToList })
  const [updateMenuAddonProduct, { error: updateError, loading: isSubmitting }] = useUpdateMenuAddonProductMutation({ awaitRefetchQueries: true, onCompleted: navigateToList })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const { breadcrumbs, deleteMessage } = useMemo<{ breadcrumbs: Breadcrumb[], deleteMessage: string, title: string }>(() => {
    if (isDeliveryCost !== true) {
      return {
        breadcrumbs: [
          { link: ['MenuAlternativeGroupList', { restaurantId }], title: 'Tillägg och Alternativgrupper' }
        ],
        deleteMessage: 'Radera tilläggsprodukt?',
        title: 'Redigera tilläggsprodukt'
      }
    }
    return {
      breadcrumbs: [
        { link: ['DeliveryLocoEdit', { restaurantId }], title: 'Leverans' }
      ],
      deleteMessage: 'Radera leveranskostnad?',
      title: 'Redigera leverans'
    }
  }, [isDeliveryCost, restaurantId])

  const handleUpdate = (patch: MenuProductPatch): void => {
    updateMenuAddonProduct({
      refetchQueries: [
        { query: GetAddonProductsDocument, variables: { restaurantId } }
      ],
      variables: { restaurantId, menuAddonProductId, patch }
    }).catch(logError)
  }

  const handleDelete = (): void => {
    setShowDeleteDialog(false)
    deleteMenuAddonProduct({
      refetchQueries: [
        { query: GetAddonProductsDocument, variables: { restaurantId } }
      ],
      variables: { restaurantId, menuAddonProductId }
    }).catch(logError)
  }

  if (loading) {
    return <Layout breadcrumbs={breadcrumbs} loading title='Redigera ...' />
  }

  return (
    <Layout breadcrumbs={breadcrumbs} title={`Redigera ${data?.restaurant?.menu?.addonProduct?.name ?? '...'}`}>
      <VStack gap={24} maxWidth={1024} padding={20}>
        {data?.restaurant?.menu?.addonProduct == null ? null : (
          <AddonProductForm
            errorMessage={deleteError?.message ?? updateError?.message}
            initialValues={data.restaurant.menu.addonProduct}
            isSubmitting={isSubmitting}
            onDismiss={navigateToList}
            onRemove={() => setShowDeleteDialog(true)}
            onSave={handleUpdate}
            reportGroups={data?.restaurant?.menu.reportGroups}
          />
        )}
      </VStack>

      <VerificationDialog
        errorMessage={deleteError?.message}
        loading={isDeleteing}
        onDelete={handleDelete}
        onDismiss={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        prompt={`Vill du radera ${unwrap(data?.restaurant?.menu?.addonProduct?.name)}?`}
        title={deleteMessage}
      />
    </Layout>
  )
}

export default MenuAddonProductEdit

import { type ApolloError } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Spacer from 'react-spacer'
import { HStack, Text } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type GetCreateProductDataQuery, type GetEditProductDataQuery, type MenuProductInput, type MenuType } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import formatCurrency from '../util/formatCurrency'
import ignoreAsync from '../util/ignoreAsync'
import { parsePrice } from '../util/price'

import FormContainer from './FormContainer'
import ProductVariantForm, { type ProductSchema } from './ProductVariantForm'
import SubmitFormButtons from './SubmitFormButtons'
import { CheckBox, IntegerField, NestedSelect, Select, TextField } from './fields'

export type MenuProductInputSchema = MenuProductInput & Pick<ProductSchema, 'hasQuantityAvailable'>

interface ProductFormProps {
  deleting?: boolean
  error: ApolloError | undefined
  hasMenuWriteAccess: boolean
  initialValues?: Partial<MenuProductInput>
  menuCategoryId: string
  onDismiss: () => void
  onDelete?: () => void
  onSave: (product: MenuProductInputSchema, menuCategoryId: string, menuPageId: string) => void
  restaurant:
    | {
      menu?: NonNullable<GetCreateProductDataQuery['restaurant']>['menu'] | NonNullable<GetEditProductDataQuery['restaurant']>['menu']
      printerQueues?: ReadonlyArray<{ id: string, name?: string | null }> | null | undefined
    }
    | null
    | undefined
  saving: boolean
  types?: MenuType[] | null
}

interface ProductVariantSchema {
  alternativeGroupIds?: string[] | null
  description?: string | null
  imageUrl?: string | null
  isActive?: boolean | null
  openingHoursId?: string | null
  price?: string | null
  priceIsOpen?: boolean | null
  printAt?: string[] | null
  reportGroupId: string
  secondaryPrinterIds?: string[] | null
}

const productVariantSchema: ObjectSchema<ProductVariantSchema> = yup.object({
  alternativeGroupIds: yup.array().of(yup.string().required()).optional(),
  description: yup.string().trim().max(65536, 'Beskrivningen får max vara 64 kB').nullable(),
  imageUrl: yup.string().nullable(),
  isActive: yup.boolean(),
  openingHoursId: yup.string().nullable(),
  price: yup.string().when('priceIsOpen', {
    is: false,
    then: schema => schema.typeError('Måste vara ett nummer').required('Pris måste anges'),
    otherwise: schema => schema.nullable()
  }).trim().matches(/^\d+(,\d{2})?$/, 'Ange tal på formen 1 eller 1,00').nullable(),
  priceIsOpen: yup.boolean(),
  printAt: yup.array().of(yup.string().required()).optional(),
  reportGroupId: yup.string().required('Rapportgrupp måste väljas'),
  secondaryPrinterIds: yup.array().of(yup.string().required()).optional()
}).required()

const schema: ObjectSchema<ProductSchema> = yup.object({
  defaultCourseId: yup.string().optional().nullable(),
  hasQuantityAvailable: yup.boolean().required(),
  name: yup.string().trim().min(1, 'Namn måste anges med minst 1 tecken').max(256, 'Namn får max innehålla 256 tecken').required('Namn på produkten måste anges').required('Namn måste anges'),
  quantityAvailable: yup.number().optional().nullable().when('hasQuantityAvailable', {
    is: true,
    then: schema => schema.required('Antal måste specificeras, om du vill stänga av lagersaldo toggla ur "Lagerförd" ovan')
  }),

  delivery: productVariantSchema.nullable().default(null),
  eatIn: productVariantSchema.nullable().default(null),
  takeAway: productVariantSchema.nullable().default(null)
})

const ProductForm: React.FC<ProductFormProps> = ({ error, hasMenuWriteAccess, initialValues, deleting, menuCategoryId, onSave, onDismiss, onDelete, restaurant, saving, types }) => {
  const [categoryId, setCategoryId] = useState(menuCategoryId ?? '')

  const form = useForm<ProductSchema>({
    criteriaMode: 'all',
    defaultValues: {
      ...initialValues,
      hasQuantityAvailable: initialValues?.quantityAvailable != null,
      delivery: initialValues?.delivery == null ? null : {
        ...initialValues?.delivery,
        price: initialValues?.delivery?.price == null ? null : formatCurrency(initialValues.delivery.price, { hideCurrency: true })
      },
      eatIn: initialValues?.eatIn == null ? null : {
        ...initialValues?.eatIn,
        price: initialValues?.eatIn?.price == null ? null : formatCurrency(initialValues.eatIn.price, { hideCurrency: true })
      },
      takeAway: initialValues?.takeAway == null ? null : {
        ...initialValues?.takeAway,
        price: initialValues?.takeAway?.price == null ? null : formatCurrency(initialValues.takeAway.price, { hideCurrency: true })
      }
    },
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const categoryList = restaurant?.menu?.pages?.map(({ id, name, categories }) => ({
    value: id,
    title: name ?? '',
    subOptions: categories?.map(({ id, name }) => ({ value: id, title: name ?? '' })) ?? []
  })) ?? []
  const hasMenuCourses = restaurant?.menu?.courses != null && restaurant.menu.courses.length > 0
  const courseOptions = [{ value: null, title: '' }, ...(restaurant?.menu?.courses?.map(({ id, name }) => ({ value: id, title: name ?? '' })) ?? [])]

  const [menuPageId, setMenuPageId] = useState(unwrap(restaurant?.menu?.pages?.find(page => page?.categories?.some(cat => cat.id === menuCategoryId) ?? false)).id ?? '')

  const disableDeleteButton = (deleting ?? false) || (saving ?? false)
  const disableSaveButton = !form.formState.isValid || (form.getValues().delivery == null && form.getValues().eatIn == null && form.getValues().takeAway == null) || (deleting ?? false) || (saving ?? false)

  const deliveryIsActive: boolean = form.watch('delivery.isActive') ?? false
  const deliveryPriceIsOpen: boolean = form.watch('delivery.priceIsOpen') ?? false
  const eatInIsActive: boolean = form.watch('eatIn.isActive') ?? false
  const eatInPriceIsOpen: boolean = form.watch('eatIn.priceIsOpen') ?? false
  const takeAwayIsActive: boolean = form.watch('takeAway.isActive') ?? false
  const takeAwayPriceIsOpen: boolean = form.watch('takeAway.priceIsOpen') ?? false
  const hasQuantityAvailable: boolean = form.watch('hasQuantityAvailable') ?? false

  const priceIsOpenValues: boolean[] = []
  if (deliveryIsActive) priceIsOpenValues.push(deliveryPriceIsOpen)
  if (eatInIsActive) priceIsOpenValues.push(eatInPriceIsOpen)
  if (takeAwayIsActive) priceIsOpenValues.push(takeAwayPriceIsOpen)
  const differentPriceIsOpen = priceIsOpenValues.some(item => item !== priceIsOpenValues[0])

  const changeCategory = (menuCategoryId: string): void => {
    if (menuCategoryId != null) {
      const parentId = unwrap(categoryList.find(page => page?.subOptions?.some(cat => cat.value === menuCategoryId) ?? false)).value
      setMenuPageId(parentId)
    }
    setCategoryId(menuCategoryId)
  }

  const handleCancel = (): void => {
    onDismiss()
  }

  const handleSave = (formInput: ProductSchema): void => {
    onSave(
      {
        ...formInput,
        delivery: formInput.delivery == null ? null : {
          ...formInput.delivery,
          price: formInput.delivery.price == null ? null : parsePrice(formInput.delivery.price)
        },
        eatIn: formInput.eatIn == null ? null : {
          ...formInput.eatIn,
          price: formInput.eatIn.price == null ? null : parsePrice(formInput.eatIn.price)
        },
        takeAway: formInput.takeAway == null ? null : {
          ...formInput.takeAway,
          price: formInput.takeAway.price == null ? null : parsePrice(formInput.takeAway.price)
        }
      },
      categoryId,
      menuPageId
    )
  }

  useEffect(() => {
    if (error?.graphQLErrors?.[0]?.extensions?.code !== 'BAD_USER_INPUT') return

    const errorState = error.graphQLErrors[0].extensions?.state ?? {}

    for (const key of Object.entries(errorState)) {
      // @ts-expect-error As key is the field name coming from the API we will need to set the form field names accordingly
      form.setError(key, { type: 'manual', message: errorState[key]?.[0] ?? '' })
    }
  }, [error])

  return (
    <FormContainer gap={16} wide>
      <TextField
        autoFocus
        disabled={!hasMenuWriteAccess}
        form={form}
        name='name'
        title='Produktnamn'
      />

      <NestedSelect
        disabled={!hasMenuWriteAccess}
        error={categoryId === '' || categoryId == null}
        helperText={categoryId === '' || categoryId == null ? 'Menykategori krävs' : ''}
        id='category'
        onChange={changeCategory}
        options={categoryList}
        placeholder='Välj kategori'
        required
        title='Menykategori'
        value={categoryId}
      />

      {/* If there is an initial value we show the default course select but disable it, otherwise hide it if !hasMenuWriteAccess */}
      {(!hasMenuWriteAccess && initialValues?.defaultCourseId == null) || !hasMenuCourses ? null : (
        <Select
          disabled={!hasMenuWriteAccess}
          form={form}
          name='defaultCourseId'
          options={courseOptions}
          title='Rätt'
        />
      )}

      <CheckBox
        form={form}
        name='hasQuantityAvailable'
        title='Lagerförd'
      />

      {!hasQuantityAvailable ? null : (
        <IntegerField
          estimatedNumberOfCharacters={12}
          form={form}
          name='quantityAvailable'
          title='Nuvarande lagersaldo'
        />
      )}

      {!hasMenuWriteAccess ? null : (
        <>
          <HStack grow={1} wrap>
            {types?.map(type => (
              <ProductVariantForm
                key={type}
                alternativeGroupOptions={restaurant?.menu?.alternativeGroups != null ? restaurant?.menu?.alternativeGroups?.map(({ id, name }) => ({ value: id, title: name ?? '' })) : []}
                copyToButtons={types.filter(t => t !== type)}
                form={form}
                openingHourOptions={[{ value: null, title: 'När restaurangen är öppen' }, ...restaurant?.menu?.productOpeningHours?.map(({ id, name }) => ({ value: id, title: name ?? '' })) ?? []]}
                printers={restaurant?.printerQueues != null ? restaurant.printerQueues.map(({ id, name }) => ({ value: id, title: name ?? '' })) : []}
                reportGroupOptions={restaurant?.menu?.reportGroups != null ? restaurant?.menu?.reportGroups?.map(({ id, name, vatRate }) => ({ value: id, title: `${name ?? '-'}, ${vatRate ?? '-'}%` })) : []}
                type={type}
              />
            ))}
          </HStack>

          {!differentPriceIsOpen
            ? null
            : (
              <HStack paddingTop={24}>
                <MaterialIcons name='warning' size={24} />

                <Spacer width={16} />

                <Text size={22}>Observera att en produkt där någon variant har öppet pris inte kommer visas ut mot kunder, utan kräver att man är inloggad som en anställd för att se.</Text>
              </HStack>
            )}
        </>
      )}

      <SubmitFormButtons
        deleting={deleting ?? false}
        disableDeleteButton={disableDeleteButton}
        disableSaveButton={disableSaveButton}
        onCancel={handleCancel}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(handleSave))}
        saving={saving}
      />
    </FormContainer>
  )
}

export default ProductForm
